import React, { useState } from 'react'
import UserReviewCard from '../../../Components/UserComponents/UserReviewCard'
import { Link } from 'react-router-dom'
import PropertyTripDetailComponent from './PropertyTripDetailComponent'
import TripConfirmationCardComp from './TripConfirmationCardComp'
import CardWrapper from '../../../Components/common/cardWrapper'
import GuestBasic from '../../../Components/common/guestBasic'
import ProfileImage from '../../../Components/common/profileImg'
import SupportCard from './SupportCard'
import Button from '../../../Components/common/Button'
import WrapperPopup from '../../../Components/common/wrapperPopup'
import RevewHostPopup from '../../../Module/Host/EditListing/ListingDetail/RevewHostPopup'
import ViewBreakDownCard from '../../../Components/HostComponents/viewBreakdownCard'
import moment from 'moment'

const PastDetailsPage = (props) => {

  const [reviewModal, setReviewModal] = useState(false); 

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const data = props.successdata?.transaction_details[0]?.price_detail;
    const addonData = props.successdata?.transaction_details[0]?.addon_details;
    console.log(data, "tried", "sdss");

    console.log(props?.successdata,'checkAddonData')
    let priceDetailMapvalues = {};
    data?.forEach((x) => {
      console.log(x, 'tried', 'wow');
  
      if (x.total) {
        console.log("tried", x.total);
        priceDetailMapvalues = x.total;
      }
    });
  

  
  
  
  const toggleVisibleDocks = () => {
   
    setReviewModal(false)
    setOpenModal(false);
   
  }

    const hostNameForUser = props.successdataUser?.host_fname + ' ' + props.successdataUser?.host_lname;
   
    console.log(props.isCheckoutDateValid,props.reviewWithBookId && props.reviewWithBookId?.[0]?.booking_id === props.successdataUser?.booking_order_id ,'checkDateValue')
    return (
    <div className="property-page">      
    <div className="property-page-grid-box">
      <div className="property-page-left-content">
        <div className="property-page-property-name-box">
          <div className="property-name-box">
            <p className="property-name-text">{props.successdataprop?.property_name}</p>
            <p className="property-name">{props.successdataprop?.space_type} hosted by {props.successdataprop?.host_details[0].fname}</p>
          </div>
          <Link to={"/properties/"+props.successdataprop?.city+"/"+ props.successdataprop?.slug}><Button btnText="View" btnclasses="offer-btn" /></Link>
        </div>
        <div className="property-page-booking-img-box">
        <img
          className="property-page-property-img"
          src={props.successdataprop?.base_url_image+ props.successdataprop?.images[0]}
          alt=""
        />
        </div>
        <PropertyTripDetailComponent
        successdata={props.successdata} 
        checkInTime={props.checkInTime}
        checkOutTime={props.checkOutTime}
      />

     <TripConfirmationCardComp
      successdata={props.successdata} 
      successdataprop={props.successdataprop}
      handleOpenModal={handleOpenModal}
    /> 
      </div>

     
      <div className="property-page-right-content">     
      <>

    <CardWrapper wrapperHeading='About Host' wrapperClass='about-guest-card'>
       <GuestBasic

        description={props.successdataprop?.host_details?.[0]?.about_me} 
        guestName ={hostNameForUser} verfiedText='Verified' icon='./assets/img/guestverified.svg'
        starPoint={props.ratingAverage?.Overall_Average ? props.ratingAverage?.Overall_Average : '0'} starIcon='./assets/img/colorStarIcon.svg' noReviews={props.review?.length !== 0  ? props.review?.length : 0}
        //  guestDuration='Jumbo guest since April 2023'
        />
        <ProfileImage src={props.successdataprop?.host_details?.[0]?.image ? props.assetsurl+props.successdataprop?.host_details?.[0]?.image : './assets/img/notFoundImage.png'} width='100%'/>
             
          </CardWrapper> 
          {props.successdata?.room_id && props.successdata?.room_id !== null ? <Link to={"/user-message?room_id="+props.successdata?.room_id}><Button btnText="Message host"  btnclasses="contact-btn" /></Link> : null}
          
          {props.reviewWithBookId && props.reviewWithBookId?.[0]?.booking_id === props.successdataUser?.booking_order_id ? (
            <div>
              <UserReviewCard
                userReviewText={props.review[0]?.review}
                profileName={props.review[0]?.users_first_name + ' ' + props.review[0]?.users_last_name}
                profileImage={props.assetsurl+props.review[0]?.users_profile_image}
                rating={props.ratingAverage?.Overall_Average}
                whoseProfile={moment(props.review[0]?.createdon).format("D MMM YYYY")}
              />
            </div>
          ) : (
            <>
             
                <Button
                  onClick={() => setReviewModal(true)}
                  btnText="Add Review"
                  btnclasses="reveiw-btn"
                />
             
            </>
          )}
{/*-----------------------------------------------------------------  */}
 
            <SupportCard   
            />      
            </>
      
        </div>

    </div>


    <div className="add-review-popup view-breakdown-popup">
        {reviewModal && (
       <WrapperPopup
       fluid={true}
       AppylyBtnBox={true}
       defaultSize={"0.45"}
       dimStyle={false}
       isVisible={true} 
       setVisibleDocks={toggleVisibleDocks}
       closeModal={setReviewModal}
            comp={<RevewHostPopup
              hostId = {props.hostId}
              fetchHostReviewDataWithBookingId = {props.fetchHostReviewDataWithBookingId }
               bookingOrderId = {props.bookingOrderIdForUser}
               propId={props.propIdForUser}  
             closeModal={setReviewModal}
             fetchHostReviewData={props.fetchHostReviewData}
              />}
       />

        )}
      </div>

      <div className="view-breakdown-popup">
          {openModal && (
            <WrapperPopup
            // AppylyBtnBox={true}
            defaultSize={"0.45"}
            dimStyle={false}
            isVisible={true} 
            setVisibleDocks={toggleVisibleDocks}
            
            //   closeModal={() => setOpenModal(false)}
              closeModal={handleCloseModal}
              comp={
                <ViewBreakDownCard
                  src={props.successdataprop?.base_url_image + props.successdataprop?.images[0]}
                  starRating="5"
                  addons={addonData}
                  reviewNo="7"
                  propertyName={props.successdataprop?.property_name}
                  entireProperty={props.successdataprop?.space_type}
                  perDayprice={priceDetailMapvalues?.total_property_addon_base_cost?.toFixed(2)}
                  ExpPrice={priceDetailMapvalues.total_experience_base_cost?.toFixed(2)}
                  TaxPrice={priceDetailMapvalues.total_property_tax_cost?.toFixed(2)}
                  ServiceFee={priceDetailMapvalues.total_property_service_cost?.toFixed(2) || 0}
                  totalAmount={priceDetailMapvalues?.grand_total_booking_cost?.toFixed(2)}
                  noOfNight={moment(props.successdata?.transaction_details[0].checkout_date).diff(moment(props.successdata?.transaction_details[0].checkin_date), "days")}
                />
              }
            />
          )}
        </div>

  </div>
  )
}

export default PastDetailsPage
