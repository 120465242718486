import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../Components/common/Button";
import EditSaveBtn from "../EditSaveBtn";

const EditCheckoutTime = (props) => {
  const [postData, setPostData] = useState(null);

  let url = "createProperty_metadata_bulk";
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, fromOnChanged) => {
    const postDatavalue = {
      property_id: props.propId,
      meta: [
        {
          metadata_key: "check_out",
          metadata_value: fromOnChanged ? data : data.checkOuttime,
        },
      ],
    };
    setPostData(postDatavalue);
  };

  return (
    <div className="edit-check-in-time-div " style={{marginTop:'2rem'}}>
      <form className="time-input-form" onSubmit={handleSubmit(onSubmit)}>
       
        <Controller
          control={control}
          defaultValue={
            props.allData !== undefined ? props.allData?.check_out : ""
          }
          name="checkOuttime"
          rules={{ required: "Please select a time" }}
          render={({ field }) => (
            <input
              type="time"
              id="checkOuttime"
              onChange={(e) => {
                field.onChange(e.target.value);
                onSubmit(e.target.value, true);
              }}
              value={field.value}
            />
          )}
        />
        {errors.checkOuttime && <p>{errors.checkOuttime.message}</p>}

        <div className="clear_apply-btn-box paddingCustomYSide">
          <p onClick={() => props.closeModal(false)} className="clear-btn">
            Cancel
          </p>
          <EditSaveBtn
            method="post"
            postData={postData}
            fetchData={props.fetchData}
            btnText="Save"
            btnclasses="save-edit-btn"
            url={url}
            closeModal={props.closeModal}
            message='updated successfully'
          />
        </div>
      </form>
    </div>
  );
};
export default EditCheckoutTime;
