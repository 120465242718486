
import SwitchExample from "../../../Components/common/switch";
import WrapperPopup from "../../../Components/common/wrapperPopup";
import { useEffect, useState } from "react";
import EditTitle from "../EditListing/ListingDetail/EditTitle";
import EditDescription from "../EditListing/ListingDetail/EditDescription";
import EditAddress from "../EditListing/ListingDetail/EditAddress";
import EditGuest from "../EditListing/ListingDetail/EditGuest";
import EditAmenities from "../EditListing/ListingDetail/EditAmenities";
import EditRooms_beds from "../EditListing/ListingDetail/EditRooms_beds";
import EditPhotos from "../EditListing/ListingDetail/EditPhotos";

import { all } from "axios";
import EditRules from "../EditListing/ListingDetail/EditRules";
import EditPropertyType from "../EditListing/ListingDetail/EditPropertyType";
import EditPlacType from "../EditListing/ListingDetail/EditPlacType";
import LocationDescription from "../EditListing/ListingDetail/LocationDescription";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ListingDetailEditContent = (props) => {
  const [openModalPhotos, setOpenModalPhotos] = useState(false);

  const [openModalTitle, setOpenModalTitle] = useState(false);
  const [openModalDescrip, setOpenModalDescrip] = useState(false);
  const [openModalLocationDescrip, setOpenModalLocationDescrip] = useState(false);

  const [openModalRules, setOpenModalRules] = useState(false);

  const [openModalAddress, setOpenModalAddress] = useState(false);

  const [openModalGuest, setOpenModalGuest] = useState(false);
  const [openModalAmenities, setOpenModalAmenities] = useState(false);
  const [openModalRooms_beds, setOpenModalRomms_beds] = useState(false);
  const [openModalPropType, setOpenModalPropType] = useState(false);
  const [openModalPlaceType, setOpenModalPlaceType] = useState(false);



  const allData = props.listingData?.data[0];

  const amenitiesDetails = allData?.amenities_details;

  console.log(amenitiesDetails,'checkNewDetails')
  console.log(amenitiesDetails, "oot");
  const amenityNames = amenitiesDetails?.map((obj) => obj.amenity_name);
  console.log(amenityNames, "oot");

  console.log(allData, "oom");
  // console.log(allData.images, "oom");

  const titleText = allData?.property_name;
  const propId = allData?.propertyid;
  console.log(propId, "kiran");
  const preferencePropId = allData?.perference_id;
  const imagesData = allData?.images;
  console.log(preferencePropId, "777");



  const locationDescriptionText = allData?.location_description;

  console.log(locationDescriptionText,'textCheck')
  const truncatedLocationDesText = locationDescriptionText && locationDescriptionText.length > 60
  ? `${locationDescriptionText.slice(0, 60)}...`
  : locationDescriptionText;

  
  const descriptionText = allData?.description;

  // Check if descriptionText exists and its length is greater than 60
  const truncatedDescriptionText = descriptionText && descriptionText.length > 60
    ? `${descriptionText.slice(0, 60)}...`
    : descriptionText;


  // const rulesText = allData?.rules;
  const streetText = allData?.street_address;
  const cityText = allData?.city;
  const stateText = allData?.state;
  const max_guestsText = allData?.max_guests;

  // console.log(titleText, "new");
  // const extracteData = props.extracteData;
  // console.log(props, "tur");

const toggleVisibleDocks = () => {
  setOpenModalPhotos(false);
    setOpenModalTitle(false);
    setOpenModalDescrip(false)
    setOpenModalLocationDescrip(false)
    
    
    setOpenModalAmenities(false)
    setOpenModalGuest(false)
    setOpenModalRomms_beds(false)
    setOpenModalRules(false)
    setOpenModalAddress(false)
    setOpenModalPropType(false)
    setOpenModalPlaceType(false)
  };


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const imagesToShow = windowWidth <= 500 ? 2 : 4; // Show 2 images if screen width is 500px or below, otherwise show 3 images


  return (
    <div className="listing-detail-edit-content">
      <div className="photos-edit-main-div">
        <div className="photos-edit-text-div">
          <h2>Photos</h2>
          <p
            onClick={() => setOpenModalPhotos(true)}
            style={{ cursor: "pointer" }}
          >
            Edit
          </p>
        </div>
        

     <div className="edit-photos-main-div">
      {imagesData && imagesData.length > 0 ? (
        <>
          {imagesData.slice(0, imagesToShow).map((imageUrl, index) => (
            <div className="edit-photos-img-box" key={index}>
              {windowWidth <= 500 ? (
                <>
                  {index === 1 && (
                    <>
                      <img src={allData?.base_url_image + imageUrl} alt="" />
                      <div className="overlay-edit-image" onClick={() => setOpenModalPhotos(true)}>
                        <p>View all {imagesData.length} Photos</p>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {index === 3 && (
                    <>
                      <img src={allData?.base_url_image + imageUrl} alt="" />
                      <div className="overlay-edit-image" onClick={() => setOpenModalPhotos(true)}>
                        <p>View all {imagesData.length} Photos</p>
                      </div>
                    </>
                  )}
                </>
              )}
              <img src={allData?.base_url_image + imageUrl} alt="" />
            </div>
          ))}
        </>
      ) : (
        <p>No Images Found</p>
      )}
    </div>
   
      </div>
      {/*  */}
      <div className="line"></div>

      <div className="edit-basic-details-main-div">
        <h2>Basic Details</h2>

        {/*  */}
        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Title</h3>
            <p className="para-text">{titleText}</p>
          </div>
          <p
            onClick={() => setOpenModalTitle(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>

        <div className="light-line"></div>

        {/*  */}

        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Description</h3>
            {/* <p className="para-text">{descriptionText}</p> */}
            <p className="para-text" dangerouslySetInnerHTML={{ __html: truncatedDescriptionText }}></p>

          </div>
          <p
            onClick={() => setOpenModalDescrip(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>

        
        <div className="light-line"></div>

        {/*  */}

        {/*  */}

        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Location</h3>
            <p className="para-text">
              {streetText}, {cityText} {stateText}
            </p>
          </div>
          <p
            onClick={() => setOpenModalAddress(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>

        
        <div className="light-line"></div>


        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Location Description</h3>
            {/* <p className="para-text"  dangerouslySetInnerHTML={{ __html:locationDescriptionText  }}></p> */}
            <p className="para-text">
            {locationDescriptionText !== null ? (
              // Render the locationDescriptionText if it is defined
              <span dangerouslySetInnerHTML={{ __html: truncatedLocationDesText }}></span>
            ) : (
              
              <p className="para-text">Not yet added <FontAwesomeIcon icon={faExclamationTriangle} className="yellow svg"/> </p>
            )}
          </p>

          </div>
          <p
            onClick={() => setOpenModalLocationDescrip(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>
        <div className="light-line"></div>

        {/*  */}

        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Property Category</h3>
            <p className="para-text">
              {allData?.property_type_name}
            </p>
          </div>
          <p
            onClick={() => setOpenModalPropType(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>

        
        <div className="light-line"></div>

        {/*  */}

   {/*  */}

    <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Place Category</h3>
            <p className="para-text">
             
              {allData?.placetype_name}
            </p>
          </div>
          <p
            onClick={() => setOpenModalPlaceType(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>

        
        <div className="light-line"></div>

        {/*  */}

        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Guest</h3>
            <p className="para-text">Maximum Guest Allowed: {max_guestsText}</p>
          </div>
          <p
            onClick={() => setOpenModalGuest(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>

        
        <div className="light-line"></div>

        {/*  */}

        {/*  */}

        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Amenities</h3>
            <p className="para-text amenities-text-box">
              {amenityNames?.join(", ").substring(0,100) + "..."}
            </p>
          </div>
          <p
            onClick={() => setOpenModalAmenities(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>

        <div className="light-line"></div>

        {/*  */}

        {/*  */}

        {/*  */}
        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Rooms,Beds and Baths</h3>
            <p className="para-text amenities-text-box">
              <span>{allData?.bedrooms} Bedrooms,</span>
              <span>{allData?.beds} Beds,</span>
              <span>{allData?.bathrooms} Bathrooms</span>
            </p>
          </div>
          <p
            className="basic-detail-edit-btn"
            onClick={() => setOpenModalRomms_beds(true)}
          >
            Edit
          </p>
        </div>
       
      </div>

      <div className="lisitng-title-details-popup">
        {openModalTitle && (
          <WrapperPopup
          fluid={true}
          headingText='Title'
          AppylyBtnBox={true}
          defaultSize={"0.45"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 

            closeModal={setOpenModalTitle}
            comp={
              <EditTitle
                extractedData={props.extractedData}
                propId={propId}
                titleText={titleText}
                closeModal={setOpenModalTitle}
                fetchData={props.fetchData}
              />
            }
          />
        )}
      </div>

      <div className="lisitng-title-details-popup lisitng-discription-details-popup">
        {openModalDescrip && (
          <WrapperPopup
          fluid={true}
          headingText='Description'
          defaultSize={"0.90"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
            closeModal={setOpenModalDescrip}
            comp={
              <EditDescription
                propId={propId}
                descriptionText={allData?.description}
                closeModal={setOpenModalDescrip}
                fetchData={props.fetchData}
              />
            }
          />
        )}
      </div>


      <div className="lisitng-title-details-popup lisitng-discription-details-popup">
        {openModalLocationDescrip && (
          <WrapperPopup
          fluid={true}
          headingText='Description'
          defaultSize={"0.90"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
            closeModal={setOpenModalLocationDescrip}
            comp={
              <LocationDescription
                propId={propId}
                locationDescriptionText={allData?.location_description}
                closeModal={setOpenModalLocationDescrip}
                fetchData={props.fetchData}
              />
            }
          />
        )}
      </div>

      <div className="lisitng-title-details-popup lisitng-address-details-popup">
        {openModalAddress && (
          <WrapperPopup
          fluid={true}
          headingText='Location'
        defaultSize={"0.90"}
        dimStyle={false}
        isVisible={true} 
        setVisibleDocks={toggleVisibleDocks} 
            closeModal={setOpenModalAddress}
            comp={
              <EditAddress
                extractedData={props.extractedData}
                propId={propId}
                allData={allData}
                closeModal={setOpenModalAddress}
                fetchData={props.fetchData}
              />
            }
          />
        )}
      </div>

      <div className=" lisitng-guest-details-popup">
        {openModalGuest && (
          <WrapperPopup

          fluid={true}
         headingText='Guests'
          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
            // wrapperPop={true}
            closeModal={setOpenModalGuest}

            comp={
              <EditGuest
                fetchData={props.fetchData}
                preferencePropId={preferencePropId}
                allData={allData}
                closeModal={setOpenModalGuest}
              />
            }
          />
        )}
      </div>

      <div className=" lisitng-amenities-details-popup">
        {openModalAmenities && (
          <WrapperPopup
          fluid={true}
          DockFilter={true}
          position='bottom'
          headingText='Amenities'
          defaultSize={"0.90"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
            closeModal={setOpenModalAmenities}
            comp={
              <EditAmenities
                fetchData={props.fetchData}
                propId={propId}
                selectedAmenities={amenitiesDetails}
                allData={allData}
                closeModal={setOpenModalAmenities}
              />
            }
          />
        )}
      </div>
      <div className=" lisitng-rooms-beds-details-popup">
        {openModalRooms_beds && (
          <WrapperPopup
          fluid={true}
          headingText='Rooms Beds and Baths'
          defaultSize={"0.70"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks}
            closeModal={setOpenModalRomms_beds}
            comp={
              <EditRooms_beds
                preferencePropId={preferencePropId}
                fetchData={props.fetchData}
                allData={allData}
                closeModal={setOpenModalRomms_beds}
              />
            }
          />
        )}
      </div>
      <div className=" lisitng-photos-details-popup">
        {openModalPhotos && (
          <WrapperPopup
          fluid={true}
          headingText='Edit Photos'
          DockFilter={true}
          position='bottom'
        defaultSize={"0.90"}
        dimStyle={false}
        isVisible={true} 
       
        setVisibleDocks={toggleVisibleDocks} 
          // wrapperPop={true}
            closeModal={setOpenModalPhotos}
            comp={
              <EditPhotos
                propId={propId}
                fetchData={props.fetchData}
                allData={allData}
                // imagesData={imagesData}
                // closeModal={setOpenModalPhotos}
              />
            }
          />
        )}
      </div>

      <div className="property-type-popup-div">
      {openModalPropType && (
          <WrapperPopup
          fluid={true}

          DockFilter={true}
          position='bottom'
          headingText='Property Category'
          defaultSize={"0.70"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks}
            closeModal={setOpenModalPropType}
            comp={
              <EditPropertyType
             
                preferencePropId={preferencePropId}
                fetchData={props.fetchData}
                allData={allData}
                propId={propId}
                closeModal={setOpenModalPropType}
              />
            }
          />
        )}
      </div>

      <div className="property-type-popup-div">
      {openModalPlaceType&& (
          <WrapperPopup
          fluid={true}
          headingText='Place Category'
          defaultSize={"0.70"}
          dimStyle={false}
          DockFilter={true}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks}
            closeModal={setOpenModalPlaceType}
            comp={
              <EditPlacType
                preferencePropId={preferencePropId}
                fetchData={props.fetchData}
                allData={allData}
                propId={propId}
                closeModal={setOpenModalPlaceType}
              />
            }
          />
        )}
      </div>

      {/*  */}
    </div>
  );
};

export default ListingDetailEditContent;
