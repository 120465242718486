import React, { useEffect, useState } from 'react';
import CardWrapper from '../../Components/common/cardWrapper';


import WrapperPopup from '../../Components/common/wrapperPopup';
import EditMaxStay from '../../Module/Host/EditListing/PricingAvailabilty/EditMaxStay';
import EditMinStay from '../../Module/Host/EditListing/PricingAvailabilty/EditMinStay';
import HostDOBPop from '../../Module/Host/Profile/HostDOBPop';
import HostNameEdit from '../../Module/Host/PersonalInfoPops/HostNameEdit';
import HostMailEdit from '../../Module/Host/PersonalInfoPops/HostMailEdit';
import HostAdressEdit from '../../Module/Host/PersonalInfoPops/HostAdressEdit';
import HostPhoneEdit from '../../Module/Host/PersonalInfoPops/HostPhoneEdit';
import HostGovtID from '../../Module/Host/PersonalInfoPops/HostGovtID';
import { apiMainUser } from '../../utils/constant';
import axios from 'axios';
import SkeletonLoader from '../../Components/common/SkeletonLoader';
import ViewDocuments from '../../Module/Host/PersonalInfoPops/ViewDocuments';
import { useNavigate } from 'react-router-dom';
// Import other edit components here

const PersonalInfo = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupComponent, setPopupComponent] = useState(null);
  const [usersData,setUsersData ] = useState(null)
  const [docsViewPop,setDocsViewPop] = useState(false)

  
const Mobnumber = usersData?.mobile;
const maskedNumber = '****' + String(Mobnumber).slice(4);
console.log(maskedNumber,'iit'); 

const addressAll = usersData?.address && usersData?.flat_no && usersData?.state && usersData?.city && usersData?.pincode;

const isAllValuesPresent = Boolean(addressAll);
console.log(isAllValuesPresent); 


const handleViewDocument = () => {
  const lastDocument = usersData?.user_docs_details?.[usersData.user_docs_details.length - 1];
  if (lastDocument) {
    // window.open(lastDocument.doc_file, '_blank');
    setDocsViewPop(true)
  }
};

console.log(usersData?.user_docs_details,'checkAllDetails')

const getDocumentStatusText = () => {
  const lastDocument = usersData?.user_docs_details?.[usersData.user_docs_details.length - 1];
  return lastDocument && lastDocument.doc_name ? "Provided" : "Not Provided";
};


  const fetchData = async () => {
   
    try {
      const response = await axios
        .get( apiMainUser + "get_user_detail", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setUsersData(response.data.data[0]);
          
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
   
  }, []);
console.log(usersData,'ro')


  const handleEdit = (id) => {
   
    
    let componentToRender = null;

    let headingText = ''; 
    switch (id) {
      case 1:
        componentToRender = <HostNameEdit fetchData={fetchData} usersData={usersData}  closeModal={setIsPopupOpen} />;
        headingText = 'Legal Name';
        break;
      case 2:
        componentToRender = <HostMailEdit fetchData={fetchData} usersData={usersData} closeModal={setIsPopupOpen} />;
        headingText = 'Email Id';
        break;
      case 3:
        componentToRender = <HostPhoneEdit fetchData={fetchData} usersData={usersData} closeModal={setIsPopupOpen} />;
        headingText = 'Mobile Number';
        break;

        case 4:
          componentToRender = <HostAdressEdit fetchData={fetchData} usersData={usersData} closeModal={setIsPopupOpen} />;
          headingText = 'Address';
          break;
          case 5:
            componentToRender = <HostGovtID fetchData={fetchData} usersData={usersData} closeModal={setIsPopupOpen} />;
          headingText = 'Verify your Identity';

            break;
            
     
      default:
        break;
    }

    // if (componentToRender) {
    //   setPopupComponent(componentToRender);
    //   setIsPopupOpen(true);
    // }
    if (componentToRender) {
      setPopupComponent({ component: componentToRender, headingText }); // Set both component and heading text
      setIsPopupOpen(true);
  }
  };

  const cardData = [
    {
      id: 1,
      
      title: "Legal Name",
      // description: usersData ? (usersData?.fname ? usersData?.fname : "host@gmail.com") : <SkeletonLoader width={100} height={20}/>,//
      ...(usersData?.fname && { description: usersData.fname + " " + usersData.lname})
    },
    {
        id: 2,
        title: "Email",
        // description: usersData ? (usersData?.email ? usersData?.email : "host@gmail.com") : <SkeletonLoader width={100} height={20}/>,//
        ...(usersData?.email && { description: usersData.email })
     
      },
      {
        id: 3,
        
        title: "Phone",
        
        // description: maskedNumber ? maskedNumber : "9389****999",//
        ...(usersData?.mobile && { description: maskedNumber })

        
      },
      {
        id: 4,
       
        title: "Address",
        // description: isAllValuesPresent ? "Provided" : 'Not Provided'//
        ...(isAllValuesPresent && addressAll && { description: 'provided' })

      },
     
      {
        id: 5,
        title: "Government ID",
        ...(usersData?.user_docs_details && usersData.user_docs_details.length > 0 && { description: getDocumentStatusText() }),
        hasDocuments: usersData?.user_docs_details?.length > 0,
      },
    
   
  ];
  const toggleVisibleDocks = () => {
   setIsPopupOpen(false)
  setDocsViewPop(false)
    

  };

  const navigate = useNavigate()


  const navigateToHostAccountPage =()=>{
    navigate('/hostAccount')
  }

  return (
    <div className='account-detail-page '>
      <p style={{ fontSize: '1.2rem', fontWeight: '500' }}> <span style={{cursor:'pointer'}} onClick={navigateToHostAccountPage}>Account</span> > Personal info</p>
      <h2 className='account-head-text'>Personal Info</h2>

      <div className="host-account-detail-box">
        {cardData.map((card) => (
          <CardWrapper wrapperClass='host-account-card host-personal-card' key={card.id}>
            <div>
              <p className='description'>{card.title}</p>
              <p className='title'>{card.description}</p>
            </div>
            {/* <p
              style={{ textDecoration: 'underline', fontWeight: '500', cursor: 'pointer' }}
              onClick={() => handleEdit(card.id)}
            >
              Edit
            </p> */}
            <div>
        
          <p
            style={{
              textDecoration: 'underline',
              fontWeight: '500',
              cursor: 'pointer',
            }}
            onClick={() => handleEdit(card.id)}
          >
            Edit
          </p>
          {card.hasDocuments && card.description && (
          <p
            style={{
              textDecoration: 'underline',
              fontWeight: '500',
              cursor: 'pointer',
              marginTop: '0.6rem'
            }}
            onClick={handleViewDocument} 
          >
            View
          </p>
        )}

        </div>

                  </CardWrapper>
                ))}
      </div>

      <div className='personal-info-page-popup-main-div'>
      {isPopupOpen && (


        <WrapperPopup 
        fluid={true}
        AppylyBtnBox={true}
    
        defaultSize={"0.60"}
        headingText={popupComponent?.headingText}
        dimStyle={false}
        isVisible={true} 
        setVisibleDocks={toggleVisibleDocks} 
        closeModal={setIsPopupOpen} 
        comp={popupComponent?.component}
        />
      )}




      </div>


      <div className='personal-info-page-popup-main-div view-documument-popup'>
      {docsViewPop && (


        <WrapperPopup 
        fluid={true}
        headingText='View Documents List'
        defaultSize={"0.60"}
        dimStyle={false}
        isVisible={true} 
        setVisibleDocks={toggleVisibleDocks} 
        closeModal={setDocsViewPop} comp={<ViewDocuments 
          closeModal={setDocsViewPop}
          documents={usersData?.user_docs_details}
        />} />
      )}

      


      </div>

      
    </div>
  );
};

export default PersonalInfo;
