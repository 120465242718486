import {React,useEffect,useRef,useState} from "react";
import SwiperCore, { A11y, Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import WishListIcon from "../../../Components/UserComponents/wishListIcon";
import PropertyPricing_calculated from "../../../Components/UserComponents/propertyPricing_calculated";
import PropertyDetailFeature from "../../../Components/UserComponents/propertyDetailFeature";
import PropertyDetailParagraph from "../../../Components/UserComponents/propertyDetailParagraph";
import PropertyAmenitiesPop from "../../../Components/UserComponents/propertyAmenitiesPop";
import ExpCardHeadText from "../../../Module/User/expCardHeadText";
import CardWrapper from "../../../Components/common/cardWrapper";
import UserExpCard from "../../../Module/User/userExpCard";
import UserPropReviewPage from "../../../Module/User/userPropReviewPage";
import GuestProfile from "../../../Components/common/guestProfile";
import LanguageContactHostCard from "../../../Components/UserComponents/languageContactHostCard";
import ListComponent from "../../../Components/common/ListComponent";
import UserReviewCard from "../../../Components/UserComponents/UserReviewCard";
import ReviewWithImages from "../../../Components/UserComponents/reviewWithImages";
import { apiMainUser, apiPublicUser, getBlockedDatesFunc } from "../../../utils/constant";
import axios from "axios";
import ImageGalleryPopup from "../../../Components/common/ImageGalleryPopup";
import UserMobileReserveComp from "../../../Module/User/UserMobileReserveComp";
import { Link, useLocation, useNavigate } from "react-router-dom";
import WrapperPopup from "../../../Components/common/wrapperPopup";
import MobHeadDestinationGuest from "../../../Module/User/MobHeadDestinaionGuest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft,faCircle, faArrowUpFromBracket, faShare, faShareNodes, faLocation, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import UserShareBtn from "../../../Components/UserComponents/UserShareBtn";
import { RWebShare } from "react-web-share";
import SkeletonLoader from "../../../Components/common/SkeletonLoader";
import PropertyDetailsBox from "../../../Components/UserComponents/propertyDetailsBox";
import Button from "../../../Components/common/Button";
import LineDiv from "../../../Components/common/LineDiv";
import IconCircle from "../../../Components/common/IconCircle";
import { getTotalGuestCount } from "../../../utils/varResults";
import DOMPurify from "dompurify";
import { Marker, StaticGoogleMap } from "react-static-google-map";
import ContactDestionationPop from "../../../Module/User/ContactDestionationPop";

import CancellationPolicyCard from "../../../Components/UserComponents/CancellationPolicyCard";
import CryptoJS from 'crypto-js';
import moment from "moment";
import RewardsReviewImages from "../../../Components/UserComponents/RewardsReviewImages";
// SwiperCore.use([Navigation]);

import { faCross, faXmark } from "@fortawesome/free-solid-svg-icons";
import MobHeadNav from "../../../Module/User/MobHeadNav";

SwiperCore.use([Navigation, Virtual,Pagination, A11y]);
// SwiperCore.use([Navigation, Virtual]);


const UserPropertyDetailsIn = (props) => {

 
  // console.log(props.mainImg,'AllSetToCheck')

  const [blockDateState, setBlockedDates] = useState();
  const [blockCheckinDateState, setCheckinBlockedDates] = useState();
  const [minDateState, setMinDate] = useState();
  const [maxDateState, setMaxDate] = useState();
  const [blockCheckoutDateState, setCheckoutBlockedDates] = useState();
  const [successdata, setSuccessData] = useState([]);
  const [apicall, setApiCall] = useState(false);
  const [propertyId, setPropertyId] = useState();


  const [guestCount, setGuestCount] = useState(null);
  const [infantCount, setInfantCount] = useState(null);
  const [childrenCount, setChildrenCount] = useState(null);
  const [adultCount, setAdultCount] = useState(null);

  const [hostUserName ,setHostUserName] = useState()
  const [coHostUserName ,setCoHostUserName] = useState()




  const [selectedCheckIn, setCheckIn] = useState();
  const [selectedCheckOut, setCheckOut] = useState();

const [openPricingModal,setOpenPricingModal]= useState()


const reviewMainParentRef = useRef(null);


const handleScrollToReviews = () => {
  console.log(reviewMainParentRef.current,'calledScroll')
  // Checking here the ref is available
  if (reviewMainParentRef.current) {

    const reviewMainParentRefs  = reviewMainParentRef.current.offsetTop;
    console.log(reviewMainParentRefs,'checkRef')

    // Set the scroll position to targetDivOffsetTop - 100px
    window.scrollTo({
      top: reviewMainParentRefs - 100,
      behavior: 'smooth', // You can use 'auto' instead for instant scrolling
    });


    // Scrolling to that div
    // reviewMainParentRef.current.scrollIntoView({ behavior: 'smooth' });
   
    
  }
};



 console.log(props.allData
  ,'checkHostDetail')


 
  useEffect(() => {
    const newHostUserName = props?.allData?.host_details[0]?.host_username;
    const newCoHostUserName = props?.allData?.cohost_details[0]?.host_username;

    console.log(props.allData,'checkHostProps')
    setHostUserName(newHostUserName);
    setCoHostUserName(newCoHostUserName)
    
  }, [props.allData]);


    console.log(props.allData?.host_details[0]?.rewards_alloted[0]?.rewards_details,'doneCalled')
const location = window.location.href;


const parseQueryParamsAll = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const checkin_date = queryParams.get('checkin');
  const checkout_date = queryParams.get('checkout');
  const adults_count = queryParams.get('adults');
  const infants_count = queryParams.get('infants');
  const children_count = queryParams.get('children');

  setCheckIn(checkin_date);
  setCheckOut(checkout_date);
  setInfantCount(infants_count);
  setChildrenCount(children_count);
  setAdultCount(adults_count);
  var calculatedGuestCount = getTotalGuestCount(adults_count,children_count)
  setGuestCount(calculatedGuestCount);
};

useEffect(() => {  
  parseQueryParamsAll();
  
}, [location]); 


  useEffect(() => {
    setPropertyId(props.propertyId); // Set propertyId first
    // setApiCall(true); // Then set apiCall to true
}, [props.propertyId]);



  ///Calling the  function present in constant file for get data 

  
 
 
  
  useEffect(() => {
    if (propertyId !== undefined && propertyId !== null && propertyId !== '') {
      const fetchData = async () => {
        try {
          const resData = await getBlockedDatesFunc(propertyId);
          setMinDate(resData.minDate);
          setMaxDate(resData.maxDate);
          setBlockedDates(resData.blockedDates);
          setCheckinBlockedDates(resData.blockedDatesCheckin);
          setCheckoutBlockedDates(resData.blockedDatesCheckout);
          setSuccessData(resData.response);
        } catch (error) {
          // Handle the error if needed
        }
      };
  
      fetchData(); // Call the async function when propertyId is defined and not empty
    }
  }, [propertyId]);
  

  const PricingPopupOpenFunc =()=>{
    setOpenPricingModal(true)
  }


  // fetching data  below for cancellation poliy details-------------
  const [cancellationData,setCancellationData]= useState()


  useEffect(()=>{
    setCancellationData(props?.allData?.cancellation_category_detail)
  },[props])



  //-------------------------------------------------------------------

  const [reviewText,setReviewText] = useState()
  const [reviewAll,setReviewAll] = useState()
  const [assetsurl,setAssetsUrl] = useState()
  const [profileImages, setProfileImages] = useState([]);


  
  const encodedAbout = DOMPurify.sanitize(props.about, { USE_PROFILES: { html: true } });
  const encodedRules = DOMPurify.sanitize(props.rules, { USE_PROFILES: { html: true } });
  console.log(encodedRules,'checkValueNow')

  const encodedHostAbt = DOMPurify.sanitize(props.hostDetails?.about_me, { USE_PROFILES: { html: true } });


  const isSmallScreen = window.innerWidth <= 500;
  const isMediumScreen = window.innerWidth <= 780;

  ///get api calling for review Data-------------------
  const fetchReviewData = async () => {
   
    // API call
    try {
      const response = await axios
        .get(apiMainUser + "reviews-ratings/property/"+ props.propertyId, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
       
          setReviewAll(response.data.data)
          setAssetsUrl(response.data.assets_url)
          setReviewText(response.data.data.reviews)
          const profileImagesArray = response.data.data.reviews.map(review =>
            review.users_profile_image ? response.data.assets_url+review.users_profile_image : './assets/img/userIconImg.png'
          );
      
          setProfileImages(profileImagesArray);
          
        });
    } catch (error) {
    }
  };

  



  useEffect(() => {
    if (props.propertyId) {
      fetchReviewData();
      // fetchHostReviewData()
    }
  }, [props.propertyId]);

  
  
  const { check_in, check_out, max_guests } = props?.allData || {};

  // Use the code provided earlier to conditionally render the list
  const listData = [
    check_in && `Check-in time: ${check_in} am`,
    check_out && `Check-out time: ${check_out} pm`,
    max_guests && `Max guests allowed: ${max_guests}`,
  ].filter(Boolean); // This will remove any falsy values (undefined, null, empty strings)


  const reviews = [
    {
      starNum: 4.5,
      reviewNum: 10,
      Img1:"./assets/img/testimonialImg.jpg",
      Img2:"./assets/img/testimonialImg2.jpg",
      Img3:"./assets/img/testimonialImg3.jpg",
      Img4:"./assets/img/testimonialImg.jpg"
    },
   
  ];


  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0); 
  const [scrollablePopupOpen, setScrollablePopupOpen] = useState(false);



  const [thirdLayerPopupOpen, setThirdLayerPopupOpen] = useState(false);
  const [selectedSecondLayerImageIndex, setSelectedSecondLayerImageIndex] = useState(null);


  const handleImageClick = (imageSrc, index) => {
    setSelectedImage(imageSrc);
    setSelectedImageIndex(index); // Add this line
    setScrollablePopupOpen(true);
    document.body.style.overflowY = "hidden";
    document.body.classList.add("popup-open");
  };

  const handleSecondLayerImageClick = (index) => {
    setSelectedSecondLayerImageIndex(index);
    setScrollablePopupOpen(false); // Close the second-layer popup
    setThirdLayerPopupOpen(true); // Open the third-layer popup
    document.body.classList.add("popup-open");
    document.body.style.overflowY = "hidden";
  };

  const hasAddonWithUid1 = props.allData?.addons_details?.some(addon => addon.uid === 1);


  // Function to close the popup
  const closePopup = () => {
    setSelectedImage(null);
    setThirdLayerPopupOpen(false);
    document.body.classList.remove("popup-open");
    document.body.style.overflowY = "visible";
  };

  const onCloseSecondaLayer =()=>{
    document.body.style.overflowY = "visible";
    setScrollablePopupOpen(false)
  }


  
  const calculateGridTemplate = (numImages) => {
    if (numImages === 1) {
      return `1fr / 1fr`;
    } else if (numImages === 2) {
      return `1fr / 1fr 1fr`;
    } else if (numImages === 3) {
      return `1fr 1fr / 50% 50%`;
    }
    else if (numImages === 4) {
      return `1fr 1fr / 33.33% 33.33% 33.33%`;
    }
    else if (numImages === 5) {
      return `1fr 1fr / 33.33% 33.33% 33.33%`;
    }
    
  };
  
  const navigate = useNavigate();

  const navigateToPreviousPage = () => {
  
      navigate(-1);
    
  };

  
  const numImages = props.smallImg ? props.smallImg.length : 0;
  const gridTemplate = calculateGridTemplate(numImages);

  const styles = {
    display: 'grid',
    gridTemplate,
    height: '100%',
   
  };


  const hasDates = selectedCheckIn && selectedCheckOut;


  const toggleVisibleDocks = () => {
    setOpenPricingModal((prevVisible) => !prevVisible);
    setShowContactPop(false)
  };


const [showFullContent, setShowFullContent] = useState(false);
const [selectedEncodedRules, setSelectedEncodedRules] = useState('');
const [openPopupView, setOpenPopupView] = useState(false);


const toggleShowFullContent = (encodedRules) => {
  // setShowFullContent(!showFullContent);
  setSelectedEncodedRules(encodedRules);
  setOpenPopupView(!openPopupView);
};

const handleScroll = () => {
  const hostTopFixedDiv = document.querySelector('.top-fixed-div-back-share');
  if (hostTopFixedDiv) {
    if (window.scrollY > 0) {
      hostTopFixedDiv.classList.add('scrolled');
    } else {
      hostTopFixedDiv.classList.remove('scrolled');
    }
  }
};

window.addEventListener('scroll', handleScroll);



  ////////////////////////////////////////////////////////////////

  console.log(props.allData?.latitude,props.allData?.longitude,'checkpropsAll')
console.log(props.propertyId && !isSmallScreen ,'checkStatusOfscreen')
  
  // console.log(staticMapURL,'checkUrlS')

  //conatct host function api below----------------
  const [showContactPop,setShowContactPop] = useState(false)

  const ContactHostFunc = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const checkin = queryParams.get('checkin');
    const checkout = queryParams.get('checkout');
    let adults = queryParams.get('adults');
  
    adults = adults || 1;
  
    if (checkin && checkout && adults) {
      const apiUrl = 'create_chat';
  
      // Construct the payload
      const payload = {
        property_id: props.propertyId,
        message_type: 'initial',
        message: '',
        checkin_date: checkin,
        checkout_date: checkout,
        guests: parseInt(adults, 10), // Convert to integer
        // Add any other parameters you need for the POST request
      };
  
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      axios.post(apiMainUser + apiUrl, payload,{ headers })
        .then(response => {
          if(response.data.status == 'success'){ 
            // navigate("/user-message");
            console.log('true')
          }  
        })
        .catch(error => {
          // Handle errors
          console.error('Error during API call:', error);
        });
    } else {
      setShowContactPop(true);
    }
  }


  //view  and Url api  call here using useeffect---------------------
  
  const [propId,setPropId] = useState()
  const [propUrl,setPropUrl] = useState()

  
  const currentUrl = useLocation()


  console.log(props.propertyId,'checkPlz')

  useEffect(()=>{
  setPropId(props.propertyId)
  setPropUrl(currentUrl.pathname)
  },[props.propertyId])


  function hmacSha256Hex(secret , message ) {
    let hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret);
    hmac.update(message);
    return CryptoJS.enc.Hex.stringify(hmac.finalize());
}

const handleSubmit = async (e) => {
    e.preventDefault();
    let timestamp = Date.now().toString();
    let dataString = JSON.stringify({
      property_id: propId,
      timestamp: timestamp      
    });
    const options = {
      method: 'POST',
      url:  apiPublicUser + 'property_visitor',
      headers: {
        // Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
        'X-jumbo-secret': hmacSha256Hex('mysecrettoken',dataString),
      },
      data: dataString,
    };

    try {
      const { data } = await axios.request(options);
      console.log(data);
    } catch (error) {
      console.error(error);
    }


  };

  useEffect(() => {
    if(propId !== undefined){

      handleSubmit({ preventDefault: () => {} }); // Call the API using the existing submit function
      handleSubmit().catch((error) => console.error(error,"lukmaaa"));
    }
   
  }, [propId]); 


  console.log(currentUrl.pathname,'checkUrl')

const handleSubmit2 = async (e) => {

  console.log(propUrl,'checkPropUrl')
    e.preventDefault();
    let timestamp = Date.now().toString();
    let dataString = JSON.stringify({
      property_id: propUrl,
      timestamp: timestamp      
    });
    const options = {
      method: 'POST',
      url:  apiPublicUser + 'url_visit_count',
      headers: {
        // Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
        'X-jumbo-secret': hmacSha256Hex(process.env.SECRET_TOKEN,dataString),
      },
      data: dataString,
    };

    try {
      const { data } = await axios.request(options);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(propUrl){

      handleSubmit2({ preventDefault: () => {} }); // Call the API using the existing submit function
      handleSubmit2().catch((error) => console.error(error,"lukmaaa"));
    }
   
  }, [propUrl]); 


console.log(props.guestImgData,props.coHostImg,'checkOnceImg')
  


  return (
    <>      
    <div className="user-property-details-page">
      

   {isSmallScreen && (
  <div className="top-fixed-div-back-share">
    <div className="back-btn-parent"> 
    <FontAwesomeIcon icon={faArrowLeft} className="back-icon" size="2x"
    onClick={navigateToPreviousPage}/>
 

   </div>
     <div className="trending-property-share-wishlist-box">
            <RWebShare
            
              data={{
                url: props.slug,
                title: props.propertyName,
                text: 'Check out this amazing property!',
                image: props.mainImg, 
              
              }}
              sites="facebook,twitter,whatsapp,linkedin,mail,copy"
            >
              {/* <UserShareBtn btnText="Share" />s */}
              
             
              {/* <div className="wishlist-white-icon-parent">
              <FontAwesomeIcon icon={faArrowUpFromBracket} className=" mob-share-icon"/>
              </div>   */}
              <IconCircle 
              icon={faArrowUpFromBracket}
              />
            </RWebShare>
            
            <div className="wishlist-white-icon-parent">
            <WishListIcon  
              // WishListpropID={props.propertyId}
              id={props?.propertyId}
              wishListApplied={props?.allData?.wishlist}
           />
           </div>
          </div>

    </div>
      )}


       
      <div className="property-detail-box-nam-images">
      <div className="property-details-heading-box">
        <div className="property-details-name-box">
        {isSmallScreen && 
        <p className="font-small mt-5 mb-5">{props?.allData?.space_type}</p>
        }  
          
      {props.propertyName !== undefined && props.propertyName !== null  ? <h2>{props.propertyName}</h2>  : <SkeletonLoader width={100} height={20} inline={true}/> } 
        
          <div className="user-property-review-box" >
            {reviewText?.length !== 0 && reviewText &&  
            <span style={{gap:'0.7rem'}} className="flex align-items-center" onClick={handleScrollToReviews}>
            { reviewAll ? 
            <span style={{cursor:'pointer'}}>
              <span className="prop-detail-span-num">{Number.parseFloat(reviewAll.total_rating?.Overall_Average)?.toFixed(1) }</span>
              <img style={{width:'14px'}} src="./assets/img/colorStarIcon.svg" alt="" />
            </span>
            : <SkeletonLoader width={50} height={20}/>
            }
            <FontAwesomeIcon icon={faCircle} className="dividerdot"/>
          
            { reviewAll ? 
            <span style={{cursor:'pointer'}} className="prop-detail-span-num underline">
              {reviewText?.length} {reviewText?.length > 1 ? ' Reviews' : ' Review'}
            </span>
            : 
            <SkeletonLoader width={50} height={20}/>
}
       
   </span>
        }

  {isSmallScreen &&  
    <>
   <span>
     
   <span style={{color:'red',fontSize:'20px'}} className="material-icons font-large">location_on</span>
    </span>
   
     {props.city !== undefined && props.city !== null ? (
       <span className="prop-detail-span-num ">{props.city},</span>
     ) : (
       <SkeletonLoader width={70} height={20} inline={true} />
     )}

    {props.state !== undefined && props.city !== null ? (
      <span  className="prop-detail-span-num"> {props.state}</span>
    ) : (
      <SkeletonLoader width={70} height={20} inline={true} />
      )} 

      
    </>
  }


 
          </div>
        </div>
        {/* <WishListIcon /> */}
        {!isSmallScreen && 
        <div className="trending-property-share-wishlist-box">
            {/* <UserShareBtn onClick={shareFunc} btnText="Share" /> */}
            <RWebShare
             data={{
              url: props.slug,
              title: props.propertyName,
              text: 'Check out this amazing property!',
              image: props.mainImg, // Replace with the actual image URL
             }}
              sites="facebook,twitter,whatsapp,linkedin,mail,copy"
            >
              {/* <button>bdnnd</button> */}
              
              <Button 
                as="div" 
                btnclasses="flex share-icon-div pd-px-0" 
                faicon={
                <FontAwesomeIcon icon={faArrowUpFromBracket} className="share-icon"/>
                }
                span="Share"
              />
       

              
            </RWebShare>
            <FontAwesomeIcon icon={faCircle} className="dividerdot"/>

            <WishListIcon
               id={props?.propertyId}
               wishListApplied={props?.allData?.wishlist}
            />
          </div>
}
      </div>
      
    
   <div className="user-property-img-box">
  {props.smallImg !== undefined && props.smallImg !== null ? (
    !isMediumScreen ? (
      <div style={styles} className="user-property-small-img-box">
        {props.smallImg &&
          props.smallImg.slice(0, 5).map((imageSrc, index) => (
            <div
              onClick={() => handleImageClick(props.base_urlImg + imageSrc, index)}
              key={index}
              className="image-wrapper"
              style={
                index === 0
                  ? {
                      gridColumn: '1 / span 1',
                      gridRow: '1 / span 2',
                      width: '100%',
                      height: '100%',
                    }
                  : index === 1
                  ? { gridColumn: '2', gridRow: '1', width: '100%', height: '100%' }
                  : index === 2
                  ? { gridColumn: '2', gridRow: '2', width: '100%', height: '100%' }
                  : index === 3
                  ? { gridColumn: '3', gridRow: '1', width: '100%', height: '100%' }
                  : { gridColumn: '3', gridRow: '2', width: '100%', height: '100%' }
              }
            >
              <img
                src={props.base_urlImg + imageSrc}
                alt=""
              />
              {index === 4 && (
                <div className="overlay-text">
                  View All {props.smallImg.length} images
                </div>
              )}
            </div>
          ))}
      </div>
    ) : (
      <div className="mobile-view-image-div">
      <Swiper spaceBetween={10} slidesPerView={1}>
        {props.smallImg &&
          props.smallImg.map((imageSrc, index) => (
            <SwiperSlide key={index}>
              <div
                onClick={() => handleImageClick(props.base_urlImg + imageSrc, index)}
                className="image-wrapper"
              >
                <img
                  src={props.base_urlImg + imageSrc}
                  alt=""
                />
                <p className="total-images-length">{index + 1}/{props.smallImg.length}</p>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
      </div>
    )
  ) : (
    <SkeletonLoader width={'100%'} height={'100%'}/>
  )}
</div>



    </div>

    
    
    {/* second Layer */}
    {scrollablePopupOpen && (
        <ImageGalleryPopup
          images={props.smallImg}
          base_urlImg={props.base_urlImg}
          initialIndex={selectedImageIndex}
          
          onClose={onCloseSecondaLayer}
          onImageClick={handleSecondLayerImageClick} // Pass the handler 
        />
      )}


        {/*  Third Layer-----------------------*/}


    {/* {selectedImage && ( */}
    {thirdLayerPopupOpen && selectedSecondLayerImageIndex !== null && (
        <div className="popup">
          {/* <span className="popup-close" onClick={closePopup}>
            &times;
          </span> */}
          <div className='popup-close-secod-layer-parent'>
        <span className="popup-close-secod-layer" onClick={closePopup}>
        <FontAwesomeIcon icon={faXmark}/>
      </span>
      </div>
          <Swiper

            spaceBetween={10}
            slidesPerView={1}
            navigation={!isSmallScreen ? true : false}
            virtual
            // initialSlide={selectedImageIndex}
            initialSlide={selectedSecondLayerImageIndex}
            // initialSlide={
            
            // }
          >
         
            {props.smallImg?.map((imageSrc, index) => (
              <SwiperSlide className="third-layer-img-class" key={index}>
                <img src={props.base_urlImg + imageSrc} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    


      {/* Image Popup */}
      {/* Hosted By {props.hostDetails?.fname + " " + props.hostDetails?.lname} */}
     
      {/* ----------------------------------------------------------------------- */}

     
   <div className="flex-container flex justify-space-between mt-5">
      <div className="check mb-5">
      {/* {!isSmallScreen ? ( */}
      {!isSmallScreen && 
        <div className="property-host-box">
          <div className="property-host-box-left-content">
          {props.hostDetails !== undefined ? (
            <div className="prop-host-name">
  {!isSmallScreen && (
    <>
      <span>
        <img style={{ width: '20px', marginRight: '5px' }} src="./assets/img/placeIcon.png" alt="" />
      </span>
      {props?.allData?.space_type} 
      <FontAwesomeIcon icon={faCircle} className="dividerdot"/>
      <span>
        <span style={{ color: 'red', fontSize: '20px' }} className="material-icons font-large">location_on</span>
      </span>
      {props.city !== undefined && props.city !== null ? (
        <span className="prop-detail-span-num">{props.city},</span>
      ) : (
        <SkeletonLoader width={70} height={20} inline={true} />
      )}
      {props.state !== undefined && props.city !== null ? (
        <span className="prop-detail-span-num"> {props.state}</span>
      ) : (
        <SkeletonLoader width={70} height={20} inline={true} />
      )}
    </>
  )}
</div>

) : (
  <SkeletonLoader width={100} height={20} inline={true}/>
)}
 
          </div>          
        </div>
        }



 {!isSmallScreen ? 
    <div className="property-details-of-room-box">
      {props.numGuest !== undefined && props.numGuest !== null ? <p>{props.numGuest} {props.numGuest > 1 ? <span>Guests</span> : <span>Guest</span>}</p> : <SkeletonLoader width={50} height={20} inline={true}/>}        
         <FontAwesomeIcon icon={faCircle} className="dividerdot"/>
      {props.numBedrooms !== undefined && props.numBedrooms !== null ? <p>{props.numBedrooms} {props.numBedrooms > 1 ? <span>Bedrooms</span> : <span>Bed</span>}</p> : <SkeletonLoader width={50} height={20} inline={true}/>}       
         <FontAwesomeIcon icon={faCircle} className="dividerdot"/>
      {props.numBeds !== undefined && props.numBeds !==null ? <p>{props.numBeds} {props.numBeds > 1 ? <span>Beds</span> : <span>Bed</span>}</p>  : <SkeletonLoader width={50} height={20} inline={true}/>}       
        <FontAwesomeIcon icon={faCircle} className="dividerdot"/>
     {props.numBathroom !== undefined && props.numBathroom !== null ? <p>{props.numBathroom} {props.numBathroom > 1 ? <span>Baths</span> : <span>Bath</span>}</p> :  <SkeletonLoader width={50} height={20} inline={true}/>}         
    </div>


:
      <PropertyDetailsBox
          guestNum={props.numGuest}

          bedroomNum={props.numBedrooms}
          bedsNum={props.numBeds}
        bathroomNum={props.numBathroom}
          someProp={true}

          //props of this component is pass where trending property is called
        />

 }
 </div>
 
 <div className="verified-host-div">
  <div>
 <GuestProfile
        profileName={props.hostDetails !== undefined  ?  `${props.hostDetails?.fname+" "+props.hostDetails?.lname}` : <SkeletonLoader width={70} height={20} inline={true}
        />}
        whosProfile={props.hostDetails !== undefined  ? 'Host' : <SkeletonLoader width={50} height={20} inline={true}/>}
        
        profileImage={props.guestImgData}
    
    />
     {props.allData?.host_details[0]?.rewards_alloted[0]?.rewards_details.length > 0 &&
        <RewardsReviewImages
        imageSources={props.allData?.host_details[0]?.rewards_alloted[0]?.rewards_details}
        />
        }
    </div>
     <span className="material-icons-parent"> <span className="material-icons">verified</span><span className="jumbo-verified-text">Jumbo Verified</span></span> 
    </div>

    </div>


 <LineDiv/>

      {/* ---------------------------------------------------------------------------- */}

      <div className="user-property-details-info-box">
        <div className="user-property-details-infor-left-box">
          <div className="user-property-details-feature-box">
            {/* <PropertyDetailFeature
              src="./assets/img/workspaceIcon.svg"
              detailHeading="Dedicated workspace"
              oneLiner="A common area with wifi that’s well suited for working."
            /> */}
            { hasAddonWithUid1   &&   <PropertyDetailFeature
              src="./assets/img/petIcon.svg"
              detailHeading="Paws Welcome Here"
              oneLiner="Bring your pets along for the stay."
            />
}
          
          </div>
          {/*  */}


    {encodedAbout !== undefined && encodedAbout !== null  && encodedAbout !== 'undefined' ?
      <div className="ckeditor-container">
          <PropertyDetailParagraph
            h4="About The Space"
            // spacePara= {props.about}
            subPara={encodedAbout}
            className='about-para-endode'

          />
          </div>
          : 
          <div className="property-detail-paragraph">
          <h4>About The Space</h4>
          <SkeletonLoader  width={100} height={20}/>
          </div>
    }

<LineDiv/>


   
      {props. amenities_details !== undefined  && 
        <div>
         <h4 className="property-Detail-para-heading">What this Place offers to you</h4>
        {props. amenities_details !== undefined ?  
          <PropertyAmenitiesPop 
            amenities={props.amenities_details ? props.amenities_details : []}
          />
          : <SkeletonLoader width= {200} count={4} height={20}/>
}
      
</div>
}
<LineDiv/>

{props.allData?.latitude !== undefined && props.allData?.latitude !== null &&
 props.allData?.longitude !== undefined && props.allData?.longitude !== null ? (
  <>
    <h4 className="property-Detail-para-heading">Where you’ll be</h4>
    <div className="reserve-card">
      <StaticGoogleMap
        size="650 x 350"
        className="img-fluid"
        apiKey="AIzaSyD2p6Azx2i_PY1RbQhin7TqLyijMt3ykT8"
      >
        <Marker
          location={`${props.allData.latitude},${props.allData.longitude}`}
          // color="blue"
          color="19A6BB"

          label="P"
        />
      </StaticGoogleMap>
    </div>
    <p
      className="host-about-para endode-para"
      dangerouslySetInnerHTML={{ __html: props?.allData?.location_description }}
    ></p>
      <LineDiv/>
  </>
) : ''}

 </div>

        
  
        {props.propertyId && !isSmallScreen && (
        <PropertyPricing_calculated
          propertyId={props.propertyId}
          maxGuests={props.allData.max_guests}
          perNightPrice={Number.parseFloat(props.allData.base_price)?.toFixed(2)}
        />
      )}

      
      </div>

      
      {/* <UserPropReviewPage /> */}
     
      <div className="user-property-details-info-box  w-100">
        <div className="w-100">

        {reviewAll && reviewText.length > 0 ? (
          <>
          <div>

      {reviewText?.length !== 0 && reviewText &&  
        <div>
        
      <h1 className="host-review-heading">Property Reviews</h1>
      {/* {reviews.map((review, index) => ( */}
        <ReviewWithImages
        imageSources={profileImages ?  profileImages : undefined} 
        starNum={reviewAll ?  (Number.parseFloat(reviewAll.total_rating?.Overall_Average)?.toFixed(1)) : undefined}
        reviewNum={reviewText ?  reviewText?.length : undefined}
        />
      {/* ))} */}
    </div>
}
{/* <LineDiv/> */}


<div ref={reviewMainParentRef} className="review-main-parent-div">
  {reviewText?.length !== 0 && reviewText ? (
    <Swiper
      spaceBetween={10}
      slidesPerView={isSmallScreen ? 1 : 3} // Set the number of slides per view to 3
      navigation
    
      pagination={{
        dynamicBullets: true,
      }}
      modules={[Pagination]}
   
    >
      {reviewText.map((review, index) => (
        <SwiperSlide key={review.uid}>
          <UserReviewCard
            rating={review.average_rating}
            userReviewText={review.review}
            profileName={`${review.users_first_name} ${review.users_last_name}`}
            profileImage={assetsurl + review.users_profile_image}
            whoseProfile={moment(review.createdon).format("D MMM YYYY")}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  ) : (
    <SkeletonLoader width={300} height={100} />
  )}
</div>

        </div>
   <LineDiv/>
   </>
        ) 
        : ""}



    
        <div className="host-language-card-div flex" >
          <div className="host-cost-parent-div">
       
        <h4 className="property-Detail-para-heading">About The Host {props.CohostDetails?.fname && "& Co-Host"}</h4>
        <Link 
        
        to={`/users/${hostUserName}`}
        
        >
        
         <GuestProfile
        profileName={props.hostDetails !== undefined  ?  `${props.hostDetails?.fname+" "+props.hostDetails?.lname}` : <SkeletonLoader width={100} height={20} inline={true}/>}
        whosProfile='Host'
        profileImage={props.guestImgData}
        
        />
        </Link>
        {props.allData?.host_details[0]?.rewards_alloted[0]?.rewards_details.length > 0 &&
        <RewardsReviewImages
        imageSources={props.allData?.host_details[0]?.rewards_alloted[0]?.rewards_details}
        />
        }
        <p className="host-about-para endode-para"  dangerouslySetInnerHTML={{ __html: props.hostDetails?.about_me}}></p>
       
   {props.CohostDetails?.fname ? 
   <div className="mt-20">
        <Link  to={`/users/${coHostUserName}`}>

        <GuestProfile
        profileName={props.hostDetails !== undefined  ?  `${props.CohostDetails?.fname+" "+props.CohostDetails?.lname}` : <SkeletonLoader width={100} height={20} inline={true}/>}
        whosProfile='Co-Host'
        profileImage={props.coHostImg}
    />
    </Link>

     {props.allData?.cohost_details[0]?.rewards_alloted[0]?.rewards_details.length > 0 &&
        <RewardsReviewImages
        imageSources={props.allData?.cohost_details[0]?.rewards_alloted[0]?.rewards_details}
        />
        }
        <p className="host-about-para endode-para"  dangerouslySetInnerHTML={{ __html: props.CohostDetails?.about_me}}></p>
        </div>
   : ''}
   </div>


     <div className="desktop-langiage-card">
       <LanguageContactHostCard
            languages= {props.hostDetails?.languages}
            responseRate ='100%'
            responseTime='with in hour'
            profileName={props.hostDetails?.fname+" "+props.hostDetails?.lname}
            profileSub={props.hostDetails?.fname+" "+props.hostDetails?.lname}
            profilePic={props.hostDetails?.image}
            onClick={ContactHostFunc}
            />
      </div>
   </div>



     {/* {isSmallScreen && 
        <LanguageContactHostCard
        languages= {props.hostDetails?.languages}
        responseRate ='100%'
        responseTime='with in hour'
        profileName={props.hostDetails?.fname+" "+props.hostDetails?.lname}
        profileSub={props.hostDetails?.fname+" "+props.hostDetails?.lname}
        profilePic={props.hostDetails?.image}
        />
      } */}
      <LineDiv/>
     
     {/* use this code once the host review api is done------------------------------ */}
        {/* {hostReviewText?.length !== 0 && 
        <div>
        
      <h1 className="host-review-heading">Host Reviews</h1>
     
        <ReviewWithImages
        imageSources={profileImages} 
        starNum={reviewAll?.total_rating?.Overall_Average}
        reviewNum={reviewText?.length}

         />
 
    </div>
} */}



          
{props.propertyId && isSmallScreen && 
     <UserMobileReserveComp 
      amount={Number.parseFloat(props.allData.base_price)?.toFixed(2)}
      selectedStartDate={selectedCheckIn}
      onClick={PricingPopupOpenFunc}
      selectedEndDate={selectedCheckOut}
      hasDates={hasDates}
      guestCount={guestCount}
      propertyId={props.propertyId}
      selectedCheckIn={selectedCheckIn}
      selectedCheckOut={selectedCheckOut}     
     />
    }


    <div style={{gap:'1rem'}} className="footer-flex-div flex justify-space-between ">
      
    <CardWrapper
      wrapperClass="w-100 rules-card-wrapper"
      wrapperHeadingClass="rules-heading-text"
      wrapperHeading='House Rules'
    >
  {encodedRules !== undefined && encodedRules !== null && encodedRules !== 'undefined' ? (
    <div className="ckeditor-container">
      <PropertyDetailParagraph
        subPara={showFullContent ? encodedRules : `${encodedRules.slice(0, 120)}...`}
        className="about-para-endode"
      />
      {encodedRules.length > 120 && !showFullContent && (
        <p onClick={() => toggleShowFullContent(encodedRules)} className="view-more-button ">
          View more
        </p>
      )}
    </div>
  ) : (
    <div className="property-detail-paragraph">
      <SkeletonLoader width={100} height={20} />
    </div>
  )}
</CardWrapper>

        <CardWrapper wrapperClass='w-100 rules-card-wrapper' wrapperHeadingClass='rules-heading-text'
        
         wrapperHeading="Rules & Policies"
         > 
        {/* <h4 className="property-Detail-para-heading">House Rules</h4> */}
       
          {/* <ListComponent listParent='Detail-rules-list-ul' data={listData} /> */}
          <ul  style={{ listStyle: 'none' }} className='Detail-rules-list-ul'>
          {listData.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
         </ul>
         </CardWrapper>
   

       <CancellationPolicyCard cancellationData={cancellationData}/>

      </div>

        </div>   
        
      </div>
    

      {/* <div className="mob-pricing-calucalated-popup-div">
        {openPricingModal && 
        
        <WrapperPopup
        closeModal={setOpenPricingModal}
        comp={
          <PropertyPricing_calculated
          propertyId={props.propertyId}
          maxGuests={props.allData.max_guests}
          perNightPrice={props.allData.base_price?.toFixed(2)}
          />
          
        }
         
        />} */}
         <div className='Mob-destination-guest-popup-div'>
          {openPricingModal && (
        <WrapperPopup 
        fluid={true}
        position='bottom'
        // dimMode={props.opaque ? props.opaque : false}
        defaultSize={"0.85"}
        DockFilter={true}
        dimStyle={false}
        // isVisible={openModalNav}
        isVisible={true}
        
        setVisibleDocks={toggleVisibleDocks} 
        closeModal={setOpenPricingModal} comp={<MobHeadDestinationGuest  
          blockDateState={blockDateState}
          blockCheckinDateState={blockCheckinDateState}
          minDateState={minDateState}
          maxDateState={maxDateState}
          blockCheckoutDateState={blockCheckoutDateState}
          successdata={successdata}
          propertyId={propertyId}
          isSave={true} 
          closeModal={setOpenPricingModal}
          updateUrl={false}
          parseQueryParamsAll={parseQueryParamsAll}
          max_guests={props.allData.max_guests}

          />} />
      )}
      </div>



      <div className="">
        {openPopupView && (
        <WrapperPopup
        luid={true}
        position='bottom'
       
        defaultSize={"0.85"}
        dimStyle={false}
        headingText='House Rules'
        isVisible={true}
          setVisibleDocks={() => toggleShowFullContent(selectedEncodedRules)}
          closeModal={() => setOpenPopupView(!openPopupView)}
          comp={
            <div className="view-more-footer-popup ">
              <p className="encoded-parent-para" dangerouslySetInnerHTML={{ __html: decodeURIComponent(selectedEncodedRules) }}></p> 
            </div>
          }
        />
      )}
      </div>


      <div className='Mob-destination-guest-popup-div'>
          {showContactPop && (
        <WrapperPopup
        fluid={true}
        position='bottom'
        DockFilter={true}
        // dimMode={props.opaque ? props.opaque : false}
        defaultSize={"0.90"}
        dimStyle={false}
        // isVisible={openModalNav}
        isVisible={true}
        
        setVisibleDocks={toggleVisibleDocks} 
        closeModal={setShowContactPop} comp={<ContactDestionationPop
          propertyId={propertyId}
            closeModal={setShowContactPop}
            />} />
      )}
      </div>


      

      {/* </div> */}
      </div>
      </>

  );
};

export default UserPropertyDetailsIn;
