import React, { useContext, useEffect, useState } from "react";
import CardWrapper from "../../../Components/common/cardWrapper";
import DataContext from "../../../utils/dataContext";
import APIHit from "../../../utils/apiHit";
import { apiMainUser } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRoomData } from "../../../utils/redux/listingTypeSlice";
import { handleNavigation } from "../../../Components/common/navigateFunc";
import Save_ExitComp from "../../../Components/common/save_ExitComp";
import { useSelector } from "react-redux";

const RoomType = () => {
  const [selectedUid, setSelectedUid] = useState(null);
  // const [spaceData, setSpaceData] = useState([]);
  const [finalRoomData, setFinalRoomData] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const storeSel = useSelector((state) => state);

  const Currenturl = window.location.href;
  console.log(Currenturl, "tyt");
  const CurrentPage = new URL(Currenturl);
  const path = CurrentPage.pathname;
  console.log(path, "uuu");
  const storeSel = useSelector((state) => state);

  const [storedReduxData, setStoredReduxData] = useState(null);
  const [storeReduxPropId, setStoreReduxPropId] = useState(null);

  useEffect(() => {
    const data = storeSel.listingDataSlie?.roomFinalData?.metadata_value;
    const PropIddata = storeSel.apiDataSlice?.propId;

    console.log(data, "yoga");
    setStoredReduxData(data);
    setStoreReduxPropId(PropIddata);
  }, []);

  useEffect(() => {
    if (selectedUid !== null) {
      console.log(selectedUid, "ooo");
      let finaldata = {
        // property_id: propertyId,
        metadata_key: "space_type",
        metadata_value: selectedUid,
      };

      dispatch(setRoomData(finaldata));
    }
  }, [selectedUid, dispatch]);

  const getdata = (uid) => {
    setStoredReduxData(null);
    setSelectedUid(uid);
  };

  const navigateTo = () => {
    handleNavigation(
      selectedUid,
      storedReduxData,
      "/host/createlisting/confirmAddress",
      navigate
    );
  };

  return (
    <div className="create-list-room-type create-list-property-type">
      <div className="create-list-room-type-content  create-list-property-type-content">
        <h2>
          Describing Your Guest Stay: Let's Choose The Type Of Place Your
          Offering!
        </h2>
        <div className="create-list-roomtype-flex-box ">
          <CardWrapper
            onClick={() => getdata("Entire Place")}
            wrapperClass={`create-list-room-type-card ${
              selectedUid === "Entire Place" ||
              storedReduxData === "Entire Place"
                ? "selected"
                : ""
            }`}
          >
            <p className="title-text">Entire Place</p>
            <p>Guests will have the entire place to themselves.</p>
          </CardWrapper>
          <CardWrapper
            onClick={() => getdata("A Private Room")}
            wrapperClass={`create-list-room-type-card ${
              selectedUid === "A Private Room" ||
              storedReduxData === "A Private Room"
                ? "selected"
                : ""
            }`}
          >
            <p className="title-text">A Private Room</p>
            <p>
              Guests will sleep in private room but some areas will be shared
              with others.
            </p>
          </CardWrapper>
          <CardWrapper
            onClick={() => getdata("A Shared Room")}
            wrapperClass={`create-list-room-type-card ${
              selectedUid === "A Shared Room" || storedReduxData === "A Shared Room"
                ? "selected"
                : ""
            }`}
          >
            <p className="title-text">A Shared Room</p>
            <p>
              Guests will sleep in common room and some areas will be shared
              with others.
            </p>
          </CardWrapper>
        </div>
      </div>
      {/* <APIHit
        url={apiMainUser + "create_property_metadata"}
        method="post"
        onSuccess={handleSuccess2}
        onFailure={handleFailure2}
        apidata={spaceData}
        // precondition={runPrecondition2}
        successResponseCode={201}
        // directCall={true}
      > */}
      <div className="bottom-btns-fixed-box">
        <Save_ExitComp
          path={path}
          // apis={["createProperty_metadata_bulk"]}
          apis={[
            "updateProperty/" + storeReduxPropId,
            "createProperty_metadata_bulk",
          ]}
        />
        <button onClick={navigateTo} type="submit" className="common-next-btn">
          Next
        </button>
      </div>
      {/* </APIHit> */}
    </div>
  );
};
export default RoomType;
