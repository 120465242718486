import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'   
const SkeletonLoader = (props) => {
    return (
        // <div>

          <Skeleton 
          width={props.width} 
          circle={props.circle ? props.circle : false}
          height={props.height}
          inline={props.inline  ? props.inline : false}
          count={props.count}
          baseColor={props.baseColor ? props.baseColor : '#ebebeb'}
          highlightColor={props.highlightColor ? props.highlightColor : '#f7f7f7'}
          borderRadius={props.borderRadius ? props.borderRadius : '0.25'}
          duration={props.duration ? props.duration : 1.5}
          direction={props.direction ? props.direction : "ltr"}
          enableAnimation={true}
          containerClassName={props.containerClassName ? props.containerClassName  : ""}
           />
        // </div>
      );
}

export default SkeletonLoader
