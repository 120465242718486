import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import DateRangeDisplay from '../../Components/common/DateRangeDisplay';
import { faCircle, faDotCircle, faDroplet, faListDots } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTotalGuestCount } from '../../utils/varResults';

const MobHeadNav = (props) => {

  
  const location = window.location;
  const queryParamsGet = new URLSearchParams(location.search);
  const [guestCount,setGuestCount]= useState('')

  const [selctedCheckinDate,setSelectedCheckinDate]= useState(null)
  const [selctedCheckoutDate,setSelectedCheckoutDate]= useState(null)


  const getQueryParamValue = (paramName) => {
    return queryParamsGet.get(paramName) || null;
  };


  
  useEffect(() => {
    const checkin = getQueryParamValue('checkin');
    const checkout = getQueryParamValue('checkout');
    const adults = getQueryParamValue('adults');
    const infants = getQueryParamValue('infants');
    const children = getQueryParamValue('children');
   

    if(adults || children){
      console.log(adults,children,'guests')
      
      var guestlist = getTotalGuestCount(adults,children)
      setGuestCount(guestlist)
      
    }else{
      setGuestCount(null)
    }
    if(checkin){
      setSelectedCheckinDate(checkin)
    }
    if(checkout){
      setSelectedCheckoutDate(checkout)
    }

  
  }, [location.search]);

  console.log(guestCount,'guest')


  return (
    <div className={location.pathname == "/properties" ? 'mob-head-parent background-fff' :'mob-head-parent'}>
     <div className={`mob-head-nav ${props.position}-position  flex justify-space-between `}>
      <div  
        onClick={props.onClickSearch ? props.onClickSearch : ''}
        className='mob-head-nav-search-destination-parent'
      >  
    <div className="search-icon-img-box">
     <img src="./assets/img/mobSearchFilter.svg" alt="" />   
    </div>
        <div className='mob-destination-date-box'>
        <p className='mob-destination-date-title font-xsm '>Search</p>
      <p className='mob-destination-date-sub-title'> 
      {selctedCheckinDate !== null  && selctedCheckinDate !== null ? 
      <>
      <DateRangeDisplay  
      startDate={selctedCheckinDate}
      endDate={selctedCheckoutDate}
      />
      </> : <span className='font-xsm '>Any week</span>
      }

         <span >
         <FontAwesomeIcon  icon={faCircle} className="head-nav-dot"  />

         </span>
      
         {guestCount !== null && guestCount !== undefined ? 
         <>
         <span>{guestCount} {guestCount === 1 ? 'Guest' : 'Guests'}</span>
         </>: <span className='font-xsm '>Add guest</span>
         }
        </p>

        </div>
        </div>

        {props.filterRequired &&
        <div  
        style={{cursor:'pointer'}}
        onClick={props.onClickFilter ? props.onClickFilter : ''}
        className='mob-head-nav-filter-btn '> 
        {/* <img src="./assets/img/MobFilterIcon.svg" alt="" /> */}
        <span class="material-symbols-outlined">
            tune
           </span>
        </div>

        }
          </div>
          </div>
        )
      }

      export default MobHeadNav
