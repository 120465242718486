import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

import "swiper/css";
import SwiperCore, { Navigation } from "swiper";
import { formatIndianRupeesFunc } from "../../utils/constant";

SwiperCore.use([Navigation]);
const ExpSlider = (props) => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      navigation
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
    >
      <SwiperSlide className="swiper-edit">
        <div className="exp-slider-left-content">
          <h3>Explore the scenic trails of ooty</h3>
          <div className="exp-slider-duration-box">
            <p className="duration-text">Duration:{props.duration}</p>
            <p className="duration-text">Guest:{props.numGuest}</p>
          </div>
          <p className="one-line-text">
            Experience the beauty of nature in a new.....
          </p>
          <p className="view-detail-text">ViewDetails</p>
          <div className="exp-card-price-box">
            <p className="total-text">Total</p>
            <p className="price-text">₹{formatIndianRupeesFunc(props.price)}</p>
          </div>
        </div>
        <div className="exp-slider-exp-img-box">
          <div className="big-img-box">
            <img src="./assets/img/expSliderImg1.jpg" alt="" />
          </div>
          <div className="small-images-box">
            <img src="./assets/img/expSliderImg2.jpg" alt="" />
            <img src="./assets/img/expSliderImg3.jpg" alt="" />
            <img src="./assets/img/expSliderImg2.jpg" alt="" />
            <img src="./assets/img/expSliderImg5.jpg" alt="" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide className="swiper-edit">
        <div className="exp-slider-left-content">
          <h3>Skiing Adventure in Mountains</h3>
          <div className="exp-slider-duration-box">
            <p className="duration-text">Duration:{props.duration}</p>
            <p className="duration-text">Guest:{props.numGuest}</p>
          </div>
          <p className="one-line-text">
            Join Us for a thrilling and exciting days.....
          </p>
          <p className="view-detail-text">ViewDetails</p>
          <div className="exp-card-price-box">
            <p className="total-text">Total</p>            
            <p className="price-text">₹{formatIndianRupeesFunc(props.price)}</p>
          </div>
        </div>
        <div className="exp-slider-exp-img-box">
          <div className="big-img-box">
            <img src="./assets/img/expSliderImg2.jpg" alt="" />
          </div>
          <div className="small-images-box">
            <img src="./assets/img/expSliderImg1.jpg" alt="" />
            <img src="./assets/img/expSliderImg5.jpg" alt="" />
            <img src="./assets/img/expSliderImg1.jpg" alt="" />
            <img src="./assets/img/expSliderImg3.jpg" alt="" />
          </div>
        </div>
      </SwiperSlide>{" "}
    </Swiper>
  );
};

export default ExpSlider;
