
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import {  faHome, faInbox, faKey, faLocation, faSearch, faSuitcase, faSuitcaseRolling, faTree, faUndo, faUser } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { faCalendar, faHeart, faListAlt } from '@fortawesome/free-regular-svg-icons';
import { switchCase } from '@babel/types';
const UserBottomBar = (props) => {

  const storeSel = useSelector((state) => state);
    const [value, setValue] = useState();
    const navigate = useNavigate()
    const location = useLocation();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);    
    switch(newValue){
      case 'home':
        navigate('/')
        break;
      case 'explore':
        navigate('/properties');
        break;
      case 'trips':
        navigate('/trip');
        break;

        case 'user-message':
          navigate('/user-message');
          break;
      case 'profile':
        navigate('/profile');
        break;
      case 'host-bookings':
        navigate('/host')
        break;
      case 'host-calendar':
        navigate('/host/calendar');
        break;
      case 'host-listings':
        navigate('/host/listing');
        break;
        case 'host-message':
          navigate('/host/message');
          break;
      case 'host-profile':
        navigate('/host/profile');
        break;
      default:
        navigate('/');
        break;
    }
  };

  useEffect(()=>{
    var mylocation = location.pathname;
    switch(mylocation){
       case '/' || '/home':
        setValue('home');
        break;
       case '/trip':
        setValue('trips');
        break;
        case '/user-message':
          setValue('user-message');
          break;
        case '/properties':
        setValue('explore');
        break;
        case '/profile':
        setValue('profile');
        break;
        case '/host':
        setValue('host-bookings');
        break;
      case '/host/listing':
        setValue('host-listings');
        break;
      case '/host/calendar':
        setValue('host-calendar');
        break;

        case '/host/message':
        setValue('host-message');
        break;

      case '/host/profile':
        setValue('host-profile');
        break;
        
      default:
        setValue('home');
        break;
    }
  },[])

    const profileImage = storeSel.hostDetailSlice?.Image;
    const hasProfileImage = profileImage !== undefined && profileImage !== 'undefined' && profileImage !== '' && profileImage !== null && profileImage !== "null";
    console.log(hasProfileImage,'profile')
    console.log(profileImage,'profile')

    // console.log(profileImage)

    console.log(profileImage,'profile')

    // const isHome = location.pathname === '/' || location.pathname === '/properties' || location.pathname === '/trip';
    const isHost = location.pathname.includes('/host');
    
    return (    
      <div className="bottom-bar">
      {
        !isHost ?      
          <BottomNavigation
            showLabels
            value={value}
            onChange={handleChange}
          >         
            <BottomNavigationAction
              label="Home"
              value="home"
              icon={<FontAwesomeIcon icon={faHome}/>}
            />  
            <BottomNavigationAction
              label="Explore"
              value="explore"
              icon={<FontAwesomeIcon icon={faSearch}/>}
            />   

             
              <BottomNavigationAction
              label="Inbox"
              value="user-message"
              icon={<FontAwesomeIcon icon={faInbox}/>}
            />

            <BottomNavigationAction
              label="Trips"
              value="trips"
              icon={<FontAwesomeIcon icon={faSuitcaseRolling} />}
            />
            <BottomNavigationAction
              label="Profile"
              value="profile"
              icon={<FontAwesomeIcon icon={faUser} />}
            />
          </BottomNavigation>
        : 
          <BottomNavigation
            showLabels
            value={value}
            onChange={handleChange}
          >         
            <BottomNavigationAction
              label="Bookings"
              value="host-bookings"
              icon={<FontAwesomeIcon icon={faKey}/>}
            />  


            <BottomNavigationAction
              label="Listings"
              value="host-listings"
              icon={<FontAwesomeIcon icon={faListAlt} />}
            />
            <BottomNavigationAction
              label="Calendar"
              value="host-calendar"
              icon={<FontAwesomeIcon icon={faCalendar}/>}
            />   

            <BottomNavigationAction
              label="Inbox"
              value="host-message"
              icon={<FontAwesomeIcon icon={faInbox}/>}
            />     
            <BottomNavigationAction
              label="Profile"
              value="host-profile"
              icon={<FontAwesomeIcon icon={faUser} />}
            />
          </BottomNavigation>
        }
  </div>
  )
}

export default UserBottomBar
