import React from "react";
import GuestAddCart from "../../../../Components/common/guestAddCart";
import EditCart from "../EditCart";
import Button from "../../../../Components/common/Button";
import HeadingComp from "../HeadingComp";
import AddToCart from "../../../../Components/common/addCarts";
import { useState } from "react";
import EditSaveBtn from "../EditSaveBtn";

const EditGuest = (props) => {
  const [cartData, setCartData] = useState({});
  const guestData = props.allData.max_guests;

  let url = "updatePropertyPref/" + props.preferencePropId;
  console.log(props.propId, "tulips");

  const handleUpdateGuests = (count) => {
    setCartData((prevData) => ({
      ...prevData,
      max_guests: count,
    }));
  };

  return (
    <>
    <div className="edit-guest-box " style={{marginTop:'2rem'}}>
     
      <AddToCart
        cartBtn="cartBtn"
        cartCount="cartCount"
        cartParent="cartParent"
        initialCount={guestData}
        
        increamentVal={handleUpdateGuests}
      />

      <p className="light-para-text">Maximum number of guests allowed</p>

     
    </div>
    <div className="clear_apply-btn-box paddingCustomYSide">
        <p onClick={() => props.closeModal(false)} className="clear-btn">
          Cancel
        </p>
        <EditSaveBtn
          url={url}
          method="patch"
          postData={cartData}
          fetchData={props.fetchData}
          closeModal={props.closeModal}
          message='Guest updated Successfully!'
          // btnText="Save"
          // btnclasses="save-edit-btn"
        />
      </div>
    </>
  );
};

export default EditGuest;
