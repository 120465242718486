import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const IconCircle = (props) => {
  return (
      <div  
      onClick={props.onClick}
       className={
        props.iconParentClass === undefined ? "wishlist-white-icon-parent " : "wishlist-white-icon-parent  " + props.iconParentClass 
      }
      >
        <FontAwesomeIcon icon={props.icon} 
            className={
             props.iconClass === undefined ? "mob-icon " : "mob-icon " + props.iconClass
            }
            
          />
      </div>   
  )
}

export default IconCircle
