import React from 'react'
import SkeletonLoader from '../Components/common/SkeletonLoader'
import { RWebShare } from 'react-web-share'
import UserShareBtn from '../Components/UserComponents/UserShareBtn'
import WishListIcon from '../Components/UserComponents/wishListIcon'
import CardWrapper from '../Components/common/cardWrapper'
import SkeletonPropertyDetailBox from './SkeletonPropertyDetailBox'
import { formatIndianRupeesFunc } from '../utils/constant'

const SkeletonTrendingProperty = (props) => {
  const isSmallScreen = window.innerWidth <= 500;

  return (
    <CardWrapper onClick ={props.onClick} wrapperClass="trending-propety-box">
    {/*  */}

    <div className="trending-property-img-box">
     
      {isSmallScreen ? (
        <div style={{width:'100%',height:'100%'}}>
        
        
        <SkeletonLoader width='100%' height='100%' count={1}/>
       
        </div>
      ) : (
        
      <SkeletonLoader width='100%' height='100%' count={1}/>
         
      )}
      {/* <SkeletonLoader width='100%' height='100%' count={1}/> */}
    </div>

    <div className="trending-property-text-box">
      <div className="trending-property-heading-box">
        <div className="trending-property-name">
          {
            isSmallScreen  ? 
            
                <SkeletonLoader width={100} height={20} count={1}/>
                : <SkeletonLoader width={250} height={20} count={1}/>
            
           
          }
          <p style={{ fontSize: "1.5rem", fontWeight: "600" }}>{props.location}</p>
        </div>
        <div className="trending-property-share-wishlist-box">
          {/* <UserShareBtn onClick={shareFunc} btnText="Share" /> */}
          <RWebShare
            data={{
              text: "",
              url: props.slug,
              title: props.property_name,
            }}
            sites="facebook,twitter,whatsapp,linkedin,mail,copy"
            onClick={() => console.log("shared successfully!")}
          >
            {/* <button>bdnnd</button> */}
            <UserShareBtn btnText="Share" />
          </RWebShare>      
         
          <WishListIcon />
        </div>
      </div>

      {/* / */}
      {/* <PropertyDetailsBox
        guestNum={props.maxguest}
        bedroomNum={props.rooms}
        bedsNum={props.beds}
        bathroomNum={props.bath}
        //props of this component is pass where trending property is called
      /> */}
      {/* <SkeletonLoader width='100%' height='10' count={3}/> */}
      {/* <SkeletonPropertyDetailBox/> */}
      <div className=''>
     <SkeletonLoader containerClassName ='skeleton-trending-guest-div' width='40' height='20' count='3'/>
      </div>
      {/*  */}
      <div className="amenities-boxes-reviews">
        <div className="amenities-list">
          {/* {
            (props.amenities).map(amdata=>{
              return <AmenitiesBoxes proertyAmenitiesText={amdata.amenity_name} />
            })
          } */}
             {isSmallScreen ? 
          <SkeletonLoader width={200} height={30} count={1} 
          />
            : 
            <SkeletonLoader width={100} height={30} count={1} />
        }
        </div>
        {/* <Button 
          as="div" 
          priceLinkClasss='price-btn-link'
          // btnclasses='property-price-link'
          link={props.slug}
          btnclasses="price-btn" 
          btnText={
            <>
              <p>
              ₹{formatIndianRupeesFunc(props.priceValue}
              <span className="per-night-text"> /per night</span>
              </p>
              
            </>
          }
        >
        </Button> */}        
        {isSmallScreen ? 
          <SkeletonLoader width={"100%"} height={30} count={1}/>
        : 
        <SkeletonLoader width={100} height={30} count={1}/>
        }
      </div>

      
    </div>
  </CardWrapper>

  )
}

export default SkeletonTrendingProperty
