import logo from "./logo.svg";
import "./App.css";
import Testnew from "./Components/common/Testnew";
import Home from "./Pages/user/home";
import TrendingProperty from "./Components/UserComponents/TrendingProperty";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import LoginPage from "./Pages/common/loginPage";
import HostReservation from "./Pages/host/hostReservation";

// import { BrowserRouter, Route, Switch, Routes } from "react-router-dom";
// import Test4 from "./Components/Test4";
// import MyAPIComponent from "./Components/Apitest";

import ListingPage from "./Pages/host/listingPage/index";
import PropType from "./Module/Host/CreateListing/propType";
import PlaceType from "./Module/Host/CreateListing/placeType";
// import HostNav from "./Components/common/hostNav";
import RoomType from "./Module/Host/CreateListing/roomType";
import GoogleAddress from "./Module/Host/CreateListing/googleAddress";
import ConfirmAddress from "./Module/Host/CreateListing/confirmAddress";
import CounterBeds_Guest from "./Module/Host/CreateListing/counterBeds_Guest";
import PlaceTitle_disc from "./Module/Host/CreateListing/placeTitle_disc";
import Rules_disc from "./Module/Host/CreateListing/rules_disc";
import BookingPreference from "./Module/Host/CreateListing/bookingPreference";
import HostCongrats from "./Module/Host/CreateListing/hostCongrats";
import ReviewListing from "./Module/Host/CreateListing/reviewListing";
import ListingPricing from "./Module/Host/CreateListing/listingPricing";

// import LoginPage from "./Pages/common/loginPage";
import Protected from "./utils/protected";
// import HostReservation from "./Pages/host/hostReservation";
import UnProtected from "./utils/unprotected";
import SignUpPage from "./Pages/common/signUpPage";
import PlaceDiscription from "./Module/Host/CreateListing/placeDiscription";
import InsightPage from "./Pages/common/insightPage";
import ListingAmenities from "./Module/Host/CreateListing/listingAmenities";
import DragDropBox from "./Module/Host/CreateListing/dragDropBox";
import UserListingPage from "./Pages/user/userListingPage";
import EditListingPage from "./Module/Host/CreateListing/EditListingPage";
import HeadNav from "./Module/User/HeadNav";
import UserPropertyDetails from "./Pages/user/userPropertyDetails";
import RequestToBook from "./Pages/user/requestToBook";
import HostProfilePage from "./Pages/host/HostProfilePage";
import AccountDetailPage from "./Pages/host/AccountDetailPage";
import PersonalInfo from "./Pages/host/PersonalInfo";
import HostLoginSecurity from "./Pages/host/HostLoginSecurity";
import HostTaxesDetail from "./Pages/host/HostTaxesDetail";
import Payout_transactionParent from "./Pages/host/Payout_transactionParent";
import UpcomingPayout from "./Module/Host/Accounts/UpcomingPayout";
import ProcessedPayout from "./Module/Host/Accounts/ProcessedPayout";
import HostCreateProfilPage from "./Pages/host/HostCreateProfilPage";
import Toast from "./Components/common/Toast";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ForgotPassPage from "./Pages/common/forgotPassPage";
import PassOtpPage from "./Pages/common/passOTPPage";
import NewPassPage from "./Pages/common/newPassPage";
import { useEffect } from "react";
import BookingDetail from "./Pages/common/bookingDetail";
import CalendarPage from "./Pages/host/CalendarPage";
import TripsPage from "./Pages/user/TripsPage";
import AllReservationComp from "./Module/Host/AllReservationComp";
import UserProfilePage from "./Module/User/UserProfilePage";
import UserBottomBar from "./Module/User/UserBottomBar";
import { useState } from "react";
import MobileProfile from "./Module/User/MobileProfile";
import MobHeadNav from "./Module/User/MobHeadNav";
import Auth from "./Pages/common/loginPage/Auth";
import Terms_conditionPage from "./Pages/common/Terms_conditionPage";
import Privacy_policy from "./Pages/common/Privacy_policy";
import WishlistPage from "./Pages/common/WishlistPage";
import StartCreateListingPage from "./Module/Host/CreateListing/StartCreateListingPage";
import Step1Listing from "./Module/Host/CreateListing/Step1Listing";
import Step3Listing from "./Module/Host/CreateListing/Step3Listing";
import SecondStepListing from "./Module/Host/CreateListing/SecondStepListing";
import { setPrompComp, setPromptComp } from "./utils/redux/GetPromptSlice";
import WrapperPopup from "./Components/common/wrapperPopup";
import DeleteImagePop from "./Module/Host/EditListing/ListingDetail/DeleteImagePop";
import CustomSnackbar from "./Components/common/CustomSnacker";
import MobCalendarPage from "./Pages/host/CalendarPage/MobCalendarPage";
import WinnerPage from "./Components/UserComponents/WinnerPage";
import HostNav from "./Components/common/hostNav";
import ContactUs from "./Pages/common/signUpPage/ContactUs";
import FooterComponent from "./Pages/common/FooterComponent";
import { Message } from "@mui/icons-material";
import MessageComp from "./Module/Host/Message";
import HostBookingDetail from "./Pages/common/bookingDetail/HostBookingDetail";
import LayerPage from "./Pages/user/PropertyDetails/LayerPage";
import HostLayerDetail from "./Pages/common/bookingDetail/HostLayerDetail";
import MessageUserSide from "./Pages/user/MessageUserSide";
import { apiMainUser } from "./utils/constant";
import VerifactionToNavigateHost from "./Components/common/VerifactionToNavigateHost";
import axios from "axios";
import RedirectToVerificationProfile from "./Components/common/RedirectToVerificationProfile";


function App() {

  
  const isSmallScreen = window.innerWidth <= 500;
    const storeSel = useSelector((state) => state)


    // const activeMessage = useSelector(getActiveMessage);

    const isPromptVisible = storeSel?.PromptSlice?.isPrompt;

  


    ////////////for snacker--------------------

    const open = storeSel?.snackbar?.open;
    const message = storeSel?.snackbar?.message;
    const severity = storeSel?.snackbar?.severity;



    const dispatch = useDispatch();

      return (
       <>
      {/* {activeMessage && (
        <Toast
          message={activeMessage.message}
          type={activeMessage.type}
          actionBtn={false} 
           
         
        />
      )} */}
      {/* Prommpt Component Call---------------------------------below|||||||||||||||||||||||||||||||||||| */}
   
     <div style={{zIndex:'99999',position:'relative'}} className="delete-prompt-popup-parent">
      {isPromptVisible && (
        <WrapperPopup
          closeModal={() => dispatch(setPromptComp(false))}
          fluid={true}
          position='bottom'
          defaultSize={"0.85"}
          dimStyle={false}
          isVisible={true}
          // setVisibleDocks={() => dispatch(setPrompComp(false))}
          comp={
            <DeleteImagePop
              // handleDelete={() => {
              //   dispatch(setPrompComp(false)); 
              // }}
              closeModal={() => dispatch(setPromptComp(false))}
            />
          }
        />
      )}

      </div>

      <div className="snacker-parent">
  
      {open && ( <CustomSnackbar open={open} message={message} severity={severity} />)}
     </div>

    


      <BrowserRouter>  
   

        {/* public routes */}
        <ScrollToTop />
        <Routes>

        {/* <Route path="/host/*" element={<RedirectToVerificationProfile />} /> */}
          {/* <HostNav/> */}
          <Route
            path="/"
            element={
              <>
           
                {!isSmallScreen && <HeadNav  />}
                {isSmallScreen && <MobHeadNav/>}
                <Home />
                <FooterComponent/>
                {isSmallScreen && <UserBottomBar  home={true}/>}
              </>
            }
          />

             <Route
             path="/ContactUs"
            element={
             <>
              {/* <UnProtected> */}
              <HostNav />
              <ContactUs/>
              <FooterComponent/>
              {/* {isSmallScreen && <UserBottomBar  home={true}/>} */}
              {/* </UnProtected> */}
              </>
            }
          /> 
          <Route
            path="/properties"
            element={
              <>
               
                {!isSmallScreen && <HeadNav/>}
                
                <UserListingPage />
                <FooterComponent/>
                {isSmallScreen && <UserBottomBar home={true}/>}
              </>
            }
          />
          <Route
            path="/properties/:city/:slug"
            element={
              <>
               {!isSmallScreen && <HeadNav  />}
                <UserPropertyDetails />
                <FooterComponent/>
              </>
            }
          />   
          
          <Route
             path="/termscondition"

            element={
                <>
                <HostNav />
                <Terms_conditionPage/>
                <FooterComponent/>
                </>
               
            }
          />
            <Route
             path="/privacypolicy"
            element={
             <>
              <HostNav />
              <Privacy_policy/>
              <FooterComponent/>
              </>
            }
          />  

        
        </Routes>

        <Routes>

          <Route
            path="/login"
            element={
              <UnProtected>
                <LoginPage />
              </UnProtected>
            }
          />
             <Route
            path="/winner"
            element={
              <UnProtected>
                <WinnerPage />
              </UnProtected>
            }
          />

              <Route
            path="/auth"
            element={
              // <UnProtected>
                <Auth />
              // </UnProtected>
            }
          />  
          <Route
            path="/forgotPassword"
            element={
              <UnProtected>
                <ForgotPassPage/>
              </UnProtected>
            }
          />
           <Route
            path="/passOtpPage"
            element={
              <UnProtected>
                <PassOtpPage/>
              </UnProtected>
            }
          />
           <Route
            path="/newPassPage"
            element={
              <UnProtected>
                <NewPassPage/>
              </UnProtected>
            }
          />
          <Route
            path="/signup"
            element={
              <UnProtected>
                <SignUpPage />
              </UnProtected>
            }
          />
           


           
        </Routes>

        {/* protected routes */}        
        <Routes>
          <Route
            path="/properties/:city/:slug/book"
            element={
              <Protected>
              <RequestToBook/>
              <FooterComponent/>
              </Protected>
            }
          />
          {/* <Route
            path="host"
            element={
              <Protected leftnav={true}>
               {isMobileVerified} ? (
              <HostReservation />
            ) : (
              <RedirectToVerificationProfile
                setVerificationProfilePop={setVerificationProfilePop}
              />
            )
              </Protected>
            }
          /> */}

            <Route
            path="host"
            element={
              <Protected leftnav={true}>
             {/* <RedirectToVerificationProfile/> */}
              <HostReservation />              
           
              </Protected>
            }
          />

            <Route
            path="/user-message"
            element={
              <Protected>
                <MessageUserSide />
                {/* <EditListingPage /> */}
              </Protected>
            }
          />

            
          <Route
            path="/trips/:bookid"
            element={
              <Protected>
              

                {/* <BookingDetail/>   */}
                <LayerPage />
                <FooterComponent/>
              </Protected>
            }
          />
          <Route
            path="/host/reservations/:bookid"
            element={
              <Protected>
              {/* <HostBookingDetail/>  */}
              <HostLayerDetail/>
              <FooterComponent/>
              </Protected>
            }
          />
           {/* <Route
             path="/host-profile/:hostId"
            element={
              <>              
              <Protected mobilenav={true}>
                <HostProfilePage />
              </Protected>
              </>
            }
          /> */}
          
             


          {/*Temporary  */}
          <Route
            path="/host/profile"
            element={
              <Protected mobilenav={true}>
              {/* {!isSmallScreen && <HostProfilePage />} */}
              {isSmallScreen && (<><MobileProfile />
              <FooterComponent/>
              </>)}
             
              </Protected>
            }
          />

         <Route
            path="profile"
            element={
              <Protected mobilenav={true}>
              {/* {!isSmallScreen && <UserProfilePage />} */}
              {isSmallScreen && (<><MobileProfile />
                <FooterComponent/></>)}
              </Protected>
            }
          />

          <Route
            path="/users/:username"
            element={
              <Protected >
               <UserProfilePage />
               <FooterComponent/>            
              </Protected>
            }
          />

         <Route
            path="trip"
            element={
              <>              
              <Protected mobilenav={true}>
                <TripsPage />
                <FooterComponent/>
              </Protected>
              </>
            }
          />

         <Route
            path="wishlist"
            element={
              <>
              
              <Protected>
                <WishlistPage />
                <FooterComponent/>
              </Protected>
              </>
            }
          />

           <Route
            path="hostAccount"
            element={
              <Protected>
                <AccountDetailPage />
                <FooterComponent/>
              </Protected>
            }
          />
          
          <Route
            path="hostPersonalInfo"
            element={
              <Protected>
                <PersonalInfo/>
                <FooterComponent/>
              </Protected>
            }
          />
            <Route
            path="hostloginSecurity"
            element={
              <Protected>
                <HostLoginSecurity/>
                <FooterComponent/>
              </Protected>
            }
          />

           <Route
            path="hostTaxes"
            element={
              <Protected>
                <HostTaxesDetail />
              </Protected>
            }
          />
             <Route
            path="hostPayout_transaction"
            element={
              <Protected>
                <Payout_transactionParent />
              </Protected>
            }
          />
            <Route
            path="hostUpcomingPayout"
            element={
              <Protected>
                <UpcomingPayout />
              </Protected>
            }
          />
            <Route
            path="hostProcessedPayout"
            element={
              <Protected>
                <ProcessedPayout />
              </Protected>
            }
          />
           <Route
            path="hostcreateprofile"
            element={
              <Protected>
                <HostCreateProfilPage />
                <FooterComponent/>
              </Protected>
            }
          />

{/*  */}

          <Route
            path="host/listing"
            element={
              <Protected leftnav={true}>
                <ListingPage />
              </Protected>
            }
          />
           {/* <Route
            path="/host/reservations/:bookid"
            element={
              <Protected>
                <BookingDetail/>  
              </Protected>
            }
          /> */}
          <Route
            path="/host/reservations"
            element={
              <Protected leftnav={true}>
                <AllReservationComp/>
                
              </Protected>
            }
          />
          {/* 
          <Route
            path="host/listing/edit"
            element={
              <Protected leftnav={true}>
               
                <EditListingPage />
              </Protected>
            }
          /> */}

          <Route
            path="/host/listing/edit/:city/:slug"
            element={
              <Protected leftnav={true}>
                {/* <HostReservation /> */}
                <EditListingPage />
              </Protected>
            }
          />

          <Route
            path="host/insight"
            element={
              <Protected leftnav={true}>
                <InsightPage />
              </Protected>
            }
          />
          <Route
            path="host/message"
            element={
              <Protected leftnav={true}>
                <MessageComp />
              </Protected>
            }
          />

           <Route
            path="host/calendar"
            element={
              <Protected leftnav={true}>
          {isSmallScreen ? <MobCalendarPage /> : <CalendarPage />}     
                
              </Protected>
            }
          />
            

           <Route
            path="host/createlisting"
            element={
              <Protected>
                <StartCreateListingPage/>
              </Protected>
            }
          />
            <Route
            path="host/createlisting/step-1"
            element={
              <Protected>
                <Step1Listing/>
              </Protected>
            }
          />
           <Route
            path="host/createlisting/step-2"
            element={
              <Protected>
                <SecondStepListing/>
              </Protected>
            }
          />
           <Route
            path="host/createlisting/step-3"
            element={
              <Protected>
                <Step3Listing/>
              </Protected>
            }
          />

          <Route
            path="host/createlisting/property-title"
            // path="host/createlisting"

            element={
              <Protected>
                <PlaceTitle_disc />
              </Protected>
            }
          />

          <Route
            path="host/createlisting/property"
            element={
              <Protected>
                <PropType />
              </Protected>
            }
          />



          <Route
            path="host/createlisting/place"
            element={
              <Protected>
                <PlaceType />
              </Protected>
            }
          />
          <Route
            path="host/createlisting/room"
            element={
              <Protected>
                <RoomType />
              </Protected>
            }
          />
          <Route
            path="host/createlisting/googleAddress"
            element={
              <Protected>
                <GoogleAddress />
              </Protected>
            }
          />

          <Route
            path="host/createlisting/discription"
            element={
              <Protected>
                <PlaceDiscription />
              </Protected>
            }
          />

          <Route
            path="host/createlisting/confirmAddress"
            element={
              <Protected>
                <ConfirmAddress />
              </Protected>
            }
          />

          <Route
            path="host/createlisting/counterBeds"
            element={
              <Protected>
                <CounterBeds_Guest />
              </Protected>
            }
          />

          <Route
            path="host/createlisting/rulesDiscription"
            element={
              <Protected>
                <Rules_disc />
              </Protected>
            }
          />
          <Route
            path="host/createlisting/bookingPreference"
            element={
              <Protected>
                <BookingPreference />
              </Protected>
            }
          />
          <Route
            path="host/createlisting/hostCongrats"
            element={
              <Protected>
                <HostCongrats />
              </Protected>
            }
          />
          <Route
            path="host/createlisting/reviewListing"
            element={
              <Protected>
                <ReviewListing />
              </Protected>
            }
          />
          <Route
            path="host/createlisting/dropbox"
            element={
              <Protected>
                <DragDropBox />
              </Protected>
            }
          />
          <Route
            path="host/createlisting/listingPrice"
            element={
              <Protected>
                <ListingPricing />
              </Protected>
            }
          />
          <Route
            path="host/createlisting/listingAmenities"
            element={
              <Protected>
                <ListingAmenities />
              </Protected>
            }
          />
        </Routes>
        
     
     {/* <FooterComponent/> */}
      </BrowserRouter>    


     
    </>
  );
}
const ScrollToTop = () => {
  const { pathname } = useLocation();  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
export default App;
