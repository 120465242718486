import React from "react";
import FontComp from "./fontComp";
import {
  faEllipsisVertical,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

const SearchComp = ({name, onKeyUp }) => {
  return (
    <>
      <div className="search-comp-box">
        <input
          type="text"
          className="search-input"
          name={name}
          // key
          placeholder="Search Property"
          // onChange={props.onKeyUp !== undefined ? props.onKeyUp : null}
          onKeyUp={onKeyUp}
        />

        <FontComp
          fontclassname="search-icon"
          icon={faSearch}
          size="lg"
          color="#BDBDBD"
        />
      </div>
    </>
  );
};

export default SearchComp;
