import React from 'react'
import HostPayout from '../../Module/Host/Accounts/HostPayout';
import HostTransaction from '../../Module/Host/Accounts/HostTransaction';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Payout_transactionParent = () => {
    const [activeTab, setActiveTab] = useState(1);

    const links = [
        { id: 1, label: "Payouts" },
        { id: 2, label: "Transactions" },
        
      ];
    
      
    
      const handleTabClick = (id) => {
        setActiveTab(id);
      };
    
      const navigate = useNavigate()


  const navigateToHostAccountPage =()=>{
  navigate('/hostAccount')
  }




  return (
    <div className='account-detail-page payout-transaction-parent-page'>
       <p style={{fontSize:'1.2rem',fontWeight:'500'}}><span className='pointer' onClick={navigateToHostAccountPage}>Account</span> > Payouts & Transactions</p>
   <h2 className='account-head-text'>Payouts & Transactions</h2>
   
   <div className="right-edit-page-content">
        <nav className="navbar">
          {links.map((link) => (
            <button
              key={link.id}
              className={`nav-link-edit ${
                activeTab === link.id ? "edit-active" : ""
              }`}
              onClick={() => handleTabClick(link.id)}
            >
              {link.label}
            </button>
          ))}
        </nav>

        

        <div className="host-payout_transaction-content">
          {activeTab === 1 && (
            <HostPayout
              
            />
          )}
          {activeTab === 2 && (
            <HostTransaction
        
            />
          )}
         
        </div>
      </div>


    </div>
  )
}

export default Payout_transactionParent;
