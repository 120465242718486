import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../../Components/common/Button';
import axios from 'axios';
import { apiMainUser } from '../../../utils/constant';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../utils/redux/SnackBarSlicer';

const HostLanguagePop = (props) => {
  const { handleSubmit, register, getValues } = useForm();

// console.log()
const dispatch = useDispatch()
  const userLanguages = props.usersData.languages ? props.usersData.languages.split(',') : [];




  const onSubmit = async () => {
    const selectedLanguages = Object.keys(getValues('languages')).filter(
      (key) => getValues('languages')[key]
    );
 
   const formData = new FormData();
    formData.append('languages', selectedLanguages);
    

    try {
      const response = await axios({
        method:'patch',
        url: apiMainUser + 'update_user',
      
        data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        
      }
      
      );
      if(response.data.status === 'success'){
        props.fetchData()
        props.closeModal()
        dispatch(openSnackbar({ message: 'Language updated succesfully!', severity: 'success' }));

       
      }
    } catch (error) {
      console.error(error);
      dispatch(openSnackbar({ message: 'Something went wrong!', severity: 'error' }));

    }
   
  };

  return (
    
    <form onSubmit={handleSubmit(onSubmit)} className="language-form">
      <div className='paddingCustom'>
        <p className="language-text">Languages</p>
        <div className="checkbox-div">
          <label>
            Hindi
            <input
             defaultChecked={userLanguages.includes('Hindi')}
            type="checkbox" value="Hindi" {...register('languages.Hindi')} />
          </label>
        </div>
        <div className="checkbox-div">
          <label>
            English
            <input
             defaultChecked={userLanguages.includes('English')}
            type="checkbox" value="English" {...register('languages.English')} />
          </label>
        </div>
        <div className="checkbox-div">
          <label>
            Marathi
            <input 
             defaultChecked={userLanguages.includes('Marathi')}
            type="checkbox" value="Marathi" {...register('languages.Marathi')} />
          </label>
        </div>
        <div className="checkbox-div">
          <label>
            Punjabi
            
            <input
             defaultChecked={userLanguages.includes('Punjabi')}
            type="checkbox" value="Punjabi" {...register('languages.Punjabi')} />
          </label>
        </div>
      </div>
      
      <div className="clear_apply-btn-box">
      
      <p onClick={() => props.closeModal(false)} className="clear-btn">
         Cancel
       </p>
      <button className="save-edit-btn" type="apply-btn">
         Save
       </button>

  
     </div>


      
    </form>
 
  );
};

export default HostLanguagePop;
