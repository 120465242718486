import React from "react";
import ViewBreakDownCard from "../../Components/HostComponents/viewBreakdownCard";
import ExpSlider from "../../Module/User/expSlider";
import CardWrapper from "../../Components/common/cardWrapper";
import UserExpCard from "../../Module/User/userExpCard";
import { apiMainUser, capitalizeText, formatIndianRupeesFunc, getBlockedDatesFunc, rzpkey, updateURLWithCountsFunc, updateUrlWithDatesFunc } from "../../utils/constant";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WrapperPopup from "../../Components/common/wrapperPopup";
import DatePicker from "../../Components/UserComponents/datePicker";
import {
  updateUserAdults,
  updateUserCheckin,
  updateUserCheckout,
  updateUserChildrens,
  updateUserGuests,
  updateUserInfants,
} from "../../utils/redux/User/UserSearchConfig";
import { useDispatch } from "react-redux";
import BookingEditGuest from "./BookingEditGuest";
import CouponComp from "../../Components/common/CouponComp";
import ButtonPopup from "../../Components/common/ButtonPopup";
import AddToCart from "../../Components/common/addCarts";
import DateRangeDisplay from "../../Components/common/DateRangeDisplay";
import SwitchExample from "../../Components/common/switch";
// import { Dock } from "react-dock";
import DockComponent from "../../Components/common/DockComponent";
import CancellationPolicyViewPop from "../../Components/UserComponents/CancellationPolicyViwPop";
import CancellationPolicyCard from "../../Components/UserComponents/CancellationPolicyCard";
import { openSnackbar } from "../../utils/redux/SnackBarSlicer";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const RequestToBookIn = (props) => {

  const location = window.location;

  const dispatch = useDispatch()



  //addon rendering state---
  const [addonDetails,setAddonDetails] =useState()

    ////date picker states---

    const navigate = useNavigate();
 
    const [cancellationData,setCancellationData]= useState()


    useEffect(()=>{
      setCancellationData(props?.propdetails?.cancellation_category_detail)
    })

  
    
  const [minDateState, setMinDate] = useState();
  const [maxDateState, setMaxDate] = useState();

  const [blockDateState, setBlockedDates] = useState();
  const [blockCheckinDateState, setCheckinBlockedDates] = useState();
  const [blockCheckoutDateState, setCheckoutBlockedDates] = useState();
  const [newsuccessdata, setNewSuccessData] = useState([]);


  const [propertyId, setPropertyId] = useState();

  const [selectedCheckIn, setCheckIn] = useState(null);
  const [selectedCheckOut, setCheckOut] = useState(null);
  const [selectedTempCheckIn, setTempCheckIn] = useState(null);
  const [selectedTempCheckOut, setTempCheckOut] = useState(null);
  const [selectedChildrenCount, setSelectedChildrenCount] = useState(null);

  const [selectedAdultCount, setSelectedAdultCount] = useState(null);
  // const [openMobDatesPop,setOpenMobDatesPop] = useState(false)


  const [selectedGuestCount, setSelectedGuestCount] = useState(null);
  const [selectedInfantCount, setSelectedInfantCount] = useState(null);

  const [guestCount, setGuestCount] = useState(null);
  const [infantCount, setInfantCount] = useState(null);
  const [childrenCount, setChildrenCount] = useState(null);
  const [adultCount, setAdultCount] = useState(null);



  useEffect(() => {
    if (props?.propdetails?.propertyid !== undefined && props?.propdetails?.propertyid !== null && props?.propdetails?.propertyid !== '') {
    const fetchData = async () => {
      try {
        const resData = await getBlockedDatesFunc(props?.propdetails?.propertyid);
        setMinDate(resData.minDate);
        setMaxDate(resData.maxDate);
        setBlockedDates(resData.blockedDates);
        setCheckinBlockedDates(resData.blockedDatesCheckin);
        setCheckoutBlockedDates(resData.blockedDatesCheckout);
        setNewSuccessData(resData.response);
      } catch (error) {
      }
    };
    
  
    fetchData(); // Call the async function immediately
  }
  }, [props.propdetails?.propertyid])
  


  
 
  const parseQueryParamsDates = () => {

    const searchParams = new URLSearchParams(location.search);
    const checkinParam = searchParams.get("checkin");
    const checkoutParam = searchParams.get("checkout");

    setCheckIn(checkinParam);
    setCheckOut(checkoutParam);
   
    
  };

  
  
  const parseQueryParamsCounts = () => {
  
    const searchParams = new URLSearchParams(location.search);
    const infantParam = searchParams.get("infants") || 0;
    const childrenParam = searchParams.get("children") || 0;
    const adlutsParam = searchParams.get("adults") || 1;

    
    console.log(selectedGuestCount,adlutsParam,'checkCountsAll')

    console.log(adlutsParam,childrenParam,'checkBothValue')

    setSelectedGuestCount(parseInt(adlutsParam,10) + parseInt(childrenParam))
    setGuestCount(parseInt(adlutsParam,10) + parseInt(childrenParam))

    setSelectedAdultCount(adlutsParam);
    setSelectedChildrenCount(childrenParam);
    setSelectedInfantCount(infantParam)

    setInfantCount(infantParam)
    setAdultCount(adlutsParam)
    setChildrenCount(childrenCount)


  };




  useEffect(() => {
    parseQueryParamsDates();
    parseQueryParamsCounts();
  }, [selectedGuestCount]);

 
  useEffect(() => {
    const reqdata = createUpdateTrransactionRequest();
  
    // Check if props.insertId exists before making the API call
    if (props.insertId !== null && props.insertId !== undefined) {
      updateTransactionApi(reqdata);
    }
  }, [selectedCheckIn, selectedCheckOut, selectedAdultCount, selectedChildrenCount, selectedInfantCount, selectedGuestCount, props.insertId]);
  

  const [addonSwitches, setAddonSwitches] = useState({});

  
  const [addonInsertIds, setAddonInsertIds] = useState({});


  const [cartDetails,setCartDetails]= useState([])

  const [addonCounts, setAddonCounts] = useState([]);
  console.log(addonCounts,'checkCart')

 
  const handlePets = (addonId, count) => {

  setAddonCounts((prevCounts) => {
    let data = cartDetails.filter(cart=>{
      return cart.product_id == addonId && cart.product_type == 'addon';
    })
    data[0].newQuantity = count

    return ({
      ...prevCounts,
     
      detail:data[0]
    })
    
  });

  };
console.log(addonCounts,'checkCount')
  
  

  // update addon api
  
  const handleUpdateAddon = async () => {
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      
      const response = await axios.patch( apiMainUser + `update_cart/${addonCounts.detail.uid}`, {
           
          "transaction_id": addonCounts.detail.transaction_id
          ,
          "product_type": "addon",
          "product_id": addonCounts.detail.product_id,
         "quantity":addonCounts.detail.newQuantity
         
      
      }, { headers });
      
      console.log('Update API responsei:', response);
      if(response.data.status == 'success'){
        
        updateTransaction()
        setAddonCounts([])
        dispatch(openSnackbar({ message: 'Addon updated succesfully!', severity: 'success' }));


      }
    } catch (error) {
      console.error('Error updating addon:', error);
      dispatch(openSnackbar({ message: 'Addon updated succesfully!', severity: 'error' }));

    }
  }
  
  


  console.log(addonCounts,'checkCounts')


  const isSmallScreen = window.innerWidth <= 500;




  const handleSwitchChange = (addonId) => {

    const isChecked = !addonSwitches[addonId];
  
    setAddonSwitches((prevSwitches) => ({
      ...prevSwitches,
      [addonId]: isChecked,
    }));
  
    const data = {
      transaction_id: props.insertId,
      product_type: "addon",
      product_id: addonId,
      quantity: 1,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
  
    if (isChecked) {
      axios
        .post(apiMainUser + "create_cart", data, { headers })
        .then((response) => {
          if (response.data.status === "success") {
            const newInsertId = response.data.data.insertId;
            console.log(response.data.data,'checkDataAll')
  
            // Update the mapping of add-on IDs to insert IDs
            setAddonInsertIds((prevInsertIds) => ({
              ...prevInsertIds,
              [addonId]: newInsertId,
            }));
  
            updateTransaction(props.insertId);
            dispatch(openSnackbar({ message: 'Addon added succesfully!', severity: 'success' }));

          }
        })
        .catch((error) => {
          dispatch(openSnackbar({ message: 'Something went wrong!', severity: 'error' }));

        });
    } else {
      const existingInsertId = addonInsertIds[addonId];
      if (existingInsertId) {
        // Call the delete API with existingInsertId and addonId
        axios
          .delete(apiMainUser + `delete_cart/${existingInsertId}`, { headers })
          .then((deleteResponse) => {
            if(deleteResponse.data.status === 'success'){
              updateTransaction(props.insertId)
              dispatch(openSnackbar({ message: 'Addon removed succesfully!', severity: 'success' }));

            }
          })
          .catch((deleteError) => {
          });
      }
    }
  };

  
 console.log(cartDetails,'cartCheck')

  const updateTransaction = async () => {
    // API call
    try {
      const response = await axios
        .get(apiMainUser + "read_transaction/" + props.insertId, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          props.onUpdateTransactionResponse(response.data.data[0]);
          console.log(response.data.data[0],'checkRead')
          setAddonDetails(response.data.data[0].addon_details)
          setCartDetails(response.data.data[0].cart)
        });
    } catch (error) {
    }
  };

  const [openModalDate, setOpenModalDate] = useState(false);
  const [openModalGuest, setOpenModalGuest] = useState(false);
  const [openModalCoupon, setOpenModalCoupon] = useState(false);

  const handleCouponPop = () => {
    setOpenModalCoupon(true);
  };



  const onDateChange = (minDate, maxDate) => {
    setTempCheckIn(moment(minDate).format("YYYY-MM-DD"));
    setTempCheckOut(moment(maxDate).format("YYYY-MM-DD"));

    // dispatch(updateUserCheckin(minDate.format('YYYY-MM-DD')))
    // dispatch(updateUserCheckout(maxDate.format('YYYY-MM-DD')))
  };


  //
  const updateTransactionApi = async (requestData) => {
    const apiEndpoint = apiMainUser + `update_transaction/${props.insertId}`;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    try {
      const response = await axios.patch(apiEndpoint, requestData, { headers });
      updateTransaction(); // Call the updateTransaction function here if needed
    } catch (error) {
    }
  };

  const createUpdateTrransactionRequest = ()=>{
    const requestData = {
      checkin_date: selectedCheckIn,
      checkout_date: selectedCheckOut,
      guest_count: selectedGuestCount,
      infants: selectedInfantCount,
      adults: selectedAdultCount,
      children: selectedChildrenCount,
    };
    return requestData;
  }

  const handleEditDates = () => {
    setCheckIn(selectedTempCheckIn)
    setCheckOut(selectedTempCheckOut)
    // const requestData = createUpdateTrransactionRequest()
    
    // // dispatch(updateUserCheckin(minDateState));
    // // dispatch(updateUserCheckout(maxDateState));
    if(selectedTempCheckIn !== undefined && selectedTempCheckOut !== undefined)
    updateUrlWithDatesFunc(moment(selectedTempCheckIn),moment(selectedTempCheckOut))
    dispatch(openSnackbar({ message: 'Dates updated succesfully!', severity: 'success' }));

    // updateTransactionApi(requestData);
    

  };


  const handleCounts = (adults, children, infants) => {
    setGuestCount(parseInt(adults, 10) + parseInt(children));
    setInfantCount(infants);
    setChildrenCount(children);
    setAdultCount(adults);
    // setSelectedGuestCount(parseInt(adults, 10) + parseInt(children))
  };




  const handleGuest_Child_infant = () => {
    setSelectedGuestCount(parseInt(adultCount, 10) + parseInt(childrenCount))
    setSelectedChildrenCount(childrenCount)
    setSelectedAdultCount(adultCount)

    // setGuestCount(parseInt(adultCount) + parseInt(childrenCount))
    // setInfantCount(infantCount)
    // setAdultCount(adultCount)
    setSelectedInfantCount(infantCount)
    // const requestData = createUpdateTrransactionRequest()
    updateURLWithCountsFunc(adultCount,childrenCount,infantCount)
    // updateTransactionApi(requestData);
    dispatch(openSnackbar({ message: 'Guest updated succesfully!', severity: 'success' }));

    
  };


  const [successdata, setSuccessData] = useState([]);
  const [userData, setUsersData] = useState([]);
  const momentStr = moment();
  


  //Razorpay Code
 
  const fetchDataUserDets = async () => {
   
    try {
      const response = await axios
        .get( apiMainUser + "get_user_detail", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setUsersData(response.data.data[0]);
          
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataUserDets();
   
  }, []);

  useEffect(()=>{
    const openPayment = async()=>{
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: rzpkey,
      currency: "INR",
      amount: props.transid.price_detail[props.transid.price_detail.length - 1].total.grand_total_booking_cost,
      order_id: successdata.razorpay_order_id,
      name: "Jumbo Holidayz "+ props.propdetails?.property_name,
      description: "Stays with a beat!",
      image: "https://jumboholidayz.com/assets/img/jumboLogo.png",
      theme:{
        color:"#19A6BB"
      },
      prefill: {
        name: userData.fname,
        email: userData.email,
        phone_number: userData.mobile,
      },
      handler: function (response) {
        if (response) {
          navigate(`/trips/`+successdata.booking_order_id);
        }
      },

    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    }
    openPayment()
  },[successdata])

  // useEffect(() => {
  //   window.open(successdata.redirect_phone_url, "_self");
  // }, [successdata]);

  const createBooking = async () => {
    try {

    const bookres = await axios.post(
      apiMainUser + "create_booking",
      { transaction_id: props.transid.uid },
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
    );
    setSuccessData(bookres.data.data);
  } catch (error) {
    console.error(error,'error');          
    dispatch(openSnackbar({ message: capitalizeText(error.response.data.message), severity: 'error' }));

  }
  };

  
  // Call the function as needed, such as on button click
  // For example:
  // <button onClick={createBooking}>Create Booking</button>
  


  const data = props.transid?.price_detail;


  let priceDetailMapvalues = {};
  data?.map((x) => {

    if (x.total) {
      priceDetailMapvalues = x?.total;
    }
  });


  const toggleVisibleDocks = () => {
    setOpenModalCoupon(false)


  };

 
  console.log(addonDetails,'checkPriceAll')
  
  console.log(props?.propdetails,'checkCancelUnique')


  const backToPropertyFunc = () => {
    const specificURL = "/properties/" +
      props?.propdetails.city +
      "/" + 
      props?.propdetails.slug;
  
    console.log('clickedFunc');
    navigate(specificURL);
  }

  console.log(cartDetails,'checkDetailsAddon')

  console.log(props.propdetails?.addons_details?.length > 0 ,'checkStatus')
  
  return (
    <div className="request-book-page">
      <div className="request-book-left-content">

   {isSmallScreen &&      
    <div style={{marginBottom:'2rem'}} className="view-breakdown-main-box mobile-view-break-down">
      <div className="view-breakdown-top-box">
        <div className="view-breakdown-img-box">
          <img
            src={props.propdetails?.base_url_image + props.propdetails?.images[0]}
            alt={props.alt}
            // className={props.className}
            // width={props.width}
            // height={props.height}
          />
        </div>
        <div className="view-breakdown-top-text-box ">
          <p className="entire-property">{props.propdetails?.space_type}</p>
          <p className="property-name">{props.propdetails?.property_name}</p>
          <p className="view-breakdown-rating-line">
            {props.propdetails?.Overall_rating > 0 &&
              <>
              <span>
                <span className="ml2 bold" style={{ marginLeft: "2px" }}>{props.propdetails?.Overall_rating}</span>
                {" "}
                <img style={{width:'14px'}} src="./assets/img/colorStarIcon.svg" alt="" />
                {" "}
              </span>
              </>
            }
            {
              props.propdetails?.review_count > 0 &&
              <span className="bold" style={{ fontSize: "1.3rem" }}>
                ({props.propdetails?.review_count} Reviews)
              </span>
            }
            
          </p>
        </div>
      </div>  
      </div>
}


      <CardWrapper wrapperClass="mb-20">
        <p className="your-trip-text">Your Trip</p>

   <div className="request-book-date-box">
    <div>
     <p className="date-text">Dates</p>
    <p className="date">
      <DateRangeDisplay
        startDate={selectedCheckIn ? selectedCheckIn : ''}
        endDate={selectedCheckOut ? selectedCheckOut : ''}
      />
    </p>
     </div> 

{/* {!isSmallScreen ?   */}
    <ButtonPopup
   
    btnClass={true}
      wrapclass="absolute edit-date-popup-reques-to-book-in"
      divContent={
        <>
          <div className={`booking-date-edit-div ${window.innerWidth < 500 ? 'mt-10' : ''}`}>
            <p className="check-in-out-para flex mb-3" style={{gap:'12rem'}}>
              <span class="font-xs">Check-in</span>
              <span class="font-xs">Check-out</span>
            </p>
            <DatePicker
              startDate={selectedTempCheckIn === null ? selectedCheckIn : selectedTempCheckIn}
              endDate={selectedTempCheckIn === null ? selectedCheckOut : selectedTempCheckOut}
              minDate={minDateState}
              maxDate={maxDateState}
              blockedDates={blockDateState}
              blockedDatesCheckin={blockCheckinDateState}
              blockedDatesCheckout={blockCheckoutDateState}
              onDateChange={onDateChange}
              minNight={props.propdetails?.min_stay}
              maxNight={props.propdetails?.max_stay}
              // updateUrl={true}
            />
          </div>
        </>
      }
      showApplyButton={true}
      onApply={handleEditDates}
      showCloseButton={true}
      closeBtnText="Cancel"
      applyBtnText="Save"
      additionalClass='request-book-button-popup'
     
      makeCustom={<p className="edit-text pointer">Edit</p>}
    />


 
</div>


        <div className="request-book-date-box">
          <div>
            <p className="date-text">Guests</p>
            <p className="date">
              {selectedGuestCount > 1
                ? selectedGuestCount + " " + "Guests"
                : "1 Guest"}
              {selectedInfantCount && selectedInfantCount > 0
                ? selectedInfantCount > 1
                  ? ", " + selectedInfantCount + " Infants"
                  : ", 1 Infant"
                : ""}
            </p>
          </div>



          <ButtonPopup
           btnClass={true}
            saveBtnClass="sav-edit-btn"
            wrapclass="absolute  edit-date-popup-reques-to-book-in w-25"
            showApplyButton={true}
            applyBtnText="Save"
            closeBtnText="Cancel"
            showCloseButton={true}
            additionalClass='request-book-button-popup'
            
            onApply={handleGuest_Child_infant}
            makeCustom={<p className="edit-text">Edit</p>}

            divContent={
              <BookingEditGuest
                maxGuestText={true}
                maxGuests={props?.propdetails?.max_guests}
                handleCounts={handleCounts}
              />
            }
          />


        </div>
        </CardWrapper>

        {props.propdetails?.addons_details?.filter((addon) => addon.allowed === 1).length > 0 && 
       <CardWrapper wrapperClass="mb-20">
        <div className="request-to-book-pet-box">
        {props.propdetails?.addons_details
        ?.filter((addon) => addon.allowed === 1)
        .map((addon) => {
          // Log the addon
          console.log(addon,'checkaddons');
          const currentAddonId = addon.addon_id;
          let data = cartDetails.filter(cart=>{
            return cart.product_id == currentAddonId && cart.product_type == 'addon';
          })
          const dataQuantity = cartDetails.find((cart) => cart.product_id === currentAddonId && cart.product_type === 'addon');
          console.log(data,'checkDetailsAddon')
          return (
            <div key={addon.addon_id} className="addon-item">
              <div className="addon-name-cost">
                <p className="addon-cost">
                  <p className="addon-name">{addon.addon_name.charAt(0).toUpperCase() + addon.addon_name.slice(1)}</p>
                  {dataQuantity?.quantity ? dataQuantity?.quantity : 1} x ₹{formatIndianRupeesFunc(addon.addon_cost)} per night
                </p>
              </div>
              <div className="addon-switch">
                <SwitchExample
                  checked={addonSwitches[addon.addon_id] || false}
                  onChange={() => handleSwitchChange(addon.addon_id)}
                />
                {addonSwitches[addon.addon_id] &&  addon.quantifiable !== 0 &&(
                  <ButtonPopup
                    btnClass={true}
                    saveBtnClass="sav-edit-btn"
                    wrapclass="absolute w-17 z-index"
                    showApplyButton={true}
                    applyBtnText="Save"
                    closeBtnText="Cancel"
                    showCloseButton={true}
                    additionalClass='request-book-button-popup'
                    onApply={handleUpdateAddon}
                    makeCustom={<p className="edit-text pointer">Edit</p>}
                    divContent={
                      <>
                        <h3 className="addon-name-popup">{addon.addon_name.charAt(0).toUpperCase() + addon.addon_name.slice(1)}</h3>
                        <AddToCart
                          cartBtn="cartBtn"
                          cartCount="cartCount"
                          cartParent="cartParent"
                          maxCount={addon.max_quantity}
                          initialCount={addonCounts?.detail?.newQuantity || data[0]?.quantity}
                          increamentVal={(count) => handlePets(addon.addon_id, count)}
                        />
                      </>
                    }
                  />
                )}
              </div>
            </div>
          );
        })}
    </div>
       </CardWrapper>
      }



        {isSmallScreen && 
      <div className="mobile-view-request-to-book">
        <ViewBreakDownCard

          onClick={backToPropertyFunc}
          updateTransaction={updateTransaction}
          transactionId={props.transid ? props.transid.uid : ""}
          // src="./assets/img/property.png"
          coupon={
            priceDetailMapvalues.discount_coupon_amount
              ? "Coupon applied"
              : "Enter coupon"
          }
          CouponLabelText={props.transid ? props.transid?.couponcode : ""}
          couponClick={handleCouponPop}
          removeText={
            priceDetailMapvalues.discount_coupon_amount ? "Remove" : ""
          }
         
          perDayprice={priceDetailMapvalues?.total_property_addon_base_cost + priceDetailMapvalues.discount_coupon_amount}
          // + priceDetailMapvalues?.discount_coupon_amount).toFixed(2)}
          ExpPrice={parseFloat(priceDetailMapvalues.total_experience_base_cost).toFixed(2)}
          TaxPrice={parseFloat(priceDetailMapvalues.total_property_tax_cost).toFixed(2)}
          ServiceFee={
            parseFloat(priceDetailMapvalues.total_property_service_cost).toFixed(2) || 0
          }
          totalAmount={parseFloat(priceDetailMapvalues?.grand_total_booking_cost)?.toFixed(
            2
          )}
          noOfNight={moment(props.transid?.checkout_date).diff(
            moment(props.transid?.checkin_date),
            "days"
          )}
          DiscountPrice={
            priceDetailMapvalues.discount_coupon_amount
              ? "- ₹" + parseFloat(priceDetailMapvalues.discount_coupon_amount).toFixed(2)
              : ""
          }
        />
        <div style={{ textAlign: "center" }}>
          <button className="request-btn" onClick={createBooking}>
           Confirm and Pay
          </button>
        </div>
      </div>
}

        <div className="request-book-cancellation-box">
          {/* <h3>Cancellation Policy</h3>
         
      <div className='all-cancelation-category-inputs'>
       {cancellationData?.map((option) => (
      <div>
      
        {option.cancellation_slabs_details?.map((slab) => (
            <div key={slab.uid} className='request-cancellation-slab-info'>
            {slab.refund_percent}% refund {slab.remaining_days} days before the day of check-in
            </div>
        ))}
        
    
         </div>
         ))}
         </div> */}
         <CancellationPolicyCard cancellationData={cancellationData}/>
      
          {/* <p className="learn-more-text">Learn more</p> */}
          
          
        </div>
      </div>
 

{!isSmallScreen && 
      <div className="request-book-right-content ">
        <ViewBreakDownCard
        onClick={backToPropertyFunc}
          updateTransaction={updateTransaction}
          transactionId={props.transid ? props.transid.uid : ""}
          addons={addonDetails}
          // src="./assets/img/property.png"
          coupon={
            priceDetailMapvalues.discount_coupon_amount
              ? "Coupon applied"
              : "Enter coupon"
          }
          CouponLabelText={props.transid ? props.transid?.couponcode : ""}
          couponClick={handleCouponPop}
          removeText={
            priceDetailMapvalues.discount_coupon_amount ? "Remove" : ""
          }
          src={props.propdetails?.base_url_image + props.propdetails?.images[0]}
          starRating={props.propdetails?.Overall_rating}
          reviewNo={props.propdetails?.review_count}
          propertyName={props.propdetails?.property_name}
          entireProperty={props.propdetails?.space_type}
          perDayprice={priceDetailMapvalues?.total_property_addon_base_cost + priceDetailMapvalues.discount_coupon_amount}
          // + priceDetailMapvalues?.discount_coupon_amount).toFixed(2)}
          ExpPrice={parseFloat(priceDetailMapvalues.total_experience_base_cost).toFixed(2)}
          TaxPrice={parseFloat(priceDetailMapvalues.total_property_tax_cost).toFixed(2)}
          ServiceFee={
            parseFloat(priceDetailMapvalues.total_property_service_cost).toFixed(2) || 0
          }
          totalAmount={parseFloat(priceDetailMapvalues?.grand_total_booking_cost).toFixed(
            2
          )}
          noOfNight={moment(props.transid?.checkout_date).diff(
            moment(props.transid?.checkin_date),
            "days"
          )}
          DiscountPrice={
            priceDetailMapvalues.discount_coupon_amount
              ? "- ₹" + parseFloat(priceDetailMapvalues.discount_coupon_amount).toFixed(2)
              : ""
          }
        />
        <div style={{ textAlign: "center" }}>
          <button className="request-btn" onClick={createBooking}>
            Book Now
          </button>
        </div>
      </div>
}


<div className="coupopn-popop-parent">
{openModalCoupon && 
        
        <WrapperPopup 
        fluid={true}
       headingText='Enter Coupon Code'
        defaultSize={"0.50"}
        dimStyle={false}
        isVisible={true} 
        btnClass={false}
        closeModal={setOpenModalCoupon}
        setVisibleDocks={toggleVisibleDocks} 
        comp={
          <CouponComp  
          totalAmount={parseFloat(priceDetailMapvalues?.grand_total_booking_cost).toFixed(2)}
         transactionId={props.transid.uid}
          closeModal={setOpenModalCoupon}
          updateTransaction={updateTransaction}
          />
        }
         
        />}
      </div>




    </div>


  );
};

export default RequestToBookIn;
