import React from 'react'
import CardWrapper from '../../../Components/common/cardWrapper'
import { Link } from 'react-router-dom'

const HostTransaction = (props) => {
  return (
    <div className='host-transaction-content' onClick={props.onClick}>

      <Link style={{color:'#222'}} to='/hostUpcomingPayout'>
     <CardWrapper>
     <p className='transaction-text'>Upcoming Payouts</p>
        <h2>₹40,000</h2>
       <p className='view-transaction'>View Detail</p>
   
     </CardWrapper>
     </Link>
     <Link style={{color:'#222'}} to='/hostProcessedPayout'>
     <CardWrapper>
    <p className='transaction-text'>Processed Payout</p>
    <h2>₹39,000</h2>
    <p className='view-transaction' >View Detail</p>
     </CardWrapper>
     </Link>
    </div>
  )
}

export default HostTransaction
