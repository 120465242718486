import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import UploadEditImgComp from './UploadEditImgComp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import IconCircle from './IconCircle';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useDispatch } from 'react-redux';
import { deleteFailedImage, deleteSuccesImageAction} from '../../utils/redux/dropboxSLicer';

import DeletePopup from './DeletePopup';
import ButtonPopup from './ButtonPopup';
import DeleteImagePop from '../../Module/Host/EditListing/ListingDetail/DeleteImagePop';
import WrapperPopup from './wrapperPopup';
import { apiMainUser, isUrl } from '../../utils/constant';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { deleteEditFailedImage, deleteEditSuccesImageAction } from '../../utils/redux/EditDropBoxSlicer';
import { setPromptComp, setPromptOkay, setPromptText } from '../../utils/redux/GetPromptSlice';
import { useEffect } from 'react';
import { openSnackbar } from '../../utils/redux/SnackBarSlicer';

const ViewEditImageComp = (props) => {

    const [deleteIndex, setDeleteIndex] = useState({ image: null, index: null });
    const [deletePop, setDeletePop] = useState(false);
    const [deleteType, setDeleteType] = useState(null);

    const dispatch = useDispatch();
    const storeSel = useSelector((state) => state);

    const isPromptOk = storeSel?.PromptSlice?.isPromptOk;
    

    useEffect(() => {
      if (isPromptOk) {
        handleDelete();
        
        dispatch(setPromptOkay(false));
        dispatch(setPromptComp(false))
        dispatch(setPromptText(null))
      }
    }, [isPromptOk, dispatch]);
 
      const location = useLocation();
      const path = location.pathname;
      console.log(path,'pathDone')


   const deleteSuccessImage = async (imageId,index) => {
       const imageobj = imageId
       
       if (isUrl(imageobj.uploadFile)) {
            var parts = imageobj.uploadFile.split("/");
            var imageUrlValue = parts[parts.length - 1];
        }else{
            var imageUrlValue = imageobj.uploadFile
        }
        console.log(imageUrlValue,'allid')
        try {
            const apiUrl = `${apiMainUser}delete_property_images?property_id=${props.propId}&images_id=${imageUrlValue}`;
            const response = await axios.delete(apiUrl, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        
            dispatch(deleteEditSuccesImageAction(index))
            props.fetchData()
            
            setDeletePop(!deletePop);
            dispatch(openSnackbar({ message: 'Image deleted successfully!', severity: 'success' }));

 
    
        
        return response.data;
    } catch (error) {
        throw new Error('Failed');
    }

    };

    
    const toggleVisibleDocks = () => {
        setDeletePop(!deletePop);
    };



      const handleDelete = async () => {
        if (deleteType === 'success') {
          try {
            await deleteSuccessImage( deleteIndex.image, deleteIndex.index);
            dispatch(openSnackbar({ message: 'Image deleted successfully!', severity: 'success' }));

          } catch (error) {
            console.error('Error deleting the success image: ', error);
          }
        } else if (deleteType === 'failed') {
        
          dispatch(deleteEditFailedImage(deleteIndex.index));
          dispatch(openSnackbar({ message: 'Image deleted successfully!', severity: 'success' }));

        
            toggleVisibleDocks();
        }
    };
    


console.log(deletePop,'checkDelete')
 
  return (
    <div className="image-list-container">
        <div className='image-container-parent'>
          <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2}}
            >
      <Masonry 

      gutter='20px'>
          {/* succes Images loop here */}
<UploadEditImgComp
      propId={props.propId}
      fetchData={props.fetchData}
      />
      {props?.successImages &&
        props?.successImages?.map((image, index) => {
            // const imageUrlParts = image.uploadFile.split('/');
            // const imageName = imageUrlParts[imageUrlParts.length - 1];
            console.log(image,'checkImage1')
            return(
                
          <div key={index} className="image-container w-100" >
          <img className="w-100" src={image.uploadFile} alt="Selected" />

           <IconCircle 
           icon={faTrashCan}
           iconClass ='trash-icon'
           iconParentClass ='trash-icon-parent'
           onClick={() => {
            setDeleteIndex({ image, index }); 
            setDeleteType('success');
            // setDeletePop(true);
            // setPromptComp(true)
            dispatch(setPromptComp(true))
            dispatch(setPromptText('Are you sure you want to delete this image?'))
          }}
           />
          </div>
          )})}

          {/* Falied Images loop here */}
         {props?.failedImages &&
          props?.failedImages?.map((image, index) => {
          
 
            return(
             <div className=''>   
            <div key={index} className="image-container w-100">
             <img className="w-100" src={image.uploadFile} alt="Selected" />
             <div className="overlay-failed-images w-100 "></div>
       

           <IconCircle 
            icon={faTrashCan}
            iconClass ='trash-icon'
            iconParentClass ='trash-icon-parent'
            // onClick={() => dispatch(deleteFailedImage(index))}
              onClick={() => {
                // setDeleteIndex(index);
                setDeleteIndex({ image, index }); 
                setDeleteType('failed');
                // setDeletePop(true);
                dispatch(setPromptComp(true))
             dispatch(setPromptText('Are you sure you want to delete this image?'))


              }}

            />
             </div>
           <p className='failed-image-text'>{image.message} (10mb) </p>
             </div>
            )     
           })}
      
        </Masonry>
   
        </ResponsiveMasonry>
           </div>      
     
     <div className="co-host-details-popup">
        {/* {deletePop && (
          <WrapperPopup
            closeModal={setDeletePop}
            fluid={true}
            position='bottom'
            // dimMode={props.opaque ? props.opaque : false}
            defaultSize={"0.85"}
            dimStyle={false}
            // isVisible={openModalNav}
            isVisible={true}
            
            setVisibleDocks={toggleVisibleDocks} 
            comp={
              <DeleteImagePop
              handleDelete={handleDelete}
              closeModal={setDeletePop}
   
              />
            }
          />
        )} */}
      </div>
        
    </div>
  )
}

export default ViewEditImageComp;
