import React, { useState } from "react";
import { useEffect } from "react";
import { Component } from "react";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import HostNav from "../Components/common/hostNav";
import LeftTabsMenu from "../Module/Host/leftTabs";
import MobileLeftMenu from "../Module/User/MobileLeftMenu";
import UserBottomBar from "../Module/User/UserBottomBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { faBell, faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import FooterComponent from "../Pages/common/FooterComponent";

const Protected = (props, children) => {

  const isSmallScreen = window.innerWidth <= 500;
  //   const { Component } = props;

  const navigate = useNavigate();
  

  const handleScroll = () => {
    const hostTopFixedDiv = document.querySelector('.host-top-fixed-div');
    if (hostTopFixedDiv) {
      if (window.scrollY > 0) {
        hostTopFixedDiv.classList.add('scrolled');
      } else {
        hostTopFixedDiv.classList.remove('scrolled');
      }
    }
  };

  window.addEventListener('scroll', handleScroll);


  const movetoInsight =()=>{
    navigate('/host/insight')
  }

  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);



  //---------------------
  useEffect(() => {
    let login = localStorage.getItem("login");
    // console.log(localStorage.getItem("login"));
    // console.log(login);
    const urlParams = new URLSearchParams(window.location.search);
    const authToken = urlParams.get('auth');
    if (login === null && !authToken) {
      navigate("/login", { replace: true });
    }
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const authToken = urlParams.get('auth');


  let login = localStorage.getItem("login");
  //   console.log(localStorage.getItem("login"));
  //   console.log(login);
  if (login === null &&  !authToken) {
    navigate("/login", { replace: true });
  } else {
    // return props.wrapme == undefined ? (
    //   <Wrapper>{props.children}</Wrapper>
    // ) :
    return (
      <>
      {props.leftnav  ? (
          <>
          <div className="host-reservation">
          {!isSmallScreen &&  <LeftTabsMenu />}
          {/* {isSmallScreen && <MobileLeftMenu />} */}
          {isSmallScreen && 
          <div className="host-top-fixed-div">
          <div className="logo-box-fixed">
         <img src="./assets/img/jumboLogo.png" alt="" />
          </div>
          <div className="flex help-notification-div">
          <div className="flex">
          {/* <p>Help</p> */}
          {/* <FontAwesomeIcon icon={faCircleQuestion} className="top-fixed-icon" /> */}
          <img onClick={movetoInsight} width='20px' src="./assets/img/blackInsightImg.svg" alt="" />
          </div>
          <div className="flex">
          {/* <p>Notification</p> */}
          <FontAwesomeIcon icon={faBell} className="top-fixed-icon"/>

          </div>
          </div>
          </div>
      }
            {props.children}
            {/* <div className="host-side-footer">
            <FooterComponent/> 
            </div>            */}
          </div>
          {isSmallScreen && <UserBottomBar  />}
          </>
        ) : (
          <>
            <HostNav />
            <div className="host-childs">
            {props.children}
            </div>
          </>
        )}
        {!props.leftnav && props.mobilenav && isSmallScreen ? <UserBottomBar  /> : null}
      </>
    );
  }
};

export default Protected;
