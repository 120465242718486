import React, { useState } from "react";
import Button from "../../../Components/common/Button";
import { Controller, useForm } from "react-hook-form";
import InputComp from "../../../Components/common/textInput";
import FontComp from "../../../Components/common/fontComp";
import {
  faArrowLeft,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { apiPublicUser } from "../../../utils/constant";
import { useLocation } from "react-router-dom";

const NewPassPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const currentPass = watch('current_pass');
  const pass = watch('password');

  const location = useLocation();
  const { token } = location.state;

  const onSubmit = async (data) => {
    console.log(data,'hey')
 
        
    try {
      if (data.password !== data.confirmpassword) {
        console.log('Current password and new password are be the same');
        return;
      }
      const response = await axios({
        method:'post',
        url: apiPublicUser + 'reset_password',
        data: {
          newPassword: data.password,
          token: token,   
        
        },
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        
      }
      
      );
      console.log(response.data);
      if (response.data.status === 'success') {
      
        
      } else {
        
      }
    } catch (error) {
      console.error(error);
    }
  };




  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  return (
    <div className="login-page newPassPage forgot-page">
      <div className="login-left-box">
        <img src="./assets/img/jumboLogo.png" alt="" />
        <div className="login-form-section">
          <h3 className="login-welcome-text">Enter New Password</h3>
          <p className="forgot-text">
            Setting a New Password: Easy and Hassle-Free
          </p>
          <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="single-input-box">
              <Controller
                control={control}
                name="password"
                defaultValue=""
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType={showPassword ? "text" : "password"}
                    inputClass="login-form-input "
                    name="password"
                    inpPlace="Enter your password"
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              <FontComp
                icon={showPassword ? faEye : faEyeSlash}
                onClick={toggleShowPassword}
                size="lg"
                color="#B8B8B8p"
              />
              {errors.password && <span>{errors.password.message}</span>}
            </div>
            <div className="single-input-box">
              <Controller
                control={control}
                name="confirmpassword"
                defaultValue=""
                rules={{
                  required: "Password is required",
                validate: (value) => value === pass || 'Confirm password must match the new password',

                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType={showConfirmPassword ? "text" : "password"}
                    inputClass="login-form-input "
                    name="confirmpassword"
                    inpPlace="Confirm Password"
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              <FontComp
                icon={showConfirmPassword ? faEye : faEyeSlash}
                onClick={toggleShowConfirmPassword}
                size="lg"
                color="#B8B8B8"
              />
              {errors.confirmpassword && (
                <span>{errors.confirmpassword.message}</span>
              )}
            </div>
            <Button btnclasses="tab-btn" type="submit" btnText="Continue" />
          </form>
          <p className="back-to-login">
            <span>
              <FontComp
                icon={faArrowLeft}
                // onClick={handleClick}
                fontclassname="my-icon-class"
                size="lg"
                color="#B8B8B8"
              />
            </span>
            <span className="back-to-login-text">Back to Login?</span>
          </p>
        </div>
      </div>
      <img
        src="./assets/img/EnterPassImg.png"
        className="login-page-img"
        alt=""
      />
    </div>
  );
};

export default NewPassPage;
