import React, { useState } from 'react';
import HeadingComp from '../EditListing/HeadingComp';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBank } from '@fortawesome/free-solid-svg-icons';
import BankAddressPop from './BankAddressPop';
import BankDetailsPop from './BankDetailsPop';

const BankPayoutPop = (props) => {
  const { register, handleSubmit } = useForm();
  const [selectedOption, setSelectedOption] = useState('');
  const [payoutAddressPop, setPayoutAddressPop] = useState(false);
  const [bankDetailsPop, setBankDetailsPop] = useState(false);

  const onSubmit = (data) => {
    console.log(data); // Handle form submission data here
    if (selectedOption === 'bankValue') {
      setBankDetailsPop(true); // Set bankDetailsPop to true when bank is selected
    }
  };

  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue === selectedOption ? '' : selectedValue);
  };

  return (
    <div className='payout-popup-parent'>
      {!payoutAddressPop && !bankDetailsPop ? (
        <>
          <HeadingComp headingText='Set up Payouts' />
          <form className='payout-pop-form' onSubmit={handleSubmit(onSubmit)}>
            <h4 className='payout-pop-heading-text'>Let's add a Payout Method</h4>
            {/* ... (other form elements) ... */}
            <div className='payout-popup-label-parent'>
              <label className='payout-popup-label'>
                <p className='bank-icon-text-div'>
                  <FontAwesomeIcon icon={faBank} size='2x' />
                  <p className='bank-payout-pop-para'>
                    <span className='span-text'>Bank Transfer</span><span>3-5 Business days</span>
                  </p>
                </p>
                <input
                  type="checkbox"
                  value="bankValue"
                  {...register('bank')}
                  checked={selectedOption === 'bankValue'}
                  onChange={handleRadioChange}
                />
              </label>
            </div>
            
            <div className='bank-payout-adress'>
              <h4 className='payout-pop-heading-text'>Use this address</h4>
              <label className='payout-popup-address-label'>
                <input
                  type="checkbox"
                  value="dummyAddressValue"
                  {...register('dummyAddress')}
                />
                <p className='dummy-address-label'>Lodha Cas viva tower 4</p>
              </label>
            </div>

            <p onClick={() => setPayoutAddressPop(true)} className='new-address-text'>Add new Address</p>

            <div className="clear_apply-btn-box paddingCustomAllSide">
              <p onClick={() => props.closeModal(false)} className="clear-btn">
                Cancel
              </p>
              <button className="save-edit-btn" type="submit">
                Apply
              </button>
            </div>
          </form>
        </>
      ) : payoutAddressPop ? (
        <BankAddressPop closeModal={props.closeModal} />
      ) : (
        <BankDetailsPop closeModal={props.closeModal}/>
      )}
    </div>
  );
};

export default BankPayoutPop;
