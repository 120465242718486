import React, { useEffect, useState } from "react";
import CardWrapper from "../../../Components/common/cardWrapper";
import { useNavigate } from "react-router-dom";
import Save_ExitComp from "../../../Components/common/save_ExitComp";
import { useSelector } from "react-redux";
import { formatIndianRupeesFunc } from "../../../utils/constant";

const ReviewListing = (props) => {    
  const storeSel = useSelector((state) => state);

  const base_price = storeSel.pricingCartSlicer?.perNightCost;
  const title = storeSel.apiDataSlice.propertyName;

  //use below line when image error resolve--------------
  const reduxImg = storeSel?.imageSlice?.selectedImages?.[0].uploadFile;

  console.log(reduxImg,'allImagesCheck')

  const navigate = useNavigate();

  const [api, setApi] = useState(["createProperty_metadata_bulk"]);
  const [method, setMethod] = useState("");
  const [addMethod, setAddMethod] = useState("");
  // const propertyId = sessionStorage.getItem("property");

  //
  const Currenturl = window.location.href;
  console.log(Currenturl, "tyt");
  const CurrentPage = new URL(Currenturl);
  const path = CurrentPage.pathname;
  console.log(path, "qqq");

  //

  useEffect(() => {
    const PropIddata = storeSel.apiDataSlice?.propId;

    const preferenceIddata = storeSel.apiDataSlice?.preferenceId;
    const addOnPropId = storeSel.apiDataSlice?.addOnPropId;

    let updatedApi = ["updateProperty/" + PropIddata, ...api];

    let updatedMethod = method;
    let updatedAddMethod = addMethod;

    if (preferenceIddata) {
      updatedApi.push("updatePropertyPref/" + preferenceIddata);
      updatedMethod = "patch";
    } else {
      updatedApi.push("add_preference");
      updatedMethod = "post";
    }

    // if (addOnPropId) {
    //   updatedApi.push("update_property_addons/" + addOnPropId);

    //   updatedAddMethod = "patch";
    // } else {
    //   updatedApi.push("add_property_addons");
    //   updatedAddMethod = "post";
    // }
    updatedApi.push("apply_property_for_publishing/" + PropIddata);

    setApi(updatedApi);
    setMethod(updatedMethod);
    setAddMethod(updatedAddMethod);
  }, []);
  console.log(api, "iip");

  const navigateTo = () => {
    console.log(true, "iip");
    navigate("/host/createlisting/hostCongrats");
  };
  
  return (
    <div className="review-listing-page">
      <div className="review-listing-content">
        <h2 className="review-head-text">Review Listing</h2>
        <p className="review-para-text">
          Take a Look at What Your Guests Will See - Ensure Your Listing Looks
          Perfect!
        </p>
        <div className="review-listing-flex-box">
          <CardWrapper wrapperClass="review-listing-card">
            <div className="review-img-box">
              <img className="review-property-img" src={reduxImg} alt="" />
            </div>
            <div className="review-listing-textbox">
              <p className="review-listing-title">{title}</p>
            </div>
            <p className="review-listing-reveiw-price">              
              ₹{formatIndianRupeesFunc(base_price)} per night
            </p>
          </CardWrapper>

          <div className="review-listing-right-box">
            <h3>Whats Next?</h3>
            <div className="review-listing-right-text-box">
              <p className="review-listing-right-heading-text">
                <span className="number-circle">1</span> Confirm a few details
                and publish
              </p>
              <p>
                We will let you know if you need to verify your identity or
                register with the local government.
              </p>
            </div>
            <div className="review-listing-right-text-box">
              <p className="review-listing-right-heading-text">
                <span className="number-circle">2</span> Set up your calendar
              </p>
              <p>
                Choose which dates your listing is available. It will be visible
                24 hours after you publish.
              </p>
            </div>
            <div className="review-listing-right-text-box">
              <p className="review-listing-right-heading-text">
                <span className="number-circle">3</span> Set up your calendar
              </p>
              <p>
                Set house rules, select a cancellation policy, choose how guests
                book and more.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-btns-fixed-box review-bottom-btn">
        <Save_ExitComp
          isButton={true}
          btnTextExit="Submit"
          // onClick={navigateTo}
          className="common-next-btn"
          method={method}
          apis={api}
          addMethod={addMethod}
          path={path}
          lastpage={true}
          // navigateTo={navigateTo}
        />
        {/* <button onClick={navigateTo} type="submit" className="common-next-btn">
          Next
        </button> */}
      </div>
    </div>
  );
};

export default ReviewListing;
