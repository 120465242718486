import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ButtonBase } from "@mui/material";

const Button = (props) => {
  const Element = props.as || "button";  
  const runOnClick = ()=>{
    props.onClick()
  }

  const content = (    
    <Element
    className={
      props.btnclasses === undefined ? "btn" : "btn " + props.btnclasses 
    }
    onClick={props.onClick !== undefined ? runOnClick : null}
    type={props.type !== undefined ? props.type : null}
    name={props.name !== undefined ? props.name : null}      
  >
    <ButtonBase className={"btn-ripple"}/>
    {props.icon && (
      <span
        className={
          props.iconBtnclass === undefined
            ? "btn-icon"
            : "btn-icon " + props.iconBtnclass
        }
      >
        <img src={props.icon} />
      </span>
    )}
    {props.faicon && (
      <span
        className={
          props.iconBtnclass === undefined
            ? "btn-icon"
            : "btn-icon " + props.iconBtnclass
        }
      >
        {props.faicon}
      </span>
    )}
    {props.btnText}
    {props.span && <span>{props.span}</span>}
  </Element>
  )
  return (    
    <>
    {props.link ? <Link className={props.priceLinkClasss} to={props.link}>{content}</Link> :
    (props.href ? <a href={props.href}>{content}</a> : content)}
    </>
  );
};

export default Button;
