import React, { useEffect, useState } from "react";
import HeadingComp from "../HeadingComp";
import Button from "../../../../Components/common/Button";
import axios from "axios";
import { apiMainUser } from "../../../../utils/constant";
import Lottie from "react-lottie";
import DeleteImagePop from "./DeleteImagePop";
import WrapperPopup from "../../../../Components/common/wrapperPopup";
import DragDropBox from "../../CreateListing/dragDropBox";
import ViewImageComp from "../../../../Components/common/ViewImageComp";
import UploadImgComp from "../../../../Components/common/UploadImgComp";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSelectedImages } from "../../../../utils/redux/dropboxSLicer";
import { setEditSelectedImages } from "../../../../utils/redux/EditDropBoxSlicer";
import ViewEditImageComp from "../../../../Components/common/ViewEditImageComp";
import UploadEditImgComp from "../../../../Components/common/UploadEditImgComp";

const EditPhotos = (props) => {
 
//  const successImages = storeSel?.imageSlice?.selectedImages;
const storeSel = useSelector((state) => state);
const isSmallScreen = window.innerWidth <= 500;

 const failedImages = storeSel?.EditImageSlice?.failedEditImages;
 const successImages = storeSel?.EditImageSlice?.selectedEditImages;

 const successImageMap = props?.allData?.images?.map((x) => ({
  uploadFile: props?.allData.base_url_image + x,
   localFile: '',
   message: 'Upload successful',
  status: 'success',
}))


const fetchDataCallFromIn = ()=>{
  props.fetchData()
}


    const dispatch = useDispatch()

    useEffect(()=>{
      dispatch(setEditSelectedImages(successImageMap))
    },[])


  return (
    <>
    <div className="edit-photos-parent "> 
    
    <div className={`edit-photos-box  ${isSmallScreen ? 'edit-amenities-box mt-55' : ''}`}>
   {/* <DragDropBox/> */}
      {successImages?.length > 0 || failedImages?.length > 0 ? 
                <ViewEditImageComp
                propId={props.propId}
                  successImages={successImages}
                  failedImages={failedImages}
               

                  fetchData={props.fetchData}
                  
                />
               :  <UploadEditImgComp
               propId={props.propId}
               fetchData={props.fetchData}
               />
            }
            </div>
            </div>
    </>
  );
};

export default EditPhotos;
