import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import InputComp from "../../../../Components/common/textInput";
import Button from "../../../../Components/common/Button";
import TextareaComp from "../../../../Components/common/textArea";
import HeadingComp from "../HeadingComp";
import { useSelector } from "react-redux";
import EditSaveBtn from "../EditSaveBtn";
import { apiMainUser, checkErrors } from "../../../../utils/constant";
import APIHit from "../../../../utils/apiHit";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditorWrapper from "../../../../Components/common/CkEditorComp";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../../utils/redux/SnackBarSlicer";
// import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
// import { Essentials } from '@ckeditor/ckeditor5-essentials';
// import { Paragraph } from '@ckeditor/ckeditor5-paragraph';

// import { BlockToolbar } from '@ckeditor/ckeditor5-ui';
// import { HeadingButtonsUI } from '@ckeditor/ckeditor5-heading';
// import { ParagraphButtonUI } from '@ckeditor/ckeditor5-paragraph';


const EditRules = (props) => {
  const [postData, setPostData] = useState(null);
  const [showerror, setError] = useState(null);


  let url = apiMainUser+"createProperty_metadata_bulk";
  console.log(props.propId, "tulips");

  const dispatch = useDispatch()


  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postDatavalue = {
      property_id: props.propId,
      meta: [
        {
          metadata_key: "rules",
          metadata_value:  data.rules,
        },
      ],
    };
    setPostData(postDatavalue);
  };

  const handleSuccess = (res) => {
    if (res.status === "success") {
      props.closeModal()
      props.fetchData()
      dispatch(openSnackbar({ message: 'updated Successfully', severity: 'success' }));   
    }
  };

  const handleFailure = (res) => {
    console.log(res);
    if (res && res.status === 404) {
      dispatch(openSnackbar({ message: 'Something went Wrong', severity: 'success' }));

      setError("");
    } else {
      setError("");
      dispatch(openSnackbar({ message: 'Something went Wrong', severity: 'success' }));
    }
  };

  const preCondition = () => {
    let ercheck = checkErrors(errors);
    console.log(ercheck, "djjd");
    return ercheck;
  };

  return (
    <>
   
    <div className="edit-listing-title-box edit-listing-description-box " style={{marginTop:'2rem'}}>
      
      <form className="" onSubmit={handleSubmit(onSubmit)}>
        <div >
          <div className="single-input-box w-100 mt-20">
            <Controller
              control={control}
              name="rules"
              defaultValue={props.rulesText}
              rules={{
                required: "rule is required",
              }}
              render={({ field: { onChange, value } }) => (
                
                <CKEditorWrapper value={value} onChange={onChange} />

              )}
            />
            {errors.rules && <span>{errors.rules.message}</span>}
          </div>

          <APIHit
              handleSubmit={handleSubmit(onSubmit)}
              url={url}
              apidata={postData}
              successResponseCode={201}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              precondition={preCondition}
              method={"post"}
              auth={true}
            >
          <div className="clear_apply-btn-box paddingCustomYSide">
          
            <p onClick={() => props.closeModal(false)} className="clear-btn">
              Cancel
            </p>
            <button type="submit" className="save-edit-btn">Save</button>
          </div> 
             
            </APIHit>
        </div>
      </form>
    </div>
    </>
  );
};

export default EditRules;
