import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { apiMainUser } from '../../../utils/constant';
import { openSnackbar } from '../../../utils/redux/SnackBarSlicer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {

const { register, handleSubmit, formState: { errors },reset } = useForm();

const [tokenExists, setTokenExists] = useState(false); // State to track token existence
const [emailParam, setEmailParam] = useState(''); // State to track token existence
const [fullnameParam, setFullNameParam] = useState(''); // State to track token existence



useEffect(() => {
  const token = localStorage.getItem('token');
  if (token) {
    setTokenExists(true);
  }
}, []);


useEffect(() => {
  
  const extractAuthToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParams = urlParams.get('email');
    const fullNameParams = urlParams.get('fullname');

    if (emailParams) {
      
      setEmailParam(emailParams)
      // localStorage.setItem("login", true);;
      // localStorage.setItem('token', authToken)
    }
    if(fullNameParams){
      setFullNameParam(fullNameParams)
    }

  };

  extractAuthToken();
}, [])

const navigate = useNavigate()

const moveToLogin = ()=>{
  navigate('/login')
}


const dispatch = useDispatch()

  const onSubmit = async (data) => {

    try {
      let apiUrl = !tokenExists ? apiMainUser + 'contact_us' : apiMainUser + 'create_ticket';
      const formData = new FormData();
      // formData.append('fullname', data.fullname);
      if (!tokenExists) {
        formData.append('email', data.email);
      }


      formData.append('subject', data.subject);
      formData.append('comment', data.message);
      formData.append('ticket_type', 'contact_us');
  
      formData.append('status', 'created');
  
      const headers = {};
    if (tokenExists) {
      const token = localStorage.getItem('token');
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(apiUrl, {
      method: 'POST',
      body: formData,
      headers: headers,
    });
  
     console.log(response,'checkAllResponseNew')
      if (response.status == 200) {
        console.log('Form data submitted successfully');
        dispatch(openSnackbar({ message: 'Message sent successfully!', severity: 'success' }));
        reset()
      } else {
        console.error('Failed to submit form data');
        dispatch(openSnackbar({message:'Please use registered email id', severity: 'error'}));
      }
    } catch (error) {
      
      console.error('Error occurred while submitting form data', error);
    }
  };
  
  return (
    <div className="contact-form-container">
      <h2>Contact Us</h2>  
      {!tokenExists && !emailParam ? <p className='font-small regiester-email-text'>Pleae use your registered email id or <span className='underline pointer' onClick={moveToLogin}>Login</span></p> :
      <p className='font-small regiester-email-text'>This form will be submitted with your logged in email id</p>}
      <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
      {!tokenExists && (
          <>
            <div className="form-group">
              <label htmlFor="message">Full Name:</label>
              <input
               id="fullname"
               defaultValue={fullnameParam} 
               readOnly={fullnameParam ? true : false}
                {...register('fullname', { required: 'Full name is required' })}
                 className="form-control">

                 </input>
              {errors.message && <p className="error-message">{errors.message.fullname}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="message">Your Registered Email:</label>
              <input 
              type='email' 
              id="email"  
              defaultValue={emailParam} 
              readOnly={emailParam ? true : false}
               {...register('email', { required: 'Email is required' })} 
               className="form-control">
               </input>
              {errors.message && <p className="error-message">{errors.message.email}</p>}
            </div>
          </>
        )}
    
        <div className="form-group">
          <label htmlFor="subject">Subject:</label>
          <select id="subject" {...register('subject', { required: 'Please select a subject' })} className="form-control">
            <option value="">Select...</option>
            <option value="Payment made but booking not confirmed">Payment made but booking not confirmed</option>
            <option value="Cancellation of booking">Cancellation of booking</option>
            <option value="Changing dates of a booking">Changing dates of a booking</option>
            <option value="Publishing a property on our platform">Publishing a property on our platform</option>
            <option value="Awaiting property listing approval for more than 48 hours">Awaiting property listing approval for more than 48 hours</option>
            <option value="Refund related queries">Refund related queries</option>
            <option value="Emergency at your stay">Emergency at your stay</option>
            <option value="Deletion of account">Deletion of account</option>
        </select>
          {errors.subject && <p className="error-message">{errors.subject.message}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea id="message" {...register('message', { required: 'Message is required' })} className="form-control"></textarea>
          {errors.message && <p className="error-message">{errors.message.message}</p>}
        </div>
        <button type="submit" className="submit-btn">Submit</button>
      </form>
    </div>
  );
};

export default ContactUs;
