import React, { useEffect } from 'react'
import {  Controller,useForm } from 'react-hook-form';
import TextareaComp from '../../Components/common/textArea';
import Button from '../../Components/common/Button';
import CardWrapper from '../../Components/common/cardWrapper';
import FontComp from '../../Components/common/fontComp';
import HostDOBPop from '../../Module/Host/Profile/HostDOBPop';
import { useState } from 'react';
import WrapperPopup from '../../Components/common/wrapperPopup';
import HostWorkPop from '../../Module/Host/Profile/HostWorkPop';
import HostLanguagePop from '../../Module/Host/Profile/HostLanguagePop';
import HostEditPhotosPop from '../../Module/Host/Profile/HostEditPhotosPop';
import axios from 'axios';
import { apiMainUser } from '../../utils/constant';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditorWrapper from '../../Components/common/CkEditorComp';
import SkeletonLoader from '../../Components/common/SkeletonLoader';
import DOMPurify from 'dompurify';
import { openSnackbar } from '../../utils/redux/SnackBarSlicer';
import { useDispatch } from 'react-redux';

const HostCreateProfilPage = () => {

    const [openModalBirth, setOpenModalBirth] = useState(false);
    const [openModalGallery, setOpenModalGallery] = useState(false);
    const [usersData,setUsersData ] = useState(null)


  const [openModalWork, setOpenModalWork] = useState(false);
  const [openModalLanguage, setOpenModalLanguage] = useState(false);

  
  const dispatch = useDispatch()

    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
      } = useForm()


  
      
  const fetchData = async () => {
   
    try {
      const response = await axios
        .get( apiMainUser + "get_user_detail", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setUsersData(response.data.data[0]);
          
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
   
  }, []);

const birthDateString = usersData?.dob;
const formattedBirthDate = birthDateString?.substring(0, 10);

 
    const onSubmit = async (data) => {
      const formData = new FormData();
      formData.append('about_me', data.description);
    

      try {
        const response = await axios({
          method:'patch',
          url: apiMainUser + 'update_user',
        
          data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          
        }
        
        );
        if(response.data.status === 'success'){
          fetchData()
          dispatch(openSnackbar({ message: 'About updated succesfully!', severity: 'success' }));


        
        }
      } catch (error) {
        console.error(error);
        dispatch(openSnackbar({ message: 'Something went wrong!', severity: 'error' }));

      }
    };

      const toggleVisibleDocks = () => {
      setOpenModalBirth(false)
      setOpenModalGallery(false)
      setOpenModalLanguage(false)
      setOpenModalWork(false)

        

      };
    const encodedAbout = DOMPurify.sanitize(usersData?.about_me, { USE_PROFILES: { html: true } });


  return (
    <div className=' host-create-profile-page'>
      {/* {!usersData ?  */}
     <div className="host-edit-img-box">
        <img className='host-img' src={usersData?.image ? usersData?.image : './assets/img/notFoundImage.png'} alt="" />
        <div onClick={() => setOpenModalGallery(true)} className='edit-img-box'>
        <img src="./assets/img/EditImg.png" alt="" />
        </div>
     </div>
{/* : <SkeletonLoader containerClassName='host-edit-img-box-skeleton' circle={true} width={150} height={150}/>
  } */}
    
     <div className="host-create-profile-content-box">
        <h2 className='about-text'>About You</h2>
      

      <form className="host-profile-discription-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="single-input-box">
        {/* <textarea 
              {...register('description', { required: true })}
              id="Firstname"
              placeholder="Describe about you"
              defaultValue={usersData?.about_me || ''} 
              rows="6"
           cols="30"
            /> */}
              <Controller
              name="description"
              control={control}
              // defaultValue={DOMPurify.sanitize(he.decode(usersData?.about_me)) || ''}
              defaultValue={encodedAbout || ''}

              render={({ field: { onChange, value } }) => (
                
                
                <CKEditorWrapper value={value} onChange={onChange} />

              )}
            />
           

      {errors.description && <span>{errors.description.message}</span>}
          </div>
      <button type="submit" className='save-edit-btn'>Save</button>
    </form>


      <h3 className='your-profile-text'>Your Profile</h3>
      <p className='your-profile-disc-text'>Your Jumbo Profile information is shared to help host and guest get to know your better accross the platform</p>
     <p className='tap-to-card'>Tap card to fill or change the details</p>
      <div className="edit-more-detail-box">

        <CardWrapper onClick={() => setOpenModalBirth(true)} wrapperClass='host-create-profile-card'>
        <img style={{alignSelf:'start'}} className='' src='./assets/img/DobImg.svg' alt="" />
        <div className='flex' style={{flexDirection:'column',gap:'0.7rem'}}>
        <p>Decade i was born</p>
        {usersData ? 
      <p>{formattedBirthDate ? formattedBirthDate :  <u>Add your birth date</u>}</p>
     
      :  <SkeletonLoader  width={100} height={20}/>
     }
        </div>
        </CardWrapper>

        <CardWrapper onClick={() => setOpenModalWork(true)}  wrapperClass='host-create-profile-card'>
        <img style={{alignSelf:'start'}}  className='' src='./assets/img/WorkImg.svg' alt="" />
        <div className='flex' style={{flexDirection:'column',gap:'0.7rem'}}>
        <p>My Work</p>
        {usersData ? (
         <p>
        {usersData?.my_work ? (usersData?.my_work?.length > 50 ? `${usersData?.my_work.slice(0, 50)}...` : usersData?.my_work) : <p><u>Add work</u></p>}
        </p>
       ) : (
        <SkeletonLoader width={100} height={20}/>
       )}
        </div>
        </CardWrapper>

        <CardWrapper onClick={() => setOpenModalLanguage(true)} wrapperClass='host-create-profile-card'>
       
        <img style={{alignSelf:'start'}}  className='' src='./assets/img/LanguageImg.svg' alt="" />
        <div className='flex' style={{flexDirection:'column',gap:'0.7rem'}}>
        <p>Language I speak</p>
        {usersData ? 
       (usersData?.languages ? <p>{usersData?.languages}</p > : <p><u>Add languages</u></p> 
      ) : (
        <SkeletonLoader width={100} height={20}/>
       )}
        </div>
        </CardWrapper>

      </div>
     </div>

     <div className=" host-profile-edit-photos-popup ">
        {openModalGallery && (
          <WrapperPopup
          fluid={true}

          defaultSize={"0.60"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks}
            closeModal={setOpenModalGallery}
            comp={
              <HostEditPhotosPop
              fetchData={fetchData} usersData={usersData} 
                closeModal={setOpenModalGallery}
              />
            }
          />
        )}
      </div>
   
     <div className=" pricing-details-popup">
        {openModalBirth && (
          <WrapperPopup
          fluid={true}

          defaultSize={"0.60"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks}
            closeModal={setOpenModalBirth}
            comp={
              <HostDOBPop
              fetchData={fetchData} usersData={usersData} 
                closeModal={setOpenModalBirth}
              />
            }
          />
        )}
      </div>

      <div className=" pricing-details-popup">
        {openModalWork && (
          <WrapperPopup
          fluid={true}

          defaultSize={"0.60"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks}

            closeModal={setOpenModalWork}
            comp={
              <HostWorkPop
              fetchData={fetchData} usersData={usersData} 
                closeModal={setOpenModalWork}
              />
            }
          />
        )}
      </div>

      <div className=" pricing-details-popup ">
        {openModalLanguage && (
          <WrapperPopup
          fluid={true}
          defaultSize={"0.60"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
            closeModal={setOpenModalLanguage}
            comp={
              <HostLanguagePop
                fetchData={fetchData} usersData={usersData} 
                closeModal={setOpenModalLanguage}
              />
            }
          />
        )}
      </div>

    </div>
  )
}

export default HostCreateProfilPage
