import React, { useState, useEffect } from "react";
import HeadingComp from "../HeadingComp";
import Button from "../../../../Components/common/Button";
import { apiMainUser, apiPublicUser, capitalizeText } from "../../../../utils/constant";
import axios from "axios";
import { openSnackbar } from "../../../../utils/redux/SnackBarSlicer";
import { useDispatch } from "react-redux";

const EditAmenities = (props) => {
  const { selectedAmenities = [] } = props;
  const [amenities, setAmenities] = useState([]);
  const [allAmenities, setAllAmenities] = useState([]);
  const [selectedAmenityIds, setSelectedAmenityIds] = useState([]);

  const amenitiesDetails = props.allData?.amenities_details;

  console.log(amenitiesDetails,'CheckAllDetailsAmen')

  const dispatch = useDispatch()

  console.log(props.propId, "tww");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiPublicUser + "all_filters", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAllAmenities(data.data.amenities_category);

          const amenitiesDetailsValue = data.data.amenities_category.flatMap(category => category.amenities_details);
          console.log(amenitiesDetailsValue,'checkvalues')
          setAmenities(amenitiesDetailsValue.map((amenity) => amenity.amenity_name));
          setSelectedAmenityIds(amenitiesDetails.map((amenity) => amenity.uid));
        } else {
          console.error("Failed to fetch amenities:", response.status);
        }
      } catch (error) {
        console.error("Error fetching amenities:", error);
      }
    };

    fetchData();
  }, [amenitiesDetails]);


  console.log(allAmenities,'CheckAllAmen')

  const handleAmenityChange = (event, uid) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedAmenityIds([...selectedAmenityIds, uid]);
    } else {
      setSelectedAmenityIds(selectedAmenityIds.filter((id) => id !== uid));
    }
  };

  const isSmallScreen = window.innerWidth <= 500;

  const handleSave = async () => {
    try {
      // Convert the selectedAmenityIds to strings
      const metadataValue = selectedAmenityIds.map(String);

      const response = await axios.post(
        apiMainUser + "createProperty_metadata_bulk",
        {
          property_id: props.propId,
          meta: [
            {
              metadata_key: "amenities",
              metadata_value: metadataValue.join(","), // Convert array to comma-separated string
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      if (response.data.status === "success") {
        props.fetchData();
        props.closeModal();
        dispatch(openSnackbar({ message: 'Amenities updated Successfully!', severity: 'success' }));

        // Handle success response
      } else {
        console.error("Failed to save amenities:", response.status);
        dispatch(openSnackbar({ message: 'Something went Wrong', severity: 'error' }));

      }
    } catch (error) {
      console.error("Error saving amenities:", error);
      dispatch(openSnackbar({ message: 'Something went Wrong', severity: 'error' }));

    }
  };

  return (
    <>
<div className={`amenities-edit-parent mt-20  ${isSmallScreen ? 'paddingCustom2AllSide' : ''}`}>

  <div className="edit-amenities-box">
    {allAmenities.map((category, index) => (
    <div key={index}>
     {category.amenities_details.length > 0 && <h2 className="mt-24 mb-10">{capitalizeText(category.category_type)}</h2>}
     <div className="edit-aminities-flex">
      {category.amenities_details.map((amenity, amenityIndex) => (
        <div className="amenity" key={amenityIndex}>
          <input
            type="checkbox"
            id={`amenity${amenity.id}`}
            value={amenity.amenity_name}
            onChange={(e) => handleAmenityChange(e, amenity.id)}
            checked={selectedAmenityIds.includes(amenity.id)}
          />
          <label htmlFor={`amenity${amenity.id}`}>
            {amenity.amenity_name}
          </label>
        </div>
      ))}
      </div>
    </div>
  ))}
</div>
      
    </div>





    <div className="clear_apply-btn-box  pb-2 fixed-bottom-btn">
        <p onClick={() => props.closeModal(false)} className="clear-btn">
          Cancel
        </p>
        <Button
          btnText="Save"
          btnclasses="save-edit-btn"
          onClick={handleSave}
        />
    </div>
    </>
  );
};

export default EditAmenities;
