
// import React, { useEffect, useRef, useState } from 'react';
// import { useForm, Controller } from 'react-hook-form';
// import EmojiPicker from 'emoji-picker-react';
// import { apiMainUser } from '../../../utils/constant';
// import axios from 'axios';

// const EmojiPickerComponent = (props) => {
//   const { control, handleSubmit, setValue,reset } = useForm();
//   const [showPicker, setShowPicker] = useState(false);
//   const emojiPickerRef = useRef(null);

//   const onEmojiClick = (emoji) => {
//     console.log(emoji,'checkEmoji')
//     // setValue('message', (prevInput) => prevInput + emoji.emoji);
//     setValue('message', (prevMessage) => prevMessage + emoji.emoji);
//     setShowPicker(false);
//   };
  

//   const handleClickOutside = (event) => {
//     if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
//       setShowPicker(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener('click', handleClickOutside);
//     return () => {
//       document.removeEventListener('click', handleClickOutside);
//     };
//   }, []);

//   const onSubmit = async (data) => {

//     try {
//       const payload = {
//         lead_event_id: props.lead_events,
//         message: data.message,
//         chat_room_id: props.room_id,
//       };

//       // Call your API here
//       const response = await axios.post( apiMainUser + 'create_chat', payload, {
//         headers: {
//           Authorization: 'Bearer ' + localStorage.getItem('token'),
//         },
//       });

//       console.log('API Response:', response.data);

//       // Reset the form after successful API call
//       reset();
//     } catch (error) {
//       console.error('API Error:', error);
//     }
//   };


//   return (
//     <div className="emoji-picker-div" ref={emojiPickerRef}>
//       <form onSubmit={handleSubmit(onSubmit)} className="message-form">
//         <div className="emoji-picker-wrapper flex">
//         <button
//             type="button"
//             onClick={() => setShowPicker((prev) => !prev)}
//             className="emoji-button pointer"
//           >
//             😀
//           </button>
//           <Controller
//             name="message"
//             control={control}
//             defaultValue=""
//             render={({ field }) => (
//               <input
//                 type="text"
//                 {...field}
//                 placeholder='Start typing...'
//                 className="message-input"
//               />
//             )}
//           />
//                <button type="submit" className="send-btn pointer">
//                 <img src="/assets/img/sendIcon.png" alt="" />
//                </button>
         
//         </div>
//         {showPicker && (
//           <EmojiPicker onEmojiClick={onEmojiClick} />
//         )}
//         {/* <button type="submit" className="submit-button">Submit</button> */}
//       </form>
//     </div>
//   );
// };

// export default EmojiPickerComponent;
import React, { useEffect, useRef, useState } from 'react';
import EmojiPicker from 'emoji-picker-react';
import axios from 'axios';
import { apiMainUser } from '../../../utils/constant';

const EmojiPickerComponent = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const emojiPickerRef = useRef(null);

  const onEmojiClick = (emoji) => {
    setInputValue((prevInput) => prevInput + emoji.emoji);
    setShowPicker(false);
  };

  const handleClickOutside = (event) => {
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const onSubmit = async (e) => {
  
    e.preventDefault();
    const currentDate = new Date();

   const options = {
    year: 'numeric',
     month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'Asia/Kolkata',
     timeZoneName: 'short',
  };
  const isoFormattedDate = currentDate.toLocaleString('en-US', options);
    try {

      const newMessage = {
        message_type: '',
        message: inputValue,
        createdon: isoFormattedDate,
        lead_event_id: props.lead_events,
        updatedon: isoFormattedDate,
        message_from: 1,
        you: 1,
      };

      const payload = {
        lead_event_id: props.lead_events,
        message: inputValue,
        chat_room_id: props.room_id,
      };

      // Call your API here
      const response = await axios.post(apiMainUser + 'create_chat', payload, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });

      console.log('API Response:', response.data);
      props.updateMessageArray(newMessage);
      setInputValue('');
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  return (
    <div className="emoji-picker-div" ref={emojiPickerRef}>
      <form onSubmit={onSubmit} className="message-form">
        <div className="emoji-picker-wrapper flex">
          <button
            type="button"
            onClick={() => setShowPicker((prev) => !prev)}
            className="emoji-button pointer"
          >
            😀
          </button>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder='Start typing...'
            className="message-input"
          />
          <button type="submit" className="send-btn pointer">
            <img src="/assets/img/sendIcon.png" alt="" />
          </button>
        </div>
        {showPicker && <EmojiPicker onEmojiClick={onEmojiClick} />}
      </form>
    </div>
  );
};

export default EmojiPickerComponent;
