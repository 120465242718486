import React, { useContext, useState } from "react";
import { Controller, useForm, watch } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import DataContext from "../../../utils/dataContext";
import APIHit from "../../../utils/apiHit";
import { apiMainUser, checkErrors } from "../../../utils/constant";
import TextareaComp from "../../../Components/common/textArea";
import { setDiscData } from "../../../utils/redux/listingTypeSlice";
import { useDispatch } from "react-redux";
import Save_ExitComp from "../../../Components/common/save_ExitComp";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditorWrapper from "../../../Components/common/CkEditorComp";

const PlaceDiscription = () => {
  //   const [listingDiscData, setlistingDiscData] = useState("");
  const [showerror, setError] = useState(null);
  const [api, setApi] = useState(["createProperty_metadata_bulk"]);
  const [method, setMethod] = useState("");

  const storeSel = useSelector((state) => state);
  // const PropIddata = storeSel.apiDataSlice?.propId;

  // console.log(indexStore, "tii");
  const inputData = storeSel?.listingDataSlie.discFinalData;
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      description:
        inputData && inputData?.metadata_value !== "null"
          ? inputData?.metadata_value
          : "",
    },
  });

  const Currenturl = window.location.href;
  console.log(Currenturl, "tyt");
  const CurrentPage = new URL(Currenturl);
  const path = CurrentPage.pathname;
  console.log(path, "qqq");

  const whatHappensOnSubmit = () => {
    let data = watch();
    const obj = {
      // property_id: propertyId,
      metadata_key: "description",
      metadata_value: data.description,
    };

    // setPlaceDiscData(obj);
    dispatch(setDiscData(obj));
  }

  const whereToGoOnNext = ()=>{
    navigate("/host/createlisting/step-3");

  }

  const whatHappensOnSaveAndExit = ()=>{
    whatHappensOnSubmit();
  }

  const onSubmit = (data) => {
    whatHappensOnSubmit(data);
    whereToGoOnNext();    
  };

  useEffect(() => {
    const PropIddata = storeSel.apiDataSlice?.propId;
    const preferenceIddata = storeSel.apiDataSlice?.preferenceId;

    let updatedApi = ["updateProperty/" + PropIddata, ...api];

    if (preferenceIddata) {
      updatedApi = [...updatedApi, "updatePropertyPref/" + preferenceIddata];
      setMethod("patch");
    } else {
      updatedApi = [...updatedApi, "add_preference"];
      setMethod("post");
    }

    setApi(updatedApi);
  }, []);
  console.log(api, "tres");

  return (
    <div className="rules-disc-box place-title-disc-box">
      <div className="create-list-place-title-disc-content create-list-place-title-disc-content">
        <h2>Craft An Irresistible Description Of Your Place</h2>
        <p>
          Entice potential guests with a captivating and detailed description of
          your property!
        </p>
        <form
          className="rules-disc-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="single-input-box">
            <Controller
              control={control}
              name="description"
              rules={{
                required: "Description is required",
              }}
              render={({ field: { onChange, value } }) => (
                <CKEditorWrapper value={value} onChange={onChange} />

              )}
            />
            {errors.description && <span className="error-text">{errors.description.message}</span>}
          </div>

          {/* <APIHit
            handleSubmit={handleSubmit(onSubmit)}
            url={apiMainUser + "create_property_metadata"}
            apidata={listingDiscData}
            successResponseCode={201}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            precondition={preCondition}
            method="post"
            // auth={true}
          > */}
          <div className="bottom-btns-fixed-box">
            <Save_ExitComp
              // apis={["createProperty_metadata_bulk", "add_preference"]}
              // placeDiscData={placeDiscData}
              method={method}
              apis={api}
              path={path}
              firstFunction={whatHappensOnSaveAndExit}

            />
            <button type="submit" className="common-next-btn">
              Next
            </button>
          </div>
          {/* </APIHit> */}
        </form>
      </div>
    </div>
  );
};

export default PlaceDiscription;
