import React, { useContext } from "react";
import CardWrapper from "../../../Components/common/cardWrapper";
import FontComp from "../../../Components/common/fontComp";
import { faBuilding, faHome } from "@fortawesome/free-solid-svg-icons";
import { faFortAwesome } from "@fortawesome/free-brands-svg-icons";
import { useState } from "react";
import { useEffect } from "react";
import APIHit from "../../../utils/apiHit";
import apiMain, { apiMainAdmin, apiMainUser } from "../../../utils/constant";
import DataContext from "../../../utils/dataContext";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PropertyDataReducer,
  setPropertyData,
} from "../../../utils/redux/listingTypeSlice";
import ProfileImage from "../../../Components/common/profileImg";
import { handleNavigation } from "../../../Components/common/navigateFunc";
import Save_ExitComp from "../../../Components/common/save_ExitComp";
import {
  setCartCommonIndex,
  setCommonIndex,
  setImageCommonIndex,
  setPricingCommonIndex,
} from "../../../utils/redux/commonIndex";

const PropType = () => {
  const location = useLocation();
  const listing = location?.state?.prop;

  // console.log(listing.property_type_id, "tri");

  const [successdata, setSuccessData] = useState([]);

  const [selectedUid, setSelectedUid] = useState(null);
  // const [propUid, setPropUid] = useState(null);

  const [apicall, setApiCall] = useState(false);
  // const [isDone, setIsDone] = useState(false);
  // const [storeReduxData, setStoredReduxData] = useState()

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // setPropUid(listing?.property_type_id);

  const Currenturl = window.location.href;
  console.log(Currenturl, "tyt");
  const CurrentPage = new URL(Currenturl);
  const path = CurrentPage.pathname;
  console.log(path, "uuu");

  const storeSel = useSelector((state) => state);

  const [storedReduxData, setStoredReduxData] = useState(null);
  const [storeReduxPropId, setStoreReduxPropId] = useState(null);

  useEffect(() => {
    const data = storeSel.listingDataSlie?.propertyFinalData?.metadata_value;
    const PropIddata = storeSel.apiDataSlice?.propId;

    console.log(data, "yoga");
    setStoredReduxData(data);
    setStoreReduxPropId(PropIddata);
    setApiCall(true);

  }, []);

  console.log(storedReduxData, "mmo");

  const getdata = (uid) => {
    // setPropUid(null);
    setStoredReduxData(null);
    setSelectedUid(uid);
    console.log(uid, "ooo");

    let finaldata = {
      metadata_key: "property_type",
      metadata_value: uid,
    };

    dispatch(setPropertyData(finaldata));
  };

  const handleSuccess = (response) => {
    // Handle the successful response here
    console.log("Successful response:", response);
    setSuccessData(response.data);
    // navigate("/listing");
  };

  const handleFailure = (data) => {
    console.log("Failure response:", data);
  };

  const runPrecondition = () => {
    if (successdata != []) {
      return false;
    }
    return true;
  };

  const handleNavigate = () => {
    console.log("yepiee");

    // if (selectedUid !== null) {
    //   navigate("/host/createlisting/place");
    // }
    handleNavigation(
      selectedUid,
      // property_uid,
      storedReduxData,
      "/host/createlisting/place",
      navigate
    );
  };
  // //

  //just before

  return (
    <>
      {apicall && (
        <APIHit
          url={apiMainUser + "read_property_type?block=0"}
          method="GET"
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          precondition={runPrecondition}
          successResponseCode={200}
          directCall={true}
          auth={true}
        ></APIHit>
      )}

      <div className="create-list-property-type">
        <div className="create-list-property-type-content">
          <h2>Tell Us About Your Property Type!</h2>
          <div className="create-list-property-flex-box">
            {successdata?.map((item) => {
              console.log(item, "hehe");
              return (
                <CardWrapper
                  key={item.uid}
                  onClick={() => getdata(item.uid)}
                  wrapperClass={`create-list-property-type-card${
                    selectedUid === item.uid || storedReduxData === item.uid
                      ? " selected"
                      : ""
                  }`}
                >
                  {/* <FontComp icon={faBuilding} size="lg" color="#000" /> */}
                  <ProfileImage
                    profileImgParent="my-icon-class"
                    src={item.icon_url}
                    className="dndn"
                  />
                  <p>{item.name}</p>
                </CardWrapper>
              );
            })}
          </div>
        </div>
      
        <div className="bottom-btns-fixed-box">
          <Save_ExitComp
            path={path}
            apis={[
              "updateProperty/" + storeReduxPropId,
              "createProperty_metadata_bulk",
            ]}
          />

          <button
            onClick={handleNavigate}
            // disabled={!selectedUid || !storedReduxData}
            className="common-next-btn"
          >
            Next
          </button>
        </div>
        {/* </APIHit> */}
      </div>
    </>
  );
};

export default PropType;
