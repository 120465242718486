import { createSlice, configureStore } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  UserCheckin: 0,
  UserCheckout: 0,
  UserGuests: 0,
  UserProperty: 0,
  UserAdults: 0,
  UserChildrens: 0,
  UserInfants: 0,

};

const UserSearchConfig = createSlice({
  name: "searchConfig",
  initialState,
  reducers: {
    updateUserCheckin: (state, action) => {
      state.UserCheckin = action.payload;
    },
    updateUserCheckout: (state, action) => {
      state.UserCheckout = action.payload;
    },
    updateUserGuests: (state, action) => {
      state.UserGuests = action.payload;
    },
    updateUserProperty: (state, action) => {
      state.UserProperty = action.payload;
    },
    updateUserAdults: (state, action) => {
      state.UserAdults = action.payload;
    },
    updateUserChildrens: (state, action) => {
      state.UserChildrens = action.payload;
    },
    updateUserInfants: (state, action) => {
      state.UserInfants = action.payload;
    },
  },
});

export const { updateUserCheckin, updateUserCheckout, updateUserGuests, updateUserProperty,updateUserInfants,updateUserChildrens,upddateUserAdults,updateUserAdults } =
  UserSearchConfig.actions;
export const CounterUserSearchReducer = UserSearchConfig.reducer;
