import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { apiMainUser, fetchDataFromApiFunc } from '../../../../utils/constant';
import CardWrapper from '../../../../Components/common/cardWrapper';
import ProfileImage from '../../../../Components/common/profileImg';
import Button from '../../../../Components/common/Button';
import HeadingComp from '../HeadingComp';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../../utils/redux/SnackBarSlicer';

const EditPlacType = (props) => {
    const [successdata, setSuccessData] = useState([]);

    const [selectedUid, setSelectedUid] = useState(null);
    
    const dispatch = useDispatch()

    const isSmallScreen = window.innerWidth <= 500;

    useEffect(()=>{
        setSelectedUid(props?.allData?.place_type)
       },[props?.allData?.place_type])


     
    useEffect(() => {
        const fetchPlaceData = async () => {
            // calinng fetchDataFromApiFunc from constant
          const data = await fetchDataFromApiFunc(apiMainUser + "read_place_type?block=0");
          if (data) {
            setSuccessData(data);
          }
        };
      
        fetchPlaceData();
      }, []);

      console.log(successdata,'checkData')

      const getData = (uid)=>{
        setSelectedUid(uid);
      }
    
      const handleSave = async () => {
        try {
    
          const response = await axios.post(
            apiMainUser + "createProperty_metadata_bulk",
            {
              property_id: props.propId,
              meta: [
                {
                    metadata_key: "place_type",
                    metadata_value: selectedUid,
                },
              ],
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          );
    
          if (response.data.status === "success") {
            props.fetchData();
            props.closeModal()
            dispatch(openSnackbar({ message: 'Place type updated Successfully!', severity: 'success' }));

       
            // Handle success response
          } else {
            console.error("Failed to save:", response.status);
            dispatch(openSnackbar({ message: 'Something went Wrong', severity: 'error' }));

          }
        } catch (error) {
          console.error("Error saving :", error);
        }
      };
  return (
    <>
    <div className="paddingCustom2AllSide">
    <div  className={`edit-property-type  ${isSmallScreen ? 'edit-amenities-box mt-30' : ''}`}>
    {successdata?.map((item) => (
      <CardWrapper
        key={item.uid}
        onClick={() => getData(item.uid)}
        wrapperClass={`create-list-property-type-card${
          selectedUid == item.uid 
            ? " selected"
            : ""
        }`}
    >
        <ProfileImage
          profileImgParent="my-icon-class"
          src={item.icon_url}
          className="dndn"
        />
        <p>{item.placetype_name}</p>
      </CardWrapper>
    ))}
</div>
    
  </div>
  <div className="clear_apply-btn-box pb-2  fixed-bottom-btn ">
        <p onClick={() => props.closeModal(false)} className="clear-btn">
          Cancel
        </p>
        <Button
          btnText="Save"
          btnclasses="save-edit-btn"
          onClick={handleSave}
        />
    </div>
  </>
  )
}

export default EditPlacType
