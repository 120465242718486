import React, { useEffect, useState } from 'react'
import CardWrapper from '../../../Components/common/cardWrapper';
import PropertyListing from '../../../Components/common/PropertyListing';
import axios from 'axios';
import { apiMainUser } from '../../../utils/constant';
import { useNavigate } from 'react-router-dom';

const UpcomingPayout = () => {
    const data = [
        { srNo: 1, propertyName: 'Lukman Villa', amount: '5000rs' },
        { srNo: 2, propertyName: 'Some Property', amount: '3000rs' },
        { srNo: 3, propertyName: 'Another Property', amount: '7000rs' },
      ];

      const [bookingsData, setBookingsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: 'Bearer ' + token,
        }
        const params = {
          filter: 'passed',
          status: 'captured',
          payout: 0,
        };
        const response = await axios.get(apiMainUser + 'read_booking_for_host_property', {
          params,
          headers,
        });
        setBookingsData(response.data.data);
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };

    fetchData();
  }, []); 

  console.log(bookingsData,'checkAllBookings')

  const navigate= useNavigate()

  const navigateToHostAccountPage =()=>{
    navigate('/hostAccount')
    }

    
    const navigateToHostPayoutparent =()=>{
      navigate('/hostPayout_transaction')
      }
  


  return (
    <div className='account-detail-page host-transaction-upcoming-payouts'>
       <p style={{fontSize:'1.2rem',fontWeight:'500'}}><span className='pointer' onClick={navigateToHostAccountPage}>Account</span>  > <span className='pointer' onClick={navigateToHostPayoutparent}>Payouts & Transactions ></span> Transactions</p>
   <h2 className='account-head-text'>Payouts & Transactions</h2>
  <h4 className='upcoming-text'>Upcoming Payouts</h4>


   

   <CardWrapper wrapperClass='upcoming-reserve-card'>
   <table className='upcoming-payout-table'>
        <thead>
          <tr>
            <th>Sr No</th>
            <th>Property Name</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {bookingsData.map((item, index) => (
            <tr key={index}>

              <td>{index + 1}</td>
              <td>
                  <PropertyListing
                    src={item?.base_url_image + item?.images[0]}
                   
                    className={item.propertyImageClassName}
                    
                    paraText={item?.property_name}
                  />
                </td>
              <td>{item?.amount?.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
   </CardWrapper>
    </div>
  )
}

export default UpcomingPayout
