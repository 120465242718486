import React, { useEffect, useState, useRef } from 'react';
import SearchInput from '../UserComponents/searchInputs';
import CardWrapper from './cardWrapper';
import Button from './Button';
import DockComponent from './DockComponent';

const ButtonPopup = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  const isSmallScreen = window.innerWidth <= 500;


  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleApply = ()=>{
    if(props.onApply) props.onApply();
    handleClose();
  }

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        event.target !== containerRef.current.querySelector('.buttonpopout')
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (

    <>
       {!isSmallScreen || props.buttonPopup ?  ( 
    <div ref={containerRef}>
      {isOpen && props.popOnTop && (
        <div>
          <CardWrapper  wrapperHeading={props.wrapHead} wrapperClass={`fixed buttonpopout mt-n-430 ${props.wrapclass ? props.wrapclass : ""}`}>
            {props.divContent}
            <br />
            {props.showCloseButton &&  (
              <Button as="span" btnText={props.closeBtnText} btnclasses="text-btn" onClick={(handleClose)}  />
            )}

        
          </CardWrapper>
        </div>
      )}
      {!props.makeCustom && <SearchInput
        icon={props.icon}
        upperText={props.upperText}
        lowerText={props.lowerText}
        middleText={props.middleText}
        onClick={handleClick}
      />}
      
      {
        props.makeCustom && <div onClick={handleClick}>{props.makeCustom}</div>
      }
      {isOpen && !props.popOnTop && (
        <div>
        <CardWrapper  style={{ padding: "16px 16px 0px" }} wrapperHeading={props.wrapHead} wrapperClass={`fixed buttonpopout mt-10 ${props.wrapclass ? props.wrapclass : ""}`}>
            {props.divContent}
            <br />
            {/* <div className='clear_apply-btn-box' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}> */}
            <div className={`clear_apply-btn-box ${props.additionalClass}` }>

            {props.showCloseButton && (
              <Button as="span" btnText={props.closeBtnText} btnclasses="text-btn cancel-edit-btn" onClick={(handleClose)} />
            )}
            {props.showApplyButton && (
              <Button as="span" btnText={props.applyBtnText} btnclasses=" apply-btn" onClick={(handleApply)} />
            )}

            
            </div>
            
          </CardWrapper>
        </div>
      )}
    </div>


) :(
  <>

  {
        props.makeCustom && <div onClick={handleClick}>{props.makeCustom}</div>
      }
   

       {isOpen && (
    <DockComponent 
      showCloseButton={props.showCloseButton}
      closeBtnText={props.closeBtnText}
      // duration={10000}
        fluid={true}
        position={props.position}
        
        defaultSize={props.defaultSize}

       
        isVisible={isOpen}
      additionalClass={props.additionalClass}
        
        // setVisibleDocks={}
        setVisibleDocks={handleClose} 
       saveApplyButton={props.showApplyButton}
        makeCustom={props.makeCustom}
        saveApplyFunc={handleApply}
        isOpen={props.setIsOpen}
        btnClass={props.btnClass}
        paddingAll={props.paddingAll}
        DockPopupFilter={props.DockPopupFilter}
        FixedBottomDock={props.FixedBottomDock}
       
        
      >
      {props.divContent}
       </DockComponent>
       
       )}

       </>
          )}
    

    </>
  );
};

export default ButtonPopup;
