import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputComp from "../../../../Components/common/textInput";
import Button from "../../../../Components/common/Button";
import HeadingComp from "../HeadingComp";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiMainUser } from "../../../../utils/constant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocation, faLocationDot, faMarker } from '@fortawesome/free-solid-svg-icons';
import { countries } from "countries-list";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../../utils/redux/SnackBarSlicer";

const EditAddress = (props) => {
  const getSlug = props.allData.slug;

  console.log(props.allData,'checkAllAddress')
  
  const [latitude,setLatitude] = useState()
  const [longitude,setLongitude] = useState()

  const dispatch = useDispatch()

  console.log(getSlug, "typ");
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data, index) => {
    const postDataValue = {
      property_id: props.propId,
      meta: [
        {
          metadata_key: "house_no",
          metadata_value: data.house_no,
        },
        {
          metadata_key: "area",
          metadata_value: data.area,
        },
        {
          metadata_key: "street_address",
          metadata_value: data.street_address,
        },
        {
          metadata_key: "landmark",
          metadata_value: data.landmark,
        },
        {
          metadata_key: "country",
          metadata_value: data.country,
        },
        {
          metadata_key: "pincode",
          metadata_value: data.pincode,
        },
        {
          metadata_key: "city",
          metadata_value: data.city,
        },
        {
          metadata_key: "state",
          metadata_value: data.state,
        },
        {
          metadata_key: "latitude",
          metadata_value: props.allData.latitude ? props.allData.latitude : latitude,
        },
        {
          metadata_key: "longitude",
          metadata_value: props.allData.longitude ? props.allData.longitude : longitude,
        },
      ],
    };

    try {
      let resPage = await axios({
        method: "post",
        url: apiMainUser + `createProperty_metadata_bulk`,
        data: postDataValue,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      console.log(resPage, "too");
      if (resPage?.data.status === "success") {

        const currentUrl = window.location.pathname;

        const baseUrl = currentUrl.split("edit/")[0];
        const updatedUrl = baseUrl + "edit/" + data.city + "/" + getSlug;

        navigate(updatedUrl);
        props.fetchData();
        dispatch(openSnackbar({ message: 'Address changed succesfully!', severity: 'success' }));


      }

      props.closeModal(false);
    } catch (error) {
      console.log(error);
    }
    // Close the modal
  };
    const watchAllFields = watch();
    const countriesName = countries
    console.log(countriesName,'country')
    // console.log(countriesName)

  
  const handleBlur = (fieldName, value) => {
    console.log(`Field ${fieldName} Value: ${value}`);
    const { house_no, area, street_address, landmark, country, pincode, city, state } = watchAllFields;
    // const addressString = `${house_no+',' || ''}+${area+',' || ''}+${street_address+',' || ''}+${landmark+',' || ''}+${country+',' || ''}+${pincode+',' || ''}+${city+',' || ''}+${state+',' || ''}`;
    const addressString = `${props.allData.house_no ? props.allData.house_no + ',' : (house_no ? house_no + ',' : '')}${props.allData.area ? props.allData.area + ',' : (area ? area + ',' : '')}${props.allData.street_address ? props.allData.street_address + ',' : (street_address ? street_address + ',' : '')}${props.allData.landmark ? props.allData.landmark + ',' : (landmark ? landmark + ',' : '')}${props.allData.country ? props.allData.country + ',' : (country ? country + ',' : '')}${props.allData.pincode ? props.allData.pincode + ',' : (pincode ? pincode + ',' : '')}${props.allData.city ? props.allData.city + ',' : (city ? city + ',' : '')}${props.allData.state ? props.allData.state + ',' : (state ? state + ',' : '')}`;

   
    const API_KEY = "AIzaSyD2p6Azx2i_PY1RbQhin7TqLyijMt3ykT8"; 
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${addressString}&key=${API_KEY}`;
    console.log(apiUrl);

    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        // console.log("Responsecheck:", data.results[0].geometry.location.lat);
        setLatitude(data.results[0].geometry.location.lat)
        setLongitude(data.results[0].geometry.location.lng)
      })
      .catch(error => {
        console.error("Errr:", error);
      });
  };

    
  return (
    <div className="confirm-address-box google-address">
      <div  className="create-list-address-type-content pd-px-0">
        <div className="google-adress-flex-box">
        
          <form
            className="confirm-address-form w-100"
            onSubmit={handleSubmit(onSubmit)}
            
          >
            {/*  */}
            <div className="flex-input-box">
              <div className="single-input-box">
                <Controller
                  control={control}
                  name="house_no"
                  defaultValue={
                    props.allData !== undefined ? props.allData?.house_no : ""
                  }
                  // defaultValue={houseData}
                  rules={{
                    required: "House/Flat info is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input"
                      name="house_no"
                      inpPlace="House/Flat"
                      defaultValue={
                        props.allData !== undefined ? props.allData?.house_no : ""
                      }
                      onKeyUp={onChange}
                      value={value}
                      onBlur={(e) => handleBlur("house_no", e.target.value)}

                    />
                  )}
                />
                {errors.house_no && <span>{errors.house_no.message}</span>}
              </div>

              <div className="single-input-box">
                <Controller
                  control={control}
                  name="area"
                  defaultValue={
                    props.allData !== undefined ? props.allData?.area : ""
                  }
                  rules={{
                    required: "Area/Village info is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input"
                      name="area"
                      inpPlace="Area/Village"
                      defaultValue={
                        props.allData !== undefined ? props.allData?.area : ""
                      }
                      onKeyUp={onChange}
                      value={value}
                      onBlur={(e) => handleBlur("area", e.target.value)}

                    />
                  )}
                />
                {errors.area && <span>{errors.area.message}</span>}
              </div>
            </div>

            {/*  */}
            <div className="flex-input-box">
              <div className="single-input-box street-inputbox">
                <Controller
                  control={control}
                  name="street_address"
                  defaultValue={
                    props.allData !== undefined
                      ? props.allData?.street_address
                      : ""
                  }
                  rules={{
                    required: "Street info is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input confirm-street-input"
                      name="street_address"
                      inpPlace="Street Address"
                      defaultValue={
                        props.allData !== undefined
                          ? props.allData?.street_address
                          : ""
                      }
                      onKeyUp={onChange}
                      value={value}
                      onBlur={(e) => handleBlur("street_address", e.target.value)}

                    />
                  )}
                />
                {errors.street_address && <span>{errors.street_address.message}</span>}
              </div>

              <div className="single-input-box landmark-inputbox">
                <Controller
                  control={control}
                  name="landmark"
                  defaultValue={
                    props.allData !== undefined ? props.allData?.landmark : ""
                  }
                  // defaultValue={landmarkData}
                  // rules={{
                  //   required: "H info is required",
                  // }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input confirm-landmark-input"
                      name="landmark"
                      inpPlace="Landmark(optional)"
                      onKeyUp={onChange}
                      value={value}
                      defaultValue={
                        props.allData !== undefined ? props.allData?.landmark : ""
                      }
                      onBlur={(e) => handleBlur("landmark", e.target.value)}

                    />
                  )}
                />
                {/* {errors.house && <span>{errors.house.message}</span>} */}
              </div>

              <div className="single-input-box">
                <Controller
                  control={control}
                  name="country"
                  defaultValue={
                    props.allData !== undefined ? props.allData?.country : ""
                  }
                  // defaultValue={landmarkData}
                  // rules={{
                  //   required: "H info is required",
                  // }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input"
                      name="country"
                      inpPlace="Country"
                      onKeyUp={onChange}
                      defaultValue={
                        props.allData !== undefined ? props.allData?.country : ""
                      }
                      value={value}
                      onBlur={(e) => handleBlur("country", e.target.value)}
                      disabled={props.allData?.country ? true : false}

                    />
                  )}
                />
                {errors.country && <span>{errors.country.message}</span>}
              </div>
            </div>

            {/*  */}
            <div className="flex-input-box">
              <div className="single-input-box">
                <Controller
                  control={control}
                  name="pincode"
                  defaultValue={
                    props.allData !== undefined ? props.allData?.pincode : ""
                  }
                  rules={{
                    required: "Pincode info is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="number"
                      inputClass="confirm-address-input"
                      name="pincode"
                      inpPlace="Pincode"
                      onKeyUp={onChange}
                      defaultValue={
                        props.allData !== undefined ? props.allData?.pincode : ""
                      }
                      value={value}
                      onBlur={(e) => handleBlur("pincode", e.target.value)}

                    />
                  )}
                />
                {errors.pincode && <span>{errors.pincode.message}</span>}
              </div>

              <div className="single-input-box">
                <Controller
                  control={control}
                  name="city"
                  defaultValue={
                    props.allData !== undefined ? props.allData?.city : ""
                  }
                  rules={{
                    required: "City info is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input"
                      name="city"
                      inpPlace="City"
                      defaultValue={
                        props.allData !== undefined ? props.allData?.city : ""
                      }
                      // onKeyUp={}
                      onKeyUp={onChange}
                      value={value}
                      onBlur={(e) => handleBlur("city", e.target.value)}

                    />
                  )}
                />
                {errors.city && <span>{errors.city.message}</span>}
              </div>

              <div className="single-input-box">
                <Controller
                  control={control}
                  name="state"
                  defaultValue={
                    props.allData !== undefined ? props.allData?.state : ""
                  }
                  rules={{
                    required: "State info is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input"
                      name="state"
                      inpPlace="State"
                      onKeyUp={onChange}
                      value={value}
                      onBlur={(e) => handleBlur("state", e.target.value)}
                      defaultValue={
                        props.allData !== undefined ? props.allData?.state : ""
                      }

                    />
                  )}
                />
                {errors.state && <span>{errors.state.message}</span>}
              </div>
            </div>
        

            <div className="clear_apply-btn-box paddingCustomYSide">
      
            <p onClick={() => props.closeModal(false)} className="clear-btn">
              Cancel
            </p>
            <button className="save-edit-btn" type="apply-btn">
              Save
            </button>

            </div>


          </form>
        
        </div>
      </div>
    </div>
  );
};

export default EditAddress;
