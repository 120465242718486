import React, { useState } from 'react'
import MapDisplayOnTripPage from '../MapDisplayOnTripPage'
import TripConfirmationCardComp from './TripConfirmationCardComp'
import PropertyTripDetailComponent from './PropertyTripDetailComponent'
import { Link } from 'react-router-dom'
import ProfileImage from '../../../Components/common/profileImg'
import GuestBasic from '../../../Components/common/guestBasic'
import CancellationPolicyCard from '../../../Components/UserComponents/CancellationPolicyCard'
import SupportCard from './SupportCard'
import CardWrapper from '../../../Components/common/cardWrapper'
import Button from '../../../Components/common/Button'
import ViewBreakDownCard from '../../../Components/HostComponents/viewBreakdownCard'
import moment from 'moment'
import WrapperPopup from '../../../Components/common/wrapperPopup'
import RewardsReviewImages from '../../../Components/UserComponents/RewardsReviewImages'

const UpcomingDetailsPage = (props) => {

    

    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
    };
  
    const data = props.successdata?.transaction_details[0]?.price_detail;
      const addonData = props.successdata?.transaction_details[0]?.addon_details;
      console.log(data, "tried", "sdss");
  
      console.log(props?.successdata,'checkAddonData')
      let priceDetailMapvalues = {};
      data?.forEach((x) => {
        console.log(x, 'tried', 'wow');
    
        if (x.total) {
          console.log("tried", x.total);
          priceDetailMapvalues = x.total;
        }
      });
    
  
      const hostNameForUser = props.successdataUser?.host_fname + ' ' + props.successdataUser?.host_lname;
  
      const toggleVisibleDocks = () => {
     
          setOpenModal(false);
         
        }

      console.log(props?.successdata,'checkSuccesData')

  return (
    <div className="property-page">      
      <div className="property-page-grid-box">
        <div className="property-page-left-content">
          <div className="property-page-property-name-box">
            <div className="property-name-box">
              <p className="property-name-text">{props.successdataprop?.property_name}</p>
              <p className="property-name">{props.successdataprop?.space_type} hosted by {props.successdataprop?.host_details[0].fname}</p>
            </div>
            <Link to={"/properties/"+props.successdataprop?.city+"/"+ props.successdataprop?.slug}><Button btnText="View" btnclasses="offer-btn" /></Link>

          </div>
          <div className="property-page-booking-img-box">
          <img
            className="property-page-property-img"
            src={props.successdataprop?.base_url_image+ props.successdataprop?.images[0]}
            alt=""
          />
          </div>
          <PropertyTripDetailComponent
          successdata={props.successdata} 
          checkInTime={props.checkInTime}
          checkOutTime={props.checkOutTime}
        />
 
       <TripConfirmationCardComp
        successdata={props.successdata} 
        successdataprop={props.successdataprop}
        handleOpenModal={handleOpenModal}
      /> 
        </div>

       
        <div className="property-page-right-content">     
        <>
 
  
      <MapDisplayOnTripPage
        successdataprop={props.successdataprop} 
      />
  

            
      <CardWrapper wrapperHeading='About Host' wrapperClass='about-guest-card'>
         <GuestBasic

          description={props.successdataprop?.host_details?.[0]?.about_me} 
          guestName ={hostNameForUser} verfiedText='Verified' icon='./assets/img/guestverified.svg'
          starPoint={props.ratingAverage?.Overall_Average ? props.ratingAverage?.Overall_Average : '0'} starIcon='./assets/img/colorStarIcon.svg' noReviews={props.review?.length !== 0  ? props.review?.length : 0}
          //  guestDuration='Jumbo guest since April 2023'
          />
          <div>
          <ProfileImage src={props.successdataprop?.host_details?.[0]?.image ? props.assetsurl+props.successdataprop?.host_details?.[0]?.image : '/assets/img/userIconImg.jpg'} width='100%'/>
          {props.successdataprop?.host_details[0]?.rewards_alloted[0]?.rewards_details.length > 0 &&
        <RewardsReviewImages
        imageSources={props.successdataprop?.host_details[0]?.rewards_alloted[0]?.rewards_details}
        />
      }

          </div>  
            </CardWrapper> 
          {props.successdata?.room_id && props.successdata?.room_id !== null ? <Link to={"/user-message?room_id="+props.successdata?.room_id}><Button btnText="Message host"  btnclasses="contact-btn" /></Link> : null}
            

{/*-----------------------------------------------------------------  */}
       
         <CancellationPolicyCard cancellationData={props.successdataprop?.cancellation_category_detail
         } /> 
            

              <SupportCard
              canCancel
              fetchData={props.fetchData}
              listingData={props.listingData}
              successdata={props.successdata}
              bookingOrderId={props.bookingOrderId}
              apicall={props.apicall}
              setApiCall={props.setApiCall}
              />      
              </>
        
          </div>

      </div>


      <div className="view-breakdown-popup">
          {openModal && (
            <WrapperPopup
            // AppylyBtnBox={true}
            defaultSize={"0.45"}
            dimStyle={false}
            isVisible={true} 
            setVisibleDocks={toggleVisibleDocks}
            
            //   closeModal={() => setOpenModal(false)}
              closeModal={handleCloseModal}
              comp={
                <ViewBreakDownCard
                  src={props.successdataprop?.base_url_image + props.successdataprop?.images[0]}
                  starRating="5"
                  addons={addonData}
                  reviewNo="7"
                  propertyName={props.successdataprop?.property_name}
                  entireProperty={props.successdataprop?.space_type}
                  perDayprice={priceDetailMapvalues?.total_property_addon_base_cost?.toFixed(2)}
                  ExpPrice={priceDetailMapvalues.total_experience_base_cost?.toFixed(2)}
                  TaxPrice={priceDetailMapvalues.total_property_tax_cost?.toFixed(2)}
                  ServiceFee={priceDetailMapvalues.total_property_service_cost?.toFixed(2) || 0}
                  totalAmount={priceDetailMapvalues?.grand_total_booking_cost?.toFixed(2)}
                  noOfNight={moment(props.successdata?.transaction_details[0].checkout_date).diff(moment(props.successdata?.transaction_details[0].checkin_date), "days")}
                />
              }
            />
          )}
        </div>




    </div>
  )
}

export default UpcomingDetailsPage
