import React from 'react'
import Button from '../../../Components/common/Button';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { apiMainUser } from '../../../utils/constant';
import { openSnackbar } from '../../../utils/redux/SnackBarSlicer';
import { useDispatch } from 'react-redux';

const HostAdressEdit = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch()

 
    const onSubmit = async (data) => {
      const formData = new FormData();
    formData.append('address', data.street_addrress);
    formData.append('flat_no', data.flat);
    formData.append('city', data.city);
    formData.append('state', data.state);
    formData.append('pincode', data.pincode);

      try {
        const response = await axios({
          method:'patch',
          url: apiMainUser + 'update_user',
          data: formData,
            headers: {
            'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          
        }
        
        );
        if(response.data.status === 'success'){
          props.fetchData()
          props.closeModal()
          dispatch(openSnackbar({ message: 'updated Successfully', severity: 'success' }));

         
        }
        console.log(response.data);
      } catch (error) {
        
        dispatch(openSnackbar({ message: error.response.data.message, severity: 'error' }));

      }
    
  };
  return (
    <div className="host-edit-name-main-div ">
    {/* <p style={{fontSize:'14px',marginTop:'2rem',marginBottom:'0.5rem'}}>Address</p>   */}
    <p className='mt-10' style={{fontSize:'12px',marginBottom:'2rem'}}>Use Your permanent address where you can recieve mail</p>  

  <form className='host-personal-info-edit-form' onSubmit={handleSubmit(onSubmit)}>

  <div className='host-personal-info-input-box'>
     
     <input {...register('flat', { required: true })} id="flat" placeholder="Enter your room no"
       defaultValue={props.usersData?.flat_no || ''} 
     
     />
     {errors.flat && <span>This field is required.</span>}
   </div>

    <div className='host-personal-info-input-box'>
      
      <input {...register('street_addrress', { required: true })} id="street_addrress" placeholder="Enter your street address" 
        defaultValue={props.usersData?.address || ''} 
      
      />
      {errors.street_addrress && <span>This field is required.</span>}
    </div>

   

    <div className="personal-info-input-flex-box">
    <div className='host-personal-info-input-box city-input'>
     <input
    {...register('pincode', {
      required: true,
    })}
    id="pincode"
    type="number"
    placeholder="Enter your pincode"
    defaultValue={props.usersData?.pincode || ''} 

  />
  {errors.pincode && errors.pincode.type === 'required' && <span>This field is required.</span>}


   </div>

   <div className='host-personal-info-input-box city-input'>
     
     <input {...register('city', { required: true })} id="city" placeholder="Enter your city" 
        defaultValue={props.usersData?.city || ''} 
     
     />
     {errors.city && <span>This field is required.</span>}
   </div>
   </div>
   <div className='host-personal-info-input-box pincode-input'>
   <input {...register('state', { required: true })} id="state" placeholder="Enter your state" 
        defaultValue={props.usersData?.state || ''} 
     
     />
     {errors.state && <span>This field is required.</span>}
</div>


<div className="clear_apply-btn-box">
      
      <p onClick={() => props.closeModal(false)} className="clear-btn">
         Cancel
       </p>
      <button className="save-edit-btn" type="apply-btn">
         Save
       </button>

  
 </div>
  </form>
  </div>
  )
}

export default HostAdressEdit
