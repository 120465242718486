import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

const Privacy_policy = () => {

  return (
   
    <div className='terns-condition-page'>
    <h2 className='privacy-heading' >Privacy Policy</h2>
    {/* <ol className='terms-condition-ol'>
        <li>Introduction and General Terms: This Privacy Policy outlines how Jumbo Holidayz collects, uses, maintains, and discloses information from users of our website and mobile application. It encompasses personal data, its usage, and public disclosure conditions.</li>
        <li>Data Collection:
            <ul className='terms-condition-ul'>
                <li>2.1 Personal Identification Information:   We collect PII when users register, place a booking, or list a property. This includes names, email addresses, phone numbers, and credit card details.</li>
                <li>2.2 Non-Personal Identification Information:   We gather non-personal identification data, such as browser type, device type, and technical information, when users interact with our platform.</li>
                <li>2.3 Web Browser Cookies:   Our platform utilizes cookies to enhance user experience.</li>
            </ul>
        </li>
        <li>Use of Collected Information:
            <ul className='terms-condition-ul'>
                <li>3.1 Fulfill Services: Information may be used to process bookings, facilitate payments, and manage
                    property listings.</li>
                <li>3.2 Personalized Experience: We may use data to understand user behavior and optimize their
                    experience.</li>
                <li>3.3 Customer Support: Respond to queries, manage complaints, and ensure smooth user experience.</li>
                <li>3.4 Marketing: Send newsletters, promotions, and platform updates. Users can always unsubscribe.</li>
            </ul>
        </li>
        <li>Data Protection:
            <ul className='terms-condition-ul'>
                <li>4.1 Security Measures: We adopt appropriate measures against unauthorized access, alteration, or
                    destruction of data.</li>
                <li>4.2 Data Breach Procedures: In the event of a data breach, affected users will be informed, and
                    necessary corrective actions will be taken.</li>
            </ul>
        </li>
        <li>Sharing Personal Information:
            <ul className='terms-condition-ul'>
                <li>5.1 Essential Sharing: User information may be shared with service providers assisting in platform operation, with a commitment to confidentiality.</li>
                <li>5.2 Legal Requirements: We may disclose user information if required by law.</li>
            </ul>
        </li>
        <li>User Rights and Choices:
            <ul className='terms-condition-ul'>
                <li>6.1 Access: Users can request copies of their personal data.</li>
                <li>6.2 Edit and Correction: Users can update or correct their data.</li>
                <li>6.3 Deletion: Users can request data deletion, subject to retention for legal or business purposes.</li>
                <li>6.4 Cookies: Users can decline cookies via browser settings.</li>
            </ul>
        </li>
        <li>Third-party Websites and Services:
            <ul className='terms-condition-ul'>
                <li>7.1 Links: Our platform may have links to external sites. We aren't responsible for their content or
                    privacy practices.</li>
                <li>7.2 Third-party Services: We might integrate third-party tools (e.g., payment gateways). Their usage of
                    data is based on their policies.</li>
            </ul>
        </li>
        <li>Updates to this Privacy Policy: We reserve the right to revise this policy at any time. Changes will be posted
            on this page with an updated 'Effective Date.'</li>
        <li>Consent: By using Jumbo Holidayz, you signify your acceptance of this Privacy Policy. If you do not agree,
            please refrain from using our platform.</li>
        <li>Contacting Us: For any questions about this Privacy Policy, practices, or dealings with this platform, please
            contact: <a style={{textDecoration:'underline'}} href="mailto:stays@jumboholidayz.com" target='_blank'>stays@jumboholidayz.com</a></li>
    </ol> */}

   <div className='heading-text-privacy'>
    <p>Last updated: December 20, 2023</p>
    <p>This Privacy Policy outlines our practices regarding the collection, utilization, and disclosure of your information when you engage with our services. It elucidates your privacy rights and the legal safeguards in place to protect your data.</p>
    <p>Your Personal data is employed to enhance and optimize the Service we provide. By utilizing our Service, you consent to the collection and utilization of information as delineated in this Privacy Policy. This document has been formulated with assistance from the Free Privacy Policy Generator.</p>
   </div>


<div style={{margin:'3rem 0rem'}}>
   <h2 className='privacy-main-heading'>Interpretation and Definitions</h2>
   <div style={{margin:'2rem 0rem'}}>
   <p className='privacy-sub-heading'>Interpretation:</p>
    <span className='privacy-sub-heading-para' >Terms with initial capitalization hold specific meanings as defined under the following conditions. These definitions remain consistent, whether expressed in singular or plural form.</span>
    </div>
    <p className='privacy-sub-heading'>Definations:</p>
    <span className='privacy-sub-heading-para'>For the purposes of this Privacy Policy:</span>
    
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Account: </span>A distinctive account created for you to access our Service or specific parts thereof.</p>
    
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Affiliate: </span>An entity that controls, is controlled by, or is under common control with a party. "Control" signifies ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for the election of directors or other managing authority.</p>
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Application: </span>Refers to Jumbo Holidayz, the software program provided by the Company.</p>
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Company: </span>Referred to as "the Company," "We," "Us," or "Our" in this Agreement, it denotes Jumbo Holidayz, with its office at DK Motors, 4TH Floor, C 1/1, Laxmi Sadan, Aman Talkies Road, Ulhasnagar, Kalyan, Thane, Maharashtra, 421003.</p>
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Country: </span>Refers to Maharashtra, India.</p>
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Device: </span>Any apparatus capable of accessing the Service, such as a computer, cellphone, or digital tablet.</p>
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Personal Data: </span>Any information relating to an identified or identifiable individual..</p>
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Service: </span>Encompasses the Application or the Website or both.</p>
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Service Provider: </span>Any natural or legal person processing data on behalf of the Company. This includes third-party entities or individuals employed by the Company to facilitate the Service, provide the Service on behalf of the Company, perform services related to the Service, or assist the Company in analyzing how the Service is used.</p>
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Third-party Social Media Service: </span>Any website or social network site through which a User can log in or create an account to use the Service.</p>
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Usage Data: </span>Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Website: </span> Refers to Jumbo Holidayz - Exotic Luxury Villas, accessible from <a style={{textDecoration:"underline"}} target='_blank' href='https://jumboholidayz.com'> https://jumboholidayz.com.</a></p>
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>You: </span>Denotes the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>

    </div>


    <div style={{margin:'3rem 0rem'}}>
    <h2 className='privacy-main-heading'>Collecting and Using Your Personal Data</h2>  
    <div style={{margin:'2rem 0rem'}}>
    <p className='privacy-sub-heading'>Types of Data Collected:</p>
    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Personal Data: </span>While using Our Service, We may request You to provide Us with specific personally identifiable information that can be used to contact or identify You. This may include, but is not limited to:
    <ul style={{marginLeft:'20px'}}>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Mobile number</li>
        <li>Address, State, Province, ZIP/Postal code, City</li>
    </ul>
    
    </p>

    <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Usage Data: </span>Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
    When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
    We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
 </p>
 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Information from Third-Party Social Media Services: </span>The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:
 <ul style={{marginLeft:'20px'}}>
        <li>Google</li>
        <li>Facebook</li>
        
    </ul>
    <br />
    If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.
    <br />
    You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.

 </p>


 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Information Collected while Using the Application: </span>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:
<br />
Pictures and other information from your Device's camera and photo library
We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.
<br />
You can enable or disable access to this information at any time, through Your Device settings.
</p>

 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Use of Your Personal Data </span>The Company is committed to utilizing Personal Data responsibly and transparently. Your information may be used for the following purposes:</p>

 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>To Provide and Maintain Our Service: </span>Monitor the usage of our Service to ensure its proper functioning.</p>

 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>To Manage Your Account: </span>Manage your registration as a user, granting access to various functionalities available to registered users.</p>

 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>For the Performance of a Contract: </span>
Develop, comply with, and execute purchase contracts for products, items, or services you have acquired through the Service.</p>

 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>To Contact You: </span>Reach out via email, telephone calls, SMS, or other electronic communication methods for updates, informative communications, or security-related notifications.</p>


 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>To Provide News and Offers: </span>Share news, special offers, and general information about goods, services, and events similar to those you have shown interest in, unless you opt out.
</p>

 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>To Manage Your Requests: </span>Attend to and manage your requests effectively.</p>

 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>For Business Transfers: </span>Evaluate or conduct business transfers, including mergers, divestitures, restructuring, reorganizations, dissolutions, or sales of assets.</p>

 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>For Other Purposes: </span>Utilize information for data analysis, identify usage trends, assess the effectiveness of promotional campaigns, and enhance our Service, products, services, marketing, and user experience.</p>

 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Sharing Your Personal Information: </span>Your personal information may be shared in the following situations:</p>
 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>With Service Providers: </span>Share with Service Providers to monitor and analyze the use of our Service and to contact You as necessary.</p>

 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>For Business Transfers: </span>Share or transfer personal information during negotiations of mergers, sales of company assets, financing, or acquisition of our business.</p>

 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>With Affiliates: </span>Share with Our affiliates, ensuring they adhere to this Privacy Policy. Affiliates include Our parent company, subsidiaries, joint venture partners, or companies under common control.</p>

 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>With Business Partners: </span>Share with business partners to offer specific products, services, or promotions</p>


 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>With Other Users: </span>When interacting with other users, personal information may be viewed and publicly distributed. Interactions through Third-Party Social Media Services may make certain details visible to contacts on those platforms.</p>




 <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>With Your Consent: </span>Personal information may be disclosed for other purposes with Your explicit consent.
The Company values Your privacy and ensures that sharing and usage of Your information adhere to stringent standards. If You have any concerns or queries regarding Your data, feel free to reach out.</p>







    

    </div>
    </div>

  <div className = 'privacy-main-topics-parent-div'>
    <h3 style={{fontSize:'21px' ,fontWeight:'500',marginBottom:'1.5rem'}}>Retention of Your Personal Data</h3>
    <p>The Company is committed to retaining Your Personal Data only for the duration necessary to fulfill the purposes outlined in this Privacy Policy. Your information will be used and retained to comply with legal obligations, resolve disputes, and enforce agreements and policies.</p>
    <p>Usage Data, collected for internal analysis, is generally retained for a shorter duration unless needed for security enhancement, Service functionality improvement, or legal obligations requiring prolonged retention.</p>
  </div>


  <div className = 'privacy-main-topics-parent-div'>
    <h3 style={{fontSize:'21px' ,fontWeight:'500',marginBottom:'1.5rem'}}>Transfer of Your Personal Data</h3>
    <p>Your information, including Personal Data, may be processed at the Company's operating offices and other locations involved in the processing. This could involve transferring information to computers located outside Your state, province, country, or jurisdiction, where data protection laws may differ.</p>
  <p>Your submission of information following this Privacy Policy indicates Your consent to such transfers. The Company is committed to taking all reasonable steps to ensure secure treatment of Your data in line with this Privacy Policy. No transfer of Your Personal Data will occur unless adequate controls, including data security and privacy, are in place.</p>
  </div>


  <div className = 'privacy-main-topics-parent-div'>
    <h3 style={{fontSize:'21px' ,fontWeight:'500',marginBottom:'1.5rem'}}>Deleting of Your Personal Data</h3>
    <p>You have the right to request the deletion of the Personal Data collected about You. Our Service provides features that enable You to delete certain information independently. You can update, amend, or delete Your information at any time through Your Account settings if applicable.</p>
    <p>For further assistance, or to request access, correction, or deletion of personal information, You can also contact Us directly. However, it's important to note that certain information may need to be retained due to legal obligations or lawful bases, even if deletion is requested.</p>
    <p>Your privacy is a priority, and We are committed to facilitating Your rights in managing Your personal information. If You have any concerns or queries, please do not hesitate to reach out to Us</p>
   
  </div>

  <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Business Transactions: </span>In the event of a merger, acquisition, or asset sale involving the Company, Your Personal Data may be transferred. We are committed to notifying You before such transfer occurs, ensuring that Your Personal Data remains subject to a Privacy Policy consistent with our standards.</p>
  <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Law Enforcement: </span>Under specific circumstances, the Company may be obligated to disclose Your Personal Data, either by law or in response to valid requests from public authorities such as courts or government agencies.</p>
  <p className='privacy-sub-child-para'><span className='privacy-sub-child-heading'>Other Legal Requirements: </span>The Company may disclose Your Personal Data in good faith, believing that such action is necessary to:
  <ul style={{marginLeft:'20px'}}>
        <li>Comply with a legal obligation</li>
        <li>Safeguard and defend the rights or property of the Company</li>
        <li>Investigate or prevent potential misconduct related to the Service</li>
        <li>Protect the personal safety of Service Users or the public</li>
        <li>Defend against legal liability</li>
    </ul>
    <br />
  </p>

  <div className = 'privacy-main-topics-parent-div'>
    <h3 style={{fontSize:'21px' ,fontWeight:'500',marginBottom:'1.5rem'}}>Security of Your Personal Data</h3>
    <p>
    Ensuring the security of Your Personal Data is a top priority for us. While we employ commercially acceptable methods to safeguard Your information, it's important to note that no method of transmission over the Internet or electronic storage is entirely foolproof. Despite our best efforts, we cannot guarantee absolute security.
    </p>
  </div>

  <div className = 'privacy-main-topics-parent-div'>
    <h3 style={{fontSize:'21px' ,fontWeight:'500',marginBottom:'1.5rem'}}>Children's Privacy</h3>
    <p>
    Our Service is not designed for individuals under the age of 13, and we do not knowingly collect personally identifiable information from this age group. If You are a parent or guardian and discover that Your child has provided Personal Data, please reach out to us. We take steps to promptly remove such information from our servers upon verification of parental consent if needed.
    </p>
    <p>
    In cases where consent from a parent is required for processing information based on the applicable laws of Your country, we may request and rely on that consent.
    
    </p>
  </div>

    <div className = 'privacy-main-topics-parent-div'>
    <h3 style={{fontSize:'21px' ,fontWeight:'500',marginBottom:'1.5rem'}}>Links to Other Websites</h3>
    <p>
    Our Service may include links to third-party websites beyond our control. It is essential to review the Privacy Policies of these external sites as we do not assume responsibility for their content, privacy practices, or policies.
    
    </p>



    <div className = 'privacy-main-topics-parent-div'>
    <h3 style={{fontSize:'21px' ,fontWeight:'500',marginBottom:'1.5rem'}}>Changes to this Privacy Policy</h3>
    <p>
    We may periodically update our Privacy Policy to reflect changes in our practices or legal requirements. Notification of such changes will be communicated via email and/or prominently displayed on our Service before the changes take effect. We recommend reviewing this Privacy Policy regularly for updates.
    </p>

  </div>




    <div className = 'privacy-main-topics-parent-div'>
    <h3 style={{fontSize:'21px' ,fontWeight:'500',marginBottom:'1.5rem'}}>Contact Us</h3>
    <p>
    For any inquiries or concerns regarding this Privacy Policy, please feel free to reach out to us:

    </p>
    <p><a style={{textDecoration:'underline'}} href="mailto:stays@jumboholidayz.com" target='_blank'>stays@jumboholidayz.com</a> </p>
 <p>Your privacy matters to us, and we are committed to addressing any questions or concerns you may have.</p>
  </div>


  {/* <div className = 'privacy-main-topics-parent-div'>
    <h3 style={{fontSize:'21px' ,fontWeight:'500',marginBottom:'1.5rem'}}>Retention of Your Personal Data</h3>
    <p></p>
  </div> */}

</div>
    </div>

   
  )
}

export default Privacy_policy
