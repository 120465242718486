import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  guests: 1,
  bedrooms: 1,
  beds: 1,
  bathrooms: 1,
};

const addCartSlice = createSlice({
  name: "addCartData",
  initialState,
  reducers: {
    updateGuests: (state, action) => {
      // const { index, max_guests } = action.payload;
      // state[index] = {
      //   ...state[index],
      //   max_guests: max_guests || 1,
      state.guests = action.payload;
    },
    updateBedrooms: (state, action) => {
      // const { index, bedrooms } = action.payload;
      // state[index] = {
      //   ...state[index],
      //   bedrooms: bedrooms || 1,
      // };
      state.bedrooms = action.payload;
    },
    updateBeds: (state, action) => {
      // const { index, beds } = action.payload;
      // state[index] = {
      //   ...state[index],
      //   beds: beds || 1,
      // };
      state.beds = action.payload;
    },
    updateBathrooms: (state, action) => {
      // const { index, bathrooms } = action.payload;
      // state[index] = {
      //   ...state[index],
      //   bathrooms: bathrooms || 1,
      // };
      state.bathrooms = action.payload;
    },
    // setGetAddCartData: (state, action) => {
    //   return action.payload;
    // },
    // setCartPropertyId: (state, action) => {
    //   return [...state, action.payload];
    // },
  },
});

export const {
  updateGuests,
  updateBedrooms,
  updateBeds,
  updateBathrooms,
  setCartPropertyId,
  setGetAddCartData,
} = addCartSlice.actions;

export const CounterDataReducer = addCartSlice.reducer;
