import React from "react";

const AmenitiesBoxes = (props) => {
  return (
    <div className="property-amenities-box">
      <p>{props.proertyAmenitiesText}</p>
    </div>
  );
};

export default AmenitiesBoxes;
