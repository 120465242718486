import React from 'react'
import CardWrapper from '../../Components/common/cardWrapper'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AccountDetailPage = () => {


  const storeSel = useSelector((state) => state);
  const fullNameData = storeSel.hostDetailSlice?.full_Name;
  const emailData = storeSel.hostDetailSlice?.host_Email;

    
  const userName = storeSel.hostDetailSlice.user_Name;
 



    const cardData = [
        {
          id: 1,
          imgUrl: "./assets/img/account1.svg",
          title: "Personal Info",
          description: "Provide personal details and how we can reach you.",
          link: "/hostPersonalInfo",
        },
        {
            id: 2,
            imgUrl: "./assets/img/account2.svg",
            title: "Login & Security",
            description: "Update your password and keep your account secure.",
            link: "/hostloginSecurity",
          
          },
          {
            id: 3,
            imgUrl: "./assets/img/account3.svg",
            title: "Transaction & Payouts",
            description: "Review payouts and transactions.",
            link: "/hostPayout_transaction",

          },
          {
            id: 4,
            imgUrl: "./assets/img/account4.svg",
            title: "Coupons",
            description: "You currently have 0 coupons left.",
          },
          {
            id: 5,
            imgUrl: "./assets/img/account5.svg",
            title: "Taxes",
            description: "Manage taxpayer information and tax documents.",
            link: "/HostTaxes",

          },
        
       
      ];

      
  return (
    <div className='account-detail-page'>
       <h2 className='account-head-text'>Account</h2>
       <p className='gmail-text'>{fullNameData}, </p>
       <p className='gmail-text'> {emailData}  <span>
       
        <Link   to={`/users/${userName}`}>Go to Profile</Link>
        </span></p>
     

       <div className='host-account-detail-box'>
        {cardData.map((card) => (
          <Link className='host-account-card' to={card.link} key={card.id}>
            <CardWrapper wrapperClass=''>
              <img className='host-account-icon' src={card.imgUrl} alt='' />
              <p className='title'>{card.title}</p>
              <p className='description'>{card.description}</p>
            </CardWrapper>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default AccountDetailPage
