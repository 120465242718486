import React from 'react'

const LineDiv = () => {
  return (
    <div className='line-div'>
      
    </div>
  )
}

export default LineDiv
