import React from "react";
import CardWrapper from "../cardWrapper";
import { formatIndianRupeesFunc } from "../../../utils/constant";

const EarningBreakDownCard = (props) => {
  return (
    <>
      <CardWrapper
        wrapperHeading="Earnings"
        wrapperClass="earning-breakdown-card"
      >
        <div className="earning-breakdown-parent-box">
          <div className="earning-breakdown-child-box">
            <p>
              <span>{props.perDayAmount}</span> x <span>{props.noOfDays}</span>{" "}
              night
            </p>
            {/* <p>Guest Service fee</p> */}
            <p> Taxes</p>
          </div>

          <div className="earning-breakdown-child-box">
            <p>{props.totalDayAmount}</p>
          {props.serviceAmount ?  <p>{props.serviceAmount}</p> : ''} 
            <p>{props.occupancyAmount}</p>
          </div>
        </div>
        <div className="earning-breakdown-total-amount-box">
          <p>Total(INR)</p>
          <p>₹{formatIndianRupeesFunc(props.totalAmount)}</p>          
        </div>
      </CardWrapper>
    </>
  );
};

export default EarningBreakDownCard;
