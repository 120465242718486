import { createSlice, configureStore } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  success_error: null,
  notFound:null,
  badRquest: null,
  internalServerErr: null,
  invalidDetails: null,
//   apiState5: null,

};

// Create a slice using createSlice
const apiSlicer = createSlice({
  name: "apiSlicer",
  initialState,
  reducers: {
    apiAction1: (state, action) => {
        state.success_error = action.payload.message;
        state.type = action.payload.type;
    },
    apiAction2: (state, action) => {
      state.notFound = action.payload;
    },

    apiAction3: (state, action) => {
      state.badRquest = action.payload;
    },

    apiAction4: (state, action) => {
      state.internalServerErr = action.payload;
    },

    apiAction5: (state, action) => {
      state.invalidDetails = action.payload;
    },
   
  },
});
export const {
    apiAction1,
    apiAction2,
    apiAction3,
    apiAction4,
    apiAction5
   
} = apiSlicer.actions;
export const ApiSlicerReducer = apiSlicer.reducer;
