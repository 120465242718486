import React from "react";

const TextareaComp = (props) => {
  return (
    <div className="text-area-comp">
      <label htmlFor={props.name}>{props.label}</label>
      <textarea
        className={props.textareaClass}
        id={props.name}
        name={props.name}
        value={props.value}
        defaultValue={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        rows={props.rows}
        cols={props.cols}
      />
    </div>
  );
};

export default TextareaComp;
