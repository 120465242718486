import React from 'react'
import { useNavigate } from 'react-router-dom';

const SecondStepListing = () => {
    const navigate = useNavigate()

    const navigateTo = () => {
            navigate("/host/createlisting/listingAmenities");

    
        };
    
    return (
    <div className='start-create-listing-page'>
    
    <div  className='flex step-flex-box m-flex-flow-column'>
        <div  className='step-1-img-box w-50 w-m-100 h-300px'>
        <img className='w-100' src="./assets/img/StartHomeImg2.jpg" alt="" /> 
        </div>
    
        <div className='w-50 w-m-100'>
        <p className='step-text'>Step 2</p>
        <h2>Elevate your presence and make it stand out</h2>
        <p className='step-para-text'>Get ready to highlight the amenities your place has to offer in this step. Don't forget to showcase its unique features by uploading 5 or more captivating photos. Lastly, craft an attention-grabbing title and description to make your listing stand out from the rest.</p>
        </div>
        </div>
    
        <div className="bottom-btns-fixed-box">
            
            <button
            onClick={navigateTo}
            type="submit"
            className="common-next-btn"
            >
            Next
            </button>
        </div>
    </div>
    )
}

export default SecondStepListing
