import React from 'react'
import { useForm } from 'react-hook-form';
import Button from '../../../Components/common/Button';
import axios from 'axios';
import { apiMainUser } from '../../../utils/constant';
import { openSnackbar } from '../../../utils/redux/SnackBarSlicer';
import { useDispatch } from 'react-redux';

const HostDOBPop = (props) => {
    const { handleSubmit, register } = useForm();


    console.log(props.usersData?.dob,'ttt')
    const dispatch = useDispatch(
      
    )
    const onSubmit = async (data) => {
      const formData = new FormData();
      formData.append('dob', data.dob);
      
  
      try {
        const response = await axios({
          method:'patch',
          url: apiMainUser + 'update_user',
        
          data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          
        }
        
        );
        if(response.data.status === 'success'){
          props.fetchData()
          props.closeModal()
          dispatch(openSnackbar({ message: 'DOB updated succesfully!', severity: 'success' }));

         
        }
      } catch (error) {
        console.error(error);
        dispatch(openSnackbar({ message: 'Something went wrong!', severity: 'error' }));

      }
    };
  
    
  const defaultDate = props.usersData?.dob ? props.usersData.dob.substring(0, 10) : '';
    return (
      <form className='my-dob-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='dob-form-input'>
          <label>Date of Birth</label>
          <input className='pop-input' type="date" {...register('dob')}
          defaultValue={ defaultDate} 
          />
        </div>
        <div className="clear_apply-btn-box paddingCustomAllSide">
          <p onClick={() => props.closeModal(false)} className="clear-btn">
            Cancel
          </p>
          <button className="save-edit-btn">Save</button>
        </div>
      </form>
    );
}

export default HostDOBPop
