import React, { useEffect, useState } from "react";
import { NavLink, Navigate } from "react-router-dom";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import {CleanCreateListingRedux, apiMainUser} from "../../utils/constant";
import { useSelector } from "react-redux";
import axios from "axios";
import WrapperPopup from "./wrapperPopup";
import VerifactionToNavigateHost from "./VerifactionToNavigateHost";


const ProfileButtons = (props) => {

  
  const storeSel = useSelector((state) => state);
  const userName = storeSel.hostDetailSlice.user_Name;
 
  const [verficationData,setVerificationData] = useState()


  const navigate = useNavigate();
  const cleanCreateListingRedux = CleanCreateListingRedux();

  const [verificatioProfilePop,setVerificationProfilePop] = useState(false)


  const toggleVisibleDocks = () => {
    setVerificationProfilePop(false)
    }

  
  useEffect(() => {
    const fetchData = async () => {
      try {
 
        const userResponse = await axios.get(apiMainUser + "get_user_detail", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          }
        });
        setVerificationData(userResponse.data.data[0])
      } catch (error) {
      }
    };

    fetchData();
  }, [userName]); // Trigger the effect whenever userName changes

  const handleSwitchToHosting = () => {
    if (verficationData?.mobile_verified !== 0 && verficationData?.email_verified !== 0 && verficationData?.user_docs_details !== []) {
      navigate("/host");
    } else {
      setVerificationProfilePop(true);
    }
  };




    const logOut = ()=>{
        localStorage.clear();
        sessionStorage.clear();
        cleanCreateListingRedux();
        navigate("/login");
    }
  if(localStorage.getItem("token") === null){
    return (
<>
              <br/>
              <NavLink
                  className={`left-tab-links`}
                  exact
                  to="/login"
                >
                <Button
                  btnText="Login"
                  btnclasses={`all-tab-btn tab-btn-hover flex justify-start w-100`}
                />
              </NavLink>
              <NavLink
                  className={`left-tab-links`}
                  exact
                  to="/signup"
                >
                <Button
                  btnText="Signup"
                  btnclasses={`all-tab-btn tab-btn-hover flex justify-start w-100`}
                />
              </NavLink>
              </>
    );
  }else{
  return (<>
              <br/>
                {/* {window.location.pathname.includes("/host") ? 
                <NavLink
                  className={`left-tab-links`}
                  exact
                  to="/"
                >
                <Button
                  btnText="Switch to travelling"
                  btnclasses={`all-tab-btn tab-btn-hover flex justify-start w-100`}
                />
              </NavLink> : 
              <NavLink
                className={`left-tab-links`}
                exact
                to="/host"
                >
                <Button
                btnText="Switch to hosting"
                btnclasses={`all-tab-btn tab-btn-hover flex justify-start w-100`}
                />
            </NavLink>
            } */}

         {window.location.pathname.includes("/host") ? (
            <NavLink className={`left-tab-links`} exact to="/">
              <Button
                btnText="Switch to travelling"
                btnclasses={`all-tab-btn tab-btn-hover flex justify-start w-100`}
              />
            </NavLink>
          ) : (
            <Button
              btnText="Switch to hosting"
              btnclasses={`all-tab-btn tab-btn-hover flex justify-start w-100`}
              onClick={handleSwitchToHosting}
            />
          )}


              <NavLink
                  className={`left-tab-links`}
                  exact
                  to={`/users/${userName}`}
                >
                <Button
                  btnText="Profile"
                  btnclasses={`all-tab-btn tab-btn-hover flex justify-start w-100`}
                />
              </NavLink>

              <NavLink
                  className={`left-tab-links`}
                  exact
                  to="/hostAccount"
                >
                <Button
                  btnText="Account"
                  btnclasses={`all-tab-btn tab-btn-hover flex justify-start w-100`}
                />
              </NavLink>

              <NavLink
                  className={`left-tab-links`}
                  exact
                  to="/user-message"
                >
                <Button
                  btnText="Messages"
                  btnclasses={`all-tab-btn tab-btn-hover flex justify-start w-100`}
                />
              </NavLink>

          
              <NavLink
                  className={`left-tab-links`}
                  exact
                  to="/trip"
                >
                <Button
                  btnText="Trips"
                  btnclasses={`all-tab-btn tab-btn-hover flex justify-start w-100`}
                />
              </NavLink>

              <NavLink
                  className={`left-tab-links`}
                  exact
                  to="/wishlist"
                >
                <Button
                  btnText="Wishlist"
                  btnclasses={`all-tab-btn tab-btn-hover flex justify-start w-100`}
                />
              </NavLink>
              {/* <NavLink
                  className={`left-tab-links`}
                  exact
                  to="/login"
                > */}
                <Button
                  btnText="Logout"
                  onClick={logOut}
                  btnclasses={`all-tab-btn tab-btn-hover flex justify-start w-100`}
                />
              {/* </NavLink> */}

 
      <div className="edit-booking-preference-popup">
        {verificatioProfilePop && (
          <WrapperPopup
          fluid={true}
     
          defaultSize={"0.90"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
          closeModal={setVerificationProfilePop}
          comp={
         <VerifactionToNavigateHost
         closeModal={setVerificationProfilePop}
         />
        }
        />
        
        )}
      </div>

            </>
            );
          }
        };
        
        export default ProfileButtons;