import React, { useRef, useState } from 'react'
import CardWrapper from '../../Components/common/cardWrapper';
import { Controller, useForm } from 'react-hook-form';
import BookingEditGuest from './BookingEditGuest';
import ButtonPopup from '../../Components/common/ButtonPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import DatePicker from '../../Components/UserComponents/datePicker';
import { useDispatch } from 'react-redux';
import { updateUserAdults, updateUserChildrens, updateUserGuests, updateUserInfants } from '../../utils/redux/User/UserSearchConfig';
import { useSelector } from 'react-redux';
import Button from '../../Components/common/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { updateURLWithCountsFunc, updateUrlWithDatesFunc } from '../../utils/constant';
import moment from 'moment';
import { getTotalGuestCount } from '../../utils/varResults';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
const MobHeadDestinationGuest = (props) => {

  const { control, handleSubmit } = useForm();
  const [minDateState, setMinDate] = useState();
  const [maxDateState, setMaxDate] = useState();
  const [selectedCheckIn, setCheckIn] = useState();
  const [selectedCheckOut, setCheckOut] = useState();
  
  const [guestCount, setGuestCount] = useState(null);
    const [infantCount, setInfantCount] = useState(null);
    const [childrenCount, setChildrenCount] = useState(null);
    const [adultCount, setAdultCount] = useState(null);

  // const dispatch =  useDispatch()




  const maxNight = props.successdata?.data?.data[0].maxnight
  const minNight = props.successdata?.data?.data[0].minnight

  const setCurrentLocationUndefined = async ()=>{
    setCurrentLocation(undefined)
  }

  const Guest_date_saveFun = async ()=>{
    await setCurrentLocationUndefined();
    console.log(selectedCheckIn,selectedCheckOut,"datesshow")
    if(selectedCheckIn !== undefined && selectedCheckOut !== undefined)
    updateUrlWithDatesFunc(moment(selectedCheckIn),moment(selectedCheckOut))
    updateURLWithCountsFunc(adultCount,childrenCount,infantCount);
  }



  const onDateChange = (checkIn, checkOut) => {
    setCheckIn(checkIn?.format("YYYY-MM-DD"));
    setCheckOut(checkOut?.format("YYYY-MM-DD"));
    
  };

  const handleCounts = (adults, children, infants) => {
    // var guestlist = parseInt(adults, 10) + parseInt(children);
    var guestlist = getTotalGuestCount(adults,children)

    var adultlist = adults;
    var childlist = children;
    var infantlist = infants;
    setGuestCount(guestlist);
    setInfantCount(infantlist);
    setChildrenCount(childlist);
    setAdultCount(adultlist);
  
    // Update the URL with the current counts
    // updateURLWithCounts(adultlist, childlist, infantlist);
  };

  const location = window.location;
  const queryParamsGet = new URLSearchParams(location.search);

  const getQueryParamValue = (paramName) => {
    return queryParamsGet.get(paramName) || null;
  };




  
  
  useEffect(() => {
    const checkin = getQueryParamValue('checkin');
    const checkout = getQueryParamValue('checkout');
    const adults = getQueryParamValue('adults');
    const infants = getQueryParamValue('infants');
    const children = getQueryParamValue('children');

    if (checkin && checkout) {
      setCheckIn(checkin);
      setCheckOut(checkout);
      setMinDate(checkin);
      setMaxDate(checkout);
    }
    if (adults) {
      setAdultCount(adults);
    }
    if (children) {
      setChildrenCount(children);
    }
    if (infants) {
      setInfantCount(infants);
    }
    if(adults || children){
      var guestlist = getTotalGuestCount(adults,children)
      setGuestCount(guestlist)
    }

  }, []);



  const closeModal=()=>{
    props.closeModal()
  }
 

console.log(props.updateUrl,"updateUrl mobhead")
  const dynamiclocation = window.location.search;

 
  const [currentLocation, setCurrentLocation] = useState(false);
  const prevLocRef = useRef();

  useEffect(()=>{       
    if(dynamiclocation.includes('?') && currentLocation === undefined){
      var savelocation = dynamiclocation.split('?')
      console.log("location:curr",savelocation[1])
      setCurrentLocation(savelocation[1])
    }
  },[dynamiclocation])


  const navigate = useNavigate()

  

  useEffect(()=>{
    
    if(currentLocation !== false){
      if(!props.isSave){
        const linkTo = `/properties${currentLocation && currentLocation !== '' ? '?'+currentLocation : ''}`;
        navigate(linkTo)
      }
      props.closeModal()
    }

  },[currentLocation])

  
 

  const onSubmit = (data) => {
    console.log(data)
  };

  const stateName=["Goa","Mumbai",'Gorakhpur',"Manali","Jaipur","Chennai"]
  const [endate, setEndate] = useState(null);
  const [startdate, setStartdate] = useState(null);
 
  const selectionRange = {
    startDate: new Date(startdate),
    endDate: new Date(endate),
    key: 'selection',
  }

  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    },
  });

  const handleSelect = (ranges)=>{
    console.log(ranges,"ranges");
    setStartdate(ranges.selection.startDate)
    setEndate(ranges.selection.endDate)
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }

  return (
    <div className='Mob-destination-guest-box '>
    


 <p style={{fontSize:'13px', fontWeight:600,marginBottom:'0.5rem '}}>When To Travel</p>            

    <div className="guest-dropdown">
    {/* <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleSelect}
        months={1}
        direction="vertical"
        
        minDate={new Date("10-20-2022")}
        maxDate={new Date("10-20-2024")}
      /> */}
        <DatePicker  
        minDate={minDateState}
        startDate={minDateState}
        endDate={maxDateState}
        blockedDates={props.blockDateState}
        blockedDatesCheckin={props.blockCheckinDateState}
        blockedDatesCheckout={props.blockCheckoutDateState}
        onDateChange={onDateChange}
        minNight={minNight}
        maxNight={maxNight}
        updateUrl={props.updateUrl !== undefined ? props.updateUrl : false}

        />

    </div>
    <br/>
    <p style={{fontSize:'13px', fontWeight:600,marginBottom:'0.5rem '}}>Who?</p>            
    <div style={{border:'1px solid #19A6BB'}} className="guest-infant-div">
            <ButtonPopup
            buttonPopup={true}
            saveBtnClass='sav-edit-btn'
            wrapclass ='absolute w-100'
            additionalClass='remove-margin'
            
            makeCustom={
            <div className="guest-dropdown">
              <div style={{paddingLeft:'0.7rem'}}>
            <p className="guest-count-text">
         
           {guestCount > 1 ? guestCount + " " +  'Guests' : '1 Guest'} 
           {infantCount && infantCount > 0 ? (infantCount > 1 ? ', '+infantCount+' Infants' : ', 1 Infant') : ''}            
          </p>
          </div>
          <FontAwesomeIcon icon={faAngleDown} className="arrow-icon" />
          </div>
          }
            divContent={
            <BookingEditGuest 
            handleCounts={handleCounts} 
            maxGuests={props.max_guests}
           
           updateUrl={props.updateUrl !== undefined ? props.updateUrl : false}

            />
          }
           />
            </div>

            {props.isSave ? 
            <div className="search-btn-fixed">
            <Button 
            onClick={Guest_date_saveFun}
            btnText='Save'
            btnclasses='common-next-btn ' />
             </div>  

             : 

            //  <Link  to={linkTo} >

             <div className="search-btn-fixed">
            <Button 
            onClick={Guest_date_saveFun}
            btnText='Search'
            btnclasses='common-next-btn ' />
             </div>  
            //  </Link>
          }


          
         

  </div>
  )
}

export default MobHeadDestinationGuest;
