import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HeadingComp from '../HeadingComp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { openSnackbar } from '../../../../utils/redux/SnackBarSlicer';
import { useDispatch } from 'react-redux';
import ImportCalendarPop from './ImportCalendarPop';
import WrapperPopup from '../../../../Components/common/wrapperPopup';
import { apiMainUser } from '../../../../utils/constant';
import axios from 'axios';
import CardWrapper from '../../../../Components/common/cardWrapper';
import SkeletonTable from '../../../../MobSkeleton/SkeletonTable';
import DynamicTable from '../../../../Components/common/HostTables';
import Button from '../../../../Components/common/Button';
import { setPromptComp, setPromptNotes, setPromptOkay, setPromptText } from '../../../../utils/redux/GetPromptSlice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FooterComponent from '../../../../Pages/common/FooterComponent';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {

    // console.log(props,'checkId')
  const [value, setValue] = React.useState(0);

  const [openImportCalendarPop,setOpenImportCalendarPop ] = React.useState(false)
  const [calendarItem,setCalendarItem ] = React.useState()


  const dispatch = useDispatch()



  const [importSuccessData, setImportSuccessData] =  React.useState([])
  const [exportSuccessData, setExportSuccessData] =  React.useState([])

  const storeSel = useSelector((state) => state);
 
  const isPromptOk = storeSel?.PromptSlice?.isPromptOk;
  const isPromptNotes = storeSel?.PromptSlice?.promptNotes;




///get api called  for import and export below---------------------

const fetchCalImportLinks = ()=>{
    const token = localStorage.getItem('token');

    if (token) {
      axios.get( `${apiMainUser}read_calender_event/${props.propId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('CalendarData:', response);
        setImportSuccessData(response.data.data)

      })
      .catch((error) => {
        console.error('Error fetching :', error);
        // setSuccessData('No Link found')
      });
    } else {
      console.error('Token not found');
    }
}

   const fetchCalExportLinks = ()=>{
    const token = localStorage.getItem('token');

    if (token) {
      axios.get( `${apiMainUser}get_calendar_export_link_for_property/${props.propId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('CalendarData:', response);
        setExportSuccessData(response.data.data)

      })
      .catch((error) => {
        console.error('Error fetching :', error);
        setExportSuccessData('No Link found')
      });
    } else {
      console.error('Token not found');
    }
}

React.useEffect(() => {
    fetchCalImportLinks()
    fetchCalExportLinks()
  }, [])


  console.log(exportSuccessData,'checkExport')

  //delete api function below-------------------------------

  useEffect(() => {
    if (isPromptOk) {
        deleteCalendarItem()
      dispatch(setPromptOkay(false));
      dispatch(setPromptComp(false))
      dispatch(setPromptText(null))
      dispatch(setPromptNotes({}))

    }
  }, [isPromptOk, dispatch]);


   
  const deleteCalendarItem = () => {

    const token = localStorage.getItem('token');
  
    if (token) {
      axios.delete(`${apiMainUser}delete_calender_event/${isPromptNotes.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('Calendar item deleted:', response);
        fetchCalImportLinks()
        dispatch(openSnackbar({ message: 'Calendar Link deleted!', severity: 'success' }));
      })
      .catch((error) => {
        dispatch(openSnackbar({ message: 'Failed to delete calendar item!', severity: 'error' }));
      });
    } else {
      console.error('Token not found');
      // Handle the scenario where the token is not available
    }
  };

  //----------------------------

  const handleSingleSync = (uid) => {
    const token = localStorage.getItem('token');

    // Make the POST request using Axios with authorization header
    axios.post(`${apiMainUser}sync_for_calender_event/${uid}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => {
      
        if (response.status === 200) {
          fetchCalImportLinks()
          dispatch(openSnackbar({ message: 'Sync completed successfully!', severity: 'success' }));
        }
        
      })
      .catch(error => {
        // Handle error
        console.error('Error:', error);
      });
}


  const handleSyncAll = () => {
   

    const token = localStorage.getItem('token');

    // Make the POST request using Axios with authorization header
    axios.post(`${apiMainUser}calendar_sync_for_property/${props.propId}`,{}, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => {
        if (response.status === 200) {
          fetchCalImportLinks()
          dispatch(openSnackbar({ message: 'Sync completed successfully!', severity: 'success' }));

        }
      })
      .catch(error => {
        // Handle error
        console.error('Error:', error);
        dispatch(openSnackbar({ message: 'Something went wrong!', severity: 'error' }));
      });
  }


  


  //---------------


  const Alldata = importSuccessData?.map(item => {
    console.log(item,'checkItem')
    const calendarLink = item?.import_link || "N/A";
    const truncatedLink = calendarLink.length > 70 ? `${calendarLink.substring(0, 70)}...` : calendarLink;
  
    return {
      "Calendar Name": <p style={{fontWeight:'500'}} className='titleTexTProperty'>{item?.import_name}</p>  || "N/A",
      "Calendar Link": <p style={{fontWeight:'500'}} className='titleTexTProperty'>{truncatedLink}</p>,
      "Action": 
      <div className='flex justify-space-between'>
     
       <div 
      onClick={() => {
        setOpenImportCalendarPop(true); 
        setCalendarItem(item);

      }}
      className='basic-detail-edit-btn mr-10'>Edit</div>

      <div 
      onClick={() => {
        handleSingleSync(item.uid)

      }}
      className='basic-detail-edit-btn mr-10'>Sync</div>

       <div 
        onClick={()=>{
            dispatch(setPromptNotes({id:item.uid}))
            dispatch(setPromptComp(true))
           dispatch(setPromptText('Are you sure you want to delete this Link'))
        }     
        }
      className='basic-detail-edit-btn'>Delete</div>

</div>,
    };
  });

//--------------------------

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Function for copy the export input Link---------------------

  const handleCopy = () => {
    const inputElement = document.getElementById('text-input');
    if (inputElement) {
      inputElement.select();
      navigator.clipboard.writeText(inputElement.value)
        .then(() => {
        
          console.log('Text copied to clipboard');
          dispatch(openSnackbar({ message: 'Copied!', severity: 'success' }));

        })
        .catch((err) => {
          // Handle any errors that might occur during the copy process
          console.error('Unable to copy text to clipboard', err);
        });
    }
  };


  //---------------------------------------------



  const handleAddMore = () => {

    setOpenImportCalendarPop(true)
    
    // const newCalendarData = [...calendarData];
    // newCalendarData.push({ name: 'New Calendar', link: 'http://newcalendar.com' });
    // setCalendarData(newCalendarData);
  };

  const toggleVisibleDocks = () => {
    setOpenImportCalendarPop(false)

  };


  
  return (
    <>
    
    <Box style={{marginTop:'2rem'}} sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab  label="Export Link" {...a11yProps(0)} />
          <Tab label="Import Link" {...a11yProps(1)} />
       
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
     
       <div className='calendar-export-div'>
       <input id='text-input' className='export-link-input' type="text"  value={exportSuccessData?.[0]?.export_link}
        
       readOnly
       />
       <FontAwesomeIcon className='copy-icon' onClick={handleCopy} icon={faCopy}/>
       </div>
       
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <div className="calendar-import-div">
        <div className='calendar-import-div'>
         
        {importSuccessData &&  (
          Array.isArray(Alldata) && Alldata.length > 0 ? (
            <DynamicTable data={Alldata} hidden={[]} itemsPerPage={5} />
          ) : (
            <p>No Link found.</p>
          )
        )}
        </div>

      {/* </CardWrapper> */}
    
       <div style={{gap:'20px'}} className='flex align-items-center'>
        <button onClick={handleAddMore} className='add-more-link-btn' >
          Add More
        </button>
        <p onClick={handleSyncAll} style={{alignSelf:'end'}}  className='underline pointer font-small' >
          Sync All
        </p>
        </div>
        </div>
      </CustomTabPanel>
      
    </Box>

    


    <div className="import-calendar-popup">
        {openImportCalendarPop && (
          <WrapperPopup
          fluid={true}
          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks}
          closeModal={() => setOpenImportCalendarPop(false)}
          headingText = "Add Name and Link" 
            comp={
              <ImportCalendarPop
                // fetchData={props.fetchData}
                propId={props.propId}
                // allData={allData}
               calendarItem={calendarItem}
                closeModal={() => setOpenImportCalendarPop(false)}
                fetchCalImportLinks={fetchCalImportLinks}

              />
            }
          />
        )}
      </div>
    </>
  );
}