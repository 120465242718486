import React from 'react'
import { formatIndianRupeesFunc } from '../../utils/constant'
import CardWrapper from '../common/cardWrapper'

const CnacellationViewBreakDown = (props) => {
  return (
    <div>
       <CardWrapper wrapperClass="view-breakdown-main-box mt-10">



   <div className="view-breakdown-mid-price-box">
  
  <div className="view-breakdown-price-details-box">
    <div>
      
      <p>Booking Amount</p>
      <p>Cancellation Fee</p>
      <p>Refund Amount</p>
     
    </div>

    <div style={{ textAlign: "right" }}>
      <p>₹{formatIndianRupeesFunc(props?.perDayprice)}</p>
      <p>₹{formatIndianRupeesFunc(props?.ExpPrice)}</p>
      <p>₹{formatIndianRupeesFunc(props?.TaxPrice)}</p>
      
    </div>
  </div>
</div>


</CardWrapper>
    </div>
  )
}

export default CnacellationViewBreakDown
