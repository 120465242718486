import React, { useEffect, useRef, useState } from 'react'
import GuestMessageComp from '../../Components/common/MessageComponents/GuestMessageComp'
import UserMessageComp from '../../Components/common/MessageComponents/UserMessageComponent'
import LeftMessgeCardWrappers from '../../Components/common/MessageComponents/LeftMessgeCardWrappers'
import RightMessagePostCard from '../../Components/common/MessageComponents/RightMessagePostCard'
import EmojiPickerComponent from '../../Components/common/MessageComponents/EmojiPickerComponent'
import CardWrapper from '../../Components/common/cardWrapper'
import GuestBasic from '../../Components/common/guestBasic'
import ProfileImage from '../../Components/common/profileImg'

import Button from '../../Components/common/Button'
import { apiMainUser, capitalizeText } from '../../utils/constant'
import axios from 'axios'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Pusher from 'pusher-js'; 
import { useLocation, useNavigate } from 'react-router-dom'

const MessageUserSide = () => {


 

  const [chatRoomsHost, setChatRoomsHost] = useState([]);
  const [assetsurl, setAssetsUrl] = useState();

  const [rightSideContent, setRightSideContent] = useState();
  const [messageArray,setMessageArray] = useState([]);

  const [queryParameterSave,setQueryParamterSave] =useState()

  const updateMessageArray = (newMessage) => {
    setMessageArray((prevMessages) => [newMessage,...prevMessages]);
  };

  console.log(messageArray,'checkArrayIf')



  const storeSel = useSelector((state) => state);
  const userData = storeSel.hostDetailSlice.user_Name;
  console.log(userData,'checkUserdata')

 
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const roomIdFromQuery = queryParams.get('room_id');
  const [roomId, setRoomId] = useState(roomIdFromQuery);
  

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiMainUser + "get_chat_rooms", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });

        if (response.status === 200) {
          const roomData = response.data.data;
          setAssetsUrl(response.data.assets_url)
          setChatRoomsHost(roomData);
          const initialRoomId = roomData[0]?.room_id;
          setRoomId(initialRoomId);

          const queryParams = new URLSearchParams(window.location.search);
          queryParams.set('room_id', initialRoomId);

          const newUrl = queryParams.toString()
            ? `${window.location.pathname}?${queryParams.toString()}`
            : window.location.pathname;

          window.history.replaceState({}, "", newUrl);

          // After setting the initial room ID, call the second API
          if (initialRoomId) {
            await fetchChatByRoomId(initialRoomId);
          }
        } else {
          console.error("Failed to fetch:", response.status);
        }
      } catch (error) {
        console.error("Error fetching:", error);
      }
    };

    fetchData();
  }, []); // Dependency array to run the effect only once

  useEffect(() => {
    
    if (roomId) {
      fetchChatByRoomId(roomId);
    }
  }, [roomId]);

  const fetchChatByRoomId = async (roomId) => {
    try {
      const headers = {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      };

      const response = await axios.get(apiMainUser + `read_chat_user/${roomId}`, { headers });

      if (response.status === 200) {
        console.log('API Response:', response.data);
        setRightSideContent(response.data.data.chat_room_details[0]);
        setMessageArray(response.data.data.message_array);
      } else {
        console.error("Failed to fetch chat:", response.status);
      }
    } catch (error) {
      console.error("Error fetching chat:", error);
    }
  };




  
  const isSmallScreen = window.innerWidth <= 500;

  // const [isMessageMoved, setIsMessageMoved] = useState(false)




  const [step, setStep] = useState(-1);
 
  const handleApiCall = (roomId) => {

    console.log('justCalled')
  // const isSmallScreen = window.innerWidth <= 500;


  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set('room_id', roomId);
  
  
  // Replace the current state without causing a full page reload
  const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
  window.history.replaceState({}, '', newUrl);
  setRoomId(roomId);

 
  console.log(isSmallScreen,'checkScreen')

   if (isSmallScreen) {
    console.log('ander')
    setStep(0)
    // setIsMessageMoved(prevState => !prevState); 
   }
  
  
   };



   
   console.log(rightSideContent,chatRoomsHost,'rightSide')

   const checkinDateForrightConent = rightSideContent?.lead_event_details[0].checkin_date
   const checkoutDateForrightConent = rightSideContent?.lead_event_details[0].checkout_date

   const formattedCheckInDate = moment(checkinDateForrightConent).format('ddd DD MMM YYYY');
   const formattedCheckOutDate = moment(checkoutDateForrightConent).format('ddd DD MMM YYYY');

   console.log(rightSideContent,'checkContentValue')



   const [pusherInstance, setPusherInstance] = useState(null); 

// ...

   const initializePusher = () => {
  console.log('Initializing Pusher...');
  const pusher = new Pusher('582888e794e6b9a114e3', {
    cluster: 'ap2',
  });

  const channel = pusher.subscribe('Chats');
  console.log(roomId, 'checKroomId');

  channel.unbind(); // Unbind the existing event bindings

  channel.bind(roomId, function (data) {
    if (
      data?.chat_room_details?.[0]?.chat_users[data?.message_array?.[0]?.message_from]?.username !=
      userData
    ) {
      console.log('donelastOne');

      const newMessage = {
        message_type: '',
        message: data?.message_array?.[0]?.message,
        createdon: data?.message_array?.[0]?.createdon,
        lead_event_id: data?.message_array?.[0]?.lead_event_id,
        updatedon: data?.message_array?.[0]?.updatedon,
        message_from: data?.message_array?.[0]?.message_from,
        you: 0,
      };

      console.log('donelast');

      updateMessageArray(newMessage);
      console.log('donelastBefore');
    }

    console.log(data, 'checkAllDaTa');
  });

  setPusherInstance(pusher);
};

useEffect(() => {
  initializePusher();
}, [roomId]); // Include roomId in the dependency array to reinitialize when it changes

console.log(pusherInstance, 'checkPusher');



    

    const messageContainerRef = useRef(null);

    useEffect(() => {
      // Scroll to the bottom when messageArray changes
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
      }
    }, [messageArray])


    
    const prevRoomIdRef = useRef(null);

    ////
    useEffect(() => {
      const exitChatRoom = async (roomId) => {
        try {
          const headers = {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          };
    
          await axios.post(apiMainUser + "chat_entry_exit", {
            chat_room_id: roomId,
            event: "exit"
          }, { headers });
          console.log("Exited chat room:", roomId);
        } catch (error) {
          console.error("Error exiting chat room:", error);
        }
      };
    
      const enterExitChatRoom = async () => {
        if (prevRoomIdRef.current) {
          exitChatRoom(prevRoomIdRef.current);
        }
    
        if (roomId) {
          const headers = {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          };
          
          try {
            await axios.post(apiMainUser + "chat_entry_exit", {
              chat_room_id: roomId,
              event: "entry"
            }, { headers });
            console.log("Entered chat room:", roomId);
          } catch (error) {
            console.error("Error entering chat room:", error);
          }
        }
    
        prevRoomIdRef.current = roomId;
      };
    
      enterExitChatRoom();
    }, [roomId]);

    
    const navigate = useNavigate()

    const navigateToProfile =()=>{
      navigate(`/users/${rightSideContent?.chat_users?.[1]?.username}`)
    }

    console.log(step,'checkStep')


    const moveToThirdStep =()=>{
      
     if (isSmallScreen) {
       console.log('ander')
         setStep(1)
    // setIsMessageMoved(prevState => !prevState); 
    }
      }
    

    


  return (
    <div   className='message-page message-user-page'>

   <div className={`message-left-div child-div ${step === 0 || step === 1 ? 'translated' : (step === -1 ? 'initial' : '')}`}>


      <p style={{fontSize:'20px',fontWeight:'700',margin:'1rem 0rem 3rem'}}>Messages</p>
      {chatRoomsHost.map((room) => {
      const matchingUser = room?.chat_users.find((user) => user.username == userData);

      if (matchingUser) {
      const firstUser = room?.chat_users[1];
      const guestImg = firstUser.image ? assetsurl+firstUser.image : '/assets/img/userIconImg.jpg';
      const guestName = `${capitalizeText(firstUser.fname)} ${capitalizeText(firstUser.lname)}`;
      // const checkinDate = moment(room?.lead_event_details[0]?.checkin_date).format('D');
      // const checkoutDate = moment(room?.lead_event_details[0]?.checkout_date).format('D MMMM');
  
      // const messageDates = `${checkinDate}-${checkoutDate}`;

      return (
      <LeftMessgeCardWrappers
        key={room.room_id}
        guestImg={guestImg}
        location={room?.property_details[0]?.property_city || ''}
        guestName={guestName}
        messageText='.' // Add your dynamic messageText logic here
        // messageEnquirytext='Cancelled'
        
        selectedCheckIn={room?.lead_event_details[0]?.checkin_date}
        selectedCheckOut={room?.lead_event_details[0]?.checkout_date}

        propertyName={room?.property_details[0]?.property_name || ''}
        // additionalClass='cancelled-text'
        onClick={() => handleApiCall(room.room_id)}
        unReadMessage={room.unread_message}
      />
    );
  }

  return ''; // If no matching user is found, skip rendering
})}
    </div> 

    <div className={`message-middle-div child-div ${step === 1 ? 'translated200' : (step === 0 ? 'translated-self' : (step === 2 ? 'translated200' : ''))}`}>

    <div className='mesage-middle-top-section'>
    
     <p style={{fontSize:'15px',fontWeight:'600'}}>{capitalizeText(rightSideContent?.chat_users?.[1]?.fname)}</p>
     {/* <p  style={{fontSize:'11px',color:'#707980'}}>Last seen 11hr ago</p> */}
     

     {isSmallScreen &&  
      <span onClick={moveToThirdStep} class="material-symbols-outlined">
      info
     </span>
     }
    
     </div>
 

     <div className='message-middle-subchild-div' ref={messageContainerRef}>
        {messageArray.slice().reverse().map((message) => {
          if (!message.message || message.message.trim() === '') {
            // Skip rendering for null or empty messages
            return null;
          }

          if (message.you == 0) {
            console.log(message.message_from,'checkArray')
            return (
              <GuestMessageComp
                key={message.createdon}
                guestName={capitalizeText(rightSideContent?.chat_users?.[message.message_from]?.fname)}
                messageText={message.message}
                messageTime={moment.utc(message.createdon).format('HH:mm')}

                guestImg={rightSideContent?.chat_users?.[1]?.image ? assetsurl+rightSideContent?.chat_users?.[1]?.image : undefined}
              />
            );
          } else if (message.you == 1) {
            console.log((message.createdon),'checkDates')
            return (
              <UserMessageComp
                key={message.createdon}
                messageText={message.message}
                // messageTime={moment(message.createdon).format('HH:mm')}
                messageTime={moment.utc(message.createdon).format('HH:mm')}

              />
            );
          }
          return null; // Skip rendering for other cases
        })}
      </div> 
       <EmojiPickerComponent
       lead_events={rightSideContent?.lead_event_details?.[0]?.uid}
       room_id={rightSideContent?.room_id}
       messageArray={messageArray}
       updateMessageArray={updateMessageArray}
       />
   
    </div>
     <div className={`message-right-div child-div ${step === 2 ? 'translated' : (step === 1 ? 'translated200' : (step === -1 ? 'initial' : ''))}`}>

            <RightMessagePostCard
            propertyName={rightSideContent?.property_details[0].property_name}
            Night={rightSideContent?.lead_event_details[0]?.nights}
            propertImg={rightSideContent?.property_details[0].base_url_image + rightSideContent?.property_details[0].property_images[0] }
            adultsCount='2'
            infantCount='2'

            checkIn={formattedCheckInDate}
            checkOut={formattedCheckOutDate}
            confirmationCode={rightSideContent?.lead_event_details[0]?.
            booking_id}

                />


             <CardWrapper onClick={navigateToProfile} wrapperHeading='About Host' wrapperClass='about-guest-card pointer'>
                <GuestBasic guestName={capitalizeText(rightSideContent?.chat_users?.[1]?.fname)} verfiedText='Verified' icon='./assets/img/guestverified.svg' noReviews={rightSideContent?.chat_users?.[1]?.reviewRating?.user?.review_count} starIcon='./assets/img/colorStarIcon.svg' starPoint={rightSideContent?.chat_users?.[1]?.reviewRating?.user?.total_rating?.Overall_Average} guestDuration='Jumbo guest since April 2023'/>
                <ProfileImage src={rightSideContent?.chat_users?.[1]?.image ? assetsurl+rightSideContent?.chat_users?.[1]?.image : undefined} width='100%'/>

                </CardWrapper> 
                {/* <Button
             onClick={()=>setReviewModal(true)}
             btnText='Add Review' btnclasses= 'reveiw-btn'/> */}
                {/*  */}
           

    </div>

  
    
    </div>
  )
}

export default MessageUserSide;
