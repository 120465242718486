import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedImages: [],
  failedImages: [],

};

const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    setSelectedImages: (state, action) => {
      state.selectedImages = action.payload;
    },
    setFailedImages: (state, action) => {
      console.log(action,"step3payload")
      state.failedImages = action.payload;
    },
    deleteFailedImage: (state, action) => {
      state.failedImages = state.failedImages.filter(
        (image, index) => index !== action.payload
      );
    },
    deleteSuccesImageAction: (state, action) => {
      state.selectedImages = state.selectedImages.filter(
        (image, index) => index !== action.payload
      );
    },
   
  },
});

export const {
  setSelectedImages,
  setFailedImages,
  deleteFailedImage,
  deleteSuccesImageAction
} = imageSlice.actions;

export const dragDropReducer = imageSlice.reducer;
