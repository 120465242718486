import React, { useEffect } from "react";
import Button from "../../../Components/common/Button";
import { Controller, useForm } from "react-hook-form";
import InputComp from "../../../Components/common/textInput";
import SwitchExample from "../../../Components/common/switch";
import { useState } from "react";
import FontComp from "../../../Components/common/fontComp";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import APIHit from "../../../utils/apiHit";
import { apiMainUser, apiPublicUser, checkErrors } from "../../../utils/constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fullNAmAction, fullNameAction, getEmail, getImage, getUid, usernameAction } from "../../../utils/redux/HostDetailSlice";
import axios from "axios";
import { useSelector } from "react-redux";
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { closeSnackbar, openSnackbar } from "../../../utils/redux/SnackBarSlicer";

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [logindata, setLoginData] = useState("");
  const [showerror, setError] = useState(null);

  const dispatch = useDispatch()
  const navigate = useNavigate();

  
  const storeSel = useSelector((state) => state);
  const loginuid = storeSel?.hostDetailSlice?.host_Uid;
console.log(loginuid,"loginuid")
console.log(storeSel,"loginuid")

  
  const location = useLocation();

  useEffect(() => {
    console.log(loginuid,"loginuid")
console.log(storeSel,"loginuid")
    if(loginuid !== null && loginuid !== '' ){
      navigate('/')
    }
  }, [loginuid]);

  const handleGoogleLogin = async () => {
    console.log('called')
    try {
      const googleLoginData = {
        signinOptions:"google"
      }
      const response = await axios
      .post(apiMainUser + "login", googleLoginData,{
      
      });
  
      // Handle the response as needed
      console.log("Google signup success:", response.data);
      window.open(response.data.consentUrl, "_blank", "width=500,height=500");
  
      // Redirect or perform other actions after successful signup
    } catch (error) {
      // Handle errors
      console.error("Google signup error:", error);
  
      // Display an error message to the user if needed
    }
  };



  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [checked, setChecked] = useState(false);

  const onSubmit = (data) => {
    let finaldata = {
      signinOptions: "direct",
      ...data,
    };
    setLoginData(finaldata);
  };

  const preCondition = () => {
    let ercheck = checkErrors(errors);
    
    return ercheck;
  };

  const handleSuccess = (res) => {
    console.log(res, "rrr");
    let resdata = res.data;
    localStorage.setItem("login", true);
    // localStorage.setItem("fullname", resdata.fname + " " + resdata.lname);
    dispatch(fullNameAction(resdata.fname + " " + resdata.lname))
    dispatch(usernameAction(resdata.username))
    dispatch(getImage(res.assets_url+resdata.image))
    dispatch(getUid(resdata.uid))
    dispatch(getEmail(resdata.email))

    // localStorage.setItem("username", resdata.username);
    dispatch(openSnackbar({ message: 'Login successful!', severity: 'success' }));

    localStorage.setItem("token", resdata.token);
    navigate("/");
    
  };
  // 160259177548-tgrp8mlenotmp1akbl7mrcrtc99pgv5j.apps.googleusercontent.com
  const handleFailure = (res) => {
    let message= res.data.message;
    console.log(message,'checkFailed')
    if (res.status === 404) {
      // setError("User not found!");
      dispatch(openSnackbar({ message: 'User not found!', severity: 'error' }));

    } else {
      // setError("Something went wrong, try again later or check email or password");
      dispatch(openSnackbar({message:message, severity: 'error'}));
    }
  };

  const handleChange = (checked) => {
    setChecked(checked);
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };


  <GoogleLogin
  onSuccess={credentialResponse => {
  console.log(credentialResponse);
   }}
  onError={() => {
  console.log('Login Failed');
  }}
/>

  return (
    <div className="login-page">
      <div className="login-left-box">
        <img className="logo-img" src="./assets/img/jumboLogo.png" alt="" />

        <div className="login-form-section">
          <h3 className="login-welcome-text">Welcome Back</h3>
          <p className="login-account-text">Login into your account</p>
          <div className="login-options-box">
          {/* <GoogleOAuthProvider
           clientId='160259177548-tgrp8mlenotmp1akbl7mrcrtc99pgv5j.apps.googleusercontent.com'
          > */}

           <Button
              btnclasses="login-btn"
              icon="./assets/img/googleImg.png"
              btnText="Google"
              iconBtnclass
              onClick={handleGoogleLogin}
            /> 
            </div>
              {/* <GoogleLogin
                    onSuccess={credentialResponse => {
                    console.log(credentialResponse.credential,'hello');
                    
                }}
                    onError={() => {
                    console.log('Login Failed');
                }}/>
          </GoogleOAuthProvider>  */}

          
          


            {/* <Button
              btnclasses="login-btn"
              icon="./assets/img/fbImg.png"
              btnText="Facebook"
              iconBtnclass
            /> */}
           {/* </div> */}

          <p className="line-para">
            <span className="line"></span>
            <span>or continue with</span>
            <span className="line"></span>
          </p> 

          <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="single-input-box">
              <Controller
                control={control}
                name="email"
                defaultValue=""
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="email"
                    inputClass="login-form-input"
                    name="email"
                    inpPlace="Enter your email"
                    onKeyUp={onChange}
                  />
                )}
              />
              {errors.email && <span>{errors.email.message}</span>}
            </div>

            <div className="single-input-box login-form-password">
              <Controller
                control={control}
                name="password"
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType={showPassword ? "text" : "password"}
                    inputClass="login-form-input "
                    name="password"
                    inpPlace="Enter your password"
                    onKeyUp={onChange}
                  />
                )}
              />
              <FontComp
                icon={showPassword ? faEye : faEyeSlash}
                onClick={toggleShowPassword}
                size="lg"
                color="#B8B8B8"
              />
              {errors.password && <span>{errors.password.message}</span>}
            </div>
            <div className="remember-or-forgot-box">
              <div className="switch-container">
                <label>
                  <SwitchExample
                    swicthClass="switch-class"
                    onChange={handleChange}
                    checked={checked}
                    labelText="Remember me"
                  />
                </label>
              </div>
              <p onClick={()=> navigate('/forgotPassword')} style={{cursor:'pointer'}}>Forgot Password</p>
            </div>
            {showerror !== null ? <p className="error-form-msg">{showerror}</p> : null}
            <APIHit
              handleSubmit={handleSubmit(onSubmit)}
              url={apiMainUser + "login"}
              apidata={logindata}
              successResponseCode={201}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              precondition={preCondition}
              method="post"
              auth={true}
            >
              {/* <Button type="submit">Submit</button> */}
              <Button btnclasses="tab-btn" type="submit" btnText="Log In" />
            </APIHit>
          </form>

          <p className="have-account-text">
            Dont have an Account?{" "}
            <Link to="/signup">
              <span>Sign up</span>
            </Link>
          </p>
<br />

          {/* <p className="have-accunt-text">
          
            <Link to="/winner">
              <span>winner page</span>
            </Link>
          </p> */}

          
        </div>
      </div>

      <img src="./assets/img/LoginImg.png" className="login-page-img" alt="" />
    </div>
  );
};

export default LoginPage;



