import { createSlice} from "@reduxjs/toolkit";

const initialState = {};
// Create a slice using createSlice
const paramsDataSlice = createSlice({
  name: "paramsData",
  initialState,
  reducers: {
    setFilterParams: (state, action) => {
        console.log('setFilterParams action dispatched with payload:')
        return { ...state, ...action.payload };
      },
     },
});



export const {
setFilterParams
} = paramsDataSlice.actions;
export const paramsDataReducer = paramsDataSlice.reducer;
