import React from "react";
import HeadingComp from "../HeadingComp";
// import { Controller } from 'react-hook-form';
import { Controller, useForm } from "react-hook-form";
import InputComp from "../../../../Components/common/textInput";
import Button from "../../../../Components/common/Button";
import { useState } from "react";
import EditSaveBtn from "../EditSaveBtn";
import APIHit from "../../../../utils/apiHit";
import { apiMainUser, checkErrors } from "../../../../utils/constant";
import { openSnackbar } from "../../../../utils/redux/SnackBarSlicer";
import { useDispatch } from "react-redux";

const EditMinStay = (props) => {
  
  
  const { control, handleSubmit, register,formState: { errors }, } = useForm();
  const [showerror, setError] = useState(null);

  const [postData, setPostData] = useState(null);

  const dispatch = useDispatch()


  let url = apiMainUser + "updatePropertyPref/" + props.propId;

      const onSubmit = (data) => {
        console.log(data,'uu')
        const postDatavalue = {
          min_stay: data.min_stay,
    
        };
        setPostData(postDatavalue);
       
      };


  

      const handleSuccess = (res) => {
        if (res.status === "success") {
          props.closeModal()
          props.fetchData()
          dispatch(openSnackbar({ message: 'updated Successfully', severity: 'success' }));

        }
      };
    
      const handleFailure = (res) => {
        console.log(res);
        if (res && res.status === 404) {
          dispatch(openSnackbar({ message: 'Something went Wrong', severity: 'error' }));

          setError("");
        } else {
          dispatch(openSnackbar({ message: 'Something went Wrong', severity: 'error' }));

          setError("");
        }
      };
    
      const preCondition = () => {
        let ercheck = checkErrors(errors);
        console.log(ercheck, "djjd");
        return ercheck;
      };


      
return (
  <>
    <div className="edit-booking-preference" style={{marginTop:'2rem'}}>
    <form className="edit-booking-pre-form" onSubmit={handleSubmit(onSubmit)}>
    <div className="avail-window-box ">
      
      
      <Controller
          control={control}
          defaultValue={
              props.allData !== undefined ? props.allData?.min_stay: ""
             }
          name="min_stay"
          rules={{
            required: "filled the data is required",
            min: {
              value: 0,
              message: "Maximum stay cannot be less than 0",
            },
          }}
       
          render={({ field: { onChange, value } }) => (
            <div>
            <InputComp
             defaultValue={
              props.allData !== undefined ? props.allData?.min_stay: ""
             }
             inputType="number"
               inputClass="input"
               name="min_stay"
              
              onKeyUp={(event) => {
                onChange(event.target.value);
                // console.log(event.target.value, "topi");
                // onSubmit(event.target.value, true);
              }}
              value={value}
            />
                 {errors.min_stay && (
            <span>{errors.min_stay.message}</span>
          )}
          <p>Enter your minimum stay in number of nights</p>
            </div>
          )}
          
        />
      </div>

      <APIHit
              handleSubmit={handleSubmit(onSubmit)}
              url={url}
              apidata={postData}
              method={"patch" }
              successResponseCode={200}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              precondition={preCondition} 
              auth={true}
            >
             

          <div className="clear_apply-btn-box paddingCustomYSide">
          <p onClick={() => props.closeModal(false)} className="clear-btn">
            Cancel
          </p>
          <button className="save-edit-btn">Save</button>
        </div>
            </APIHit>
    </form>
    </div>
  </>
)
};

export default EditMinStay;
