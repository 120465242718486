import { createSlice, configureStore } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  perNightCost: 0,
  additionalGuests: 0,
  additionalBeds: null,
  extraGuest: 0,
  weekendPrice:0,
};

const pricingCartSlice = createSlice({
  name: "pricingCartData",
  initialState,
  reducers: {
    updateAdditionalGuests: (state, action) => {
      state.additionalGuests = action.payload;
    },
    updateAdditionalBeds: (state, action) => {
      state.additionalBeds = action.payload;
    },

    updatePerNightCost: (state, action) => {
      
      state.perNightCost = action.payload;
    },
    updateWeekendPrice:(state, action)=>{
      state.weekendPrice = action.payload;
    },
    updateBaseGuest: (state, action) => {
      state.extraGuest = action.payload;
     
    },
   
  },
});

export const {
  updateAdditionalGuests,
  updateAdditionalBeds,
  updatePerNightCost,
  updateBaseGuest,
  updateWeekendPrice,
  setGetPricingData,
  setPricingCartId,
} = pricingCartSlice.actions;

export const PricingDataReducer = pricingCartSlice.reducer;
