import axios from 'axios';
import React from 'react'
import { apiMainUser, apiPublicUser } from '../../utils/constant';
import DateRangeDisplay from '../../Components/common/DateRangeDisplay';
import { Link, useNavigate } from 'react-router-dom';
import TripCardComp from '../../Components/common/TripCardComp';
import { useEffect } from 'react';
import { useState } from 'react';
import TrendingProperty from '../../Components/UserComponents/TrendingProperty';
import NoDataLottie from '../../Components/common/NoDataLottie';
import Button from '../../Components/common/Button';

const WishlistPage = () => {

    const [wishListData, setWishData] = useState([])
    const navigate = useNavigate()
    

    const fetchwishData = async () => {
        try {
          const response = await axios.get(apiPublicUser + 'search_property', {
            params: {
              wishlist: 1, // Replace YOUR_PROPERTY_ID with the actual property ID
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });

          console.log(response.data.data,'faadu')

          setWishData(response.data.data)
          // Further operations with response data
        } catch (error) {
          console.error(error);
        }
      };
      
console.log(wishListData,'dataAllCheck')

      useEffect(()=>{
        fetchwishData()
      },[])

      const navigateToProperties =()=>{
       navigate('/properties')
      }



      const [isVisible, setIsVisible] = useState('');



      const handleUniqueID = (uniqueID) => {
        // setModifiedId(uniqueID)
      
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              console.log(entry, 'checkInter');
      
              if (entry.isIntersecting) {
                console.log(entry.isIntersecting, 'checkInter');
                // setIsVisible(true);
                setIsVisible(entry.target.id === uniqueID ? uniqueID : '');
                // setModifiedId(entry.target.id === uniqueID ? uniqueID : '')
              } 
            });
          },
          { threshold: 0.4 }
        );
      
        const targetElement = document.getElementById(uniqueID);
      
            if (targetElement) {
              observer.observe(targetElement);
            }
      
          // Clean up observer
            return () => {
              if (targetElement) {
                observer.unobserve(targetElement);
              }
            };
      
          };
      
      
      
    
    
  return (
    <div className='wishlist-page'>
       <h2>Wishlist Properties</h2>

       {wishListData.length === 0 && (
        <>
      <NoDataLottie jsonLotty='./assets/lottie/tripnotFound.json' height="200px" width="300px"/>
      <div className='flex justify-content-center'>
      <Button 
       btnText='View Properties'
       btnclasses ='redirectLinks'
       onClick={navigateToProperties}
      />
      </div>
  
        </>
       )}
       
       {wishListData && wishListData.map((wish,index) => {
        console.log(wish,'allWish'); // Log wish object here
    return (
        <div className='wishlist-property-div' key={wish.uid}>
            {/* Use Link to create a navigation link */}
            {/* <Link style={{width:'100%',color:'#222'}} to={`/trips/${wish.booking_order_id}`}> */}
            <TrendingProperty
              index={index}
              isVisible={isVisible}
              // id={uniqueID}
              handleUniqueID={handleUniqueID}
              property_name={wish.property_name}
              img={wish.base_url_image + wish.images?.[0]}
              swiperSlides={wish.images.map((imgUrl, index) => ({
                imgUrl: wish.base_url_image + imgUrl,
                altText: `Slide ${index + 1}`,
              }))}
              priceValue={wish.base_price}
              location={wish.city + ", " + wish.state}
              maxguest={wish.max_guests}
              rooms={wish.bedrooms}
              beds={wish.beds}
              bath={wish.bathrooms}
              amenities={wish.amenities !== null ? wish.amenities_details : []}
              slug={"/properties/" + wish.city + "/" + wish.slug}
              // reviewNum={data?.review_count}
              // starNum={data?.Overall_rating}
              // reviewNum='4'
                // starNum='5'
               WishListpropID={wish.propertyid}
               wishListApplied={wish.wishlist}
               fetchwishData={fetchwishData}
        />
            {/* </Link> */}
        </div>
    );
})}

      
    </div>
  )
}

export default WishlistPage;
