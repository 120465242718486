import React from "react";
// import { forwardRef } from "react";

const InputComp = (props) => {
  return (
    <>
      <input
        type={props.inputType}
        className={props.inputClass}
        name={props.name}
        defaultValue={props.defaultValue ? props.value  : ''}
        id={props.id}
        min={props.inputType === 'number' ? props.min || 0 : undefined}
        // key
        placeholder={props.inpPlace}
        onChange={props.onKeyUp !== undefined ? props.onKeyUp : null}
        onBlur={props.onBlur !== undefined ? props.onBlur : null}
        ref={props.ref}
        disabled={props.disabled ? props.disabled : null}
      />
    </>
  );
};

export default InputComp;
