import React from 'react';
import moment from 'moment';

const DateRangeDisplay = ({ startDate, endDate,className,onClick }) => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  const sameYear = startMoment.year() === endMoment.year();
  const sameMonth = startMoment.month() === endMoment.month();

  let displayText;

  if (sameYear && sameMonth) {
    displayText = `${startMoment._isValid ? startMoment.format('D') : ''} - ${endMoment._isValid ? endMoment.format('D MMM') : ''}`;
  } else if (sameYear) {
    displayText = `${startMoment._isValid ? startMoment.format('D MMM') : ''} - ${endMoment._isValid ? endMoment.format('D MMM') : ''}`;
  } else {
    displayText = `${startMoment._isValid ? startMoment.format('D MMM') : ''} - ${endMoment._isValid ? endMoment.format('D MMM YYYY') : ''}`;
  }

  return <span onClick={onClick} className={className}>{displayText}</span>;
};

export default DateRangeDisplay;
