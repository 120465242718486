// ImageGalleryPopup.jsx

import React from 'react';
import Masonry from 'react-responsive-masonry';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, faXmark } from "@fortawesome/free-solid-svg-icons";
// import './ImageGalleryPopup.scss'; // Import the SCSS file for styling

const ImageGalleryPopup = ({ images, base_urlImg, initialIndex, onClose,onImageClick }) => {
  return (
    <div className="popup-second-layer">
      
      <div className="scrollable-popup">
        <div className='popup-close-secod-layer-parent'>
        <span className="popup-close-secod-layer" onClick={onClose}>
        <FontAwesomeIcon icon={faXmark}/>
      </span>
      </div>
        <Masonry columnsCount={2} gutter="10px" style={{width:"95%",margin:"auto"}}>
        {images.map((imageSrc, index) => (
            <div className='second-layer-img-box'>
          <img
            key={index}
            src={base_urlImg + imageSrc}
            alt=""
            className={index === initialIndex ? 'active' : ''}
            // onClick={() => onClose(index)} // Pass the index back to the parent onClose function
            onClick={() => onImageClick(index)}
            />
     </div>
        ))}
        </Masonry>
        
      </div>
    </div>
  );
};

export default ImageGalleryPopup;
