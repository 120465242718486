import React from 'react'
import { useState } from 'react';
import { apiMainUser, fetchDataFromApiFunc } from '../../../../utils/constant';
import { useEffect } from 'react';
import CardWrapper from '../../../../Components/common/cardWrapper';
import ProfileImage from '../../../../Components/common/profileImg';
import axios from 'axios';
import HeadingComp from '../HeadingComp';
import Button from '../../../../Components/common/Button';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../../utils/redux/SnackBarSlicer';

const EditPropertyType = (props) => {
    
  const [successdata, setSuccessData] = useState([]);

  const [selectedUid, setSelectedUid] = useState(null);
  const dispatch = useDispatch()
  
  useEffect(()=>{
   setSelectedUid(props?.allData?.property_type)
  },[props?.allData?.property_type])

  const isSmallScreen = window.innerWidth <= 500;

useEffect(() => {
    const fetchPropertyData = async () => {
        // calinng fetchDataFromApiFunc from constant
      const data = await fetchDataFromApiFunc(apiMainUser + "read_property_type?block=0");
      if (data) {
        setSuccessData(data);
      }
    };
  
    fetchPropertyData();
  }, []);




  const getData = (uid)=>{
    setSelectedUid(uid);
  }


  
  const handleSave = async () => {
    try {

      const response = await axios.post(
        apiMainUser + "createProperty_metadata_bulk",
        {
          property_id: props.propId,
          meta: [
            {
                metadata_key: "property_type",
                metadata_value: selectedUid,
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      if (response.data.status === "success") {
        props.fetchData();
        dispatch(openSnackbar({ message: 'Property type updated succesfully!', severity: 'success' }));

        props.closeModal()
        
   
        // Handle success response
      } else {
     
        dispatch(openSnackbar({ message: 'Something went wrong!', severity: 'success' }));

      }
    } catch (error) {
      
      dispatch(openSnackbar({ message: 'Something went wrong!', severity: 'success' }));

    }
  };

  console.log(selectedUid,'heheDone')
  return (
    <>
   
  <div className='paddingCustom2AllSide '>
   
    <div  className={`edit-property-type  ${isSmallScreen ? 'edit-amenities-box mt-30' : ''}`}>
      {successdata?.map((item) => {
            //   console.log(item.uid, "heheDone");

              return (
                <CardWrapper
                  key={item.uid}
                  onClick={() => getData(item.uid)}
                  wrapperClass={`create-list-property-type-card${
                    selectedUid == item.uid
                      ? " selected"
                      : ""
                  }`}
                >
                  {/* <FontComp icon={faBuilding} size="lg" color="#000" /> */}
                  <ProfileImage
                    profileImgParent="my-icon-class"
                    src={item.icon_url}
                    className="dndn"
                  />
                  <p>{item.name}</p>
                </CardWrapper>
              );
            })}

</div>
    </div>
    <div style={{marginTop:'0rem'}} className="clear_apply-btn-box pb-2  fixed-bottom-btn">
        <p onClick={() => props.closeModal(false)} className="clear-btn">
          Cancel
        </p>
        <Button
          btnText="Save"
          btnclasses="save-edit-btn"
          onClick={handleSave}
        />
    </div>
    </>
  )
}

export default EditPropertyType
