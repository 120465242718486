
import React, { useState } from 'react';
import CardWrapper from '../common/cardWrapper';
import CancellationPolicyViwPop from './CancellationPolicyViwPop';
import WrapperPopup from '../common/wrapperPopup';


const CancellationPolicyCard = ({ cancellationData }) => {

     const [cancelPolicyPop,setCancelPolicyPop] = useState(false)

 
  const toggleVisibleDocks = () => {
    setCancelPolicyPop(false)
    };

  return (
    <>
    <CardWrapper
      wrapperHeadingClass='rules-heading-text'
      wrapperClass='rules-card-wrapper w-100'
      wrapperHeading='Cancellation Policy'
    >
      <div className='all-cancelation-category-inputs'>
        <table className='your-table-class w-100'>
          <tbody>
            {cancellationData?.map((option, rowIndex) => (
              <React.Fragment key={option.id}>
                <tr className='flex flex-flow-column' style={{marginBottom:'0.7rem'}}>
                  {option.cancellation_slabs_details?.slice(0, 2).map((slab, slabIndex) => (
                    <td
                      key={slab.uid}
                      className='your-td-class'
                      style={{
                        border: '1px solid #ddd',
                        padding: '8px',
                        textAlign: 'left',
                        fontSize:'13px',
                        
                        borderBottom: rowIndex === cancellationData.length - 1 ? '1px solid #ddd' : 'none',
                      }}
                    >
                      {slabIndex === 0 ? (
                        slab.refund_percent === 100 ? (
                          `Full refund up to ${slab.remaining_days} days prior to check-in`
                        ) : (
                       <p> <span className='bold'>{slab.remaining_days} days</span> prior to check-in - <span className='bold'>{slab.refund_percent}%</span> refund of amount </p>
                        )
                      ) : (
                        <p> <span className='bold'>{slab.remaining_days} days</span> prior to check-in - <span className='bold'>{slab.refund_percent}%</span> refund of amount </p>

                      )}
              



                    </td>
                  ))}
                </tr>
                {option.cancellation_slabs_details && option.cancellation_slabs_details.length > 2 && (
                  <tr>
                    <td colSpan={2} className='your-td-class'>
                      <p
                    className='view-more-button' 
                    onClick={() => setCancelPolicyPop(true)}
                      >
                        View More
                      </p>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </CardWrapper>

{cancelPolicyPop ? 

    <div className="view-more-cancel-policy-popup">
        {cancelPolicyPop && (
        <WrapperPopup
        fluid={true}
        position='bottom'
        defaultSize={"0.85"}
        dimStyle={false}
        // DockFilter={true}
        headingText='Cancellation Policy'
        isVisible={true} 
        setVisibleDocks={toggleVisibleDocks} 
          closeModal={setCancelPolicyPop}
          comp={
        <CancellationPolicyViwPop 
        closeModal={setCancelPolicyPop}
        cancellationData={cancellationData} 
        />
          }
        />
      )}

      </div>
      :''}
      </>
  );
};

export default CancellationPolicyCard;
