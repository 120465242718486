import React from 'react'
import { useForm } from 'react-hook-form';
import Button from '../../../Components/common/Button';
import axios from 'axios';
import { apiMainUser } from '../../../utils/constant';
import { openSnackbar } from '../../../utils/redux/SnackBarSlicer';
import { useDispatch } from 'react-redux';

const HostWorkPop = (props) => {
  const { handleSubmit, register } = useForm();


  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('my_work', data.message);
    

    try {
      const response = await axios({
        method:'patch',
        url: apiMainUser + 'update_user',
      
        data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        
      }
      
      );
      if(response.data.status === 'success'){
        props.fetchData()
        props.closeModal()
        dispatch(openSnackbar({ message: 'Work Disc updated succesfully!', severity: 'success' }));

       
      }
    } catch (error) {
      console.error(error);
      dispatch(openSnackbar({ message: 'Something went wrong!', severity: 'error' }));

    }
  };

  return (
    <form className='my-dob-form' onSubmit={handleSubmit(onSubmit)} >
      <div className='dob-form-input '>
        <label>What is ur Profession?</label>
        <textarea
        className='pop-text-area'
        {...register('message')} 
        defaultValue={props.usersData?.my_work || ''} 
        
        cols="20" rows="4"></textarea>
      </div>

      <div className="clear_apply-btn-box paddingCustomAllSide">
      
      <p onClick={() => props.closeModal(false)} className="clear-btn">
         Cancel
       </p>
      <button className="save-edit-btn" type="apply-btn">
         Save
       </button>

  
     </div>
      {/* <Button btnclasses='save-edit-btn' btnText='save'/> */}
      
    </form>
  );
}

export default HostWorkPop
