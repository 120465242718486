

import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiMainUser } from "../../../utils/constant";
import Button from "../../../Components/common/Button";

const ListingAmenitiesCheckPopup = ({ onSelectedAmenities,onClose,initialSelectedAmenities,  }) => {
  const [amenities, setAmenities] = useState([]);
  
  const [selectedAmenities, setSelectedAmenities] = useState(initialSelectedAmenities);


  const fetchData = async () => {
    try {
      const response = await axios.get(apiMainUser + "read_amenity", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setAmenities(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const amenity = amenities.find((item) => item.amenity_name === name);
    if (checked) {
      setSelectedAmenities((prevState) => [...prevState, amenity.uid]);
    } else {
      setSelectedAmenities((prevState) =>
        prevState.filter((item) => item !== amenity.uid)
      );
    }
  };

   useEffect(()=>{
     onSelectedAmenities(selectedAmenities);
   },[selectedAmenities])

  console.log(selectedAmenities,'hh')


  return (
    <div className="listing-amenities-check-popup-parent">
          <div className="listing-amenities-check-popup">
        {amenities.map((amenity) => (
          <div style={{ margin: "1rem 1rem 1rem 0rem" }} key={amenity.uid}>
            <label className="checkbox-container">
              <input
                type="checkbox"
                name={amenity.amenity_name}
                checked={selectedAmenities.includes(amenity.uid)}
                onChange={handleCheckboxChange}
              />
              {amenity.amenity_name}
            </label>
          </div>
        ))}
      </div>
      {/* <Button btnclasses="save-edit-btn" btnText="Apply" onClick={handleSaveClick} /> */}
    </div>
  );
};

export default ListingAmenitiesCheckPopup;
