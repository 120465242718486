import React, { useEffect } from "react";
import Button from "../../../Components/common/Button";
import { Controller, useForm } from "react-hook-form";
import InputComp from "../../../Components/common/textInput";
import FontComp from "../../../Components/common/fontComp";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { apiMainUser, apiPublicUser } from "../../../utils/constant";
import axios from "axios";

const ForgotPassPage = () => {

const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    
    formState: { errors },
  } = useForm();
 
  const location = useLocation();


 
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const myToken = queryParams.get("token");
    console.log(myToken);
    if (myToken) {
      axios({
        method: "post",
        url: apiPublicUser + "verify_token",
        data: {
          token: myToken,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "success") {
            navigate("/newPassPage", { state: { token: myToken } });
          } else {
            // Handle other response scenarios if needed
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [location.search, navigate]);

  

  const onSubmit = async (data) => {
        
    try {
      const response = await axios({
        method:'post',
        url: apiPublicUser + 'forgot_password',
        data: {
          email: data.email,
        
        },
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        
      }
      
      );
      console.log(response.data);
      if (response.data.status === 'success') {
      //  navigate('/newPassPage')
        
      } else {
        
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="login-page forgot-page">
      <div className="login-left-box">
        <img src="./assets/img/jumboLogo.png" alt="" />
        <div className="login-form-section">
          <h3 className="login-welcome-text">Forgot Password</h3>
          <p className="forgot-text">
            No need to Panic-Resetting Your Password is a Breeze
          </p>
          <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="single-input-box">
              <Controller
                control={control}
                name="email"
                defaultValue=""
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="email"
                    inputClass="login-form-input"
                    name="email"
                    inpPlace="Enter your email"
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              {errors.email && <span>{errors.email.message}</span>}
            </div>
            <Button btnclasses="tab-btn" type="submit" btnText="Send OTP" />
          </form>

          <p onClick={()=> navigate('/login')} style={{cursor:'pointer', color:'#19A6BB'}} className="back-to-login">
            <span >
           
            </span>
            <span className="back-to-login-text">Back to Login</span>
          </p>

          {/* <Link to="/login">
              <span>Back to Login</span>
           </Link> */}
        </div>
      </div>
      <img
        src="./assets/img/ForgotPassImg.png"
        className="login-page-img"
        alt=""
      />
    </div>
  );
};

export default ForgotPassPage;
