import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardWrapper from '../../../Components/common/cardWrapper';
import PropertyListing from '../../../Components/common/PropertyListing';
import { useNavigate } from 'react-router-dom';
import { apiMainUser } from '../../../utils/constant';
import axios from 'axios';
import moment from 'moment';

const ProcessedPayout = () => {

  const navigate = useNavigate()
  const data = [
    { srNo: 1, Date: 'May 29 2023', amount: '5000rs', accordionContent: 'Accordion Content 1' },
    { srNo: 2, Date: 'June 30 2023', amount: '3000rs', accordionContent: 'Accordion Content 2' },
    { srNo: 3, Date: 'April 30 2022', amount: '7000rs', accordionContent: 'Accordion Content 3' },
  ];

  const data2 = [
    { srNo: 1, propertyName: 'Lukman Villa',id:'1053',Date: 'June 30 2023', amount: '5000rs' },
    { srNo: 2, propertyName: 'Some Property',id:'1222', Date: 'July 29 2023', amount: '3000rs' },
    { srNo: 3, propertyName: 'Another Property',id:'1253', Date: 'Aug 28 2023', amount: '7000rs' },
  ];

  const [bookingsData, setBookingsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: 'Bearer ' + token,
        }
        
        const response = await axios.get(apiMainUser + 'read_payout_host', {
         
          headers,
        });
        setBookingsData(response.data.data);
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };

    fetchData();
  }, []); 

  console.log(bookingsData,'checkAllBookings')

  const formatDate = (dateString) => {
    return moment(dateString).format('D MMM YYYY');
  };
  




  const navigateToHostAccountPage =()=>{
    navigate('/hostAccount')
    }

    const navigateToHostPayoutparent =()=>{
      navigate('/hostPayout_transaction')
      }
  

  return (
    <div className='account-detail-page host-transaction-upcoming-payouts'>
      <p className='font-xs' style={{fontWeight: '500' }}><span onClick={navigateToHostAccountPage} className='pointer'>Account</span> > <span className='pointer' onClick={navigateToHostPayoutparent}>Payouts & Transactions </span> > Transactions</p>
      <h2 className='account-head-text'>Payouts & Transactions</h2>
      <h4 className='upcoming-text'>Processed Payouts</h4>

      <CardWrapper>
      {bookingsData.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography className='processed-payout-typography-div'>
              <span className='processed-payout-data'>{index + 1}</span>
              <span className='processed-payout-data'>{formatDate(item.createdon)}</span>
              <span className='processed-payout-data'>{item.amount}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <table className='upcoming-payout-table'>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Property Name</th>
                  <th>Booking Id</th>
                  <th>Booking Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {/* Use item.booking_details for specific booking details */}
                {item.booking_details.map((booking, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>
                      <PropertyListing
                        src='./assets/img/property.png'
                        className={booking.propertyImageClassName}
                        paraText={booking.property_name}
                      />
                    </td>
                    <td>{booking.booking_id}</td>
                    <td>{formatDate(booking.from_date)}</td>
                    <td>{booking.price_detail}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
      ))}
    </CardWrapper>
    </div>
  );
};

export default ProcessedPayout;
