
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import CardWrapper from "../../../Components/common/cardWrapper";
import Button from "../../../Components/common/Button";
import GuestProfile from "../../../Components/common/guestProfile";
import ButtonPopup from "../../../Components/common/ButtonPopup";
import ProfileButtons from "../../../Components/common/ProfileButtons";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const LeftTabsMenu = (props) => {

  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

    const storeSel = useSelector((state) => state);

  const fullNameData = storeSel.hostDetailSlice?.full_Name;
  const profileimage = storeSel.hostDetailSlice?.Image;


  useEffect(()=>{
    setActiveTab(location.pathname)
  },[location])

  return (
    <CardWrapper wrapperClass="reservation-tab-card">
      <div className="logo-box">
        <img src="./assets/img/jumboLogo.png" alt="" />
      </div>
      <div className="reservation-tab-box">
        <NavLink
          className={`left-tab-links ${
            activeTab === "/host" ? "left-tab-active" : ""
          }`}
          exact
          to="/host"        
        >
          <Button
            btnText="Reservation"
            btnclasses={`tab-btn ${
              activeTab === "/host" ? "tab-btn-active" : "tab-btn"
            }`}
            icon={
              activeTab === "/host"
                ? "./assets/img/whiteReservationImg.svg"
                : "./assets/img/blackReservationImg.svg"
            }
          />
        </NavLink>

        <NavLink
          className={`left-tab-links ${
            activeTab.includes("/host/listing") ? "left-tab-active" : ""
          }`}
          to="/host/listing"        
        >
          <Button
            btnText="Listings"
            iconBtnclass="btn-icon-hover"
            btnclasses={`tab-btn ${
              activeTab.includes("/host/listing") ? "tab-btn-active" : "tab-btn"
            }`}
            icon={
              activeTab.includes("/host/listing")
                ? "./assets/img/whiteListingImg.svg"
                : "./assets/img/blackListingImg.svg"
            }
          />
        </NavLink>
        
        <NavLink
          className={`left-tab-links ${
            activeTab.includes("/host/calendar") ? "left-tab-active" : ""
          }`}
          to="/host/calendar"        
        >
          <Button
            btnText="Calendar"
            iconBtnclass="btn-icon-hover"
            btnclasses={`tab-btn ${
              activeTab.includes("/host/calendar") ? "tab-btn-active" : "tab-btn"
            }`}
            icon={
              activeTab.includes("/host/calendar")
                ? "./assets/img/whiteCalenderImg.svg"
                : "./assets/img/blackCalendImg.svg"
            }
          />
        </NavLink>

        <NavLink
          className={`left-tab-links ${
            activeTab.includes("/host/insight") ? "left-tab-active" : ""
          }`}
          to="/host/insight"        
        >
          <Button
            btnText="Insights"
            btnclasses={`tab-btn ${
              activeTab.includes("/host/insight") ? "tab-btn-active" : "tab-btn"
            }`}
            icon={
              activeTab.includes("/host/insight")
                ? "./assets/img/whiteInsightImg.svg"
                : "./assets/img/blackInsightImg.svg"
            }
          />
        </NavLink>

        
        <NavLink
          className={`left-tab-links ${
            activeTab.includes("/host/message") ? "left-tab-active" : ""
          }`}
          to="/host/message"        
        >
          <Button
            btnText="Messages"
            btnclasses={`tab-btn ${
              activeTab.includes("/host/message") ? "tab-btn-active" : "tab-btn"
            }`}
            icon={
              activeTab.includes("/host/message")
                ? "./assets/img/whiteMessageImg.svg"
                : "./assets/img/Message.svg"
            }
          />


        </NavLink>

      
        {/* <Button
          btnText="Help"
          iconBtnclass="btn-icon-hover"
          btnclasses="tab-btn"
          icon="./assets/img/Help.svg"
        /> */}


        

        <Button
          btnText="Notification"
          iconBtnclass="btn-icon-hover"
          btnclasses="tab-btn"
          icon="./assets/img/Notification.svg"
        />
        
      </div>
      <ButtonPopup
          wrapclass="wc-max"
          makeCustom={
            <GuestProfile
              profileName={fullNameData}
              // whoseProfile="Host Profile"
              profileImage={profileimage}
            />
          }
          divContent={
            <ProfileButtons/>
          }          
          icon="./assets/img/locationImg.svg"                      
          popOnTop={true}
        />
    </CardWrapper>
  );
};

export default LeftTabsMenu;
