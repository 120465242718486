import React from 'react'
import { useNavigate } from 'react-router-dom';

const StartCreateListingPage = () => {

    const navigate = useNavigate()

    const navigateTo = () => {
      navigate('/host/createlisting/step-1')

      };
    
  return (
    <div className='start-create-listing-page '>
      <h2>Embrace the ease of starting on Jumbo</h2>
    <div className='start-create-listing-page-flex-box'>

    <div className='start-listing-page-child-flex-box'>
     <div className='start-listing-page-child-para-box'>
     <p className='flex first-child'><span>1.</span><span>Unveil the beauty of your space</span></p>
     <p className='second-child'>Give us the essentials: location and guest capacity.</p>
     </div>
     <div className='start-listing-page-child-img-box'>
        <img src="./assets/img/StartHomeImg.jpg" alt="" />
     </div>
    </div>

{/* ----------------------------------------------------------------------- */}
    <div className='start-listing-page-child-flex-box'>
     <div className='start-listing-page-child-para-box'>
     <p className='flex first-child'><span>2</span><span >Elevate your presence and make it stand out</span></p>
     <p className='second-child'>Elevate your listing's appeal with 5+ stunning photos, a captivating title, and an enticing description - with our guidance.</p>
     </div>
     <div className='start-listing-page-child-img-box'>
        <img src="./assets/img/StartHomeImg2.jpg" alt="" />
     </div>
     
    </div>
    {/*  -----------------------------------------------------------*/}
    <div className='start-listing-page-child-flex-box'>
     <div className='start-listing-page-child-para-box'>
     <p className='flex first-child'><span>3.</span> <span>Prepare for grand reveal - finish up and publish</span></p>
     <p className='second-child'>Set your price, rules and launch your listing.</p>
     </div>
     <div className='start-listing-page-child-img-box'>
        <img src="./assets/img/StartHomeImg3.jpg" alt="" />
     </div>
     
    </div>
    </div>

    <div className="bottom-btns-fixed-box">
          
          <button
            onClick={navigateTo}
            type="submit"
            className="common-next-btn"
          >
            Next
          </button>
        </div>
    </div>
  )
}

export default StartCreateListingPage
