import React from "react";

import { useNavigate } from "react-router-dom";

import CardWrapper from "../../../Components/common/cardWrapper";
import AddToCart from "../../../Components/common/addCarts";
import {
  updateBathrooms,
  updateBedrooms,
  updateBeds,
  updateGuests,
} from "../../../utils/redux/CounterSlice";
import Save_ExitComp from "../../../Components/common/save_ExitComp";
import { setCartCommonIndex } from "../../../utils/redux/commonIndex";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const CounterBeds_Guest = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [api, setApi] = useState(["createProperty_metadata_bulk"]);
  const [method, setMethod] = useState("");
  // const storeSel = useSelector((state) => state);
  const storeSel = useSelector((state) => state);
  const initialGuests = storeSel.addCartSlice.guests || 2;
  const initialBedrooms = storeSel.addCartSlice.bedrooms || 1;
  const initialBeds = storeSel.addCartSlice.beds || 1;
  const initialBathrooms = storeSel.addCartSlice.bathrooms || 1;

  const PropIddata = storeSel.apiDataSlice?.propId;

  const Currenturl = window.location.href;
  console.log(Currenturl, "tyt");
  const CurrentPage = new URL(Currenturl);
  const path = CurrentPage.pathname;
  console.log(path, "qqq");

  useEffect(() => {
    const PropIddata = storeSel.apiDataSlice?.propId;
    const preferenceIddata = storeSel.apiDataSlice?.preferenceId;
    let updatedApi = ["updateProperty/" + PropIddata, ...api];

    if (preferenceIddata) {
      updatedApi = [...updatedApi, "updatePropertyPref/" + preferenceIddata];
      setMethod("patch");
    } else {
      updatedApi = [...updatedApi, "add_preference"];
      setMethod("post");
    }

    setApi(updatedApi);
  }, []);
  console.log(api, "tres");

  const handleUpdateGuests = (count) => {
    dispatch(updateGuests(count));
    console.log("hurrat");

    // dispatch(updateGuests(count));
  };

  const handleUpdateBedrooms = (count) => {
    dispatch(updateBedrooms(count));
  };
  const handleUpdateBeds = (count) => {
    // dispatch(updateBeds(count));
    dispatch(updateBeds(count));
  };
  const handleUpdateBathrooms = (count) => {
    dispatch(updateBathrooms(count));
    // dispatch(updateBathrooms(count));
  };
  console.log(api, "trr");

  const navigateTo = () => {
    // navigate("/host/createlisting/listingAmenities");
    navigate('/host/createlisting/step-2');

  };

  return (
    <div className="counter-beds-guest google-address">
      <div className="create-list-address-type-content create-listing-address-section">
        <h2>Share some basic about your place</h2>
        <div className="google-adress-flex-box">
          <CardWrapper wrapperClass="counter-beds-guest-card">
            <div className="counter-beds-guest-card-textbox">
              <p className="head-text">Maximum Guests</p>
              {props.haspara && <p>Maximum number of guests allowed</p>}
            </div>

            <AddToCart
              cartName="cartName"
              cartBtn="cartBtn"
              cartCount="cartCount"
              cartParent="cartParent"
              increamentVal={handleUpdateGuests}
              initialCount={initialGuests}
            />
          </CardWrapper>
          <CardWrapper wrapperClass="counter-beds-guest-card">
            <div className="counter-beds-guest-card-textbox">
              <p className="head-text">Bedrooms</p>
              {props.haspara && <p>Maximum number of bedrooms allowed</p>}
            </div>

            <AddToCart
              cartName="cartName"
              cartBtn="cartBtn"
              cartCount="cartCount"
              cartParent="cartParent"
              initialCount={initialBedrooms}
              increamentVal={handleUpdateBedrooms}
            />
          </CardWrapper>
          <CardWrapper wrapperClass="counter-beds-guest-card">
            <div className="counter-beds-guest-card-textbox">
              <p className="head-text">Beds</p>
              {props.haspara && <p>Maximum number of Beds allowed</p>}
            </div>

            <AddToCart
              cartName="cartName"
              cartBtn="cartBtn"
              cartCount="cartCount"
              cartParent="cartParent"
              initialCount={initialBeds}
              increamentVal={handleUpdateBeds}
            />
          </CardWrapper>

          <CardWrapper wrapperClass="counter-beds-guest-card">
            <div className="counter-beds-guest-card-textbox">
              <p className="head-text">Bathrooms</p>
              {props.haspara && <p>Maximum number of Bathrooms allowed</p>}
            </div>

            <AddToCart
              cartName="cartName"
              cartBtn="cartBtn"
              cartCount="cartCount"
              cartParent="cartParent"
              initialCount={initialBathrooms}
              increamentVal={handleUpdateBathrooms}
            />
          </CardWrapper>
        </div>
      </div>
        <div className="bottom-btns-fixed-box">
        <Save_ExitComp path={path} method={method} apis={api} />
        <button onClick={navigateTo} type="submit" className="common-next-btn">
          Next
        </button>
      </div>
    </div>
  );
};

export default CounterBeds_Guest;
