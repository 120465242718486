

import React from 'react';

const CancellationPolicyViewPop = ({ cancellationData, }) => {

  const mtMbClasses = window.innerWidth < 500 ? 'mt-20' : '';
  return (
    <div className={`cancellation-policy-popup ${mtMbClasses}`}>

      <div className='cancellation-policy-popup-content'>
        <table className='popup-table w-100'>
          <tbody>
            {cancellationData?.map((option, optionIndex) => (
              <tr key={option.id} className='flex flex-flow-column w-100 mb-20' >
                {option.cancellation_slabs_details?.map((slab, slabIndex) => (
                  <td
                    key={slab.uid}
                    className='your-popup-td-class'
                    style={{
                      border: '1px solid #ddd',
                      padding: '9px',
                      textAlign: 'left',
                      fontSize: '14px',
                      borderBottom: optionIndex === cancellationData.length - 1 && slabIndex === option.cancellation_slabs_details.length - 1 ? '1px solid #ddd' : 'none',
                    }}
                  >
                    {slabIndex === 0 ? (
                      slab.refund_percent === 100 ? (
                        `Full refund up to ${slab.remaining_days} days prior to check-in`
                      ) : (
                        <p> <span className='bold'>{slab.remaining_days} days</span> prior to check-in - <span className='bold'>{slab.refund_percent}%</span> of amount will be refunded </p>

                      )
                    ) : (
                        <p> <span className='bold'>{slab.remaining_days} days</span> prior to check-in - <span className='bold'>{slab.refund_percent}%</span> of amount will be refunded </p>

                    )}
                  </td>
                ))}
                {/* Always display "No refund" message */}
                <td
                  className='your-popup-td-class'
                  style={{
                    border: '1px solid #ddd',
                    padding: '10px',
                    textAlign: 'left',
                    fontSize: '14px',
                    borderBottom: optionIndex === cancellationData.length - 1 ? '1px solid #ddd' : 'none',
                  }}
                >
                  No amount will be refunded once this days passed.
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
 };

export default CancellationPolicyViewPop;

