import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputComp from './textInput';
import { apiMainUser, checkErrors } from '../../utils/constant';
import axios from 'axios';
import { useState } from 'react';
import APIHit from '../../utils/apiHit';
import { openSnackbar } from '../../utils/redux/SnackBarSlicer';
import { useDispatch } from 'react-redux';

// ... (your imports and setup)

const CouponComp = (props) => {
    const { control, handleSubmit, formState: { errors } } = useForm();
    const [showerror, setError] = useState(null);

  const [postData, setPostData] = useState(null);

  const dispatch = useDispatch()




    const url = apiMainUser + 'apply_coupon'
  
    const onSubmit = (data) => {
      const reviewData = {
        cart_amount: props.totalAmount,
        transaction_id: props.transactionId,
        couponcode: data.coupon_code,
      };
      setPostData(reviewData)
  
    
    };

    const handleSuccess = (res) => {
        if (res.status === "success") {

          props.closeModal()
          props.updateTransaction()
          dispatch(openSnackbar({ message: 'Coupon added!', severity: 'success' }));



     
        }
      };
    
      const handleFailure = (res) => {
   
        if (res.data.status == 'failed') {
          dispatch(openSnackbar({ message: res.data.data, severity: 'error' }));
      
          setError("");
        } else {
          setError("");
        }
      };
    
      const preCondition = () => {
        let ercheck = checkErrors(errors);
        return ercheck;
      };


  
    return (
      <div className='coupon-code-popup-page'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='coupon-code-form'>
            {/* <label htmlFor='couponCode'>Enter Coupon Code</label> */}
            <Controller
              name='coupon_code'
              control={control}
              rules={{
                required: 'Coupon code is required',
                pattern: {
                  value: /^[A-Za-z0-9]+$/,
                  message: 'Only letters and numbers are allowed',
                },
              }}
        
            render={({ field: { onChange, value } }) => (
                <div>
                <InputComp
                   inputType='text'
                    inputClass='title-input'
                       name='coupon_code'
                    inpPlace='Enter your code here...'
                  onKeyUp={(event) => {
                    onChange(event.target.value);
                    
                  }}
                  value={value}
                />
                <br/>
                 {errors.coupon_code && (
                <span>{errors.coupon_code.message}</span>
              )}
            
                </div>
              )}
            />
          </div>
        
          
            <APIHit
              handleSubmit={handleSubmit(onSubmit)}
              url={url}
              apidata={postData}
              method="post"
              successResponseCode={200}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              precondition={preCondition} 
              auth={true}
            >
             

             <div className="clear_apply-btn-box paddingCustomYSide">
      
            <p onClick={() => props.closeModal(false)} className="clear-btn">
              Cancel
            </p>
            <button className="save-edit-btn" type="apply-btn">
              Save
            </button>   
            </div>
        
            </APIHit>

        </form>
      </div>
    );
  };
  
  export default CouponComp;
  