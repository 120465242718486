import React from "react";
import InputComp from "../../../Components/common/textInput";
import FontComp from "../../../Components/common/fontComp";
import { Controller, useForm } from "react-hook-form";

import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const GoogleAddress = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div className="google-address">
      <div className="create-list-address-type-content">
        <h2>Let us know where your placde is located</h2>
        <p>Help guest to find your property easily</p>

        <div className="google-adress-flex-box">
          <form
            style={{ width: "50%", position: "relative" }}
            className="google-address-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="single-input-box">
              <Controller
                control={control}
                name="address"
                defaultValue=""
                rules={{
                  required: "address is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="text"
                    inputClass="google-address-input"
                    inpPlace="Enter your address"
                    name="address"
                    //  onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              {errors.address && <span>{errors.address.message}</span>}
            </div>

            <FontComp
              icon={faLocationDot}
              // onClick={handleClick}
              fontclassname="my-icon-class"
              size="lg"
              color="#707980"
            />
          </form>

          <div>hdd</div>
        </div>
      </div>
      <button className="common-next-btn">Next</button>
    </div>
  );
};

export default GoogleAddress;
