import React from 'react';


const UserMessageComp = (props) => {
  return (
   
      <div className='user-message-card skewed-top user-message'>
        <div className="guest-message-content">
          <p className='message-guest-messge-text user-mesage-text'>{props.messageText}</p>
          <p className="time-right-align user-message-time">{props.messageTime}</p>
        </div>
   
    </div>
  );
};

export default UserMessageComp;
