import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import rootReducer from "./RootReducer";

import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";

const persistConfig = {
  key: "root",
  storage,
};



const persistedReducer = persistReducer(persistConfig, rootReducer);

const reduxStateSyncConfig = {};




const syncMiddleware = createStateSyncMiddleware(reduxStateSyncConfig)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [syncMiddleware], 
});

export const persistor = persistStore(store);

