import React from 'react'
import CardWrapper from '../cardWrapper'
import DateRangeDisplay from '../DateRangeDisplay'

const LeftMessgeCardWrappers = (props) => {
  return (
  <CardWrapper  onClick={props.onClick} wrapperClass="left-message-card pointer">
  <div className='left-message-card-img-box'>
  <img src={props.guestImg} alt='Guest' />
  </div>

  <div className='left-message-card-text-box'>
 <p className='left-message-card-guest-location-text'>
  <span className='bold font-xs ' style={{color:'#303030'}}>{props.guestName}</span> 
 {/* <span className='card-dot'>

 </span> */}

 </p>
 <p>
 <span className='left-card-property-name-text' >
    {props.propertyName}
 </span>,
 &nbsp;
 <span style={{color:'#303030',fontSize:'11px'}}>
 {props.location} 
 </span>
 &nbsp;
 &nbsp;
  {props.unReadMessage != 0  && 
   <span className='unread_message-text'>{props.unReadMessage}</span>
  }
 </p>

 {/* <p className='left-message-card-message-text'>
    {props.messageText}
 </p> */}

 <p className='left-message-card-guest-location-text'>
  {/* <span className={props.additionalClass} style={{fontSize:'11px'}}>{props.messageEnquirytext}</span> 
 <span className='card-dot'>

 </span> */}
 <span style={{color:'#303030',fontSize:'11px'}}>
 <DateRangeDisplay
        startDate={props.selectedCheckIn ? props.selectedCheckIn : ''}
        endDate={props.selectedCheckOut ? props.selectedCheckOut : ''}
      />
 </span>
 </p>
  </div>
  </CardWrapper>
  )
}

export default LeftMessgeCardWrappers
