import React, { useState } from 'react';
import ButtonPopup from '../../../Components/common/ButtonPopup';
import PropertyListing from '../../../Components/common/PropertyListing';
import WrapperPopup from '../../../Components/common/wrapperPopup';
import DatePicker from '../../../Components/UserComponents/datePicker';
import CalendarPriceInpPop from '../../../Module/Host/CalendarPriceInpPop';
import { apiMainUser, apiPublicUser, getJustBlockedDatesFunc } from '../../../utils/constant';
import axios, { all } from 'axios';
import { useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faGreaterThan, faLessThan, faSearch,faMessageCaptions } from '@fortawesome/free-solid-svg-icons';
import FontComp from '../../../Components/common/fontComp';
import { useNavigate } from 'react-router';
import { faBell, faCommentDots, faMessage } from '@fortawesome/free-regular-svg-icons';
import { useRef } from 'react';
import MobCalendarPage from './MobCalendarPage';
import FooterComponent from '../../common/FooterComponent';
import HostFooterComp from '../../common/HostFooterComp';

const CalendarPage = () => {
  

  const [publishData,setPublishData] = useState([])
  const [slotsData,setSlotsData] = useState([])
  const [singleSlotsData,setSingleSlotsData] = useState([])

  const [allBlockDateState, setAllBlockedDates] = useState();
  const [blockCheckinDateState, setCheckinBlockedDates] = useState();
  const [blockCheckoutDateState, setCheckoutBlockedDates] = useState();
  const [filterChanged, setFilterChanged] = useState(false);
  // const [blockMessage,setBlockMesage] = useState(null)

  const [searchInput, setSearchInput] = useState('');

  const [radionText,setRadioText]=useState('Available')

  const isSmallScreen = window.innerWidth <= 500;


  const [bookedDates, setBookedDates] = useState({
    propertyId: [],
    bookedDate: []
  });

  const [currentMonthStartDate, setCurrentMonthStartDate] = useState(null);
  const [currentMonthEndDate, setCurrentMonthEndDate] = useState(null);

  const [isPreviousMonthSlotsAvailable, setIsPreviousMonthSlotsAvailable] = useState(false);
 const [isNextMonthSlotsAvailable, setIsNextMonthSlotsAvailable] = useState(false);
  const [propertyUids, setPropertyUids] = useState([]);


// const [openModalDatePicker, setOpenModalDatePicker] = useState(false);
// const [openModalPriceInput, setOpenModalPriceInput] = useState(false);


const [minDateState, setMinDate] = useState()
const [maxDateState, setMaxDate] = useState()
const [blockDateState, setBlockedDates] = useState([])

  

  const showInputs = false;

  
  const fetchCalendarSlots = async () => {
  
    try {
      const response = await axios.get(apiMainUser + 'get_calender_slots_host', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        params: {
          fromDate: moment(currentMonthStartDate).format('YYYY-MM-DD'),
           toDate: moment(currentMonthEndDate).format('YYYY-MM-DD'),
         
        },
      });


      if (response.data.status === 'success') {
       
        setPublishData(response.data.data)
       
      } else {
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }

  
  };

  
  const [currentDate, setCurrentDate] = useState(new Date());

  let CurrentDateRendering = new Date()

  console.log(CurrentDateRendering,'checkDate')
  

  const currentDay = CurrentDateRendering.getDate();
  const currentMonthCheck = new Date().getMonth() + 1;

  
  const activeElement = document.querySelector('.calendar-active-date');
  // const activeElement = document.querySelector('.active');

  console.log(activeElement,'activeCheck')
  useEffect(() => {
    const currentDateDiv = document.querySelector('.current-date');
    console.log(currentDateDiv,'checkCurrent')
    // const currentDateDiv = document.querySelector('.date-day-parent');

  
    if (activeElement && currentDateDiv) {
      const currentDivOffset = currentDateDiv.getBoundingClientRect();
  
    
      // console.log(currentDivOffset.left,containerRect.left, 'currentDateDiv');
      const scrollOffset = currentDivOffset.left;

      console.log(currentDateDiv,currentDivOffset,'currentDateDiv')
  
    
      activeElement.scrollTo({
        left: scrollOffset,
        behavior: 'smooth',
      });
      
    }
  }, [activeElement]);
  



  useEffect(() => {
    if (currentMonthStartDate && currentMonthEndDate)   {
      fetchCalendarSlots();
    }
  }, [currentMonthStartDate, currentMonthEndDate]);

  console.log(currentMonthStartDate,currentMonthEndDate,'endDate')

  

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  
  const getCurrentYearRange = (numberOfYearsBack, numberOfYearsForward) => {
    const currentYearCalc = new Date().getFullYear();
    const years = [];
    for (let i = currentYearCalc - numberOfYearsBack; i <= currentYearCalc + numberOfYearsForward; i++) {
      years.push(i);
    }
    return years;
  };
  
  // Example usage:
  const years = getCurrentYearRange(3, 10);
  console.log(years);

  const [selectedMonth, setSelectedMonth] = useState('');
  const [saveSelectedMonthClass, setSaveSelectedMonthClass] = useState(new Date().getMonth());
  const [saveSelectedMonth, setSaveSelectedMonth] = useState();

  const [saveSelectedYear, setSaveSelectedYear] = useState(new Date().getFullYear());





  const [selectedYear, setSelectedYear] = useState('');
  const [selectedDayDateCellIndex, setSelectedDayDateCellIndex] = useState(-1);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = months[currentDate.getMonth()];
    const currentYear = currentDate.getFullYear();
    setSelectedMonth(currentMonth);
    setSelectedYear(currentYear);
  }, []);

  const handleMonthClick = (month,index) => {

    setSaveSelectedMonthClass(index)
    setSaveSelectedMonth(month)

    // setSelectedMonth(month);

  };

  // console.log(saveSelectedMonth,saveSelectedYear,'checkMonth')

  const handleYearClick = (year) => {
    setSaveSelectedYear(year)

    // setSelectedYear(year);
  };

 


  useEffect(() => {
    const currentMonthIndex = months.indexOf(selectedMonth);
    const newDate = new Date(currentDate.setFullYear(selectedYear, currentMonthIndex, 1));
    setCurrentDate(newDate);
  }, [selectedMonth, selectedYear]);

  const [currentIndex, setCurrentIndex] = useState(0);
  // const [currentDate, setCurrentDate] = useState(new Date());
  const [slides, setSlides] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(new Date().getDate() - 1 );
  const [selectedMonthyear, setSelectedMonthyear] = useState(new Date().toLocaleString('default', { month: 'long' }) + " " + new Date().getFullYear());

  


  useEffect(() => {
    const generateSlides = () => {
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const generatedSlides = [];

      // Generate the previous month
      const prevMonthDate = new Date(currentYear, currentMonth - 1, 1);
      generatedSlides.push(generateMonthSlide(prevMonthDate));

      // Generate the current month 
      const currentMonthDate = new Date(currentYear, currentMonth, 1);
      generatedSlides.push(generateMonthSlide(currentMonthDate));

      // Generate the next month
      const nextMonthDate = new Date(currentYear, currentMonth + 1, 1);
      generatedSlides.push(generateMonthSlide(nextMonthDate));

      setCurrentMonthStartDate(currentMonthDate);
      setCurrentMonthEndDate(new Date(currentYear, currentMonth + 1, 0));

      

      setSlides(generatedSlides);
    };



    const generateMonthSlide = (date) => {
      const year = date.getFullYear();
      const month = date.toLocaleString('default', { month: 'long' });
      const daysInMonth = new Date(year, date.getMonth() + 1, 0).getDate();
      const days = [];
      console.log(date,'check')

      for (let day = 1; day <= daysInMonth; day++) {
        const dayOfWeek = new Date(year, date.getMonth(), day).toLocaleDateString('default', { weekday: 'short' });
        days.push({ day: dayOfWeek, date: day,fullDate:date });
      }

      return { title: `${month} ${year}`, days: days };
    };
   
    

    generateSlides();
    // fetchDataProperty();

  }, [currentDate]);

  

  const prevSlide = () => {
  
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);

      //here i am updating the selected month and year on slide change
      const updatedMonth = months[newDate.getMonth() - 1 < 0 ? 11 : newDate.getMonth() - 1];
      const updatedYear = newDate.getMonth() - 1 < 0 ? newDate.getFullYear() - 1 : newDate.getFullYear();
      setSelectedMonth(updatedMonth);
      setSelectedYear(updatedYear);
      newDate.setMonth(newDate.getMonth() - 1);
      return newDate;
    });
    // }
  };

  // const justBlocks = getJustBlockedDatesFunc(propertyId)
  
  const nextSlide = () => {
 
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      //here i am updating the selected month and year on slide change
      const updatedMonth = months[newDate.getMonth() + 1 > 11 ? 0 : newDate.getMonth() + 1];
      const updatedYear = newDate.getMonth() + 1 > 11 ? newDate.getFullYear() + 1 : newDate.getFullYear();
      setSelectedMonth(updatedMonth);
      setSelectedYear(updatedYear);
      newDate.setMonth(newDate.getMonth() + 1);
      return newDate;
    });
    // }
  };
  
  

  const handleDayDateCellClick = (index) => {
    setSelectedIndex(index);
  
  }
 



    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [hoveredDayIndexes, setHoveredDayIndexes] = useState({});
    const [showPopup, setShowPopup] = useState(false); 
    const [propertyId, setPropertyId] = useState(null); 



    // Function to handle the click event on property rows
    
    const handlePropertyRowClick = async (propertyData) => {
      console.log('propsDataClick');
    
      const currentDate = new Date();
      const clickedDate = new Date(propertyData.date);
    
      // Extract year, month, and day from the dates
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
    
      const clickedYear = clickedDate.getFullYear();
      const clickedMonth = clickedDate.getMonth();
      const clickedDay = clickedDate.getDate();
    
      // Compare the extracted date components
      if (
        clickedYear > currentYear ||
        (clickedYear === currentYear && clickedMonth > currentMonth) ||
        (clickedYear === currentYear && clickedMonth === currentMonth && clickedDay >= currentDay)
      ) {
        setPropertyId(propertyData);
    
        const response = await getJustBlockedDatesFunc(propertyData.property_id);
        setAllBlockedDates(response.blockedDates);
        setCheckinBlockedDates(response.blockedDatesCheckin);
        setCheckoutBlockedDates(response.blockedDatesCheckout);
        setMinDate(response.minDate);
        setMaxDate(response.maxDate);
    
        if (propertyData.block === 1) {
          setRadioText('Blocked');
        } else {
          setRadioText('Available');
        }
    
        console.log('Popupclick');
        setShowPopup(true);
      }
    };
    
    


    const toggleVisibleDocks = () => { 
     setShowPopup(false)
    }


    ///---------------------------------

  
  const handleHover = (priceData, dayIndex, propertyIndex) => {
    console.log('hoverAll')
    if ((priceData && priceData.booked === 1) || (priceData && priceData.block === 1)) {
      setHoveredIndex(propertyIndex);
      setHoveredDayIndexes((prev) => ({ ...prev, [dayIndex]: propertyIndex }));
    } else {
      setHoveredIndex(null);
      setHoveredDayIndexes((prev) => ({ ...prev, [dayIndex]: null }));
    }
  };
  
  const handleMouseLeave = (dayIndex) => {
    setHoveredIndex(null);
    setHoveredDayIndexes((prev) => ({ ...prev, [dayIndex]: null }));
  };
  
 
    //--------------------

   
  
    const handleBookedClick = (priceData) => {
      if (priceData.booked === 1) {
        window.location.href = `host/reservations/${priceData?.booking_details[0]?.booking_order_id}`;
      }
    };

  

    const handleSearchInputChange = (e) => {
      setSearchInput(e.target.value);
    };
  
    const filteredPublishData = publishData.filter((item) =>
      item.property_name.toLowerCase().includes(searchInput.toLowerCase())
    );

   const handleFilterMonthYear =()=>{
  

    setSelectedMonth(saveSelectedMonth)
    setSelectedYear(saveSelectedYear)
    
   }

  //  setSaveSelectedMonth(month)
    // setSelectedMonth(month);
    
  return (
  
   
    <>
    <div className='calendar-footer-page' style={{overflowX:'hidden'}}>
    <div className='calendar-page'>

        
  <div className="calendar-right-content">
        
    <div className='calendar-top-div'>
      <p className='no-of-listing-text'>{filteredPublishData.length} Listings</p>
     <div className='flex-calendar-sub-top-div'>
     <p className="custom-swiper-button prev" onClick={prevSlide}>
       <FontAwesomeIcon icon={faChevronLeft}/>
       </p>
       <ButtonPopup
      wrapclass='Calendar-year-button-popup absolute'
      btnClass={true}
      
      showCloseButton={true}
      closeBtnText="Cancel"
      showApplyButton={true}
      applyBtnText="Save" 
      onApply={handleFilterMonthYear}
      divContent={
        <div className='calendar-popup-main-div'>
          <div className='calendar-popup-month-div'>
            {months.map((month, index) => (
              <div
                className={`mnth-year-text ${saveSelectedMonthClass === index ? 'selected' : ''}`}
                // className={`mnth-year-text ${selectedMonth === month ? 'selected' : ''}`}

                key={index}
                onClick={() => handleMonthClick(month,index)}
              >
                {month}
              </div>
            ))}
          </div>

          <div className='calendar-popup-years-div'>
            {years.map((year, index) => (
              <div
                className={`mnth-year-text ${saveSelectedYear === year ? 'selected' : ''}`}
                // className={`mnth-year-text ${selectedYear === year ? 'selected' : ''}`}

                key={index}
                onClick={() => handleYearClick(year)}
              >
                {year}
              </div>
            ))}
          </div>
        </div>
      }
      makeCustom={
         <div className='custom-filter-btn-parent'>
          <button style={{width:'100%'}} className="custom-filter-btn">
           {`${selectedMonth} ${selectedYear}`} <span className="arrow">▼</span>
          </button>
         </div>
       }
    />
  
       <p className="custom-swiper-button next" onClick={nextSlide}>
       <FontAwesomeIcon icon={faChevronRight}/>
        </p>

    </div>

      
        </div>

      
        <div  id="currentMonth" className="custom-swiper-parent">
          {
           slides.map((slide, index) => {
             return (
            <div key={index} className={`custom-swiper-slide ${index === 1 ? 'calendar-active-date active' : ''}`}>
          <     div className="calendar-days-container">
                <div className="sticky-container">
                <div className='positioning-search-com'>
                    <div className="search-comp-box ">
                      <input
                        type="text"
                        className="search-input"
                        placeholder="Search Property"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                      />
                      <FontComp
                        fontclassname="search-icon"
                        icon={faSearch}
                        size="lg"
                        color="#BDBDBD"
                      />
                    </div>
                  </div>
                  <div className="date-day-parent">
                   {slide.days.map((day, dayIndex) => {
                    console.log(day,'check1')
                  
                    const isCurrentDay = day.date === currentDay;
                    const isCurrentMonth = day.fullDate.getMonth() + 1 === currentMonthCheck ;
                   
                    return(
                 
                  <div
                  style={{ flex: '0 0 120px' }}
                  className={`date-day-div ${isCurrentDay ? 'current-date' : ''}`}
                  data-date={day.date}
                  key={`day-${dayIndex}`}
                    >
                  {isCurrentDay && isCurrentMonth? (
                      <div className="current-slected-div">
                          <div className="day-cell">{day.day}</div>
                          <div className="date-cell">{day.date}</div>
                      </div>
                  ) : (
                      <>
                       <div className="non-current-selected-div">
                          <div className="day-cell">{day.day}</div>
                          <div className="date-cell">{day.date}</div>
                          </div>
                      </>
                  )}
              </div>
              )})}
              </div>
            </div>
            <div className="property-rows-parent">
              <div className="property-rows-container calendar-left-content">
                <>
                  
                  {filteredPublishData?.map((listing, index) => (
                    <PropertyListing
                      key={index}
                      src={listing.base_url_image + listing?.images[0] ? 
                        listing.base_url_image + listing?.images[0] : './assets/img/notFoundImage.png'}
                      propListingText="calendar-property-name-text"
                      paraText={listing.property_name}
                    />
                  ))}
                </>
              </div>

              <div>
           {filteredPublishData.map((propertyData, propertyIndex) => (
           <div key={`property-container-${propertyIndex}`} className="property-rows-container" style={{ display: 'flex' }}>
          {slide.days.map((day, dayIndex) => {
            let priceData = propertyData?.slots?.find((slot) => {
              const slotDate = new Date(slot.date);
              return (
                slotDate.getDate() === day.date &&
                slotDate.getMonth() === day.fullDate.getMonth() &&
                slotDate.getFullYear() === day.fullDate.getFullYear()
              );
            });

            const startDate = new Date(priceData?.booking_details[0]?.from_date);
            const endDate = new Date(priceData?.booking_details[0]?.to_date);
            const timeDiff = Math.abs(endDate - startDate);
            const numberOfNights = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
          // console.log(priceData,'checkDataPrice')
  
            return (
              <div
                key={`property-${propertyIndex}-day-${dayIndex}`}
                className="property-row w-px-120 h-px-80"
              
                onClick={() => handlePropertyRowClick(priceData)}
                onMouseOver={() => handleHover(priceData, dayIndex, propertyIndex)}
                onMouseLeave={() => handleMouseLeave(dayIndex)}
              >
                <div
                  onClick={() => {
                    handleBookedClick(priceData);
                  }}
             
                className={`price-cell ${
                  priceData && priceData.block === 1 ? 'blocked' : ''
                }${priceData && priceData.booked === 1 ? ' booked' : ''}${
                  priceData && new Date(priceData.date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) ? ' past-date' : ''
                }`}
              >
                  {priceData ? (
                    <>
                    
                      <div className='booked-row'>
                        <p>
                          ₹ {priceData?.booked === 1
                            ? priceData?.booking_details[0]?.amount
                            : priceData?.price}
                        </p>
                        <p>
                          {priceData?.booked === 1 &&
                            priceData?.booking_details[0]?.fname &&
                            priceData?.booking_details[0]?.lname
                            ? `${priceData?.booking_details[0]?.fname} ${priceData?.booking_details[0]?.lname}`
                            : priceData?.booked === 1 &&
                              priceData?.booking_details[0]?.fname
                              ? `${priceData?.booking_details[0]?.fname}`
                              : priceData?.booked === 1 &&
                                priceData?.booking_details[0]?.lname
                                ? `${priceData?.booking_details[0]?.lname}`
                                : ''}
                        </p>
                      </div>
                      {priceData.block === 1 && priceData.block_message !== null && priceData.block_message !== "" && priceData.block_message !== false && 
                      <FontAwesomeIcon icon={faCommentDots}/>
                      }
                    </>
                  ) : (
                    '-'
                  )}
                </div>

                {hoveredDayIndexes[dayIndex] === propertyIndex && priceData && priceData.booked === 1 && (
            <div className='calendar-hovered-data-div'>
              <p className='hovered-guest-name'>{priceData?.booking_details[0]?.fname} {priceData?.booking_details[0]?.lname}</p>
              <p>{priceData?.booking_details[0]?.no_of_guest} {priceData?.booking_details[0]?.no_of_guest > 1 ? 'Guests' : 'Guest'}</p>
              <p>{numberOfNights} {numberOfNights > 1 ? 'Nights' : 'Night'}</p>
            </div>
          )}
          {hoveredDayIndexes[dayIndex] === propertyIndex && priceData && priceData.block === 1 && priceData.block_message !== null && priceData.block_message !== "" && priceData.block_message !== false && (
        <div className='calendar-hovered-data-div block-hover-div'>{priceData.block_message}</div>
      )}
        </div>
            );
          })}
        </div>
      ))}
       </div>
            </div>
          </div>
        
      </div>
    );
  })
 }
  
    </div>
     <div>
    </div>
     </div>
    </div>

        <div className="booking-date-edit-popup">
        {showPopup && (
          <WrapperPopup
          fluid={true}
          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks}
          
            closeModal={() => setShowPopup(false)}

            comp={
              <CalendarPriceInpPop  
              closeModal={() => setShowPopup(false)}
              propertyId={propertyId.property_id}
              blockMessage={propertyId.block_message}
              blockDate={allBlockDateState} 
              blockCheckinDateState={blockCheckinDateState}
              blockCheckoutDateState={blockCheckoutDateState}
              startDate={propertyId.date}
              price={propertyId.price}
              minDate={minDateState}
              maxDate={maxDateState}
              radionText={radionText}
              fetchCalendarSlots={fetchCalendarSlots}
              />
            // 'hhh'
            }
          />
        )}
        </div> 
        {/* <FooterComponent/> */}
      <HostFooterComp/>

        </div>
</> 


  );
};

export default CalendarPage;
