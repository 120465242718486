import React from 'react'
import { useNavigate } from 'react-router-dom';

const Step1Listing = () => {

const navigate = useNavigate()

const navigateTo = () => {
    navigate('/host/createlisting/property-title')

    };

return (
<div className='start-create-listing-page'>

<div  className='flex step-flex-box  m-flex-flow-column'>
    <div  className='step-1-img-box w-50 w-m-100 h-300px'>
    <img className='w-100' src="./assets/img/StartHomeImg.jpg" alt="" /> 
    </div>

    <div className='w-50 w-m-100'>
    <p className='step-text'>Step 1</p>
    <h2>Unveil the beauty of your space</h2>
    <p className='step-para-text'>Let's get started by selecting your property type, whether it's an entire place or just a room. Next, we'll gather details about the location and the maximum number of guests it can accommodate.</p>
    </div>
    </div>

    <div className="bottom-btns-fixed-box">
        
        <button
        onClick={navigateTo}
        type="submit"
        className="common-next-btn"
        >
        Next
        </button>
    </div>
</div>
)
}

export default Step1Listing
