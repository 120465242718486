
import React from 'react';
import { Link } from 'react-router-dom';

const FooterComponent = () => {
  return (
    <div className='footer-container' >
      <div className="footer-grid">
        <div className="support">
          <h3>Support</h3>
          <div className="terms-links">
            <Link className="terms-link" to='/ContactUs'>Contact us</Link>
            <Link className="terms-link" to='/ContactUs'>Get help with your stays</Link>
            <Link className="terms-link" to='/ContactUs'>Get help with your property listing</Link>
            <Link className="terms-link" to='/privacypolicy'>Privacy Policy</Link>
            <Link className="terms-link" to='/termscondition'>Terms & Conditions</Link>
          </div>
        </div>

        <div className="quick-links">
          <h3>Quick links</h3>
          <div className="terms-links">
            <Link className="terms-link" to='/properties?location=Mahabaleshwar'>Stays in Mahabaleshwar</Link>
            <Link className="terms-link" to='/properties?location=Ambernath'>Stays in Ambernath</Link>
            <Link className="terms-link" to='/properties?location=Lonavala'>Stays in Lonavala</Link>
            <Link className="terms-link" to='/properties?location=Karjat'>Stays in Karjat</Link>
            <Link className="terms-link" to='/properties?location=Goa'>Stays in Goa</Link>
          </div>
        </div>

        <div className="jumbo-holidayz">
          <h3>Jumbo Holidayz</h3>
          <div className="terms-links">
            <Link className="terms-link" to='/Blog'>Blog</Link>
            <Link className="terms-link" to='/Careers'>Careers</Link>
          </div>
          <br/>
          <p>&copy; 2023 Jumbo Holidayz</p>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
