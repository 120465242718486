import React from 'react'
import Button from '../../Components/common/Button'
import { Link } from 'react-router-dom'
import DateRangeDisplay from '../../Components/common/DateRangeDisplay'
import { calculateBookingPriceFunc, formatIndianRupeesFunc } from '../../utils/constant'
import { useEffect } from 'react'
import { useState } from 'react'

const UserMobileReserveComp = (props) => {

  const [guestCount, setGuestCount] = useState(null);
  const [infantCount, setInfantCount] = useState(null);
  const [childrenCount, setChildrenCount] = useState(null);
  const [adultCount, setAdultCount] = useState(null);
  const [propertyId, setPropertyId] = useState();
  const [successdataprice, setSuccessDataPrice] = useState();
  const [errorMessage,setErrorMesssage]= useState('')


  const [selectedCheckIn, setCheckIn] = useState();
  const [selectedCheckOut, setCheckOut] = useState();

  const [currentLocation, setCurrentLocation] = useState();

  const dynamiclocation = window.location.href;

  useEffect(()=>{   
    if(dynamiclocation.includes('?')){
      var savelocation = dynamiclocation.split('?')
      setCurrentLocation(savelocation[1])
      console.log("location:",dynamiclocation) 
    }
  },[dynamiclocation])

  const linkTo = `./book${currentLocation && currentLocation !== '' ? '?'+currentLocation : ''}`;

  useEffect(() => {
    if (props.selectedCheckIn !== null && props.selectedCheckOut !== null) {
   calculateBookingPriceFunc(props.propertyId, props.guestCount, props.selectedCheckIn, props.selectedCheckOut)
  .then((result) => {
    console.log(result,'result')
        setSuccessDataPrice(result);
        setErrorMesssage(null)


  })
  .catch((error) => {
    console.log(error,'errors')
    setErrorMesssage('Dates are not available')
    setSuccessDataPrice(null);

    // Handle the error if needed
  });

    }

    
  }, [props.selectedCheckIn, props.selectedCheckOut, props.guestCount]);

console.log(errorMessage,'errors')



  return (
    <div className='mobile-reserve-bottombar-div'>
      <div className='mobile-reserve-flex-div flex'>

  
    {successdataprice?.total.grand_total_booking_cost ?  <p> 
      <span className='amount-text'>
     ₹{formatIndianRupeesFunc(successdataprice?.total.grand_total_booking_cost.toFixed(2))}
      </span></p> : errorMessage }

        {/* <span className='selected-dates'>{props.selectedDates}</span> */}
        {props.hasDates ?  
        <DateRangeDisplay  
        onClick={props.onClick}
        className=' mob-date-range-text'   
        startDate={props.selectedStartDate}
         endDate={props.selectedEndDate}
         />

         :  <p className='mob-date-range-text'  onClick={props.onClick}>Add dates for prices</p>
        }

      </div>
      {errorMessage === null ?
      
      <Link to={linkTo}><Button
      btnclasses='save-edit-btn'
      btnText='Reserve'
      /></Link>
      : 

      <Button
      onClick={props.onClick}
      btnText='Add Dates'
      btnclasses='save-edit-btn'
      />
      }
    
    </div>
  )
}

export default UserMobileReserveComp
