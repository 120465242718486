import React, { useState } from 'react'
import ViewBreakDownCard from '../../../Components/HostComponents/viewBreakdownCard';
import WrapperPopup from '../../../Components/common/wrapperPopup';
import CardWrapper from '../../../Components/common/cardWrapper';
import moment from 'moment';
import { formatIndianRupeesFunc } from '../../../utils/constant';

const TripConfirmationCardComp = (props) => {
  
  
    return (
        <>
        <CardWrapper wrapperClass="confirmation-card">
          {/* Left Box */}
          <div className="confirmation-card-left-box">
            <div className='mb-25'>
            <p className="confirmation-text">Status</p>
            <p className="confirmation-code-text">{props.successdata?.status && props.successdata.status.charAt(0).toUpperCase() + props.successdata.status.slice(1)}</p>
            <p className='font-xs'>On {props.successdata?.status != 'refunded' ? (props.successdata?.createdon && new Date(props.successdata.createdon).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "UTC",
        })) : (props.successdata?.refundedon && new Date(props.successdata.refundedon).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "UTC",
        }))}</p>
            </div>
            <div>
            <p className="confirmation-text">Confirmation code</p>
            <p className="confirmation-code-text">{props.successdata?.booking_order_id}</p>
            </div>
          </div>
  
          {/* Right Box */}
          <div className="confirmation-card-right-box">
            <div style={{ gap: '1rem' }} className="flex align-items-center">
              <p className="total-text">Total</p>
              <p className="total-amount-text">
                ₹{formatIndianRupeesFunc(props.successdata?.amount)}
              </p>
            </div>
            <div style={{ gap: '1rem' }} className="flex justify-space-between">
             
                <p
                    style={{ cursor: 'pointer' }}
                    onClick={props.handleOpenModal}
                    className="viewBreakdown"
               >
                View Breakdown
                </p>

              
              <p
                style={{ cursor: 'pointer' }}
                // onClick={}
                className="viewBreakdown"
              >
                Get receipt
              </p>
            </div>
          </div>
        </CardWrapper>
  
       
        </>
      
    )
}

export default TripConfirmationCardComp
