import { createSlice } from "@reduxjs/toolkit";

// Define initial state
// const initialState = [];
const initialState = {
  propertyFinalData: null,
  placeFinalData: null,
  roomFinalData: null,
  discFinalData: null,
  rulesFinalData: null,
  bookingPrefrenceData: null,
  amenitiesData: null,
  confirmAddressData: null,
};

const listingDataSlice = createSlice({
  name: "propertyData",
  initialState,
  reducers: {
    setPropertyData: (state, action) => {
      // const index = action.payload.index;
      state.propertyFinalData = action.payload;
      // delete action.payload.index;
      // state[index].propertyFinalData = action.payload.propertyTypeFinalData;
    },
    setPlaceData: (state, action) => {
      // console.log("ooo");
      // let update = state[action.payload?.index];
      // console.log("tuuu", action.payload, update);
      // update.placeFinalData = action.payload?.placeFinalData;
      // state[action.payload.index].placeFinalData =
      //   action.payload.placeFinalData;
      state.placeFinalData = action.payload;
    },
    setRoomData: (state, action) => {
      // let update = state[action.payload?.index];
      // console.log("tuuu", action.payload, update);
      // update.roomFinalData = action.payload?.roomFinalData;
      // state[action.payload.index].roomFinalData = action.payload.roomFinalData;
      state.roomFinalData = action.payload;
    },

    setDiscData: (state, action) => {
      state.discFinalData = action.payload;

      // let update = state[action.payload?.index];
      // console.log("tuuu", action.payload, update);
      // update.discFinalData = action.payload?.discFinalData;
      // state[action.payload.index].discFinalData = action.payload.discFinalData;
    },
    setRulesData: (state, action) => {
      
      state.rulesFinalData = action.payload;
    },
    setBookingPrefrenceData: (state, action) => {
  
      state.bookingPrefrenceData = action.payload;
    },
    setAmenitiesData: (state, action) => {
      // let update = state[action.payload?.index];
      // console.log("tuuu", action.payload, update);
      // update.amenitiesFinalData = action.payload?.amenitiesFinalData;
      // state[action.payload.index].amenitiesFinalData =
      //   action.payload.amenitiesFinalData;
      state.amenitiesData = action.payload;
    },
    setConfirmAddressData: (state, action) => {
     
      state.confirmAddressData = action.payload;
    },
    
  },
});

export const {
  setPropertyData,
  setPlaceData,
  setRoomData,
  setDiscData,
  setRulesData,
  setBookingPrefrenceData,
  setConfirmAddressData,
  setAmenitiesData,
  // setGetListingData,
  // setPropertyId,
} = listingDataSlice.actions;
export const ListingDataReducer = listingDataSlice.reducer;
