import React, { useEffect, useState } from "react";
import HeroSwiper from "../../Components/UserComponents/HeroSwiper";
import SearchInput from "../../Components/UserComponents/searchInputs";
import GuestProfile from "../../Components/common/guestProfile";
import CardWrapper from "../../Components/common/cardWrapper";
import UserExploreCard from "../../Components/UserComponents/userExploreCard";
import BookingActionCard from "../../Components/common/bookingActionCard";
import Button from "../../Components/common/Button";
import TrendingProperty from "../../Components/UserComponents/TrendingProperty";
import DatePicker from "../../Components/UserComponents/datePicker";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiMainUser, apiPublicUser } from "../../utils/constant";
import UserBottomBar from "../../Module/User/UserBottomBar";
import ButtonPopup from "../../Components/common/ButtonPopup";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WrapperPopup from "../../Components/common/wrapperPopup";
import MobHeadDestinationGuest from "../../Module/User/MobHeadDestinaionGuest";
import MobHeadNav from "../../Module/User/MobHeadNav";
import Swiper from "swiper";
import { SwiperSlide } from "swiper/react";
import SkeletonLoader from "../../Components/common/SkeletonLoader";
import SkeletonTrendingProperty from "../../MobSkeleton/SkeletonTrendingProperty";
import VerifactionToNavigateHost from "../../Components/common/VerifactionToNavigateHost";
import { useSelector } from "react-redux";

const Home = () => {
  const [successdata, setSuccessData] = useState([]);

  const [sortingOption, setSortingOption] = useState("");

  const [openModalNav, setOpenModalNav] = useState(false);
  const isSmallScreen = window.innerWidth <= 500;

  const navigate = useNavigate()


   //sorting filter------------------------------------
   const [activeSortingOption, setActiveSortingOption] = useState(null);

   const [selectedFilterText, setSelectedFilterText] = useState("Type");
 
  const handleSortingOptionClick = (option) => {
 
    if (activeSortingOption === option) {
      setActiveSortingOption(null);
      setSortingOption("");
      // setSelectedFilterText('Type')
    } else {
      setActiveSortingOption(option);
      setSortingOption(option);
      // Call the API only in desktop view
      if (window.innerWidth > 500) {
        fetchDatapProperty();
      }
      // setSelectedFilterText(option);
    }
    
  };

   
 
   const isOptionActive = (option) => {
     return sortingOption === option ? "active-sort" : "";
   };
 


  const fetchDatapProperty = async () => {
    try {
      const response = await axios.get(apiPublicUser + "search_property", {
        params: {
          sortby: sortingOption,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
  
      
      if (response.status === 200 && response.data.data.length > 0) {
        setSuccessData(response.data.data);
      } else {
        setSuccessData("No Property Found");
      }
    } catch (error) {
      setSuccessData("No Property Found");
    }
  };
  

  useEffect(() => {
    fetchDatapProperty();
   
  }, [])

  useEffect(() => {
    if (window.innerWidth > 500) {
      fetchDatapProperty();
    }
  }, [sortingOption]);


  const fetchDataOnSave = () => {
    let filterText = "";
    switch (sortingOption) {
      case "booking":
        filterText = "Most Booked";
        break;
      case "low2high":
        filterText = "Price : Low to High";
        break;
      case "high2low":
        filterText = "Price : High to Low";
        break;
      default:
        filterText = "Type";
        break;
    }
  
    setSelectedFilterText(filterText);
    fetchDatapProperty();
  };



  const toggleVisibleDocks = () => {
    setOpenModalNav((prevVisible) => !prevVisible);
  };


  const [isVisible, setIsVisible] = useState('');
  const storeSel = useSelector((state) => state);

  const userName = storeSel.hostDetailSlice.user_Name;

  const [verficationData,setVerificationData] = useState()

  const [verificatioProfilePop,setVerificationProfilePop] = useState(false)


  const toggleVisibleDocksHostNav = () => {
    setVerificationProfilePop(false)
    }


const handleUniqueID = (uniqueID) => {
  // setModifiedId(uniqueID)

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        console.log(entry, 'checkInter');

        if (entry.isIntersecting) {
          console.log(entry.isIntersecting, 'checkInter');
          // setIsVisible(true);
          setIsVisible(entry.target.id === uniqueID ? uniqueID : '');
          // setModifiedId(entry.target.id === uniqueID ? uniqueID : '')
        } 
      });
    },
    { threshold: 0.4 }
  );

  const targetElement = document.getElementById(uniqueID);

      if (targetElement) {
        observer.observe(targetElement);
      }

    // Clean up observer
      return () => {
        if (targetElement) {
          observer.unobserve(targetElement);
        }
      };

    };

  // Updating here the URL based on sorting option
  useEffect(() => {
  const params = new URLSearchParams(window.location.search);
  if (sortingOption) {
    params.set("sort", sortingOption);
  } else {
    params.delete("sort");
  }
  const newUrl = params.toString() ? `${window.location.pathname}?${params}` : window.location.pathname;
  window.history.replaceState({}, "", newUrl);
  }, [sortingOption]);


  useEffect(() => {
    const fetchData = async () => {
      try {
 
        const userResponse = await axios.get(apiMainUser + "get_user_detail", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          }
        });
        console.log(userResponse.data.data,'checkResponseNew')
        setVerificationData(userResponse.data.data[0])
      } catch (error) {
      }
    };

    fetchData();
  }, [userName]); // Trigger the effect whenever userName changes
  const handleSwitchToHosting = () => {
    if (verficationData?.mobile_verified !== 0 && verficationData?.email_verified !== 0 && verficationData?.user_docs_details !== []) {
      navigate("/host");
    } else {
      setVerificationProfilePop(true);
    }
  };

    
    
  
  return (
    <>
   
    <div className="user-home-page">
    {isSmallScreen && <MobHeadNav
   
   
   
   
   onClickSearch={() => setOpenModalNav(true)}
   position=""/>
   }


      <div className="hero-section">
        <HeroSwiper />
        
      </div>

      <div className="main-section">
        {/*  */}
        <div className="key-benefits-div">
          <CardWrapper wrapperClass="home-key-benefits-card">
            <GuestProfile
              profileName="Escape the world together in private heaven"
              //   whoseProfile="Together in private heaven"
              profileImage="./assets/img/benefitsImg3.svg"
            />
          </CardWrapper>
          <CardWrapper wrapperClass="home-key-benefits-card">
            <GuestProfile
              profileName="Exclusive Gateways, Zero shared amenities"
              //   whoseProfile="Zero shared amenities"
              profileImage="./assets/img/benefitsImg2.svg"
            />
          </CardWrapper>
          <CardWrapper wrapperClass="home-key-benefits-card">
            <GuestProfile
              profileName="24 hour service at your back and call"
              //   whoseProfile="Zero shared amenities"
              profileImage="./assets/img/benefitsImg4.svg"
            />
          </CardWrapper>
        </div>
        {/*  */}

        {/*    */}
        {/* <div className="home-explore-jumbo-div">
          <h3 className="jumbo-topic-heading">Explore Jumbo</h3>
          <p className="explore-sub-para">
            Escape the ordinary and discover a world of luxury in our portfolio
            of vacation rentals.
          </p>
          <div className="explore-jumbo-card-box">
            <UserExploreCard
              src="./assets/img/exploreStateImg.jpg"
              exploreCarText="Gorakhpur"
            />
            <UserExploreCard
              src="./assets/img/exploreStateImg.jpg"
              exploreCarText="Delhi"
            />
            <UserExploreCard
              src="./assets/img/exploreStateImg.jpg"
              exploreCarText="Assam"
            />
            <UserExploreCard
              src="./assets/img/exploreStateImg.jpg"
              exploreCarText="Sikkim"
            />
            <UserExploreCard
              src="./assets/img/exploreStateImg.jpg"
              exploreCarText="Mumbai"
            />
            <UserExploreCard
              src="./assets/img/exploreStateImg.jpg"
              exploreCarText="Goa"
            />
          </div>
        </div> */}

       

        {/*  */}
        <div className="home-trending-property-div">
          <h3 className="jumbo-topic-heading">Trending Properties</h3>
          <p className="explore-sub-para">
            Unleash the hidden gems of the world and live like a local with
            Jumbo’s hottest trending properties.
          </p>
          <div className="home-trending-flex-box">
             
              {/* <Button btnclasses="tab-btn-hover" btnText="Our top Picks" />
              <Button btnclasses="tab-btn-hover" btnText="Most popular" />
              <Button btnclasses="tab-btn-hover" btnText="Most Booked" />
              <Button btnclasses="tab-btn-hover" btnText="Family Friendly" />
              <Button btnclasses="btn-filter" btnText="More Filter" /> */}
              {!isSmallScreen ? <CardWrapper 
               wrapperClass={`trending-property-sort-card sticky-t-111`}
              >             
            <Button
            onClick={() => handleSortingOptionClick("booking")}
            btnclasses={`tab-btn-hover  w-100 ${isOptionActive("booking")}`}
            btnText="Most Booked" />


            {/* <Button btnclasses="tab-btn-hover" btnText="User ratings" /> */}
            <Button
            onClick={() => handleSortingOptionClick("low2high")}
            btnclasses={`tab-btn-hover  w-100 ${isOptionActive("low2high")}`}
           btnText="Price : Low to High" />

            <Button
            onClick={() => handleSortingOptionClick("high2low")}
            btnclasses={`tab-btn-hover  w-100 ${isOptionActive("high2low")}`}
            btnText="Price : High to Low" />

            </CardWrapper>
            : null}

            
            {isSmallScreen ?
        <ButtonPopup
        // wrapHead="Rooms & Beds"
        
           wrapclass={`absolute  custom-mob-filter-card`}
           buttonPopup={true}
          showCloseButton={true}
          closeBtnText="Cancel"
          showApplyButton={true}
          applyBtnText="Save"
          onApply={fetchDataOnSave}
        divContent={
          <>
        <Button
            onClick={() => handleSortingOptionClick("booking")}
            btnclasses={`tab-btn-hover  w-100 ${isOptionActive("booking")}`}
            btnText="Most Booked" />


            {/* <Button btnclasses="tab-btn-hover" btnText="User ratings" /> */}
            <Button
            onClick={() => handleSortingOptionClick("low2high")}
            btnclasses={`tab-btn-hover  w-100 ${isOptionActive("low2high")}`}
           btnText="Price : Low to High" />

            <Button
            onClick={() => handleSortingOptionClick("high2low")}
            btnclasses={`tab-btn-hover  w-100 ${isOptionActive("high2low")}`}
            btnText="Price : High to Low" />


          </>
        }
        makeCustom={
          <button className={`custom-filter-btn`}>
          {selectedFilterText}
          <FontAwesomeIcon icon={faAngleDown} className="arrow-icon-filter" />

        </button>
        }
        
      />
      : null}

  <div className="all-trending-property-box">
    {successdata === "No Property Found" ? (
     <p>No properties found.</p>
     ) : (
    successdata.slice(0, 5).map((data, index) => {
      const amenitiesToDisplay = data.amenities !== null
        ? data.amenities_details.slice(0, 3)
        : [];

      return (
        <TrendingProperty
          key={index}
          index={index}
          isVisible={isVisible}
          handleUniqueID={handleUniqueID}
          property_name={data.property_name}
          img={
            data.images && data.images.length > 0
              ? data.base_url_image + data.images[0]
              : "./assets/img/notFoundImage.png"
          }
          swiperSlides={
            data.images && data.images.length > 0
              ? data.images.map((imgUrl, index) => ({
                  imgUrl: data.base_url_image + imgUrl,
                  altText: `Slide ${index + 1}`,
                }))
              : [
                  {
                    imgUrl: "./assets/img/notFoundImage.png",
                    altText: "Not Found Image",
                  },
                ]
          }
          priceValue={data.base_price}
          location={data.city + ", " + data.state}
          maxguest={data.max_guests}
          rooms={data.bedrooms}
          beds={data.beds}
          bath={data.bathrooms}
          amenities={amenitiesToDisplay}
          slug={"/properties/" + data.city + "/" + data.slug}
          amenitiesCount={data.amenities_details.length - 3}
          reviewNum={data?.review_count}
          starNum={data?.Overall_rating}
          WishListpropID={data.propertyid}
          wishListApplied={data.wishlist}
        />
      );
     })
    )}
     <div className="text-center mt-10">
      <p
       className="underline pointer font-small" 
       onClick={() => {
        let url = '/properties';
        if (sortingOption) {
          url += `?sort=${sortingOption}`;
        }
          navigate(url);
       }}
      >
       View All
      </p>
    </div>
    </div>



          </div>


        </div>

   


        <div className="home-experience-more-img-box">
          <a href="https://link.jumboholidayz.com/getapp/" target="_blank"><img src="./assets/img/homePartnerImg.png" alt="no-img" /></a>
        </div>

        <div className="home-experience-more-img-box" 
            onClick={handleSwitchToHosting}
            >
          <img src="./assets/img/homeExpImg.png" alt="" />
        </div>
      </div>


      
    </div>




    <div className='Mob-destination-guest-popup-div'>
          {openModalNav && (
        <WrapperPopup
        fluid={true}
        DockFilter={true}
        headingText='Select Date and Guest'
        position='bottom'
        // dimMode={props.opaque ? props.opaque : false}
        defaultSize={"0.90"}
        dimStyle={false}
        // isVisible={openModalNav}
        isVisible={true}
        
        setVisibleDocks={toggleVisibleDocks} 
        closeModal={setOpenModalNav}
         comp={
         <MobHeadDestinationGuest
          closeModal={setOpenModalNav}/>
        }
         />
      )}
      </div>
      <div className="edit-booking-preference-popup">
        {verificatioProfilePop && (
          <WrapperPopup
          fluid={true}
     
          defaultSize={"0.90"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocksHostNav} 
          closeModal={setVerificationProfilePop}
          comp={
         <VerifactionToNavigateHost
         closeModal={setVerificationProfilePop}
         />
        }
        />
        
        )}
      </div>
    </>
  );
};

export default Home;

