import React from "react";

const PropertyDetailFeature = (props) => {
  
  return (
    <div className="property-detail-feature">
      <div className="property-detail-feature-headingbox">
        <img src={props.src} alt="" className={props.className} />
        <h4>{props.detailHeading}</h4>
      </div>
      <p className="one-liner">{props.oneLiner}</p>
    </div>
  );
};

export default PropertyDetailFeature;
