import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropertyListing from "../../../Components/common/PropertyListing";
import Button from "../../../Components/common/Button";
import SearchComp from "../../../Components/common/searchComp";
import FilterArrow from "../../../Components/common/filterArrow";
import DynamicTable from "../../../Components/common/HostTables";
import CardWrapper from "../../../Components/common/cardWrapper";
import CircleComp from "../../../Components/common/circleComp";
import InsightOccupancyComp from "../../../Components/HostComponents/InsightComponents/InsightOccupancyComp";
import IInsightRatingComp from "../../../Components/HostComponents/InsightComponents/IInsightRatingComp";
import FooterComponent from "../FooterComponent";
import { apiMainUser } from "../../../utils/constant";
import axios from "axios";
import WrapperPopup from "../../../Components/common/wrapperPopup";
import CustomInsightDatePop from "../../../Components/common/CustomInsightDatePop";
import DateRangeDisplay from "../../../Components/common/DateRangeDisplay";
import HostFooterComp from "../HostFooterComp";

const InsightPage = () => {
  const [activeTab, setActiveTab] = useState('7'); // Default to '7' for the Last 7 days tab
  const [apiParams, setApiParams] = useState({ key: 'week', value: 1 });
  const [currentData,setCurrentData] = useState()
  const [propertyOverallData, setPropertyOverallData] = useState([]);

  const [customDatePop,setCustomDatePop] = useState(false)

  const [defaultFromDate, setDefaultFromDate] = useState(''); // Set default from date
  const [defaultToDate, setDefaultToDate] = useState('');


  const handleTabClick = (time) => {
    setActiveTab(time);
  

       // Set API parameters based on the selected tab
       if (time === '7') {
        setApiParams({ key: 'week', value: 1 });
      } else if (time === '30') {
        setApiParams({ key: 'month', value: 1 });
      } else if (time === '365') {
        setApiParams({ key: 'year', value: 1 });
      }

      setDefaultFromDate('');
      setDefaultToDate('');
  };

  const updateDateRange = (fromDate, toDate) => {
    setApiParams({
      from_date: fromDate,
      to_date: toDate
    });
    setActiveTab('')

    setDefaultFromDate(fromDate);
    setDefaultToDate(toDate);

  };
  


  const toggleVisibleDocks = () => {
  setCustomDatePop(false)
  }

  useEffect(() => {
    // Call your API with apiParams
    fetchData(apiParams);
  }, [apiParams]);

  const fetchData = async (params) => {
    try {
      const headers = {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      };
  
      // Make API call using Axios with headers
      const response = await axios.get(apiMainUser + 'insights_for_host', {
        params: params,
        headers: headers,
      });
  
      const data = response.data;
      // Process the fetched data

      setCurrentData(data.data.current.average_data)
      setPropertyOverallData(data.data.current.data)
      console.log(data, 'checkInsightData');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  console.log(propertyOverallData,'Ckkl')


  // const [searchQuery, setSearchQuery] = useState("");
  // // const [filteredData, setFilteredData] = useState([]); 

  //  const handleSearch = (e) => {

  //   setSearchQuery(e.target.value);

  //  };

  

  const data = propertyOverallData?.map((item, index) => {
    return {
      "PROPERTY NAME": (
        <PropertyListing
          key={index}
          src={item?.property_details?.base_url_image + item?.property_details?.property_images[0]}
          className="property-listing-img"
          width="60px"
          height="60px" // Corrected typo in height attribute
          propListingText="propListingText"
          paraText={<>{item?.property_details.property_name}<br/>{item?.property_details.city} {item?.property_details.state}</>}
        />
      ),


      "PLAN NAME_sort": item?.property_details?.property_name,
      "OCCUPANCY RATE": <CircleComp key={index} percentage={item?.occupancy_rate} color='#8102FF' />,
      "CANCELLATION RATE": <CircleComp key={index} percentage={item?.cancellation_rate} color='#8102FF' />,
      "AVERAGE RATING": item?.rating_avg || 0,
      "REVIEW": item?.rating_avg || 0,
      "WISHLIST": item?.review_count || 0,
      "COVERSION RATE": <CircleComp key={index} percentage={item?.occupancy_rate}  color='#8102FF' />,
      EARNINGS: item?.earnings,
     
      
    };
  });


  console.log(defaultFromDate,defaultToDate,'checkAllSome')


  


  return (
    <>
    <div className="Listing-page Insight-page">
      <div className="insight-tab-container-parent" >
      <div className="navbar-tab-container insight-tab-container flex ">
        {/* <div className="flex"> */}
      <p
        className={`nav-link-edit ${activeTab === '7' ? 'edit-active' : ''}`}
        onClick={() => handleTabClick('7')}
      >
        Last 7 days
      </p>
      <p
        className={`nav-link-edit ${activeTab === '30' ? 'edit-active' : ''}`}
        onClick={() => handleTabClick('30')}
      >
        Last 30 days
      </p>
      <p
        className={`nav-link-edit ${activeTab === '365' ? 'edit-active' : ''}`}
        onClick={() => handleTabClick('365')}
      >
        365 days
      </p>
      {/* </div> */}
      {/* <p className="font-small pointer underline mt-8" onClick={() => setCustomDatePop(true)}>
  {defaultFromDate && defaultToDate ? `${defaultFromDate} to ${defaultToDate}` : "Custom Dates"}
</p> */}
<p className={`nav-link-edit ${defaultFromDate && defaultToDate ? 'edit-active' : ''}`} onClick={() => setCustomDatePop(true)}>
  {defaultFromDate && defaultToDate ? (
    <DateRangeDisplay startDate={defaultFromDate} endDate={defaultToDate} />
  ) : (
    "Custom Dates"
  )}
</p>


    </div>
    </div>

    <div className="insight-ocunpancy-card-box">

    <IInsightRatingComp
     wrapperHeading='Ratings'
     ratingText='Overall Rating'
     overallRatingNumber={currentData?.overallRating || 0}
    //  propertyAboveRating='5'
    propertyAboveRating={currentData?.above4Star}

     propertRateText1='Properties above'
     propertRateStar1='4'
     propertyBelowRating={currentData?.below3Star}
     propertRateText2='Properties below'
     propertRateStar2='3'
     overall_review_count={currentData?.review_count || 0}
    
    />

      <InsightOccupancyComp
      wrapperHeading='Occupancy & Rates'
      cardText1='Occupancy rate'
      cardText2='Cancellation rate'
      cardText3='Length of Stay'
      rateText1={currentData?.overallOccupancy || 0}
      rateText2='6%'
      rateText3={currentData?.avg_stay || 0}
      


      />

   <InsightOccupancyComp
      wrapperHeading='Conversion'
      cardText1='Booking conversion'
      // cardText2='Returning guests'
      cardText3='Wishlist count'
      rateText1={currentData?.overallConversions}
      // rateText2='0%'
      rateText3={currentData?.wishlist_count}

      />

    </div>

      {/* <div className="listing-page-top-box insight-page-top-box">
        <div className="listing-page-search-box">
          <SearchComp onKeyUp={handleSearch} />
         
        </div>
      </div> */}

      <div className="listing-table insight-table">
        <CardWrapper wrapperClass="listing-table-card insight-table-card">
          <DynamicTable data={data}  hidden={["PLAN NAME_sort"]} itemsPerPage={10}/>
        </CardWrapper>
       
      </div>

      {/* <FooterComponent/> */}
      <HostFooterComp/>

    </div>

 
     <div className="edit-booking-preference-popup">
        {customDatePop && (
          <WrapperPopup
          fluid={true}
          headingText='Select Dates'
          defaultSize={"0.90"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
          closeModal={setCustomDatePop}
          comp={
         <CustomInsightDatePop
         closeModal={setCustomDatePop}
         updateDateRange={updateDateRange}
         defaultFromDate={defaultFromDate}
        defaultToDate={defaultToDate}
         />
        }
        />
        
        )}
      </div>
    </>
  );
};

export default InsightPage;
