import React, { useState } from "react";
import { Controller, useForm, watch } from "react-hook-form";
import TextareaComp from "../../../Components/common/textArea";
import { useNavigate } from "react-router-dom";
import { apiMainUser, checkErrors } from "../../../utils/constant";
import APIHit from "../../../utils/apiHit";
import { useDispatch } from "react-redux";
import { setRulesData } from "../../../utils/redux/listingTypeSlice";
import Save_ExitComp from "../../../Components/common/save_ExitComp";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { useEffect } from "react";
import CKEditorWrapper from "../../../Components/common/CkEditorComp";

const Rules_disc = () => {
  // const [rulesDiscData, setRulesDiscData] = useState("");
  const [showerror, setError] = useState(null);
  const [rulesDiscData, setRulesDiscData] = useState({});
  

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storeSel = useSelector((state) => state);
  const inputData = storeSel?.listingDataSlie.rulesFinalData;
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch 
  } = useForm({
    defaultValues: {
      rules:
        inputData && inputData?.metadata_value !== "null"
          ? inputData?.metadata_value
          : "",
    },
  });

  // const propertyId = sessionStorage.getItem("property");
  const [api, setApi] = useState(["createProperty_metadata_bulk"]);
  const [method, setMethod] = useState("");

  const Currenturl = window.location.href;
  console.log(Currenturl, "tyt");
  const CurrentPage = new URL(Currenturl);
  const path = CurrentPage.pathname;
  console.log(path, "qqq");

  //
  // console.log(indexStore, "tii");
  const whatHappensOnSubmit = () => {
    let data = watch();
    let finaldata = {
      // property_id: propertyId,
      metadata_key: "rules",
      metadata_value: data.rules,
    };
    // console.log();
    // setRulesDiscData(finaldata);
    dispatch(setRulesData(finaldata));
  }

  const whereToGoOnNext = ()=>{
    navigate("/host/createlisting/bookingPreference");
  }

  const whatHappensOnSaveAndExit = ()=>{
    whatHappensOnSubmit();
  }

  const onSubmit = (data) => {
    whatHappensOnSubmit(data);
    whereToGoOnNext();    
  };

  useEffect(() => {
    const PropIddata = storeSel.apiDataSlice?.propId;
    const preferenceIddata = storeSel.apiDataSlice?.preferenceId;

    let updatedApi = ["updateProperty/" + PropIddata, ...api];

    if (preferenceIddata) {
      updatedApi = [...updatedApi, "updatePropertyPref/" + preferenceIddata];
      setMethod("patch");
    } else {
      updatedApi = [...updatedApi, "add_preference"];
      setMethod("post");
    }

    setApi(updatedApi);
  }, []);
  console.log(api, "tres");

  return (
    <div className="rules-disc-box place-title-disc-box">
      <div className="create-list-place-title-disc-content create-list-place-title-disc-content">
        <h2>Set rules and policies for your guests</h2>
        <p>
          Create a comfortable and safe stay experience for your guests by
          setting clear guidelines and expectations.
        </p>
        <form className="rules-disc-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="single-input-box">
            <Controller
              control={control}
              name="rules"
              rules={{
                required: "rules is required",
              }}
              render={({ field: { onChange, value } }) => (
              
                
                <CKEditorWrapper value={value} onChange={onChange} />
              )}
            />
            {errors.rules && <span className="error-text">{errors.rules.message}</span>}
          </div>

          <div className="bottom-btns-fixed-box">
            <Save_ExitComp
              // apis={["createProperty_metadata_bulk", "add_preference"]}
              rulesDiscData={rulesDiscData}
              method={method}
              apis={api}
              path={path}
              firstFunction={whatHappensOnSaveAndExit}
            />
            <button className="common-next-btn">
              Next
            </button>
          </div>
        </form>
      </div>
      {/* <APIHit
        handleSubmit={handleSubmit(onSubmit)}
        url={apiMainUser + "create_property_metadata"}
        apidata={rulesDiscData}
        successResponseCode={201}
        onSuccess={handleSuccess}
        onFailure={handleFailure}
        precondition={preCondition}
        method="post"
        // auth={true}
      > */}

      {/* </APIHit> */}
    </div>
  );
};

export default Rules_disc;
