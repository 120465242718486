
import React from "react";

const AmenitiesPopup = (props) => {
  const amenities = props.list;

  // Group amenities by category_type
  const amenitiesByCategory = {};
  amenities.forEach((amenity) => {
    const categoryType = amenity.amenities_category_details[0].category_type;
    if (!amenitiesByCategory[categoryType]) {
      amenitiesByCategory[categoryType] = [];
    }
    amenitiesByCategory[categoryType].push(amenity);
  });

  return (
    <div className="paddingCustom2AllSide">
      <div className="amenities-detail-popup ">

      {Object.entries(amenitiesByCategory).map(([categoryType, amenities]) => (
        <div key={categoryType} style={{marginBottom:'3rem'}}>
          <h4 className="category-type-text">{categoryType}</h4>
          {amenities.map((amenity) => (
            <div className="amenities-list" key={amenity.uid}>
              <div className="amenities-icon-box">
                <img src={amenity?.icon_url} alt="" />
              </div>
              <p>{amenity?.amenity_name}</p>
            </div>
          ))}
        </div>
      ))}
      </div>
    </div>
  );
};

export default AmenitiesPopup;

