import React, { useEffect } from 'react'
import CardWrapper from '../cardWrapper'

const RightMessagePostCard = (props) => {
    const nightText = props.Night > 1 ? 'nights' : 'night';
    const adultText = props.adultsCount > 1 ? 'Adults' : 'Adult';

    const infantText = props.infantCount > 1 ? 'infants' : 'infant';

 
  return (
    <CardWrapper  onClick={props.onClick} wrapperClass="right-message-post-card pointer">
    <div className='right-message-post-top-section'>

    <div className='right-message-post-top-text-box w-75'>
        {/* <p style={{fontSize:'16px',fontWeight:'500',color:'#707980'}}>Post Booking</p> */}
        <p className='right-message-post-property-name'>{props.propertyName}</p>
        <p className='night-text'>{props.Night} {nightText}</p>
    </div>
    <div className='right-message-post-img-box'>
        <img src={props.propertImg} alt="" />
    </div>

    </div>



    {/*  */}

    <div className='right-message-post-mid-section flex' >
      <div className='right-message-mid-section-box'>
        <p className='right-message-mid-section-first-text'>Guest</p> 
        <p className='right-message-mid-section-second-text'>{props.adultsCount} {adultText}, {props.infantCount} {infantText}</p>
    </div>  
      <div className='right-message-mid-section-box'>
      <p  className='right-message-mid-section-first-text'>Check-in</p> 
      <p   className='right-message-mid-section-second-text'>{props.checkIn}</p>
     </div>  
      <div className='right-message-mid-section-box'>
      <p  className='right-message-mid-section-first-text'>Check-out</p> 
      <p  className='right-message-mid-section-second-text'>{props.checkOut}</p>
     </div>  


    </div>

    {/*  */}
    {props.confirmationCode ? 
    <div className='right-message-post-confirmation-code-section flex'>
      <p className='confirmation-code-text'>Confiramtion Code</p>  
      <p className='confirmation-code-value'>{props.confirmationCode}</p>
    </div>
    : ''}


    </CardWrapper>
  )
}

export default RightMessagePostCard
