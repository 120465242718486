import React from "react";
import { useSelector } from "react-redux";
import SkeletonLoader from "./SkeletonLoader";

const GuestProfile = (props) => {
  // const storeSel = useSelector((state) => state);

  // const fullNameData = storeSel.hostDetailSlice?.full_Name;

  const isJPEGorPNG = (filename) => /\.(jpg|jpeg|png|svg)$/i.test(filename);

  const shouldRenderImage = props.profileImage && isJPEGorPNG(props.profileImage);



  return (
    <>
      <div className="resrvation-profile-box">
    
      <div className="guest-profile-img-box">
          {shouldRenderImage ? (
            <img src={props.profileImage} alt="" />
          ) : (
            <img src="./assets/img/userIconImg.jpg" alt="" />
          )}
        </div>



        <div>
          
          <p className="profile-name">{props.profileName}</p>
          <p className="whose-profile">{props.whosProfile}</p>
        </div>
      </div>
    </>
  );
};

export default GuestProfile;
