import React, { useEffect, useState } from 'react'
import Button from '../../Components/common/Button'
import CardWrapper from '../../Components/common/cardWrapper';
import DynamicTable from '../../Components/common/HostTables';
import { Link, NavLink } from 'react-router-dom';
import { apiMainUser } from '../../utils/constant';
import axios from 'axios';
import moment from "moment";
import SkeletonTable from '../../MobSkeleton/SkeletonTable';
import NoDataLottie from '../../Components/common/NoDataLottie';
import FooterComponent from '../../Pages/common/FooterComponent';
import HostFooterComp from '../../Pages/common/HostFooterComp';




const AllReservationComp = () => {

  
  const [successdata, setSuccessData] = useState([]);
  const [sortingOption, setSortingOption] = useState("upcoming");
  const [activeSortingOption, setActiveSortingOption] = useState("upcoming");

  
  const fetchDatapProperty = async () => {
    try {
      let params = {};
      if (sortingOption && sortingOption !== "all") {
        params.filter = sortingOption;
      }
  
      const response = await axios.get(apiMainUser + "read_booking_for_host_property", {
        params,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
  
      if (response.data.status === 'success') {
        if (Array.isArray(response.data.data)) {
          setSuccessData(response.data.data);
        } else {
          setSuccessData([]);
        }
      } else {
        setSuccessData([]);
      }
    } catch (error) {
      setSuccessData([]);
    }
  };
  
      useEffect(() => {
        fetchDatapProperty();
        
      }, [sortingOption]);

  

      useEffect(() => {
        handleSortingOptionClick();
      }, []);

    const handleSortingOptionClick = (option = "upcoming") => {
      setActiveSortingOption(option);
      setSortingOption(option);
    };


    const isOptionActive = (option) => {
      return sortingOption === option ? "active-sort" : "";
    };


    const Alldata = successdata?.map(item => ({

    Status: item.transaction_details[0]?.payment_status || "N/A",
    Guests:<>
    <div className='guest-detail-text'>
    <NavLink to='/userprofile'  className='guest-name'>{item.fname} {item.lname}</NavLink>
    
      <p className='guest-count'>{item.transaction_details[0]?.guest_count} Adults</p>
    </div>
    </>,
    
    "check-in":moment(item.checkin_date).format('D MMM YYYY'),

   
    "Check-out": moment(item.checkout_date).format('D MMM YYYY'),
    "Confirmation Code": item.booking_order_id || "N/A",
    Listing: <>
    <div style={{width:"150px"}} className='all-reservation-property-name'>
      { item.property_name || "N/A"}
    </div>
    </>,
    "Total Payouts": '₹' + (
      item.transaction_details[0]?.price_detail[
        (item.transaction_details[0]?.price_detail).length - 1
      ]?.total?.total_host_payout_property || "N/A"
    ).toFixed(2), 
    "": <NavLink to={`${item.booking_order_id}`} className='reservation-detail-btn'>details</NavLink>,
  }));


  return (
    <div className='all-reservation-div reserve-content-box'>
      
         <h1>Reservations</h1>

         <div className="reserve-filter-btn-box-parent">

         <div className="reserve-filter-btn-box">
         <Button btnText="All"
        onClick={() => handleSortingOptionClick("all")}
        btnclasses={`reserve-filter-btn ${isOptionActive("all")}`}
        
        />
         <Button btnText="Upcoming" 
       onClick={() => handleSortingOptionClick("upcoming")}
       btnclasses={`reserve-filter-btn ${isOptionActive("upcoming")}`}
       
       />
       
        <Button btnText="Ongoing" 
        onClick={() => handleSortingOptionClick("ongoing")}
        btnclasses={`reserve-filter-btn ${isOptionActive("ongoing")}`}
        
        />
        <Button btnText="Arriving Soon" 
        onClick={() => handleSortingOptionClick("arriving")}
        btnclasses={`reserve-filter-btn ${isOptionActive("arriving")}`}
        
        />
         <Button btnText="Passed"
        onClick={() => handleSortingOptionClick("passed")}
        btnclasses={`reserve-filter-btn ${isOptionActive("passed")}`}
        
        />
       
      

      </div>

      </div>

      <CardWrapper wrapperClass="listing-table-card reservation-table-card">
        {successdata ? (
          Array.isArray(Alldata) && Alldata.length > 0 ? (
            <DynamicTable data={Alldata} hidden={[]} itemsPerPage={10} />
          ) : (
            <>
            <NoDataLottie jsonLotty='./assets/lottie/tripnotFound.json' height="200px" width="300px"/>
          
        
             </>
          )
        ) : (
          <SkeletonTable />
        )}
      </CardWrapper>

      {/* <FooterComponent/> */}
      <HostFooterComp/>

    </div>
  )
}

export default AllReservationComp
