import React, { useEffect } from "react";
import GuestProfile from "../../Components/common/guestProfile";
import CardWrapper from "../../Components/common/cardWrapper";
import Button from "../../Components/common/Button";
import { Swiper, SwiperSlide } from "swiper/react";


import SwiperCore, { Navigation, Pagination, Scrollbar} from 'swiper';

import "swiper/swiper-bundle.css";
import ReviewCard from "../../Components/HostComponents/reviewCard";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { apiMainUser, apiPublicUser } from "../../utils/constant";
import axios from "axios";
import { useSelector } from "react-redux";
import { useState } from "react";
import SkeletonLoader from "../../Components/common/SkeletonLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faStar } from "@fortawesome/free-solid-svg-icons";
import UserReviewCard from "../../Components/UserComponents/UserReviewCard";
import moment from "moment";

SwiperCore.use([Navigation, Pagination, Scrollbar]);
const HostProfilePage = () => {

  const navigate = useNavigate()

  const [listing,setListing]= useState([])

  const [hostDetails,setHostDetails]=useState()
  const [review,setReview]= useState([])
  const [ratingAverage,setRatingAverage]= useState([])

  // const [review,setReview]= useState([])

  const { hostId } = useParams();
  const isSmallScreen = window.innerWidth <= 500;

  console.log(hostId,'checkHostId')

  const [hostDetailsData,setHostDetailsData] = useState()
  useEffect(() => {
    setHostDetailsData(review?.host?.reviews[0]);
  }, [review]);

  const formattedDateDecimal = moment().diff(moment(hostDetails?.createdon),'years',true);
  var integerPart = Math.floor(formattedDateDecimal);
       var formattedDateDiff = Math.floor((formattedDateDecimal - integerPart) * 10);
       var formattedDate;
       if (formattedDateDiff > 0) {
         formattedDate = integerPart + 0.1;
     } else {
         formattedDate = integerPart;
     }


  const fetchHostReviewData = async () => {
   
    // API call
    try {
      const response = await axios
        .get(apiPublicUser + `reviews-ratings/user/${hostId}` ,{
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
       
          if(response.data.status === 'success'){
            console.log(response?.data?.data,'checkResponseOnce')
            setReview(response?.data?.data)
            setRatingAverage(response?.data?.data?.user?.total_rating)
           }
       
          
        });
    } catch (error) {
      console.error(error);
    }
  };

 
  const fetchHostListings = async () => {
   
    // API call
    try {
      const response =  await axios.get(`${apiPublicUser}search_property?host_id=${hostId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
       if(response.data.status === 'success'){
        console.log('hey')
        console.log(response?.data?.data,'checkResponseOnce')
        setListing(response?.data?.data)
        setHostDetails(response?.data?.data?.[0]?.host_details)
       }
         
       
          
        });
    } catch (error) {
      console.error(error);
    }
  };

 console.log(hostDetails,'checkListing')

  useEffect(() => {
    
      console.log('yes called')
      fetchHostReviewData() 
      fetchHostListings();


  }, [hostId]);

  console.log(review,'checkOnceRes')



  const navigateTo =()=>{
    navigate('/hostcreateprofileofile')
  }
  console.log(hostDetailsData,'checkDetail')

  

  return (
    <div className="host-profile-page-div">
  
     
     <div className="host-profile-page-left-div">
     <div className="host-profile-left-child-div">
      <CardWrapper wrapperClass='host-profile-left-card'>
        
       {hostDetailsData && (
        <div>
        <GuestProfile
        profileImage={hostDetailsData?.host_image}
          whose-profile='host'
        />
        
        </div>
      )}

       <div className="host-profile-rating-div w-100">

 {review ?
      <p className="host-profile-rating-text">
        <span className="rating-number-text">{review?.host?.review_count !== 0  ? review?.host?.review_count : 0}</span>
        <span className="rating-text">Reviews</span>
      </p>
: 
<SkeletonLoader width={100} height={20}/>
 }
      {ratingAverage ? 
      <p className="host-profile-rating-text">
        <span  className="rating-number-text">{ratingAverage?.Overall_Average ? ratingAverage?.Overall_Average : '0'}</span>
        <span className="rating-text">Rating</span>
      </p>
     : <SkeletonLoader width={100} height={20}/>
}
      <p className="host-profile-rating-text host-profile-rating--last-text">
        <span  className="rating-number-text">{formattedDate}</span>
        <span className="rating-text ">Year hosting</span>
      </p>

       </div>
      </CardWrapper>
       
       <div className="host-confirm-info-box">
         <h2>Host Confirmed information</h2>
          
         <div className="host-confirmed-button-box">
          <div className="host-btn-div">
            <p className="host-confirmed-identity-text">Identity</p>
            <FontAwesomeIcon icon={faCircleCheck} className="verify-icon-class"/>
          </div>
          <div className="host-btn-div">
            <p className="host-confirmed-identity-text">Email</p>
            {hostDetails?.email_verified !== 1 ?
            <Button
             btnText='Not Verified' btnclasses='save-edit-btn not-verify-btn'/>

             : 
            <FontAwesomeIcon icon={faCircleCheck} className="verify-icon-class"/>
            }
          </div>
          <div className="host-btn-div">
            <p className="host-confirmed-identity-text"> Phone</p>
            {hostDetails?.mobile_verified !== 1 ? 
            <Button 
            // onClick={handleVerify}
              btnText='Not Verified' btnclasses='save-edit-btn not-verify-btn'/>
            : 
            <FontAwesomeIcon icon={faCircleCheck} className="verify-icon-class"/>
            
            }
          </div>

         </div>
         <p className="host-confirm-info-text">By confirming account information, we can enhance the safety and protection of every member in the Jumbo community.</p>
       </div>
       </div>
     </div>

     
     <div className="host-profile-page-right-div">
      <div className="create-profile-box">
        <div className="create-profile-text-div">
       <h2 className="main-heading">Hi, I'm {hostDetailsData?.host_fname} {hostDetailsData?.host_fname}</h2>

       </div>

      </div>

      <h2 className="main-heading">Host Reviews</h2>


      <div className="review-main-parent-div">
  {
    review.host?.review_count > 0 ? (
      <Swiper
      spaceBetween={16}
      slidesPerView={isSmallScreen ? 1 : 3}
      navigation
      pagination={{ clickable: true }}
      >
        {review?.host?.reviews?.map((review, index) => (
          <SwiperSlide key={index}>
            {/* <ReviewCard
              reviewName={review?.user_fname + review?.user_lname}
              reviewDate="5 April 2023"
              src={review?.user_image !== null ? review?.user_image: './assets/img/notFoundImage.png'}
              reveiwText={review.review ? review.review : ''}
            /> */}

            <UserReviewCard
            // rating={review.average_rating}
            userReviewText={review.review ? review.review : ''}
            profileName={review?.user_fname + review?.user_lname}
            profileImage={ review?.user_image }
            
            
            />
          </SwiperSlide>
        ))}
      </Swiper>
    ) : (
      <p>No reviews yet</p>
    )
}
  
</div>


<h2 className="main-heading">Guest Reviews</h2>


<div className="review-main-parent-div">
  {review ? (
    review.user?.review_count > 0 ? (
      <Swiper
          spaceBetween={16}
          slidesPerView={isSmallScreen ? 1 : 3}
          navigation
          pagination={{ clickable: true }}
    >
    {review?.user?.reviews?.map((review, index) => (
    <SwiperSlide key={index}>
     
         <UserReviewCard
            // rating={review.average_rating}
            userReviewText={review.review ? review.review : ''}
            profileName={review?.user_fname + review?.user_lname}
            profileImage={review?.user_image}
            
            
            />

           </SwiperSlide>
        ))}
         </Swiper>
       ) : (
        <p>No reviews yet</p>
       )
     ) : (
      <SkeletonLoader width={240} height={100}/>
      )}
      </div> 




  <h2 className="main-heading">Host Listing's</h2>
  <div className="host-listing-div"> 
  {listing ? (
    <Swiper
    spaceBetween={16}
    slidesPerView={isSmallScreen ? 1 : 3}
    navigation
    pagination={{ clickable: true }}
    >
      {listing?.map((slide) => (
        <SwiperSlide key={slide.id}>
           <Link to={`/properties/${slide.city}/${slide.slug}`}>
           <div className='review-card'>
            <div className="host-list-img-box">
              <img src={slide?.base_url_image + slide?.images[0]} alt="no-img" />
            </div>
            <div className="review-card-text-box">
              <div style={{display:'flex',gap:'2.6rem',marginBottom:'0.7rem'}}>
                <div className="w-76">
                <p className="title">{slide?.property_name}</p>
                <p className="sub-tilte-city">{slide?.city},{slide?.state}</p>
                </div>
                <div className="w-25">
                
                {(slide.review_count && slide.Overall_rating) !== null && (slide.review_count && slide.Overall_rating) !== undefined && (slide.review_count && slide.Overall_rating) > 0 &&
            <div  className="flex" style={{flexDirection:'column'}} >   
            <p>
              <span className="star-num-trending-card bold" style={{fontSize:'1.3rem'}}>{slide?.Overall_rating.toFixed(1)}</span>
              <span className="pd-px-3">
                <FontAwesomeIcon
                icon={faStar}
                // size='2x'
                fontSize={"14px"}
                color="#FFC700"
              />
              </span>
            </p>
            <span style={{ fontSize: "1.1rem", fontWeight: "500" }}>
              {slide.review_count} reviews
            </span>
          </div> 
          

          }
          </div>
              </div>
             
            </div>
          </div>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  ) : (
    <SkeletonLoader width={240} height={100} />
  )}
</div>

   
    </div>

    </div>

  );
};

export default HostProfilePage;
