import React from 'react';
import { useForm } from 'react-hook-form';
import HeadingComp from '../EditListing/HeadingComp';

const BankDetailsPop = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
  } = useForm();

  const accountNumber = watch('accountNumber');

  const onSubmit = (data) => {
    console.log(data);
  };

  const validateReEnteredAccount = (value) => {
    if (value !== accountNumber) {
      setError('reEnterAccountNumber', {
        type: 'manual',
        message: 'Account numbers do not match',
      });
      return false;
    }
    return true;
  };

  return (
    <div className='bank-details-pop'>
        <HeadingComp headingText='Bank Details' />
      <form className='bank-details-pop-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='bank-details-pop-input-div'>
          <label>Account Number</label>
          <input
            type="text"
            {...register('accountNumber', { required: true })}
          />
          {errors.accountNumber && <span>This field is required</span>}
        </div>

        <div className='bank-details-pop-input-div' >
          <label>Re-enter Account Number</label>
          <input
            type="text"
            {...register('reEnterAccountNumber', {
              required: true,
              validate: validateReEnteredAccount,
            })}
          />
          {errors.reEnterAccountNumber && (
            <span>{errors.reEnterAccountNumber.message}</span>
          )}
        </div>

        <div className='bank-details-pop-input-div'>
          <label>IFSC Code</label>
          <input
            type="text"
            {...register('ifscCode', { required: true })}
          />
          {errors.ifscCode && <span>This field is required</span>}
        </div>

        <div className='bank-details-pop-input-div'>
          <label>Account Holder's Name</label>
          <input
            type="text"
            {...register('accountHolderName', { required: true })}
          />
          {errors.accountHolderName && <span>This field is required</span>}
        </div>

          <div className="clear_apply-btn-box paddingCustomAllSide">
              <p onClick={() => props.closeModal(false)} className="clear-btn">
                Cancel
              </p>
              <button className="save-edit-btn" type="submit">
                Apply
              </button>
            </div>
      </form>
    </div>
  );
};

export default BankDetailsPop;
