import React from 'react'
import CardWrapper from '../../common/cardWrapper'

const InsightOccupancyComp = (props) => {
  return (
    <CardWrapper wrapperHeading={props.wrapperHeading} wrapperClass='insight-occupancy-card'>
     <div>
      <p className='occupancy-card-rate-text'>{props.rateText1}</p>
      <p className='occupancy-card-text'>{props.cardText1}</p>
       </div>
       <div>
       <p className='occupancy-card-rate-text'>{props.rateText2}</p>
       <p className='occupancy-card-text'>{props.cardText2}</p>
    </div>
        <div>
        <p className='occupancy-card-rate-text'>{props.rateText3}</p>
        <p className='occupancy-card-text'>{props.cardText3}</p>
        </div>
    </CardWrapper>
  )
}

export default InsightOccupancyComp
