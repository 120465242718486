import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  open: false,
  message: '',
  severity: 'info',
};

const SnackbarSlicer = createSlice({
  name: "SnackbarSlicer",
  initialState,

  reducers: {
    openSnackbar: (state, action) => {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity || 'info';
    },
    closeSnackbar: (state) => {
      state.open = false;
      state.message = '';
      state.severity = 'info';
    },
  },
});

export const { openSnackbar, closeSnackbar } = SnackbarSlicer.actions;
export const SnackbarReducer = SnackbarSlicer.reducer;
