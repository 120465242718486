import axios from "axios";
import { apiMainUser } from "../../../utils/constant";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../utils/redux/SnackBarSlicer";

const EditSaveBtn = (props) => {
  useEffect(() => {
    console.log(props.postData, "clicked");
  }, [props.postData]);


  const dispatch = useDispatch()

  const handleClick = async () => {
    console.log(props, "clicked");
    if (props.postData !== null) {
      try {
        const resPage = await axios({
          method: props.method,
          url: apiMainUser + props.url,
          data: props.postData,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (resPage.data.status === "success") {
          props.closeModal(false);
          dispatch(openSnackbar({ message: props.message, severity: 'success' }));

          props.fetchData();


          // window.location.reload();
        }
        // console.log(resPage.data.status, "ramya");

        console.log(resPage.data);
      } catch (error) {
        console.log(error);
        dispatch(openSnackbar({ message: 'Something went wrong', severity: 'error' }));

      }
    }
  };

  return (
    <button className="save-edit-btn" onClick={handleClick}>
      Save
    </button>
  );
};

export default EditSaveBtn;
