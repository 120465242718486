
   import { useNavigate } from "react-router-dom";
  import UploadImgComp from "../../../Components/common/UploadImgComp";
  import ViewImageComp from "../../../Components/common/ViewImageComp";
  import { useSelector } from "react-redux";
  import Save_ExitComp from "../../../Components/common/save_ExitComp";
  import { useEffect, useState } from "react";
  const DragDropBox = (props) => {

        const storeSel = useSelector((state) => state);
        const successImages = storeSel?.imageSlice?.selectedImages;
        const failedImages = storeSel?.imageSlice?.failedImages;
        const PropIddata = storeSel.apiDataSlice?.propId;

        const [api, setApi] = useState(["createProperty_metadata_bulk"]);
        const [method, setMethod] = useState("");

        console.log(successImages,failedImages,'allCompcalled')

        const Currenturl = window.location.href;
        console.log(Currenturl, "tyt");
        const CurrentPage = new URL(Currenturl);
        const path = CurrentPage.pathname;
        console.log(path, "qqq");

        useEffect(() => {
          const PropIddata = storeSel.apiDataSlice?.propId;
          const preferenceIddata = storeSel.apiDataSlice?.preferenceId;
      
          let updatedApi = ["updateProperty/" + PropIddata, ...api];
      
          if (preferenceIddata) {
            updatedApi = [...updatedApi, "updatePropertyPref/" + preferenceIddata];
            setMethod("patch");
          } else {
            updatedApi = [...updatedApi, "add_preference"];
            setMethod("post");
          }
      
          setApi(updatedApi);
        }, []);
      

          const navigate = useNavigate()

            const navigateFunc = () => {
              if(successImages.length > 0){
                navigate("/host/createlisting/discription");
              }

            };
         
        
        

          return (
            <div className="listing-amenities-box create-list-property-type">
          <div style={{margin: '0 auto'}} className=" create-list-amenities-content">
          <h2 className="listing-amenities-heading-text">
           Showcase Your Place in All Its Glory  
           </h2>
          <p className="listing-amenities-para-text">
          Add stunning images of your property to captivate potential guests and boost your bookings!
          </p>



              {successImages.length > 0 || failedImages.length > 0 ? (
                <ViewImageComp  
                propId={PropIddata}
                  successImages={successImages}
                  failedImages={failedImages}
                />
              ) :       <UploadImgComp/>
            }


 
             <div className="bottom-btns-fixed-box">
            <Save_ExitComp
            // apis={["createProperty_metadata_bulk", "add_preference"]}
             method={method}
             apis={api}
             path={path}
           />
           <button onClick={navigateFunc} type="submit" className="common-next-btn">
              Next
            </button>
            </div>
          </div>
          </div>
            );
          };

       export default DragDropBox;