import { createSlice, configureStore } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  commonIndex: null,
  addCartIndex: null,
  pricingCartIndex: null,
  imageIndex: null,
};

const commonIndexSlice = createSlice({
  name: "commonIndexSlice",
  initialState,
  reducers: {
    setCommonIndex: (state, action) => {
      state.commonIndex = action.payload;
    },
    setCartCommonIndex: (state, action) => {
      state.addCartIndex = action.payload;
    },
    setPricingCommonIndex: (state, action) => {
      state.pricingCartIndex = action.payload;
    },
    setImageCommonIndex: (state, action) => {
      state.imageIndex = action.payload;
    },
  },
});

export const {
  setCommonIndex,
  setCartCommonIndex,
  setPricingCommonIndex,
  setImageCommonIndex,
} = commonIndexSlice.actions;
export const indexDataReducer = commonIndexSlice.reducer;
