import React from "react";
import SwitchExample from "../../../Components/common/switch";
import EditCheckinTime from "../EditListing/Policy_Rules/EditCheckinTime";
import WrapperPopup from "../../../Components/common/wrapperPopup";
import { useState } from "react";
import EditCheckoutTime from "../EditListing/Policy_Rules/editCheckoutTime";
import EditRules from "../EditListing/ListingDetail/EditRules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import EditCancellation from "../EditListing/Policy_Rules/EditCancellation";
import { all } from "axios";

const Policies_rulesEditComp = (props) => {
  const [openModalCheckinTime, setOpenModalCheckinTime] = useState(false);
  const [openModalCheckoutTime, setOpenModalCheckoutTime] = useState(false);
  const [openModalCancelation, setOpenModalCancelation] = useState(false);

  const [openModalRules, setOpenModalRules] = useState(false);

  const allData = props.listingData?.data[0];
  console.log(allData,'checkNew')

  console.log(allData?.cancellation_category_detail?.[0],'checkAllResponseData')

  const rulesText = allData?.rules;


  const truncatedRulesDesText = rulesText && rulesText.length > 60
  ? `${rulesText.slice(0, 60)}...`
  : rulesText;


  const preferencePropId = allData?.perference_id;

  const Check_inAm_pm = allData?.check_in?.split(':')[0]

  console.log(Check_inAm_pm,'timeCheck')

  console.log(allData?.check_in,'checkTime',Check_inAm_pm)

 

 
  const Check_outAm_pm = allData?.check_out?.split(':')[0]

  console.log(Check_inAm_pm,Check_outAm_pm,'time' )


  


  const toggleVisibleDocks = () => {
    setOpenModalCheckinTime(false)
    setOpenModalCheckoutTime(false)
    setOpenModalRules(false)
    setOpenModalCancelation(false)

  };

  const propId = allData?.propertyid;
  return (
    <div className="pricing-detail-edit-content  listing-detail-edit-content">
      <div className="pricing-edit-first-main-div">
        <div className="text-edit-div-box">
          <h2 className="title-text">Cancellation Policy</h2>
          <p className="para-text">
          {allData?.cancellation_category_detail?.map((option) => {
            console.log(option,'checkResOp')
            return(
              
          <div>
        <div className='cancellation-category-text'>{option?.name}</div>
        <div className='cancellation-category-message-text'>{option.description}</div>
        {option.cancellation_slabs_details?.map((slab) => (
            <div key={slab.uid} className='request-cancellation-slab-info'>
            {slab.refund_percent}% refund {slab.remaining_days} days before the day of check-in
            </div>
        ))}
        
    
         </div>
          )})}
          </p>
        </div>
        <p 
          onClick={() => setOpenModalCancelation(true)}
         style={{ cursor: "pointer" }} className="basic-detail-edit-btn" 
         >
          Edit
        </p>
      </div>
      
      {/*  */}

      <div className="line"></div>
      <div className="edit-basic-details-main-div">
        <h2>House Rules</h2>

        {/*  */}
        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Rules</h3>
            <p className="para-text" dangerouslySetInnerHTML={{ __html: truncatedRulesDesText}}></p>

          </div>
          <p
            onClick={() => setOpenModalRules(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>
        <div className="light-line"></div>

        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Check-in time</h3>
            {
              allData?.check_in ?
            <p className="para-text">After {allData?.check_in} {Check_inAm_pm < 12 ? "AM" : "PM"}</p>
            :<p className="para-text">Not yet added <FontAwesomeIcon icon={faExclamationTriangle} className="yellow svg"/> </p>

            }
           

          </div>
          <p
            onClick={() => setOpenModalCheckinTime(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>
        <div className="light-line"></div>

        {/*  */}

        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Check-out time</h3>
            {allData?.check_out ? 
            <p className="para-text">At {allData?.check_out} {Check_outAm_pm > 12 ? "PM" : "AM"}</p>
               :<p className="para-text">Not yet added <FontAwesomeIcon icon={faExclamationTriangle} className="yellow svg"/> </p>
            }
          </div>
          <p
            onClick={() => setOpenModalCheckoutTime(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>
        <div className="light-line"></div>

        {/*  */}

        {/*  */}
{/* 
        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Smoking</h3>
            <p className="para-text">Allow smoking, vaping, e-cigrate, etc.</p>
          </div>
          <SwitchExample />
        </div> */}

        {/*  */}

        {/*  */}

        {/* <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Videography</h3>
            <p className="para-text">
            Allow commercial photography and filming?
            </p>
          </div>
          <SwitchExample />
        </div> */}

        {/*  */}

        {/*  */}

        {/* <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Quite Hours</h3>
            <p className="para-text ">11:00 PM to 6:00 PM</p>
          </div>
          <p className="basic-detail-edit-btn">Edit</p>
        </div> */}

        {/*  */}
      </div>

      <div className="policy-rules-edit-details-popup">
        {openModalCheckoutTime && (
          <WrapperPopup
          fluid={true}
          defaultSize={"0.50"}
          headingText='Check out Time'
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
            closeModal={setOpenModalCheckoutTime}
            comp={
              <EditCheckoutTime
                propId={propId}
                fetchData={props.fetchData}
                allData={allData}
                closeModal={setOpenModalCheckoutTime}
              />
            }
          />
        )}
      </div>


      <div className="lisitng-title-details-popup lisitng-discription-details-popup">
        {openModalRules && (
          <WrapperPopup
          fluid={true}
          defaultSize={"0.70"}
          headingText='Rules'
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
            closeModal={setOpenModalRules}
            comp={
              <EditRules
                propId={propId}
                rulesText={allData?.rules}
                closeModal={setOpenModalRules}
                fetchData={props.fetchData}
              />
            }
          />
        )}
      </div>

      <div className="policy-rules-edit-details-popup">
        {openModalCheckinTime && (
          <WrapperPopup
          headingText='Check in Time'
          fluid={true}
          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
            closeModal={setOpenModalCheckinTime}
            comp={
              <EditCheckinTime
                allData={allData}
                propId={propId}
                fetchData={props.fetchData}
                closeModal={setOpenModalCheckinTime}
              />
            }
          />
        )}
      </div>


      <div className="cancelation-policy-edit-div">
        {openModalCancelation && (
          <WrapperPopup
          fluid={true}
          headingText='Standard cancellation policy'
          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
          closeModal={setOpenModalCancelation}
            comp={
              <EditCancellation
               
                preferencePropId={preferencePropId}
                fetchData={props.fetchData}
                allData={allData}
                
               closeModal={setOpenModalCancelation}
                
              />
            }
          />
        )}
      </div>




      {/*  */}
    </div>
  );
};

export default Policies_rulesEditComp;
