import React, { useState, useEffect } from 'react';
import AddToCart from '../../Components/common/addCarts';

const ContactAdultCountPop = (props) => {
  const maxGuestValue = props.maxGuests;

  const [adultCount, setAdultCount] = useState(1);
  const [infantCount, setInfantCount] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);

  useEffect(() => {
    props.handleCounts(adultCount, childrenCount, infantCount);
  }, [adultCount, childrenCount, infantCount, props]);



  console.log(adultCount,infantCount,childrenCount,'checkAllCounts')
  const handleUpdateGuests_adults = (count) => {
    setAdultCount(count);
  };

  const handleUpdateGuests_children = (count) => {
    setChildrenCount(count);
  };

  const handleUpdateGuests_infants = (count) => {
    setInfantCount(count);
  };

  return (
    <div className='booking-edit-guest'>
      {props.maxGuestText && 
        <p style={{ fontSize: '12px', marginBottom: '2rem' }}>
          This place allows a maximum of {props.maxGuests} guests, not including infants.
        </p>
      }
      <div className='cart-flex-div'>
        <p>
          ADULT <span>Above 13+</span>
        </p>
        <AddToCart
          cartBtn='cartBtn'
          cartCount='cartCount'
          cartParent='cartParent'
          initialCount={adultCount}
          increamentVal={handleUpdateGuests_adults}
        />
      </div>

      <div className='cart-flex-div'>
        <p>
          CHILDREN <span>age 2 to 12</span>
        </p>
        <AddToCart
          cartBtn='cartBtn'
          cartCount='cartCount'
          cartParent='cartParent'
          initialCount={childrenCount}
          minCount={0}
          increamentVal={handleUpdateGuests_children}
        />
      </div>

      <div className='cart-flex-div'>
        <p>
          INFANTS <span>under 2</span>
        </p>
        <AddToCart
          cartBtn='cartBtn'
          cartCount='cartCount'
          cartParent='cartParent'
          initialCount={infantCount}

          increamentVal={handleUpdateGuests_infants}
        />
      </div>
    </div>
  );
};

export default ContactAdultCountPop;
