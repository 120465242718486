import React, { useContext, useEffect, useState } from "react";
import CardWrapper from "../../../Components/common/cardWrapper";
import { apiMainUser } from "../../../utils/constant";
import APIHit from "../../../utils/apiHit";
import DataContext from "../../../utils/dataContext";
import { useNavigate } from "react-router-dom";
import { setPlaceData } from "../../../utils/redux/listingTypeSlice";
import { useDispatch } from "react-redux";
import ProfileImage from "../../../Components/common/profileImg";
import { handleNavigation } from "../../../Components/common/navigateFunc";
import Save_ExitComp from "../../../Components/common/save_ExitComp";
import { useSelector } from "react-redux";
import { store } from "../../../utils/redux/Store";

const PlaceType = () => {
  // const [data, setData] = useState([]);
  const [successdata, setSuccessData] = useState([]);
  const [finalPlaceData, setFinalPlaceData] = useState({});

  // const [placeData, setPlaceData] = useState([]);
  const [selectedUid, setSelectedUid] = useState(null);
  const [apicall, setApiCall] = useState(false);

  const [clickdata, setClick] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeSel = useSelector((state) => state);

  const [storedReduxData, setStoredReduxData] = useState(null);
  const [storeReduxPropId, setStoreReduxPropId] = useState(null);

  useEffect(() => {
    const data = storeSel.listingDataSlie?.placeFinalData?.metadata_value;
    const PropIddata = storeSel.apiDataSlice?.propId;

    console.log(data, "yoga");
    setStoredReduxData(data);
    setStoreReduxPropId(PropIddata);
  }, []);

  const Currenturl = window.location.href;
  console.log(Currenturl, "tyt");
  const CurrentPage = new URL(Currenturl);
  const path = CurrentPage.pathname;
  console.log(path, "uuu");

  const getdata = (uid) => {
    setStoredReduxData(null);

    setSelectedUid(uid);
    console.log(uid, "ooo");
    let finaldata = {
      // property_id: propertyId,
      metadata_key: "place_type",
      metadata_value: uid,
    };

    dispatch(setPlaceData(finaldata));
  };

  useEffect(() => {
    setApiCall(true);
  }, []);
  const handleSuccess = (response) => {
    // Handle the successful response here
    console.log("Successful response:", response);
    setSuccessData(response.data);
  };

  // const handleSuccess2 = (res) => {
  //   // Handle the successful response here
  //   console.log("Successful response:", res);
  //   if (res.status === "success") {
  //     navigate("/host/createlisting/room");
  //   }
  // };
  const handleFailure = (data) => {
    // Handle the successful response here
    console.log("Failure response:", data);
  };

  // const handleFailure2 = (data) => {
  //   // Handle the successful response here
  //   console.log("Failure response:", data);
  // };

  const runPrecondition = () => {
    // Perform your precondition check here
    // Return true if the precondition is met, or false otherwise
    if (successdata != []) {
      return false;
    }
    return true;
  };

  // const navigateTo = () => {
  //   handleNavigation(
  //     selectedUid,

  //     "/host/createlisting/room",
  //     navigate
  //   );
  // };
  const navigateTo = () => {
    handleNavigation(
      selectedUid,
      // property_uid,
      storedReduxData,
      "/host/createlisting/room",
      navigate
    );
  };

  return (
    <>
      {apicall && (
        <APIHit
          url={apiMainUser + "read_place_type?block=0"}
          method="GET"
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          precondition={runPrecondition}
          successResponseCode={200}
          // apidata={placeData}
          directCall={true}
          auth={true}
        ></APIHit>
      )}
      <div className=" create-list-place-type create-list-property-type">
        <div className="create-list-property-flex-box create-list-property-type-content">
          <h2>Tell Us About Your Place!</h2>
          <div className="create-list-place-flex-box create-list-property-flex-box">
            {successdata?.map((item) => (
              <CardWrapper
                key={item.uid}
                onClick={() => getdata(item.uid)}
                wrapperClass={`create-list-property-type-card${
                  selectedUid === item.uid || storedReduxData === item.uid
                    ? " selected"
                    : ""
                }`}
              >
                <ProfileImage
                  profileImgParent="my-icon-class"
                  src={item.icon_url}
                  className="dndn"
                />
                <p>{item.placetype_name}</p>
              </CardWrapper>
            ))}

            {/* <CardWrapper wrapperClass="create-list-place-type-card">
              <p>Hill Station</p>
            </CardWrapper>
            <CardWrapper wrapperClass="create-list-place-type-card">
              <p>River</p>
            </CardWrapper>
            <CardWrapper wrapperClass="create-list-place-type-card">
              <p>River</p>
            </CardWrapper>
            <CardWrapper wrapperClass="create-list-place-type-card">
              <p>River</p>
            </CardWrapper> */}
          </div>
        </div>
        {/* <APIHit
          url={apiMainUser + "create_property_metadata"}
          method="post"
          onSuccess={handleSuccess2}
          onFailure={handleFailure2}
          apidata={placeData}
          // precondition={runPrecondition2}
          successResponseCode={201}
        > */}
        <div className="bottom-btns-fixed-box">
          <Save_ExitComp
            path={path}
            // apis={["createProperty_metadata_bulk"]}
            apis={[
              "updateProperty/" + storeReduxPropId,
              "createProperty_metadata_bulk",
            ]}
            finalPlaceData={finalPlaceData}
          />
          <button
            onClick={navigateTo}
            type="submit"
            className="common-next-btn"
          >
            Next
          </button>
        </div>
        {/* </APIHit> */}
      </div>
    </>
  );
};

export default PlaceType;
