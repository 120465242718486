import React from 'react'
import CardWrapper from '../../common/cardWrapper'

const IInsightRatingComp = (props) => {
  return (
   <CardWrapper wrapperHeading={props.wrapperHeading} wrapperClass='insight-rating-card'>
     <div className='insight-rating-left-div'>
     <p className='font-small' style={{color:'#A1A1A1'}}>{props.ratingText}</p>
     <p style={{fontSize:"45px",color:"#333",fontWeight:'700'}}>{props.overallRatingNumber}</p>
     <p className='font-small ' style={{color:"#333"}}>{props.overall_review_count} <span style={{color:'#A1A1A1'}}>reviews</span></p>
     </div>
    
     <div className='insight-rating-right-div'>

     <div className='insight-rating-right-subchild-div' style={{marginBottom:'1rem'}}>
       <p className='insight-rating-property-value'>{props.propertyAboveRating}</p>
       <p className='insight-rating-star-para'>{props.propertRateText1} <span>{props.propertRateStar1} <img src="./assets/img/BlueStar.svg" alt="" /></span></p>
    </div> 

       <div className='insight-rating-right-subchild-div'>
       <p className='insight-rating-property-value'>{props.propertyBelowRating}</p>
       <p className='insight-rating-star-para'>{props.propertRateText2} <span>{props.propertRateStar2} <img src="./assets/img/BlueStar.svg" alt="" /></span></p>

       </div>

     </div>
   </CardWrapper>
  )
}

export default IInsightRatingComp
