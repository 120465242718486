
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { apiMainUser, capitalizeText } from '../../../utils/constant';
import Button from '../../../Components/common/Button';
import { openSnackbar } from '../../../utils/redux/SnackBarSlicer';
import { useDispatch } from 'react-redux';


const HostPhoneOtp = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const otpInputs = useRef([]);

  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    const mobileOtpValue = Object.keys(data)
      .sort()
      .map((key) => data[key])
      .join('');

    const newData = {
      mobile_otp: parseInt(mobileOtpValue),
      // mobile: props.numberdata,

      mobile: props.numberdata.replace(`${props.countryCode.dialCode}`, ''), // Remove the country code from the phone number
      country_code: `+${props.countryCode.dialCode}`,
      
    };

    try {
      const response = await axios.post(apiMainUser + 'change_mobile_email', newData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });

      if (response.data.status === 'success') {
        props.fetchData();
        props.closeModal();
        dispatch(openSnackbar({ message: capitalizeText(response.data.message), severity: 'success' }));

      }
    } catch (error) {
      console.error(error);
      dispatch(openSnackbar({ message: 'Something went wrong!', severity: 'error' }));

    }
  };

  const handleInputChange = (index, event) => {
    const value = event.target.value;

    if (value.length === 1 && index < otpInputs.current.length - 1) {
      otpInputs.current[index + 1].focus();
    }

    setValue(`otp${index}`, value); // Set the value in react-hook-form
  };

  return (
    <form className='host-email-otp-div' onSubmit={handleSubmit(onSubmit)}>
      <h2>Verify your mobile number</h2>
      <p>Enter the verification code sent to your mobile</p>
      <div className='otp-input'>
        {[0, 1, 2, 3, 4, 5].map((index) => (
          <input
            key={index}
            {...register(`otp${index}`, { required: true })}
            ref={(el) => (otpInputs.current[index] = el)}
            type="text"
            maxLength={1}
            onChange={(e) => handleInputChange(index, e)}
          />
        ))}
      </div>
      {errors && Object.keys(errors).length > 0 && <span>This field is required.</span>}
      <div className='paddingCustomAllSide'>
       <Button btnText='Submit' btnclasses='save-edit-btn '/>  
       </div>
    </form>
  );
};

export default HostPhoneOtp;
