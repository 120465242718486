
import React from "react";
import EditPricing from "../EditListing/PricingAvailabilty/EditPricing";
import { useState } from "react";
import WrapperPopup from "../../../Components/common/wrapperPopup";
import EditAvailWindow from "../EditListing/PricingAvailabilty/EditAvailWindow";
import EditAdvanceWindow from "../EditListing/PricingAvailabilty/EditAdvanceWindow";
import EditMaxStay from "../EditListing/PricingAvailabilty/EditMaxStay";
import EditMinStay from "../EditListing/PricingAvailabilty/EditMinStay";
import EditExtraGuest from "../EditListing/PricingAvailabilty/EditExtraGuest";
import EditPetFee from "../EditListing/PricingAvailabilty/EditAddonComponent";
import EditCleanFee from "../EditListing/PricingAvailabilty/EditCleanFee";
import { apiMainUser, apiPublicUser, formatIndianRupeesFunc } from "../../../utils/constant";
import { useEffect } from "react";
import axios from "axios";
import SwitchExample from "../../../Components/common/switch";
import EditAddonComponent from "../EditListing/PricingAvailabilty/EditAddonComponent";
import EditCalendarSync from "../EditListing/PricingAvailabilty/EditCalendarSync";

const PricingDetailEditComponent = (props) => {
  const [addonStates, setAddonStates] = useState({});
  const [addonData, setAddonData] = useState([]);

  const [selectedAddonStatus, setSelectedAddonStatus] = useState(false);

  const [switchStates, setSwitchStates] = useState([]);

  const [showEditText, setShowEditText] = useState(false);
  const [selectedAddonId, setSelectedAddonId] = useState(null);
  const [selectedAddonData, setSelectedAddonData] = useState(null);
  const [selectedAddonName, setSelectedAddonName] = useState(null);
  const [selectedAddonDiscription, setSelectedDescription] = useState(null);



  
  const [quantifiable,setQuantifiable] = useState(null)


console.log(switchStates,'jjm')

const handleChange = (checkstate, index, propaddon_uid) => {
  if (propaddon_uid) {
    setSelectedAddonStatus(false);

    const yourApiEndpoint = apiMainUser + `toggle_property_addon/${propaddon_uid}`;
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: "Bearer " + token,
    };

    axios
      .post(yourApiEndpoint, null, { headers })
      .then((response) => {
        // Assuming fetchData is defined and works correctly
        props.fetchData();
      })
      .catch((error) => {
        console.error("Error toggling property addon: ", error);
      });
  } else {
    setSwitchStates((prevData) => {
      const updatedData = [...prevData];
      updatedData[index].allowedstate = !checkstate;
      return updatedData;
    });
  }
};



  const handleEditClick = (addonId,addonName,discription,quantify) => {
    setSelectedAddonData(addonId);
    setShowEditText(true);
    setSelectedAddonName(addonName)
    setSelectedDescription(discription)
    setQuantifiable(quantify)
    console.log(quantify,'qty')

  };
  console.log(selectedAddonId,'pp')
  

  const [openModalPricing, setOpenModalPricing] = useState(false);
  const [openModalExtraGuest, setOpenModalExtraGuest] = useState(false);
  const [openModalAvailWindow, setOpenModalAvailWindow] = useState(false);
  const [openModalAdvanceWindow, setOpenModalAdvanceWindow] = useState(false);
  const [openModalMaxStay, setOpenModalMaxStay] = useState(false);
  const [openModalMinStay, setOpenModalMinStay] = useState(false);
  const [openCalendarSync, setOpenCalendarSync] = useState(false);


  const allData = props.listingData?.data[0];
  const AddonAll = allData?.addons_details

  
  const propId = allData?.propertyid;
  const preferencePropId = allData?.perference_id;
  const basePriceText = allData?.base_price;
  const weekendPriceText = allData?.weekend_price;


  const max_guestsText = allData?.extra_guests;
  const extraguestPrice = allData?.extra_guests_price;

  console.log(allData,'kkgextraaa')

  
    const [price,setPrice]  = useState({});
    const updatedPrice = {};

  const fetchDataAddon = async () => {
    const AddonAllin = allData?.addons_details
    console.log(AddonAllin,'checking')


    try {
      const response = await axios.get(apiMainUser + "getAddons_property", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
     
      const addonSwitchStates = [];
      // console.log(response.data.data,'ttm') data made by admin
      const addonUidsFromResponse = response.data.data.map((addon) => addon);
      console.log(addonUidsFromResponse,'ttm')
      //AddonAll is data from property addons
      addonUidsFromResponse.forEach((addon,i) => {
      
        var checktrue = false;
        // var curpropaddon;
        AddonAllin?.forEach((propaddons)=>{
          console.log(propaddons,'dd')
          if (addon.addon_id === propaddons.addon_id) {
            let truefalse = propaddons.allowed === 1;
            addonSwitchStates.push({"propaddon_uid":propaddons.uid,"allowedstate":truefalse,...propaddons,...addon}); 
            checktrue = true;        
            updatedPrice[propaddons.addon_id] = propaddons.addon_cost;
          }
        })



        setPrice(updatedPrice);
        console.log(price,updatedPrice,"newprices")
        if(!checktrue){
          addonSwitchStates.push({"propaddon_uid":undefined,"allowedstate":false,...addon}); 
        }
      });
      
      console.log("Addon Switch States:", addonSwitchStates);
      setSwitchStates(addonSwitchStates);

      // Update the switch states with the addonSwitchStates object
      
      setAddonData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  console.log(props,'checkProps')


  // console.log(addonData,'uui')
  console.log(switchStates,'uui')


  useEffect(() => {
    fetchDataAddon();
  }, []);

  useEffect(() => {
    fetchDataAddon();
  }, [props?.listingData?.data[0]?.amenities_details]);


  const toggleVisibleDocks = () => {

    setOpenModalPricing(false)
    setOpenModalExtraGuest(false)
    setSelectedAddonData(null)
    setOpenModalAvailWindow(false)
    setOpenModalAdvanceWindow(false)
    setOpenModalMaxStay(false)
    setOpenModalMinStay(false)
    setOpenCalendarSync(false)

  };

  return (
    <div className="pricing-detail-edit-content  listing-detail-edit-content">
      <div className="pricing-edit-first-main-div">
        <div className="text-edit-div-box">
          <h2 className="title-text">Pricing</h2>
          <p className="para-text">Base Price: ₹{formatIndianRupeesFunc(basePriceText)}</p>
          <p className="para-text">Weekend Price: ₹{formatIndianRupeesFunc(weekendPriceText)}</p>

        </div>
        <p
          onClick={() => setOpenModalPricing(true)}
          style={{ cursor: "pointer" }}
          className="basic-detail-edit-btn"
        >
          Edit
        </p>
      </div>
      
      <div className="line"></div>
      <div className="edit-basic-details-main-div">
        <h2>Additional Charges</h2>

        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Extra guest Fee</h3>
            <p className="para-text">              
              After {max_guestsText} guest ₹{formatIndianRupeesFunc(extraguestPrice)} per person per
              night
            </p>
          </div>
          <p
            onClick={() => setOpenModalExtraGuest(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>
        <div className="light-line"></div>

        {switchStates?.map((addon, index) => {
          console.log(price,'checkaddonS'); // Log the addon object
           return (
            <>
           <div key={addon.addon_id} className="basic-details-text-edit-div">
           <div className="text-edit-div-box">
           <h3 className="title-text">{addon.addon_name.charAt(0).toUpperCase() + addon.addon_name.slice(1)}</h3>
            {addon.allowedstate ? (
             <p className="para-text amenities-text-box">
                {addon && price[addon.addon_id] ? `${addon.addon_name} fee is ₹${price[addon.addon_id]}` : `Add ${addon.addon_name} `}
             </p>
            ) : (
           <p className="para-text amenities-text-box">
            <span>Allow {addon.addon_name.charAt(0).toUpperCase() + addon.addon_name.slice(1)} at your listing</span>
           </p>
           )}
          </div>
          <div className="switch-edit-div">
          <SwitchExample
          checked={addon.allowedstate || false}
          onChange={() => handleChange(addon.allowedstate, index, addon.propaddon_uid)}
          />
          {addon.allowedstate && (
          <p onClick={() => handleEditClick(addon.addon_id, addon.addon_name, addon.description, addon.quantifiable)} className="basic-detail-edit-btn">
            Edit
          </p>
           )}

          </div>
          
        </div>
        
        <div className="light-line"></div>
        </>
         );
        })}
           

       

        <div className="line"></div>
        <h2>Availabilty</h2>
        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Available Window</h3>
            <p className="para-text">
              {allData?.available_window} days in advance
            </p>
          </div>
          <p
            onClick={() => setOpenModalAvailWindow(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>
        <div className="light-line"></div>

        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Advance Window</h3>
            <p className="para-text ">{allData?.advance_window} days before</p>
          </div>
          <p
            onClick={() => setOpenModalAdvanceWindow(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>
        <div className="light-line"></div>

        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Minimum Stay</h3>
            <p className="para-text">{allData?.min_stay} night</p>
          </div>
          <p
            onClick={() => setOpenModalMinStay(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>
        <div className="light-line"></div>

        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Maximum stay</h3>
            <p className="para-text">{allData?.max_stay} nights</p>
          </div>
          <p
            onClick={() => setOpenModalMaxStay(true)}
            className="basic-detail-edit-btn"
          >
            Edit
          </p>
        </div>
        

        <div className="line"></div>
        {/* <h2></h2> */}


        <div className="basic-details-text-edit-div">
          <div className="text-edit-div-box">
            <h3 className="title-text">Calendar Sync</h3>
            {/* <p className="para-text">{allData?.max_stay} nights</p> */}
          </div>
          <p
            onClick={() => setOpenCalendarSync(true)}
            className="basic-detail-edit-btn"
          >
            Manage
          </p>
        </div>
      </div>

      <div className="pricing-details-guest-popup ">
        {openModalPricing && (
          <WrapperPopup
          fluid={true}

          defaultSize={"0.70"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
            closeModal={() => setOpenModalPricing(false)}
            comp={
              <EditPricing
                fetchData={props.fetchData}
                propId={preferencePropId}
                allData={allData}
                closeModal={() => setOpenModalPricing(false)}
              />
            }
          />
        )}
      </div>

      <div className=" pricing-details-popup">
        {selectedAddonData !== null && (
          <WrapperPopup
          fluid={true}
          headingText={selectedAddonName.charAt(0).toUpperCase() + selectedAddonName.slice(1)}

          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
          

            closeModal={() => setSelectedAddonData(null)}
            comp={
              <EditAddonComponent
                selectedAddonName={selectedAddonName}
                selectedAddonData={selectedAddonData}
                quantifiable={quantifiable}

                // addonData={selectedAddonData}
                addonDiscription={selectedAddonDiscription}
                propId={propId}
                allData={allData}
                fetchData={props.fetchData}
                closeModal={() => setSelectedAddonData(null)}
                

              />
            }
          />
        )}
      </div>

      <div className=" pricing-details-popup">
        {openModalExtraGuest && (
          <WrapperPopup
          fluid={true}

          headingText=' Additional guest fee'
          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
            closeModal={() => setOpenModalExtraGuest(false)}
            comp={
              <EditExtraGuest
                fetchData={props.fetchData}
                propId={preferencePropId}
                allData={allData}
                closeModal={() => setOpenModalExtraGuest(false)}
              />
            }
          />
        )}
      </div>

      <div className="edit-booking-preference-popup">
        {openModalAvailWindow && (
          <WrapperPopup
          fluid={true}
         headingText='Available Window'
          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 

            closeModal={() => setOpenModalAvailWindow(false)}
            comp={
              <EditAvailWindow
                fetchData={props.fetchData}
                allData={allData}
                propId={preferencePropId}
                closeModal={() => setOpenModalAvailWindow(false)}
              />
            }
          />
        )}
      </div>
      <div className="edit-booking-preference-popup">
        {openModalAdvanceWindow && (
          <WrapperPopup
          fluid={true}
          headingText='Advance Window'
          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
            closeModal={() => setOpenModalAdvanceWindow(false)}
            comp={
              <EditAdvanceWindow
                fetchData={props.fetchData}
                addonName={selectedAddonName}
                allData={allData}
                propId={preferencePropId}
                closeModal={() => setOpenModalAdvanceWindow(false)}
              />
            }
          />
        )}
      </div>

      <div className="edit-booking-preference-popup">
        {openModalMaxStay && (
          <WrapperPopup
          fluid={true}
          headingText='Maximum Stay'
          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks}
            closeModal={() => setOpenModalMaxStay(false)}
            comp={
              <EditMaxStay
                fetchData={props.fetchData}
                propId={preferencePropId}
                allData={allData}
                closeModal={() => setOpenModalMaxStay(false)}
              />
            }
          />
        )}
      </div>
      <div className="edit-booking-preference-popup">
        {openModalMinStay && (
          <WrapperPopup
          fluid={true}
          headingText='Minimum Stay'
          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks}
            closeModal={() => setOpenModalMinStay(false)}
            comp={
              <EditMinStay
                fetchData={props.fetchData}
                propId={preferencePropId}
                allData={allData}
                closeModal={() => setOpenModalMinStay(false)}
              />
            }
          />
        )}
      </div>




      <div className="edit-calendar-sync-popup">
        {openCalendarSync && (
          <WrapperPopup
          fluid={true}
          defaultSize={"0.50"}
          dimStyle={false}
          headingText="Calendar Sync"
          DockFilter={true}
          position='bottom'
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks}
            closeModal={() => setOpenCalendarSync(false)}

            comp={
              <EditCalendarSync
                fetchData={props.fetchData}
                propId={propId}
                allData={allData}
                closeModal={() => setOpenCalendarSync(false)}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default PricingDetailEditComponent;

