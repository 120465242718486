import React from "react";
import Button from "../common/Button";

const UserShareBtn = (props) => {
  //css is provideed in button.scss
  return (
    <div className="user-share-save-btn">
     <Button 
     btnText={props.btnText}
     />
    </div>
  );
};

export default UserShareBtn;
