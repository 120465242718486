
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import SkeletonLoader from "../common/SkeletonLoader";

const PropertyDetailsBox = (props) => {
  const isSmallScreen = window.innerWidth <= 500;

  return (
<div className={`property-details-parent-box ${props.someProp ? 'mt-12' : ''}`}>
      {/* Render skeleton loaders if data is not available */}
      {!props.guestNum ? (
        <div className="property-details-box skeleton-loader">
          <SkeletonLoader width={50} height={20} inline={true}/>
        </div>
      ) : (
        <div className="property-details-box">
          <p>{props.guestNum}</p>
          <p>{props.guestNum > 1 ? 'Guests' : 'Guest'}</p>
        </div>
      )}
      <FontAwesomeIcon icon={faCircle} className="dividerdot"/>

      {!props.bedroomNum ? (
        <div className="property-details-box skeleton-loader">
          <SkeletonLoader width={50} height={20} inline={true}/>
        </div>
      ) : (
        <div className="property-details-box">
          <p>{props.bedroomNum}</p>
          <p>{props.bedroomNum > 1 ? 'Bedrooms' : 'Bedroom'}</p>
        </div>
      )}
      <FontAwesomeIcon icon={faCircle} className="dividerdot"/>

      {!props.bedsNum ? (
        <div className="property-details-box skeleton-loader">
          <SkeletonLoader width={50} height={20} inline={true}/>
        </div>
      ) : (
        <div className="property-details-box">
          <p>{props.bedsNum}</p>
          <p>{props.bedsNum > 1 ? 'Beds' : 'Bed'}</p>
        </div>
      )}
      <FontAwesomeIcon icon={faCircle} className="dividerdot"/>

      {!props.bathroomNum ? (
        <div className="property-details-box skeleton-loader">
          <SkeletonLoader width={50} height={20} inline={true}/>
        </div>
      ) : (
        <div className="property-details-box">
          <p>{props.bathroomNum}</p>
          <p>{props.bathroomNum > 1 ? 'Baths' : 'Bath'}</p>
        </div>
      )}
    </div>
  );
};

export default PropertyDetailsBox;
