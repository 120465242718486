import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import DynamicTable from "../../../Components/common/HostTables";
import Button from "../../../Components/common/Button";
import CardWrapper from "../../../Components/common/cardWrapper";
import PropertyListing from "../../../Components/common/PropertyListing";
import SearchComp from "../../../Components/common/searchComp";
import FilterArrow from "../../../Components/common/filterArrow";
import WrapperPopup from "../../../Components/common/wrapperPopup";
import Room_beds_popup from "../../../Module/Host/CreateListing/Room_beds_popup";
import ListingAmenitiesCheckPopup from "../../../Module/Host/CreateListing/ListingAmentiesCheckPopup";
import { apiMainUser,CleanCreateListingRedux, CleanCreateListingReduxNew } from "../../../utils/constant";
import { useDispatch } from "react-redux";
import {
  setPropertyData,
  setPlaceData,
  setRoomData,
  setAmenitiesData,
  setDiscData,
  setRulesData,
  setBookingPrefrenceData,
  setConfirmAddressData,
} from "../../../utils/redux/listingTypeSlice";

import {
  updateGuests,
  updateBedrooms,
  updateBeds,
  updateBathrooms,
} from "../../../utils/redux/CounterSlice";

import {
  updateBaseGuest,
  updatePerNightCost,
  updateAdditionalBeds,
  updateAdditionalGuests,
} from "../../../utils/redux/PricingCounter";

import { setFailedImages, setSelectedImages } from "../../../utils/redux/dropboxSLicer";

import { setGetAddCartData } from "../../../utils/redux/CounterSlice";
import {
  setGetPricingData,
  setPricingCartId,
} from "../../../utils/redux/PricingCounter";
import { setGetImageData } from "../../../utils/redux/dropboxSLicer";
import {
  setAddonId,
  setAddonPropId,
  setPreferenceId,
  setPropId,
  setPropName,
} from "../../../utils/redux/idSlice";
import ButtonPopup from "../../../Components/common/ButtonPopup";
import AddToCart from "../../../Components/common/addCarts";
import { Controller, useForm } from "react-hook-form";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SkeletonLoader from "../../../Components/common/SkeletonLoader";
import SkeletonDTable from "../../../MobSkeleton/SkeletonTable";
import { setEditFailedImages } from "../../../utils/redux/EditDropBoxSlicer";
import NoDataLottie from "../../../Components/common/NoDataLottie";
import FooterComponent from "../../common/FooterComponent";
import HostFooterComp from "../../common/HostFooterComp";

const ListingPage = () => {
  const [roomPop, setRoomPop] = useState(false);
  const [amenitiesPop, setAmenitiesPop] = useState(false);
  const [listings, setListings] = useState([]);
  const [page, setPage] = useState();



  const statusColors = {
    "In Progress": "#828282",
    "Pending Approval": "#F1AE00",
    "Publish Pending": "#19A6BB",
    "Listed": "#48BE00",
    // Add more status-color pairs as needed
  };

  console.log(listings,'iio')

  

  //Filter states -----------------------------------------------
  //Searrch filter------------------------
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  
  //
  
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [count1, setCount1] = useState(1); 
const [count2, setCount2] = useState(1);
const [count3, setCount3] = useState(1); 
const [Tempcount1, setTempCount1] = useState(1); 
const [Tempcount2, setTempCount2] = useState(1);
const [Tempcount3, setTempCount3] = useState(1); 

const handleCountChange1 = (newCount) => {
  setTempCount1(newCount); 
};

const handleCountChange2 = (newCount) => {
  setTempCount2(newCount); 
};

const handleCountChange3 = (newCount) => {
  setTempCount3(newCount); 
  
};



///amenities-filter state

const handleSelectedAmenities = (amenities) => {
  setSelectedAmenities(amenities);
};

const handle_amenities=()=>{
  
  fetchData();
}

const handleClose = () => {
  setAmenitiesPop(false);
};

//
//status--filter-state------

const statusOptions = [
  { label: 'All', value: '' },

  { label: 'Listed', value: 'listed' },
  { label: 'Pending Approval', value: 'pendingapproval' },
  { label: 'In Progress', value: 'inprogress' },
  { label: 'Unlisted', value: 'unlisted' },
];
const { handleSubmit, control } = useForm();


const isSmallScreen = window.innerWidth <= 500;
  

  const onSubmit = (data) => {
    // Handle form submission
    console.log(data.status,'iip');
    setSelectedStatus(data.status);
  };
  console.log(selectedStatus,'iip')

  const amenitiesString = selectedAmenities.join(',');
//---------------------------------------------- clear filter function=----

const [shouldRenderButton, setShouldRenderButton] = useState(false);

// useEffect to check if any state value has changed
useEffect(() => {
  if (
    selectedStatus !== '' ||
    selectedAmenities.length > 0 ||
    count1 !== 1 ||
    count2 !== 1 ||
    count3 !== 1
  ) {
    setShouldRenderButton(true);
  } else {
    setShouldRenderButton(false);
  }
}, [selectedStatus, selectedAmenities, count1, count2, count3]);

const clearFilter =()=>{
  setSelectedAmenities([])
  setSelectedStatus('')
  setCount1(1)
  setCount2(1)
  setCount3(1)
  setTempCount1(1)
  setTempCount2(1)
  setTempCount3(1)

}
//




  // const [pageNo, setPageNo] = useState("");
  const navigate = useNavigate();
  const cleanCreateListingReduxNew = CleanCreateListingReduxNew();
  const dispatch = useDispatch();




      const handle_beds_bath_bedrooms =()=>{
      setCount1(Tempcount1)
      setCount2(Tempcount2)

      setCount3(Tempcount3)

        
        // fetchData()
      }

  let status = "";
  const fetchData = async () => {
    try {
      // const amenitiesString = selectedAmenities.join(',');
      // const response = await axios.get(apiMainUser + "getListing?limit=1000&beds=1&bedrooms=1&bathrooms=1&amenties", {
      const response = await axios.get(apiMainUser + "getListing", {
        params: {
          limit: 1000,
          bedrooms: count1,
          bathrooms: count2,
          bedss: count3,
          amenities: amenitiesString,
          status:selectedStatus
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      console.log("response.data:", response.data);
     

      const extractedData = response.data.data.map((listing) => {
      
        const propertyImage =
          listing.images && listing.images.length > 0
            ? listing.base_url_image + listing.images[0]
            : './assets/img/notFoundImage.png';
        const lastModified = new Date(listing.property_updatedon);        
        const formattedDate = lastModified.toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "UTC",
        });



        if (listing.isverified === 0) {
          status = "In Progress"; //Finish
        } else if (
          listing.isverified === 1
          // (listing.archived === 0 || listing.archived === 1)
        ) {
          status = "Pending Approval"; //view
        } else if (listing.isverified === 2 && listing.publish === 0) {
          status = "Publish Pending"; //publish
        } else if (listing.isverified === 2 && listing.publish === 1) {
          status = "Listed"; //edit
        } else if (
          listing.isverified === 2 &&
          listing.publish === 1 &&
          listing.archived === 1
        ) {
          status = "Archived"; //View
        }

        let btnFunction;
        switch (status) {
          case "In Progress":
            btnFunction = finishFunction;
            break;
          case "Pending Approval":
            btnFunction = viewFunction;
            break;
          case "Publish Pending":
            btnFunction = publishFunction;
            break;
          case "Listed":
            btnFunction = editFunction;
            break;
          case "Archived":
            btnFunction = viewFunction;
            break;
          default:
            btnFunction = unknownFunction;
            break;
        }

        return {
          "PROPERTY NAME": (
            <PropertyListing
              src={propertyImage}
              className="property-listing-img"
              width="60px"
              height="6px"
              propListingText="propListingText"
              paraText={<>{listing.property_name}<br/>{listing.city} {listing.state}</>}
            />
          ),
          "PLAN NAME_sort": listing.property_name,
          
          STATUS: (
            <span style={{ color: statusColors[status] || "",fontWeight:'500' }}>
              {status}
            </span>
          ),
           
          "TO DO": (
            <Button
              onClick={() => btnFunction(listing)}
              // btnText="Edit"
              btnText={
                status === "In Progress"
                  ? "Finish"
                  : status === "Pending Approval"
                  ? "Wait for approval"
                  : status === "Publish Pending"
                  ? "Publish"
                  : status === "Listed"
                  ? "Edit"
                  : status === "Archived"
                  ? "View"
                  : "Unknown"
              }
              btnclasses="edit-btn"
            />
          ),
          BEDROOMS: listing.bedrooms,
          BEDS: listing.beds,
          BATHS: listing.bathrooms,
          // LOCATION: ,
          "LAST MODIFIED": `${formattedDate}`,
          };
        });

      // dispatch(setGetListingData(actionListingData));
      // dispatch(setGetAddCartData(addCartData));
      // dispatch(setGetPricingData(pricingCartData));
      // dispatch(setGetImageData(imageData));
      setListings(extractedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setListings('')
    }
  };


  useEffect(() => {
    fetchData();
  }, [selectedStatus,count1,count2,count3]);


  console.log(listings.amenities,'iit')

//Filter states--------------------------




//
  const finishFunction = (listing) => {
    console.log(listing,'allListing')
    navigate(listing?.list_age, { state: { prop: listing } });

    dispatch(setPropName(listing.property_name));
    dispatch(setPropId(listing.propertyid));
    dispatch(setPreferenceId(listing.perference_id));

    dispatch(
      setPropertyData({
        metadata_key: "property_type",
        metadata_value: listing.property_type_id,
      })
    );
    dispatch(
      setPlaceData({
        metadata_key: "place_type",
        metadata_value: listing.place_type_id,
      })
    );
    dispatch(
      setRoomData({
        metadata_key: "space_type",
        metadata_value: listing.space_type,
      })
    );
    let amenitiesArray = listing.amenities_details.map((amenity) =>amenity.uid); 
    let amenitiesJoin = amenitiesArray.join(",");
    dispatch(
      setAmenitiesData({
        metadata_key: "amenities",
        metadata_value: amenitiesJoin,
      })
    );
    
    dispatch(
      setConfirmAddressData([
        {
          metadata_key: "house_no",
          metadata_value: listing.house_no,
        },
        {
          metadata_key: "area",
          metadata_value: listing.area,
        },
        {
          metadata_key: "street_address",
          metadata_value: listing.street_address,
        },

        {
          metadata_key: "landmark",
          metadata_value: listing.landmark,
        },
        {
          metadata_key: "country",
          metadata_value: listing.country,
        },
        {
          metadata_key: "pincode",
          metadata_value: listing.pincode,
        },
        {
          metadata_key: "city",
          metadata_value: listing.city,
        },

        {
          metadata_key: "state",
          metadata_value: listing.state,
        },
        {
          metadata_key: "latitude",
          metadata_value: listing.latitude,
        },
        {
          metadata_key: "longitude",
          metadata_value: listing.longitude,
        },
      ])
    );

    let new_max_guests = listing.max_guests !== null ? listing.max_guests : 1
    let new_bathrooms = listing.bathrooms !== null ? listing.bathrooms : 1
    let new_beds = listing.beds !== null ? listing.beds : 1
    let new_bedrooms = listing.bedrooms !== null ? listing.bedrooms : 1
    dispatch(updateGuests(new_max_guests));
    dispatch(updateBathrooms(new_bathrooms));
    dispatch(updateBeds(new_beds));
    dispatch(updateBedrooms(new_bedrooms));
    // dispatch(updateBedrooms(listing.bedrooms));
    console.log(listing.images, "278");
    let image_arr =
      listing.images !== ""
        ? listing.images?.map((x) => ({
            file: listing.base_url_image + x,
            uploadFile: listing.base_url_image + x,
             localFile: listing.base_url_image + x,
             message: 'Upload successful',
            status: 'success',
          }))
        : [];
        

    // dispatch(setSelectedImages(listing.images));
    dispatch(setSelectedImages(image_arr));
    dispatch(setFailedImages([]))
    dispatch(setEditFailedImages([]))


    dispatch(
      setDiscData({
        metadata_key: "description",
        metadata_value: listing.description,
      })
    );
    dispatch(
      setRulesData({
        metadata_key: "rules",
        metadata_value: listing.rules,
      })
    );
    dispatch(
      setBookingPrefrenceData({
        availabiliityWindow: listing.available_window,
        advanceWindow: listing.advance_window,
        maximumStay: listing.max_stay,
        minimumStay: listing.min_stay,
      })
    );
    dispatch(updateAdditionalGuests(listing.extra_guests_price));
    dispatch(updateBaseGuest(listing.extra_guests));
    // dispatch(
    //

    const addonId = 4;

    const addon = listing?.addons_details.find(
      (addon) => addon.addon_id === addonId
    );
    const addonCost = addon ? addon.addon_cost : null;
    console.log(addonCost, "rey");
    dispatch(updateAdditionalBeds(addonCost));
    const property_addOn_id = addon ? addon.uid : null;

    console.log(property_addOn_id, "trending");

    if (property_addOn_id) {
      dispatch(setAddonPropId(property_addOn_id));
      dispatch(setAddonId(4));
    }

    dispatch(updatePerNightCost(listing.base_price));

    // dispatch;
  };
  const viewFunction = () => {};

  const publishFunction = async (listing) => {
    try {
      // console.log(property_id, "tiku");
      const token = localStorage.getItem("token");
      const response = await axios.post(
        apiMainUser + "publish_property/" + listing.propertyid,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchData();
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // const editFunction = (listing) => {
  //  navigate('/host/listing/edit')

  // };
  const editFunction = (listing) => {
    console.log("heydoneclick");
    const city = listing.city;
    const slug = listing.slug;
    navigate(`/host/listing/edit/${city}/${slug} `);
  };

  // const archivedFunction = () => {};
  const unknownFunction = () => {};

  // const navigateToPage = () => {};


  const filteredListings = searchQuery  ? listings.filter((item) => {
      const propertyName = item["PROPERTY NAME"];
      const paraText = propertyName.props.paraText.props.children[0];
      // console.log(paraText,searchQuery,"sera")

      if (paraText && typeof paraText === "string") {
        // console.log(paraText,searchQuery,"sera")
        return paraText.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return false;
    })
  : listings;

  const [isOpen, setIsOpen] = useState(false);


  const toggleFunc = () => {
    console.log('clicked')
    setIsOpen(!isOpen);
    // setIsOpen(!isOpen)
  };
  console.log(isOpen,'clicked')

  return (
    <div className="Listing-page">
      <div className="listing-page-top-box">
        <div className="listing-page-search-box">
          <div className="responsive-search-box-main-div">
          <SearchComp onKeyUp={handleSearch} />

          <div className="responsive-button-popups-parent">
       
          <div>

            {/* <p>Rooms</p> */}
            {/* <FilterArrow onClick={() => setRoomPop(true)} text="Rooms & BEDS" />  */}
          {/*  */}
          <ButtonPopup
          btnClass={true}
          paddingAll={true} 
          // buttonPopup={true}
         
          wrapclass ='absolute listing-page-buttonpopup'
          
          showCloseButton={true}
          closeBtnText="Cancel"
          showApplyButton={true}
          // additionalClass={isSmallScreen ? 'Fixed-applybox' : ''}
          // isOpen={true}
          applyBtnText="Save" 
          onApply={handle_beds_bath_bedrooms}
          // setVisibleDocks={toggleFunc}

          divContent={
          <>
          <div className="all-items-dock"> 
           <div className="item-dock">
            <span className="text-dock">Bedrooms:</span> <AddToCart
               initialCount={Tempcount1}
              //  onCountChange={handleCountChange1}
              increamentVal={handleCountChange1}
              cartParent="cart-parent-dock"
              cartBtn="cart-button-dock"
              cartCount="cart-count-dock"
                
              />
            </div>
            <div className="item-dock">
            <span className="text-dock">Bathrooms:</span> <AddToCart     
                cartParent="cart-parent-dock"
                cartBtn="cart-button-dock"
                cartCount="cart-count-dock"
                initialCount={Tempcount2}
              
              increamentVal={handleCountChange2}

              />
            </div>
            <div className="item-dock">
            <span className="text-dock">Beds:</span> <AddToCart
              
                cartParent="cart-parent-dock"
                cartBtn="cart-button-dock"
                cartCount="cart-count-dock"
                initialCount={Tempcount3}
              //  onCountChange={handleCountChange3}
              increamentVal={handleCountChange3}
              />
            </div>
            </div>
            {/* <p className="underline">Cancel</p> */}
            
          </>
        }
        makeCustom={
          <button className="custom-filter-btn">
            Rooms & Beds 
            <span className="arrow">
            <FontAwesomeIcon icon={faAngleDown} className="arrow-icon-filter" />
            </span>
          </button>
        }
      />

          </div>
          
       <div>
            {/* <FilterArrow onClick={() => setRoomPop(true)} text="Rooms & BEDS" />  */}
          {/*  */}
          <ButtonPopup
          //buttonPopup={true}
          btnClass={true}
        // wrapHead="Rooms & Beds"
          wrapclass ='absolute h-300px scroll-Y listing-page-buttonpopup'
          showCloseButton={true}
          closeBtnText="Cancel"
          FixedBottomDock={isSmallScreen ? true  : false}
          DockPopupFilter={isSmallScreen ? true : false}
          showApplyButton={true}
          applyBtnText="Save"
         onClose={handle_amenities}
         onApply={handle_amenities}
        
        divContent={
          <>

          <div className="all-items-dock">
           <ListingAmenitiesCheckPopup 
           
          //  handleSaveClick={handle_amenities} 
             initialSelectedAmenities={selectedAmenities}  onClose={handleClose} 
             onSelectedAmenities={handleSelectedAmenities} />
             </div>
          </>
        }
        makeCustom={
          <button className="custom-filter-btn">
          Amenities
          <FontAwesomeIcon icon={faAngleDown} className="arrow-icon-filter" />

        </button>
        }
        
      />
    </div>
    
{/*  */}
          <div>
          
       
          <ButtonPopup
            wrapclass ='absolute '
            btnClass={true}
            // buttonPopup={true}
            divContent={
          <>
           <div className="all-items-dock">
      <form onSubmit={handleSubmit(onSubmit)}>
     
      {statusOptions.map((option, index) => (
        <div key={index} className="radio-container">
          <Controller
            name="status"
            control={control}
            defaultValue={selectedStatus}
            rules={{ required: true }}
            render={({ field }) => (
              <label className="radio-label">
                <input
                  type="radio"
                  value={option.value}
                  {...field}
                  checked={selectedStatus === option.value}
                  onChange={() => setSelectedStatus(option.value)}
                  className="radio-input"
                  name="status"
                />
                {option.label}
              </label>
            )}
          />
        </div>
      ))}
      
    </form>
    </div>
          </>
        }
        makeCustom={
          <button className="custom-filter-btn">
          Status

          <FontAwesomeIcon icon={faAngleDown} className="arrow-icon-filter" />
        </button>
        }
      />


          </div>
         
         
          {shouldRenderButton && (
       <p 
       onClick={clearFilter}
       style={{alignSelf:'end'}}
       className="font-small underline pointer">Clear Filter</p>

       
      )}
          </div>

          </div>
             
        </div>

        
        <Link className="link" to="/host/createlisting">
          <Button
            btnText="Create Listing"
            btnclasses="tab-btn"
            icon="./assets/img/plusImg.svg"
            onClick={cleanCreateListingReduxNew}
          ></Button>
        </Link>
      </div>
   
  

      <div className="listing-table">
      {filteredListings.length === 0 ? (
         <>
         <NoDataLottie jsonLotty='./assets/lottie/tripnotFound.json' height="200px" width="300px"/>
       
          </>
      ) : (
        <CardWrapper wrapperClass="listing-table-card">
        {filteredListings ?  <DynamicTable data={filteredListings} hidden={["PLAN NAME_sort"]} itemsPerPage={10}/>

           :  <SkeletonDTable data={filteredListings} />} 
        </CardWrapper>
      )}
    </div>
    
    {/* <FooterComponent/> */}
    <HostFooterComp/>
    
    </div>
  );
};

export default ListingPage;
