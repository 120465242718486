import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedEditImages: [],
  failedEditImages: [],

};

const EditImageSlice= createSlice({
  name: "Editimage",
  initialState,
  reducers: {
    setEditSelectedImages: (state, action) => {
      state.selectedEditImages = action.payload;
    },
    setEditFailedImages: (state, action) => {
      console.log(action,"step3payload")
      state.failedEditImages = action.payload;
    },
    deleteEditFailedImage: (state, action) => {
      state.failedEditImages = state.failedEditImages.filter(
        (image, index) => index !== action.payload
      );
    },
    deleteEditSuccesImageAction: (state, action) => {
      state.selectedEditImages = state.selectedEditImages.filter(
        (image, index) => index !== action.payload
      );
    },
   
  },
});

export const {
  setEditSelectedImages,
  setEditFailedImages,
  deleteEditFailedImage,
  deleteEditSuccesImageAction
// setImagePropertyId,
  // setGetImageData,
} = EditImageSlice.actions;

export const EditdragDropReducer = EditImageSlice.reducer;
