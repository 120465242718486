import React from 'react'
import GuestProfile from '../../Components/common/guestProfile'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { CleanCreateListingRedux, apiMainUser } from '../../utils/constant';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import VerifactionToNavigateHost from '../../Components/common/VerifactionToNavigateHost';
import WrapperPopup from '../../Components/common/wrapperPopup';

const MobileProfile = () => {
    const storeSel = useSelector((state) => state);
    
    const profileImage = storeSel.hostDetailSlice?.Image;


   
    const userName = storeSel.hostDetailSlice.user_Name;



  
  const [verficationData,setVerificationData] = useState()

  const [verificatioProfilePop,setVerificationProfilePop] = useState(false)


  const toggleVisibleDocks = () => {
    setVerificationProfilePop(false)
    }

  
  useEffect(() => {
    const fetchData = async () => {
      try {
 
        const userResponse = await axios.get(apiMainUser + "get_user_detail", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          }
        });
        console.log(userResponse.data.data,'checkResponseNew')
        setVerificationData(userResponse.data.data[0])
      } catch (error) {
      }
    };

    fetchData();
  }, [userName]); // Trigger the effect whenever userName changes



  console.log(verficationData?.host_status,'checkResponseNew')


   

    const [switchMessage, setSwitchMessage] = useState('Switch to hosting')


    const navigate = useNavigate();
    const cleanCreateListingRedux = CleanCreateListingRedux();

    const location = useLocation();


    useEffect(() => {
      if (location.pathname.includes('/host/profile')) {
          setSwitchMessage('Switch to travelling');
      } else {
          setSwitchMessage('Switch to hosting');
      }
  }, [location.pathname]); // Only re-run the effect if location.pathname changes

  const handleSwitchToHosting = () => {
    if (verficationData?.mobile_verified !== 0 && verficationData?.email_verified !== 0 && verficationData?.user_docs_details !== []) {
      navigate("/host");
    } else {
      setVerificationProfilePop(true);
    }
  };


  const switchToTravelling =()=>{
    navigate('/')
  }
  

  

    

    const logOut = ()=>{
        localStorage.clear();
        sessionStorage.clear();
        cleanCreateListingRedux();
        navigate("/login");
    }
  return (
    <div className='mobile-profile-page' >
      <h1>Profile</h1>
 
    <Link className='flex justify-space-between align-items-center'   to={`/users/${userName}`}>
      <GuestProfile 
      profileName={verficationData?.fname }
      profileImage={profileImage}
      />
     <span className="material-icons-parent mt-0"> <span className="material-icons">verified</span><span className="jumbo-verified-text">Jumbo Verified</span></span> 


      </Link>

      <h2>Account Setting</h2>
     <div className="proile-link-div">
     <Link style={{color:'#222'}} className='mob-profile-link' to='/wishlist'>
      <span className='mobile-profile-icon'>
        <img src="./assets/img/wishListImg.svg" alt="" />
      </span>
      <span className='mobile-profile-lin-text'>Wishist</span>
     </Link>

     <Link style={{color:'#222'}} className='mob-profile-link' to='/hostPersonalInfo'>
      <span className='mobile-profile-icon'>
        <img src="./assets/img/account1.svg" alt="" />
      </span>
      <span className='mobile-profile-lin-text'>Personal Info</span>
     </Link>

     <Link style={{color:'#222'}} className='mob-profile-link' to='/hostAccount'>
      <span className='mobile-profile-icon'>
        <img src="./assets/img/account1.svg" alt="" />
      </span>
      <span className='mobile-profile-lin-text'>Account</span>
     </Link>

     <Link style={{color:'#222'}} className='mob-profile-link'  to={`/users/${userName}`}>
      <span className='mobile-profile-icon'>
        <img src="./assets/img/account1.svg" alt="" />
      </span>
      <span className='mobile-profile-lin-text'>My Profile</span>
     </Link>


     <Link style={{color:'#222'}} className='mob-profile-link' to='/hostloginSecurity'>
      <span className='mobile-profile-icon'>
        <img src="./assets/img/account2.svg" alt="" />
      </span>
      <span className='mobile-profile-lin-text'>Login & Security</span>
     </Link>

     </div>

     



     <h2>Hosting</h2>
 
   <div className="proile-link-div">

     {window.location.pathname.includes("/host") ? (
            <p 
            onClick={switchToTravelling}
            style={{ color: '#222' }}
            className='mob-profile-link'
              >
             <span className='mobile-profile-icon'>
             <img src="./assets/img/switchHost.svg" alt="" />
             </span>
             <span className='mobile-profile-lin-text'>Switch to travelling</span>
            </p>
          ) : (
            <p
            onClick={handleSwitchToHosting}
            style={{ color: '#222' }}
            className='mob-profile-link'
            >
           <span className='mobile-profile-icon'>
             <img src="./assets/img/switchHost.svg" alt="" />
             </span>
           <span className='mobile-profile-lin-text '>Switch to Hosting</span>    

            </p>
          )}

</div>

     <h2>Support</h2>
     <div className="proile-link-div">
     <Link style={{color:'#222'}} className='mob-profile-link' to=''>
      <span className='mobile-profile-icon'>
        <img src="./assets/img/support.svg" alt="" />
      </span>
      <span className='mobile-profile-lin-text'>Support</span>
     </Link>

     <Link style={{color:'#222'}} className='mob-profile-link' to='/privacypolicy'>
      <span className='mobile-profile-icon'>
        <img src="./assets/img/account1.svg" alt="" />
      </span>
      <span className='mobile-profile-lin-text'>Privacy Policy</span>
     </Link>

     <Link style={{color:'#222'}} className='mob-profile-link' to='/termscondition'>
      <span className='mobile-profile-icon'>
        <img src="./assets/img/account1.svg" alt="" />
      </span>
      <span className='mobile-profile-lin-text'>Terms & Condition</span>
     </Link>

     <Link style={{color:'#222'}} className='mob-profile-link' to='/ContactUs'>
      <span className='mobile-profile-icon'>
        <img src="./assets/img/account1.svg" alt="" />
      </span>
      <span className='mobile-profile-lin-text'>Contact Us</span>
     </Link>

     </div>

     <button onClick={logOut} className='mob-logout-btn'>Logout</button>


     <div className="edit-booking-preference-popup">
        {verificatioProfilePop && (
          <WrapperPopup
          fluid={true}
     
          defaultSize={"0.90"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
          closeModal={setVerificationProfilePop}
          comp={
         <VerifactionToNavigateHost
         closeModal={setVerificationProfilePop}
         />
        }
        />
        
        )}
      </div>
    </div>
  )
}

export default MobileProfile
