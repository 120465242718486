import { combineReducers } from "redux";
import { ApiDataReducer } from "./idSlice";
import { ListingDataReducer, PropertyDataReducer } from "./listingTypeSlice";
import { CounterDataReducer } from "./CounterSlice";
import { PricingDataReducer } from "./PricingCounter";
import { CounterUserSearchReducer } from "./User/UserSearchConfig";
import { dragDropReducer } from "./dropboxSLicer";
import { indexDataReducer } from "./commonIndex";
import { ApiSlicerReducer } from "./apiSlicer";
import { FilterGetReducer } from "./FilterSlicer";
import { HostDetailReducer } from "./HostDetailSlice";
import { EditdragDropReducer } from "./EditDropBoxSlicer";
import { PromptSliceReducer } from "./GetPromptSlice";
import { SnackbarReducer } from "./SnackBarSlicer";
import { paramsDataReducer } from "./ParamsSlicer";

const rootReducer = combineReducers({
  // AboutCatList: AboutCatListReducer,
  apiDataSlice: ApiDataReducer,
  paramDataSlice:paramsDataReducer,
  listingDataSlie: ListingDataReducer,
  addCartSlice: CounterDataReducer,
  pricingCartSlicer: PricingDataReducer,
  UserSearchConfig: CounterUserSearchReducer,
  imageSlice: dragDropReducer,
  PromptSlice: PromptSliceReducer,
  EditImageSlice:EditdragDropReducer,
  indexReducer: indexDataReducer,
  apiSlicer:ApiSlicerReducer,
  filterDataSlice:FilterGetReducer,
  hostDetailSlice:HostDetailReducer,
  snackbar:SnackbarReducer

});

export default rootReducer;
