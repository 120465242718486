import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../Components/common/Button";
import EditSaveBtn from "../EditSaveBtn";
// import TimePicker from "react-time-picker";

const EditCheckinTime = (props) => {
  const [postData, setPostData] = useState(null);

 
  let url = "createProperty_metadata_bulk";
  console.log(props.propId, "tulips");
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, fromOnChanged) => {
    const postDatavalue = {
      property_id: props.propId,
      meta: [
        {
          metadata_key: "check_in",
          metadata_value: fromOnChanged ? data : data.checkIntime,
        },
      ],
    };
    setPostData(postDatavalue);
  };

  return (
    <div className="edit-check-in-time-div" style={{marginTop:'2rem'}}>
      <form className="time-input-form" onSubmit={handleSubmit(onSubmit)}>
        
        <Controller
          control={control}
          defaultValue={
            props.allData !== undefined ? props.allData?.check_in : ""
          }
          name="checkIntime"
          rules={{ required: "Please select a time" }}
          render={({ field }) => (
            <input
              type="time"
              id="checkIntime"
              onChange={(e) => {
                field.onChange(e.target.value);
                onSubmit(e.target.value, true);
              }}
              value={field.value}
            />
            
          )}
        />

        {errors.checkIntime && <p>{errors.checkIntime.message}</p>}

        <div className="clear_apply-btn-box paddingCustomYSide">
          <p onClick={() => props.closeModal(false)} className="clear-btn">
            Cancel
          </p>
          <EditSaveBtn
            method="post"
            postData={postData}
            fetchData={props.fetchData}
            btnText="Save"
            btnclasses="save-edit-btn"
            url={url}
            closeModal={props.closeModal}
            message='updated successfully'
          />
        </div>
      </form>
    </div>
  );
};
export default EditCheckinTime;
// import React, { useState } from "react";
// import { Controller, useForm } from "react-hook-form";
// import Button from "../../../../Components/common/Button";
// import EditSaveBtn from "../EditSaveBtn";
// import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import moment from "moment";

// const EditCheckinTime = (props) => {
//   const [postData, setPostData] = useState(null);
//   const [selectedTime, setSelectedTime] = useState(moment(props.allData?.check_in,'HH:mm' ));
//   // moment(props.allData?.check_in,'HH:mm' )|| 


//   console.log(props.allData,'checkAll')

  
// console.log(selectedTime,'checktime')


  
//   let url = "createProperty_metadata_bulk";
//   const {
//     register,
//     handleSubmit,
//     control,
//     setValue,
//     formState: { errors },
//   } = useForm();

//   const onSubmit = () => {

//     let actualTime = selectedTime?.format('HH:mm')
//     const postDatavalue = {
//       property_id: props.propId,
//       meta: [
//         {
//           metadata_key: "check_in",
//           metadata_value:  actualTime,
//         },
//       ],
//     };
//     setPostData(postDatavalue);
//   };

//   const selectTimeFunc =(value)=>{
//     console.log(value,'checkTime')
//     setSelectedTime(value)
//   }

//   return (
//     <div className="edit-check-in-time-div" style={{marginTop:'2rem'}}>
//          <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <form className="time-input-form" onSubmit={handleSubmit(onSubmit)}>
        
//         <Controller
//           control={control}
//           // defaultValue={
//           //   props.allData !== undefined ? props.allData?.check_in : ""
//           // }
//           name="checkIntime"
//           rules={{ required: "Please select a time" }}
//           render={({ field }) => (
//             <input
//               type="time"
//               id="checkIntime"
//               onChange={(e) => {
//                 field.onChange(e.target.value);
//                 onSubmit(e.target.value, true);
//               }}
//               value={field.value}
//             />
//           // <TimePicker
//           // label="Controlled picker"
//           // value={selecetedTime}
//           //  onChange={(newValue) => selectTimeFunc(newValue)}
//           // />
            
//           )}
//         />

//         {errors.checkIntime && <p>{errors.checkIntime.message}</p>}

//         <div className="clear_apply-btn-box paddingCustomYSide">
//           <p onClick={() => props.closeModal(false)} className="clear-btn">
//             Cancel
//           </p>
//           <EditSaveBtn
//             method="post"
//             postData={postData}
//             fetchData={props.fetchData}
//             btnText="Save"
//             btnclasses="save-edit-btn"
//             url={url}
//             closeModal={props.closeModal}
//             message='updated successfully'
//           />
//         </div>
//       </form>
//       </LocalizationProvider>
//     </div>
//   );
// };
// export default EditCheckinTime;
