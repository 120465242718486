import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../../Components/common/Button";
import AddToCart from "../../../../Components/common/addCarts";
import EditSaveBtn from "../EditSaveBtn";
import GuestAddCart from "../../../../Components/common/guestAddCart";
import InputComp from "../../../../Components/common/textInput";
import APIHit from "../../../../utils/apiHit";
import { apiMainUser, checkErrors } from "../../../../utils/constant";
import { openSnackbar } from "../../../../utils/redux/SnackBarSlicer";
import { useDispatch } from "react-redux";


const EditPricing = (props) => {
  const [overwriteSpecialPrice, setOverwriteSpecialPrice] = useState(false);

  const { control, handleSubmit, register,formState: { errors }, } = useForm();
  const [showerror, setError] = useState(null);

  const dispatch = useDispatch()
  

  const [postData, setPostData] = useState(null);
  const [guestCount, setGuestCount] = useState(props.allData?.max_guests - props.allData?.extra_guests);

 
  let url = apiMainUser+"updatePropertyPref/" + props.propId;
  console.log(props.propId, "tulips");

  const handleGuestCountChange = (count) => {
    setGuestCount(Number(count));
  };


  

  const onSubmit = (data) => {
    console.log(data);
    
  
    // Set the overwrite_special_price only if the checkbox is checked
    const postDatavalue = {
      base_price: data.basePrice,
      weekend_price: data.weekendPrice || 0,
      extra_guests: props.allData?.max_guests - guestCount,
      ...(data.overwriteSpecialPrice ? { overwrite_all: 1 } : {})
    };
    setPostData(postDatavalue);
  };
  

  const handleSuccess = (res) => {
    if (res.status === "success") {
      props.closeModal()
      props.fetchData()
      dispatch(openSnackbar({ message: 'Price updated Successfully', severity: 'success' }));
    }
  };

  const handleFailure = (res) => {
    
    if (res && res.status === 404) {
      dispatch(openSnackbar({ message: 'Something went Wrong', severity: 'error' }));

      // setError("");
    } else {
      // setError("");
      dispatch(openSnackbar({ message: 'Something went Wrong', severity: 'error' }));
    }
  };

  const preCondition = () => {
    let ercheck = checkErrors(errors);
    console.log(ercheck, "djjd");
    return ercheck;
  };

  console.log(props?.allData,'allSum')
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form-pricing form-pricing-with-guest"
      >
        <div className="edit-pricing-guest-box">
          <div className="base-weekendprice-edit-box">
          <div className="form-row">
            <label htmlFor="basePrice" className="edit-pricing-form-label">
              Base price
            </label>
            <Controller
            control={control}
            name="basePrice"
            defaultValue={
              props.allData !== undefined ? props.allData?.base_price : ""
            }
            rules={{
              required: "Please enter a value",
              min: {
                value: 0,
                message: "Base Price cannot be less than 0",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <InputComp
                inputType="number"
                inputClass="edit-pricing-input"
                name="basePrice"
                // inpPlace="Enter your title here..."
                onKeyUp={(event) => {
                  onChange(event.target.value);
                  // console.log(event.target.value, "topi");
                  // onSubmit(event.target.value, true);
                }}
                defaultValue={
                  props.allData !== undefined ? props.allData?.base_price : ""
                }
                value={value}
              />
            )}
          />
          {errors.basePrice && <span>{errors.basePrice.message}</span>}




            <p className="edit-pricing-input-para">
              The base price for a Jumbo Holidayz rental is the starting price and does
              not include taxes or any other fees.
            </p>

              <Controller
            control={control}
            name="overwriteSpecialPrice"
            defaultValue={overwriteSpecialPrice}
            render={({ field }) => (
            <div className="checkbox-container large-checkbox-container">
              <input
                type="checkbox"
                id="overwriteSpecialPrice"
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  setOverwriteSpecialPrice(e.target.checked);
                }}
                defaultValue={overwriteSpecialPrice}
                checked={field.value}
              />
                  <label htmlFor="overwriteSpecialPrice">
                    Do you want to overwrite the special price?
                  </label>
                </div>
              )}
           />
                  </div>

                  <div className="weekprice-edit form-row">
                    <label htmlFor="basePrice" className="edit-pricing-form-label">
                        Weekend price
                        </label>
                        <Controller
                        control={control}
                        name="weekendPrice"
                        defaultValue={
                          props.allData !== undefined ? props.allData?.weekend_price : ""
                        }
                        rules={{
                          required: "Please enter a value",
                          min: {
                            value: 0,
                            message: "Base Price cannot be less than 0",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <InputComp
                            inputType="number"
                            inputClass="edit-pricing-input"
                            defaultValue={
                              props.allData !== undefined ? props.allData?.weekend_price : ""
                            }
                            name="weekendPrice"
                            // inpPlace="Enter your title here..."
                            onKeyUp={(event) => {
                              onChange(event.target.value);
                             
                            }}
                            value={value}
                          />
                        )}
                      />
                      {errors.weekendPrice && <span>{errors.weekendPrice.message}</span>}
                  </div>



                  </div>



                  <div className="guest-add-box">
                    <span style={{ fontSize: "1.4rem" }}>for</span>
                  
                    <AddToCart
                      cartCount="cart-count-box"
                      cartBtn="guest-cart-btn"
                      cartParent="cartParentdiv"
                      initialCount={props.allData?.max_guests - props.allData?.extra_guests}
                      maxCount={props.allData?.max_guests}
                      onCountChange={handleGuestCountChange}
                      />
                    <span style={{ fontSize: "1.4rem" }}>guests</span>
                  </div>
                </div>

        
        <APIHit
              handleSubmit={handleSubmit(onSubmit)}
              url={url}
              apidata={postData}
              successResponseCode={200}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              precondition={preCondition}
              method={"patch"}
              auth={true}
            >
              

         <div className="clear_apply-btn-box paddingCustomYSide">
          <p onClick={() => props.closeModal(false)} className="clear-btn">
            Cancel
          </p>
          <button className="save-edit-btn">Save</button>
        </div>
            </APIHit>
      </form>

    </>
  );
};

export default EditPricing;
