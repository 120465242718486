import React, { useEffect, useState } from "react";
import APIHit from "../../utils/apiHit";
import { apiMainUser, checkErrors } from "../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import {CleanCreateListingRedux} from "../../utils/constant";

import axios from "axios";
import {
  setPropertyData,
  setPlaceData,
  setRoomData,
  setDiscData,
  setRulesData,
  setBookingPrefrenceData,
  setConfirmAddressData,
  setAmenitiesData,
} from "../../utils/redux/listingTypeSlice";
import {
  updateAdditionalGuests,
  updateAdditionalBeds,
  updatePerNightCost,
  updateBaseGuest,
} from "../../utils/redux/PricingCounter";

import { setSelectedImages } from "../../utils/redux/dropboxSLicer";

import {
  updateGuests,
  updateBedrooms,
  updateBeds,
  updateBathrooms,
} from "../../utils/redux/CounterSlice";

import { useNavigate } from "react-router";
import {
  setAddonId,
  setPreferenceId,
  setPropId,
  setPropName,
  setAddonPropId,
} from "../../utils/redux/idSlice";

const Save_ExitComp = (props) => {
  const [callApis, setCallApis] = useState(null);
  const [callClick, setOnClick] = useState(null);
  const [validkeys, setValidkeys] = useState(null);
  //   const [addressData, setAddressData] = useState('')
  // const propertyId = sessionStorage.getItem("property");
  // console.log(propertyId);
  
  const cleanCreateListingRedux = CleanCreateListingRedux();

  const dispatch = useDispatch();
  const storeSel = useSelector((state) => state);
  const PropIddata = storeSel.apiDataSlice?.propId;

  const available_windowNew =
    storeSel.listingDataSlie?.bookingPrefrenceData?.availabiliityWindow || 0;

  const advance_windowNew =
    storeSel.listingDataSlie?.bookingPrefrenceData?.advanceWindow || 0;
  const base_priceNew = storeSel.pricingCartSlicer?.perNightCost || 0;
  const Weekend_price = storeSel.pricingCartSlicer?.weekendPrice || 0;

  const extra_guests_priceNew =
    storeSel.pricingCartSlicer?.additionalGuests || 0;
  const max_guests = storeSel.addCartSlice?.guests || 0;
  const bedrooms = storeSel.addCartSlice?.bedrooms;
  const beds = storeSel.addCartSlice?.beds;
  const bathrooms = storeSel.addCartSlice?.bathrooms;
  const max_staying =
    storeSel.listingDataSlie?.bookingPrefrenceData?.maximumStay || 0;
  const min_staying =
    storeSel.listingDataSlie?.bookingPrefrenceData?.minimumStay || 0;
  const addOnPrice = storeSel.pricingCartSlicer?.additionalBeds || null;

  const extraGuests =
    storeSel.addCartSlice?.guests - storeSel.pricingCartSlicer?.extraGuest;

  //   console.log(storeSel.listingDataSlie.confirmAddressData, "ooo");
  // const indexStore = storeSel.indexReducer.commonIndex

  const metaData = storeSel.listingDataSlie;

  useEffect(()=>{
    if(callClick !== null){
    const validkeysin = Object.entries(metaData)
    .filter(([key, value]) => key !== 'bookingPrefrenceData')
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .filter(item => item && item.metadata_key && item.metadata_value)
          .map(item => ({
            metadata_key: item.metadata_key,
            metadata_value: item.metadata_value
          }));
      } else if (value && value.metadata_key !== null && value.metadata_value !== null) {
        return [{
          metadata_key: value.metadata_key,
          metadata_value: value.metadata_value
        }];
      } else {
        return null;
      }
    })
    .filter(value => value !== null);
    setValidkeys(validkeysin)
    setCallApis(!callApis);
    }
  },[callClick])

  useEffect(()=>{
    if(callApis !== null){
      CallingAllApis();    
    }
  },[callApis])
  

  const navigate = useNavigate();
  // const navigateToCongrats = () => {
  //   const reviewPagePath = "/host/createlisting/reviewListing";
  //   if (window.location.pathname === reviewPagePath) {
  //     // navigate("/host/createlisting/hostCongrats"); // Navigate to congrats page
  //   } else {
  //     // navigate("/listing"); // Navigate to listing page for other pages
  //   }
  // };


   const handlePropertySuccess=(res)=>{
    if(res.data.code === 200){
      navigate("/host/listing");
    }
   }

  const handleSuccess = (res) => {    
    if (res.data.code === 201) {
      // console.log(res.data "yyy");
      dispatch(setPreferenceId(res.data.data.insertId));
      if(props.lastpage === undefined){
        navigate("/host/listing");
      }
    }
  };
  const handleSuccessMeta = (res) => {
    if (res.data.code === 201) {
      // navigate("/listing");      
      if(props.lastpage === undefined){
        navigate("/host/listing")
      }
    }
  };
  const handleSuccess2 = (res) => {
    if (res.data.code === 201) {
      // sessionStorage.setItem("addOnId", res.data.data.insertId);
      dispatch(setAddonId(res.data.data.insertId));
      if(props.lastpage === undefined){
        navigate("/host/listing")
      }
    }
  };
  const handleSuccess3 = (res) => {
    console.log(res.data.data, "tyy");
    if (res.data.code === 201 || res.data.status === "success") {
      // navigate("/listing");
      cleanCreateListingRedux();
      navigate("/host/createlisting/hostCongrats");
    }
  };

  const CallingAllApis = async () => {
    console.log("hey");
    console.log(validkeys);    
    
    try {
      const resPage = await axios({
        method: "patch",
        url: apiMainUser + props.apis[0],
        data: {
          list_age: props.path,
        },

        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then(handlePropertySuccess)

     
   
      if (props.apis.length >= 2 && validkeys.length > 0) {
        const res1 = await axios({
          method: "post",
          url: apiMainUser + props.apis[1],
          data: {
            property_id: PropIddata,
            meta: validkeys,
          },

          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }).then(handleSuccessMeta);
      }

      if (props.apis.length >= 3) {
        const res2 = await axios({
          method: props.method,
          url: apiMainUser + props.apis[2],
          data: {
            property_id: Number(PropIddata),
            available_window: available_windowNew,
            advance_window: advance_windowNew,
            base_price: base_priceNew,
            extra_guests: extraGuests,
            extra_guests_price: extra_guests_priceNew,
            weekend_price: Weekend_price,
            max_guests: max_guests,
            bedrooms: bedrooms,
            beds: beds,
            bathrooms: bathrooms,
            max_stay: max_staying,
            min_stay: min_staying,
          },

          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }).then(handleSuccess);
      }

      // if (props.apis.length >= 4) {
      //   console.log(props.apis[2], "tyy");
      //   const res3 = await axios({
      //     method: props.addMethod,
      //     url: apiMainUser + props.apis[3],
      //     data: {
      //       property_id: Number(PropIddata),
      //       addon_id: 4,
      //       addon_cost: addOnPrice,
      //       max_quantity:1
      //     },

      //     headers: {
      //       Authorization: "Bearer " + localStorage.getItem("token"),
      //     },
      //   }).then(handleSuccess2);
      //   // props.navigateto();
      // }

      if (props.apis.length >= 4) {
        const res3 = await axios({
          method: "post",
          url: apiMainUser + props.apis[3],
          // data: {},

          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }).then(handleSuccess3);
        // props.navigateto();
      }
      // dispatch(setConfirmAddressData(null));
      // dispatch(setDiscData(initialStateForDiscData));


      // dispatch(setRulesData(null));
      // window.location.reload();
      // navigateToCongrats();
    } catch (e) {
      console.log(e);
    }
  };



  const handleClick = async ()=>{
    if(props.firstFunction){
      await props.firstFunction()
    }
    setOnClick(!callClick)
  }

  //   console.log(confirmAddress, "pooh");
  return (
    <>
      {props.isButton ? (
        <button
          type="submit"
          onClick={handleClick}
          className="common-next-btn"
        >
          {props.btnTextExit}
        </button>
      ) : (
        <>
          {" "}
          {/* Place the content of the div here */}
          <div onClick={handleClick} className="save-exit-btn">
            Save & Exit
          </div>
        </>
      )}
    </>
  );
};

export default Save_ExitComp;
