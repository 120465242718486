import React from 'react'
import CardWrapper from '../Components/common/cardWrapper'
import SkeletonLoader from '../Components/common/SkeletonLoader'

const SkeletonReservationCard = () => {
  return (

    <CardWrapper wrapperClass="main-reservation-card">
        <div style={{padding:'1rem 0rem'  }}>
        <div className="main-reservation-card-grid">
          <div className="">
            <p className="main-reservation-card-left-div-first-text">
              <SkeletonLoader width={100} count={1} height={20}/>
              <SkeletonLoader width={100} count={1} height={20}/>

            </p>
            <p className="main-reservation-card-left-div-last-text">
              {/* 2 Guests, 1 Infant, 1 Pet */}
              {/* {props.noGuest} {props.noInfant} {props.noPets} */}
            </p>
          </div>
  
          {/* <NightText days={props.NightdaysCount} /> */}
          <SkeletonLoader width={200} count={1} height={40}/>
          <div>
            <p className="main-reservation-card-left-div-second-text">
            <SkeletonLoader width={100} count={1} height={20}/>
            </p>
            <p className="main-reservation-card-left-div-last-text">
            <SkeletonLoader width={100} count={1} height={20}/>
            </p>
          </div>
  
          
  
          <div className="flex justify-center" style={{flexDirection:'column'}}>
            
            <p className="main-reservation-card-right-div-para-text">
            <SkeletonLoader width={200} count={1} height={30}/>
            </p>
          
          </div>
        </div>
        </div>
      </CardWrapper>

  )
}

export default SkeletonReservationCard
