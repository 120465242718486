import React from 'react'
import { useNavigate } from 'react-router-dom'

const Terms_conditionPage = () => {

 

  return (
    
    <div className='terns-condition-page'>
      <h2 className='privacy-heading'>Terms & Conditions</h2>
      <div>
      <ol className='terms-condition-ol'>
        <li>Introduction: Welcome to Jumbo Holidayz. These Terms and Conditions ("T&amp;Cs") govern your access to and use
            of our website, services, and applications. Please read them carefully.</li>
        <li>Agreement to Terms: By accessing or using our platform, you acknowledge that you have read, understand, and
            agree to be bound by these T&amp;Cs.</li>
        <li>Registration and User Accounts:
            <ul className='terms-condition-ul'>
                <li>3.1 Age Requirement: Users must be at least 18 years old. By registering, you represent that you meet
                    this age requirement.</li>
                <li>3.2 Account Security: You are responsible for safeguarding your login credentials. Any unauthorized
                    use should be immediately reported to our support team.</li>
                <li>3.3 Account Information: Ensure all your account information is accurate and up-to-date.</li>
            </ul>
        </li>
        <li>Property Listings, Bookings, and Payments:
            <ul className='terms-condition-ul'>
                <li>4.1 Listings: Hosts can freely list properties, ensuring accurate depiction through descriptions and
                    photos.</li>
                <li>4.2 Bookings: While hosts set their rates and availability, guests are responsible for reviewing and
                    accepting these conditions during booking.</li>
                <li>4.3 Payments: All financial transactions occur securely through our platform. Jumbo Holidayz might
                    charge a service fee, which will be deducted before hosts receive their payouts.</li>
                <li>4.4 Cancellations: Each host sets their cancellation and refund policies. Guests must abide by these
                    terms when cancelling a booking.</li>
            </ul>
        </li>
        <li>Conduct and Use of Platform:
            <ul className='terms-condition-ul'>
                <li>5.1 Respectful Interaction: Harassment, discrimination, or any harmful behavior towards other users is
                    prohibited.</li>
                <li>5.2 Prohibited Uses: Users must not misuse the platform, including illegal activities, spreading
                    malware, spamming, or violating any of our policies.</li>
            </ul>
        </li>
        <li>Intellectual Property:
            <ul className='terms-condition-ul'>
                <li>6.1 Ownership: All platform content, including text, graphics, logos, and images, belongs to Jumbo
                    Holidayz, protected by international copyright and intellectual property laws.</li>
                <li>6.2 Restrictions: Users may not reproduce, distribute, or create derivative works without explicit
                    permission.</li>
            </ul>
        </li>
        <li>Dispute Resolution and Limitations:
            <ul className='terms-condition-ul'>
                <li>7.1 Mediation: Jumbo Holidayz may help mediate disputes but isn't obligated to and isn't liable for any
                    resolution outcomes.</li>
                <li>7.2 Liability: Jumbo Holidayz isn't liable for indirect damages, lost profits, or incidental expenses
                    arising from using the platform.</li>
            </ul>
        </li>
        <li>Modifications to Terms and Platform:
            <ul className='terms-condition-ul'>
                <li>8.1 Changes to T&amp;Cs: We reserve the right to modify these T&amp;Cs periodically. Users will be
                    notified of significant changes and continued use denotes acceptance.</li>
                <li>8.2 Platform Updates: Jumbo Holidayz may update, modify, or even temporarily suspend the platform for
                    maintenance or updates.</li>
            </ul>
        </li>
        <li>Governing Law and Jurisdiction:
            <ul className='terms-condition-ul'>
                <li>9.1 Law: These T&amp;Cs are governed by the laws of India without regard to its conflicts of law
                    rules.</li>
                <li>9.2 Courts: Disputes arising will be resolved in the courts of India.</li>
            </ul>
        </li>
        <li>Miscellaneous:
            <ul className='terms-condition-ul'> 
                <li>10.1 Severability: If any provision is found to be unenforceable, the remaining provisions remain in
                    full effect.</li>
                <li>10.2 No Waiver: Failure to enforce any right or provision isn't deemed a waiver of such right.</li>
            </ul>
        </li>
    </ol>
      </div>
    </div>
  
  )
}

export default Terms_conditionPage
