import React, { useEffect, useState } from "react";
import UserPropertyDetailsIn from "./PropertyDetails/DetailsIn";
import APIHit from "../../utils/apiHit";
import { apiPublicUser } from "../../utils/constant";
import SkeletonLoader from "../../Components/common/SkeletonLoader";

const UserPropertyDetails = (props) => {
  const [apicall, setApiCall] = useState(false);
  const [successdata, setSuccessData] = useState([]);
  const [assetsurl, setAssetsUrl] = useState();
  const slug = window.location.pathname.replace("/properties/","")
  console.log(slug);


  // console.log(successdata?.[0].wishlist , 'papz')

  
  const handleSuccess = (response) => {
    // Handle the successful response here  
    setAssetsUrl(response.assets_url);
    setSuccessData(response.data);
    props.setLatitude()
  };
  const handleFailure = (data) => {
    // Handle the successful response here
    console.log("Failure response:", data);
  };
  const runPrecondition = () => {
    // Perform your precondition check here
    // Return true if the precondition is met, or false otherwise
    if (successdata !== []) {
      return false;
    }
    return true;
  };  
  useEffect(() => {
    setApiCall(true);
  }, []);
  return (
    <>
    {apicall === false && (      
        <APIHit
          url={apiPublicUser + "search_property/?slug="+slug}
          method="GET"
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          precondition={runPrecondition}
          successResponseCode={200}
          // apidata={amenitiesData}
          directCall={true}
          auth={true}
        ></APIHit>
      )}
      {apicall === true &&
      <UserPropertyDetailsIn

        propertyId={successdata[0]?.propertyid}
        propertyName={successdata ?  successdata[0]?.property_name : undefined}//
        starNum="4.6"
        reviewNum="6"
        mainImg={successdata[0]?.base_url_image+successdata[0]?.images[0]}
        smallImg={successdata ? successdata[0]?.images : undefined}//
        base_urlImg={successdata[0]?.base_url_image}

        numGuest={successdata ?  successdata[0]?.max_guests : undefined}//
        numBedrooms={successdata ?  successdata[0]?.bedrooms : undefined}
        numBeds={successdata ?  successdata[0]?.beds : undefined}//
        numBathroom={successdata ? successdata[0]?.bathrooms : undefined}//
        // location={successdata ?  successdata[0]?.city+ ", "+ successdata[0]?.state  :  undefined }//
        city={successdata ?  successdata[0]?.city :  undefined }
        state={successdata ?  successdata[0]?.state :  undefined }
        about={successdata ?  successdata[0]?.description : undefined}//
        rules={successdata  ? successdata[0]?.rules : undefined}//
        amenities_details={successdata  ? successdata[0]?.amenities_details : undefined}//
        hostDetails={successdata ?  successdata[0]?.host_details[0] : undefined}//
        CohostDetails={successdata ?  successdata[0]?.cohost_details[0] : undefined}//

        coHostName="Zubair khan"
        allData={successdata[0] }
       guestImgData={successdata ?  assetsurl+successdata[0]?.host_details[0]?.image : undefined }//
       coHostImg = {successdata ?  assetsurl+successdata[0]?.cohost_details[0]?.image : undefined }
       id={successdata[0]?.propertyid} 
       wishListApplied={successdata[0]?.wishlist} 
      // setLatitude={props.setLatitude}
      // 
      />
    }
    </>
  );
};

export default UserPropertyDetails;
