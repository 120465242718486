
import React, { useState } from "react";
import EditSaveBtn from "../EditSaveBtn";
import { useForm, Controller } from "react-hook-form";
import { apiMainUser, checkErrors } from "../../../../utils/constant";
import APIHit from "../../../../utils/apiHit";
import InputComp from "../../../../Components/common/textInput";
import { openSnackbar } from "../../../../utils/redux/SnackBarSlicer";
import { useDispatch } from "react-redux";

const EditAddonComponent = (props) => {
  // console.log(props.closeModal, props.fetchData, 'kkd');
  console.log(props.quantifiable,'kkd');

  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [showerror, setError] = useState(null);

  const [postData, setPostData] = useState(null);

  const addonId = props.selectedAddonData;


  const addon = props.allData?.addons_details.find(
    (addon) => addon.addon_id === addonId
  );

  
  console.log(addon,'mubin')
  const addonCost = addon ? addon.addon_cost : null;
  const addonMaxQuantity = addon ? addon.max_quantity : null;



  console.log(addonCost, "rey");

  const url =
    addonCost !== null
      ? apiMainUser + "update_property_addons/" + addon.uid
      : apiMainUser + "add_property_addons";

  const onSubmit = (data) => {
    console.log(data, 'uu');
    const postDatavalue = {
      property_id: Number(props.propId),
      addon_id: props.selectedAddonData,
      addon_cost: data[props.selectedAddonName],
      // Include the maximum quantity only if quantifiable is 1
      ...(props.quantifiable === 1 && { max_quantity: data.max_quantity }),
    };
    setPostData(postDatavalue);
    console.log(data, 'uu');
  };

  console.log(postData, props.selectedAddonData, props.selectedAddonName, 'ggi');

  const handleSuccess = (res) => {
    console.log(res,'checkresponse')
    if (res.status === "success") {
      console.log('calledFirst')
      props.closeModal();
      props.fetchData();
      console.log('calledSecond')
      dispatch(openSnackbar({ message: 'Addon added succesfully!', severity: 'success' }));


    }
  };

  const handleFailure = (res) => {
    console.log(res);
    if (res && res.status === 404) {
      setError("");
    } else {
      setError("");
    }
  };

  const preCondition = () => {
    let ercheck = checkErrors(errors);
    console.log(ercheck, "djjd");
    return ercheck;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}  className="form-pricing" style={{marginTop:'2rem'}}>
        <div className="form-row">
         
          <Controller
            control={control}
            defaultValue={addonCost !== undefined ? addonCost : ""}
            name={props.selectedAddonName}
            rules={{
              required: "Please enter a value",
              min: {
                value: 0,
                message: "Price cannot be less than 0",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <div>
                <InputComp
                max_quantity

                  inputType="number"
                  inputClass="edit-pricing-input"
                  name={props.selectedAddonName}
                  // inpPlace="Enter your title here..."
                  onKeyUp={(event) => {
                    onChange(event.target.value);
                    // console.log(event.target.value, "topi");
                    // onSubmit(event.target.value, true);
                  }}
                  value={value}
                  defaultValue={addonCost !== undefined ? addonCost : ""}
                />
                {errors[props.selectedAddonName] && (
                  <p className="error-message">
                    {errors[props.selectedAddonName].message}
                  </p>
                )}
              </div>
            )}
          />

<p className="edit-pricing-input-para">{props.addonDiscription.charAt(0).toUpperCase() + props.addonDiscription.slice(1)}</p>


        </div>

        {props.quantifiable === 1 && (
          <div className="form-row">
            <label htmlFor="max_quantity" className="form-label mb-5 font-xs">
              Maximum Quantity
            </label>
            <Controller
              control={control}
              defaultValue={addonMaxQuantity !== undefined ? addonMaxQuantity : ""}
            
              name="max_quantity"
              rules={{
                required: "Maximum quantity is required",
                min: {
                  value: 1,
                  message: "Maximum quantity must be at least 1",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <div>
                  <InputComp
                    inputType="number"
                    inputClass="edit-pricing-input"
                    name="max_quantity"
                    defaultValue={addonMaxQuantity !== undefined ? addonMaxQuantity : ""}
                    onKeyUp={(event) => {
                      onChange(event.target.value);
                    }}
                    value={value}
                  />
                  {errors.max_quantity && (
                    <p className="error-message">{errors.max_quantity.message}</p>
                  )}
                </div>
              )}
            />
          </div>
        )}

        <APIHit
          handleSubmit={handleSubmit(onSubmit)}
          url={url}
          apidata={postData}
          method={addonCost !== null ? "patch" : "post"}
          successResponseCode={addonCost !== null ? 200 : 201}
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          precondition={preCondition}
          auth={true}
        >
          <div className="clear_apply-btn-box paddingCustomYSide">
          <p onClick={() => props.closeModal(false)} className="clear-btn">
              Cancel
            </p>
            <button className="save-edit-btn">Save</button>
          </div>
        </APIHit>
      </form>
    </>
  );
};

export default EditAddonComponent;
