import React from 'react'
import CardWrapper from './cardWrapper'

const TripCardComp = (props) => {
  return (
    <div className={props.tripCardParent}>
      <CardWrapper wrapperClass='trips-card'>
        <div className='trips-card-left-content'>

        <div className='trips-property-name-type-text'>
           <p className='property-name-para'>{props.propertyName}</p> 
           <p className='space-type-para'>{props.spaceType} hosted by <span>{props.hostName}</span></p> 
        </div>

        <div className="trips-detail-div">
        <div className='trips-check-in-checkout-div'>
            <p className='trips-data-para'>{props.checkInOut}</p>

            <p className='trips-data-subpara'>{props.noOfNight} {props.noOfNight > 1  ? 'nights' : 'night'}</p>
         </div>
         <div className='trips-address-div'>
          <p className='trips-data-para'>{props.address}</p>
          <p className='trips-data-subpara'>{props.country}</p>
         </div>
        </div>

        </div>

        <div className='trips-card-right-img-box'>
          <img src={props.propImage} alt="" />  
        </div>

      </CardWrapper>
    </div>
  )
}

export default TripCardComp
