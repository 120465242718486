
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import InputComp from '../../Components/common/textInput';
import Button from '../../Components/common/Button';
import { apiMainUser } from '../../utils/constant';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { openSnackbar } from '../../utils/redux/SnackBarSlicer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const HostTaxesDetail = () => {
  const [usersData, setUsersData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  
  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm(
  //   defaultValues:{
  //     firstName:
  //       usersData && usersData[0]?.fname !== "null"
  //         ? usersData[0]?.fname
  //         : "",
  //         surname:
  //       usersData && usersData[0]?.lname !== "null"
  //         ? usersData[0]?.lname
  //         : "",
  //         street:
  //       usersData && usersData[0]?.address !== "null"
  //         ? usersData[0]?.address
  //         : "",
  //         pincode:
  //       usersData && usersData[0]?.pincode !== "null"
  //         ? usersData[0]?.fname
  //         : "",
  //         city:
  //       usersData && usersData[0]?.city !== "null"
  //         ? usersData[0]?.fname
  //         : "",
  //         state:
  //         usersData && usersData[0]?.state !== "null"
  //           ? usersData[0]?.state
  //           : "",
  //           pan_no:
  //           usersData && usersData[0]?.pan_card !== "null"
  //             ? usersData[0]?.pan_card
  //             : "",
  //   }
  // });
  );

  const fetchData = async () => {
    try {
      const response = await axios.get(apiMainUser + "get_tax_details", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setUsersData(response.data.data);
      setIsDataFetched(true)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [hasGST, setHasGST] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.pdf, image/*',
  });

  const Taxurl = usersData?.length > 0 ? 'update_tax_details' : 'create_tax_details';

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('fname', data.firstName);
      formData.append('lname', data.surname);
      formData.append('address', data.street);
      formData.append('state', data.state);
      formData.append('city', data.city);
      formData.append('pincode', data.pincode);
      formData.append('pan_card', data.pan_no);
      formData.append('gst_id', data.gstNumber || '');
      formData.append('trade_name', data.gstName || '');
      if(selectedFile){
        formData.append('file', selectedFile );
      }
        
      

      const response = await axios({
        method: usersData?.length > 0 ? 'patch' : 'post',
        url: apiMainUser + Taxurl,
        data: formData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });

      if (response.data.status === 'success') {
        // Success case handling
        dispatch(openSnackbar({ message: ' Updated succesfully!', severity: 'success' }));
        fetchData()

      } else {
        // Failure case handling
      }
    } catch (error) {
      console.error(error);
      dispatch(openSnackbar({ message: error.response.data.message, severity: 'error' }));

    }
  };
console.log(usersData,'iim')

const handleViewImage = (e, imageUrl) => {
  e.preventDefault();
  window.open(imageUrl, '_blank');
};

const navigate = useNavigate()


const navigateToHostAccountPage =()=>{
  navigate('/hostAccount')
}


  return (
    <>
    {isDataFetched && (
    <div className='account-detail-page host-taxes-page'>
      <p style={{ fontSize: '1.2rem', fontWeight: '500' }}><span style={{cursor:'pointer'}} onClick={navigateToHostAccountPage}>Account</span> > Taxes</p>
      <h2 className='account-head-text'>Taxes</h2>
      <h4>Add a Few details about your Taxpayer Status</h4>

      <form className="tax-address-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-input-box">
          <div className="single-input-box">
            <Controller
              control={control}
              name="firstName"
              defaultValue={usersData[0]?.fname ? usersData[0]?.fname : ''}
              rules={{
                required: "First name is required",
              }}
              render={({ field: { onChange, value } }) => (
                <InputComp
                  inputType="text"
                  inputClass="input-tax"
                  name="firstName"
                  inpPlace="First Name"
                  defaultValue={usersData[0]?.fname}

                  onKeyUp={onChange}
                  value={value}
                />
              )}
            />
            {errors.firstName && <span>{errors.firstName.message}</span>}
          </div>

          <div className="single-input-box">
            <Controller
              control={control}
              name="surname"
              defaultValue={usersData[0]?.lname ? usersData[0]?.lname : '' }
              rules={{
                required: "Surname is required",
              }}
              render={({ field: { onChange, value } }) => (
                <InputComp
                  inputType="text"
                  defaultValue={usersData[0]?.lname ? usersData[0]?.lname : '' }
                  inputClass="input-tax"
                  name="surname"
                  inpPlace="Surname"
                  onKeyUp={onChange}
                  value={value}
                />
              )}
            />
            {errors.surname && <span>{errors.surname.message}</span>}
          </div>
        </div>

        <div className="single-input-box street-inputbox">
          <Controller
            control={control}
            name="street"
            defaultValue={usersData[0]?.address  ? usersData[0]?.address  : '' }
            
            rules={{
              required: "Street info is required",
            }}
            render={({ field: { onChange, value } }) => (
              <InputComp
                inputType="text"
                inputClass="address-input"
                name="street"
                inpPlace="Street Address"
                onKeyUp={onChange}
                value={value}
                defaultValue={usersData[0]?.address  ? usersData[0]?.address  : '' }
              />
            )}
          />
          {errors.street && <span>{errors.street.message}</span>}
        </div>

        <div className="flex-input-box">
          <div className="single-input-box">
            <Controller
              control={control}
              name="pincode"
              defaultValue={usersData[0]?.pincode  ? usersData[0]?.pincode : '' }

              rules={{
                required: "Pincode info is required",
              }}
              render={({ field: { onChange, value } }) => (
              <InputComp
                  inputType="number"
                  inputClass="input-tax"
                  name="pincode"
                  inpPlace="Pincode"
                  onKeyUp={onChange}
                  value={value}
                  defaultValue={usersData[0]?.pincode  ? usersData[0]?.pincode : '' }
                />
              )}
            />
            {errors.pincode && <span>{errors.pincode.message}</span>}
          </div>

          <div className="single-input-box">
            <Controller
              control={control}
              name="city"
              defaultValue={usersData?.length > 0 ? usersData[0].city : ""}
            

              rules={{
                required: "City info is required",
              }}
              render={({ field: { onChange, value } }) => (
                <InputComp
                  inputType="text"
                  inputClass="input-tax"
                  name="city"
                  inpPlace="City"
                  onKeyUp={onChange}
                  value={value}
                  defaultValue={usersData[0]?.city  ? usersData[0]?.city  : '' }
                />
              )}
            />
            {errors.city && <span>{errors.city.message}</span>}
          </div>

          <div className="single-input-box">
            <Controller
              control={control}
              name="state"
              defaultValue={usersData?.length > 0 ? usersData[0].state : ""}
           

              rules={{
                required: "State info is required",
              }}
              render={({ field: { onChange, value } }) => (
                <InputComp
                  inputType="text"
                  inputClass="input-tax"
                  name="state"
                  inpPlace="State"
                  onKeyUp={onChange}
                  value={value}
                  defaultValue={usersData[0]?.state  ? usersData[0]?.state  : '' }
                />
              )}
            />
            {errors.state && <span>{errors.state.message}</span>}
          </div>
        </div>

        <div className="flex-input-box">
          <div className="single-input-box" style={{alignSelf:'flex-start'}}>
            <Controller
              control={control}
              name="pan_no"
              defaultValue={usersData?.length > 0 ? usersData[0].pan_card : ""}
           

              rules={{
                required: "PAN is required",
              }}
              render={({ field: { onChange, value } }) => (
                <InputComp
                  inputType="text"
                  inputClass="input-tax"
                  name="pan_no"
                  inpPlace="Enter PAN"
                  onKeyUp={onChange}
                  value={value}
                  defaultValue={usersData[0]?.pan_card  ? usersData[0]?.pan_card  : '' }
                />
              )}
            />
            {errors.pan_no && <span>{errors.pan_no.message}</span>}
          </div>

          <div style={{height:'20px'}} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the file here...</p>
            ) : (
              <p
                style={{ textAlign: 'center', cursor: 'pointer' }}
                className='save-edit-btn'
              >
               { usersData !==[] && usersData[0]?.pan_doc !== null ? ' Doc Already uploaded' : " Upload file (jpg, png, ...)"}
              </p>
            )}
            {preview && (
              <div style={{ width: '150px', height: '150px', marginBottom: '2rem', marginTop: '2rem' }}>
                {selectedFile.type.includes('image') ? (
                  <img style={{ width: '100%', height: '100%' }} src={preview} alt="Selected" />
                ) : (
                  <iframe title="Selected PDF" style={{ width: '100%', height: '100%' }} src={preview}></iframe>
                
                )}
              </div>
            )}

          
          {usersData !== [] && usersData[0]?.pan_doc && (
          <div style={{ width: '150px', height: '150px', marginBottom: '2rem', marginTop: '1rem' }}>
            <a style={{textDecoration:'underline',fontWeight:'500',fontSize:'13px'}} href="#" onClick={(e) => handleViewImage(e, usersData[0]?.pan_doc)}>View</a>
          </div>
        )}



            
            
          </div>
        </div>

        <div className="single-checkox">
          <label>I have a GST number</label>
          <input
            type="checkbox"
            checked={hasGST}
            onChange={() => setHasGST(!hasGST)}
          />
        </div>

        {hasGST && (
          <div className="flex-input-box mt-30">
            <div className="single-input-box">
              <Controller
                control={control}
                name="gstName"
                defaultValue={usersData?.length > 0 ? usersData[0].trade_name : ""}
                rules={{
                  required: "GST Name is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="text"
                    inputClass="input-tax"
                    name="gstName"
                    inpPlace="GST Name"
                    onKeyUp={onChange}
                    value={value}
                    defaultValue={usersData?.length > 0 ? usersData[0].trade_name : ""}
                  />
                )}
              />
              {errors.gstName && <span>{errors.gstName.message}</span>}
            </div>

            <div className="single-input-box">
              <Controller
                control={control}
                name="gstNumber"
                defaultValue={usersData?.length > 0 ? usersData[0].gst_id : ""}
                rules={{
                  required: "GST Number is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="text"
                    inputClass="input-tax"
                    name="gstNumber"
                    inpPlace="GST Number"
                    onKeyUp={onChange}
                    value={value}
                    defaultValue={usersData?.length > 0 ? usersData[0].gst_id : ""}
                  />
                )}
              />
              {errors.gstNumber && <span>{errors.gstNumber.message}</span>}
            </div>
          </div>
        )}

        <div  className="mt-65 text-center" >
          
          <Button
            btnText="Save"
            btnclasses="save-edit-btn"
          />
        </div>

      
      </form>
    </div>
    )}
    </>
  );
}

export default HostTaxesDetail;
