import React from "react";

const PropertyListing = (props) => {
  return (
    <div
      onClick={props.onClick !== undefined ? props.onClick : null}
      className="property-listing-parent"
    >
      <div className="img-parent-box" style={{ overflow: "hidden" }}>
        <img
          src={props.src}
          alt={props.alt}
          className={props.className}
          width={props.width}
          height={props.height}
        />
      </div>
      <p className={props.propListingText}>{props.paraText}</p>
    </div>
  );
};

export default PropertyListing;
