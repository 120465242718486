

import React, { useEffect, useState } from "react";

const AddToCart = (props) => {
  // const [count, setCount] = useState(props.initialCount ? parseInt(props.initialCount,10) : 1);
   const [count, setCount] = useState(props.initialCount || props.initialCount === 0  ? parseInt(props.initialCount,10) : 1);

  
  // const [count, setCount] = useState(props.initialCount !== undefined ? parseInt(props.initialCount,10) : 1);

  // const [maxcount, setMaxCount] = useState(props.maxCount ? parseInt(props.maxCount,10) : null);
  // const [minCount, setMinCount] = useState(props.minCount ? parseInt(props.minCount,10) : 1);

  const [maxcount, setMaxCount] = useState(props.maxCount || props.maxCount === 0 ? parseInt(props.maxCount,10) : null);


  const [minCount, setMinCount] = useState(props.minCount || props.minCount === 0 ? parseInt(props.minCount,10) : 1);

  useEffect(() => {
    setCount(props.initialCount || props.initialCount === 0  ? parseInt(props.initialCount,10) : 1);
  }, [props.initialCount]);

  useEffect(() => {
    setMaxCount(props.maxCount || props.maxCount === 0 ? parseInt(props.maxCount,10) : null);
  }, [props.maxCount]);

  useEffect(() => {
    setMinCount(props.minCount || props.minCount === 0 ? parseInt(props.minCount,10) : 1);
  }, [props.minCount]);

  const handleIncrement = () => {    

    if(maxcount === minCount) return;
    if (count === maxcount && maxcount !== null) return;
    const newCount = count + 1;
    setCount(newCount);
    if (props.increamentVal) {
      props.increamentVal(newCount);
    }
    if (props.onCountChange) {
      props.onCountChange(newCount);
    }
  };

  const handleDecrement = () => {
    if(maxcount === minCount) return;
    if (count === minCount && minCount !== null) return;
    const newCount = count - 1;
    setCount(newCount);
    if (props.increamentVal) {
      props.increamentVal(newCount);
    }
    if (props.onCountChange) {
      props.onCountChange(newCount);
    }
    
  };

  return (
    <>
      <div className={props.cartParent}>
        <div className={props.cartBtn} onClick={handleDecrement}>
          -
        </div>
        <span className={props.cartCount}>{count}</span>
        <div className={props.cartBtn} onClick={handleIncrement}>
          +
        </div>
      </div>
    </>
  );
};

export default AddToCart;

