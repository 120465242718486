import React from "react";
import Lottie from "react-lottie";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Button from "../../../../Components/common/Button";
import HeadingComp from "../HeadingComp";
import axios from "axios";
import { apiMainUser } from "../../../../utils/constant";

const RemoveUser = (props) => {
  const propertyId = props.propertyid;

  const deleteUser = async () => {

    try {
      const token = localStorage.getItem("token"); // Retrieve the token from localStorage
      const propertyId = props.listingData?.data?.[0].propertyid

// Replace with your main API URL

      // Make a DELETE request using axios with headers and API endpoint
      const response = await axios.delete(
        `${apiMainUser}delete_cohost_to_property/${propertyId}`,
        {
          headers: {
           
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        props.fetchData()
        props.closeModal(false);

        
      } else {
        
      }
    } catch (error) {
      // Handle axios errors or exceptions if any
      console.error("Error deleting user:", error);
    }
  };

  
  return (
    <div className="remove-user-main-div paddingCustom">
      <Player
        autoplay
        loop
        style={{ height: "150px", width: "150px" }}
        src="./assets/lottie/exclamation.json"
      >
        <Controls
          visible={false}
          buttons={["play", "repeat", "frame", "debug"]}
        />
      </Player>

      <HeadingComp headingText="Remove Co-Host?" />
      <p style={{marginTop:'0.5rem'}}>Are you sure you want to remove username as a co-host?</p>
      <div className="clear_apply-btn-box paddingCustomYSide">
        <p onClick={() => props.closeModal(false)} className="clear-btn">
          No
        </p>
        <Button btnText="Yes" btnclasses="save-edit-btn"  onClick={deleteUser}/>
      </div>
    </div>
  );
};

export default RemoveUser;
