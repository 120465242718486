import { createSlice, configureStore } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  full_Name: null,
  user_Name:null,
  auth_Token: null,
   Image:null,
   host_Uid:null,
   host_Email:null,
};

// Create a slice using createSlice
const hostDetailSlice = createSlice({
  name: "hostDetailSlice",
  initialState,
  reducers: {
    fullNameAction: (state, action) => {
        state.full_Name = action.payload;
    },
    usernameAction: (state, action) => {
      state.user_Name = action.payload;
    },

    tokenAction: (state, action) => {
      state.Token = action.payload;
    },
    getImage:(state,action)=>{
      state.Image = action.payload;

    },
    getUid:(state,action)=>{
      state.host_Uid = action.payload;

    },
    getEmail:(state,action)=>{
      state.host_Email= action.payload;

    }

    
   
  },
});
export const {
   fullNameAction,
   usernameAction,
   tokenAction,
   getImage,
   getUid,
   getEmail
   
} = hostDetailSlice.actions;
export const HostDetailReducer = hostDetailSlice.reducer;
