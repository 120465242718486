import React from 'react'
import { Link } from 'react-router-dom'

const HostFooterComp = () => {
  return (
    <div className='host-footer flex'>
        <Link className="host-terms-link" to='/ContactUs'>Contact us</Link>
         <Link className="host-terms-link" to='/privacypolicy'>Privacy Policy</Link>
         <Link className="host-terms-link" to='/termscondition'>Terms & Conditions</Link>
         <Link className="host-terms-link" to='/Blog'>Blog</Link>
        <Link className="host-terms-link" to='/Careers'>Careers</Link>
        <br/>
        <p className='align-end'>&copy; 2023 Jumbo Holidayz</p>
    </div>
  )
}

export default HostFooterComp
