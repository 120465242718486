
import React, { useEffect, useState } from "react";
import CardWrapper from "../../../Components/common/cardWrapper";
import ProfileImage from "../../../Components/common/profileImg";
import Button from "../../../Components/common/Button";
import ReservationCard from "../../../Components/HostComponents/reservation-card";
import { apiMainUser, apiPublicUser } from "../../../utils/constant";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DateRangeDisplay from "../../../Components/common/DateRangeDisplay";
import SkeletonLoader from "../../../Components/common/SkeletonLoader";
import SkeletonReservationCard from "../../../MobSkeleton/SkeletonReservationCard";
import SkeletonTrendingProperty from "../../../MobSkeleton/SkeletonTrendingProperty";
import NoDataLottie from "../../../Components/common/NoDataLottie";
import FooterComponent from "../../../Pages/common/FooterComponent";
import HostFooterComp from "../../../Pages/common/HostFooterComp";

const ReserveContentComp = (props) => {
  const [sortingOption, setSortingOption] = useState("upcoming");

  

  const isSmallScreen = window.innerWidth <= 500;


  const navigate = useNavigate()

  const storeSel = useSelector((state) => state);

  const fullNameData = storeSel.hostDetailSlice?.full_Name;

  const handleSortingOptionClick = (option = "upcoming") => {    
    setSortingOption(option);
  };


  const isOptionActive = (option) => {
    return sortingOption === option ? "active-sort" : "";
  };

  const [successdata, setSuccessData] = useState([]);
  const [metadata, setMetaData] = useState([]);



  const fetchDatapProperty = async () => {
    try {
      const response = await axios.get(apiMainUser + "read_booking_for_host_property", {
        params: {
          filter:sortingOption ,
          // status:"captured"
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.data.status === 'success') {
        setSuccessData(response.data.data);
        setMetaData(response.data.host_earning)
      } else {
        setSuccessData("No Property Found");
      }
    } catch (error) {
      setSuccessData("No Property Found");
    }
  };

  useEffect(() => {
    fetchDatapProperty();
    
  }, [sortingOption]);


  useEffect(() => {
    handleSortingOptionClick();
  }, []);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
  const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];
    return `${date.getUTCDate()} ${monthNames[date.getUTCMonth()]}`;
  };

  return (
    <div className="reserve-content-box">
      <h4 className="welcome-text">Welcome Back</h4>
      <div className="reserve-host-box">
        <div className="reserve-host-text-box">
          <h3 className="host-name text-center">{fullNameData}</h3>
          
        </div>
        <div className="resrvation-analytics-card-box">
          <CardWrapper wrapperClass="resrvation-analytics-card">
            <ProfileImage src="./assets/img/circle.png" className="" width="100%" height="100%" />
            <div className="reserve-analytics-text-box">
              <p className="reserve-analytics-first-text">Total Bookings</p>
              <p className="reserve-analytics-mid-text">
                  {metadata?.current_month_booking_count ? 
                    metadata.current_month_booking_count : "0"
                  }
                </p>

                <p className="reserve-analytics-last-text">
                <span className="reserve-anaylytics-span-text">
                  {(metadata && metadata.booking_growth_percent) ? 
                    parseFloat(metadata.booking_growth_percent).toFixed(0) : "0"
                  }%
                </span>
                this month
              </p>

            </div>
          </CardWrapper>

          <CardWrapper wrapperClass="resrvation-analytics-card">
            <ProfileImage src="./assets/img/circleEarning.png" className="" width="100%" height="100%" />
            <div className="reserve-analytics-text-box">
              <p className="reserve-analytics-first-text">Total Earnings</p>
              <p className="reserve-analytics-mid-text">{metadata && "₹"}
              {metadata?.current_month_total_amount ? 
              parseFloat(metadata.current_month_total_amount).toFixed(2) : '0'
                 }
           </p>
           <p className="reserve-analytics-last-text">
              <span className="reserve-analytics-span-text">
                {metadata?.revenue_growth_percent ? 
                  parseFloat(metadata.revenue_growth_percent).toFixed(0) : '0'
                }
              </span>
              this month
            </p>

            </div>
          </CardWrapper>
        </div>
      </div>
      <p className="your-reservation ">Your Reservations</p>

      <div className="reserve-filter-btn-box-parent">

      <div className="reserve-filter-btn-box">
        <Button btnText="Upcoming" 
       onClick={() => handleSortingOptionClick("upcoming")}
       btnclasses={`reserve-filter-btn ${isOptionActive("upcoming")}`}
       
       />
       
        <Button btnText="Ongoing" 
        onClick={() => handleSortingOptionClick("ongoing")}
        btnclasses={`reserve-filter-btn ${isOptionActive("ongoing")}`}
        
        />
        <Button btnText="Arriving Soon" 
        onClick={() => handleSortingOptionClick("arriving")}
        btnclasses={`reserve-filter-btn ${isOptionActive("arriving")}`}
        
        />
         <Button btnText="Passed"
        onClick={() => handleSortingOptionClick("passed")}
        btnclasses={`reserve-filter-btn ${isOptionActive("passed")}`}
        
        />
          <Button btnText="Cancelled" 
        onClick={() => handleSortingOptionClick("refunded")}
        btnclasses={`reserve-filter-btn ${isOptionActive("refunded")}`}
        
        />


      {!isSmallScreen &&  
      <NavLink className="all-reservation-link" to="reservations">
        <div className="all-reservation-link-text">All Reservations</div>
       </NavLink>
} 

      </div>
      </div>
      {isSmallScreen &&  
      <NavLink className="all-reservation-link" to="reservations">
        <div className="all-reservation-link-text">All Reservations</div>
       </NavLink>
} 



      {successdata === "No Property Found" || successdata.length === 0  ? (
    <>
    <NoDataLottie jsonLotty='./assets/lottie/tripnotFound.json' height="200px" width="300px"/>

     </>
) : (

<div className="reserve-card-box">
    

   
   {successdata ? successdata?.map((data) => {


  return (
    <Link className="host-reservation-link" to={`/host/reservations/${data.booking_order_id}`}>
    <ReservationCard
    key={data.uid}
    guestName={data.fname + " " + data.lname}
    noGuest={data.transaction_details.guest_count}
    propertName={data.property_name}
  checkInOut={<DateRangeDisplay startDate={data.checkin_date} endDate={data.checkout_date} />}
    checkInDate={data?.checkin_date}
    checkOutDate={data?.checkout_date}
    NightdaysCount={moment(data?.checkout_date).startOf('day').diff(moment(data?.checkin_date).startOf('day'), "days")}
  />
  </Link>
  );


})    : successdata?.slice(0, 5).map((data) => <SkeletonReservationCard />)
}



       
      </div>
      )}
   
   {/* <FooterComponent/> */}
   <HostFooterComp/>

    </div>
  );
};

export default ReserveContentComp;

