import React, { useState } from "react";
import Button from "../../Components/common/Button";
import GuestProfile from "../../Components/common/guestProfile";
import SearchInput from "../../Components/UserComponents/searchInputs";
import DatePicker from "../../Components/UserComponents/datePicker";
import { Link, NavLink, useLocation } from "react-router-dom";
import ButtonPopup from "../../Components/common/ButtonPopup";
import AddToCart from "../../Components/common/addCarts";
import FontComp from "../../Components/common/fontComp";
import { faSearch, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import ProfileButtons from "../../Components/common/ProfileButtons";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {  faCross, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BookingEditGuest from "./BookingEditGuest";
import MobHeadNav from "./MobHeadNav";
import WrapperPopup from "../../Components/common/wrapperPopup";
import MobHeadDestinationGuest from "./MobHeadDestinaionGuest";
import UserFilterPopup from "./UserFilterPopup";
import { getTotalGuestCount } from "../../utils/varResults";

// import { useNavigate } from "react-router-dom";

const HeadNav = () => {  
  const[guestcountText,setGuestCountText] = useState("Guests");
  const[destination,setDestination] = useState("Destination");

  const [openModal, setOpenModal] = useState(false);

  const [openModalNav, setOpenModalNav] = useState(false);


  // const history =  useHistory()

  const storeSel = useSelector((state) => state);

  const fullNameData = storeSel.hostDetailSlice?.full_Name;
  const profileImage =storeSel.hostDetailSlice?.Image
  // let profileImage;
// 
const [minDateState, setMinDate] = useState();
const [maxDateState, setMaxDate] = useState();
const [selectedCheckIn, setCheckIn] = useState();
const [selectedCheckOut, setCheckOut] = useState();

const [guestCount, setGuestCount] = useState(null);
  const [infantCount, setInfantCount] = useState(null);
  const [childrenCount, setChildrenCount] = useState(null);
  const [adultCount, setAdultCount] = useState(null);

const onDateChange = (checkIn, checkOut) => {
  setCheckIn(checkIn?.format("YYYY-MM-DD"));
  setCheckOut(checkOut?.format("YYYY-MM-DD"));
  setMinDate(checkIn?.format("YYYY-MM-DD"));
  setMaxDate(checkOut?.format("YYYY-MM-DD"));

  // const queryParams = new URLSearchParams(window.location.search);

  // // Update or add the 'checkin' and 'checkout' query parameters with the new date values
  // queryParams.set("checkin", checkIn?.format("YYYY-MM-DD"));
  // queryParams.set("checkout", checkOut?.format("YYYY-MM-DD"));

  // // Combine the updated query string with the current pathname
  // const updatedURL = `${window.location.pathname}?${queryParams.toString()}`;

  // // Use the updated URL without replacing the current one
  // window.history.pushState(null, "", updatedURL);
};

const handleCounts = (adults, children, infants) => {
  // var guestlist = parseInt(adults, 10) + parseInt(children);
  var guestlist = getTotalGuestCount(adults,children)
  var adultlist = adults;
  var childlist = children;
  var infantlist = infants;
  setGuestCount(guestlist);
  setInfantCount(infantlist);
  setChildrenCount(childlist);
  setAdultCount(adultlist);

  // Update the URL with the current counts
  // updateURLWithCounts(adultlist, childlist, infantlist);
};


  const location = useLocation();

  // const isPropertiesPage = location.pathname.includes("/properties");
  const isPropertiesPage = location.pathname === "/properties";

  // const isPropertiesPage = location.pathname.indexOf("/properties") !== -1;


  const shouldUseFilter = isPropertiesPage;

  const changeDestination = (guestVal)=>{    
    setDestination(guestVal);    
  }

  // const queryParams = new URLSearchParams(window.location.search);

  // const appendQueryParam = (key, value) => {
  //   if (value !== null && value !== undefined && value !== "") {
  //     queryParams.append(key, value);
  //   }
  // };

  // console.log(typeof appendQueryParam,'checkParamsType')

  
  
  // const [queryParams, setQueryParams] = useState(new URLSearchParams(location.search));

  



  // console.log(window.location.href,'checkUrlValue')
  // const handleQueryParamChange = (newParams) => {
  //   const newSearchParams = new URLSearchParams(newParams);
  //   const currentUrl = window.location.href;
  //   console.log(newSearchParams,'checkUrlValue')
  //   const separator = currentUrl.includes('?') ? '&' : '?';
  
  //   const newUrl = newSearchParams.size > 0
  //     ? `${currentUrl}${separator}${newSearchParams}`
  //     : currentUrl.split('?')[0];  // Remove existing parameters
  
  //   window.history.replaceState(null, "", newUrl);
  // };



  

  return (
    // <div className="host-nav-box user-listing-page">
    <div className="host-nav-box ">

      <div className="head-in">        
        {/* logo */}
        <Link to="/"><img src="./assets/img/jumboLogo.png" /></Link>

        {/* Center date and guest display division */}
        <div className="hero-navigation-div"> 
          <MobHeadNav
            filterRequired={shouldUseFilter}
            onClickFilter={() => setOpenModal(true)}
            onClickSearch={() => setOpenModalNav(true)}
            position="" 
          />
        </div>

        {/* Right side user profile button and signin div */}
        <ButtonPopup
          wrapclass="wc-max"
          makeCustom={
            <GuestProfile
              // profileName="Mihir"
              profileName={fullNameData}
              // whoseProfile="Host Profile"
            
              profileImage={profileImage}
            />
          }
          divContent={
            <ProfileButtons/>
          }          
          // icon="./assets/img/locationImg.svg"                      
        />        
      </div>

      {/* Modal popup on clicking date and guest count div */}
      <div className="filter-popup-main-div">
        {openModal && (
          <WrapperPopup 
          closeModal={setOpenModal} //used to closePopup either on saving or closing directly
          comp={
            <UserFilterPopup  
            closeModal={setOpenModal}
            
          
            />
          } //used only on property listing page and props is used to show the filters popup
          />
        )}
      </div>
       

      <div className='Mob-destination-guest-popup-div'>
        {openModalNav && (
        <WrapperPopup 
        dontClose={true} 
        closeModal={setOpenModalNav} 
        comp={
          <MobHeadDestinationGuest  
            closeModal={setOpenModalNav} 
            // isSave={true}
          />
        } 
        />
      )}
      </div>
    </div>
  );
};

export default HeadNav;
