

import React, { useEffect, useState } from 'react';
import TripCardComp from '../../Components/common/TripCardComp';
import axios from 'axios';
import { apiMainUser } from '../../utils/constant';
import { Link, useNavigate } from 'react-router-dom';
import DateRangeDisplay from '../../Components/common/DateRangeDisplay';
import NoDataLottie from '../../Components/common/NoDataLottie';
import Button from '../../Components/common/Button';

const TripsPage = () => {
  const [tripData, setTripData] = useState([]);
  const [selectedTripType, setSelectedTripType] = useState('');
  const navigate = useNavigate();

  const handleTripTypeClick = async (tripType) => {
    setSelectedTripType(tripType);
    let filter = '';
    switch (tripType) {
      case 'upcoming':
        filter = 'upcoming';
        break;
      case 'ongoing':
        filter = 'ongoing';
        break;
      case 'previous':
        filter = 'passed';
        break;
      case 'cancelled':
        filter = 'refunded';
        break;
      case 'arriving':
        filter = 'arriving';
        break;
      default:
        filter = '';
    }
    await fetchTripData(filter);
  };

  const fetchTripData = async (filter) => {
    try {
      const response = await axios.get(apiMainUser + 'read_bookings', {
        params: { filter: filter },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });
      setTripData(response.data.data);
    } catch (error) {
      console.error(error);
      setTripData([]);
    }
  };

  useEffect(() => {
    handleTripTypeClick('upcoming');
  }, []);

  const navigateToProperties = () => {
    navigate('/properties');
  };

  const isOptionActive = (option) => {
    return selectedTripType === option ? 'active-sort' : '';
  };

  return (
    <>
      <div className="user-trips-page reserve-content-box">
        <h1 className='mb-15'>Trips</h1>
        {/* Render filter buttons */}
        <div className="reserve-filter-btn-box-parent">
        <div className="reserve-filter-btn-box">
          <Button btnText="Upcoming" 
          onClick={() => handleTripTypeClick('upcoming')}
          btnclasses={`reserve-filter-btn ${isOptionActive('upcoming')}`}
           />
          <Button btnText="Ongoing" 
          onClick={() => handleTripTypeClick('ongoing')} 
          
          btnclasses={`reserve-filter-btn ${isOptionActive('ongoing')}`}
          />
          <Button btnText="Previous"
           onClick={() => handleTripTypeClick('previous')}
           btnclasses={`reserve-filter-btn ${isOptionActive('previous')}`}

            />
          <Button btnText="Cancelled" 
          onClick={() => handleTripTypeClick('cancelled')}
          btnclasses={`reserve-filter-btn ${isOptionActive('cancelled')}`}
          />
          <Button btnText="Arriving Soon" 
          onClick={() => handleTripTypeClick('arriving')} 
          btnclasses={`reserve-filter-btn ${isOptionActive('arriving')}`}
          />
        </div>
        </div>

        {/* Render trip cards */}
        {tripData.map((trip) => (
          <div className="upcoming-trips-div" key={trip.uid}>
            <Link className="trips-links" style={{ width: '100%', color: '#222' }} to={`/trips/${trip.booking_order_id}`}>
              <TripCardComp
                tripCardParent="trip-card-parent-div"
                spaceType={trip.space_type}
                hostName={trip.host_fname + trip.host_lname}
                propertyName={trip.property_name}
                checkInOut={<DateRangeDisplay startDate={trip?.checkin_date} endDate={trip?.checkout_date} />}
                noOfNight={trip.nights}
                address={trip.city + ',' + trip.state}
                country="India"
                propImage={trip.base_url_image + trip.images[0]}
              />
            </Link>
          </div>
        ))}

        {/* Render no data message if tripData is empty */}
        {tripData.length === 0 && <NoDataLottie jsonLotty="./assets/lottie/tripnotFound.json" height="200px" width="300px" />}
      </div>
    </>
  );
};

export default TripsPage;
