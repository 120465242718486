import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Button from '../../../Components/common/Button';
import PropertyTripDetailComponent from '../../user/PropertyDetails/PropertyTripDetailComponent';
import TripConfirmationCardComp from '../../user/PropertyDetails/TripConfirmationCardComp';
import CardWrapper from '../../../Components/common/cardWrapper';
import GuestBasic from '../../../Components/common/guestBasic';
import SupportCard from '../../user/PropertyDetails/SupportCard';
import ProfileImage from '../../../Components/common/profileImg';
import UserReviewCard from '../../../Components/UserComponents/UserReviewCard';
import WrapperPopup from '../../../Components/common/wrapperPopup';
import GuestReviewPopup from '../../../Module/Host/EditListing/ListingDetail/GuestReviewPopup';
import ViewBreakDownCard from '../../../Components/HostComponents/viewBreakdownCard';
import moment from 'moment';

const HostPastBookingDetail = (props) => {
    const userNameForHost = props.successdata?.fname + ' ' + props.successdata?.lname;
    const userImageForHost = props.assetsurl + props.successdata?.image
    const hostId =  props.successdata?.host_id
    const shouldRenderButton = props.reviewWithBookId?.some((review) => review.from_id === hostId);
    const [guestReviewModal, setGuestReviewModal] = useState(false);  

    const [openModal, setOpenModal] = useState(false);


    const toggleVisibleDocks = () => {
        setGuestReviewModal(false)
        setOpenModal(false)
      }


      const handleOpenModal = () => {
        setOpenModal(true);
      };
    
      const handleCloseModal = () => {
        setOpenModal(false);
      };
    
      const data = props.successdata?.transaction_details[0]?.price_detail;
        const addonData = props.successdata?.transaction_details[0]?.addon_details;
        console.log(data, "tried", "sdss");
    
        console.log(props?.successdata,'checkAddonData')
        let priceDetailMapvalues = {};
        data?.forEach((x) => {
          console.log(x, 'tried', 'wow');
      
          if (x.total) {
            console.log("tried", x.total);
            priceDetailMapvalues = x.total;
          }
        });
      
    

  return (
    <div className="property-page">      
    <div className="property-page-grid-box">
      <div className="property-page-left-content">
        <div className="property-page-property-name-box">
          <div className="property-name-box">
            <p className="property-name-text">{props.successdataprop?.property_name}</p>
            <p className="property-name">{props.successdataprop?.space_type} hosted by {props.successdataprop?.host_details[0].fname}</p>
          </div>
          <Link to={"/properties/"+ props.successdataprop?.city+"/"+ props.successdataprop?.slug}><Button btnText="View" btnclasses="offer-btn" /></Link>
        </div>
        <div className="property-page-booking-img-box">
        <img
          className="property-page-property-img"
          src={props.successdataprop?.base_url_image+ props.successdataprop?.images[0]}
          alt=""
        />
        </div>
      
        <PropertyTripDetailComponent
          successdata={props.successdata} 
          checkInTime={props.checkInTime}
          checkOutTime={props.checkOutTime}
        />

        <TripConfirmationCardComp
        successdata={props.successdata} 
        successdataprop={props.successdataprop}
        handleOpenModal={handleOpenModal}

      /> 
      </div>

     
      <div className="property-page-right-content">
      <CardWrapper wrapperHeading='About Guest' wrapperClass='about-guest-card pointer'>
          <GuestBasic
              guestName={userNameForHost}
              verfiedText='Verified'
              icon='./assets/img/guestverified.svg'
              starPoint={props.ratingAverageData ? props.ratingAverageData : 0}
              starIcon='./assets/img/colorStarIcon.svg'
              noReviews={props.review && props.review.length !== 0 ? props.review.length : 0}
              guestDuration='Jumbo guest since April 2023'
          />
      <ProfileImage src={props?.successdata?.image ? userImageForHost : undefined} width='100' />
     </CardWrapper>

     {!shouldRenderButton ? (
            <div>
             <Button
              onClick={() => setGuestReviewModal(true)}
              btnText='Add Review'
              btnclasses='reveiw-btn'
            />
            </div>
            ) : (
            <UserReviewCard
                key={props.review.uid}
                userReviewText={props.reviewWithBookId[0]?.review}
                profileName={props.hostNameForUser}
                profileImage={props.reviewWithBookId[0]?.user_image}
                rating={props.ratingAverageWithBookId?.Overall_Average}

                whoseProfile={props.reviewFormattedDate}
            
            />
            )}

          <SupportCard
          
          />
        </div>

    </div>
    <div className="add-review-popup view-breakdown-popup">
        {guestReviewModal && (
       <WrapperPopup
       fluid={true}
    //    headingText='Title'
       AppylyBtnBox={true}
       defaultSize={"0.45"}
       dimStyle={false}
       isVisible={true} 
       setVisibleDocks={toggleVisibleDocks} 

       closeModal={setGuestReviewModal}
            comp={<GuestReviewPopup
                fetchGuestReviewData={props.fetchGuestReviewData}
                fetchGuestReviewDataWithBookingId={props.fetchGuestReviewDataWithBookingId}
              // hostId = {hostId}
               bookingOrderId = {props.bookingOrderIdForUser }
               userId={props.userId}
             closeModal={setGuestReviewModal}
            //  fetchHostReviewData={fetchHostReviewData}
              />}
       />

        )}
      </div>

      <div className="view-breakdown-popup">
          {openModal && (
            <WrapperPopup
            // AppylyBtnBox={true}
            defaultSize={"0.45"}
            dimStyle={false}
            isVisible={true} 
            setVisibleDocks={toggleVisibleDocks}
            
            //   closeModal={() => setOpenModal(false)}
              closeModal={handleCloseModal}
              comp={
                <ViewBreakDownCard
                  src={props.successdataprop?.base_url_image + props.successdataprop?.images[0]}
                  starRating="5"
                  addons={addonData}
                  reviewNo="7"
                  propertyName={props.successdataprop?.property_name}
                  entireProperty={props.successdataprop?.space_type}
                  perDayprice={priceDetailMapvalues?.total_property_addon_base_cost?.toFixed(2)}
                  ExpPrice={priceDetailMapvalues.total_experience_base_cost?.toFixed(2)}
                  TaxPrice={priceDetailMapvalues.total_property_tax_cost?.toFixed(2)}
                  ServiceFee={priceDetailMapvalues.total_property_service_cost?.toFixed(2) || 0}
                  totalAmount={priceDetailMapvalues?.grand_total_booking_cost?.toFixed(2)}
                  noOfNight={moment(props.successdata?.transaction_details[0].checkout_date).diff(moment(props.successdata?.transaction_details[0].checkin_date), "days")}
                />
              }
            />
          )}
        </div>
  </div>
  )
}

export default HostPastBookingDetail
