import { Controls, Player } from '@lottiefiles/react-lottie-player'
import React, { useEffect } from 'react'
import HeadingComp from '../../Module/Host/EditListing/HeadingComp'
import Button from '../common/Button'
import { apiMainUser } from '../../utils/constant'
import { useState } from 'react'
import axios from 'axios'
import ViewBreakDownCard from '../HostComponents/viewBreakdownCard'
import CnacellationViewBreakDown from '../HostComponents/CnacellationViewBreakDown'
import { openSnackbar } from '../../utils/redux/SnackBarSlicer'
import { useDispatch } from 'react-redux'


const CancelReservationPop = (props) => {

    const dispatch = useDispatch()

    // const []
    

    // useEffect(()=>{

    // })
    
    const cancelReservation = async () => {
        const cancelApi = props.hostCancel ? 'cancel_booking_by_host' : 'cancel_booking';
        try {
            
          const response = await axios.post(
            `${apiMainUser}${cancelApi}/${props.bookingOrderId}`,
            // Move headers outside of the data payload
            null,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          );
          if(response.data.status == 'success'){
            props.closeModal()
            dispatch(openSnackbar({ message: 'Booking cancelled successfully!', severity: 'success' }));
            props.setApiCall(true)

          }
      
          console.log('API Response:', response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      


    const [cancelBookingPrice, setCancelBookingPrice] = useState(null);

    console.log(cancelBookingPrice,'checkPrice')

    useEffect(() => {
      if(!props.hostCancel)  {

      const fetchData = async () => {
        try {
          const response = await axios.get(
         `${apiMainUser}calculate_cancel_booking_price/${props.bookingOrderId}`,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          );
  
          console.log('API Response:', response.data);
          setCancelBookingPrice(response.data.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  
     
    }, []); // The empty dependency array ensures the effect runs only once when the component mounts
  


  return (
    <div className="remove-user-main-div paddingCustom">
     {!props.hostCancel && <HeadingComp headingText="Confirm Cancellation" />}
   <br />
       {!props.hostCancel ? 
        <CnacellationViewBreakDown
        perDayprice={cancelBookingPrice?.booking_amount}
        // + priceDetailMapvalues?.discount_coupon_amount).toFixed(2)}
        ExpPrice={cancelBookingPrice?.booking_amount - cancelBookingPrice?.refund_amount }
        TaxPrice={cancelBookingPrice?.refund_amount}
        />

        : 
        <>

        <Player
        autoplay
        loop
        style={{ height: "150px", width: "150px" }}
        src="./assets/lottie/exclamation.json"
      >
        <Controls
          visible={false}
          buttons={["play", "repeat", "frame", "debug"]}
        />
      </Player>
      <p className='font-small mt-10'>
        Are you sure you want to cancel this booking?
    
      </p>
       <p className='font-small mt-10'>Full amount will be refunded.</p>
  
     </>
}
   
      <div className="clear_apply-btn-box paddingCustomYSide">
        {/* <p onClick={() => props.closeModal(false)} className="clear-btn">
          No
        </p> */}
        <Button 
        onClick={cancelReservation}
        btnText="Confirm" btnclasses="save-edit-btn w-100"
        //   onClick={deleteUser}
          />
      </div>
    </div>
  )
}

export default CancelReservationPop
