import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
// import { Dock } from 'react-dock';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css'
import HeadingComp from '../../Module/Host/EditListing/HeadingComp';
const DockComponent = (props) => {


  const [open, setOpen] = useState(props.isVisible)
    const isSmallScreen = window.innerWidth <= 500;
    const dockClass = props.isVisible ? "dock-visible" : "dock-hidden";

  if (!props.isVisible || !isSmallScreen) {
    return null;
  }

  const handleClose = () => {
    props.setVisibleDocks(false);
  };

  const handleSave = () => {
    // Save logic here
    // You can use props.saveApplyFunc() or any other method to save the changes
    props.saveApplyFunc();
    // After saving, close the Dock
    handleClose();
  };
    
return (
    
  <BottomSheet
  className="bottomsheet"
    open={open}
    onDismiss={handleClose}
    // snapPoints={({ minHeight, maxHeight }) => [0.75, maxHeight/(props.defaultSize ? props.defaultSize : 0.75)]}
  // Opens the largest snap point by default, unless the user selected one previously
  defaultSnap={({ lastSnap, snapPoints }) =>
    lastSnap ?? Math.max(...snapPoints)
  }
    >
     
    
    <div className={`dock-popup-parent ${props.DockFilter ? 'filter-dock-parent' : ''} ${props.noCloseButton ? 'disable-close-button' : ''} ${props.DockPopupFilter ? 'buttonpopup-dock-parent' : ''}`}>
      <div className='title-cross-div'>
      <HeadingComp headingText={props.headingText}/>
      
        <p className='dock-close-icon' onClick={handleClose}>
        <FontAwesomeIcon icon={faXmark}/>
        </p>
        </div>
        {props.children}
        <div className={`save-edit-btn-parent-dock ${props.btnClass ? 'clear_apply-btn-box' : ''} ${props.paddingAll ? 'paddingCustomYSide' : ''} ${props.FixedBottomDock ? 'fixed-dock-bottom' : ''}`}>

           {props.showCloseButton && (
            <p className='clear-btn'  onClick={handleClose}>
              {props.closeBtnText}
            </p>
          )}
          {props.saveApplyButton && (
            <button className="save-edit-btn" onClick={handleSave}>
              Save
            </button>
          )}
        </div>
      </div>


      {/* -------------------------------------- */}
    {/* </Dock> */}
    </BottomSheet>
  )
}

export default DockComponent

