import { createSlice, configureStore } from "@reduxjs/toolkit";

// Define initial state
  const initialState = {
    isPrompt:false,
    isPromptText:null,
    isPromptOk:false,
    promptNotes:{}

  };

const PromptSlice = createSlice({
  name: "PromptSlice",
  initialState,

  reducers: {
    setPromptComp: (state, action) => {
      state.isPrompt = action.payload;
    },
    setPromptText: (state, action) => {
      state.isPromptText = action.payload;
    },
    setPromptOkay: (state, action) => {
      state.isPromptOk = action.payload;
    },
    setPromptNotes: (state, action) => {
      state.promptNotes = action.payload;
    }, 
  },

});
export const {setPromptComp, setPromptText,setPromptOkay, setPromptNotes} = PromptSlice.actions;
export const  PromptSliceReducer = PromptSlice.reducer;
