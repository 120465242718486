import React, { useState } from "react";
import CounterBeds_Guest from "../Host/CreateListing/counterBeds_Guest";
import Room_Beds_counter from "../../Components/common/Room_Beds_counter";
import SwitchExample from "../../Components/common/switch";
import Button from "../../Components/common/Button";
import RangeSlider from "../../Components/common/rangeSlider";
import RangeSlider2 from "../../Components/common/rangeSlider2";
import Slider from "react-slider";
import { useEffect } from "react";
import AddToCart from "../../Components/common/addCarts";
import { apiPublicUser, formatIndianRupeesFunc } from "../../utils/constant";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { amenitiesFilter, bathroomsFilter, bedroomsFilter, bedsFilter, maxFilter, minFilter, propertyFilter, spaceFilter } from "../../utils/redux/FilterSlicer";
import CardWrapper from "../../Components/common/cardWrapper";
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import { setFilterParams } from "../../utils/redux/ParamsSlicer";


const UserFilterPopup = (props) => {


  const location = useLocation()



  console.log('Longtext')
  
  // const [allFilters ,setAllFilters] = useState()

  const storeSel = useSelector((state) => state);

  // const location = useLocation()
  const queryParams = new URLSearchParams(window.location.search);


  /// for space type  fetching from params
  const initialSpaceTypes = queryParams.get("space_type")
  ? queryParams.get("space_type").split(",")
  : [];





// State for selected space types
const [selectedSpaceTypes, setSelectedSpaceTypes] = useState(
  initialSpaceTypes
);
 // Function to handle checkbox change
//  const handleCheckboxChangeSpace = (spaceType) => {
//   setSelectedSpaceTypes((prevSelected) => {
//     if (prevSelected.includes(spaceType)) {
//       return prevSelected.filter((type) => type !== spaceType);
//     } else {
//       return [...prevSelected, spaceType];
//     }
//   });
// };

const handleCheckboxChangeSpace = (spaceType) => {
  setSelectedSpaceTypes((prevSelected) => {
    const updatedSpaceTypes = prevSelected.includes(spaceType)
      ? prevSelected.filter((type) => type !== spaceType)
      : [...prevSelected, spaceType];
    return updatedSpaceTypes;
  });
};


///////////Amenties filter------------------------------------
const parseQueryParamArray = (key) => {
  const paramValue = queryParams.get(key);
  return paramValue ? paramValue.split(",").map(item => parseInt(item, 10)) : [];
};

const initialAmenitiesTypes = parseQueryParamArray("amenities");

const [selectedAmenities, setSelectedAmenities] = useState(initialAmenitiesTypes);

const handleCheckboxChange = (amenityId) => {
  setSelectedAmenities((prevSelected) => {
    if (prevSelected.includes(amenityId)) {
      return prevSelected.filter((id) => id !== amenityId);
    } else {
      return [...prevSelected, amenityId];
    }
  });
};


///---------------------------------------------------------------------------------

console.log(selectedAmenities,initialAmenitiesTypes,'checkSelectedValues')
  

  const bedsCount = storeSel.filterDataSlice?.bedsCount;
  const bathroomsCount = storeSel.filterDataSlice?.bathroomsCount;
  const bedroomsCount = storeSel.filterDataSlice?.bedroomsCount;
 
  const filterdata = storeSel.filterDataSlice.filterData;
  const bedsMax = filterdata?.max_bed_bedroom_bathroom[0]?.max_beds
  const bedroomsMax = filterdata?.max_bed_bedroom_bathroom[0]?.max_bedrooms
  const bathroomsMax = filterdata?.max_bed_bedroom_bathroom[0]?.max_bathroo



  ///Price slider filter states--------------------

  
  const minGet = filterdata?.room_prices[0].min_price
  const maxGet = filterdata?.room_prices[0].max_price

  const minPriceCount = queryParams.get("base_price")
  ? queryParams.get("base_price")
  : minGet;
  const maxPriceCount = queryParams.get("ceil_price")
  ? queryParams.get("ceil_price")
  : maxGet;


  
  const [min, setMin] = useState(minGet);
  const [max, setMax] = useState(maxGet);
  const [values, setValues] = useState([ minPriceCount? minPriceCount : minGet, maxPriceCount? maxPriceCount : maxGet]);

  

  const handleMinChange = (event) => {
    const newMin = parseInt(event.target.value.replace(/\D/g, ""), 10);
    if (!isNaN(newMin) && newMin <= max) {
      setValues([newMin, values[1]]);
    }
  };
  console.log(values,'iio')

  const handleMaxChange = (event) => {
    const newMax = parseInt(event.target.value.replace(/\D/g, ""), 10);
    if (!isNaN(newMax) && newMax >= min) {
      setValues([values[0], newMax]);
    }
  };

  const handleSliderChange = (newValues) => {
    setValues(newValues);
  };


  // const minPriceCount = storeSel.filterDataSlice?.minPriceCount
  // const maxPriceCount = storeSel.filterDataSlice?.maxPriceCount






  console.log(filterdata,'hhjj')

  //rooms and beds filter -----------------------------------------
  const initialBedroomsTypes = queryParams.get("bedrooms")
  ? queryParams.get("bedrooms")
  : 1;
  const initialBedsTypes = queryParams.get("beds")
  ? queryParams.get("beds")
  : 1;

  const initialBathroomsTypes = queryParams.get("bathrooms")
  ? queryParams.get("bathrooms")
  : 1;



  const [count1, setCount1] = useState( initialBedroomsTypes || 1)
  const [count2, setCount2] = useState(initialBathroomsTypes || 1)

  const [count3, setCount3] = useState(initialBedsTypes || 1)

  
  const dispatch = useDispatch()

  const handleCountChange1 = (newCount) => {
    setCount1(newCount); 
  };
  
  const handleCountChange2 = (newCount) => {
    setCount2(newCount); 
  };
  
  const handleCountChange3 = (newCount) => {
    setCount3(newCount); 
    
  };

  console.log('checkType')
  /////////--------------------------------------------------------


  const clearFilters = () => {
    // Clear all selected filter states
    setSelectedAmenities([]);
    setSelectedSpaceTypes([]);
    setSelectedPropeUids([]);
    setValues([minGet, maxGet]);
    setCount1(1);
    setCount2(1);
    setCount3(1);

   
  };



 


// proerty typw card type----------------------------------------------------------------------------------------
const propertTypeData = filterdata?.propertyType;
const initialSelectedProperty = storeSel.filterDataSlice?.propertyTypeCount;

// const initialPropertyTypes = queryParams.get("property_type")
//   ? queryParams.get("property_type")
//   : [];

const [selectedPropIndex, setSelectedPropIndex] = useState(null);

  const [selectedPropUids, setSelectedPropeUids] = useState([]);
  
  console.log(selectedPropIndex,selectedPropUids,'ooj')

  // const isCardSelected = (uid) => selectedPropUids.includes(uid);
  const isCardSelected = (uid) => selectedPropUids.some(selectedUid => String(selectedUid) === String(uid));




  // const handleCardClick = (index) => {

  //   const selectedPropUid = propertTypeData[index].id;  

  //   if (isCardSelected(selectedPropUid)) {
      
  //     setSelectedPropeUids((prevSelectedUids) =>
  //       prevSelectedUids.filter((id) => id !== selectedPropUid)
  //     );
  //   } else {
      
  //     setSelectedPropeUids((prevSelectedUids) => [
  //       ...prevSelectedUids,
  //       selectedPropUid,
  //     ]);
  //   }
  // };

  
const handleCardClick = (index) => {
  const selectedPlaceUid = String(propertTypeData[index].id);

  console.log(selectedPlaceUid,'checkOnceAll')


  setSelectedPropeUids((prevSelectedUids) => {
    const isAlreadySelected = prevSelectedUids.includes(selectedPlaceUid);
    console.log(isAlreadySelected,'checkOnceAll')
    let updatedSelectedUids;

    if (isAlreadySelected) {
      // Unselect the card
      updatedSelectedUids = prevSelectedUids.filter((id) => id !== selectedPlaceUid);
    } else {
      // Select the card
      updatedSelectedUids = [...prevSelectedUids, selectedPlaceUid];
    }

    console.log(updatedSelectedUids,'checkOnceAll')
    const queryParams = new URLSearchParams(window.location.search);
    if (updatedSelectedUids.length > 0) {
    console.log(updatedSelectedUids,'checkOnceAll')

      queryParams.set("property_type", updatedSelectedUids.join(","));
    } else {
      queryParams.delete("property_type");
    }

    return updatedSelectedUids;
  });
};




  useEffect(() => {
    const parseQueryParamsAndUpdateState = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const placeTypesParam = queryParams.get('property_type');
  
      if (placeTypesParam) {
        const selectedPlaceUids = placeTypesParam.split(',');
        setSelectedPropeUids(selectedPlaceUids);
      } else {
        setSelectedPropeUids([]);
      }
    };
  
    parseQueryParamsAndUpdateState();
  }, [location.search, location]);







  console.log((selectedAmenities)); 
  //============================================================================

  // const saveSliderValuesToLocal = () => {
  //  dispatch(bedroomsFilter(count1))
  //  dispatch(bathroomsFilter(count2))
  //   dispatch(bedsFilter(count3))
  //   dispatch(amenitiesFilter(selectedAmenities));
  //   dispatch(minFilter(values[0]))
  //   dispatch(maxFilter(values[1]))
  //   dispatch(spaceFilter(selectedSpaceTypes));
  //   dispatch(propertyFilter(selectedPropUids))
  //   props.closeModal()
  // };
  console.log( values[1],values[0],'checkAllPropS')

  
  
  console.log(count1,'checkCount1')
  
   const saveFilterValuesToLocal = () => {

    console.log(selectedSpaceTypes,'checkSpacevalue')

  const queryParams = new URLSearchParams(window.location.search);

  

    if(values[1] !== maxGet || count1 >= 1 || count2 > 1 || count3 > 1 || values[0] !== minGet){
      queryParams.set("bedrooms", count1);

    }
    if(values[1] !== maxGet || count1 > 1 ||count2 >= 1 || count3 > 1 || values[0] !== minGet){
      queryParams.set("bathrooms", count2);
    }
    if(values[1] !== maxGet || count1 > 1 ||count2 > 1 || count3 >= 1 || values[0] !== minGet){
      queryParams.set("beds", count3);
    }

    if(values[1] !== minGet ){

      queryParams.set("base_price", values[0]);
    }
 
    if(values[0] !== maxGet){
      queryParams.set("ceil_price", values[1]);
    }

    if (selectedSpaceTypes.length > 0) {
      queryParams.set("space_type", selectedSpaceTypes.join(","));
    }else {
      queryParams.delete("space_type");
    }

    if (selectedPropUids.length > 0) {
      queryParams.set("property_type", selectedPropUids.join(","));
    } else {
      queryParams.delete("property_type");
    }

    
    // Check and append amenities if it has values
    if (selectedAmenities.length > 0) {
      queryParams.set("amenities", selectedAmenities.join(","));
    }else {
      queryParams.delete("amenities");
    }

    
  
    console.log(queryParams,'checkAllFilterParam')
  
    const queryString = queryParams.toString();
    dispatch(setFilterParams({ query: queryString }));
    // const newPath = queryString ? `${window.location.pathname}?${queryString}` : window.location.pathname;

    // console.log(newPath,'checkNewPath')
    // // window.history.pushState(null, "", newPath);
    // window.history.replaceState({}, "", newPath);
  
    props.closeModal();
  };

  


  

  console.log(typeof props.appendQueryParamFunc,'checkParamsType')
  console.log( props,'checkParamsType')



  

  
  return (
    <>
    <div className="user-filter-popup paddingCustom2AllSide">
    <h3 className="slider-price-range-text">Price Range</h3>
    <p className="start-para">
      The average Total price of 2 nights is {props.nightPrice}
    </p>
    {/* Range Slider */}
    <div className="range-slider-new">
      <div className="values">        
        ₹{formatIndianRupeesFunc(values[0])} - ₹{formatIndianRupeesFunc(values[1])}
      </div>
      {/* <small className="current-range-value">
        current price: ₹{formatIndianRupeesFunc(values[1] - values[0]}
      </small> */}
      <Slider
        className="slider"
        onChange={handleSliderChange}
        value={values}
        min={minGet}
        max={maxGet}
      />
      <div className="price-input-box">
        <div className="input-container">
          <label htmlFor="minInput">Min Price:</label>
          <div className="input-with-symbol">
            <span>₹</span>
            <input
              type="number"
              id="minInput"
              value={values[0]}
              onChange={handleMinChange}
            />
          </div>
        </div>
        <div className="input-container">
          <label htmlFor="maxInput">Max Price:</label>
          <div className="input-with-symbol">
            <span>₹</span>
            <input
              type="number"
              id="maxInput"
              value={values[1]}
              onChange={handleMaxChange}
            />
          </div>
        </div>
      </div>
    </div>


{/*  */}


      <h2 className="place-type-text">Type of Space</h2>

        {/* <h2 className="place-type-text">Type of Place</h2> */}
        <div className="checbox-div">
        <div>
          <label>
            <input
              className="largerCheckbox"
              type="checkbox"
              checked={selectedSpaceTypes.includes("Entire Place")}
              onChange={() => handleCheckboxChangeSpace("Entire Place")}
            />
            Entire Place
          </label>
          <p className="checkbox-para">A Place all to Yourself</p>
        </div>

        <div>
          <label>
            <input
              className="largerCheckbox"
              type="checkbox"
              checked={selectedSpaceTypes.includes("A Private Room")}
              onChange={() => handleCheckboxChangeSpace("A Private Room")}
            />
            A Private Room
          </label>
          <p className="checkbox-para">
            Your Own room in home or hotel, plus some shared common place
          </p>
        </div>

        <div>
          <label>
            <input
              className="largerCheckbox"
              type="checkbox"
              checked={selectedSpaceTypes.includes("A Shared Room")}
              onChange={() => handleCheckboxChangeSpace("A Shared Room")}
            />
            A Shared Room
          </label>
          <p className="checkbox-para">
            A sleeping space and common areas that may be shared with others
          </p>
        </div>
      </div>
    

      <h2 className="place-type-text">Rooms and Beds</h2>
      <div className="room-beds-cart-div">
      <span className="text">Bedrooms:</span>   <AddToCart
              
                cartParent="cart-parent"
                cartBtn="cart-button"
                cartCount="cart-count"
                initialCount={count1}
                maxCount={bedroomsMax}
               onCountChange={handleCountChange1}
              // increamentVal={handleCountChange1}
          />
          </div>

          <div className="room-beds-cart-div">
           <span className="text">Bathrooms:</span>  <AddToCart
              
              cartParent="cart-parent"
              cartBtn="cart-button"
              cartCount="cart-count"
              initialCount={count2}
              maxCount={bathroomsMax}
             onCountChange={handleCountChange2}
            // increamentVal={handleCountChange2}
        />
        </div >

        <div className="room-beds-cart-div">
         <span className="text">Beds:</span>  <AddToCart
              
              cartParent="cart-parent"
              cartBtn="cart-button"
              cartCount="cart-count"
              initialCount={count3}
              maxCount={bedsMax}
             onCountChange={handleCountChange3}
            // increamentVal={handleCountChange3}
        />
        </div>
        {/* allFilters?.amenities_category */}

        <h2 className="place-type-text">Property Type</h2>
        <div className="Property-type-filter-parent">
          {propertTypeData?.map((property,index)=>{
            console.log(property,'jjl')
            return(
              <CardWrapper 
              wrapperClass={`property-type-icon-card ${
                // isCardSelected(property.id) ? "selected-card" : ""
                isCardSelected(String(property.id)) ? "selected-card" : ""

              }`}
              onClick={() => {
                setSelectedPropIndex(index);
                handleCardClick(index);
              }}
              >
              <span>
                <img src={property.icon_url} alt="" />
              </span>
              <span className="icon-text">{property.name}</span>
            </CardWrapper>
            )
           

            })}
            </div>
            
      


      <h2 className="place-type-text">Amenities</h2>
      
    <div className="popup-container">
  {filterdata?.amenities_category
    ?.filter((category) => category.amenities_details.length > 0) // Filter out categories with no amenities
    .map((category) => (
      <div style={{ marginBottom: "1rem" }} key={category.id}>
      <h5>{category.category_type}</h5>
      <div className="amenties-list-box">
        {category.amenities_details.map((amenity) => (
          <div className="amenities-list" key={amenity.id}>
            <label
              style={{
                display: "flex",
                gap: "0.6rem",
                alignItems: "center",
                marginBottom: "0.7rem",
              }}
            >
              <input
                type="checkbox"
                checked={selectedAmenities?.includes(amenity.id)}
                onChange={() => handleCheckboxChange(amenity.id)}
              />
              {/* <div className="amenities-icon-box">
                <img style={{ width: "20px" }} src={amenity.icon_url} alt={amenity.amenity_name} />
              </div> */}
              <p style={{ fontSize: "15px" }}>{amenity.amenity_name}</p>
            </label>
            {console.log(selectedAmenities?.includes(amenity.id),'checkSelectedValues')}
          </div>
        ))}
      </div>
    </div>
    
    ))}
</div>


      <h2 className="place-type-text">Top-tier stays</h2>

      <div className="host-tier-switchbox">
        <div className="host-tier-box">
          <h3>Super Host</h3>
          <p>Stay with recognised hosts</p>
        </div>
        <label>
          {/* <SwitchExample
            swicthClass="switch-class"
            onChange={handleChange}
            checked={checked}
          /> */}
        </label>
      </div>

      
    </div>
    <div className="clear_apply-btn-box btn fixed-bottom-btn">
    <div onClick={clearFilters} className="clear-btn">Clear</div>
    <Button
      btnclasses="apply-btn"
      btnText="Apply"
      onClick={saveFilterValuesToLocal}
    />
    
  </div>
  
 </>
 

 
  );
};
// UserFilterPopup.propTypes = {
//   queryParams: PropTypes.object.isRequired,
//   appendQueryParamFunc: PropTypes.func.isRequired,
//   closeModal: PropTypes.func.isRequired,
// };

export default UserFilterPopup;
