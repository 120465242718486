import React, { useState } from 'react'
import Button from '../../../Components/common/Button'
import BankPayoutPop from './BankPayoutPop'
import WrapperPopup from '../../../Components/common/wrapperPopup'

const HostPayout = () => {

  const [payoutPop,setPayoutPop] = useState(false)


  const toggleVisibleDocks = () => {
    setPayoutPop(false);
  }
  return (
    <div className='host-payout-content'>
     <h3 className='title'>How you get Paid</h3>
     <p className='sub-title'>You can send your money to one or more payout methods. To manage your payout method(s) or assign a taxpayer, use the edit menu next to each payout method.</p>

     <div className="host-payout-bank-details-box">

      {/* <img src="./assets/img/bankImg.svg" alt="" />  
      <div className='host-payout-bank-details-text'>
        <p className='bank-account-text'>Bank Account</p>
        <p className='bank-details-para'>Jumbo Holidays, ....4815 (INR)</p>
      </div> */}
       
      </div>

        <div className="host-payout-bank-details-box">

            {/* <img src="./assets/img/bankImg.svg" alt="" />  
            <div className='host-payout-bank-details-text'>
            <p className='bank-account-text'>Bank Account</p>
            <p className='bank-details-para'>Jumbo Holidays, ....4815 (INR)</p>
            </div>
            <p className='edit-bank-btn'>Edit</p> */}
            </div>

        <Button   onClick={() => setPayoutPop(true)} btnText='Add payment method' btnclasses='save-edit-btn' />    


        <div className="payout-popup-div">
        {payoutPop && (
          <WrapperPopup
          fluid={true}
          defaultSize={"0.90"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
            closeModal={setPayoutPop}
            comp={
              <BankPayoutPop
              
                closeModal={setPayoutPop}
              />
            }
          />
        )}
      </div>
    </div>
  )
}

export default HostPayout
