
import React, { useEffect, useState } from 'react';

const MobCalnedarPropertyPop = (props) => {


  
  // const initialSelectedProperty = props.filteredPublishData[0]?.propertyid || localStorage.getItem('selectedPropertyRadio') ;
  const initialSelectedProperty = props.selectedPoperty;

  const [selectedPropertyRadio, setselectedPropertyRadio] = useState(initialSelectedProperty);
  const [propertyId, setPropertyId] = useState(selectedPropertyRadio);

  const [tempPropertIdParent,setTempPropertIdParent] = useState('')
  const [tempPropertNameParent,setTempPropertNameParent] = useState('')


  useEffect(() => {
    setselectedPropertyRadio(initialSelectedProperty);
  }, [initialSelectedProperty]);

  console.log(initialSelectedProperty,'checkInitialValue')


  const handleRadioChange = (property_name, propertid) => {
    setPropertyId(propertid);
    setselectedPropertyRadio(property_name);
    localStorage.setItem('selectedPropertyRadio', propertid);
    setTempPropertIdParent(propertid)
    setTempPropertNameParent(property_name)

    console.log('Selected Property:', property_name, propertid);
  };



  const onSubmit = () => {
    props.setPropertyIdPrent(tempPropertIdParent);
    props.setSelectedProperty(tempPropertNameParent);
   
    props.closeModal();
  };

  console.log(selectedPropertyRadio,'checkRadio')

  

  return (
    <div className='mob-calnedar-property-pop'>
      <form style={{ padding: '3.5rem 0rem 6rem' }} onSubmit={onSubmit}>
        {props.filteredPublishData.map((property, index) => (

          <label key={index} htmlFor={`property_${index}`} className='mob-calnedar-property-pop-box'>
            <div className='mob-property-dropdown-imgbox'>
              <img src={property.base_url_image + property?.images[0]} alt={property.property_name} />
              <h3>{property.property_name}</h3>
            </div>
            <input
              type="radio"
              id={`property_${index}`}
              onChange={() => handleRadioChange(property.property_name, property.propertyid)}
              checked={selectedPropertyRadio === property.property_name}
              value={property.property_name}
              name="selectedProperty"
            />
          </label>
        ))}
        <div className="clear_apply-btn-box fixed-bottom-btn">
          <p onClick={() => props.closeModal(false)} className="clear-btn">
            Cancel
          </p>
          <button className="save-edit-btn" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default MobCalnedarPropertyPop;
