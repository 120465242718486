import React from 'react';
import { Marker, StaticGoogleMap } from 'react-static-google-map';

const MapDisplayOnTripPage = (props) => {

    const combinedAddress = `${props.successdataprop?.house_no}, ${props.successdataprop?.area}, ${props.successdataprop?.street_address}, ${props.successdataprop?.city}, ${props.successdataprop?.pincode}, ${props.successdataprop?.country ? props.successdataprop?.country : ''}`;

  return (
    <div className="map-display-on-trip-page">
      <h2 className="map-display-on-trip-heading">Where you're staying</h2>
      <p>{combinedAddress}</p>
      <div>
        <StaticGoogleMap
          size="350 x 280"
          className="img-fluid img-fluid-custom"
          apiKey="AIzaSyD2p6Azx2i_PY1RbQhin7TqLyijMt3ykT8"
        >
          <Marker
            location={`${props.successdataprop?.latitude},${props.successdataprop?.longitude}`}
            color="19A6BB"
            label="P"
          />
        </StaticGoogleMap>
      </div>
    </div>
  );
};

export default MapDisplayOnTripPage;
