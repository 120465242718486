import React from 'react'
import HostEmailOtp from './HostEmailOTP';
import WrapperPopup from '../../../Components/common/wrapperPopup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Button from '../../../Components/common/Button';
import axios from 'axios';
import HostPhoneOtp from './HostPhoneOtp';
import { apiMainUser, capitalizeText } from '../../../utils/constant';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { openSnackbar } from '../../../utils/redux/SnackBarSlicer';
import { useDispatch } from 'react-redux';

const HostPhoneEdit = (props) => {
    const [openOtpModal,setOpenOtpModal] = useState(false)
    const [apiSuccess, setApiSuccess] = useState(false);
    const [countryCode, setCountryCode] = useState(''); 

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();

      const [numberdata, setNumberData] = useState(props.usersData?.mobile ? props?.usersData?.country_code + props.usersData?.mobile : '');
      console.log(numberdata, props?.userData?.country_code,props?.userData,'checkNumber')

    
     console.log(numberdata,'checkNumberData')
     const dispatch = useDispatch()
    
      const onSubmit = async (data) => {
        // const countryData = document.querySelector('.react-tel-input').querySelector('.country');
        // const countryCode = countryData.getAttribute('data-country-code'); // Get selected country code
        // const phoneNumber = numberdata.replace('+' + countryCode, ''); // Extract phone number without country code
        // setNumberData(numberdata)
       
        try {
            const response = await axios({
              method:'post',
              url: apiMainUser + 'send_otp',
              data: {
                // mobile: numberdata,
                // country_code: countryCode,
                mobile: numberdata.replace(`${countryCode.dialCode}`, ''), // Remove the country code from the phone number
                // country_code: countryCode.dialCode, // Send the dialCode as the country code
                country_code: `+${countryCode.dialCode}`,
              },
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
              
            }
            
            );            
            if (response.data.status === 'success') {
              setApiSuccess(true);
              dispatch(openSnackbar({ message: 'OTP sent succesfully!', severity: 'success' }));

              
            } else {
              
            }
          } catch (error) {       
            dispatch(openSnackbar({ message: capitalizeText(error.response.data.message), severity: 'error' }));

          }
      };

    
    
      return (
        <>
        {apiSuccess ?
        <HostPhoneOtp fetchData={props.fetchData} usersData={props.usersData}  numberdata={numberdata} countryCode={countryCode}  closeModal={props.closeModal}/>:
        <div className="host-edit-name-main-div" style={{padding:'0rem',marginTop:'2rem'}}>
          
        <form className='host-personal-info-edit-form ' onSubmit={handleSubmit(onSubmit)}>
          <div style={{marginTop:'2rem'}} className='country-code-input'>
            {/* <label className='personal-info-label' htmlFor="phone">Phone Number</label> */}
            {/* <input 
            defaultValue={props.usersData?.mobile ? props.usersData?.mobile : ''}
            
            {...register('phone', { required: true })} id="phone" placeholder="Enter your phone number"  /> */}

           <PhoneInput
              className='phone-number-input'
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                country={'in'}
                value={numberdata}
                onChange={(phone,country) => {
                  // Set the phone value when changed
                  setNumberData(phone);
                  setCountryCode(country);

                }}
              />
              {errors.phone && <span>This field is required.</span>}
            {/* {errors.phone && <span>This field is required.</span>} */}
          </div>
    
           
      <div className="clear_apply-btn-box paddingCustomYSide">
      
      <p onClick={() => props.closeModal(false)} className="clear-btn">
         Cancel
       </p>
      <button className="save-edit-btn" type="apply-btn">
         send OTP
       </button>

      </div>
          
    
      
        </form>

      
        </div>
}
        </>
      );
}

export default HostPhoneEdit
