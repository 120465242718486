import React from 'react';
import SkeletonLoader from '../common/SkeletonLoader';
import { Tooltip } from '@mui/material';

const RewardsReviewImages = (props) => {
    console.log(props.imageSources,'checkPropsImage')


  return (
    <div className='rewards-review-images ml-10'>
      {props.imageSources !== undefined && props.imageSources !== null ? (
        <div className="review-img-box pointer">
          {props.imageSources.map((image, index) => (
            <Tooltip key={index} title={image.name} arrow>
              <img src={image.icon} alt=""  className='w-20px h-15px ' />
            </Tooltip>
          ))}
        </div>
      ) : (
        <SkeletonLoader circle={true} width={40} height={40} count={1} />
      )}
    </div>
  );
};

export default RewardsReviewImages;
