import React from "react";
import { Controller, useForm, watch } from "react-hook-form";
import InputComp from "../../../Components/common/textInput";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBookingPrefrenceData } from "../../../utils/redux/listingTypeSlice";
import Save_ExitComp from "../../../Components/common/save_ExitComp";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

function BookingPreference() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [api, setApi] = useState(["createProperty_metadata_bulk"]);
  const [method, setMethod] = useState("");
  // console.log(propertyId, "tulips");

  const storeSel = useSelector((state) => state);
  // const propertyId = sessionStorage.getItem("property");

  // console.log(indexStore, "tii");

  //
  const Currenturl = window.location.href;
  console.log(Currenturl, "tyt");
  const CurrentPage = new URL(Currenturl);
  const path = CurrentPage.pathname;
  console.log(path, "qqq");

  const inputData = storeSel?.listingDataSlie.bookingPrefrenceData;

  //
  // console.log(indexStore, "tii");
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      availabiliityWindow:
        inputData && inputData?.availabiliityWindow !== "null"
          ? inputData?.availabiliityWindow
          : "",
      advanceWindow:
        inputData && inputData?.advanceWindow !== "null"
          ? inputData?.advanceWindow
          : "",
      minimumStay:
        inputData && inputData?.minimumStay !== "null"
          ? inputData?.minimumStay
          : "",
      maximumStay:
        inputData && inputData?.maximumStay !== "null"
          ? inputData?.maximumStay
          : "",
    },
  });

  const whatHappensOnSubmit = () => {
    let data = watch();
    const bookingPrefData = {
      ...data,
    };

    dispatch(setBookingPrefrenceData(bookingPrefData)); // Dispatch the updated action
  }

  const whereToGoOnNext = ()=>{
    navigate("/host/createlisting/listingPrice");
  }

  const whatHappensOnSaveAndExit = ()=>{
    whatHappensOnSubmit();
  }

  const onSubmit = (data) => {
    whatHappensOnSubmit(data);
    whereToGoOnNext();    
  };


  useEffect(() => {
    // const storeSel = useSelector((state) => state);

    const PropIddata = storeSel.apiDataSlice?.propId;

    const preferenceIddata = storeSel.apiDataSlice?.preferenceId;

    let updatedApi = ["updateProperty/" + PropIddata, ...api];

    if (preferenceIddata) {
      updatedApi = [...updatedApi, "updatePropertyPref/" + preferenceIddata];
      setMethod("patch");
    } else {
      updatedApi = [...updatedApi, "add_preference"];
      setMethod("post");
    }

    setApi(updatedApi);
  }, []);
  console.log(api, "tres");

  return (
    <div className="booking-preference-box ">
      <div className="create-list-booking-preference-type-content  ">
        <h2>Set Up Your Booking Preferences</h2>
        <p>
          Customize your availability, advance notice, and minimum/maximum stay
          requirements
        </p>

        <form
          className="confirm-address-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex-input-box">
            <div className="single-input-box">
              <label htmlFor="">Availability Window</label>
              <Controller
                control={control}
                name="availabiliityWindow"
                // defaultValue={
                //   inputData !== null ? inputData.availabiliityWindow : ""
                // }
                rules={{
                  required: "filled the data is required",
                  min: {
                    value: 0,
                    message: "Availability window cannot be less than 0",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="number"
                    inputClass="input"
                    name="availabiliityWindow"
                    min={1}
                    // inpPlace="Enter your title here..."
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              {errors.availabiliityWindow && (
                <span>{errors.availabiliityWindow.message}</span>
              )}
              <p>Enter your availability window in days</p>
            </div>

            <div className="single-input-box">
              <label htmlFor="">Advance Window</label>
              <Controller
                control={control}
                name="advanceWindow"
                rules={{
                  required: "Title is required",
                  min: {
                    value: 0,
                    message: "Advance window cannot be less than 0",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="number"
                    inputClass="input"
                    name="advanceWindow"
                    min={1}
                    // inpPlace="Enter your title here..."
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              {errors.advanceWindow && (
                <span>{errors.advanceWindow.message}</span>
              )}
              <p>Enter your advanced window in days</p>
            </div>
          </div>
          {/*  */}

          <div className="flex-input-box">
            <div className="single-input-box">
              <label htmlFor="">Minimum stay</label>
              <Controller
                control={control}
                name="minimumStay"
                // defaultValue={inputData !== null ? inputData.minimumStay : ""}
                rules={{
                  required: "filled the data is required",
                  min: {
                    value: 0,
                    message: "Minimum stay cannot be less than 0",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="number"
                    inputClass="input"
                    name="minimumStay"
                    min={1}
                    // inpPlace="Enter your title here..."
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              {errors.minimumStay && <span>{errors.minimumStay.message}</span>}
              <p>Enter your minimum stay in number of nights</p>
            </div>

            <div className="single-input-box">
              <label htmlFor="">Maximum stay</label>
              <Controller
                control={control}
                name="maximumStay"
                rules={{
                  required: "Title is required",
                  min: {
                    value: 0,
                    message: "Maximum stay cannot be less than 0",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="number"
                    inputClass="input"
                    name="maximumStay"
                    min={1}
                    // inpPlace="Enter your title here..."
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              {errors.maximumStay && <span>{errors.maximumStay.message}</span>}
              <p>Enter your maximum stay in number of nights</p>
            </div>
          </div>

          <div className="bottom-btns-fixed-box">            
            <Save_ExitComp
              method={method}
              apis={api}
              path={path}
              firstFunction={whatHappensOnSaveAndExit}

            />
            <button type="submit" className="common-next-btn">
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BookingPreference;






