import React from "react";
import Button from "../../../Components/common/Button";

import FontComp from "../../../Components/common/fontComp";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import OtpInput from "react-otp-input";
import { useState } from "react";
import { apiPublicUser } from "../../../utils/constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PassOtpPage = () => {
  const [otp, setOtp] = useState("");
const navigate = useNavigate();



  const onSubmit = async (data) => {
        
    try {
      const response = await axios({
        method:'post',
        url: apiPublicUser + 'reset_password',
        data: {
          newPassword: otp,
          
        
        },
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        
      }
      
      );
      console.log(response.data);
      if (response.data.status === 'success') {
      
        
      } else {
        
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="Pass-otp-page login-page forgot-page">
      <div className="login-left-box">
        <img src="./assets/img/jumboLogo.png" alt="" />
        <div className="login-form-section">
          <h3 className="login-welcome-text">Forgot Password</h3>
          <p className="forgot-text">
            No need to Panic-Resetting Your Password is a Breeze
          </p>
          <form className="login-form">
            <div className="single-input-box">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <Button onClick={onSubmit} btnclasses="tab-btn" type="submit" btnText="Send OTP" />
          </form>

          <p onClick={()=> navigate('/login')} className="back-to-login">
            <span>
              <FontComp
                icon={faArrowLeft}
                // onClick={handleClick}
                fontclassname="my-icon-class"
                size="lg"
                color="#000"
              />
              <FontComp />
            </span>
            <span  className="back-to-login-text">Back to Login?</span>
          </p>
        </div>
      </div>
      <img
        src="./assets/img/PassOtpImg.png"
        className="login-page-img"
        alt=""
      />
    </div>
  );
};

export default PassOtpPage;
