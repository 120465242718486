

import React, { useState, useEffect } from 'react';
import AddToCart from '../../Components/common/addCarts';
import { useLocation } from 'react-router-dom';
import { fetchUrlFunc, updateURLWithCountsFunc } from '../../utils/constant';

const BookingEditGuest = (props) => {
  const maxGuestValue = props.maxGuests;

  console.log(maxGuestValue,'hhm')

  
  const location = useLocation();

  const [adultCount, setAdultCount] = useState();
  const [infantCount, setInfantCount] = useState();
  const [childrenCount, setChildrenCount] = useState();

  const parseQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const adultsParam = parseInt(searchParams.get('adults'));
    const childrenParam = parseInt(searchParams.get('children'));
    const infantsParam = parseInt(searchParams.get('infants'));
    console.log("parseQueryParams", adultsParam , childrenParam )

    !isNaN(adultsParam) && setAdultCount(adultsParam);
    !isNaN(childrenParam) && setChildrenCount(childrenParam);
    !isNaN(infantsParam) && setInfantCount(infantsParam);
  }

  useEffect(() => {
    parseQueryParams();
  }, []);

  const currentUrl = window.location.href;
  console.log(currentUrl);
  
  useEffect(() => {
    console.log("counts",adultCount,childrenCount)
   props.handleCounts(adultCount, childrenCount, infantCount);
    props.updateUrl && updateURLWithCountsFunc(adultCount,childrenCount,infantCount);
  }, [childrenCount, adultCount, infantCount, props]);

  const handleUpdateGuests_adults = (count) => {
    const totalAdults = Math.max(1, count);
    setAdultCount(totalAdults);
  };

  const handleUpdateGuests_children = (count) => {
    setChildrenCount(count);
  };

  const handleUpdateGuests_infants = (count) => {
    setInfantCount(count);
  };

  return (
    <div className='booking-edit-guest'>
      {props.maxGuestText && 
    <p style={{fontSize:'12px',marginBottom:'2rem'}}>This place allows maximum of {props.maxGuests} guests, not including infants. 
</p>
}
      <div className='cart-flex-div'>
        <p>
          Adults <span>Above 13+</span>
        </p>
        <AddToCart
          cartBtn='cartBtn'
          cartCount='cartCount'
          cartParent='cartParent'
          initialCount={adultCount}
          maxCount={childrenCount !== undefined && childrenCount !== null  ? maxGuestValue - childrenCount : maxGuestValue}
          increamentVal={handleUpdateGuests_adults}
        />
      </div>

      <div className='cart-flex-div'>
        <p>
          Children <span>age 2 to 12</span>
        </p>
        <AddToCart
          cartBtn='cartBtn'
          cartCount='cartCount'
          cartParent='cartParent'
          initialCount={childrenCount !== undefined && childrenCount !== null ? childrenCount : 0}
          maxCount={adultCount !== undefined && adultCount !== null  ? maxGuestValue - adultCount : maxGuestValue - 1}
          minCount={0}
          increamentVal={handleUpdateGuests_children}
        />
      </div>

      <div className='cart-flex-div'>
        <p>
          Infants <span>under 2</span>
        </p>
        <AddToCart
          cartBtn='cartBtn'
          cartCount='cartCount'
          cartParent='cartParent'
          initialCount={infantCount !== undefined && infantCount !== null ? infantCount : 0}
          minCount={0}
          maxCount={5}
          increamentVal={handleUpdateGuests_infants}
        />
      </div>
    </div>
  );
};

export default BookingEditGuest;


