import React from "react";

const SearchInput = (props) => {
  return (
    <div className="search-input-box" onClick={props.onClick}>
      {props.icon && (
        <span className="search-input-img">
          <img src={props.icon} />
        </span>
      )}

      <div className="search-input-text-box">
        {props.upperText && (
          <p className="search-upper-text">{props.upperText}</p>
        )}
        {props.lowerText && (
          <p className="search-lower-text">{props.lowerText}</p>
        )}
        {props.middleText && (
          <p className="search-middle-text">{props.middleText}</p>
        )}
      </div>
    </div>
  );
};

export default SearchInput;
