import React, { useEffect, useState } from "react";
import APIHit from "../../utils/apiHit";
import CardWrapper from "../common/cardWrapper";
import DatePicker from "./datePicker";
import Button from "../common/Button";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  updateUserGuests,
  updateUserProperty,
} from "../../utils/redux/User/UserSearchConfig";
import BookingEditGuest from "../../Module/User/BookingEditGuest";
import ButtonPopup from "../common/ButtonPopup";
import { apiPublicUser, calculateBookingPriceFunc, formatIndianRupeesFunc, getBlockedDatesFunc } from "../../utils/constant";
import { getTotalGuestCount } from "../../utils/varResults";

const PropertyPricing_calculated = (props) => {
  const options = [];
  const [apicall, setApiCall] = useState(false);
  const [pricecall, setPriceCall] = useState(false);
  const [successdata, setSuccessData] = useState([]);
  const [successdataprice, setSuccessDataPrice] = useState();
  const [propertyId, setPropertyId] = useState(props.propertyId);
  const [minDateState, setMinDate] = useState();
  const [maxDateState, setMaxDate] = useState();
  const [blockDateState, setBlockedDates] = useState();
  const [blockCheckinDateState, setCheckinBlockedDates] = useState();
  const [blockCheckoutDateState, setCheckoutBlockedDates] = useState();
  const [guestCount, setGuestCount] = useState(null);
  const [infantCount, setInfantCount] = useState(null);
  const [childrenCount, setChildrenCount] = useState(null);
  const [adultCount, setAdultCount] = useState(null);
  const [hideReserveCard, setHideReserveCard] = useState(true);


  const location = useLocation();

  // console.log(successdata?.data?.data[0],'maxnight')
  const dynamiclocation = window.location.href;

  const maxNight = successdata?.data?.data[0].maxnight
  const minNight = successdata?.data?.data[0].minnight


  console.log(maxNight,'maxnight')

  const [selectedCheckIn, setCheckIn] = useState();
  const [selectedCheckOut, setCheckOut] = useState();
  const [currentLocation, setCurrentLocation] = useState();

  useEffect(()=>{   
    if(dynamiclocation.includes('?')){
      var savelocation = dynamiclocation.split('?')
      setCurrentLocation(savelocation[1])
      console.log("location:",dynamiclocation) 
    }
  },[dynamiclocation])

  useEffect(() => {
    // Update hideReserveCard based on screen width
    const handleResize = () => {
      if (window.innerWidth > 500) {
        setHideReserveCard(false);
      } else {
        setHideReserveCard(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  
 

  
const linkTo = `./book${currentLocation && currentLocation !== '' ? '?'+currentLocation : ''}`;

  const parseQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const checkinParam = searchParams.get("checkin");
    const checkoutParam = searchParams.get("checkout");
    const adults = searchParams.get("adults") || 1;
    const children = searchParams.get("children") || 0;
    const infant = searchParams.get("infants") || 0;
    console.log(adults,"checkinparam");
    setCheckIn(checkinParam);
    setCheckOut(checkoutParam);
    var calculatedGuestCount = getTotalGuestCount(adults,children)
    setGuestCount(calculatedGuestCount);
    setInfantCount(infant)
    setChildrenCount(children)
    setAdultCount(adults)
  };

  useEffect(() => {
    parseQueryParams();
  }, []);


  


  const onDateChange = (checkIn, checkOut) => {
    setCheckIn(checkIn?.format("YYYY-MM-DD"));
    setCheckOut(checkOut?.format("YYYY-MM-DD"));
};

  const dispatch = useDispatch();

  //to show guest + infant with s or without s in pricing calculator make custom field of guests
  options.push({ label: "Guest", value: 0, disabled: true });
  for (let i = 1; i <= props.maxGuests; i++) {
    options.push({ label: `${i} Guest${i > 1 ? "s" : ""}`, value: i });
  }
  //end


  //as soon as user changes checkin or checkout date or guest count we change the pricall state
  useEffect(() => {
    if (selectedCheckIn !== null && selectedCheckOut !== null && selectedCheckIn !== '' && selectedCheckOut !== '' && selectedCheckIn !== undefined && selectedCheckOut !== undefined) {
   calculateBookingPriceFunc(propertyId, guestCount, selectedCheckIn, selectedCheckOut)
  .then((result) => {
        setSuccessDataPrice(result);

    // Handle the result if needed
    console.log(result,'func')
  })
  .catch((error) => {
    // Handle the error if needed
  });

    }

    
  }, [selectedCheckIn, selectedCheckOut, guestCount]);


  //as soon as pricecall state changes we run the useEffect below to hit api to fetch updated pricing for user
  // useEffect(() => {
  //   axios
  //     .post(apiPublicUser + "calculate_booking_price", pricecall)
  //     .then((response) => {
  //       let alterResponse = [];
  //       response.data.data.forEach((item) => {
  //         const key = Object.keys(item)[0];
  //         alterResponse[key] = item[key];
  //       });
  //       setSuccessDataPrice(alterResponse);
  //     });
  // }, [pricecall]);





  

  const handleSuccess = (response) => {
    
  };

  console.log(successdata,'hill')

  const handleFailure = (data) => {
    console.log("Failure response:", data);
  };
// 

  useEffect(() => {
    dispatch(updateUserProperty(props.propertyId));
    dispatch(updateUserGuests(1));
    setApiCall(true);
    console.log(apicall,"apicall");
  }, []);

  useEffect(() => {
    if(propertyId !== undefined){
    const fetchData = async () => {
      try {
        const resData = await getBlockedDatesFunc(propertyId);
        console.log(resData,"resData")
        setMinDate(resData.minDate);
        setMaxDate(resData.maxDate);
        setBlockedDates(resData.blockedDates);
        setCheckinBlockedDates(resData.blockedDatesCheckin);
        setCheckoutBlockedDates(resData.blockedDatesCheckout);
        setSuccessData(resData.response);
      } catch (error) {
        console.error(error,"resData");
      }
    };
  
    fetchData(); // Call the async function immediately
  }
  
  }, [])

  const handleCounts = (adults, children, infants) => {
    // var guestlist = parseInt(adults, 10) + parseInt(children);    
    var guestlist = getTotalGuestCount(adults,children)
    var adultlist = adults;
    var childlist = children;
    var infantlist = infants;
    setGuestCount(guestlist);
    setInfantCount(infantlist);
    setChildrenCount(childlist);
    setAdultCount(adultlist);

    // Update the URL with the current counts
    // updateURLWithCounts(adultlist, childlist, infantlist);
  };

  return (
    <>

      <div className="pricing-calculated-box">
        <CardWrapper wrapperClass="pricing_calculated-card"
        hideReserveCard={hideReserveCard}
         >
          <p className="price-text">
            <span className="overall-price">₹{formatIndianRupeesFunc(props.perNightPrice)}</span>{" "}
            <span>per night + taxes</span>
          </p>
          <div className="property-detail-card-div">
            <p className="check-in-out-para">
              <span>Check-in</span>
              <span>Check-out</span>
            </p>
            <DatePicker
              minDate={minDateState}
              maxDate={maxDateState}
              blockedDates={blockDateState}
              blockedDatesCheckin={blockCheckinDateState}
              blockedDatesCheckout={blockCheckoutDateState}
              onDateChange={onDateChange}
              startDate={selectedCheckIn}
              endDate={selectedCheckOut}
              updateUrl={true}
              minNight={minNight}
              maxNight={maxNight}
            />




            <div className="guest-infant-div">
              <ButtonPopup
                saveBtnClass="sav-edit-btn"
                wrapclass="absolute w-100"
                showCloseButton={true}
                // onClose={handleCounts}
                makeCustom={
                  <div className="guest-dropdown">
                    <p className="guest-count-text">
                      {guestCount > 1 ? guestCount + " " + 'Guests' : '1 Guest'}
                      {infantCount && infantCount > 0 ? (infantCount > 1 ? ', ' + infantCount + ' Infants' : ', 1 Infant') : ''}
                    </p>
                  </div>
                }
                divContent={<BookingEditGuest maxGuests={props.maxGuests} 
                updateUrl={true}
                maxGuestText={true}
                handleCounts={handleCounts} />}
              />
            </div>
          </div>
        


          {successdataprice && (
            <>
              <div className="total-amount-box">
                <p className="total-text">Total</p>
                <p className="total-price-para">
                  <span className="total-price">₹{formatIndianRupeesFunc(parseFloat(successdataprice.total.total_property_addon_base_cost).toFixed(2))} <span className="font-xxs">+ taxes</span></span>

                </p>
              </div>
              
              <Link to={linkTo} >
                <Button btnclasses="common-next-btn" btnText="Reserve" />
              </Link>
            </>
          )}
          {!successdataprice && (
            <>
              <Link to="#">
                <Button btnclasses="common-next-btn inactive-btn" btnText="Reserve" />
              </Link>
            </>
          )}
        </CardWrapper>
      </div>
    </>
  );
};

export default PropertyPricing_calculated;

