import React from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Button from "../../../../Components/common/Button";
import { useSelector } from "react-redux";
import { setPromptComp, setPromptOkay, setPromptText } from "../../../../utils/redux/GetPromptSlice";
import { useDispatch } from "react-redux";

const DeleteImagePop = (props) => {

  const dispatch = useDispatch()
  
  const storeSel = useSelector((state) => state);
  const isPromptText = storeSel?.PromptSlice?.isPromptText;

  
  

  const handleDeleteConfirmation = (action) => {
    if (action === "confirm") {
      console.log('Dispatching confirmation');
      dispatch(setPromptOkay(true));
      console.log('Dispatching confirmation');
    } else if (action === "cancel") {
      console.log('Dispatching cancel');

      dispatch(setPromptOkay(false));
      dispatch(setPromptComp(false));
      console.log('Dispatching cancel');

    }
  };

  return (
    <>
    <div  className="paddingCustom">
      <Player
        autoplay
        loop
        style={{ height: "150px", width: "150px" }}
        src="./assets/lottie/exclamation.json"
      >
        <Controls
          visible={false}
          buttons={["play", "repeat", "frame", "debug"]}
        />
      </Player>
      <p style={{ fontSize: "16px", fontWeight: "500", color: "#222",textAlign:'center' }}>
        {/* Are You sure you want to delete the selected image */}
        {isPromptText}

      </p>
    
    </div>
      <div className="clear_apply-btn-box pd-px-10">
        <p
       onClick={() => handleDeleteConfirmation("cancel")} 

          className="clear-btn">
          Cancel
        </p>

        <Button
          btnText="Yes"
          btnclasses="save-edit-btn"
          // onClick={props.handleDelete}
          onClick={() => handleDeleteConfirmation("confirm")}
        />
      </div>
    </>
  );
};

export default DeleteImagePop;
