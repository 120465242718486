// import { useNavigate } from "react-router-dom";

export const handleNavigation = (selectedCard, storeData, path, navme) => {
  if (selectedCard !== null || storeData !== null) {
    navme(path);
  } else {
    // Perform action or show error message for the scenario where no card is selected
    alert("Please select at least 1 option");
    // Optionally, you can prevent navigation by returning early
    return;
  }
};
