import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const GuestBasic = (props) => {
  return (
    <div className="guest-basic-box">
      <h3 className="guest-name">{props.guestName}</h3>
      <p className="verified-text-box">
        <span className="verfied-text">Identity {props.verfiedText}</span>
        {props.icon && (
          <span className="guest-verified-batch">
            <img src={props.icon} />
          </span>
        )}
      </p>

      <div className="guest-review-box">
      { props.starPoint ? <><p className="guest-review-star">
          <span className="star-Point">{props.starPoint}</span>
          {props.starIcon && props.starPoint && (
            <span className="star-icon">
              <img  style={{width:'14px'}} src={props.starIcon} />
            </span>
          )}
        </p> <FontAwesomeIcon icon={faCircle} className="dividerdot"/></>: ''}
        
        <p className="no-guest-review">{props.noReviews || 'No' } Reviews</p>
      </div>
      {/* <p className="guest-duration-text">{props.guestDuration}</p> */}
      {/* <p className="guest-description-text">{props.description}</p> */}

      <p className="guest-description-text"  dangerouslySetInnerHTML={{ __html: props.description}}></p>

    </div>
  );
};

export default GuestBasic;
