import React, { useState } from "react";
import { Controller, useForm, watch } from "react-hook-form";
import InputComp from "../../../Components/common/textInput";
import APIHit from "../../../utils/apiHit";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setConfirmAddressData } from "../../../utils/redux/listingTypeSlice";
import Save_ExitComp from "../../../Components/common/save_ExitComp";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocation, faLocationDot, faMarker } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from "react";
import { countries } from "countries-list";


const ConfirmAddress = () => {
  const [formData, setFormData] = useState({});

  const [latitude,setLatitude] = useState()
  const [longitude,setLongitude] = useState()

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storeSel = useSelector((state) => state);
  // const indexStore = storeSel.indexReducer.commonIndex;

  const inputData = storeSel?.listingDataSlie.confirmAddressData;
  console.log(inputData,'allDataCheck')
  const PropIddata = storeSel.apiDataSlice?.propId;

  // console.log(inputData, "pooja");

  const Currenturl = window.location.href;
  console.log(Currenturl, "tyt");
  const CurrentPage = new URL(Currenturl);
  const path = CurrentPage.pathname;
  console.log(path, "uuu");
  console.log(inputData, "uuu");

  
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch
  } = useForm(
    {
    defaultValues: {
      house_no:
        inputData && inputData[0]?.metadata_value !== "null"
          ? inputData[0]?.metadata_value
          :  "",
      area:
        inputData && inputData[1]?.metadata_value !== "null"
          ? inputData[1]?.metadata_value
          : "",
      street_address:
        inputData && inputData[2]?.metadata_value !== "null"
          ? inputData[2]?.metadata_value
          : "",
      landmark:
        inputData && inputData[3]?.metadata_value !== "null"
          ? inputData[3]?.metadata_value
          :  "",
          country:
          inputData && inputData[4]?.metadata_value !== "null"
            ? inputData[4]?.metadata_value
            : "",
      pincode:
        inputData && inputData[5]?.metadata_value !== "null"
          ?  inputData[5]?.metadata_value
          :  "",
      city:
        inputData && inputData[6]?.metadata_value !== "null"
          ? inputData[6]?.metadata_value
          :  "",
      state:
        inputData && inputData[7]?.metadata_value !== "null"
          ? inputData[7]?.metadata_value
          :  "",
    },
  }
  );
  const watchAllFields = watch();

  const whatHappensOnSubmit = () => {
    let data = watch();
    const keys = Object.keys(data);
    const address = keys.map((key) => {
      return {
        metadata_key: key,
        metadata_value: data[key],
      };
    });
  
    // Check if latitude and longitude are present
    if (latitude && longitude) {
      address.push({ metadata_key: "latitude", metadata_value: latitude });
      address.push({ metadata_key: "longitude", metadata_value: longitude });
    }
  
    console.log(address, formData, "props2");
    dispatch(setConfirmAddressData(address));
  };
  

  const whereToGoOnNext = ()=>{
    navigate("/host/createlisting/counterBeds");
  }

  const whatHappensOnSaveAndExit = ()=>{
    whatHappensOnSubmit();
  }

  const onSubmit = (data, index) => {
    whatHappensOnSubmit(data);

    whereToGoOnNext();  
  };


  console.log("hey Brother");
  // console.log(document.querySelector("#street_address"),'idCheck')
// console.log( document.querySelector("#house_no"),'idCheck2')

  
  useEffect(() => {
    console.log('LL Inside useEffect');

    if (window.google.maps.places) {
      console.log('LL Google Maps API is available');

    const address1Field = document.querySelector("#autocomplete-input");
  
    if (address1Field) {
      // console.log('LL Found #autocomplete-input element');
      const autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: { country: ["in"] },
        fields: ["address_components", "geometry"],
        // types: [],
        types: ['geocode'],
        language: "en"
      });
  
      autocomplete.addListener("place_changed", fillInAddress);

      // console.log('LL Autocomplete initialized');
  
      function fillInAddress() {
        const place = autocomplete.getPlace();
        // const { lat, lng } = place.geometry.location;
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();

        setLongitude(longitude)
        setLatitude(latitude)
      
        console.log('Lagg:', latitude,place);
        console.log('Lagg:', longitude);


       
        // let updatedAddressData = { ...googleAddress };

      for (const component of place.address_components) {
        const componentType = component.types[0];
        console.log(component,'componentType')

        switch (componentType) {
          case "street_number": {
          
            const inputFieldHouseNo = document.querySelector('#house_no');
            if (inputFieldHouseNo) {
              inputFieldHouseNo.value = component.long_name;
              setValue('house_no', component.long_name); // Trigger setValue for 'house_no'
            }
            

            break;
          }
          case "route": {
           
            const inputFieldStreet = document.querySelector('#street_address');
            if (inputFieldStreet) {
              inputFieldStreet.value = component.long_name;
              setValue('street_address', component.long_name); // Trigger setValue for 'country here'
              // updatedAddressData = { ...updatedAddressData, street_address: component.long_name };
            }

            break;
          }
          
          case "locality":
           
            const inputFieldCity = document.querySelector('#city');
            if (inputFieldCity) {
              inputFieldCity.value = component.long_name;
              setValue('city', component.long_name); // Trigger setValue for 'country here'
              // updatedAddressData = { ...updatedAddressData, city: component.long_name };
            }

            break;
          case "administrative_area_level_1": {
       
            const inputFieldState = document.querySelector('#state');
            if (inputFieldState) {
              inputFieldState.value = component.long_name;
              setValue('state', component.long_name); // Trigger setValue for 'state here'
              // updatedAddressData = { ...updatedAddressData, state: component.long_name };
            }

            break;
          }
          case "country":
           
            const inputFieldCountry = document.querySelector('#country');
            if (inputFieldCountry) {
              inputFieldCountry.value = component.long_name;
              setValue('country', component.long_name); // Trigger setValue for 'country here'
              // updatedAddressData = { ...updatedAddressData, country: component.long_name };
            }

            break;
          case "postal_code":
          
            const inputFieldPincode = document.querySelector('#pincode');
            if (inputFieldPincode) {
              inputFieldPincode.value = component.long_name;
              setValue('pincode', component.long_name); // Trigger setValue for 'pincode here'
              // updatedAddressData = { ...updatedAddressData, pincode: component.long_name };
            }

            break;
        }
    
    }


      }
    }
  }else{
    // console.error('LL Google Maps API is not available');
  }



  }, []);


  
  const handleBlur = (fieldName, value) => {
    console.log(`Field ${fieldName} Value: ${value}`);
    const { house_no, area, street_address, landmark, country, pincode, city, state } = watchAllFields;
    const addressString = `${house_no+',' || ''}+${area+',' || ''}+${street_address+',' || ''}+${landmark+',' || ''}+${country+',' || ''}+${pincode+',' || ''}+${city+',' || ''}+${state+',' || ''}`;
    const API_KEY = "AIzaSyD2p6Azx2i_PY1RbQhin7TqLyijMt3ykT8"; 
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${addressString}&key=${API_KEY}`;
    console.log(apiUrl);

    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        // console.log("Responsecheck:", data.results[0].geometry.location.lat);
        setLatitude(data.results[0].geometry.location.lat)
        setLongitude(data.results[0].geometry.location.lng)
      })
      .catch(error => {
        console.error("Errr:", error);
      });
  };



  return (
    <div className="confirm-address-box google-address ">
      <div className="create-list-address-type-content create-listing-address-section">
        <h2>Confirm your Address</h2>
        <p>
          We only share your address with guests who have a confirmed
          reservation.
        </p>
        <div className="google-adress-flex-box">
          <div className="address-map-img-input-box">
          <div className="map-image-box">
            <img src="./assets/img/mapImage2.png" alt="" />
          </div>
          <div className="map-input-box">
          <div className="relative-map-input-box">
          <input 
          type="text"
          id='autocomplete-input'
          placeholder="Enter your location"
          className="map-input"
          />
       <FontAwesomeIcon icon={faLocationDot} className="location-icon" size="2x" />
          </div>
          </div>
         
          </div>
          <form
            className="confirm-address-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/*  */}
            <div className="flex-input-box">
              <div className="single-input-box">
                <Controller
                  control={control}
                  name="house_no"
                  id='house_no'
                  rules={{
                    required: "House/Flat info is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input"
                      name="house_no"
                      inpPlace="House/Flat"
                      id='house_no'
                      onKeyUp={onChange}
                      value={value}
                      onBlur={(e) => handleBlur("house_no", e.target.value)}

                    />
                  )}
                />
                {errors.house_no && <span className="error-text">{errors.house_no.message}</span>}
              </div>

              <div className="single-input-box">
                <Controller
                  control={control}
                  name="area"
                  id='area'
                  rules={{
                    required: "Area/Village info is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input "
                      name="area"
                      inpPlace="Area/Village"
                      onKeyUp={onChange}
                      id='area'
                      value={value}
                      onBlur={(e) => handleBlur("area", e.target.value)}


                    />
                  )}
                />
                {errors.area && <span className="error-text">{errors.area.message}</span>}
              </div>
            </div>

            {/*  */}
            <div className="flex-input-box">
              <div className="single-input-box street-inputbox">
                <Controller
                  control={control}
                  name="street_address"
                  className='street_address'
                  id='street_address'
                  rules={{
                    required: "Street info is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input confirm-street-input"
                      name="street_address"
                      inpPlace="Street Address"
                      onKeyUp={onChange}
                      value={value}
                      id='street_address'
                      onBlur={(e) => handleBlur("street_address", e.target.value)}

                      
                    />
                  )}
                />
                {errors.street_address && <span className="error-text">{errors.street_address.message}</span>}
              </div>

              <div className="single-input-box landmark-inputbox">
                <Controller
                  control={control}
                  id='landmark'
                  name="landmark"
                  // defaultValue={
                  //   inputData !== null ? inputData[3].metadata_value : ""
                  // }
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input confirm-landmark-input"
                      name="landmark"
                      inpPlace="Landmark(optional)"
                      onKeyUp={onChange}
                      id='landmark'
                       value={value}
                      onBlur={(e) => handleBlur("landmark", e.target.value)}
                      

                      
                    />
                  )}
                />
                {/* {errors.house && <span>{errors.house.message}</span>} */}
              </div>

              <div className="single-input-box ">
                <Controller
                  control={control}
                  name="country"
                  id='country'
                  rules={{
                    required: "Country info is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input "
                      name="country"
                      inpPlace="Country"
                      onKeyUp={onChange}
                      id='country'
                      value={value}
                      onBlur={(e) => handleBlur("country", e.target.value)}


                    />
                  )}
                />
                {errors.country && <span className="error-text">{errors.country.message}</span>}
              </div>
          {/* <select>
            {countryList.map((country, index) => (
              <option key={index} value={country.code}>
                {country.name}
              </option>
            ))}
         </select> */}

            </div>

            {/*  */}
            <div className="flex-input-box">
              <div className="single-input-box">
                <Controller
                id='pincode'
                  control={control}
                  name="pincode"
                  rules={{
                    required: "Pincode info is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="number"
                      inputClass="confirm-address-input"
                      name="pincode"
                      inpPlace="Pincode"
                      onKeyUp={onChange}
                      id='pincode'
                      value={value}
                      onBlur={(e) => handleBlur("pincode", e.target.value)}


                    />
                  )}
                />
                {errors.pincode && <span className="error-text">{errors.pincode.message}</span>}
              </div>

              <div className="single-input-box">
                <Controller
                  control={control}
                  name="city"
                  id="city"
                  rules={{
                    required: "City info is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input"
                      name="city"
                      inpPlace="City"
                      id="city"
                      onKeyUp={onChange}
                      // value={value}
                      onBlur={(e) => handleBlur("city", e.target.value)}


                    />
                  )}
                />
                {errors.city && <span className="error-text">{errors.city.message}</span>}
              </div>

              <div className="single-input-box">
                <Controller
                  control={control}
                  name="state"
                  id = 'state'
                  rules={{
                    required: "State info is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputComp
                      inputType="text"
                      inputClass="confirm-address-input"
                      name="state"
                      inpPlace="State"
                      onKeyUp={onChange}
                      id = 'state'
                      value={value}
                      onBlur={(e) => handleBlur("state", e.target.value)}


                    />
                  )}
                />
                {errors.state && <span className="error-text">{errors.state.message}</span>}
              </div>
            </div>
            <div className="bottom-btns-fixed-box">              
              <Save_ExitComp
                hookForm={true}
                path={path}
                // apis={["createProperty_metadata_bulk"]}
                apis={[
                  "updateProperty/" + PropIddata,
                  "createProperty_metadata_bulk",
                ]}
                firstFunction={whatHappensOnSaveAndExit}
              />

              <button type="submit" className="common-next-btn">
                Next
              </button>
            </div>
          </form>
          {/* <h1>dd</h1> */}
        </div>
      </div>
    </div>
  );
};

export default ConfirmAddress;
