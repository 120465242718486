// import axios from 'axios';
// import React, { useCallback, useState } from 'react';
// import { useDropzone } from 'react-dropzone';
// import { apiMainUser } from '../../../utils/constant';
// import Cropper from 'react-cropper';
// import 'cropperjs/dist/cropper.css';

// const HostEditPhotosPop = (props) => {
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [cropper, setCropper] = useState(null);
//   const [preview, setPreview] = useState(null);

//   const onDrop = useCallback((acceptedFiles) => {
//     if (acceptedFiles.length > 0) {
//       const file = acceptedFiles[0];
//       setSelectedFile(file);
//       setPreview(URL.createObjectURL(file));
//     }
//   }, []);

//   const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

//   const handleSave = async () => {
//     console.log('Save button clicked!');
//     if (cropper) {
//       const croppedImage = cropper.getCroppedCanvas().toDataURL(); // Get the cropped image data URL
//       const croppedFile = await fetch(croppedImage).then((res) => res.blob()); // Convert data URL to Blob

//       const formData = new FormData();
//       formData.append('image', croppedFile);
//       try {
//         const response = await axios({
//           method: 'patch',
//           url: apiMainUser + 'update_user',
//           data: formData,
//           headers: {
//             'Content-Type': 'multipart/form-data',
//             Authorization: 'Bearer ' + localStorage.getItem('token'),
//           },
//         });

//         if (response.data.status === 'success') {
//           props.fetchData();
//           props.closeModal();
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     }
//   };

//   return (
//     <div className='edit-host-img-box'>
//       <div {...getRootProps()}>
//         <input {...getInputProps()} />
//         {isDragActive ? (
//           <p>Drop the file here...</p>
//         ) : (
//           <p style={{ fontSize: '14px' }}>Click or drag and drop a file here max 1mb</p>
//         )}
//       </div>

//       {selectedFile && (
//         <div>
//           <Cropper
//             src={URL.createObjectURL(selectedFile)}
//             style={{ height: 300, width: '100%' }}
//             initialAspectRatio={1}
//             aspectRatio={1}
//             viewMode={1}
//             guides={true}
//             dragMode='move'
//             minCropBoxHeight={10}
//             minCropBoxWidth={10}
//             cropBoxResizable={false}
//             cropBoxMovable={true}
//             zoomOnWheel={false}
//             onInitialized={(instance) => {
//               setCropper(instance);
//             }}
//           />
//           <button className='save-edit-btn' onClick={handleSave}>
//             Save
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default HostEditPhotosPop;



import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { apiMainUser } from '../../../utils/constant';
import { openSnackbar } from '../../../utils/redux/SnackBarSlicer';
import { useDispatch } from 'react-redux';

const HostEditPhotosPop = (props) => {

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const dispatch = useDispatch()

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSave = async () => {
  
    console.log('Save button clicked!');
    const formData = new FormData();
    formData.append('image', selectedFile);
    try {
      const response = await axios({
        method:'patch',
        url: apiMainUser + 'update_user',
      
        data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        
      }
      
      );
      if(response.data.status === 'success'){
        props.fetchData()
        props.closeModal()
        dispatch(openSnackbar({ message: 'Image updated succesfully!', severity: 'success' }));

       
      }
    } catch (error) {
      console.error(error);
      dispatch(openSnackbar({ message: 'File Size should be less then 1mb', severity: 'error' }));

    }
    
  };

  return (
    <div className='edit-host-img-box'> 
      <div  {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here...</p>
        ) : (
          <p style={{fontSize:'14px'}}>Click or drag and drop a file here max 1mb</p>
        )}
      </div>
      
      {preview && (
        <div style={{width:'150px',height:'150px',marginBottom:'2rem',marginTop:'2rem'}}>
          <img style={{width:'100%',height:'100%'}} src={preview} alt="Selected" />
          
        </div>
      )}

      {selectedFile && (
        <button className='save-edit-btn' onClick={handleSave}>Save</button>
      )}
    </div>
  );
};

export default HostEditPhotosPop;
