import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const initialState = {
  filterData: null,
  bedsCount:null,
  bathroomsCount:null,
  bedroomsCount:null,
  amenitiesCount:null,
  spaceCount:null,
  minPriceCount:null,
  maxPriceCount:null,
  maxPriceCount:null,
  propertyTypeCount:null,

  
};
const FilterGetData = createSlice({
  name: "FilterGetData",
  initialState: initialState,
  reducers: {
    FilterGet: function (state, action) {
      state.filterData = action.payload;
    },
    bedsFilter: function (state, action) {
      state.bedsCount = action.payload;
    },
    bathroomsFilter: function (state, action) {
        state.bathroomsCount = action.payload;
      },
      bedroomsFilter: function (state, action) {
        state.bedroomsCount = action.payload;
      },
      amenitiesFilter: function (state, action) {
        state.amenitiesCount = action.payload;
      },
      minFilter: function (state, action) {
        state.minPriceCount= action.payload;
      },
      maxFilter: function (state, action) {
        state.maxPriceCount= action.payload;
      },
      spaceFilter: function (state, action) {
        state.spaceCount= action.payload;
      },
      propertyFilter: function (state, action) {
        state.propertyTypeCount = action.payload;
      },
     
    
   },
});

export const {
  FilterGet,
  bedsFilter,
  bathroomsFilter,
  bedroomsFilter,
  amenitiesFilter,
  maxFilter,
  minFilter,
  spaceFilter,
  propertyFilter
 
} = FilterGetData.actions;
export const FilterGetReducer = FilterGetData.reducer;
