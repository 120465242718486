import React from 'react'

const NightText = (props) => {
  return (
    <>
    <div className='night-text-parent'>
     <h3 className='no-days-text'>{props.days}</h3> 
     <p className='night-text'>{props.days > 1 ? 'Nights' : "Night"}</p>
     </div>
    </>
  )
}

export default NightText
