import { createSlice, configureStore } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  propertyName: null,
  propId: null,
  preferenceId: null,
  addOnId: null,
  addOnPropId: null,
};

// Create a slice using createSlice
const apiDataSlice = createSlice({
  name: "apiData",
  initialState,
  reducers: {
    setPropName: (state, action) => {
      state.propertyName = action.payload;
    },
    setPropId: (state, action) => {
      state.propId = action.payload;
    },

    setPreferenceId: (state, action) => {
      state.preferenceId = action.payload;
    },

    setAddonId: (state, action) => {
      state.addOnId = action.payload;
    },
    setAddonPropId: (state, action) => {
      state.addOnPropId = action.payload;
    },
    // setPropertyName: (state, action) => {
    //   state.map((data) => {
    //     if (data.apiData === action.payload.uid) {
    //       data = { ...data, propertyname: action.payload.propertyname };
    //     }
    //   });
    // },
  },
});
export const {
  setPropName,
  setPropId,
  setPreferenceId,
  setAddonId,
  setAddonPropId,
} = apiDataSlice.actions;
export const ApiDataReducer = apiDataSlice.reducer;
