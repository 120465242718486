import React from "react";
import { Controller, useForm } from "react-hook-form";
import InputComp from "../../../../Components/common/textInput";
import HeadingComp from "../HeadingComp";
import Button from "../../../../Components/common/Button";
import axios from "axios";
import { apiMainUser } from "../../../../utils/constant";

const AddCoHost = (props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

 


  console.log(props.listingData?.data?.[0].propertyid,'checkAll')
  const onSubmit = async (data) => {
    try {
      const token = localStorage.getItem("token");
      const email = data.addHostEmail;
      const propertyId = props.listingData?.data?.[0].propertyid;

      const response = await axios.post(
       apiMainUser + `add_cohost_to_property/${propertyId}`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        console.log('Co-Host added successfully');
        props.fetchData()
        props.closeModal()

      } else {
        console.error('Failed to add co-host');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <div className="add-co-host-main-div ">
      <form
        className="add-co-host-email-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="single-input-box">
          <Controller
            control={control}
            name="addHostEmail"
            defaultValue=""
            rules={{
              required: "Title is required",
            }}
            render={({ field: { onChange, value } }) => (
              <InputComp
                inputType="email"
                inputClass="title-input"
                name="addHostEmail"
                inpPlace="Enter email of co-host"
                onKeyUp={onChange}
                value={value}
              />
            )}
          />
          {errors.addHostEmail &&  <span className="error-text">{errors.addHostEmail.message}</span>}
        </div>

        <div className="clear_apply-btn-box paddingCustomYSide">
      
      <p onClick={() => props.closeModal(false)} className="clear-btn">
         Cancel
       </p>
      <button className="save-edit-btn" type="apply-btn">
         Add
       </button>

  
      </div>

        {/* )} */}
      </form>
    </div>
  );
};

export default AddCoHost;
