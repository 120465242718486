import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";

import InputComp from "../../../../Components/common/textInput";
import Button from "../../../../Components/common/Button";
import HeadingComp from "../HeadingComp";
import { useSelector } from "react-redux";
import EditSaveBtn from "../EditSaveBtn";
import { apiMainUser } from "../../../../utils/constant";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../../utils/redux/SnackBarSlicer";

const EditTitle = (props) => {
  console.log(props.extractedData, "tiii");
  const navigate = useNavigate();

  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const postDatavalue = {
      ...data,
    };

    try {
      let resPage = await axios({
        method: "patch",
        url: apiMainUser + `updateProperty/${props.propId}`,
        data: postDatavalue,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      console.log(resPage, "too");

      if (resPage?.data.status === "success") {
        
        console.log(resPage, "too");
        // alert("Updated Successfully");

        //
        const currentUrl = window.location.pathname;
        console.log(currentUrl, "tick");
        const baseUrl = currentUrl.split("edit/")[0];
        const updatedUrl =
          baseUrl +
          "edit/" +
          props.extractedData.split("/")[0] +
          "/" +
          resPage.data.updated_slug;
        console.log(resPage.data.updated_slug, "tick");

        navigate(updatedUrl);
        props.fetchData();
        dispatch(openSnackbar({ message: 'Title updated succesfully!', severity: 'success' }));

      }
      // Close the modal
      console.log(resPage);
      props.closeModal(false);
    } catch (error) {
      console.log(error);
      dispatch(openSnackbar({ message: 'Something went wrong!', severity: 'error' }));

    }
  };

  return (

    <div className="edit-listing-title-box">
      {/* <HeadingComp headingText="Title" /> */}
      <form className="confirm-address-form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="single-input-box">
            <Controller
              control={control}
              name="property_name"
              defaultValue={props.titleText}
              rules={{
                required: "Title is required",
              }}
              render={({ field: { onChange, value } }) => (
                <InputComp
                  inputType="text"
                  inputClass="title-input"
                  name="property_name"
                  inpPlace="Enter your title here..."
                  onKeyUp={onChange}
                  value={value}
                  defaultValue={props.titleText}
                />
              )}
            />
            {errors.property_name && (
              <span className="error-text">{errors.property_name.message}</span>
            )}
          </div>

         


          <div className="clear_apply-btn-box paddingCustomYSide">
      
           <p onClick={() => props.closeModal(false)} className="clear-btn">
              Cancel
            </p>
           <button className="save-edit-btn" type="apply-btn">
              Save
            </button>   
           </div>
           
        </div>
      </form>
    </div>
  );
};

export default EditTitle;
