import React from 'react';
// import { Controller } from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CKEditorWrapper = (props) => {
  return (
    
        <div className="ckeditor-container">
          <CKEditor
            editor={ClassicEditor}
            data={props.value}
            config={{
              toolbar: [
                'heading',
                'bold',
                'italic',
                'numberedList',
                'bulletedList',
                '|',
                'undo',
                'redo',
                '|',
              ],
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
             props.onChange(data);
            }}
          />
        </div>
 
  );
};

export default CKEditorWrapper;
