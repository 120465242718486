import React from 'react'
import { useNavigate } from 'react-router-dom';

const Step3Listing = () => {
    const navigate = useNavigate()

    const navigateTo = () => {
        navigate("/host/createlisting/rulesDiscription")
    
        };
    
    return (
    <div className='start-create-listing-page'>
    
    <div  className='flex step-flex-box m-flex-flow-column'>
        <div  className='step-1-img-box w-50 w-m-100 h-300px'>
        <img className='w-100' src="./assets/img/StartHomeImg.jpg" alt="" /> 
        </div>
    
        <div className='w-50 w-m-100'>
        <p className='step-text'>Step 3</p>
        <h2>Prepare for grand reveal - finish up and publish</h2>
        <p className='step-para-text'>In the final step, you'll define your availability window and advanced booking window. Next, you'll establish some rules for your guests, and set your desired nightly price. Simply answer a few brief questions, and when you're ready, hit the publish button to make your listing live.</p>
        </div>
        </div>
    
        <div className="bottom-btns-fixed-box">
            
            <button
            onClick={navigateTo}
            type="submit"
            className="common-next-btn"
            >
            Next
            </button>
        </div>
    </div>
    )
}

export default Step3Listing
