import React from "react";

const ProfileImage = (props) => {
  return (
    <div
      className={
        props.profileImgParent === undefined
          ? "profile-img-parent"
          : props.profileImgParent
      }
    >
      <img
        src={props.src ? props.src : './assets/img/userIconImg.jpg'}
        alt={props.alt}
        className={props.className}
        width={props.width}
        height={props.height}
      />

      {props.icon && (
        <span className={props.imgIcon}>
          <img src={props.icon} />
        </span>
      )}
    </div>
  );
};

export default ProfileImage;
