import React, { useEffect, useState } from "react";
import axios from "axios";

const APIHit = ({
  url,
  method,
  options,
  headers,
  successResponseCode,
  onSuccess,
  onFailure,
  precondition,
  children,
  handleSubmit,
  directCall,
  auth,
  apidata,
  preDispatch,
  parentClassApiHit,
  ApiHitformClass

}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    try {
      console.log("apicall in click")

      if (precondition && precondition()) {
      console.log("apicall in precondition")

        return;
      }

      setIsLoading(true);

      // if (preDispatch) {
      //   await preDispatch(); // Dispatch data before API call
      // }

      let data = apidata;
      if (auth) {
        headers = {
          Authorization: "Bearer " + localStorage.getItem("token"),
          ...headers,
        };
      }
      const response = await axios({
        method,
        url,
        data,
        headers,
        ...options,
      });
      
      if (response.status === successResponseCode) {
      console.log("apicall in success")

        onSuccess(response.data);
      } else {
        onFailure(response);
      }
    } catch (error) {
      onFailure(error.response);
      console.error("API request failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (handleSubmit) {
      try {
        setIsLoading(true);
        await handleSubmit();
      } catch (error) {
        console.error("Form submission failed:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      handleClick();
    }
  };

  useEffect(() => {
    if (directCall && directCall !== undefined) {
      console.log("apicall called")
      handleClick();
    }
  }, []);

  useEffect(() => {
    if (
      handleSubmit &&
      apidata !== undefined &&
      apidata !== null &&
      apidata !== ""
    ) {
      handleClick();
    }
  }, [apidata]);

  console.log(apidata);
  return (
    <>
    {!directCall &&
      <div className={parentClassApiHit} onClick={handleSubmit ? handleFormSubmit : handleClick}>
        {handleSubmit ? (
          <form className={ApiHitformClass} onSubmit={handleFormSubmit}>
            {isLoading ? "Loading..." : children}
            <button type="submit" style={{ display: "none" }}>
              Submit
            </button>
          </form>
        ) : (
          <>
            {isLoading ? "Loading..." : children}
            {/* <button type="submit" style={{ display: "block" }}>
            Submit
          </button> */}
          </>
        )}
      </div>
    }
    </>
  );
};

export default APIHit;
