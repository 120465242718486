import React, { useEffect } from "react";
import GuestProfile from "../../Components/common/guestProfile";
import CardWrapper from "../../Components/common/cardWrapper";
import Button from "../../Components/common/Button";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { A11y, Navigation, Pagination, Virtual } from "swiper";
import 'swiper/swiper-bundle.min.css';
import ReviewCard from "../../Components/HostComponents/reviewCard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiMainUser, apiPublicUser } from "../../utils/constant";
import { useState } from "react";
import moment from "moment";
import HostMailEdit from "../Host/PersonalInfoPops/HostMailEdit";
import WrapperPopup from "../../Components/common/wrapperPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faStar } from "@fortawesome/free-solid-svg-icons";
import HostPhoneEdit from "../Host/PersonalInfoPops/HostPhoneEdit";
import { useSelector } from "react-redux";
import SkeletonLoader from "../../Components/common/SkeletonLoader";
import UserReviewCard from "../../Components/UserComponents/UserReviewCard";

// SwiperCore.use([Navigation]);
SwiperCore.use([Navigation, Virtual,Pagination, A11y]);



const UserProfilePage = () => {


  const [usersData,setUsersData] = useState()
  const [userNameurl,setUserNameUrl] = useState()

  const [EmailPopupVerify, setEmailPopupVerify] = useState(false);
  const [phonePopupVerify, setphonePopupVerify] = useState(false);
  const [listing,setListing]= useState([])

  const isSmallScreen = window.innerWidth <= 500;

  const storeSel = useSelector((state) => state);

  const hostId = storeSel?.hostDetailSlice?.host_Uid
  const ReduxuserName = storeSel.hostDetailSlice.user_Name;


  console.log(hostId,'checkOnceId')
  const navigate = useNavigate()



 
  
  const [reviews, setReviews] = useState([]);
  const [assetsurl, setAssetsUrl] = useState();
  const [ratingAverage, setRatingAverage] = useState(0);
  const location = useLocation();
  
 
  const fetchData = async () => {
    try {
      const userName = location.pathname.split('/').pop();
     
      if (!userName) {
        // If userName is not available, do not make the API calls
        return;
      }
      setUserNameUrl(userName)

      // Fetch user details
      const userResponse = await axios.get(apiMainUser + "read_user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          username: userName
        }
      });

      // Fetch host reviews
      const reviewResponse = await axios.get(apiPublicUser + `reviews-ratings/user/${userName}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      // Fetch host listings        
      const listingsResponse = await axios.get(`${apiPublicUser}search_property?username=${userName}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (reviewResponse.data.status === 'success') {
        setReviews(reviewResponse.data.data);
        setAssetsUrl(reviewResponse.data.assets_url);
        setRatingAverage(reviewResponse.data.data.user.total_rating);
      }

      if (listingsResponse.data.status === 'success') {
        setListing(listingsResponse.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {

    fetchData();

  }, [location.pathname]); // Re-run effect when location changes



  const fetchVerifiedData = async () => {
    try {
      const userName = location.pathname.split('/').pop();

      const response = await axios.get(apiMainUser + "read_user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          username: userName
        }
      });
      // await axios.get(apiMainUser + "get_user_detail", {
       
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //   },
      // });

      // console.log(response.data,response.data.status,'tts')
      if (response.data.status === 'success') {          
        setUsersData(response.data.data)
        setAssetsUrl(response.data.assets_url);       
      }
    } catch (error) {
      console.error(error);
    }
  };


  console.log(reviews,ratingAverage,'checkBothData')



  
  const toggleVisibleDocks = () => {
    setEmailPopupVerify(false)
    setphonePopupVerify(false)

 
   };
 

  //  const reviews = usersData?.reviews?.user?.reviews
   const ratingAvg =  usersData?.reviews?.user?.total_rating


   console.log(reviews?.review,'log')


   const formattedDateDecimal = moment().diff(moment(usersData?.createdon),'years',true);
   var integerPart = Math.floor(formattedDateDecimal);
        var formattedDateDiff = Math.floor((formattedDateDecimal - integerPart) * 10);
        var formattedDate;
        if (formattedDateDiff > 0) {
          formattedDate = integerPart + 0.1;
      } else {
          formattedDate = integerPart;
      }

  const navigateTo =()=>{
    navigate('/hostcreateprofile')
  }

  console.log(listing,'checkListing')

  useEffect(()=>{
    fetchVerifiedData()
    // fetchData()
  console.log(usersData,'usersData')

    // fetchHostListings()
    // fetchHostReviewData()
  },[])


 
 
  return (
    <div className="host-profile-page-div">
 
     <div className="host-profile-page-left-div" >
     <div className="host-profile-left-child-div">
      <CardWrapper wrapperClass='host-profile-left-card'>
      <GuestProfile profileImage={assetsurl+usersData?.image} whose-profile='host'/>

       <div className="host-profile-rating-div w-100">
      {reviews?.host?.review_count &&  
      <p className="host-profile-rating-text">
        <span className="rating-number-text">{reviews?.host?.review_count}</span>
        <span className="rating-text">Reviews</span>
      </p>
     }
     {reviews?.host?.total_rating?.Overall_Average && 
      <p className="host-profile-rating-text">
        <span  className="rating-number-text">{reviews?.host?.total_rating?.Overall_Average}</span>
        <span className="rating-text">Rating</span>
      </p>
    }
      <p className="host-profile-rating-text host-profile-rating--last-text">
        <span  className="rating-number-text">{formattedDate}</span>
        <span className="rating-text ">Year hosting</span>
      </p>

       </div>
      </CardWrapper>
       
       <div className="host-confirm-info-box">
         <h2>Guest Confirmed information</h2>
          
         <div className="host-confirmed-button-box">
          <div className="host-btn-div">
            <p className="host-confirmed-identity-text">Identity</p>
            {/* <Button btnclasses='save-edit-btn verify-btn' btnText='Verify' /> */}
            <FontAwesomeIcon icon={faCircleCheck} className="verify-icon-class"/>
          </div>
          <div className="host-btn-div">
            <p className="host-confirmed-identity-text">Email</p>
            {usersData?.email_verified !== 1 ? 
           <Button 
           onClick={() => setEmailPopupVerify(true)}
          btnText='Verify'
           btnclasses='save-edit-btn verify-btn'/>  
          :
          <FontAwesomeIcon icon={faCircleCheck} className="verify-icon-class"/>
          }
           
          </div>
          <div className="host-btn-div">
            <p className="host-confirmed-identity-text"> Phone</p>
            {usersData?.mobile_verified !== 1 ? 
              <Button 
              onClick={() => setphonePopupVerify(true)}
              btnText='Verify'
               btnclasses='save-edit-btn verify-btn'
               />
            :
          <FontAwesomeIcon icon={faCircleCheck} className="verify-icon-class"/>
          }
       

          </div>

         </div>
         <p className="host-confirm-info-text">By confirming account information, we can enhance the safety and protection of every member in the Jumbo community.</p>
       </div>
       </div>
     </div>
     <div className="host-profile-page-right-div">
      <div className="create-profile-box">
        <div className="create-profile-text-div">
       <h2 className="main-heading">Hi, I'm {usersData?.fname} {usersData?.lname}</h2>
      
      <p dangerouslySetInnerHTML={{ __html: usersData?.about_me}}></p>
       </div>
       {ReduxuserName == userNameurl ? 
       <Button onClick={navigateTo} btnText='Manage Profile' btnclasses='save-edit-btn'/>
       :
       ""
}
      </div>

      
      
      
 {reviews.host?.reviews.length > 0 ?  <h2 className="main-heading">Host Reviews</h2> : ''}  

    <div className="review-main-parent-div pad-0">
      {reviews.host?.reviews && reviews.host?.reviews.length > 0 ? (
        <Swiper
          spaceBetween={16}
          slidesPerView={isSmallScreen ? 1 : 3}
          navigation
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination]}
        >
          {reviews.host?.reviews.map((review, index) => {
            const formattedDate = moment(review.updatedon).diff(moment(),'years',true);

            return (
              <SwiperSlide key={index}>
                <UserReviewCard
                  userReviewText={review.review ? review.review : ''}
                  profileName={review?.user_fname + ' ' + review?.user_lname}
                  profileImage={
                    review?.user_image !== null
                      ? assetsurl+review?.user_image
                      : undefined
                  }
                  rating={review?.ratings[0]?.rating_value}

                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        // If no reviews exist or the length is 0
        <p>No reviews yet</p>
      )}
    </div>


    {reviews.user?.reviews.length > 0 ?  <h2 className="main-heading">Guest Reviews</h2> : ''}  

    <div className="review-main-parent-div pad-0">
      {reviews.user?.reviews && reviews.user?.reviews.length > 0 ? (
        <Swiper
          spaceBetween={16}
          slidesPerView={isSmallScreen ? 1 : 3}
          navigation
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination]}
        >
          {reviews.user?.reviews.map((review, index) => {
            console.log(review.ratings,'checkMapRating')
            const formattedDate = moment(review.updatedon).format('DD MMM YYYY');

            return (
              <SwiperSlide key={index}>
                <UserReviewCard
                  userReviewText={review.review ? review.review : ''}
                  profileName={review?.user_fname + ' ' + review?.user_lname}
                  profileImage={
                    review?.user_image !== null
                      ? assetsurl+review?.user_image
                      : undefined
                  }
                  rating={review?.ratings[0]?.rating_value}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        // If no reviews exist or the length is 0
        <p>No reviews yet</p>
      )}
    </div>



  {listing.length > 0 && <h2 className="main-heading">Host Listing's</h2>}  
  <div className="host-listing-div"> 
  {listing ? (
    <Swiper
      spaceBetween={50}
      slidesPerView={isSmallScreen ? 1 : 3}
      navigation
      autoHeight 
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
    >
      {listing?.map((slide) => (
        <SwiperSlide key={slide.id}>
           <Link to={`/properties/${slide.city}/${slide.slug}`}>
          <div className='review-card'>
            <div className="host-list-img-box">
              <img src={slide?.base_url_image + slide?.images[0]} alt="no-img" />
            </div>
            <div className="review-card-text-box">
              <div style={{display:'flex',gap:'2.6rem',marginBottom:'0.7rem'}}>
                <div className="w-76">
                <p className="title">{slide?.property_name}</p>
                <p className="sub-tilte-city">{slide?.city},{slide?.state}</p>
                </div>
                {/* <span className="sub-title">{slide.space_type}</span> */}
                <div className="w-25">
                {/* <span className="sub-title">{slide.space_type}</span> */}
                {/* review_count={data?.review_count}
                  Overall_rating={data?.Overall_rating} */}
                {(slide.review_count && slide.Overall_rating) !== null && (slide.review_count && slide.Overall_rating) !== undefined && (slide.review_count && slide.Overall_rating) > 0 &&
            <div  className="flex" style={{flexDirection:'column'}} >   
            <p>
              <span className="star-num-trending-card bold" style={{fontSize:'1.3rem'}}>{slide?.Overall_rating.toFixed(1)}</span>
              <span className="pd-px-3">
                {/* <img src="./assets/img/BigBlackStar.svg" alt="" /> */}
                <FontAwesomeIcon
                icon={faStar}
                // size='2x'
                fontSize={"14px"}
                color="#FFC700"
              />
              </span>
            </p>
            <span style={{ fontSize: "1.1rem", fontWeight: "500" }}>
              {slide.review_count} reviews
            </span>
          </div> 
          

          }
          </div>
              </div>
              {/* {slide.reviewCount?.length > 0 ?
              <div className="host-listing-review-details-box">
                <div style={{display:'flex',gap:'0.3rem'}}>
                  <p className="rating">{slide.rating}</p>
                </div>
                <div style={{display:'flex',gap:'0.3rem'}}>
                  <p className="review-count-text">{slide.reviewCount}</p>
                  <p className="review-text">reviews</p>
                </div>
              </div>
              :
 
              <p style={{fontSize:'1rem',textAlign:'start'}}>No reviews</p>
             

      } */}
            </div>
          </div>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  ) : (
    <SkeletonLoader width={240} height={100} />
  )}
</div>


    </div>

    <div className='personal-info-page-popup-main-div'>
      {EmailPopupVerify && (

        <WrapperPopup 
        fluid={true}
        defaultSize={"0.60"}
        headingText='Verify Your Email'
        dimStyle={false}
        isVisible={true} 
        setVisibleDocks={toggleVisibleDocks} 
        closeModal={setEmailPopupVerify} 
        comp={<HostMailEdit
          fetchData={fetchVerifiedData}
          usersData={usersData} 
          closeModal={setEmailPopupVerify} />} />
      )}
      </div>


      
    <div className='personal-info-page-popup-main-div'>
      {phonePopupVerify && (
        <WrapperPopup 
        fluid={true}
        defaultSize={"0.60"}
        dimStyle={false}
        isVisible={true} 
        headingText='Verify Your Number'
        setVisibleDocks={toggleVisibleDocks} 
        closeModal={setphonePopupVerify} 
        comp={<HostPhoneEdit
          fetchData={fetchVerifiedData}
          usersData={usersData} 
          closeModal={setphonePopupVerify} />} />
      )}
      </div>
      
    </div>
  );
};

export default UserProfilePage;
