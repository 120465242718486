import React from "react";
import WrapperPopup from "../common/wrapperPopup";
import AmenitiesPopup from "./AmenitiesPopup";
import { useState } from "react";
// const obj = [
//   { name: "Parking", img: "./assets/img/amenitiesImg1.svg" },
//   { name: "Wifi", img: "./assets/img/amenitiesImg2.svg" },
//   { name: "FireGuard", img: "./assets/img/amenitiesImg3.svg" },
//   { name: "FirstAid", img: "./assets/img/amenitiesImg4.svg" },
//   { name: "shower", img: "./assets/img/amenitiesImg5.svg" },
//   { name: "Park", img: "./assets/img/amenitiesImg6.svg" },
//   { name: "Gym", img: "./assets/img/amenitiesImg7.svg" },
//   { name: "Pool table", img: "./assets/img/amenitiesImg8.svg" },
// ];
const PropertyAmenitiesPop = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const toggleVisibleDocks = () => {
    setOpenModal((prevVisible) => !prevVisible);
  };

  return (
    <>
      <div className="property-amenties-container">
        <div className="property-amenities-with-popup">
          <div className="amenities-box">            
              {
                (props.amenities).slice(0, 6).map((item,index) => {                  
                  return (
                    <div className="amenities-list">
                      <img src={item.icon_url} alt="" />
                      <p>{item.amenity_name}</p>
                    </div>
                  );
                })
              }            
          </div>
        </div>
        <div >
        {props.amenities.length > 6 && (
          <button onClick={() => setOpenModal(true)} className="btnShowAll">
            Show All Amenities
          </button>
        )}
      </div>

        {openModal && (
          <WrapperPopup 
          fluid={true}
          DockFilter={true}
          position='bottom'
          headingText='What this place offers'
          // dimMode={props.opaque ? props.opaque : false}
          defaultSize={"0.85"}
          dimStyle={false}
          // DockFilter={true}
          // isVisible={openModal}
          isVisible={true}
          
          setVisibleDocks={toggleVisibleDocks} 
          
          
          closeModal={setOpenModal} comp={<AmenitiesPopup list={props.amenities}/>} />
        )}
      </div>
    </>
  );
};

export default PropertyAmenitiesPop;
