import React, { useEffect, useState } from "react";
import UserPropertyDetailsIn from "./PropertyDetails/DetailsIn";
import APIHit from "../../utils/apiHit";
import { apiMainUser, apiPublicUser } from "../../utils/constant";
import RequestToBookIn from "../../Module/User/RequestToBookIn";
// import { useSelector } from "react-redux";



const RequestToBook = (props) => {


  const [apicall, setApiCall] = useState(false);
  const [successdata, setSuccessData] = useState([])

  const [insertId,setInsertId] = useState()
  const [successdatatrans, setSuccessDataTrans] = useState([])
  var slug = window.location.pathname.replace("/properties/","")
  slug = slug.replace("/book","");
  
  // const storeSel = useSelector((state) => state);
  // const searchdata = storeSel.UserSearchConfig;  
  // console.log(searchdata)

  const queryParams = new URLSearchParams(window.location.search);
  const checkinDate = queryParams.get("checkin");
  const checkoutDate = queryParams.get("checkout");
  const adults = parseInt(queryParams.get("adults") || "1", 10);
  const childrens = parseInt(queryParams.get("children") || "0", 10);
  const infants = parseInt(queryParams.get("infants") || "0", 10);

  
  // Calculate guestCount as the sum of adults and children
  const guestCount = adults + childrens;

  console.log(successdatatrans,"successdatatrans");
  const handleSuccess = (response) => {
    // Handle the successful response here
    setSuccessData(response.data);    
  };

  console.log(successdata,'successData')
  const handleFailure = (data) => {
    // Handle the successful response here
    console.log("Failure response:", data);
  };
  const handleSuccessTrans = (response) => {
    console.log(response.data,'kkn')
    // Handle the successful response here
    setSuccessDataTrans(response.transaction_details.data[0]);  
    setInsertId(response.data.insertId)  
  };
  console.log(successdatatrans,'kkm')


  const handleFailureTrans = (data) => {
    // Handle the successful response here
    console.log("Failure response:", data);
  };

  const handleUpdateTransactionResponse = (response) => {
   
    setSuccessDataTrans(response);
  };

  const runPrecondition = () => {
    // Perform your precondition check here
    // Return true if the precondition is met, or false otherwise
    if (successdata != []) {
      return false;
    }
    return true;
  };  
  useEffect(() => {
    setApiCall(true);
  }, []);
console.log(successdata,'checkprop')
 
  return (
    <>
    {apicall === true && successdata.length < 1 && (   
        <APIHit
          url={apiPublicUser + "search_property/?slug="+slug}
          method="GET"
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          precondition={runPrecondition}
          successResponseCode={200}
          // apidata={amenitiesData}
          directCall={true}
          auth={true}
        ></APIHit>
    )}
    {successdata.length > 0 && (
        <APIHit
          url={apiMainUser + "create_transaction"}
          method="POST"
          onSuccess={handleSuccessTrans}
          onFailure={handleFailureTrans}          
          successResponseCode={200}
          apidata={{
            "property_id":successdata[0].propertyid,    
            "checkin_date":checkinDate,
            "checkout_date":checkoutDate,
            "guest_count":guestCount,
            "infants":infants,

            "adults":adults,
            "children":childrens


          }}
          directCall={true}
          auth={true}
        ></APIHit>
      )}
    
      <RequestToBookIn
       onUpdateTransactionResponse={handleUpdateTransactionResponse}
        insertId={insertId}
         transid={successdatatrans}
          propdetails={successdata[0]}
          />
    </>
  );
};

export default RequestToBook;