import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import PropertyTripDetailComponent from '../../user/PropertyDetails/PropertyTripDetailComponent';
import TripConfirmationCardComp from '../../user/PropertyDetails/TripConfirmationCardComp';
import GuestBasic from '../../../Components/common/guestBasic';
import CardWrapper from '../../../Components/common/cardWrapper';
import ProfileImage from '../../../Components/common/profileImg';
import EarningBreakDownCard from '../../../Components/common/earningBreakdownCard';
import SupportCard from '../../user/PropertyDetails/SupportCard';
import { Button } from '@mui/material';
import WrapperPopup from '../../../Components/common/wrapperPopup';
import ViewBreakDownCard from '../../../Components/HostComponents/viewBreakdownCard';
import moment from 'moment';

const HostUpcomingDetail = (props) => {

    const userNameForHost = props.successdata?.fname + ' ' + props.successdata?.lname;
    const userImageForHost = props.assetsurl + props.successdata?.image



    let totalObject = null;
  
            props.successdata?.transaction_details[0].price_detail.forEach((item) => {
            if ("total" in item) {
              totalObject = item["total"];
            }
          });

 const [openModal, setOpenModal] = useState(false);


    const toggleVisibleDocks = () => {
        setOpenModal(false)
      }


      const handleOpenModal = () => {
        setOpenModal(true);
      };
    
      const handleCloseModal = () => {
        setOpenModal(false);
      };
    
      const data = props.successdata?.transaction_details[0]?.price_detail;
        const addonData = props.successdata?.transaction_details[0]?.addon_details;
        console.log(data, "tried", "sdss");
    
        console.log(props?.successdata,'checkAddonData')
        let priceDetailMapvalues = {};
        data?.forEach((x) => {
          console.log(x, 'tried', 'wow');
      
          if (x.total) {
            console.log("tried", x.total);
            priceDetailMapvalues = x.total;
          }
        });
      
  return (
    <div className="property-page">      
    <div className="property-page-grid-box">
      <div className="property-page-left-content">
        <div className="property-page-property-name-box">
          <div className="property-name-box">
            <p className="property-name-text">{props.successdataprop?.property_name}</p>
            <p className="property-name">{props.successdataprop?.space_type} hosted by {props.successdataprop?.host_details[0].fname}</p>
          </div>
          <Link to={"/properties/"+ props.successdataprop?.city+"/"+ props.successdataprop?.slug}><Button btnText="View" btnclasses="offer-btn" /></Link>
        </div>
        <div className="property-page-booking-img-box">
        <img
          className="property-page-property-img"
          src={props.successdataprop?.base_url_image+ props.successdataprop?.images[0]}
          alt=""
        />
        </div>
      
        <PropertyTripDetailComponent
          successdata={props.successdata} 
          checkInTime={props.checkInTime}
          checkOutTime={props.checkOutTime}
        />

        <TripConfirmationCardComp
        successdata={props.successdata} 
        successdataprop={props.successdataprop}
        handleOpenModal={handleOpenModal}

      /> 
      </div>

     
      <div className="property-page-right-content">
      <CardWrapper wrapperHeading='About Guest' wrapperClass='about-guest-card pointer'>
          <GuestBasic
              guestName={userNameForHost}
              verfiedText='Verified'
              icon='./assets/img/guestverified.svg'
              starPoint={props.ratingAverageData ? props.ratingAverageData : 0}
              starIcon='./assets/img/colorStarIcon.svg'
              noReviews={props.review && props.review.length !== 0 ? props.review.length : 0}
              guestDuration='Jumbo guest since April 2023'
          />
      <ProfileImage src={props?.successdata?.image ? userImageForHost : undefined} width='100' />
      </CardWrapper>



              {/*  */}
              <EarningBreakDownCard
              totalDayAmount={parseFloat(totalObject?.total_property_addon_base_cost).toFixed(2) }
                perDayAmount={parseFloat(totalObject?.total_property_addon_base_cost / props.successdata?.nights).toFixed(2)}
                noOfDays={props.successdata?.nights}
                // serviceAmount="₹ 2,999"
                occupancyAmount={parseFloat(totalObject?.total_property_tax_cost).toFixed(2)}

                totalAmount={parseFloat(totalObject?.grand_total_booking_cost).toFixed(2)}
              />

              
          <SupportCard
           canCancel
           hostCancel={true}
           fetchData={props.fetchData}
           listingData={props.listingData}
           successdata={props.successdata}
           bookingOrderId={props.bookingOrderId}
           apicall={props.apicall}
           setApiCall={props.setApiCall}
          />
        </div>

    </div>

    <div className="view-breakdown-popup">
          {openModal && (
            <WrapperPopup
            // AppylyBtnBox={true}
            defaultSize={"0.45"}
            dimStyle={false}
            isVisible={true} 
            setVisibleDocks={toggleVisibleDocks}
            
            //   closeModal={() => setOpenModal(false)}
              closeModal={handleCloseModal}
              comp={
                <ViewBreakDownCard
                  src={props.successdataprop?.base_url_image + props.successdataprop?.images[0]}
                  starRating="5"
                  addons={addonData}
                  reviewNo="7"
                  propertyName={props.successdataprop?.property_name}
                  entireProperty={props.successdataprop?.space_type}
                  perDayprice={priceDetailMapvalues?.total_property_addon_base_cost?.toFixed(2)}
                  ExpPrice={priceDetailMapvalues.total_experience_base_cost?.toFixed(2)}
                  TaxPrice={priceDetailMapvalues.total_property_tax_cost?.toFixed(2)}
                  ServiceFee={priceDetailMapvalues.total_property_service_cost?.toFixed(2) || 0}
                  totalAmount={priceDetailMapvalues?.grand_total_booking_cost?.toFixed(2)}
                  noOfNight={moment(props.successdata?.transaction_details[0].checkout_date).diff(moment(props.successdata?.transaction_details[0].checkin_date), "days")}
                />
              }
            />
          )}
        </div>
 
 

  </div>
  )
}

export default HostUpcomingDetail
