import React from "react";
import ReviewText from "../../common/reviewText";
import CardWrapper from "../../common/cardWrapper";
import axios from "axios";
import { apiMainUser, formatIndianRupeesFunc } from "../../../utils/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const ViewBreakDownCard = (props) => {

  console.log(props,"props")
  
  const isSmallScreen = window.innerWidth <= 500

  const handleRemoveCoupon = () => {
  
    const  headers= {
      Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const requestData = {
      transaction_id: props.transactionId,
    };
    const apiEndpoint = apiMainUser +  `remove_coupon`;
  
    axios.post(apiEndpoint, requestData, { headers })
      .then(response => {
       
        props.updateTransaction()
      })
      .catch(error => {
       
        console.error(error);
      });
  };
  

  
  return (
    <CardWrapper wrapperClass="view-breakdown-main-box">

      { !isSmallScreen  && props.propertyName &&
      <div className="view-breakdown-top-box pointer" onClick={props.onClick}>
        <div className="view-breakdown-img-box">
          <img
            src={props.src}
            alt={props.alt}
            className={props.className}
            width={props.width}
            height={props.height}
          />
        </div>
        <div className="view-breakdown-top-text-box">
          <p className="entire-property">{props.entireProperty}</p>
          <p className="property-name">{props.propertyName}</p>
          <p className="view-breakdown-rating-line">
            <span>              
              <span style={{ marginLeft: "2px" }}>{props.starRating}</span>{" "}
              <img style={{width:'14px'}} src="./assets/img/colorStarIcon.svg" alt="" />
            </span>{" "}
            <FontAwesomeIcon icon={faCircle} className="dividerdot"/>
            <span style={{ fontSize: "1.4rem",fontWeight:'400' }}>
              {props.reviewNo} Reviews
            </span>
          </p>
        </div>
      </div>
      }

      <div className="view-breakdown-mid-price-box">
        <h2>Price details</h2>
        <div className="view-breakdown-price-details-box">
          <div>
            <p>
              {/* <span>{props.perDayprice}</span>x{" "} */}
              <span>{(parseFloat(props?.perDayprice / props.noOfNight)).toFixed(2)} x {props.noOfNight} {props.noOfNight === 1 ? 'night' : 'nights'}</span>

            </p>
            {props?.addons?.length > 0 && (
              <div>
                {props.addons.map((addon, index) => (
                  <p key={index}>
                    <span>{addon.quantity} x </span>
                    <span>{addon.name}</span>
                  </p>
                ))}
              </div>
             )}
         
        
            <p>Experience</p>
            <p>Taxes</p>
            <p>Service Fee</p>
            <p  
             className={`view-breakdown-coupon-text ${props.coupon === 'Coupon applied' ? 'view-breakdown-coupon-applied' : ''}`} 
            onClick={props.couponClick}
            >{props.coupon}</p>
            <span className=" view-breakdown-remove-text">{props.CouponLabelText}</span> <span  onClick={handleRemoveCoupon}  className="view-breakdown-remove-text underline ">{props.removeText}</span>
          </div>

          <div style={{ textAlign: "right" }}>
            <p>₹{formatIndianRupeesFunc(props?.perDayprice)}</p>
            {props?.addons?.length > 0 && (
              Array(props.addons.length).fill(null).map((_, index) => (
                <p key={`empty-${index}`}>&nbsp;</p>
              ))
            )}
            <p>₹{formatIndianRupeesFunc(props?.ExpPrice)}</p>
            <p>₹{formatIndianRupeesFunc(props?.TaxPrice)}</p>
            <p>₹{formatIndianRupeesFunc(props?.ServiceFee)}</p>            
            <p className="discount-para">{props?.DiscountPrice} </p>
          </div>
        </div>
      </div>
            <br/>
      <div className="view-breakdown-total-amount-box">
        <p>Total</p>
        <p className="view-breakdown-total-amount">₹{formatIndianRupeesFunc(props.totalAmount)}</p>
      </div>
    </CardWrapper>
  );
};


export default ViewBreakDownCard;
