import React, { useState, useEffect, useRef } from "react";

function TableHead({ columns, sortColumn, sortOrder, onSort }) {
  return (
    <thead className="table-head">
      <tr className="row header row-header">
        {columns.map((column, index) => (
          <th
            key={index}
            className={`cell ${sortColumn === index ? sortOrder : ""}`}
            onClick={() => onSort(index)}
          >
            {column}
          </th>
        ))}
      </tr>
    </thead>
  );
}

function TableBody({ data, columnWidths, hidden }) {
  const newdata = Object.keys(data[0] || {}).filter(
    (column) => !hidden.includes(column)
  );

  return (
    <tbody>
      {data.map((row, rowIndex) => (
        <tr key={rowIndex} className="row">
          {Object.keys(row).map(
            (cellContent, columnIndex) => (
              newdata.includes(cellContent) ? (
                <td
                  key={columnIndex}
                  className="cell"
                  style={{ width: `${columnWidths[columnIndex]}px` }}
                >
                  {row[cellContent]}
                </td>
              ) : (
                ""
              )
            )
          )}
        </tr>
      ))}
    </tbody>
  );
}

function DynamicTable({ data, hidden, itemsPerPage }) {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [columnWidths, setColumnWidths] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const tableRef = useRef(null);

  useEffect(() => {
    const calculateColumnWidths = () => {
      const table = tableRef.current;
      const tableRows = [...table.querySelectorAll("tbody tr")];

      const columns = [...table.querySelectorAll("thead th")];
      const widths = columns.map(() => 0);

      tableRows.forEach((row) => {
        const cells = [...row.cells];
        cells.forEach((cell, columnIndex) => {
          const contentWidth = cell.scrollWidth;
          widths[columnIndex] = Math.max(widths[columnIndex], contentWidth);
        });
      });

      setColumnWidths(widths);
    };

    calculateColumnWidths();
  }, [data]);

  const handleSort = (columnIndex) => {
    if (columnIndex === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnIndex);
      setSortOrder("asc");
    }
  };

  const hiddenHeaders = hidden;
  const columns = Object.keys(data[0] || {}).filter(
    (column) => !hiddenHeaders.includes(column)
  );

  // Sorting logic
  const sortedData = [...data];
  if (sortColumn !== null) {
    sortedData.sort((a, b) => {
      const keyA = Object.keys(a)[sortColumn];
      const keyB = Object.keys(b)[sortColumn];
      const valueA =
        typeof a[keyA] === "string" || typeof a[keyA] === "number"
          ? typeof a[keyA] === "number"
            ? a[keyA]
            : a[keyA].toLowerCase()
          : a[keyA + "_sort"];
      const valueB =
        typeof b[keyB] === "string" || typeof b[keyB] === "number"
          ? typeof b[keyB] === "number"
            ? b[keyB]
            : b[keyB].toLowerCase()
          : b[keyB + "_sort"];
      if (valueA < valueB) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  // Pagination logic
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = sortedData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1); // Reset currentPage to 1 whenever data changes
  }, [data]);

  return (
    <div>
      <table className="table" ref={tableRef}>
        <TableHead
          columns={columns}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          onSort={handleSort}
        />
        <TableBody
          data={currentData}
          columnWidths={columnWidths}
          hidden={hiddenHeaders}
        />
      </table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
}








// useEffect(()=>{

// },[currentPage])

function Pagination({ currentPage, totalPages, onPageChange }) {
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);


  return (
    <div className="pagination">
      {pages.map((page) => (
        <button
          key={page}
          className={`page ${currentPage === page ? "active" : ""}`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      ))}
    </div>
  );
}

export default DynamicTable;
