import React, { useState } from "react";
import CardWrapper from "../../../Components/common/cardWrapper";
import AddToCart from "../../../Components/common/addCarts";
import Checkbox from "../../../Components/common/checkbox";
import Button from "../../../Components/common/Button";
import { Link, useNavigate } from "react-router-dom";

import {
  updateAdditionalBeds,
  updateAdditionalGuests,
  updateBaseGuest,
  updatePerNightCost,
  updateWeekendPrice,
} from "../../../utils/redux/PricingCounter";
import { useDispatch } from "react-redux";
import GuestAddCart from "../../../Components/common/guestAddCart";
import Save_ExitComp from "../../../Components/common/save_ExitComp";
import { useSelector } from "react-redux";
import { setPricingCommonIndex } from "../../../utils/redux/commonIndex";
import { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";
import InputComp from "../../../Components/common/textInput";

const ListingPricing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [guestCount, setGuestCount] = useState();
  // const [bedCount, setBedCount] = useState();
  const storeSel = useSelector((state) => state);

 

  const inputData = storeSel?.pricingCartSlicer;

 
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      perNightCost:
        inputData && inputData?.perNightCost !== "null"
          ? inputData?.perNightCost
          : "",
          weekendPrice:
          inputData && inputData?.weekendPrice !== "null"
            ? inputData?.weekendPrice
            : "",
          additionalGuests:
        inputData && inputData?.additionalGuests !== "null"
          ? inputData?.additionalGuests
          : "",
        
    },
  });

  const whatHappensOnSubmit = () => {
    let data = watch();
    const PricingData = {
      ...data,
    };
  
    dispatch(updateAdditionalGuests(PricingData.additionalGuests));
    dispatch(updatePerNightCost(PricingData.perNightCost));
    dispatch(updateWeekendPrice(PricingData.weekendPrice));

    dispatch(updateAdditionalBeds(PricingData.additionalBeds));
  };

  const whereToGoOnNext = ()=>{
    navigate("/host/createlisting/reviewListing");
  }

  const whatHappensOnSaveAndExit = ()=>{
    whatHappensOnSubmit();
  }

  const onSubmit = (data) => {
    whatHappensOnSubmit(data);
    whereToGoOnNext();    
  };
  


  const [api, setApi] = useState(["createProperty_metadata_bulk"]);
  const [method, setMethod] = useState("");
  const [addMethod, setAddMethod] = useState("");
 

  // const navigateTo = () => {
  //   navigate("/host/createlisting/reviewListing");
  // };
  const addOnPropId = storeSel.apiDataSlice?.addOnPropId;

console.log(addOnPropId,'jjl')

  const reduxstoreGuest = storeSel?.addCartSlice;


  //
  const Currenturl = window.location.href;
  console.log(Currenturl, "tyt");
  const CurrentPage = new URL(Currenturl);
  const path = CurrentPage.pathname;
  console.log(path, "qqq");

  //
  // console.log(propertyId);

  // const additionalGuestFunc = (count) => {
  //   console.log(count, "tack");
    
  //   dispatch(updateAdditionalGuests(count));
  // };

  const baseGuestFunc = (count) => {
    console.log(count, "tack");
    dispatch(updateBaseGuest(count));
  };
  

  console.log(api, "tulips");
  // }, []);
  useEffect(() => {
    const PropIddata = storeSel.apiDataSlice?.propId;

    const preferenceIddata = storeSel.apiDataSlice?.preferenceId;
    const addOnPropId = storeSel.apiDataSlice?.addOnPropId; 

    let updatedApi = ["updateProperty/" + PropIddata, ...api];
    let updatedMethod = method;
    let updatedAddMethod = addMethod;

    if (preferenceIddata) {
      updatedApi.push("updatePropertyPref/" + preferenceIddata);
      updatedMethod = "patch";
    } else {
      updatedApi.push("add_preference");
      updatedMethod = "post";
    }

    // if (addOnPropId) {
    //   updatedApi.push("update_property_addons/" + addOnPropId);

    //   updatedAddMethod = "patch";
    // } else {
    //   updatedApi.push("add_property_addons");
    //   updatedAddMethod = "post";
    // }

    setApi(updatedApi);
    setMethod(updatedMethod);
    setAddMethod(updatedAddMethod);
  }, []);
  console.log()

  console.log(api, "666");

  return (
    <div className="listing-pricing-box">
      <div className="listing-pricing-content">
        <h2 className="listing-price-heading-text">Now, set your price</h2>
        <p className="listing-price-para-text">You can change it anytime.</p>
        

        <form
          className="confirm-address-form"
          onSubmit={handleSubmit(onSubmit)}
        >

         <div className="listing-price-flex-box">
          <div className="listing-pricing-right-box">
          
            <CardWrapper wrapperClass="listing-pricing-right-card">             
              <p className="additional-charges-text">Price Per Night</p>
            
              <div style={{flexDirection:'column'}} className="lisiting-pricing-flexbox flex  w-100">
              <div className="additional-stuff-box addition-flex-stuff-box">
             <div className="cart-with-title">
             <p className="additional-charges-para"> Base price </p>
              <div className="cart-number">
                  <span>₹</span>
                  
             <Controller
                control={control}
                name="perNightCost"
                rules={{
                  required: "Filled the data is required",
                 
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="number"
                    inputClass="input"
                    name="perNightCost"
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              {errors.perNightCost && (
                <span>{errors.perNightCost.message}</span>
              )}
                </div>
                </div>


            <div className="cart-with-title">
        
            <p className="additional-charges-para"> Weekend price </p>
             <div className="cart-number">
                  <span>₹</span>
                  
             <Controller
                control={control}
                name="weekendPrice"
                rules={{
                  required: "Filled the data is required",
                 
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="number"
                    inputClass="input"
                    name="weekendPrice"
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              {errors.weekendPrice && (
                <span>{errors.weekenPrice.message}</span>
              )}
                </div>
                </div>
               </div>

              <div className="guest-add-box">
                <span style={{ fontSize: "1.4rem" }}>for</span>
               

              <AddToCart
             cartName="cartName"
             cartBtn="cartBtn"
             cartCount="cartCount"
             cartParent="cartParent"
              initialCount={reduxstoreGuest?.guests}
              maxCount={reduxstoreGuest !== null ? reduxstoreGuest?.guests : ""}
              onCountChange={baseGuestFunc}
              />


                <span style={{ fontSize: "1.4rem" }}>guests</span>
              </div>              
              </div>
              <p className="additional-stuff-span-text">The number of guests cannot be more than <Link to="/host/createlisting/counterBeds">maximum guests</Link> you mentioned before.</p>

            </CardWrapper>
          </div>
          <div className="listing-pricing-right-box">
            <CardWrapper wrapperClass="listing-pricing-right-card">
              <p className="additional-charges-text">Additional Charges</p>
              <p className="additional-charges-para">
                Add additional charges for your property
              </p>
              <div className="additional-stuff-box">
                <p className="first-text">Additional Guests</p>
               
                <div className="cart-number">
                  <span>₹</span>
                  
             <Controller
                control={control}
                name="additionalGuests"
                rules={{
                  required: "Filled the data is required",
                 
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="number"
                    inputClass="input"
                    name="additionalGuests"
                    onKeyUp={onChange}
                    value={value}
                  />
                  
                )}
              />
              {errors.guestdata && (
                <span>{errors.guestdata.message}</span>
              )}
                </div>


                <span className="additional-stuff-span-text">
                  Charge per person for additional guests to ensure fair
                  pricing.
                </span>
              </div>
{/* 
              <div className="additional-stuff-box">
                <p className="first-text">Additional Bedss</p>
                <div className="cart-number">
                  <span>₹</span>
                  
      
             <Controller
                control={control}
                name="additionalBeds"
                rules={{
                  required: "Filled the data is required",
                 
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="number"
                    inputClass="input"
                    name="additionalBeds"
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              {errors.additionalBeds && (
                <span>{errors.additionalBeds.message}</span>
              )}
                </div>
                <span className="additional-stuff-span-text">
                  Charge per bed for extra sleeping arrangements.
                </span>
              </div> */}

            </CardWrapper>
          </div>
        </div>
        <div className="bottom-btns-fixed-box">            
            <Save_ExitComp
              method={method}
              apis={api}
              addMethod={addMethod}
              path={path}
              firstFunction={whatHappensOnSaveAndExit}

            />
            <button type="submit" className="common-next-btn">
              Next
            </button>
          </div>
          </form>
      </div>
      
    </div>
  );
};

export default ListingPricing;

