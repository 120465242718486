import React from "react";
// import ReservationTabComp from "../../../Module/Host/reservationTabComp";
import ReserveContentComp from "../../../Module/Host/reservationContent";
import HostNav from "../../../Components/common/hostNav";
import LeftTabsMenu from "../../../Module/Host/leftTabs";

const HostReservation = (props) => {
  return <ReserveContentComp hostName="Host Name" />;
};

export default HostReservation;
