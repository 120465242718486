import React from "react";
import SkeletonLoader from "../common/SkeletonLoader";

const ReviewWithImages = (props) => {
  // Take only the first five images from props.imageSources
  const limitedImageSources = props.imageSources.slice(0, 5);

  return (
    <div className="review-with-images">
      {props.starNum !== undefined && props.starNum !== null ? (
        <p className="rating-with-star">
          <span>{props.starNum}</span>
          <span className="rating-start-img">
            <img src="./assets/img/colorStarIcon.svg" alt="" />
          </span>
        </p>
      ) : (
        <SkeletonLoader width={40} height={20} count={1} />
      )}

      <div className="review-num-box">
        {props.reviewNum !== undefined && props.reviewNum !== null ? (
          <span className="review-num-text">
            {props.reviewNum} {props.reviewNum > 1 ? <span>Reviews</span> : <span>Review</span>}
          </span>
        ) : (
          <SkeletonLoader width={40} height={20} count={1} />
        )}

        {limitedImageSources !== undefined && limitedImageSources !== null ? (
          <div className="review-img-box">
            {limitedImageSources.map((src, index) => (
              <img key={index} src={src} alt="" />
            ))}
          </div>
        ) : (
          <SkeletonLoader circle={true} width={40} height={40} count={1} />
        )}
      </div>
    </div>
  );
};

export default ReviewWithImages;
