import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import EditSaveBtn from "../EditSaveBtn";
import { apiMainUser, checkErrors } from "../../../../utils/constant";
import APIHit from "../../../../utils/apiHit";
import InputComp from "../../../../Components/common/textInput";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../../utils/redux/SnackBarSlicer";

const EditExtraGuest = (props) => {
  const { control, handleSubmit, register,formState: { errors }, } = useForm();
  const [postData, setPostData] = useState(null);
  const [showerror, setError] = useState(null);


  const dispatch = useDispatch()


  let url = apiMainUser+ "updatePropertyPref/" + props.propId;
  console.log(props.propId, "tulips");

  const onSubmit = (data) => {
    const postDatavalue = {
      extra_guests_price:  data.additionalGuestFee,
    };
    setPostData(postDatavalue);
  };

  const handleSuccess = (res) => {
    if (res.status === "success") {
      props.closeModal()
      props.fetchData()
      dispatch(openSnackbar({ message: 'updated Successfully', severity: 'success' }));

    }
  };

  const handleFailure = (res) => {
    console.log(res);
    if (res && res.status === 404) {
      dispatch(openSnackbar({ message: 'Something went Wrong', severity: 'error' }));

      setError("");
    } else {
      dispatch(openSnackbar({ message: 'Something went Wrong', severity: 'error' }));

      setError("");
    }
  };

  const preCondition = () => {
    let ercheck = checkErrors(errors);
    console.log(ercheck, "djjd");
    return ercheck;
  };
  return (
    <form  onSubmit={handleSubmit(onSubmit)} className="form-pricing " style={{marginTop:'2rem'}}>
      <div className="form-row">
       

        <Controller
          control={control}
          defaultValue={
            props.allData !== undefined ? props.allData?.extra_guests_price : ""
          }
          name="additionalGuestFee"
          rules={{ required: "Please select a time" }}
          
          render={({ field: { onChange, value } }) => (
            <InputComp
              inputType="number"
              inputClass="edit-pricing-input"
              name="additionalGuestFee"
              // inpPlace="Enter your title here..."
              onKeyUp={(event) => {
                onChange(event.target.value);
                // console.log(event.target.value, "topi");
                // onSubmit(event.target.value, true);
              }}
              value={value}
              defaultValue={
                props.allData !== undefined ? props.allData?.extra_guests_price : ""
              }
            />
          )}
        />
          {errors.additionalGuestFee && <span>{errors.additionalGuestFee.message}</span>}


        <p className="edit-pricing-input-para">
          Charge this amount per extra guest, per night.
        </p>
      </div>

      
           <APIHit
              handleSubmit={handleSubmit(onSubmit)}
              url={url}
              apidata={postData}
              successResponseCode={200}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              precondition={preCondition}
              method={"patch"}
              auth={true}
            >
            <div className="clear_apply-btn-box paddingCustomYSide">
                <p onClick={() => props.closeModal(false)} className="clear-btn">
                  Cancel
                </p>
                <button className="save-edit-btn">Save</button>
              </div>
            </APIHit>


    </form>
  );
};

export default EditExtraGuest;
