

import React, { useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

// import { useState } from 'react';
import axios from 'axios';

import { useEffect } from 'react';
import { Rating, Typography } from '@mui/material';
import { apiMainUser, apiPublicUser } from '../../../../utils/constant';
import TextareaComp from '../../../../Components/common/textArea';
import PropertyReviewPop from '../../HostReviewPop';

const RevewHostPopup = (props) => {
  const [rating, setRating] = useState(0);

  const [ratingCategories, setRatingCategories] = useState([]);
  // const [isForHost, setIsForHost] = useState(false);
  const [isForproperty, setIsForProperty] = useState(false);

  const fetchDataReview = async () => {
    // API call
    try {
      const response = await axios
        .get(apiPublicUser + "read_rating_category?category_for=host", {

          // headers: {
          //   Authorization: "Bearer " + localStorage.getItem("token"),
          // },
        })
        .then((response) => {
          
          setRatingCategories(response.data.data)
         
       
        });
    } catch (error) {
      console.error(error);
    }
  };

  
  function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  

  console.log(ratingCategories,'ooh')

    useEffect(() => {
  
    fetchDataReview();
  
  }, []);

//   useEffect(() => {
//     fetchDataReview();
//   }, [isForHost]); 


  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  console.log(props.bookingOrderId,'checkBook')
  
  console.log(props.propId,'checkonce1')
  const onSubmit = (data) => {
   

    const reviewData = {
      review_for:'host',
      // for_id:  props.hostId , 
      booking_id:props.bookingOrderId ,
      // for_id: 534, 
      review: data.feedback,
      ratings: ratingCategories
        .filter(category => category.rating > 0) // Filter out categories with no rating
        .map(category => ({
          rating: category.rating,
          rating_category: category.uid,
        })),
    };
    
    console.log(reviewData,'checkReviewData')
    const headers = {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json', 
    };

   
    axios
      .post(apiMainUser + 'create_review', reviewData, { headers: headers })
    //   .post(apiMainUser + (isForHost ? 'create_review' : 'create_review'), reviewData, { headers: headers })
      .then((response) => {
        console.log('API response:', response.data);
        console.log(response.status,'uuto')
      
        
        if(response.status === 200){ 
         
          setIsForProperty(true)
          // props.fetchHostReviewData()
          // props.fetchHostReviewDataWithBookingId() 
        // props.closeModal()

     
          
        }
      })
      .catch((error) => {
        console.error('Error calling API:', error);
      
      });
  };



  const handleRatingChange = (newValue, categoryIndex) => {
    setRatingCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      updatedCategories[categoryIndex].rating = newValue;
      return updatedCategories;
    });
  }

  return (
    <>
    {!isForproperty ?
    <div className='host-review-content-div'>
      <h2>Rate the Host</h2>
      
      
      <div className='host-rating-parent'>
      {ratingCategories.map((category, categoryIndex) => {
        console.log(category.rating,'categoryList'); 
        return (
          <div key={categoryIndex} className='rating-category'>
            <p className='rating-category-label'>{capitalizeFirstWord(category.rating_category)}</p>
            <div className='rating-icons-div'>
              {/* <Typography component="legend">Controlled</Typography> */}
              <Rating
                name={`rating-${categoryIndex}`}
                value={category.rating}
                onChange={(event, newValue) => handleRatingChange(newValue, categoryIndex)}
                size='large'
              />
            </div>
          </div>
        );
      })}

      </div>
      <form className='confirm-address-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='single-input-box ' style={{width:'100%'}}>
          <label className='review-feedback-label' htmlFor='feedback'>Additional Feedback</label>
          <Controller
            control={control}
            name='feedback'
            rules={{
              required: 'Feedback is required',
            }}
            render={({ field: { onChange, value } }) => (
              <TextareaComp
                name='feedback'
                textareaClass='review-textarea'
                rows='7'
                cols='40'
                onChange={onChange}
                value={value}
                placeholder='Enter your additional feedback here...'
              />
            )}
          />
          {errors.feedback && <span>{errors.feedback.message}</span>}
        </div>

        <button className='save-edit-btn' type='submit'>
          Next
        </button>
      </form>
    </div>

    :   
      <PropertyReviewPop
      fetchHostReviewDataWithBookingId = {props.fetchHostReviewDataWithBookingId }
      fetchHostReviewData={props.fetchHostReviewData}
      propId={props.propId}  
      closeModal={props.closeModal}
      bookingOrderId={props.bookingOrderId}

       />
     }
     </>
  );
};

export default  RevewHostPopup;
