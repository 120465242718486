
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { apiMainUser } from '../../../utils/constant';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../utils/redux/SnackBarSlicer';

const HostDocsImage = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);

  console.log(props.docsData,'checkPopData')

  const getDetailsUid = props.getDetails?.uid

  const dispatch = useDispatch()
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.pdf, image/*', 
  });

  const handleSave = async () => {
    console.log('Save button clicked!');
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('doc_name', props.docsData);

  
    try {
      const response = await axios({
        
        method : getDetailsUid !== null ? "patch" : "post",

        
        url:
            getDetailsUid !== null
              ? apiMainUser + "update_user_docs/" + getDetailsUid
              : apiMainUser + "create_user_docs",
          
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });

      if (response.data.status === 'success') {
        props.fetchData()
        props.closeModal();

        dispatch(openSnackbar({ message: 'Added Successfully', severity: 'success' }));

      }
    } catch (error) {
      console.error(error);
      dispatch(openSnackbar({ 
        message: error.response.data.message 
          ? error.response.data.message + 'file size should be less than 1mb' 
          : '', 
        severity: 'error' 
      }));
      
    }
  };

  return (
    <div  className='drag-drop-box drag-drop-box-docs ' >
      <div  {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here...</p>
        ) : (
          <p style={{ fontSize: '14px' }}>Click or drag and drop a file here (max 1mb)</p>
        )}
      </div>

      {preview && (
        <div style={{ width: '150px', height: '150px', marginBottom: '2rem', marginTop: '2rem' }}>
          {selectedFile.type.includes('image') ? (
            <img style={{ width: '100%', height: '100%' }} src={preview} alt="Selected" />
          ) : (
            <iframe title="Selected PDF" style={{ width: '100%', height: '100%' }} src={preview}></iframe>
          )}
        </div>
      )}

      {selectedFile && <button className="save-edit-btn" onClick={handleSave}>Submit</button>}
    </div>
  );
};

export default HostDocsImage;
