import React from 'react';
import { useForm } from 'react-hook-form';
import HeadingComp from '../HeadingComp';
import { useEffect } from 'react';
import axios from 'axios';
import { apiMainUser } from '../../../../utils/constant';
import { useState } from 'react';
// css of this page is in EditCheckinTime.scss---------------

const EditCancellation = (props) => {
  const { register, handleSubmit } = useForm();

  console.log(props.allData?.cancellation_category_detail?.[0]?.category_id,'checkOptions')

  
  

  const [cancellationData,setCancellationData]= useState()


  const fetchCancellationData = async () => {
    try {
      const response = await axios.get(apiMainUser + "read_cancellation_category", {
       
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.data.status === 'success') {
         console.log(response.data,'chill')
         setCancellationData(response.data.data)
      } else {
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchCancellationData();
    
  }, []);



  const onSubmit = async (formData) => {

    console.log(formData,'logit')
    try {
        const url = `${apiMainUser}updatePropertyPref/${props.preferencePropId}`;
      
    
      
        const response = await axios.patch(
          url,
          formData, // Pass the form data object (empty or with other data) if required
          {
            headers: {
              
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
      
        if (response.status === 200) {
            props.closeModal()
            props.fetchData()
          // Handle success
          console.log('POST request successful');
        } else {
          console.error('POST request failed');
        }
      } catch (error) {
        console.error('Error occurred:', error);
      }
      
  };




  return (
    <div className='cancellation-policy-div' style={{marginTop:'2rem'}}>
  
   
    <form onSubmit={handleSubmit(onSubmit)} >
    <div className='all-cancelation-category-inputs'>
    {cancellationData?.map((option) => {
     console.log(option,'checkOptions')
    return(
      <div className='cancelation-category-radio-input-div' key={option.uid}>
        <input
        type="radio"
        id={option.uid}
        value={option.uid}
        {...register('cancellation_category')} 
        defaultChecked={props.allData?.cancellation_category_detail?.[0]?.category_id == option.uid}

        />
        <label htmlFor={option.uid}>
        <div className='cancellation-category-text'>{option?.name}</div>
        <div className='cancellation-category-message-text'>{option.description}</div>

        {/* Rendering slab data inside the label */}
        {option.slabs.map((slab) => (
            <div key={slab.uid} className='cancellation-slab-info'>
            {slab.refund_percent}% refund {slab.remaining_days} days before the day of check-in
            </div>
        ))}
        </label>
     </div>
    )
})}


         </div>
     <div className="clear_apply-btn-box paddingCustomYSide">
      
      <p onClick={() => props.closeModal(false)} className="clear-btn">
         Cancel
       </p>
      <button className="save-edit-btn" type="apply-btn">
         Save
       </button>   
      </div>

    </form>
    </div>
  );
};

export default EditCancellation;
