import React, { useEffect, useState } from 'react'
import APIHit from '../../../utils/apiHit';
import { apiMainUser, apiPublicUser } from '../../../utils/constant';
import PastDetailsPage from '../../user/PropertyDetails/PastDetailsPage';
import HostOngoingDetail from './HostOngoingDetail';
import HostUpcomingDetail from './HostUpcomingDetail';
import HostPastBookingDetail from './HostPastBookingDetail';
import HostCancelledDetail from './HostCancelledDetail';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const HostLayerDetail = (props) => {

    const [apicall, setApiCall] = useState(false);
    const [apicallHost, setApiCallHost] = useState(false);
  const [selectedTripType, setSelectedTripType] = useState();

  
    const [successdata, setSuccessData] = useState()
    const [successdataUser, setSuccessDataUser] = useState()
  
    const [hostuser, setHostUser] = useState()
    const [slug, setSlug] = useState()
    const [successdataprop, setSuccessDataProp] = useState()
  
    const [openModal, setOpenModal] = useState(false);
    const [reviewModal, setReviewModal] = useState(false);  
    const [guestReviewModal, setGuestReviewModal] = useState(false);  

   
  
  const data = successdata?.transaction_details[0]?.price_detail;
  console.log(data, "tried","sdss")
    let priceDetailMapvalues = {};
     data?.map((x)=>{
      console.log(x,'tried',"wow")
      
      if(x.total){
        console.log("tried",x.total)
        priceDetailMapvalues = x?.total;
      }
     
    })
  
  
    const toggleVisibleDocks = () => {
      setGuestReviewModal(false)
      setReviewModal(false)
    }
  
  
  
  const consoleDateStr = successdata?.transaction_details[0].checkout_date;
  const consoleDate = new Date(consoleDateStr);
  
  const[reviewWithBookId,setReviewWithBookId] = useState([])
  const [review,setReview]= useState([])
  const [ratingAverage,setRatingAverage]= useState([])
  const [ratingAverageWithBookId,setRatingAverageWithBookId]= useState()
  const [assetsurl,setAssetsUrl]= useState()
  
  
  
  
  const propId = successdata?.property_id;
  const propIdForUser = successdataUser?.property_id
  
  
  const userId = successdata?.user_id
  
  console.log(userId,'checkIdPlease')
  
  const bookingOrderId = successdata?.booking_order_id;
    const bookingOrderIdForUser = successdataUser?.booking_order_id;
    const hostId =  successdata?.host_id
  const user_id = successdata?.user_id
  





    const fetchGuestReviewDataWithBookingId = async () => {
      // API call
      try {
        const response = await axios.get(apiPublicUser + `reviews-ratings/user/${user_id}`, {
          params: {
            booking_id: successdataUser?.booking_order_id,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
    
        if (response.data.status === 'success') {
          console.log(response.data.data.total_rating, 'checkReviews');
          setReviewWithBookId(response.data.data.user?.reviews);
          setRatingAverageWithBookId(response.data.data.user?.total_rating);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
  
    const fetchGuestReviewData = async () => {
      // API call
      try {
        const response = await axios.get(apiPublicUser + `reviews-ratings/user/${user_id}`, {
          // params: {
          //   booking_id: successdataUser?.booking_order_id,
          // },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
    
        if (response.data.status === 'success') {
          console.log(response.data.data.total_rating, 'checkReviews');
          setReview(response.data.data.user?.reviews);
          setRatingAverage(response.data.data.user?.total_rating);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    console.log(review,'checkstatus')
  
  
  
  
  
      useEffect(()=>{
        if(successdata){
          console.log('calledUser')
          fetchGuestReviewData()
          fetchGuestReviewDataWithBookingId()
        }
      },[successdata])
   
      // user_id
  
  
  
    const handleSuccess = (response) => {
      // Handle the successful response here
      console.log("Successful response:", response);
      setAssetsUrl(response.assets_url);
      setSuccessData(response.data[0]);
      setSuccessDataUser(response.data[0]);
      setApiCall(true)
  
    };
    console.log(successdata,'iifg')
    
  
    // console.log(successdata?.transaction_details[0].price_detail,'luks')
    const handleFailure = (data) => {
      // Handle the successful response here
      console.log("Failure response:", data);
    };
    const runPrecondition = () => {
      // Perform your precondition check here
      // Return true if the precondition is met, or false otherwise
      if (successdata !== []) {
        return false;
      }
      return true;
    };  
  
  
    
    
  
      useEffect(()=>{
        if(successdataUser){
          console.log('calledUser')
          // fetchHostReviewData()
        }
      },[successdataUser])
   
  
    const handleSuccessProp = (response) => {
      // Handle the successful response here
      console.log("Successful response:", response);
      setSuccessDataProp(response.data[0]);
    };
  
    // console.log(successdata?.host_details,'lucifer')
    const handleFailureProp = (data) => {
      // Handle the successful response here
      console.log("Failure response:", data);
    };
    const runPreconditionProp = () => {
      // Perform your precondition check here
      // Return true if the precondition is met, or false otherwise
      if (successdata !== []) {
        return false;
      }
      return true;
    };  
    useEffect(() => {
      let pathname = window.location.pathname
      if(pathname.includes("reservations")){
        setHostUser("host")
       
  
        var slugme = window.location.pathname.replace("/host/reservations/","")  
        setApiCallHost(true);    
      }else{
        setHostUser("null")
       
      }
      setSlug(slugme)
      // setApiCall(true);
      // setApiCallHost(true);
  
    }, []);

    console.log(successdata,'checkAllUsers')
  
 
  //   const checkOutDate= 
    const checkoutDate = new Date(successdataUser?.checkout_date);
    const currentDate = new Date();
    const isCheckoutDateValid = currentDate > checkoutDate;
  
   
  
  
       ///date of host reply to user----
          const apiDateString = reviewWithBookId[0]?.createdon;
  
              
               // Parse the date using Moment.js
               const apiDate = moment(apiDateString).local();;
               
               const reviewFormattedDate = apiDate.format("DD MMM");
  ///////////////////////////////////////////////////////////////////////////////////////
   
          const shouldRenderButton = reviewWithBookId?.some((review) => review.from_id === hostId);
         const ratingAverageData = ratingAverage && ratingAverage.Overall_Average !== 0 ? ratingAverage.Overall_Average?.toFixed(2) : '0';
   
  useEffect(()=>{
    setSelectedTripType(successdata?.tabsname)
   },[successdata])
 
   const renderDetailsPage = () => {
 

    switch (selectedTripType) {
     case 'ongoing':
      return <HostOngoingDetail
      successdataUser={successdataUser}
      successdataprop={successdataprop}
      propIdForUser={propIdForUser}
      fetchGuestReviewData={fetchGuestReviewData}
      fetchGuestReviewDataWithBookingId={fetchGuestReviewDataWithBookingId}
      successdata={successdata}
      assetsurl={assetsurl}
      bookingOrderIdForUser={bookingOrderIdForUser}
      ratingAverage={ratingAverage}
      review={review}
      
      />;


    case 'upcoming':
      return <HostUpcomingDetail
            apicall={apicall}
            setApiCall={setApiCall}
           
            fetchData={props.fetchData}
              listingData={props.listingData}
              successdata={successdata}
              assetsurl={assetsurl}
              bookingOrderId={bookingOrderId}
              successdataUser={successdataUser}
              successdataprop={successdataprop}
              ratingAverage={ratingAverage}
              review={review}
            //   totalObject={totalObjectState}
            
      
      />;
      case 'arriving':
        return <HostUpcomingDetail
              apicall={apicall}
              setApiCall={setApiCall}
             
              fetchData={props.fetchData}
                listingData={props.listingData}
                successdata={successdata}
                assetsurl={assetsurl}
                bookingOrderId={bookingOrderId}
                successdataUser={successdataUser}
                successdataprop={successdataprop}
                ratingAverage={ratingAverage}
                review={review}
              
        
        />;


      case 'passed':
      return <HostPastBookingDetail
      successdataUser={successdataUser}
      successdataprop={successdataprop}
      successdata={successdata}
      assetsurl={assetsurl}
      ratingAverage={ratingAverage}
      review={review}
      reviewWithBookId={reviewWithBookId}
      ratingAverageWithBookId={ratingAverageWithBookId}
      isCheckoutDateValid={isCheckoutDateValid}
      setReviewModal={setReviewModal}
      fetchGuestReviewData={fetchGuestReviewData}
      fetchGuestReviewDataWithBookingId={fetchGuestReviewDataWithBookingId}
      bookingOrderIdForUser={bookingOrderIdForUser}
      userId={userId}
    />;
      case 'refunded':
      return <HostCancelledDetail
      successdataUser={successdataUser}
      successdataprop={successdataprop}
      successdata={successdata}
      assetsurl={assetsurl}
      checkInTime={props.checkInTime}
      checkOutTime={props.checkOutTime}  
      ratingAverage={ratingAverage}
      review={review}
      
      
      />;

      default:
       return null;
  }
  };

  return (
    <>
    
    {apicallHost && (            
      <APIHit
        url={apiMainUser + "read_booking_for_host_property?booking_order_id="+slug}
        method="GET"
        onSuccess={handleSuccess}
        onFailure={handleFailure}
        precondition={runPrecondition}
        successResponseCode={200}
        // apidata={amenitiesData}
        directCall={true}
        auth={true}
      ></APIHit>
    )}

     
    {apicall  && (
      <APIHit
        url={apiPublicUser + "search_property/?propertyid="+propId }
        method="GET"
        onSuccess={handleSuccessProp}
        onFailure={handleFailureProp}
        precondition={runPreconditionProp}
        successResponseCode={200}
        // apidata={amenitiesData}
        directCall={true}        
      ></APIHit>      
    )}

    {renderDetailsPage()}

    </>



  )
}

export default HostLayerDetail
