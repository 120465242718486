
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiMainUser, getJustBlockedDatesFunc } from '../../../utils/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import MobCalnedarPropertyPop from './MobCalnedarPropertyPop';
import WrapperPopup from '../../../Components/common/wrapperPopup';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CalendarPriceInpPop from '../../../Module/Host/CalendarPriceInpPop';

const MobCalendarPage = (props) => {

  // const [selectedPoperty, setSelectedProperty] = useState(props.filteredPublishData[0]?.property_name);
  const [selectedPoperty, setSelectedProperty] = useState();
  const [selectedPopertyId, setSelectedPropertyId] = useState();
  const [selectedPopertyImage, setSelectedPropertyImage] = useState();



  const [propertyPop, setPropertyPop] = useState(false);
  const [publishData, setPublishData] = useState(false);


  


  const fetchDataAll = async () => {
    // API call
    try {
      const response = await axios
        .get(apiMainUser + "getListing?limit=999", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
     
       const filteredArray = response.data.data.filter(item => item.publish == 1)
          setPublishData(filteredArray)
          setSelectedPropertyId(response.data.data[0])
  
          console.log(response.data, "qqq");
        });
    } catch (error) {
      console.error(error);
    }
  };


  const [propertyIdParent, setPropertyIdPrent] = useState();  


  const [waitToCall, setWaitToCall] = useState(false)


  const [showCalendarPriceInpPop, setShowCalendarPriceInpPop] = useState(false);

  const [selectedPropertySlots, setSelectedPropertySlots] = useState([]);

  const [radionText,setRadioText] = useState('Available')
  const [priceState, setPriceState] = useState(0)

  const [blockMessage,setBlockMesage] = useState('')
  const [pastDate,setPastDate]=useState(false)

  
  const [allBlockDateState, setAllBlockedDates] = useState();
  const [blockCheckinDateState, setCheckinBlockedDates] = useState();
  const [blockCheckoutDateState, setCheckoutBlockedDates] = useState();
  
const [minDateState, setMinDate] = useState()
const [maxDateState, setMaxDate] = useState()
const [startDate,setStartDate] = useState()



 
  

  const fetchCalendarSlots = async () => {
    try {
      console.log('It\'s called here', propertyIdParent);
  
      // Fetch calendar slots data
      const response = await axios.get(apiMainUser + 'get_calender_slots_host', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        params: {
          property_id: propertyIdParent,
        },
      });
  
      console.log(response, 'checkAllDataResponse');
  
      if (response.data.status === 'success') {
        var maxDate, minDate;
        var blockedDates = [];
        var blockedDatesCheckin = [];
        var blockedDatesCheckout = [];
  
        response.data.data[0]?.slots.forEach((days, i) => {
          let datebe = days.date.split("T");
  
          if (i === 0) {
            minDate = new Date(datebe[0]);
          }
  
          if (days.block === 1 || days.booked === 1) {
            blockedDates.push(new Date(datebe[0]));
          }
  
          if (days.block === 1 || (days.booked === 1 && days.ischeckin === 1)) {
            blockedDatesCheckin.push(new Date(datebe[0]));
          }
  
          if (((days.block === 1 || days.booked === 1) && days.ischeckout === 1) || (days.block === 1 && response.data.data[i - 1]?.block === 1)) {
            blockedDatesCheckout.push(new Date(datebe[0]));
          }
  
          if (i === response.data.data[0]?.slots.length - 1) {
            maxDate = new Date(datebe[0]);
          }
        });
  
        setMinDate(minDate);
        setMaxDate(maxDate);
        setAllBlockedDates(blockedDates);
        setCheckinBlockedDates(blockedDatesCheckin);
        setCheckoutBlockedDates(blockedDatesCheckout);
  
        setSelectedPropertySlots(response.data.data[0]?.slots);
      } else {
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  
  useEffect(()=>{
    fetchDataAll()
  },[])

console.log(selectedPopertyId,'CheckPropertyNow')
  console.log(propertyIdParent,'checkPropId')
  useEffect(() => {
    const propertyName = selectedPopertyId?.property_name
    const propertyImage = selectedPopertyId?.base_url_image + selectedPopertyId?.images[0]
    const propertyIdToSet = selectedPopertyId?.propertyid;
    setPropertyIdPrent(propertyIdToSet);
    setSelectedProperty(propertyName)
    setSelectedPropertyImage(propertyImage)

  }, [selectedPopertyId]);
  

  const [clickedBookedDate, setClickedBookedDate] = useState(null);


  useEffect(() => {
    if(propertyIdParent !== ''  && propertyIdParent !== undefined){
        fetchCalendarSlots();
        console.log('Its called here', propertyIdParent);  
    }
   
  }, [propertyIdParent]);

  const toggleVisibleDocks = () => {
    setPropertyPop(false);
    setShowCalendarPriceInpPop(false)
  };


  const [value, onChange] = useState(new Date());
  

  const tileContent = ({ date }) => {
    const matchingSlots = selectedPropertySlots.find(
      (slot) => new Date(slot.date).toLocaleDateString() === date.toLocaleDateString()
    );
  
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    // Check if matchingSlots is defined
    if (matchingSlots?.booked === 1) {
  
      // Render only the price
      return (
        <div className="booked-label-name">
        {matchingSlots?.booking_details[0]?.fname}
        </div>
      );
    }else{
      const hasBlockMessage = matchingSlots?.block_message != null && matchingSlots?.block_message != '' ;

      return (
        <>
        
        <div className={`price-label ${date < currentDate ? 'price-label-past' : ''}`}>
          ₹{matchingSlots?.price}
        </div>
        {hasBlockMessage && (
          <p  className="dot-above-date"></p>
        )}
        </>
      );
    }
  
    return null;
  };


  const handleBookedDateClick =  (date) => {
    const matchingSlot = selectedPropertySlots.find(
      (slot) => new Date(slot.date).toLocaleDateString() === date.toLocaleDateString()
    );
    
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
  
    if (matchingSlot) {
      if (matchingSlot.block === 1  && date > currentDate)  {
        setShowCalendarPriceInpPop(true);
        setRadioText('Blocked');
       
      } else if (matchingSlot.booked === 1) {
        setClickedBookedDate(date);
        const bookingOrderId = matchingSlot.booking_details[0]?.booking_order_id;
        window.location.href = `host/reservations/${bookingOrderId}`;
      }

      if (!matchingSlot || (matchingSlot.block != 1 && matchingSlot.booked != 1 && date > currentDate)) {
        setShowCalendarPriceInpPop(true);
        setRadioText('Available');
        setPastDate(false)
      }

      
      if ( date < currentDate && matchingSlot.block_message != null && matchingSlot.block_message != '') {
        setPastDate(true)
        setShowCalendarPriceInpPop(true);
      }
    

      const priceForDate = matchingSlot?.price || 0;
      setPriceState(priceForDate);
      setStartDate(matchingSlot.date)
      setBlockMesage(matchingSlot.block_message
        )

    }

  };
  


  const tileClassName = ({ date }) => {
    const matchingSlots = selectedPropertySlots.find(
      (slot) => new Date(slot.date).toLocaleDateString() === date.toLocaleDateString()
    );

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

     if(matchingSlots && matchingSlots.booked == 1 &&  date < currentDate){
      return 'past-booked-date'
    }

    if ( date < currentDate) {
      console.log(date < new Date(), 'checkDatesOnce');
      return 'past-date ';
    }
  
    if (matchingSlots) {
      if (matchingSlots.block == 1) {
        return 'blocked-date';
      } else if (matchingSlots.booked == 1) {
        // return 'booked-date';
        return `booked-date circle-btn ${date.getDate() === clickedBookedDate?.getDate() ? 'react-calendar__tile--booked-date' : ''}`;

      }
    }
  
    return 'circle-btn';
  };

 



  return (
    <div className='mob-calendar-page'>
      <div onClick={() => setPropertyPop(true)} className='mob-calendar-drop-down flex justify-space-between font-medium align-items-center w-80' >
        <div className='mob-calendar-drop-down-image-box flex align-items-center'>
        <img className='w-10' src={selectedPopertyImage} alt="" />
         {selectedPoperty} 
         </div>
        <span>
          <FontAwesomeIcon icon={faAngleDown}/>
        </span>
      </div>

    
      <div className="property-slots-container mt-20 flex justify-content-center">
      <Calendar onChange={onChange} value={value}
       tileContent={tileContent}
       tileClassName={tileClassName} 
      //  tileDisabled={tileDisabled}
       onClickDay={(value, event) => handleBookedDateClick(value)}

       
       />
    </div>


      <div className="property-type-popup-div">
      {propertyPop && (
          <WrapperPopup
          fluid={true}
          DockFilter={true}
          defaultSize={"0.70"}
          dimStyle={false}
          isVisible={true} 

          noCloseButton ={true}
          setVisibleDocks={toggleVisibleDocks}
            closeModal={setPropertyPop}
            comp={
            <MobCalnedarPropertyPop
            filteredPublishData={publishData}
             closeModal={setPropertyPop}
             setSelectedProperty={setSelectedProperty}
             setPropertyIdPrent={setPropertyIdPrent}
            selectedPoperty={selectedPoperty}
              />
            }
          />
        )}
      </div> 

      <div className="booking-date-edit-popup">
      {showCalendarPriceInpPop && (
   <WrapperPopup
    fluid={true}
    AppylyBtnBox={true}
    defaultSize={"0.45"}
    dimStyle={false}
    isVisible={true} 
    setVisibleDocks={toggleVisibleDocks} 
    closeModal={() => setShowCalendarPriceInpPop(false)}
    headingText={pastDate ? 'Private Note' : ''}
    comp={
      <CalendarPriceInpPop  
        closeModal={() => setShowCalendarPriceInpPop(false)}
        propertyId={propertyIdParent}
        blockMessage={blockMessage}
        blockDate={allBlockDateState} 
        blockCheckinDateState={blockCheckinDateState}
        blockCheckoutDateState={blockCheckoutDateState}
        startDate={startDate}
        price={priceState}
        minDate={minDateState}
        maxDate={maxDateState}
        radionText={radionText}
        fetchCalendarSlots={fetchCalendarSlots}
        pastDate={pastDate}
      
      />
    }
  />
)}
</div>



    </div>

  );
}

export default MobCalendarPage;




// <div className='mob-calnedar-property-pop-box' key={index}>
          //   <div className='mob-property-dropdown-imgbox'>
          //     <img src={property.base_url_image + property?.images[0]} alt={property.property_name} />
          //     <h3>{property.property_name}</h3>
          //   </div>
          //   <input
          //     type="radio"
          //     onChange={() => handleRadioChange(property.property_name,property.propertyid)}
          //     // checked={propertyId === property.propertyid}
          //     checked={selectedPropertyRadio == property.property_name}

          //     value={property.property_name}
          //     name="selectedProperty"
          //   />
          // </div>