import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Save_ExitComp from "../../../Components/common/save_ExitComp";
import { useSelector } from "react-redux";

const HostCongrats = () => {
  const navigate = useNavigate();
  const storeSel = useSelector((state) => state);

  const fullNameData = storeSel.hostDetailSlice?.full_Name;


  // const [api, setApi] = useState(["createProperty_metadata_bulk"]);
  // const [method, setMethod] = useState("");
  // const [addMethod, setAddMethod] = useState("");
  // // const propertyId = sessionStorage.getItem("property");

  // //
  // const Currenturl = window.location.href;
  // console.log(Currenturl, "tyt");
  // const CurrentPage = new URL(Currenturl);
  // const path = CurrentPage.pathname;
  // console.log(path, "qqq");

  // const storeSel = useSelector((state) => state);
  // //

  // useEffect(() => {
  //   const PropIddata = storeSel.apiDataSlice?.propId;

  //   const preferenceIddata = storeSel.apiDataSlice?.preferenceId;
  //   const addOnPropId = storeSel.apiDataSlice?.addOnPropId;

  //   let updatedApi = ["updateProperty/" + PropIddata, ...api];

  //   let updatedMethod = method;
  //   let updatedAddMethod = addMethod;

  //   if (preferenceIddata) {
  //     updatedApi.push("updatePropertyPref/" + preferenceIddata);
  //     updatedMethod = "patch";
  //   } else {
  //     updatedApi.push("add_preference");
  //     updatedMethod = "post";
  //   }

  //   if (addOnPropId) {
  //     updatedApi.push("update_property_addons/" + addOnPropId);

  //     updatedAddMethod = "patch";
  //   } else {
  //     updatedApi.push("add_property_addons");
  //     updatedAddMethod = "post";
  //   }
  //   updatedApi.push("apply_property_for_publishing/" + PropIddata);

  //   setApi(updatedApi);
  //   setMethod(updatedMethod);
  //   setAddMethod(updatedAddMethod);
  // }, []);
  // console.log(api, "iip");

  const navigateTo = () => {    
    navigate("/host/listing");
  };

  return (
    <div className="host-congrats-page">
      <div className="host-congrats-content">
        <h2>Congratulations, {fullNameData}</h2>
        <p>
          Your property listing has been successfully sent for verification.
          Further updates will be received through message and email.
        </p>
        <p>
          Welcome aboard! As a fellow host, we appreciate your generosity in
          sharing your home and helping us create unforgettable experiences for
          our guests.
        </p>
      </div>
      <div className="bottom-btns-fixed-box">
        {/* <Save_ExitComp
          isButton={true}
          btnTextExit="Go To Dashboard"
          // onClick={navigateTo}
          className="common-next-btn"
          method={method}
          apis={api}
          addMethod={addMethod}
          path={path}
          // navigateTo={navigateTo}
        /> */}

        <button onClick={navigateTo} type="submit" className="common-next-btn">
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default HostCongrats;
