// LayerPage.js
import React, { useEffect, useState } from 'react';
import OngoingDetailsPage from './OngoingDetailsPage';
import UpcomingDetailsPage from './UpcomingDetailsPage';
import PastDetailsPage from './PastDetailsPage';
import CancelledDetailsPage from './CancelledDetailsPage';
import APIHit from '../../../utils/apiHit';
import { apiMainUser, apiPublicUser } from '../../../utils/constant';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import WrapperPopup from '../../../Components/common/wrapperPopup';
import RevewHostPopup from '../../../Module/Host/EditListing/ListingDetail/RevewHostPopup';

const LayerPage = (props) => {
    const [apicall, setApiCall] = useState(false);
  const [assetsurl, setAssetsUrl] = useState();

  const [successdata, setSuccessData] = useState()
  const [successdataUser, setSuccessDataUser] = useState()

  const [hostuser, setHostUser] = useState()
  const [slug, setSlug] = useState()
  const [successdataprop, setSuccessDataProp] = useState()
  const [openModal, setOpenModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);  
  const [guestReviewModal, setGuestReviewModal] = useState(false);  

  const [selectedTripType, setSelectedTripType] = useState();
  const [openCancelModal,setOpenCancelModal] = useState(false)

  const navigate= useNavigate()


const data = successdata?.transaction_details[0]?.price_detail;
console.log(data, "tried","sdss")
  let priceDetailMapvalues = {};
   data?.map((x)=>{
    console.log(x,'tried',"wow")
    
    if(x.total){
      console.log("tried",x.total)
      priceDetailMapvalues = x?.total;
    }
   
  })


  const toggleVisibleDocks = () => {
    setGuestReviewModal(false)
    setReviewModal(false)
    setOpenCancelModal(false)
  }




const consoleDateStr = successdata?.transaction_details[0].checkout_date;
const consoleDate = new Date(consoleDateStr);
const [review,setReview]= useState([])
const[reviewWithBookId,setReviewWithBookId] = useState()
const [ratingAverage,setRatingAverage]= useState([])
const [ratingAverageWithBookId,setRatingAverageWithBookId]= useState()


const propId = successdata?.property_id;
const propIdForUser = successdataUser?.property_id


const userId = successdata?.user_id


const bookingOrderId = successdata?.booking_order_id;
const bookingOrderIdForUser = successdataUser?.booking_order_id;
const hostId =  successdataUser?.host_id

  const handleSuccess = (response) => {
    // Handle the successful response here
    console.log("Successful response:", response);
    setSuccessData(response.data[0]);
    
  };
  console.log(successdata,'iifg')

  // console.log(successdata?.transaction_details[0].price_detail,'luks')
  const handleFailure = (data) => {
    // Handle the successful response here
    console.log("Failure response:", data);
  };
  const runPrecondition = () => {
    // Perform your precondition check here
    // Return true if the precondition is met, or false otherwise
    if (successdata !== []) {
      return false;
    }
    return true;
  };  


  
  const  fetchHostReviewDataWithBookingId = async () => {
    // API call
    try {
      const response = await axios.get(apiPublicUser + `reviews-ratings/user/${hostId}`, {
        params: {
          booking_id: successdataUser?.booking_order_id,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
  
      if (response.data.status === 'success') {
    
        setReviewWithBookId(response.data.data?.host?.reviews);
        // setRatingAverage(response.data.data.user?.total_rating);
        setRatingAverageWithBookId(response.data.data?.host?.total_rating);
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log(reviewWithBookId,'checkReveiw')


  const fetchHostReviewData = async () => {
   
    // // API call
    // try {
    //   const response = await axios
   
    //     .get(apiPublicUser + `reviews-ratings/property/` ,{
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //     .then((response) => {
       
    //       if(response.data.status === 'success'){
    //         console.log(response.data.data ,'hey')
    //         setReview(response.data.data.reviews)
    //         setRatingAverage(response.data.data.total_rating)
    //        }
       
          
    //     });
    // } catch (error) {
    //   console.error(error);
    // }
 // API call
 try {
    const response = await axios.get(apiMainUser + `reviews-ratings/property`, {
      params: {
        booking_id: successdataUser?.booking_order_id,
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (response.data.status === 'success') {
        console.log(response.data.data,'checkreviewdata')
  
        setReview(response.data.data.reviews)
        setRatingAverage(response.data.data.total_rating)
    }
  } catch (error) {
    console.error(error);
  }




  };




    useEffect(()=>{
      if(successdataUser){
        console.log('calledUser')
        fetchHostReviewData()
        fetchHostReviewDataWithBookingId()
      }
    },[successdataUser])

    console.log(successdataprop,'checkSuccess')
 
  
  const handleSuccessUser = (response) => {
    // Handle the successful response here
    setAssetsUrl(response.assets_url);
    setSuccessDataUser(response.data[0]);
    setSuccessData(response.data[0])
  };
  console.log(successdata,'iifg')

  // console.log(successdata?.transaction_details[0].price_detail,'luks')
  const handleFailureUser = (data) => {
    // Handle the successful response here
    console.log("Failure response:", data);
  };
  const runPreconditionUser = () => {
    // Perform your precondition check here
    // Return true if the precondition is met, or false otherwise
    if (setSuccessDataUser !== []) {
      return false;
    }
    return true;
  };  

  const handleSuccessProp = (response) => {
    // Handle the successful response here
    console.log("Successful response:", response);
    setSuccessDataProp(response.data[0]);
    setApiCall(false)
  };
console.log(successdataprop,'checkProps')

  // console.log(successdataprop?.host_details,'lucifer')
  const handleFailureProp = (data) => {
    // Handle the successful response here
    console.log("Failure response:", data);
  };
  const runPreconditionProp = () => {
    // Perform your precondition check here
    // Return true if the precondition is met, or false otherwise
    if (successdataprop !== []) {
      return false;
    }
    return true;
  }; 
  useEffect(() => {
    let pathname = window.location.pathname
    if(pathname.includes("trips")){
      setHostUser("user")
     

      var slugme = window.location.pathname.replace("/trips/","")  
      // setApiCallHost(true);    
      setApiCall(true);   
    }else{
      setHostUser(null)
     
      // var slugme = window.location.pathname.replace("/trips/","")
      // setApiCall(true);      
    }
    setSlug(slugme)
   

  }, []);
  
  
  const hostNameForUser = successdataUser?.host_fname + ' ' + successdataUser?.host_lname;
  const userNameForHost = successdata?.fname + ' ' + successdata?.lname;
  const hostImageForUser = successdataUser?.host_fname
  // console.log(successdata?.transaction_details[0].price_detail,'successall')


  ///validations  for rendering the cards=====================

  const checkoutDate = new Date(successdataUser?.checkout_date);
  const checkinDate = new Date(successdataUser?.checkout_date);

  const currentDate = new Date();
  const isCheckoutDateValid = currentDate > checkoutDate;

  console.log(isCheckoutDateValid,'checkDateValue')
  console.log(ratingAverage,'checkDateValue')

  const isValidForUpcoming = currentDate < checkinDate


  //===============================================================
  



  //  console.log(reviewWithBookId?.[0]?.booking_id == successdataUser?.booking_order_id,'CheckPara')




  //  console.log(successdata,'checkDataAll')

  //  console.log(apicall,'checkStatus')

  //  console.log(selectedTripType,'checkTrip')

   useEffect(()=>{
    setSelectedTripType(successdata?.tabsname)
   },[successdata])
 
   const renderDetailsPage = () => {
 

    switch (selectedTripType) {
     case 'ongoing':
      return <OngoingDetailsPage
      successdataUser={successdataUser}
      assetsurl={assetsurl}
      successdataprop={successdataprop}
      propIdForUser={propIdForUser}
      fetchHostReviewDataWithBookingId={fetchHostReviewDataWithBookingId}
      fetchHostReviewData={fetchHostReviewData}
      successdata={successdata}
      bookingOrderIdForUser={bookingOrderIdForUser}
      ratingAverage={ratingAverage}
      review={review}
      
      />;
    case 'upcoming':
      return <UpcomingDetailsPage
            apicall={apicall}
            setApiCall={setApiCall}            
            fetchData={props.fetchData}
              listingData={props.listingData}
              successdata={successdata}
              bookingOrderId={bookingOrderId}
              successdataUser={successdataUser}
              assetsurl={assetsurl}
              successdataprop={successdataprop}
              ratingAverage={ratingAverage}
              review={review}
            
      
      />;
      case 'arriving':
        return <UpcomingDetailsPage
              apicall={apicall}
              setApiCall={setApiCall}              
              fetchData={props.fetchData}
                listingData={props.listingData}
                successdata={successdata}
                bookingOrderId={bookingOrderId}
                successdataUser={successdataUser}
                assetsurl={assetsurl}
                successdataprop={successdataprop}
                ratingAverage={ratingAverage}
                review={review}
              
        
        />;


      case 'passed':
      return <PastDetailsPage
      successdataUser={successdataUser}
      assetsurl={assetsurl}
      successdataprop={successdataprop}
      successdata={successdata}
      ratingAverage={ratingAverage}
      review={review}
      reviewWithBookId={reviewWithBookId}
      ratingAverageWithBookId={ratingAverageWithBookId}
      isCheckoutDateValid={isCheckoutDateValid}
      setReviewModal={setReviewModal}
      fetchHostReviewDataWithBookingId={fetchHostReviewDataWithBookingId}
      fetchHostReviewData={fetchHostReviewData}
      bookingOrderIdForUser={bookingOrderIdForUser}
    />;
      case 'refunded':
      return <CancelledDetailsPage
      successdataUser={successdataUser}
      assetsurl={assetsurl}
      successdataprop={successdataprop}
      successdata={successdata}
      checkInTime={props.checkInTime}
      checkOutTime={props.checkOutTime}  
      ratingAverage={ratingAverage}
      review={review}
      
      
      />;

      
      default:
       return null;
  }
  };
 

 return(
 <>
  

      {apicall && ( 

      <APIHit
        url={apiMainUser + "read_bookings?booking_order_id="+slug}
        method="GET"
        onSuccess={handleSuccessUser}
        onFailure={handleFailureUser}
        precondition={runPreconditionUser}
        successResponseCode={200}
        // apidata={amenitiesData}
        directCall={true}
        auth={true}
      ></APIHit>
      )}
      

    {successdata && (
      <APIHit
        url={apiPublicUser + "search_property?propertyid="+successdata?.property_id }
        method="GET"
        onSuccess={handleSuccessProp}
        onFailure={handleFailureProp}
        precondition={runPreconditionProp}
        successResponseCode={200}
        // apidata={amenitiesData}
        directCall={true}    
        auth={true}

           
      ></APIHit>      
    )}

    {/* {successdataUser && (
      <APIHit
        url={apiPublicUser + "search_property?propertyid="+  successdataUser?.property_id}
        method="GET"
        onSuccess={handleSuccessProp}
        onFailure={handleFailureProp}
        precondition={runPreconditionProp}
        successResponseCode={200}
        // apidata={amenitiesData}
        directCall={true} 
        auth={true}

      ></APIHit>      

    )}
 */}

 
 {renderDetailsPage()}


 </>
 )
};

export default LayerPage;
