import React from "react";
import SearchComp from "../../../Components/common/searchComp";
import PropertyListing from "../../../Components/common/PropertyListing";
import ListingDetailEditContent from "./ListingDetailEditContent";
import PricingDetailEditComponnent from "./PricingDetailEditComponnent";
import Policies_rulesEditComp from "./Policies_rulesEditComp";
import CoHostEditComponent from "./CoHostEditComponent";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { CleanCreateListingReduxNew, apiMainUser } from "../../../utils/constant";
import { useRef } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs } from "@mui/material";

const EditListingPage = () => {
  const navigate = useNavigate();
  const [listingData, setListingData] = useState(null);
  const [listingDataCopy, setListingDataCopy] = useState(false);
 

  const [listingAllData, setListingAllData] = useState(null);

  const isSmallScreen = window.innerWidth <= 500;

 const currentPropertyName = listingData?.data[0]?.property_name
  

  const DataStored = listingAllData?.data;

  const filterFunc = DataStored?.filter((item) => {
    return item.publish === 1;
  });

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredListings = filterFunc?.filter((listing) =>
    listing.property_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  


  const navigateToPage = (listing) => {
    console.log(listing, "clicked");
    console.log("heydoneclick");
    const city = listing.city;
    const slug = listing.slug;
    navigate(`/host/listing/edit/${city}/${slug} `);
    // window.location.reload();
  };

  const Currenturl = window.location;
  const CurrentPage = Currenturl;
  const path = CurrentPage.pathname;
  let extractedData = path.replace("/host/listing/edit/", "");

  const fetchData = async () => {
    const Currenturl = window.location;
    const CurrentPage = Currenturl;
    const path = CurrentPage.pathname;
   
    console.log(path, "qqq");

    let extractedData = path.replace("/host/listing/edit/", "");

    // API call
    try {
      const response = await axios
        .get(apiMainUser + "getListing?slug=" + extractedData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setListingData(response.data);
          if (!listingDataCopy) {
            setListingDataCopy(true);
          }
          // setListingDataCopy(response.data);
          console.log(response.data, "qqq");
        });
    } catch (error) {
      console.error(error);
    }
  };

  
  console.log(listingData,'iif')

const fetchDataAll = async () => {
  // API call
  try {
    const response = await axios
      .get(apiMainUser + "getListing?limit=999", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setListingAllData(response.data);

        console.log(response.data, "qqq");
      });
  } catch (error) {
    console.error(error);
  }
};

  console.log(listingAllData,'yyr')

  useEffect(() => {
    fetchData();
    fetchDataAll();
  }, [window.location.pathname]);

  useEffect(() => {
    if (listingDataCopy) {
      fetchData();
    }
    setListingDataCopy(false);

    
  }, [listingData]);

  console.log(listingData,'checkListingData')

  

  //   console.log(path, "topi");
  const links = [
    { id: 1, label: "Listing Details" },
    { id: 2, label: "Pricing & Availablity" },
    { id: 3, label: "Policies & Rules" },
    { id: 4, label: "Co-Host" },
  ];

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  console.log(listingData,'checkDataAll')


  const cleanCreateListingReduxNew = CleanCreateListingReduxNew();

  ///Extracting the property name from params--------------
  // const dynamicUrl = useLocation();
  
  
  return (
    <div className="edit-listing-main-page">

     {!isSmallScreen &&  
      <div className="left-edit-page-content">
        <div>
          <SearchComp onKeyUp={handleSearch}/>
          <NavLink style={{color:'#222'}} to="/host/createlisting">
            
          <span onClick={cleanCreateListingReduxNew} className="listing-edit-filter-btn">
    
          <FontAwesomeIcon  icon={faPlus} className="location-icon"  />

          </span>
            </NavLink>

       
        </div>

        
        <div className="edit-property-list">
        {filteredListings?.map((listing, index) => (
          <div 
          className={
            currentPropertyName === listing.property_name 
              ? 'property-listing-edit-parent highlighted-property-listing'
              : 'property-listing-edit-parent'
          }
          >
          <PropertyListing
            key={index}
            src={
              listing.images && listing.images.length > 0
                ? listing.base_url_image + listing.images[0]
                : './assets/img/notFoundImage.png'
            }
            
            
            propListingText="titleTexTProperty"
            paraText={listing.property_name}
            onClick={() => navigateToPage(listing)}
          />
          </div>
        ))}
      </div>
      </div>
}

      <div className="right-edit-page-content">
      <h2 className="edit-listing-property-name-heading">{listingData?.data[0].property_name}</h2>
        <div className="edit-page-navbar-parent">
        <nav className="navbar-tab-container">
          {links.map((link) => (
            <button
              key={link.id}
              className={`nav-link-edit ${
                activeTab === link.id ? "edit-active" : ""
              }`}
              onClick={() => handleTabClick(link.id)}
            >
              {link.label}
            </button>
          ))}
        </nav>
        </div>


        <div className="tab-content" style={{marginTop:'2rem'}}>
          {activeTab === 1 && (
            <ListingDetailEditContent
              extractedData={extractedData}
              listingData={listingData}
              fetchData={fetchData}
            />
          )}
          {activeTab === 2 && (
            <PricingDetailEditComponnent
              fetchData={fetchData}
              listingData={listingData}
            />
          )}
          {activeTab === 3 && (
            <Policies_rulesEditComp
              fetchData={fetchData}
              listingData={listingData}
            />
          )}
          {activeTab === 4 && (
            <CoHostEditComponent
              fetchData={fetchData}
              listingData={listingData}                            
            />
          )}
        </div>
      </div>
        
    </div>
  );
};

export default EditListingPage;
