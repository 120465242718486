import React from 'react';
import axios from 'axios';
import Button from '../../../Components/common/Button';
import { useForm } from 'react-hook-form';
import { apiMainUser } from '../../../utils/constant';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../utils/redux/SnackBarSlicer';

const HostNameEdit = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch()

  console.log(props.usersData,'iii')
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('fname', data.Firstname);
    formData.append('lname', data.Surname);

    try {
      const response = await axios({
        method:'patch',
        url: apiMainUser + 'update_user',
      
        data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        
      }
      
      );
      if(response.data.status === 'success'){
        props.fetchData()
        props.closeModal()
        dispatch(openSnackbar({ message: 'updated Successfully', severity: 'success' }));

       
      }
    } catch (error) {
      console.error(error,'errorcheck');
      dispatch(openSnackbar({ message: error.response.data.message, severity: 'error' }));

    }
  };
  

  return (
    <div className="host-edit-name-main-div ">
      {/* <p style={{ fontSize: '14px', marginTop: '2rem', marginBottom: '0.5rem' }}>Legal Name</p> */}
      <p className='mb-10 mt-14 font-xs' >This is the name that appears on a government-issued ID document</p>

      <form className="host-personal-info-edit-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="host-personal-info-input-box">
          <label className='personal-info-label' htmlFor="Firstname">Firstname</label>
          <input
              {...register('Firstname', { required: true })}
              id="Firstname"
              placeholder="Enter your first name"
              defaultValue={props.usersData?.fname || ''} 
            />
          {errors.Firstname && <span>This field is required.</span>}
        </div>

        <div className="host-personal-info-input-box">
          <label className='personal-info-label' htmlFor="Surname">Surname</label>
          <input {...register('Surname', { required: true })} id="Surname" placeholder="Enter your Surname" 
          defaultValue={props.usersData?.lname || ''} 
          />
          {errors.Surname && <span>This field is required.</span>}
        </div>

        {/* <Button btnText="save" btnclasses="save-edit-btn" /> */}
        <div className="clear_apply-btn-box">
      
           <p onClick={() => props.closeModal(false)} className="clear-btn">
              Cancel
            </p>
           <button className="save-edit-btn" type="apply-btn">
              Save
            </button>
            </div>
      </form>
    </div>
  );
};

export default HostNameEdit;
