import React from 'react'
import { useNavigate } from 'react-router-dom'

const VerifactionToNavigateHost = () => {

    const navigate = useNavigate()

    const navigateToPersonalInfo = ()=>{
     navigate('/hostPersonalInfo')
    }


  return (
    <div className='text-center Verifaction-to-navigate-host'>
     <p className='font-large mb-10'>Update Your Personal Info</p> 
     <ul className='font-small mb-10 text-left pd-2'>To proceed with hosting please update the following details:<br/>
        <li className='mb-5 mt-5'>Verify your email id</li>
        <li className='mb-5'>Verify your mobile no.</li>
        <li className='mb-5'>Upload your government documents for verification</li>
      </ul> 

      <button onClick={navigateToPersonalInfo} className="save-edit-btn mb-20" type="apply-btn naviagteBtntoProfile">
         Update Personal Info     
      </button>
    </div>
  )
}

export default VerifactionToNavigateHost
