import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const UnProtected = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    let login = localStorage.getItem("login");
    if (login !== null) {
      navigate("/");
    }
  });
  let login = localStorage.getItem("login");
    console.log(login);
  if (login === null) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default UnProtected;