import React, { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import InputComp from "../../../Components/common/textInput";
import Button from "../../../Components/common/Button";
import { useState } from "react";
import FontComp from "../../../Components/common/fontComp";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import APIHit from "../../../utils/apiHit";
import { apiMainUser, apiPublicUser, checkErrors } from "../../../utils/constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { fullNAmAction, fullNameAction, getEmail, getImage, getUid, usernameAction } from "../../../utils/redux/HostDetailSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { openSnackbar } from "../../../utils/redux/SnackBarSlicer";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const SignUpPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [signupdata, setSignUpData] = useState("");
  const [showerror, setError] = useState(null);
  const phoneInputRef = useRef(null);
  const [countryCode, setCountryCode] = useState(''); 


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [numberdata, setNumberData] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors, getValues },
  } = useForm();

  const location = useLocation();
  const storeSel = useSelector((state) => state);
  const loginuid = storeSel?.hostDetailSlice?.host_Uid;
  useEffect(() => {
    console.log(loginuid,"loginuid")
    if(loginuid !== null && loginuid !== '' ){
      navigate('/')
    }
  }, [loginuid]);




  const handleGoogleSignUp = async () => {
    try {
      const googleSignupData = {
        signinOptions:"google"
      }
      const response = await axios.post(apiMainUser + "signup", googleSignupData,{
      
      });
  
      // Handle the response as needed
      console.log("Google signup success:", response.data);
    window.open(response.data.consentUrl, "", "width=500px,height=500px");
  
      // Redirect or perform other actions after successful signup
    } catch (error) {
      // Handle errors
      console.error("Google signup error:", error);
  
      // Display an error message to the user if needed
    }
  };

  const onSubmit = (data) => {

    console.log(data,'checkData')
    delete data.confirmPassword;
    let finaldata = {
      signinOptions: "direct",
    
      // mobile:  numberdata,
      mobile: numberdata.replace(`${countryCode.dialCode}`, ''), 
      // country_code: countryCode.dialCode,
      country_code: `+${countryCode.dialCode}`,
      
      ...data,
    };
    setSignUpData(finaldata);
  };

  const preCondition = () => {
    return checkErrors(errors);
  };

  const handleSuccess = (res) => {    
    let headers = {headers:{Authorization: "Bearer " + res.data.token}}    
    axios.post(apiMainUser+"apply_for_host",null,headers);   
    let resdata = signupdata;    
    // localStorage.setItem("fullname", resdata.fname + " " + resdata.lname);
    dispatch(fullNameAction(resdata.fname + " " + resdata.lname))
    dispatch(usernameAction(resdata.username))
    dispatch(getImage(res.assets_url+resdata.image))
    dispatch(getUid(resdata.uid))
    dispatch(getEmail(resdata.email))
    dispatch(openSnackbar({ message: 'signup successful!', severity: 'success' }));
    
    navigate("/");
    localStorage.setItem("login", true);
    localStorage.setItem("token", res.data.token);
  };

  const handleFailure = (res) => {    
    console.log(res,'checkRes')
    // if (res.data.status === 'failed') {
      // setError("User not found!");
      dispatch(openSnackbar({ message: res.data.message, severity: 'error' }));
    // }
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };
  return (
    <div className="sign-up-page login-page">
      <img src="./assets/img/signupImg.png" className="login-page-img" alt="" />
      <div className="login-left-box">
        <div className="login-form-section">
          <h3 className="get-started-text">Get Started with JUMBO</h3>
          <p className="get-started-easy-text">Getting started is easy</p>
          <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="single-input-box single-input-signup-box">
              <Controller
                control={control}
                name="fname"
                defaultValue=""
                rules={{ required: "First name is required" }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="text"
                    inputClass="login-form-input signup-form-input"
                    name="fname"
                    inpPlace="Enter your first name"
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              {errors.fname && <span>{errors.fname.message}</span>}
            </div>
            <div className="single-input-box single-input-signup-box">
              <Controller
                control={control}
                name="lname"
                defaultValue=""
                rules={{ required: "Last name is required" }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="text"
                    inputClass="login-form-input signup-form-input"
                    name="lname"
                    inpPlace="Enter your last name"
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              {errors.fname && <span>{errors.fname.message}</span>}
            </div>
            <div className="single-input-box single-input-signup-box">
              <Controller
                control={control}
                name="email"
                defaultValue=""
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType="email"
                    inputClass="login-form-input signup-form-input"
                    name="email"
                    inpPlace="Enter your email"
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              {errors.email && <span>{errors.email.message}</span>}
            </div>

            <div className="single-input-box single-input-signup-box">
            <PhoneInput
            ref={phoneInputRef}
              className='phone-number-input'
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                  className:'login-form-input signup-form-input mobile-signup-input'
                }}
                country={'in'}
              //  value={numberdata}
                onChange={(phone,country) => {
                  // Set the phone value when changed
                  setNumberData(phone);
                  setCountryCode(country);
                }}
              />
              {errors.phone && <span>This field is required.</span>}
              
            </div>


            <div className="single-input-box single-input-signup-box">
              <Controller
                control={control}
                name="password"
                defaultValue=""
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType={showPassword ? "text" : "password"}
                    inputClass="login-form-input signup-form-input"
                    name="password"
                    inpPlace="Enter your password"
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              <FontComp
                icon={showPassword ? faEye : faEyeSlash}
                onClick={toggleShowPassword}
                size="lg"
                color="#B8B8B8"
              />
              {errors.password && <span>{errors.password.message}</span>}
            </div>
            <div className="single-input-box single-input-signup-box">
              <Controller
                control={control}
                name="confirmPassword"
                defaultValue=""
                rules={{
                  required: "Confirm password is required",
                  // validate: (value) =>
                  //   value === getValues("password") ||
                  //   "The passwords do not match",
                }}
                render={({ field: { onChange, value } }) => (
                  <InputComp
                    inputType={showConfirmPassword ? "text" : "password"}
                    inputClass="login-form-input signup-form-input"
                    name="confirmPassword"
                    inpPlace="Confirm your password"
                    onKeyUp={onChange}
                    value={value}
                  />
                )}
              />
              <FontComp
                icon={showConfirmPassword ? faEye : faEyeSlash}
                onClick={toggleShowConfirmPassword}
                size="lg"
                color="#B8B8B8"
              />
              {errors.confirmPassword && (
                <span>{errors.confirmPassword.message}</span>
              )}
            </div>

            <APIHit
              handleSubmit={handleSubmit(onSubmit)}
              url={apiMainUser + "signup"}
              apidata={signupdata}
              successResponseCode={200}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              precondition={preCondition}
              method="post"
            >
              {/* <Button type="submit">Submit</button> */}
            {showerror !== null ? <p className="error-form-msg">{showerror}</p> : null}
              <Button btnclasses="tab-btn" type="submit" btnText="Sign up" />
            </APIHit>
          </form>
          <p className="signup-footer">
            By continuing you indicate that you read and agreed to the{" "}
            <span>Terms of Use</span>{" "}
          </p>
          <p className="have-account-text">
            Already have an Account? <Link to="/login"><span>Sign in</span></Link>
          </p>

           <p className="line-para">
            <span className="line"></span>
            <span>or continue with</span>
            <span className="line"></span>
          </p>

          <div className="login-options-box">
            <Button
              btnclasses="login-btn"
              icon="./assets/img/googleImg.png"
              btnText="Google"
              iconBtnclass
              onClick={handleGoogleSignUp}
            />
              {/* <Button
              btnclasses="login-btn"
              icon="./assets/img/fbImg.png"
              btnText="Facebook"
              iconBtnclass
            />  */}
          </div>  

         
       

        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
