
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../Components/common/Button";
import HeadingComp from "../HeadingComp";
import { apiMainUser, checkErrors } from "../../../../utils/constant";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import APIHit from "../../../../utils/apiHit"; // Import the APIHit component
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditorWrapper from "../../../../Components/common/CkEditorComp";
import { openSnackbar } from "../../../../utils/redux/SnackBarSlicer";
import { useDispatch } from "react-redux";

const EditDescription = (props) => {
  const [postData, setPostData] = useState(null);
  //   const [postData, setPostData] = useState(null);
  const [showerror, setError] = useState(null);
  const dispatch = useDispatch()

  let url = apiMainUser + "createProperty_metadata_bulk";

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postDatavalue = {
      property_id: props.propId,
      meta: [
        {
          metadata_key: "description",
          metadata_value: data.description,
        },
      ],
    };
    setPostData(postDatavalue);
  };

  const preCondition = () => {
    let ercheck = checkErrors(errors);
    return ercheck;
  };
    const handleSuccess = (res) => {
    if (res.status === "success") {
      props.closeModal()
      props.fetchData()
      dispatch(openSnackbar({ message: 'Description updated succesfully!', severity: 'success' }));

    }
  };

  const handleFailure = (res) => {
    console.log(res);
    if (res && res.status === 404) {
      setError("");
    } else {
      setError("");
      dispatch(openSnackbar({ message: 'Somethng went wrong!', severity: 'error' }));

    }
  };


  return (
    <div className="edit-listing-title-box edit-listing-description-box">
     
      <form className="confirm-address-form" onSubmit={handleSubmit(onSubmit)}>
        <div >
          <div style={{width:'100%'}} className="single-input-box w-100">
            <Controller
              control={control}
              name="description"
              defaultValue={props.descriptionText}
              rules={{
                required: "Description is required",
              }}
              render={({ field: { onChange, value } }) => (
                <CKEditorWrapper value={value} onChange={onChange} />

              )}
            />
            {errors.description && (
              <span className="error-message">
                {errors.description.message}
              </span>
            )}
          </div>
          {/* <div className="edit-save-cancel-btn-box"> */}
          <div className="clear_apply-btn-box paddingCustomYSide">
            {/* Place the APIHit component here */}
            <APIHit
            parentClassApiHit='api-hit-parent'
            ApiHitformClass='api-hit-form'
              handleSubmit={handleSubmit(onSubmit)}
              url={url}
              apidata={postData}
              successResponseCode={201}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              precondition={preCondition}
              method={"post"}
              auth={true}
            >
            

              <p onClick={() => props.closeModal(false)} className="clear-btn">
              Cancel
            </p>
           <button className="save-edit-btn" type="apply-btn">
              Save
            </button>   
            </APIHit>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditDescription;
