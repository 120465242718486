import React from 'react';


const GuestMessageComp = (props) => {
  return (
    <div className='guest-message-container'>
      <div className='guest-img-on-message-card'>
        <img src={props.guestImg ? props.guestImg : './assets/img/userIconImg.jpg'} alt='Guest' />
      </div>
      <div className='guest-message-card skewed-top'>
        <div className="guest-message-content">
          <p className='message-guest-name-text'>{props.guestName}</p>
          <p className='message-guest-messge-text'>{props.messageText}</p>
          <p className="time-right-align guest-message-time">{props.messageTime}</p>
        </div>
      </div>
    </div>
  );
};

export default GuestMessageComp;
