import React from "react";
import {
  faEllipsisVertical,
  faPen,
  faCoffee,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const FontComp = (props) => {
  return (
    <div className="font-icon-div">
      <FontAwesomeIcon
        className={props.fontclassname}
        icon={props.icon}
        // name={link.link}
        onClick={props.onClick !== undefined ? props.onClick : null}
        size={props.size}
        color={props.color}
      />
    </div>
  );
};

export default FontComp;
