import React from "react";
import CardWrapper from "../common/cardWrapper";
import Button from "../common/Button";
import GuestProfile from "../common/guestProfile";

const LanguageContactHostCard = (props) => {
  // iski styling CardWrappe.scss me hai ----------
  return (
    <CardWrapper wrapperClass="language-contact-card sticky-t-100">      
      {/* <GuestProfile
            profileName={props.profileName}
            whoseProfile={props.profileSub}
            profileImg={props.profilePic}
        /> */}
        {props.languages ? <p>Languages known: <span>{props.languages}</span></p> : null}
        
      <Button onClick={props.onClick} btnText="Message host"  btnclasses="contact-btn" />
      <div className="protection-payment-text-box">
        <img src="./assets/img/protectionIcon.svg" alt="" />
        <p>For payment protection, stick to Jumbo Holidays website or app.</p>
      </div>
    </CardWrapper>
  );
};

export default LanguageContactHostCard;
