// CancelReservationButton.jsx
import React, { useState } from 'react';
import WrapperPopup from '../../../Components/common/wrapperPopup';
import CancelReservationPop from '../../../Components/UserComponents/CancelReservationPop';

const CancelReservationButton = (props) => {
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const openCancelReservationModal = () => {
    setOpenCancelModal(true);
  };

  return (
    <div>
      <p className="underline mt-10 viewBreakdown" onClick={openCancelReservationModal}>
        Cancel Reservation
      </p>

      <div className="co-host-details-popup">

      {openCancelModal && (
        <WrapperPopup
          fluid={true}
          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={props.toggleVisibleDocks} 
          closeModal={() => setOpenCancelModal(false)}
          comp={
            <CancelReservationPop
              fetchData={props.fetchData}
              listingData={props.listingData}
              uid={props.successdata?.uid}
              bookingOrderId={props.bookingOrderId}
              closeModal={() => setOpenCancelModal(false)}
              apicall={props.apicall}
              setApiCall={props.setApiCall}
              hostCancel={props.hostCancel}
            />
          }
        />
      )}
      </div>
    </div>
  );
};

export default CancelReservationButton;
