

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import HeadingComp from '../HeadingComp';
import axios from 'axios';
import { apiMainUser } from '../../../../utils/constant';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../../utils/redux/SnackBarSlicer';

const ImportCalendarPop = (props) => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const [nameError, setNameError] = useState('');
  const [linkError, setLinkError] = useState('');

  const onSubmit = async (data) => {
    setNameError('');
    setLinkError('');

    if (!data.newCalendarName) {
      setNameError('Please enter a calendar name');
      return;
    }

    if (!data.newCalendarLink) {
      setLinkError('Please enter a calendar link');
      return;
    }

    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
      'i'
    );

    if (!urlPattern.test(data.newCalendarLink)) {
      setLinkError('Please enter a valid URL for Calendar Link');
      return;
    }

    let method;
        let apiUrl;
      
        if (props.calendarItem && props.calendarItem.import_link) {
          method = 'PATCH';
          apiUrl = `update_calender_event/${props.calendarItem?.uid}`;
        } else {
          method = 'POST';
          apiUrl = 'create_calender_event';
        }

    try {
      const response = await axios({
         

        method: method,
         url: apiMainUser + apiUrl,
        data: {
          import_link: data.newCalendarLink,
          import_name: data.newCalendarName,
          property_id: props.propId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}` || '',
        },
      });

      if (response.data.status === 'success') {
        
        let message = '';
        if (method === 'PATCH') {
          message = 'Link updated successfully!';
        } else {
          message = 'Link added successfully!';
        }
        props.fetchCalImportLinks();
        dispatch(
          openSnackbar({
            message: message,
            severity: 'success',
          })
        );
        props.closeModal();
      }
    } catch (error) {
        console.error('Error:', error);
              dispatch(
                openSnackbar({
                  message: 'Failed to add/update link!',
                  severity: 'error',
                })
              );
    }
  };

  return (
    <div className="import-calenadar-popup">
      
      <form onSubmit={handleSubmit(onSubmit)} style={{marginTop:'2rem'}}>
        <div className='flex import-calendar-input-div' >
          <input
            type="text"
            placeholder="Calendar Name"
            defaultValue={props.calendarItem ? props.calendarItem?.import_name : ''}
            {...register('newCalendarName')}
          />
          {nameError && <p className="error-message">{nameError}</p>}
          <input
            type="text"
            defaultValue={props.calendarItem ? props.calendarItem?.import_link : ''}
            placeholder="Calendar Link"
            {...register('newCalendarLink')}
          />
          {linkError && <p className="error-message">{linkError}</p>}
        </div>
        <div className="clear_apply-btn-box paddingCustomYSide">
          <p onClick={() => props.closeModal(false)} className="clear-btn">
            Cancel
          </p>
          <button className="save-edit-btn">Save</button>
        </div>
      </form>
    </div>
  );
};

export default ImportCalendarPop;
