import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { closeSnackbar } from '../../utils/redux/SnackBarSlicer';


const CustomSnackbar = (props) => {
  const dispatch = useDispatch();


const isSmallScreen = window.innerWidth <= 500;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar()); 
  };

//   console.log(open,'checkOpen')

  return (
    <>
     
      <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}

      style={{
        width: isSmallScreen ? '70%' : '',
        margin: isSmallScreen ? '0 auto' : '',
        bottom: isSmallScreen ? '115px' : '24px',
        zIndex: '99999999999999999999999999999999999999999'
      }}
      open={props.open} autoHideDuration={3000} onClose={handleClose}>
        <Alert className='snackbarr-alert' onClose={handleClose} severity={props.severity} 
         style={{
            fontSize: '15px',
            width:'100%'
         }}
        // sx={{ width: '100%' }}
        // sx={{
        //     '& .css-1pxa9xg-MuiAlert-message': {
        //       fontSize: '14px', 
           
        //     },
        //     width: '100%',
        //   }}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomSnackbar;
