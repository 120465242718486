import React from 'react';
import { useForm } from 'react-hook-form';
import HeadingComp from '../EditListing/HeadingComp';

const BankAddressPop = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log(data); // You can handle form data submission here
  };

  return (
    <>
    <HeadingComp headingText="Adress" />
    <form className='payout-pop-form' onSubmit={handleSubmit(onSubmit)}>
      <div className='bank-payout-adress-input' style={{ marginBottom: '20px' }}>
        <input
          type="text"
          {...register('streetAddress', { required: true })}
          placeholder="Street Address"
          style={{ width: '100%' }}
        />
        {errors.streetAddress && <span>This field is required</span>}
      </div>

      <div className='bank-payout-adress-input' style={{ marginBottom: '20px' }}>
        <input
          type="text"
          {...register('flatOrRoomNo', { required: true })}
          placeholder="Flat or Room No"
          style={{ width: '100%' }}
        />
        {errors.flatOrRoomNo && <span>This field is required</span>}
      </div>

      <div className='bank-payout-adress-input flex' style={{ marginBottom: '20px'}}>
        <input
          type="text"
          {...register('state', { required: true })}
          placeholder="State"
          style={{ width: '50%', marginRight: '10px' }}
        />
        {errors.state && <span>This field is required</span>}
        <input
          type="text"
          {...register('city', { required: true })}
          placeholder="City"
          style={{ width: '50%' }}
        />
        {errors.city && <span>This field is required</span>}
      </div>

      <div className='bank-payout-adress-input flex' style={{ marginBottom: '20px' }}>
        <input
          type="text"
          {...register('pincode', { required: true })}
          placeholder="Pincode"
          style={{ width: '50%' }}
        />
        {errors.pincode && <span>This field is required</span>}
      </div>
      <div className="clear_apply-btn-box paddingCustomAllSide">
      
      <p onClick={() => props.closeModal(false)} className="clear-btn">
         Cancel
       </p>
      <button className="save-edit-btn" type="apply-btn">
         Save
       </button>   
      </div>
    </form>
    </>
  );
};

export default BankAddressPop;

