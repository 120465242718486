import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputComp from '../../Components/common/textInput';
import FontComp from '../../Components/common/fontComp';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import Button from '../../Components/common/Button';
import { apiMainUser } from '../../utils/constant';
import axios from 'axios';
import Toast from '../../Components/common/Toast';
import { useDispatch } from 'react-redux';
import { apiAction1, apiAction2 } from '../../utils/redux/apiSlicer';
import { openSnackbar } from '../../utils/redux/SnackBarSlicer';
import { useNavigate } from 'react-router-dom';

const HostLoginSecurity = () => {

  const [apiStatus, setApiStatus] = useState(null);

  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    reset,
    register,
    
    formState: { errors },
    watch,
  } = useForm();



  // const currentPass = watch('current_pass');
  const newPass = watch('new_pass');

  const onSubmit = async (data) => {
    console.log(data.current_pass,'checkPass')
  
    try {
      if (data.current_pass === data.new_pass) {
        console.log('Current password and new password cannot be the same');
        return;
      }

      const response = await axios({
        method: 'post',
        url: apiMainUser + 'change_password',
        data: {
         previous_password: data.current_pass,
         new_password : data.new_pass,
        },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });
      
      console.log(response.data);
     

      if(response.data.status === 'success'){
        console.log('Resetting form fields...');
        // dispatch(apiAction1({ message: response.data.message, type: 'success' }));
        dispatch(openSnackbar({ message: 'Password updated succesfully!', severity: 'success' }));


        setApiStatus('success')
        // reset({
        //   current_pass: '',
        //   new_pass: '',
        //   confirm_pass: '',

        // });
        document.getElementById('current_pass').value = '';
        document.getElementById('new_pass').value = '';
        document.getElementById('confirm_pass').value = '';
        // window.location.reload();

      }
    } catch (error) {
      console.error(error);
      setApiStatus('error')
      
      // const errorMessage = error.response?.data?.message || 'API call error message';
      dispatch(openSnackbar({ message: error.response.data.message, severity: 'error' }));

      // window.location.reload();
    }
  };


  const navigate = useNavigate()


  const navigateToHostAccountPage =()=>{
    navigate('/hostAccount')
  }


  return (
    <>
    <div className='account-detail-page host-login-security-page'>
      <p style={{ fontSize: '1.2rem', fontWeight: '500' }}><span style={{cursor:'pointer'}} onClick={navigateToHostAccountPage}>Account</span> > Login & Security</p>
      <h2 className='account-head-text'>Login & Security</h2>

      <div className='host-security-detail-box'>
        <form className='host-security-form w-30' onSubmit={handleSubmit(onSubmit)}>
          <h3 style={{ marginBottom: '2.5rem' }}>Update Password</h3>
          {/* <p style={{ marginBottom: '3rem' }}>Last updated 21 Days ago</p> */}

          <div className='single-input-box w-100'>
            <Controller
              control={control}
              name='current_pass'
              rules={{
                required: 'Current Password is Required',
              }}
              render={({ field: { onChange, value } }) => (
                <InputComp
                  inputType='text'
                  inputClass='security-input w-100'
                  name='current_pass'
                  inpPlace='Enter Current Password'
                  onKeyUp={onChange}
                  value={value}
                  id='current_pass'
                />
              )}
            />
            <br />
            {errors.current_pass && <span className='error-message'>{errors.current_pass.message}</span>}
            <FontComp fontclassname='key-icon' icon={faKey} size='lg' color='#BDBDBD' />
          </div>

          <div className='single-input-box w-100'>
            <Controller
              control={control}
              name='new_pass'
              rules={{
                required: 'New Password is Required',
              }}
              render={({ field: { onChange, value } }) => (
                <InputComp
                  inputType='text'
                  inputClass='security-input w-100'
                  name='new_pass'
                  inpPlace='Enter New Password'
                  onKeyUp={onChange}
                  value={value}
                  id='new_pass'
                />
              )}
            />
            <br />
            {errors.new_pass && <span className='error-message'>{errors.new_pass.message}</span>}
            <FontComp fontclassname='key-icon' icon={faKey} size='lg' color='#BDBDBD' />
          </div>

          <div className='single-input-box w-100'>
            <Controller
              control={control}
              name='confirm_pass'
              rules={{
                required: 'Confirm Password is Required',
                validate: (value) => value === newPass || 'Confirm password must match the new password',
              }}
              render={({ field: { onChange, value } }) => (
                <InputComp
                  inputType='text'
                  inputClass='security-input w-100'
                  name='confirm_pass'
                  inpPlace='Enter Confirm Password'
                  onKeyUp={onChange}
                  value={value}
                  id='confirm_pass'
                />
              )}
            />
            <br />
            {errors.confirm_pass && <span className='error-message'>{errors.confirm_pass.message}</span>}
            <FontComp fontclassname='key-icon' icon={faKey} size='lg' color='#BDBDBD' />
          </div>

          {/* <Button type="submit" className="common-next-btn">
            update
          </button> */}
          <div className='text-center'>
          <Button type='submit' btnclasses='save-edit-btn' btnText='Update' />
          </div>
        </form>

        <div className='social-box'>
          <h3 style={{ marginBottom: '3rem' }}>Social Login</h3>

          <div className='social-link-box'>
            <div>
              <p className='social-title'>Facebook</p>
              <p className='social-connection'>Not Connected</p>
            </div>
            <p className='connection-update'>Connected</p>
          </div>

          <div className='social-link-box'>
            <div>
              <p className='social-title'>Google</p>
              <p className='social-connection'>Connected</p>
            </div>
            <p className='connection-update'>Disconnected</p>
          </div>
        </div>
      </div>
      {/* {apiStatus === 'success' && (
        <Toast 
        
        props={{ actionBtn: true, buttonText: 'view' }}
        message="Your Password Changed Succesfully" type="success" />
      )}
       {apiStatus === 'error' && (
          <Toast
          props={{ actionBtn: true, buttonText: 'view' }}
          message="Incorrect Previous Password"
          type="error"
        />
      )} */}
    </div>
   
    </>
  );
};

export default HostLoginSecurity;



