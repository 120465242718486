import React, { useState } from "react";
import DockComponent from "./DockComponent";
import APIHit from "../../utils/apiHit";
import { Fade } from "react-reveal";
import FixedButton from "./FixedButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, faXmark } from "@fortawesome/free-solid-svg-icons";
import HeadingComp from "../../Module/Host/EditListing/HeadingComp";
// import { Fade } from "@mui/material";

const WrapperPopup = (props) => {



  const isSmallScreen = window.innerWidth <= 500;

  // const [visibleDocks, setVisibleDocks]  = useState(false)

  // const toggleVisibleDocks = () => {
  //   setVisibleDocks((prevVisible) => !prevVisible);
  // };

  const handleCloseClick = () => {
    props.closeModal(false);
   
  };


  ///////////

  const handleSaveClick = () => {
    if (props.onSave) {
      props.onSave();
    }
  };

  const handleCancelClick = () => {
    if (props.onCancel) {
      props.onCancel();
    }
    props.closeModal(false);
  };
  ////

  


  const handleWrapperClick = (e) => {
    // Check if the dontClose prop is true, and if it is, do nothing.
  if (props.dontClose) {
      return;
    }

    if (e.target.classList.contains("wrapper-popup-div")) {
      props.closeModal(false);
    }
  };


  return (
    <div className="wrapper-Main-parent">
    {!isSmallScreen || props.wrapperPop ?  (
      <div className="wrapper-popup-div" onClick={handleWrapperClick}>
     
        <Fade bottom>

        <div className="wrapper-popup-container">
          <div className="title-cross-div">
          <HeadingComp headingText={props.headingText}/>
          <button
            className="wrapper-popup-close-btn"
            onClick={handleCloseClick}
          >
           
            {/* &times; */}
            <FontAwesomeIcon icon={faXmark}/>
          </button>
          </div>
        {props.comp}


        {/* //////////////////////--------------- */}
        {/* <FixedButton/>  */}

              {/* -------------------------------- */}

        </div>
    </Fade>
      </div>
     ) :(

      <>
      <DockComponent  
    headingText={props.headingText}
      // duration={10000}
        fluid={true}
        position={props.position}
        // dimMode={props.opaque ? props.opaque : false}
        defaultSize={props.defaultSize ? props.defaultSize : "0.75"}
        // dimStyle={props.dimStyle ? props.dimStyle : false}
        isVisible={props.isVisible}
        // setVisibleDocks={}
        btnClass={props.btnClass}
        setVisibleDocks={props.setVisibleDocks} 
        DockFilter={props.DockFilter}
        noCloseButton={props.noCloseButton}        
      >
     {props.comp}
       </DockComponent>
      </>
     )}
     </div>    
  );
};

export default WrapperPopup;
// trips/2023081810492822