import React, { useEffect, useState } from "react";
import CardWrapper from "../../common/cardWrapper";
import NightText from "../../common/nightsText";
import Skeleton from "react-loading-skeleton";
import SkeletonLoader from "../../common/SkeletonLoader";
import moment from "moment";

const ReservationCard = (props) => {

  const [arrivingin, setArrivingIn] = useState("Arriving in");

  function getArrivalMessage(checkinDateStr) {
    const checkinDate = moment(checkinDateStr).startOf('day');
    const today = moment().startOf('day');
    const differenceInDays = checkinDate.diff(today, 'days');
  
    let message;
    if (differenceInDays === 0) {
      message = 'Arriving today';
    } else if (differenceInDays === 1) {
      message = 'Arriving tomorrow';
    } else if (differenceInDays > 1) {
      message = `Arriving in ${differenceInDays} days`;
    } else {
      message = '';
    }
  
    return message;
  }
  
  useEffect(()=>{

  },[props])
 
    return (
      <CardWrapper wrapperClass="main-reservation-card">
        <div className="main-reservation-card-grid">
          <div className="">
            <p className="main-reservation-card-left-div-first-text">
              {props.guestName}
            </p>
            <p className="main-reservation-card-left-div-last-text">
              {/* 2 Guests, 1 Infant, 1 Pet */}
              {props.noGuest} {props.noInfant} {props.noPets}
            </p>
          </div>
  
          <NightText days={props.NightdaysCount} />
          <div>
            <p className="main-reservation-card-left-div-second-text">
              {/* Arriving in {props.arrivingin > 1 ? props.arrivingin+' days' : props.arrivingin+' day'} */}
              {getArrivalMessage(props.checkInDate)}
            </p>
            <p className="main-reservation-card-left-div-last-text">
             {props.propertName}
            </p>
          </div>
  
          
  
          <div className="flex justify-center" style={{flexDirection:'column'}}>
            <p className="main-reservation-card-right-div-para-text">
              Check-In & out: 
            </p>
            <p className="main-reservation-card-right-div-para-text">
              {props.checkInOut}
            </p>
          
          </div>
        </div>
       
      </CardWrapper>
    )


  
};

export default ReservationCard;
