import React from "react";
import AddToCart from "../../../../Components/common/addCarts";
import HeadingComp from "../HeadingComp";
import Button from "../../../../Components/common/Button";
import EditSaveBtn from "../EditSaveBtn";
import { useState } from "react";
import { useDispatch } from "react-redux";

const EditRooms_beds = (props) => {
  const [cartData, setCartData] = useState({});

  let url = "updatePropertyPref/" + props.preferencePropId;
  console.log(props.propId, "tulips");

  const dispatch = useDispatch()

  console.log(props.preferencePropId, "iiy");

  const bedData = props.allData?.beds;
  const bathData = props.allData?.bathrooms;
  const bedroomData = props.allData?.bedrooms;

  const handleUpdateBeds = (count) => {
    console.log(count);
    setCartData((prevData) => ({
      ...prevData,
      beds: count,
    }));
  };

  const handleUpdateBedrooms = (count) => {
    console.log(count);
    setCartData((prevData) => ({
      ...prevData,
      bedrooms: count,
    }));
  };

  const handleUpdateBathrooms = (count) => {
    console.log(count);
    setCartData((prevData) => ({
      ...prevData,
      bathrooms: count,
    }));
  };

  return (
    <>
    <div className="room-beds-counter " style={{marginTop:'2rem'}}>
   
      <div className="room-cart-div">
        <p>Bedrooms</p>
        <AddToCart
          cartBtn="cartBtn"
          cartCount="cartCount"
          cartParent="cartParent"
          initialCount={bedroomData}
          increamentVal={handleUpdateBedrooms}
        />
      </div>
      <div className="room-cart-div">
        <p>Beds</p>
        <AddToCart
          cartBtn="cartBtn"
          cartCount="cartCount"
          cartParent="cartParent"
          initialCount={bedData}
          increamentVal={handleUpdateBeds}
        />
      </div>
      <div className="room-cart-div">
        <p>Bathrooms</p>
        <AddToCart
          cartBtn="cartBtn"
          cartCount="cartCount"
          cartParent="cartParent"
          initialCount={bathData}
          increamentVal={handleUpdateBathrooms}
        />
      </div>
     
      {/* <div className="clear_apply-btn-box">
        <p onClick={() => props.closeModal(false)} className="clear-btn">
          Cancel
        </p>
        <EditSaveBtn
          url={url}
          method="patch"
          postData={cartData}
          fetchData={props.fetchData}
          closeModal={props.closeModal}
          btnText="Save"
          btnclasses="save-edit-btn"
        />
      </div> */}
    </div>

      <div className="clear_apply-btn-box paddingCustomYSide">
      <p onClick={() => props.closeModal(false)} className="clear-btn">
        Cancel
      </p>
      <EditSaveBtn
        url={url}
        method="patch"
        postData={cartData}
        fetchData={props.fetchData}
        closeModal={props.closeModal}
        btnText="Save"
        btnclasses="save-edit-btn"

        message={'Updated successfully'}
      />
      </div>

</>
  );
};

export default EditRooms_beds;
