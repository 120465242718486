import React from 'react'
import NightText from '../../../Components/common/nightsText'
import moment from 'moment'
import CardWrapper from '../../../Components/common/cardWrapper'

const PropertyTripDetailComponent = (props) => {  
  return (
    <CardWrapper wrapperClass="check-in-out-card">
    {/* Check-In Box */}
    <div className="check-in-out-box">
      <p className="check-text">Check-In</p>
      <p className="check-in-out-date">{moment(props.successdata?.checkin_date).format("DD MMMM YYYY")}</p>
      <p className="check-in-out-day">{moment.utc(props.successdata?.checkin_date).format("h:mm a")}, {moment(props.successdata?.checkin_date).format("dddd")}</p>
    </div>

    {/* Check-Out Box */}
    <div className="check-in-out-box">
      <p className="check-text">Check-Out</p>
      <p className="check-in-out-date">{moment(props.successdata?.checkout_date).format("DD MMMM YYYY")}</p>
      <p className="check-in-out-day">{moment.utc(props.successdata?.checkout_date).format("h:mm a")}, {moment(props.successdata?.checkout_date).format("dddd")}</p>
    </div>

    {/* NightText Component */}
    <NightText
      days={moment(props.successdata?.transaction_details[0].checkout_date).diff(moment(props.successdata?.transaction_details[0].checkin_date), "days")}
    />
  </CardWrapper>
  )
}

export default PropertyTripDetailComponent
