import { Controls, Player } from '@lottiefiles/react-lottie-player'
import React from 'react'

const NoDataLottie = (props) => {
  return (
    <div className='lottie-parent-div'>
      <Player
        autoplay
        loop
        style={{ height: props.height, width: props.width }}
        // src="./assets/lottie/notFound.json"
        src={props.jsonLotty}
      >
        <Controls
          visible={false}
          buttons={["play", "repeat", "frame", "debug"]}
        />
      </Player>  
    </div>
  )
}

export default NoDataLottie
