// SupportCard.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CardWrapper from '../../../Components/common/cardWrapper';
import Button from '../../../Components/common/Button';
import CancelReservationButton from './CancelReservationButton';

const SupportCard = (props) => {

    const navigate = useNavigate()
  const navgigateToContact = () => {
    navigate('/ContactUs');
  };

  return (
    <CardWrapper wrapperHeading='Support' wrapperClass='about-guest-card'>
      <div>
        {props.canCancel && <CancelReservationButton
        //  toggleVisibleDocks={toggleVisibleDocks}
         fetchData={props.fetchData}
         listingData={props.listingData}
         successdata={props.successdata}
         bookingOrderId={props.bookingOrderId}
         apicall={props.apicall}
         setApiCall={props.setApiCall}
         hostCancel={props.hostCancel}
        
        />}

        {/* <p className="underline mt-10 viewBreakdown">
          Change Reservation Dates
        </p> */}

        <p className="cancellation-text">If you need time, we are available 24/7</p>

        <Button
          btnclasses='save-edit-btn'
          btnText='Contact support'
          onClick={navgigateToContact}
        />
      </div>
    </CardWrapper>
  );
};

export default SupportCard;
