import React from "react";
import ProfileImage from "../../../Components/common/profileImg";
import GuestProfile from "../../../Components/common/guestProfile";
import { useState } from "react";
import RemoveUser from "../EditListing/CoHost/RemoveUser";
import WrapperPopup from "../../../Components/common/wrapperPopup";
import AddCoHost from "../EditListing/CoHost/AddCoHost";

const CoHostEditComponent = (props) => {
  const [openModalRemoveUser, setOpenModalRemoveUser] = useState(false);
  const [openModalAddUser, setOpenModalAddUser] = useState(false);


  const toggleVisibleDocks = () => {
 setOpenModalAddUser(false)
 setOpenModalRemoveUser(false)

  }; 
  
  return (
    <div className="pricing-detail-edit-content  listing-detail-edit-content ">
    
      <div className="edit-basic-details-main-div co-host-details">
        <h2>Co-Host</h2>

        {/*  */}
        {props?.listingData?.data[0]?.cohostid ? (
     <div className="basic-details-text-edit-div">
    <div className="text-edit-div-box">
      <GuestProfile
        profileName={props?.listingData?.data[0]?.cohost_details[0]?.fname + ' ' + props?.listingData?.data[0]?.cohost_details[0]?.lname}
        // whoseProfile={}
        profileImage={props?.listingData?.assets_url+props?.listingData?.data[0]?.cohost_details[0]?.image}
      />
    </div>
    <p
      onClick={() => setOpenModalRemoveUser(true)}
      className="basic-detail-remove-btn w-15"
    >
      Remove
    </p>
  </div>
   ) : (
    <div className="basic-details-text-edit-div">
    <div className="text-edit-div-box">
      <p className="para-text-co-host">
        This Property does not currently have assigned to co-host
      </p>
    </div>
    <p
      onClick={() => setOpenModalAddUser(true)
      }
      className="basic-detail-edit-btn w-15"
    >
      Add co-host
    </p>
  </div>
   )}




        <div className="light-line"></div>
      </div>

      <div className="co-host-details-popup">
        {openModalRemoveUser && (
          <WrapperPopup
          fluid={true}
          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
            closeModal={setOpenModalRemoveUser}
            comp={<RemoveUser 
              fetchData={props.fetchData}
              listingData={props.listingData.data}
              
              closeModal={setOpenModalRemoveUser}
               />}
          />
        )}
      </div>
      <div className="co-host-details-popup">
        {openModalAddUser && (
          <WrapperPopup
          fluid={true}
          defaultSize={"0.50"}
          dimStyle={false}
          isVisible={true} 
          setVisibleDocks={toggleVisibleDocks} 
          headingText='Invite CO-Host'

            closeModal={setOpenModalAddUser}
            comp={<AddCoHost
              fetchData={props.fetchData}
              listingData={props.listingData.data}
               closeModal={setOpenModalAddUser} />}
          />
        )}
      </div>

      {/*  */}
    </div>
  );
};

export default CoHostEditComponent;
