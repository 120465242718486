
import React, { useState } from "react";
import { DateRangePicker, isInclusivelyAfterDay, isInclusivelyBeforeDay } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import moment from 'moment';
import { useEffect } from "react";
import { fetchUrlFunc, updateUrlWithDatesFunc } from "../../utils/constant";
import { START_DATE, END_DATE } from 'react-dates/constants';
import { openSnackbar } from "../../utils/redux/SnackBarSlicer";
import { useDispatch } from "react-redux";

const DatePicker = (props) => {
  const isSmallScreen = window.innerWidth <= 500;
  const orientation = isSmallScreen ? "verticalScrollable" : undefined;
  const dispatch = useDispatch()

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState(null);
  
  const minDate = props.minDate !== undefined ? moment(props.minDate).endOf('day') : moment();
  const maxDate = props.maxDate !== undefined ? moment(props.maxDate).endOf('day') : moment().add(360, 'days').endOf('day')
  
  let maximumNights = props.maxNight || 365;
  let maximumEndDate = moment().add(maximumNights + 2, 'days');
  let condition;

  if (startDate) {
    maximumEndDate = startDate.clone().add(maximumNights + 1, 'days');
  }

  const isCheckInBlockedFunc = (day) => props.blockedDatesCheckin.some((date) => day.isSame(date, 'day'))

  const isCheckOutBlockedFunc = (day) => props.blockedDatesCheckout.some((date) => day.isSame(date, 'day'));
  const isBlockedFunc = (day) => props.blockedDates.some((date) => day.isSame(date, 'day'));

  const isBlocked = (day,po) => {
    if (focusedInput === START_DATE) {
      return (
        props.blockedDatesCheckin && 
        props.blockedDatesCheckin.some((date) => day.isSame(date, 'day'))
      );
    } else if (focusedInput === END_DATE) {
      return (
        props.blockedDatesCheckout && 
        props.blockedDatesCheckout.some((date) => day.isSame(date, 'day'))
      );
    } else {
      return (
        props.blockedDates && 
        props.blockedDates.some((date) => day.isSame(date, 'day'))
      );
    }
  };

  const onFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
    if (focusedInput === START_DATE) {
      setEndDate(null);
    } 
    if (focusedInput === END_DATE) {
      if(props.minNight > 1){
        dispatch(openSnackbar({ message: 'Minimum '+props.minNight+' nights booking required.', severity: 'error' }));
      }
    }
  };

  if (focusedInput === END_DATE) {
    condition = (day) => {
      if (!props.blockedDatesCheckin) {
        return false;
      }

      if(day.isBefore(startDate,'day')){        
        return true;
      }

      const isAfterMaximumEndDate = isInclusivelyAfterDay(day, maximumEndDate);      

      const nextBlockedDay = props.blockedDatesCheckin.find(
        (blockedDate) => moment(blockedDate).isAfter(startDate, 'day')
      );
        
      if (!nextBlockedDay) {
        return isAfterMaximumEndDate;
      }

      let maxCheckOut;
      if (new Date(maximumEndDate.format("YYYY-MM-DD")) > nextBlockedDay) {
        maxCheckOut = isInclusivelyAfterDay(day, moment(nextBlockedDay).add(1, 'days'));
      } else {
        maxCheckOut = isAfterMaximumEndDate;
      }
      return !isInclusivelyAfterDay(day, moment()) || maxCheckOut;
    };
  }

  if (focusedInput === START_DATE) {
    condition = (day) => !isInclusivelyAfterDay(day, moment());
  }

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    props.onDateChange(startDate, endDate);
    if(props.updateUrl === true) {
      updateUrlWithDatesFunc(startDate, endDate)
    }
  };
  
  useEffect(() => {
    if (props.startDate !== undefined && props.endDate !== null && props.endDate !== "null") {
      setStartDate(props.startDate ? moment(props.startDate) : null);
    }
    if (props.endDate !== undefined && props.endDate !== null && props.endDate !== "null") {
      setEndDate(
        props.endDate ? moment(props.endDate) : (props.startDate ? moment(props.startDate).add(1, "day") : null)
      );
    }
  }, [props.startDate, props.endDate]);

  const preventKeyboardOpen = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <DateRangePicker
        minDate={minDate}
        maxDate={maxDate}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        displayFormat="DD/MM/YYYY"
        startDatePlaceholderText={props.startDatePlaceholderText === false  ? '' : 'Check-in'}
        endDatePlaceholderText={props.endDatePlaceholderText === false  ? '' : 'Check-out'}
        startDateId="start_date"
        endDateId="end_date"
        isDayBlocked={isBlocked}
        isOutsideRange={condition}
        minimumNights={props.minNight || 1}
        orientation={orientation}
        daySize={45}
        showClearDates
        small
        readOnly  
        onClick={preventKeyboardOpen} 
      />
    </div>
  );
};

export default DatePicker;
