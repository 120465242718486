import React, { useRef } from "react";
import HostNav from "../../Components/common/hostNav";
import CardWrapper from "../../Components/common/cardWrapper";
import Button from "../../Components/common/Button";
import TrendingProperty from "../../Components/UserComponents/TrendingProperty";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { apiMainUser, apiPublicUser, useQuerryFunc } from "../../utils/constant";
import "swiper/css";
import SwiperCore, { Navigation } from "swiper";
import { useState } from "react";
import { useEffect } from "react";
import WrapperPopup from "../../Components/common/wrapperPopup";
import UserFilterPopup from "../../Module/User/UserFilterPopup";
import APIHit from "../../utils/apiHit";
import axios from "axios";
import { FilterGet } from "../../utils/redux/FilterSlicer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ButtonPopup from "../../Components/common/ButtonPopup";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MobHeadNav from "../../Module/User/MobHeadNav";
import Mob_destination_guest_popup from "../../Module/User/MobHeadDestinaionGuest";
import MobHeadDestinationGuest from "../../Module/User/MobHeadDestinaionGuest";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SkeletonLoader from "../../Components/common/SkeletonLoader";
import SkeletonTrendingProperty from "../../MobSkeleton/SkeletonTrendingProperty";
import NoDataLottie from "../../Components/common/NoDataLottie";
import { useInView } from "react-intersection-observer";
import { type } from "@testing-library/user-event/dist/type";
import { setFilterParams } from "../../utils/redux/ParamsSlicer";
SwiperCore.use([Navigation]);


const UserListingPage = (props) => {
  const [widthInPixels, setWidthInPixels] = useState(0);
  const [apicall, setApiCall] = useState(false);

  const navigate = useNavigate()
 
  const location = useLocation();

  console.log(location,'checkAllLocation')

  const [sortingOption, setSortingOption] = useState("");
  // const [sortingMobileOption, setSortingMobileOption] = useState("");

  const [selectedFilterText, setSelectedFilterText] = useState("Sort");

  
  const isSmallScreen = window.innerWidth <= 500;


  

  ///fetching data from selector----------
  const storeSel = useSelector((state) => state);

  



  

  const dispatch = useDispatch()
  const [checkin, setCheckIn] = useState();
  const [checkout, setCheckOut] = useState();
  const [guestCount, setGuestCount] = useState(null);
  const [infant, setInfantCount] = useState(null);
  const [adults, setChildrenCount] = useState(null);
  const [children, setAdultCount] = useState(null);
  const [spaceType, setSpaceType] = useState(null);

  

  const dynamiclocation = useLocation();



const parseQueryParamsAll = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const checkin_date = queryParams.get('checkin');
  const checkout_date = queryParams.get('checkout');
  const adults_count = queryParams.get('adults');
  const infants_count = queryParams.get('infants');
  const children_count = queryParams.get('children');
  const space_type = queryParams.get('space_type')

  setCheckIn(checkin_date);
  setCheckOut(checkout_date);
  setInfantCount(infants_count);
  setChildrenCount(children_count);
  setAdultCount(adults_count);
  setGuestCount(
    parseInt(adults_count, 10) + parseInt(children_count, 10)
  );
  setSpaceType(space_type)
};







 
  const [currentLocation, setCurrentLocation] = useState();

  useEffect(()=>{   
    parseQueryParamsAll();
    if(dynamiclocation.search.includes('?')){
      var savelocation = dynamiclocation.search.split('?')
      setCurrentLocation(savelocation[1])
    }
  },[dynamiclocation])

  // const linkTo = `/properties${currentLocation && currentLocation !== '' ? '?'+currentLocation : ''}`;

 
  ///place type filter---------------------------------------------------
  const [placeTypes, setPlaceTypes] = useState([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  
  
  const isCardSelected = (uid) => selectedPlaceUids.some(selectedUid => String(selectedUid) === String(uid));
  const [selectedPlaceUids, setSelectedPlaceUids] = useState([]);


  console.log(selectedPlaceUids,)


const handleCardClick = (index) => {
  const selectedPlaceUid = String(placeTypes[index].uid);

  console.log(selectedPlaceUid,'checkOnceAll')


  setSelectedPlaceUids((prevSelectedUids) => {
    const isAlreadySelected = prevSelectedUids.includes(selectedPlaceUid);
    console.log(isAlreadySelected,'checkOnceAll')
    let updatedSelectedUids;

    if (isAlreadySelected) {
      // Unselect the card
      updatedSelectedUids = prevSelectedUids.filter((uid) => uid !== selectedPlaceUid);
    } else {
      // Select the card
      updatedSelectedUids = [...prevSelectedUids, selectedPlaceUid];
    }

    console.log(updatedSelectedUids,'checkOnceAll')
    const queryParams = new URLSearchParams(window.location.search);
    if (updatedSelectedUids.length > 0) {
    console.log(updatedSelectedUids,'checkOnceAll')

      queryParams.set("place_type", updatedSelectedUids.join(","));
    } else {
      queryParams.delete("place_type");
    }

    const newUrl = queryParams.toString()
      ? `${window.location.pathname}?${queryParams.toString()}`
      : window.location.pathname;

    window.history.replaceState({}, "", newUrl);

    // Log for debugging
    console.log("Selected Place UID:", selectedPlaceUid);
    console.log("Previous Selected UIDs:", prevSelectedUids);
    console.log("Updated Selected UIDs:", updatedSelectedUids);

    
    return updatedSelectedUids;
  });
};


console.log(selectedPlaceUids,'checkOnceAll')





useEffect(() => {
  const parseQueryParamsAndUpdateState = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const placeTypesParam = queryParams.get('place_type');

    if (placeTypesParam) {
      const selectedPlaceUids = placeTypesParam.split(',');
      setSelectedPlaceUids(selectedPlaceUids);
    } else {
      setSelectedPlaceUids([]);
    }
  };

  parseQueryParamsAndUpdateState();
}, [location.search, location]);




console.log(spaceType,'checkSpaceType')




///------------------------------------------

const placeAllUid = selectedPlaceUids?.join(',')
  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const percentage = 90;
      const widthInPixels = (viewportWidth * percentage) / 100;
      setWidthInPixels(widthInPixels);
    };

    handleResize(); // Initial calculation
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);




  const fetchData = async () => {
   
    try {
      const response = await axios
        .get( apiPublicUser + "read_place_type?block=0", {
          // headers: {
          //   Authorization: "Bearer " + localStorage.getItem("token"),
          // },
        })
        .then((response) => {
          // setUsersData(response.data.data[0]);
          setPlaceTypes(response.data.data);
          
        });
    } catch (error) {
    }
  };


  

  const [openModal, setOpenModal] = useState(false);
  const [openModalNav, setOpenModalNav] = useState(false);

  const [successdata, setSuccessData] = useState([]);


  const fetchDataFilter = async () => {
   
    try {
      const response = await axios
        .get( apiPublicUser + "all_filters", {
        
        })
        .then((response) => {
          dispatch(FilterGet(response.data.data))
         
       
          
        });
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchDataFilter();
    fetchData();
   
  }, []);

  
  const [firstEffectCompleted, setFirstEffectCompleted] = useState(false);


  useEffect (() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sortParam = queryParams.get("sort");
     if (sortParam) {
       setActiveSortingOption(sortParam);
       setSortingOption(sortParam);
     }
     dispatch(setFilterParams({query:undefined}));
     setFirstEffectCompleted(true);
  
      }, [window.location]);



  useEffect(() => {
    const reduxParam = storeSel.paramDataSlice.query;
    console.log(reduxParam,'checkValueParamNow')

    if(firstEffectCompleted &&  reduxParam && reduxParam !== undefined && reduxParam !== "undefined" ){
      console.log("empty object")
      const newPath = reduxParam ? `${location.pathname}?${reduxParam}` : location.pathname;


      console.log(newPath, 'checkNewPath');
      
      window.history.replaceState({}, "", newPath);
    }

  }, [storeSel.paramDataSlice.query,firstEffectCompleted]);

  console.log(location,'checkLocationAll')


  const fetchDataProperty = async () => {
    try {
        const queryParams = new URLSearchParams(window.location.search);
        const sortParam = queryParams.get("sort");
        const locationParam = queryParams.get("location");


        // Construct params object with default values
        const params = {
            from_date: queryParams.get('checkin') || '',
            to_date: queryParams.get('checkout') || '',
            max_guests: queryParams.get('adults') || '',
            base_price: queryParams.get('base_price') || '',
            ceil_price: queryParams.get('ceil_price') || '',
            place_type: queryParams.get('place_type') || '',
            beds: queryParams.get('beds') || '',
            bathrooms: queryParams.get('bathrooms') || '',
            bedrooms: queryParams.get('bedrooms') || '',
            amenities: queryParams.get('amenities') || '',
            space_type: queryParams.get('space_type') || '',
            property_type: queryParams.get('property_type') || '',
            sortby: sortParam || sortingOption, // Use sortParam from URL if available, otherwise use sortingOption state
            location:locationParam || '' 
        };

        if (selectedPlaceUids.length > 0) {
            params.place_type = selectedPlaceUids.join(',');
        }

        // Remove empty properties from params object
        Object.keys(params).forEach(key => params[key] == '' && delete params[key]);

        const response = await axios.get(apiPublicUser + "search_property", {
            params,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        if (response.status === 200 && response.data.data.length > 0) {
            setSuccessData(response.data.data);
        } else {
            setSuccessData("No Property Found");
        }
    } catch (error) {
        setSuccessData("No Property Found");
        console.error("Error fetching data:", error);
    }
};


    console.log(successdata,'checkLocationOnce')
  
    
  
    const dependencies = window.innerWidth > 500
  ? [spaceType, selectedPlaceUids, storeSel.paramDataSlice,sortingOption,window.location]
  : [ spaceType, selectedPlaceUids, storeSel.paramDataSlice,window.location];

  
    

    useEffect(() => { 
      fetchDataProperty();

      },dependencies)

  
  //sorting filter-------------------------------------------------
  const [activeSortingOption, setActiveSortingOption] = useState(null);

  

 
  const isOptionActive = (option) => {
    return sortingOption === option ? "active-sort" : "";
  };
  

  const handleSortingOptionClick = (option) => {
    const queryParams = new URLSearchParams(window.location.search);
  
    if (sortingOption === option) {
      setSortingOption("");
      queryParams.delete("sort");
    } else {
      setSortingOption(option);
  
      if (window.innerWidth > 500) {
        queryParams.set("sort", option);
      }
    }
  
    const newUrl = queryParams.toString()
      ? `${window.location.pathname}?${queryParams.toString()}`
      : window.location.pathname;
  
    window.history.replaceState({}, "", newUrl);
  
  };

  
  const fetchDataOnSave = () => {

    const queryParams = new URLSearchParams(window.location.search);
  
    if (window.innerWidth < 500 && sortingOption) {
      queryParams.set("sort", sortingOption);
      const newUrl = queryParams.toString()
        ? `${window.location.pathname}?${queryParams.toString()}`
        : window.location.pathname;
      window.history.replaceState({}, "", newUrl);
    }
  
    let filterText = "";
    switch (sortingOption) {
      case "booking":
        filterText = "Most Booked";
        break;
      case "low2high":
        filterText = "Price: Low to High";
        break;
      case "high2low":
        filterText = "Price: High to Low";
        break;
      default:
        filterText = "Type";
        break;
    }
  
    setSelectedFilterText(filterText);
    fetchDataProperty();
  };
  
          
          
          
    
 
//-------------------------------------------------------------------


const [isVisible, setIsVisible] = useState('');



    const toggleVisibleDocks = () => {
      setOpenModalNav((prevVisible) => !prevVisible);
    };
    const toggleVisibleDocksFilter = () => {
      setOpenModal((prevVisible) => !prevVisible);
    };





const handleUniqueID = (uniqueID) => {
  // setModifiedId(uniqueID)

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        console.log(entry, 'checkInter');

        if (entry.isIntersecting) {
          console.log(entry.isIntersecting, 'checkInter');
          // setIsVisible(true);
          setIsVisible(entry.target.id === uniqueID ? uniqueID : '');
          // setModifiedId(entry.target.id === uniqueID ? uniqueID : '')
        } 
      });
    },
    { threshold: 0.4 }
  );

  const targetElement = document.getElementById(uniqueID);

      if (targetElement) {
        observer.observe(targetElement);
      }

    // Clean up observer
      return () => {
        if (targetElement) {
          observer.unobserve(targetElement);
        }
      };

    };

    
 
 

  return (
    <>
     {isSmallScreen && <MobHeadNav
   
   
      filterRequired={true} 
      onClickFilter={() => setOpenModal(true)}
      onClickSearch={() => setOpenModalNav(true)}
      position=""
      />
    }

   {/*  */}
    <div className="user-listing-page">
      {/* <HostNav /> */}
      <div className={isSmallScreen ? "listing-amenities-filter-box sticky-t-101 z-index-9" : "listing-amenities-filter-box sticky-t-93 z-index-9"}>
        {/* <CardWrapper wrapperClass="filter-amenities-all-home-icon-card">
          <span>
            <img src="./assets/img/allHomesIcon.svg" alt="" />
          </span>
          <span>All Homes</span>
        </CardWrapper>
        <div className="partition-line"></div> */}

        <div
          style={{ width: ` ${widthInPixels}px` }}
          className="filter-amenities-icon-card-flex-box"
        >
         <Swiper
          breakpoints={{
           
            500: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 4,
            },
           
            1024: {
              slidesPerView: 10,
            },
            
          }}
         
        spaceBetween={10}
        slidesPerView={Math.floor(widthInPixels / 100) - 1}
        navigation = {!isSmallScreen ? true : false}
       
      >
        

   <div className="wrapFilterSwipe">
     {placeTypes.map((placeType, index) => (
       <SwiperSlide key={placeType.uid}>
        <CardWrapper
          wrapperClass={`filter-amenities-icon-card ${
           isCardSelected(String(placeType.uid)) ? "selected-card" : ""
            }`}
          onClick={() => {
          setSelectedCardIndex(index);
          handleCardClick(index);
          }}
        >
         <span>
           <img src={placeType.icon_url} alt="" />
          </span>
         <span className="icon-text">{placeType.placetype_name}</span>
         </CardWrapper>
         </SwiperSlide>
        ))}
    </div>


      </Swiper>
        </div>
        
        {/* {!isSmallScreen &&
        <Button
        onClick={() => setOpenModal(true)}
        btnclasses="amenities-filter-btn"
        btnText="Filter"
      />
        } */}
        
      </div>


      <div className="sorting-trending-property-main-box">
        <h2>{props.homeNum}</h2>
        <div className="sorting-trending-property-flexbox home-trending-flex-box">
        <CardWrapper 
               wrapperClass={`trending-property-sort-card sticky-t-180 flex flex-flow-column  ${
                window.innerWidth > 500 ? 'show' : 'hide'
              }`}

              wrapperHeading='Sort By'
              wrapperHeadingClass='sort-heading-text '
              >
        
            <Button
            onClick={() => handleSortingOptionClick("booking")}
            btnclasses={`tab-btn-hover ${isOptionActive("booking")}`}
            btnText="Most Booked" />


            {/* <Button btnclasses="tab-btn-hover" btnText="User ratings" /> */}
            <Button
            onClick={() => handleSortingOptionClick("low2high")}
            btnclasses={`tab-btn-hover ${isOptionActive("low2high")}`}
           btnText="Price : Low to High" />

            <Button
            onClick={() => handleSortingOptionClick("high2low")}
            btnclasses={`tab-btn-hover ${isOptionActive("high2low")}`}
            btnText="Price : High to Low" />


          </CardWrapper>
          {
            window.innerWidth < 500 ?
          <ButtonPopup
          buttonPopup={true}
        
        
        wrapclass={`absolute custom-mob-filter-card`}
        showCloseButton={true}
        // closeBtnText="Cancel"
        
        closeBtnText="Cancel"
        showApplyButton={true}
        applyBtnText="Save"
       
        
        
        // onApply={fetchDataPropertySort}
        onApply={fetchDataOnSave}
// 
        divContent={
          <>
        <Button
            onClick={() => handleSortingOptionClick("booking")}
            btnclasses={`tab-btn-hover flex justify-start w-100 ${isOptionActive("booking")}`}
            btnText="Most Booked" />


            {/* <Button btnclasses="tab-btn-hover" btnText="User ratings" /> */}
            <Button
            onClick={() => handleSortingOptionClick("low2high")}
            btnclasses={`tab-btn-hover  w-100 ${isOptionActive("low2high")}`}
           btnText="Price : Low to High" />

            <Button
            onClick={() => handleSortingOptionClick("high2low")}
            btnclasses={`tab-btn-hover  w-100 ${isOptionActive("high2low")}`}
            btnText="Price : High to Low" />


          </>
        }
        makeCustom={
          <button className={`custom-filter-btn w-100`}>
          {selectedFilterText}
          <FontAwesomeIcon icon={faAngleDown} className="arrow-icon-filter" />

        </button>
        }
        
      /> : null}
      

         
            
  {successdata === "No Property Found" ? (
    <div>
    <NoDataLottie jsonLotty='./assets/lottie/tripnotFound.json' height="200px" width="300px"/>
    
    </div>

  ) : (
<div className="all-trending-property-box main-section">
  {/* Rendering the property list */}
  {successdata
    ? successdata.length > 0 &&
      successdata?.map((data, index) => {
        console.log(data,'checkListData'); // Log the data
        const amenitiesToDisplay = data.amenities !== null
          ? data.amenities_details.slice(0, 3)
          : [];

        return (
          <TrendingProperty
            index={index}
            isVisible={isVisible}
            handleUniqueID={handleUniqueID}
            property_name={data.property_name}
            img={
              data.images && data.images.length > 0
                ? data.base_url_image + data.images[0]
                : "./assets/img/notFoundImage.png"
            }
            swiperSlides={
              data.images && data.images.length > 0
                ? data.images.map((imgUrl, index) => ({
                    imgUrl: data.base_url_image + imgUrl,
                    altText: `Slide ${index + 1}`,
                  }))
                : [
                    {
                      imgUrl: "./assets/img/notFoundImage.png",
                      altText: "Not Found Image",
                    },
                  ]
            }
            priceValue={data.base_price}
            location={data.city + ", " + data.state}
            maxguest={data.max_guests}
            rooms={data.bedrooms}
            beds={data.beds}
            bath={data.bathrooms}
            amenities={amenitiesToDisplay}
            amenitiesCount={data.amenities_details
              .length - 3}
            slug={
              "/properties/" +
              data.city +
              "/" +
              data.slug +
              (currentLocation && currentLocation !== '' ? '?' + currentLocation : '')
            }
            reviewNum={data?.review_count}
            starNum={data?.Overall_rating}
            WishListpropID={data.propertyid}
            wishListApplied={data.wishlist}
          />
        );
      })
    : successdata?.slice(0, 5).map((data) => {
        console.log(data); // Log the data
        return <SkeletonTrendingProperty />;
      })}
</div>



)}




        </div>
      </div>
      <div className="filter-popup-main-div">
      {openModal && (
        <WrapperPopup
        fluid={true}
        DockFilter={true}
        headingText ='Filters'
        closeModal={setOpenModal}
        position='bottom'
        // dimMode={props.opaque ? props.opaque : false}
        defaultSize={"0.85"}
        dimStyle={false}
        isVisible={true}
        // btnClass={false}
        setVisibleDocks={toggleVisibleDocksFilter}
  
         comp={
         <UserFilterPopup
        //  appendQueryParamFunc={appendQueryParam}
         closeModal={setOpenModal}
        //  queryParams={queryParams}
            
            
            />
          } />
      )}
      </div>
       
       <div className='Mob-destination-guest-popup-div'>
          {openModalNav && (
        <WrapperPopup
        fluid={true}
        position='bottom'
        headingText='Select Date and Guest'
        DockFilter={true}
        // dimMode={props.opaque ? props.opaque : false}
        defaultSize={"0.90"}
        dimStyle={false}
        isVisible={true}
        setVisibleDocks={toggleVisibleDocks} 
         closeModal={setOpenModalNav}

          comp={<MobHeadDestinationGuest  closeModal={setOpenModalNav}/>}
           />
      )}
      </div>

    </div>
    </>
  );
};

export default UserListingPage;
