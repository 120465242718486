import React, { useState } from "react";
import Switch from "react-switch";

const SwitchExample = (props) => {
// const [checked, setChecked] = useState(false);

// const handleChange = (checked) => {
//   setChecked(checked);
// };

// const onChangeFunc = props.onChange()
  return (
    <label className="switch-label">
      {/* <span>Switch with default style</span> */}
      <Switch
        className={props.swicthClass}
        // onChange={handleChange}
        onChange={props.onChange !== undefined ? props.onChange : null}

        checked={props.checked}
        height={18}
        width={38}
      />
      {props.labelText && <span>{props.labelText}</span>}
    </label>
  );
};

export default SwitchExample;
