import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { setFailedImages, setSelectedImages } from "../../utils/redux/dropboxSLicer";
import { apiMainUser } from "../../utils/constant";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { setEditFailedImages, setEditSelectedImages } from "../../utils/redux/EditDropBoxSlicer";

const UploadImgComp = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const storeSel = useSelector((state) => state);
    const PropertyId = storeSel.apiDataSlice?.propId
        

    const [propId,setPropId]=useState(null)
    // const PropIddata = storeSel.apiDataSlice?.propId;
    
    const failedRedux = storeSel.imageSlice?.failedImages;
    

    const [counter, setCounter] = useState(0);
    const [filelength, setFileLength] = useState(0);
    const [isUploading, setIsUploading] = useState(false); // State to manage the uploading status


    const location = useLocation(); 

  
         


    const dispatchFailedImages = async (failedImage) => {
        dispatch(setFailedImages(failedImage));

    };

    const dropzoneStyle = {
        border: '2px dashed #000',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    };

    const checkConstraints = (file) => {
        // check file size constraint
        const fileSizeLimit = 10 * 1024 * 1024; // 10MB limit
        if (file.size > fileSizeLimit) {
            return false;
        }
        return true;
    };

    const callApiFunction = async (file) => {
    
       
           
        console.log("m at undefined")

        const formData = new FormData();
        formData.append("property_id", PropertyId );


        formData.append("file", file);

        try {
            const response = await axios.post(apiMainUser + 'upload_property_images', formData, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data',
                },
            });

            const newImageUrl = response.data.data.all_images;
            const successImage = newImageUrl.map((url) => ({
                uploadFile: url,
                localFile: file,
                message: 'Upload successful',
                status: 'success',
            }));
       
                dispatch(setSelectedImages(successImage));
         
            return successImage;
        } catch (error) {
            const failedImage = {
                uploadFile: URL.createObjectURL(file),
                localFile: file,
                message: error.message,
                status: 'failed',
            };
            return failedImage;
        }
    };

    const uploadFilesLoop = async (files) => {
        setIsUploading(true); 
        const failedImagesArray = [...failedRedux]; // Initialize an empty array here
       

        for (const file of files) {
            const okayForAPI = checkConstraints(file);
            if (okayForAPI) {
                const apiresponse = await callApiFunction(file);
                if (apiresponse?.status === "failed") {
                    failedImagesArray.push(apiresponse);
                }
            } else {
                const newFailedImage = {
                    uploadFile: URL.createObjectURL(file),
                    localFile: file,
                    message: "File size exceeds limit",
                    status: "failed",
                };
                failedImagesArray.push(newFailedImage);//pushing here the failed objects
            }
            setCounter((prevCounter) => prevCounter + 1);
        }
        // Dispatch the array containing all the failed images
        await dispatchFailedImages(failedImagesArray);
        setIsUploading(false); // Set the uploading status to false once the upload is complete
    };

    const onDrop = useCallback(
        async (acceptedFiles) => {
            setFileLength(acceptedFiles.length);
            await uploadFilesLoop(acceptedFiles);
        },
        [checkConstraints, callApiFunction, uploadFilesLoop]
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div className="w-100" {...getRootProps()} style={dropzoneStyle}>
            <input {...getInputProps()} />

            {isUploading ? ( 
                <p>
                    Uploading images {counter}/{filelength}
                </p>
            ) : ( 
                <div className="upload-icon">
                    <FontAwesomeIcon icon={faPlus} />
                </div>
            )}
        </div>
    );
};

export default UploadImgComp;
