import React, { useEffect } from "react";
import Button from "../../../Components/common/Button";
import { Controller, useForm } from "react-hook-form";
import InputComp from "../../../Components/common/textInput";
import SwitchExample from "../../../Components/common/switch";
import { useState } from "react";
import FontComp from "../../../Components/common/fontComp";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import APIHit from "../../../utils/apiHit";
import { apiMainUser, apiPublicUser, checkErrors } from "../../../utils/constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fullNAmAction, fullNameAction, getImage, getUid, usernameAction } from "../../../utils/redux/HostDetailSlice";
import axios from "axios";
import { openSnackbar } from "../../../utils/redux/SnackBarSlicer";

const Auth = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [logindata, setLoginData] = useState("");
  const [showerror, setError] = useState(null);

  

  const dispatch = useDispatch()
  const navigate = useNavigate();


  
  const location = useLocation();



  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    console.log(queryParams,'checkQuerry')

    const myToken = queryParams;
    setLoginData(myToken)

    if (myToken) {

      
      
      axios({
        method: "get",
        url: apiPublicUser + "verify?"+myToken,
      
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((response) => {
          console.log(response.data,"123");
          if (response.data.status === "success") {
            handleSuccess(response.data)
            dispatch(openSnackbar({ message: 'successful!', severity: 'success' }));
            // console.log()
            window.close()
          } else {
            console.log('1235')

          }
        })
        .catch((error) => {
         console.log(error.response.data.message,'12345')
          console.error(error,'1236');
          setError(error.response.data.message)
          window.close(); 
          dispatch(openSnackbar({ message: 'Something went wrong,Try another Method', severity: 'error' }));


        });
    }
  }, []);


 

  const handleSuccess = (res) => {
    console.log(res, "rrr");
    let resdata = res.data;
    localStorage.setItem("login", true);
    // localStorage.setItem("fullname", resdata.fname + " " + resdata.lname);
    dispatch(fullNameAction(resdata.fname + " " + resdata.lname))
    dispatch(usernameAction(resdata.username))
    dispatch(getImage(resdata.image))
    dispatch(getUid(resdata.uid))

    // localStorage.setItem("username", resdata.username);
    localStorage.setItem("token", resdata.token);
    // navigate("/");
  };



  return (
    <div>
      {/* {showerror} 
      {logindata} */}
    </div>
  );
};

export default Auth;

