
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiMainUser } from "../../utils/constant";
import { Player } from "@lottiefiles/react-lottie-player";
import Jump from 'react-reveal/Jump';
import HeadShake from 'react-reveal/HeadShake';
import Jello from 'react-reveal/Jello';
import { openSnackbar } from "../../utils/redux/SnackBarSlicer";
import { useDispatch } from "react-redux";


const WishListIcon = ({ id, wishListApplied,fetchwishData }) => {
  const [isInWishlist, setIsInWishlist] = useState();
  const [shouldApplyJump, setShouldApplyJump] = useState(wishListApplied === 0);

  const isSmallScreen = window.innerWidth <= 500;

  const dispatch = useDispatch()

  useEffect(()=>{    
    setIsInWishlist(wishListApplied === 1)
    setShouldApplyJump(wishListApplied === 0)
  },[wishListApplied])


  console.log('called',wishListApplied,id);
  

  const handleToggleWishlist = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  
    if (isInWishlist) {
      try {
        await axios.delete(apiMainUser + `remove_from_wishlist/${id}`, config);
        setIsInWishlist(false);
        // fetchwishData()
        dispatch(openSnackbar({ message: 'Removed from wishlist successfully!', severity: 'success' }));
      } catch (error) {
        console.error("Error occurred during delete API call:", error);
        if (error.response && error.response.data && error.response.data.message === "Please Authorize!") {
          dispatch(openSnackbar({ message: 'Please Login to remove from wishlist.', severity: 'error' }));
        } else {
          dispatch(openSnackbar({ message: 'Failed to remove from wishlist. Please try again.', severity: 'error' }));
        }
      }
    } else {
      try {
        await axios.post(apiMainUser + `add_to_wishlist/${id}`, {}, config);
        setIsInWishlist(true);
        
        dispatch(openSnackbar({ message: 'Added to wishlist successfully!', severity: 'success' }));
      } catch (error) {
        console.error("Error occurred during add API call:", error);
        if (error.response && error.response.data && error.response.data.message === "Please Authorize!") {
          dispatch(openSnackbar({ message: 'Please Login to add to wishlist.', severity: 'error' }));
        } else {
          dispatch(openSnackbar({ message: 'Failed to add to Wishlist. Please try again.', severity: 'error' }));
        }
      }
    }
  
    setShouldApplyJump(!isInWishlist);
  };
  


  return (
    <div className="wishlist-box" onClick={handleToggleWishlist}>
      {shouldApplyJump ? (
        isSmallScreen ? (
        <Jump>          
          <img
            className={`wishtlist-heart-img ${isInWishlist ? 'visible' : 'hidden'}`}
            src="./assets/img/RedHeart.svg"
            alt=""
          />
        </Jump>
        ): (
          <Jello>          
          <img
            className={`wishtlist-heart-img ${isInWishlist ? 'visible' : 'hidden'}`}
            src="./assets/img/RedHeart.svg"
            alt=""
          />
        </Jello>
        )
      ) : (
        <img
          className={`wishtlist-heart-img ${isInWishlist ? 'visible' : 'hidden'}`}
          src="./assets/img/RedHeart.svg"
          alt=""
        />
      )}
      <img
        className={`wishlist-svg-img ${!isInWishlist ? 'visible' : 'hidden'}`}
        src="./assets/img/wishListImg.svg"
        alt=""
      />
    </div>
  );
};

export default WishListIcon;
