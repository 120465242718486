import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputComp from "../../../Components/common/textInput";
import TextareaComp from "../../../Components/common/textArea";
import { useNavigate } from "react-router-dom";
import APIHit from "../../../utils/apiHit";
import { apiMainUser, capitalizeText, checkErrors } from "../../../utils/constant";
import DataContext from "../../../utils/dataContext";
import { useDispatch } from "react-redux";
import {
  setApiData,
  setPropId,
  setPropName,
  setPropertyName,
} from "../../../utils/redux/idSlice";
import useEncryptedSessionStorage from "../../../utils/sessionUtil";
import { setPropertyId } from "../../../utils/redux/listingTypeSlice";
import { setImagePropertyId } from "../../../utils/redux/dropboxSLicer";
import { setCartPropertyId } from "../../../utils/redux/CounterSlice";
import { setPricingCartId } from "../../../utils/redux/PricingCounter";
import { useSelector } from "react-redux";
import {
  setCartCommonIndex,
  setCommonIndex,
  setImageCommonIndex,
  setPricingCommonIndex,
} from "../../../utils/redux/commonIndex";
import { capitalize } from "@mui/material";

const PlaceTitle_disc = () => {
  const storeSel = useSelector((state) => state);

  const ReduxName = storeSel.apiDataSlice.propertyName;
  const ReduxPropId = storeSel.apiDataSlice?.propId;

  const [listingTitleData, setlistingTitleData] = useState("");
  const [id, setID] = useState();
  const [propertyId, setPropertyId] = useState(storeSel.apiDataSlice?.propId);

  // const [change, setChange] = useState(false);

  const [showerror, setError] = useState(null);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const Currenturl = window.location.href;
  console.log(Currenturl, "tyt");
  const CurrentPage = new URL(Currenturl);
  const path = CurrentPage.pathname;
  console.log(path, "uuu");

  const onSubmit = (data) => {
    let finaldata = {
      // signinOptions: "direct",
      // property_name: data.title,,
      // userid: 29,list
      list_age: path,
      ...data,
    };
    // console.log();
    setlistingTitleData(finaldata);
  };
  console.log(listingTitleData, "pity");

  // const dispacthFunc = () => {
  //   dispatch(setPropertyName(listingTitleData.property_name));
  // };
  // const navigateTo = () => {
  //   navigate("/host/createlisting/property");
  // };

  const preCondition = () => {
    let ercheck = checkErrors(errors);
    console.log(ercheck, "djjd");
    return ercheck;
  };

  const handleSuccess = (res) => {
    console.log(res, "roy");
    // console.log(res.data.create_property.insertId, "bro");
    // setID(res.data.create_property.insertId);

    // sessionStorage.setItem("property", res.data.create_property.insertId);
    if (res.status === "success") {
      console.log(res.status, "roy1");
      dispatch(setPropName(listingTitleData.property_name));
      if (ReduxPropId == null || ReduxPropId == undefined) {
        dispatch(setPropId(res.data.create_property.insertId));
      }
      

      // dispatch(
      //   setPropertyId({
      //     propertyId: res.data.create_property.insertId,
      //   })
      // );
      // dispatch(
      //   setCartPropertyId({
      //     propertyId: res.data.create_property.insertId,
      //   })
      // );
      // dispatch(
      //   setPricingCartId({
      //     propertyId: res.data.create_property.insertId,
      //   })
      // );
      navigate("/host/createlisting/property");
    }
  };

  const handleFailure = (res) => {
    console.log(res);
    if (res && res.status === 404) {
      setError("");
    } else {
      setError("");
    }
  };
  console.log(id, "poo");

  useEffect(() => {
    console.log(id, "poo"); // You can access the updated id here
  }, [id]);

  // const data = useContext(DataContext); use this code when required insert id in other pages--

  return (
    <div className="place-title-disc-box">
      <div className="create-list-place-title-disc-content">
        <h2>Set Your Place Apart With A Captivating Title</h2>
        <p>
          Short titles work best. Have fun with it – you can always change it
          later.
        </p>
        <form
          className="confirm-address-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="single-input-box">
            <Controller
              control={control}
              name="property_name"
              defaultValue={ReduxName !== null ? ReduxName : ""}
              rules={{
                required: "Title is required",
              }}
              render={({ field: { onChange, value } }) => (
                <InputComp
                  inputType="text"
                  inputClass="title-input"
                  name="property_name"
                  inpPlace="Enter your title here..."
                  onKeyUp={onChange}
                  defaultValue={ReduxName !== null ? ReduxName : ""}
                  value={value}
                  // defa
                />
              )}
            />
            {errors.property_name && <span className="error-text">{errors.property_name.message}</span>}
          </div>
          {ReduxName === listingTitleData.property_name ? (
            <div
              className="bottom-btns-fixed-box"
              onClick={navigate("/host/createlisting/property")}
            >
              <button className="common-next-btn">Next</button>
            </div>
          ) : (
            <APIHit
              handleSubmit={handleSubmit(onSubmit)}
              url={
                ReduxName !== null
                  ? apiMainUser + "updateProperty/" + propertyId
                  : apiMainUser + "create_property"
              }
              apidata={listingTitleData}
              successResponseCode={ReduxName !== null ? 200 : 201}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              precondition={preCondition}
              method={ReduxName !== null ? "patch" : "post"}
              auth={true}
            >
              <div className="bottom-btns-fixed-box">
                {/* <button type="submit" className="save-exit-btn">
                Save & Exit
              </button> */}
                <button className="common-next-btn">Next</button>
              </div>
            </APIHit>
          )}
          {/* )} */}
        </form>
      </div>
    </div>
  );
};

export default PlaceTitle_disc;
