import React, { useState } from 'react'
import Button from '../../../Components/common/Button';
import { useForm } from 'react-hook-form';
import HostEmailOtp from './HostEmailOTP';
import WrapperPopup from '../../../Components/common/wrapperPopup';
import axios from 'axios';
import { apiMainUser, capitalizeText } from '../../../utils/constant';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../utils/redux/SnackBarSlicer';

const HostMailEdit = (props) => {

    const [openOtpModal,setOpenOtpModal] = useState(false)
    const [apiSuccess, setApiSuccess] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
      const [emaildata, setEmailData] = useState(null);
      // border: 2px dashed #ccc;
      // padding: 20px;
      // text-align: center;
      // margin-top: 50px !important;
      // width: 60%;
      // position: relative;
      // margin: auto;

  const dispatch = useDispatch()

      const onSubmit = async (data) => {
        
        try {
          const response = await axios({
            method:'post',
            url: apiMainUser + 'send_otp',
            data: {
              email: data.email,
            
            },
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            
          }
          
          );
          console.log(response.data);
          if (response.data.status === 'success') {
            setApiSuccess(true);
            setEmailData(data.email)
            
          } 
        } catch (error) {
          console.log(error)
          dispatch(openSnackbar({ message: capitalizeText(error.response.data.message), severity: 'error' }));

        }
      };
    
      return (
        <>
        {apiSuccess ? 

        <HostEmailOtp fetchData={props.fetchData} usersData={props.usersData}  emaildata={emaildata}  closeModal={props.closeModal}/> : 
        <div className="host-edit-name-main-div " style={{padding:'0rem',marginTop:'2rem'}}>
        <form className='host-personal-info-edit-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='host-personal-info-input-box'>
        {/* <label className='personal-info-label' htmlFor="email">Email</label> */}
        <input
          {...register('email', {
            required: true,
            pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
          })}
          id="email"
          defaultValue={props.usersData?.email
            || ''} 

        />
        {errors.email && errors.email.type === 'required' && (
          <span>This field is required.</span>
        )}
        {errors.email && errors.email.type === 'pattern' && (
          <span>Invalid email format.</span>
        )}
      </div>
      
      <div className="clear_apply-btn-box paddingCustomYSide">
      
      <p onClick={() => props.closeModal(false)} className="clear-btn">
         Cancel
       </p>
      <button className="save-edit-btn" type="apply-btn">
         Save
       </button>

      </div>
          
    
        </form>
       

        </div>

       
         }
         </>
      );

}

export default HostMailEdit
