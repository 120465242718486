import React, { useContext, useEffect, useState } from "react";
import CardWrapper from "../../../Components/common/cardWrapper";
import FontComp from "../../../Components/common/fontComp";
import { faWifi } from "@fortawesome/free-solid-svg-icons";
import APIHit from "../../../utils/apiHit";
import { apiMainUser, apiPublicUser, capitalizeText } from "../../../utils/constant";
import DataContext from "../../../utils/dataContext";
import { useNavigate } from "react-router-dom";
import ProfileImage from "../../../Components/common/profileImg";
import { setAmenitiesData } from "../../../utils/redux/listingTypeSlice";
import { useDispatch } from "react-redux";
import { handleNavigation } from "../../../Components/common/navigateFunc";
import Save_ExitComp from "../../../Components/common/save_ExitComp";
import { useSelector } from "react-redux";

const ListingAmenities = () => {
  const [successdata, setSuccessData] = useState([]);

  // const [amenitiesData, setAmenitiesData] = useState([]);
  const [selectedUid, setSelectedUid] = useState([]);
  const [apicall, setApiCall] = useState(false);

  // const [clickdata, setClick] = useState([]);
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const storeSel = useSelector((state) => state);
  const storeSel = useSelector((state) => state);
  const data = storeSel.listingDataSlie?.amenitiesData?.metadata_value;

  console.log(data, "trp");

  const [storedReduxData, setStoredReduxData] = useState(null);

  useEffect(() => {
    const data = storeSel.listingDataSlie?.amenitiesData?.metadata_value;
    console.log(data, "yoga");
    setStoredReduxData(data);
  }, []);

  console.log(storedReduxData, "trp");
  console.log(storedReduxData, "moo");

  const [api, setApi] = useState(["createProperty_metadata_bulk"]);
  const [method, setMethod] = useState("");

  const dispatch = useDispatch();

  const Currenturl = window.location.href;
  console.log(Currenturl, "tyt");
  const CurrentPage = new URL(Currenturl);
  const path = CurrentPage.pathname;
  console.log(path, "qqq");

  const getdata = (uid) => {
    setStoredReduxData(null);
    const selectedIds = [...selectedUid];

    // Check if the card is already selected
    const index = selectedIds.indexOf(uid);

    // If the card is already selected, remove it from the array
    if (index !== -1) {
      selectedIds.splice(index, 1);
    } else {
      selectedIds.push(uid);
    }

    setSelectedUid(selectedIds);
    let selectedIdsJoin = selectedIds.join(",")
    const finaldata = {
      metadata_key: "amenities",
      metadata_value: selectedIdsJoin,
    };
    dispatch(setAmenitiesData(finaldata));
  };

  useEffect(() => {
    setApiCall(true);
  }, []);

  // useEffect(() => {
  //   if (Object.keys(reduxData).length > 1) {
  //     setApi([...api, "updatePropertyPref/" + addPrefrenceId]);
  //     setMethod("patch");
  //   } else {
  //     setApi([...api, "add_preference"]);
  //     setMethod("post");
  //   }
  // }, []);
  useEffect(() => {
    const PropIddata = storeSel.apiDataSlice?.propId;
    const preferenceIddata = storeSel.apiDataSlice?.preferenceId;

    let updatedApi = ["updateProperty/" + PropIddata, ...api];

    if (preferenceIddata) {
      updatedApi = [...updatedApi, "updatePropertyPref/" + preferenceIddata];
      setMethod("patch");
    } else {
      updatedApi = [...updatedApi, "add_preference"];
      setMethod("post");
    }

    setApi(updatedApi);
  }, []);

  console.log(api, "www");
  const handleFailure = (data) => {
    // Handle the successful response here
    console.log("Failure response:", data);
  };

  const handleSuccess = (response) => {
    // Handle the successful response here
    console.log("Successful response:", response.data);

    setSuccessData(response.data.amenities_category
      );
  };
  const handleSuccess2 = (response) => {
    // Handle the successful response here
    console.log("Successful response:", response);
  };
  const handleFailure2 = (data) => {
    // Handle the successful response here
    console.log("Failure response:", data);
  };

  const runPrecondition = () => {
    // Perform your precondition check here
    // Return true if the precondition is met, or false otherwise
    if (successdata != []) {
      return false;
    }
    return true;
  };

  // const navigateTo = () => {
  //   handleNavigation(selectedUid, "/host/createlisting/dropbox", navigate);
  // };

  const navigateTo = () => {
    handleNavigation(
      selectedUid,
      // property_uid,
      storedReduxData,
      "/host/createlisting/dropbox",
      navigate
    );
  };
  return (
    <>
      {apicall && (
        <APIHit
          url={apiPublicUser + "all_filters"}
          method="GET"
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          precondition={runPrecondition}
          successResponseCode={200}
          // apidata={amenitiesData}
          directCall={true}
          auth={true}
        ></APIHit>
      )}

      <div className="listing-amenities-box create-list-property-type">
        <div className="create-list-amenities-content">
          <h2 className="listing-amenities-heading-text">
            Elevate Your Guests' Experience with Exceptional Amenities
          </h2>
          <p className="listing-amenities-para-text">
            Highlight the unique features of your place by adding desirable
            amenities to your listing!
          </p>

          {/* <div className="create-list-amenities-flex-box create-list-property-flex-box">
            {successdata?.map((item) => (
              <label>
                <CardWrapper
                  key={item.uid}
                  // onClick={() => getdata(item.uid)}
                  wrapperClass={`create-list-property-type-card listing-amenities-card ${
                    selectedUid.includes(item.uid) ||
                    storedReduxData?.includes(item.uid)
                      ? "selected"
                      : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    className="card-checkbox"
                    checked={selectedUid.includes(item.uid)}
                    onChange={() => getdata(item.uid)}
                  />
                 
                  <ProfileImage
                    profileImgParent="my-icon-class"
                    src={item.icon_url}
                    className="dndn"
                  />

                  <p className="amenities-text">{item.amenity_name}</p>
                </CardWrapper>
              </label>
            ))}
          </div> */}

<div className="">
  {successdata?.map((category) => (
    <div key={category.id}>
      {category.amenities_details.length > 0 && (
        <h2 className="mt-30 mb-10">{capitalizeText(category.category_type)}</h2>
      )}
      <div className="create-list-amenities-flex-box create-list-property-flex-box">
      {category.amenities_details.map((amenity) => (
      
        <label key={amenity.id}>
          <CardWrapper
            key={amenity.id}
            // add other necessary props for CardWrapper
            wrapperClass={`create-list-property-type-card listing-amenities-card ${selectedUid.includes(amenity.id) || storedReduxData?.includes(amenity.id) ? "selected" : ""}`}
          >
            <input
              type="checkbox"
              className="card-checkbox"
              checked={selectedUid.includes(amenity.id)}
              onChange={() => getdata(amenity.id)}
            />
            <ProfileImage
              profileImgParent="my-icon-class"
              src={amenity.icon_url}
              className="dndn"
            />
            <p className="amenities-text">{amenity.amenity_name}</p>
          </CardWrapper>
        </label>
    
      ))}
          </div>
    </div>
  ))}
</div>



        </div>
        {/* <APIHit
          url={apiMainUser + "create_property_metadata"}
          method="post"
          onSuccess={handleSuccess2}
          onFailure={handleFailure2}
          apidata={amenitiesData}
          // precondition={runPrecondition2}
          successResponseCode={201}
          // directCall={true}
        > */}
        <div className="bottom-btns-fixed-box">
          <Save_ExitComp
            // apis={["createProperty_metadata_bulk", "add_preference"]}
            method={method}
            apis={api}
            path={path}
          />
          <button
            onClick={navigateTo}
            type="submit"
            className="common-next-btn"
          >
            Next
          </button>
        </div>
        {/* </APIHit> */}
      </div>
    </>
  );
};

export default ListingAmenities;
