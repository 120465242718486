


import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { apiMainUser } from '../../../utils/constant';
import HostDocsImage from './HostDocsImage';

const HostGovtID = (props) => {
  const { register, handleSubmit } = useForm();
  const [apiSuccess, setApiSuccess] = useState(false);
  const [docsData, setDocsData] = useState(null);
  const [getDetails, setGetDetails] = useState(null);



  const docsFetchData = async () => {
   
    try {
      const response = await axios
        .get( apiMainUser + "read_user_docs", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data.data[0],'rey')
          setGetDetails(response.data.data[0])
          
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    docsFetchData();
   
  }, []);

  


  const onSubmit =  (data) => {

    setDocsData(data.option)
    setApiSuccess(true)
    
  };


  return (
    <>
     {apiSuccess ? 

   <HostDocsImage getDetails={getDetails} fetchData={props.fetchData}   docsData={docsData}   closeModal={props.closeModal}/> : 
   <div className='host-edit-name-main-div '>
    {/* <h2 style={{marginBottom:'2rem'}}>Verify your Identity</h2> */}
    <p className='mt-10'>To confirm Your identity, please slecte goverment issued-id from the options provided</p>
    <form className='govt-id-form' onSubmit={handleSubmit(onSubmit)}>
      <div className='govt-id-flex-box'>
        <label>
          <input
            type="radio"
            value="Adhar Card"
            {...register('option')}
            // checked={getDetails?.doc_name === 'Adhar Card'}
            // onChange={() => setDocsData('Adhar Card')}
          />
          Adhar Card
        </label>
      
    
        <label>
          <input
            type="radio"
            value="Passport"
            {...register('option')}
           
            // checked={getDetails?.doc_name === 'Passport'}
            // onChange={() => setDocsData('Passport')}
          />
          Passport
        </label>
      </div>
      <div className='govt-id-flex-box'>
        <label>
          <input
            type="radio"
            value="Voter Id"
            {...register('option')}
            // checked={getDetails?.doc_name === 'Voter Id'}
            // onChange={() => setDocsData('Voter Id')}
          />
        Voter Id
        </label>
      
        <label>
          <input
            type="radio"
            value="Driving License"
            {...register('option')}
            // checked={getDetails?.doc_name === 'Driving License'}
            // onChange={() => setDocsData('Driving License')}
          />
          Driving License
        </label>

        
      </div>

      <div className='govt-id-flex-box'>
        <label>
          <input
            type="radio"
            value="PAN"
            {...register('option')}
            // checked={getDetails?.doc_name === 'PAN'}
            // onChange={() => setDocsData('PAN')}
          />
        PAN
        </label>
      
      

        
      </div>     
      <div className="clear_apply-btn-box">
      
      <p onClick={() => props.closeModal(false)} className="clear-btn">
         Cancel
       </p>
      <button className="save-edit-btn" type="apply-btn">
         Save
       </button>

  
 </div>
    </form>
    </div>
}
    </>
  );
};

export default HostGovtID;
