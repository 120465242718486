
import React, { useState, useEffect } from 'react';

import ButtonPopup from '../../Components/common/ButtonPopup';
import ContactAdultCountPop from './ContactAdultCountPop';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../Components/common/Button';
import DatePicker from '../../Components/UserComponents/datePicker';
import axios from 'axios';
import { apiMainUser } from '../../utils/constant';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../utils/redux/SnackBarSlicer';


const ContactDestionationPop = (props) => {
  const [minDateState, setMinDate] = useState();
  const [maxDateState, setMaxDate] = useState();

  console.log(props.propertyId,'cehckPropertyId')

  const navigate = useNavigate();


  const [totalGuestCount, setTotalGuestCount] = useState(1); // Default to 1 adult

  const [guestCount, setGuestCount] = useState(null);
  const [infantCount, setInfantCount] = useState(null);
  const [messageval, setMessageVal] = useState("");

  const setMessageValFunc = (e)=>{
    setMessageVal(e.target.value);
  }
  const [selectedCheckIn, setCheckIn] = useState(null); // initialize with the current date or any valid date
const [selectedCheckOut, setCheckOut] = useState(null); // initialize with the current date or any valid date

  const onDateChange = (checkIn, checkOut) => {
    setCheckIn(checkIn?.format("YYYY-MM-DD"));
    setCheckOut(checkOut?.format("YYYY-MM-DD"));
  };

  const dispatch = useDispatch()

  const ContactHostFunc = () => {
    if (selectedCheckIn && selectedCheckOut && totalGuestCount && messageval.trim() !== "") {
      const apiUrl = 'create_chat';
  
      // Construct the payload
      const payload = {
        property_id: props.propertyId,
        message_type: 'initial',
        message: messageval,
        checkin_date: selectedCheckIn,
        checkout_date: selectedCheckOut,
        guests: parseInt(totalGuestCount, 10),
      };
  
      // Add the Authorization header
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
  
      axios.post(apiMainUser + apiUrl, payload, { headers })
        .then(response => {
          // Handle the response
          if(response.data.status == 'success'){

            props.closeModal();
            navigate('/user-message');
          }
          
     
        })
        .catch(error => {
          // Handle errors
          console.error('Error during API call:', error);
        });
    } else {
      dispatch(openSnackbar({ message:"Please fill in all the details of the form", severity: 'error' }));

    }
  };
  




  const handleCounts = (adults, children, infants) => {
    const guestlist = adults + children + infants;
    setGuestCount(adults + children);  // Update guestCount state
    setInfantCount(infants);           // Update infantCount state
    setTotalGuestCount(guestlist);
    
    // console.log(infantCount,guestCount,'checkAllCounts')
    // ... other handling logic if needed
  };


  console.log(selectedCheckIn,selectedCheckOut,'checkAllDates')

  return (
    <div className='Mob-destination-guest-box'>
    {/* Reuse the same class from MobHeadDestinationGuest */}
    <p className='your-shared-class' style={{ fontSize: '13px', fontWeight: 600, marginBottom: '0.5rem' }}>Tell us your dates, no. of guests, and message</p>

    <div className="guest-dropdown">
      {/* Reuse the same class from MobHeadDestinationGuest */}
      <DatePicker
        minDate={minDateState}
        startDate={selectedCheckIn}
        endDate={selectedCheckOut}
        // Additional props as needed
        onDateChange={onDateChange}
      />
    </div>
    <div style={{border:'1px solid #19A6BB'}} className="guest-infant-div">
            <ButtonPopup
            buttonPopup={true}
            saveBtnClass='sav-edit-btn'
            wrapclass ='absolute w-100'
            makeCustom={
            <div className="guest-dropdown">
              <div style={{paddingLeft:'0.7rem'}}>
            <p className="guest-count-text">
         
           {guestCount > 1 ? guestCount + " " +  'Guests' : '1 Guest'} 
           {infantCount && infantCount > 0 ? (infantCount > 1 ? ', '+infantCount+' Infants' : ', 1 Infant') : ''}            
          </p>
          </div>
          <FontAwesomeIcon icon={faAngleDown} className="arrow-icon" />
          </div>
          }
            divContent={
            <ContactAdultCountPop
            handleCounts={handleCounts} 
            // maxGuests={props.max_guests}
           

            />
          }
           />
            </div>
            <textarea style={{border:'1px solid #19A6BB',padding:'10px'}} className="guest-infant-div" placeholder='Write your message here' onChange={(e)=>setMessageValFunc(e)}></textarea>

        
            <div className="search-btn-fixed">
             <Button
            onClick={ContactHostFunc}
            btnText='Send Message'
            btnclasses='common-next-btn ' /> 
            
             </div>  


    {/* Add any additional states or functionality needed for the contact component */}
    {/* ... */}
  </div>
  );
}

export default ContactDestionationPop;
