import React from 'react';
import SkeletonLoader from '../Components/common/SkeletonLoader';

const SkeletonTable = () => {
  const numRows = Array.from({ length: 5 }); // Creates an array with 5 undefined elements
  const numCols = Array.from({ length: 1 }); // Creates an array with 4 undefined elements

  const cellContent = 'Skeleton';

  return (
    <div className="">
      <table style={{width:'100%'}}>
        <thead>
          <tr>
            {numCols.map((data, index) => (
              <th key={index}>
                <SkeletonLoader  width={"100%"} height={10} count={1} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {numRows.map((data, index) => (
            <tr key={index}>
              <td>
                <SkeletonLoader width={"100%"} height={10} count={1} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SkeletonTable;
