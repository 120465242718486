

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { apiMainUser } from '../../utils/constant';
import DatePicker from '../../Components/UserComponents/datePicker';
import moment from 'moment';

const CalendarPriceInpPop = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isBlocked, setIsBlocked] = useState(props.radionText == 'Blocked');
  const [minDateState, setMinDate] = useState();
  const [maxDateState, setMaxDate] = useState();
  const [blockDateState, setBlockedDates] = useState([]);

  console.log(isBlocked,'checkPageCals')

  const onDateChange = (minDate, maxDate) => {
    setMinDate(moment(minDate).format('YYYY-MM-DD'));
    setMaxDate(moment(maxDate ? maxDate : minDate).format('YYYY-MM-DD'));
  };

  const startDate = moment(props.startDate).format('YYYY-MM-DD');

  console.log(props.blockMessage, 'propsCal');
  // console.log(minDateState, maxDateState, 'checkOnce');

  const onSubmit = (data) => {
    const numericDataPrice = parseFloat(data.price);

    const requestData = {
      date_from: minDateState ? minDateState : startDate,
      date_to: maxDateState ? maxDateState : startDate,
      property_id: props.propertyId,
      block_message: data.message,
      block: isBlocked ? 1 : 0,
    };
    if (data.price !== '' && numericDataPrice !== props.price) {
      console.log('Setting price:', data.price);
      requestData.price = numericDataPrice;
    }

    axios
      .post(apiMainUser + 'updateCalenderData', requestData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((response) => {
        console.log('API Response:', response.data);
        props.fetchCalendarSlots();
        props.closeModal();
      })
      .catch((error) => {
        console.error('API Error:', error);
        // Handle error if needed
      });
  };

  
  return (
    <div className="calendar-price-in-pop">
      {props.pastDate ?
      <>
      {/* <h2 className='font-large past-block-heading'>Private Note</h2> */}
     <p className='font-xs mt-20 past-block-message'>{props.blockMessage}</p> 
     
     <p onClick={() => props.closeModal(false)} className="clear-btn closeBtnPop mt-20">
      Close
    </p>
    </>
     : 
      <>
         <div className="check-in-out-para">
        <p className="check-in-out-para">
          <span>From Date</span>
          <span>To Date</span>
        </p>
      </div>

      <div className="booking-date-edit-div">
        <DatePicker
        endDatePlaceholderText={false}
        startDatePlaceholderText={false}

          minDate={props.minDate}
          maxDate={props.maxDate}
          blockedDates={props.blockDate}
          blockedDatesCheckin={props.blockCheckinDateState}
          blockedDatesCheckout={props.blockCheckoutDateState}
          onDateChange={onDateChange}
          startDate={props.startDate}
        />
      </div>

      <form className="calendar-popup-form" onSubmit={handleSubmit(onSubmit)}>
        <h2>Availability</h2>
        <div className="radio-box-div">
          <div>
            <label>
              Available
              <input
                type="radio"
                value="available"
                checked={!isBlocked}
                onChange={() => {
                  setIsBlocked(false);
                }}
              />
            </label>
          </div>
          <div>
            <label>
              Blocked
              <input
                type="radio"
                value="blocked"
                onChange={() => {
                  setIsBlocked(true);
                }}
                checked={isBlocked}
              />
            </label>
          </div>
          {errors.gender && <p>{errors.gender.message}</p>}
        </div>

        <div>
          <h2>Price per Night</h2>
          <div>
            <input
              defaultValue={props.price ? props.price : ''}
              type="number"
              {...register('price', { required: 'Price is required' })}
            />
          </div>
        </div>

        <div className="private-note-box">
          <h2>Private Note</h2>
          <div>
            <textarea
              {...register('message')}
              defaultValue={props.blockMessage ? props.blockMessage : ''}
              placeholder="Add a note"
            />
          </div>
        </div>

        {/* <div className="search-btn-fixed">
          <button className="save-edit-btn" type="submit">
            Save
          </button>

          
        </div> */}

      <div className="clear_apply-btn-box paddingCustomAllSide">
      
      <p onClick={() => props.closeModal(false)} className="clear-btn">
         Cancel
       </p>
      <button className="save-edit-btn" type="apply-btn">
         Save
       </button>   
      </div>
      </form>
      </>
      
      }
   
    </div>
  );
};

export default CalendarPriceInpPop;
