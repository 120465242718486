import React from "react";

const PropertyDetailParagraph = (props) => {
  // iski styling proprtyDetailFeature me hai-------
  return (
    <div className="property-detail-paragraph">
      
     {props.h4 ?  <h4 className="property-detail-para-heading">{props.h4}</h4> : ''}
     {props.spacePara ? <p>{props.spacePara}</p> : ''} 
     {props.subHeading ? <h5>{props.subHeading}</h5> : ''} 
      {/* <p>{props.subPara}</p> */}
      {/* <p>{props.subPara}</p> */}
    {props.subPara ?  <p className={`${props.className ? props.className + ' ' : ''}endode-para`} dangerouslySetInnerHTML={{ __html: props.subPara }} /> : ''} 
    
    </div>
  );
};

export default PropertyDetailParagraph;
