import React, { useEffect, useState } from 'react';

const CustomInsightDatePop = (props) => {
  // State variables for form inputs
//   const [fromDate, setFromDate] = useState('');
//   const [toDate, setToDate] = useState('');


  const [fromDate, setFromDate] = useState(props.defaultFromDate || '');
  const [toDate, setToDate] = useState(props.defaultToDate || '');

  useEffect(() => {
    // If defaultFromDate and defaultToDate are provided, update state with defaults
    if (props.defaultFromDate && props.defaultToDate) {
      setFromDate(props.defaultFromDate);
      setToDate(props.defaultToDate);
    }
  }, [props.defaultFromDate, props.defaultToDate]);



  const handleSubmit = (event) => {
    event.preventDefault();
    // Call the updateDateRange function from props
    props.updateDateRange(fromDate, toDate);
    // Close modal if needed
    props.closeModal();
  };
  

  return (
    <div>
      <form onSubmit={handleSubmit} >
        <div style={{gap:'20px'}} className='flex'>
        <div className='w-100'>
          <label  className='inline-block mb-5 font-xs mt-6'  htmlFor="fromDate">From Date:</label><br />
          <input
            type="date"
            className='pd-px-10 w-100'
            id="fromDate"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            required
          />
        </div>
        <div className='w-100'>
          <label className='inline-block mb-5 mt-6 font-xs' htmlFor="toDate">To Date:</label><br />
          <input 
          className='pd-px-10 w-100'
            type="date"
            id="toDate"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            required
          />
        </div>
        </div>
       

        <div className="clear_apply-btn-box paddingCustomYSide">
          <p onClick={() => props.closeModal(false)} className="clear-btn">
            Cancel
          </p>
          <button type="submit" className="save-edit-btn">Save</button>
        </div>
      </form>
    </div>
  );
};

export default CustomInsightDatePop;
