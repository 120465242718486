import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

import "swiper/css";
import SwiperCore, { Navigation } from "swiper";

SwiperCore.use([Navigation]);


const HeroSwiper = () => {

  const isSmallScreen = window.innerWidth <= 500;

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      navigation={isSmallScreen ? false : true}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
    >
      <SwiperSlide>
        <img src="./assets/img/HeroImg1.png" alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="./assets/img/HeroImg2.png" alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="./assets/img/HeroImg3.png" alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="./assets/img/HeroImg4.png" alt="" />
      </SwiperSlide>
    </Swiper>
  );
};

export default HeroSwiper;
