
import React from 'react';

const getDocumentName = (text) => {
    switch (text) {
      case 'aadhaar_card':
        return 'Aadhaar Card';
      case 'pancard':
        return 'Pan Card';
      case 'Voter ID Card':
        return 'Voter Card';
      case 'passport':
        return 'Passport';
      case 'Driving License':
        return 'Driving License';
      default:
        return '';
    }
  };

const ViewDocuments = ({ documents,closeModal }) => {

    console.log(documents,'checkDocs')

  const handleImageClick = (document) => {
    //here i am opening the image in new tab
    window.open(document.doc_file, '_blank');
    closeModal()
    console.log('closedPop')
  };

  console.log(documents,'checkDocuments')

  return (
    <div className='view-document-pop'>
    
      <ul>
        {documents.map((document) => (
          <li className='view-dcument-li' key={document.userdoc_id} onClick={() => handleImageClick(document)}>
            {/* <p style={{fontSize:'12px',fontWeight:'500'}}>{document.doc_name}</p> */}
            <img src={document.doc_file} alt={document.doc_name} />
         <p style={{ fontSize: '12px', fontWeight: '500' }}>{getDocumentName(document.doc_name)}</p>

          </li>
        ))}
      </ul>
    </div>
  );
};

export default ViewDocuments;
