import React from "react";
import GuestProfile from "../common/guestProfile";
import CardWrapper from "../common/cardWrapper";
import { Rating } from "@mui/material";

const UserReviewCard = (props) => {
  return (
    <CardWrapper wrapperClass="user-review-card">
       <Rating
          // name={`rating-${props.categoryIndex}`}
          value={props.rating}
          readOnly
        />
      <GuestProfile
        profileName={props.profileName}
        whosProfile={props.whoseProfile}
        profileImage={props.profileImage}
      />
      
      <p className="user-review-text">{props.userReviewText}</p>
      {/* <div className="reply-btn">View reply</div> */}
      
     

    </CardWrapper>
  );
};

export default UserReviewCard;
