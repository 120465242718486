import React from "react";
import Button from "./Button";
import GuestProfile from "./guestProfile";
import { NavLink } from "react-router-dom";
import ButtonPopup from "./ButtonPopup";
import { useNavigate } from "react-router-dom";
import ProfileButtons from "./ProfileButtons";
import StepFormBack from "../../Module/Host/CreateListing/GoBack";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from "react-router-dom";

const HostNav = () => {


 

  const storeSel = useSelector((state) => state);
   const fullNameData = storeSel.hostDetailSlice?.full_Name;
   const profileImage = storeSel.hostDetailSlice?.Image;

   const isSmallScreen = window.innerWidth <= 500;

  const navigate = useNavigate();

  const navigateTo = ()=>{
    navigate('/')
  }
  const createListingGoto = (currentStep)=>{
    switch (currentStep) {
      case "/step-1":
        return (
          "/"
        );
      case "/property-title":
        return (
          "/step-1"
        );
      case "/property":
        return (
          "/property-title"
        );
      case "/place":
        return (
          "/property"
        );
      case "/room":
        return (
          "/place"
        );
      case "/confirmAddress":
        return (
          "/room"
        );
      case "/counterBeds":
        return (
          "/confirmAddress"
        );
        case "/step-2":
          return (
            "/counterBeds"
          );
      case "/listingAmenities":
        return (
          "/step-2"
        );
      case "/dropbox":
        return (
          "/listingAmenities"
        );
        case "/discription":
          return (
            "/dropbox"
          );
        
      case "/step-3":
        return (
          "/discription"
        );
      case "/rulesDiscription":
        return (
          "/step-3"
        );
     
      case "/bookingPreference":
        return (
          "/rulesDiscription"
        );
      case "/listingPrice":
        return (
          "/bookingPreference"
        );
      case "/reviewListing":
        return (
          "/listingPrice"
        );
      case "/hostCongrats":
        return (
          ""
        );
      default: return "";
    }
  }
  const navigateBack = () => {
    var currentpage = window.location.pathname;
    if(currentpage.includes("/createlisting")){
      var splitcurrent = currentpage.split("/host/createlisting");
      var splitpath = splitcurrent[1];
      if(createListingGoto(splitpath) === ""){
        navigate("/host/listing");
      }else{
        navigate("/host/createlisting"+createListingGoto(splitpath));
      }
    }else{
      navigate(-1);
    }
  };
  return (
    <div className="host-nav-box">
      <div className="head-in">
        {!isSmallScreen ? 
        <Button
          // onClick={handleGoBack}
          as="div"
          btnText="Go Back"
          btnclasses="text-btn"
          onClick={navigateBack}
        />
        :

<FontAwesomeIcon icon={faArrowLeft} className="location-icon" size="2x"
 onClick={navigateBack}
/>
}

        <img style={{cursor:'pointer'}} src="./assets/img/jumboLogo.png"  onClick={navigateTo}/>


  {!isSmallScreen ?
        <ButtonPopup
          wrapclass="wc-max"
          makeCustom={
            <GuestProfile
            profileName={fullNameData}
              // whoseProfile="Host Profile"
              profileImage={profileImage}
            />
          }
          divContent={
            <ProfileButtons/>
          }          
          icon="./assets/img/locationImg.svg"                                
        />

        :
        <div>

        </div>
        }
      </div>
    </div>
  );
};

export default HostNav;
