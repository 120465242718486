import React, { useEffect } from "react";
import CardWrapper from "../common/cardWrapper";
import PropertyDetailsBox from "./propertyDetailsBox";
import AmenitiesBoxes from "./AmenitiesBoxes";
import DatePicker from "./datePicker";
import UserShareBtn from "./UserShareBtn";
import WishListIcon from "./wishListIcon";
import Button from "../common/Button";
import { RWebShare } from "react-web-share";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay,Pagination } from "swiper";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleDot, faDotCircle, faStar } from "@fortawesome/free-solid-svg-icons";

import 'swiper/css';
import 'swiper/css/pagination';
import { formatIndianRupeesFunc } from "../../utils/constant";
import { useRef } from "react";
import { useInView } from 'react-intersection-observer';
SwiperCore.use([Autoplay]);

const TrendingProperty = (props) => {

  const isSmallScreen = window.innerWidth <= 500;
  const [currentSlide, setCurrentSlide] = useState(0);

  const uniqueIDRef = useRef(`id_listing_${props.index}`);

  const { current: uniqueID } = uniqueIDRef;

  useEffect(() => {
    props.handleUniqueID(uniqueID); // Pass the uniqueID to the parent component
  }, [uniqueID, props]);
 

  console.log(`id_listing_${props.index}`,props.isVisible,'checkInterSect')
  console.log(`id_listing_${props.index}`== props.isVisible,'checkInterSect')

  



  return (
    <CardWrapper
    
     onClick ={props.onClick} wrapperClass="trending-propety-box"
    //  id={uniqueID}
    id={uniqueID}
     >
      {/*  */}

      <div className="trending-property-img-box h-180px">
        {/* <img src={props.img} alt="" /> */}

        <Link to={props.slug}>
        {isSmallScreen ? (
          <div style={{width:'100%',height:'100%'}}
          >
          
          {props.isVisible == `id_listing_${props.index}`  ?
          <Swiper
            //  autoplay={isVisible ? { delay: 2000 } : false}
            // autoplay={inView ? { delay: 2000 } : false}
            key={`swiper-visible-${props.isVisible}`}
            autoplay={{ delay: 2000 }}
            slidesPerView={1}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            spaceBetween={10}
            onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
          >
            {props.swiperSlides.map((slide, index) => (
              <SwiperSlide key={index}>
                <img src={slide.imgUrl} alt={slide.altText} />
              </SwiperSlide>
            ))}
          </Swiper>
: 
           <Swiper
            key={`swiper-not-visible-${props.isVisible}`}
          //  className={props.isVisible + 'swiper'}
            autoplay={false}
            slidesPerView={1}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            spaceBetween={10}
            onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
          >
            {props.swiperSlides.map((slide, index) => (
              <SwiperSlide key={index}>
                <img src={slide.imgUrl} alt={slide.altText} />
              </SwiperSlide>
            ))}
          </Swiper>

}
         
          </div>
        ) : (
          // Render image for larger screens
          <img src={props.img} alt="" />
        )}
        </Link>


        {isSmallScreen &&     
          <div className="trending-property-share-wishlist-box">

            <div className="wishlist-white-icon-parent">
            <WishListIcon  
            // fetchwishData={props.fetchwishData}
             id={props.WishListpropID} wishListApplied={props.wishListApplied} 
           />
           </div>
          </div>
}
      </div>

     

      

      <div className="trending-property-text-box">
      
        <div className="trending-property-heading-box">
          <Link className="trending-property-name-link w-75" to={props.slug} >
          
          <div className="trending-property-name w-100">
            <h3 className="trending-property-heading">{props.property_name}</h3>
            <p className="trending-property-loction-text">{props.location}</p>
          </div>
          </Link>
       <div className="trending-property-reviews-parent-div w-20 flex align-items-center justify-space-around" >
      {(props.reviewNum && props.starNum) !== null && (props.reviewNum && props.starNum) !== undefined && (props.reviewNum && props.starNum) > 0 &&
            <div  className="trending-property-review-box">   
            <p>
              <span className="star-num-trending-card bold">{props?.starNum.toFixed(1)}</span>
              <span className="pd-px-3">
                {/* <img src="./assets/img/BigBlackStar.svg" alt="" /> */}
                <FontAwesomeIcon
                icon={faStar}
                // size='2x'
                fontSize={"14px"}
                color="#FFC700"
              />
              </span>
            </p>
            <span style={{ fontSize: "1.1rem", fontWeight: "500" }}>
              {props.reviewNum} reviews
            </span>
          </div> 

          }

        {!isSmallScreen &&     
          <>
        {props?.reviewNum > 0 ? <FontAwesomeIcon icon={faCircle} className="dividerdot"/> : <div></div>}  
          {/* <div className="trending-property-share-wishlist-box ">  */}
          <div className={props?.reviewNum > 0 ? 'trending-property-share-wishlist-box' : "trending-property-share-wishlist-box" + ' flex-end-wishlist'}>           

          <WishListIcon
          // fetchwishData={props.fetchwishData}
           id={props.WishListpropID} 
           wishListApplied={props.wishListApplied} />
          </div>
          </>
        }



          </div>
        </div>

        {/* / */}
        <PropertyDetailsBox
          guestNum={props.maxguest || 1}
          bedroomNum={props.rooms}
          bedsNum={props.beds}
          bathroomNum={props.bath}
          //props of this component is pass where trending property is called
        />
        {/*  */}
        <div className="amenities-boxes-reviews">
          <div className="amenities-list">
            {
              (props.amenities).map((amdata,index)=>{
                if(index > 2) return;
                return<>
                 <AmenitiesBoxes proertyAmenitiesText={amdata.amenity_name} />
                 
                 
                 </>
              })
              
            }
            {props.amenitiesCount > 0 && <span className="font-xs property-amenities-box">+{props.amenitiesCount}</span>}
          </div>
          <Button 
              as="div" 
              priceLinkClasss='price-btn-link'
            
              link={props.slug}
              btnclasses="price-btn" 
              btnText={
                <>
                  <p>
                  ₹{formatIndianRupeesFunc(props.priceValue)}
                  <span className="per-night-text"> per night</span>
                  </p>                  
                </>
              }
            >
            
            </Button>         
        </div>


       
      </div>
    </CardWrapper>
  );
};

export default TrendingProperty;
