import React from "react";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import 'react-circular-progressbar/dist/styles.css';

const CircleComp = ({ percentage, color }) => {
  return (
    <div style={{ width: "5rem",margin:'auto'}}>
      
       <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles({
          rotation: 0.25,
          strokeLinecap: 'butt',
          textSize: '16px',
          pathTransitionDuration: 0.5,
          pathColor: color || `rgba(62, 152, 199, ${percentage / 100})`,
          textColor: '#f88',
          trailColor: '#d6d6d6',
          backgroundColor: '#3e98c7',
          textColor: '#333',
        })}
      />
    </div>
  );
};

export default CircleComp;
