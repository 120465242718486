const getTotalGuestCountFunc = (adults, children)=>{
  var guestlist;
  if(adults !== undefined && children !== undefined && adults !== "null"  && children !== "null" && adults !== null  && children !== null ){
    guestlist = parseInt(adults) + parseInt(children);
  }else if(adults !== undefined && adults !== "null" && adults !== null){
    guestlist = parseInt(adults);
  }else{
    guestlist =parseInt(children);
  }
  return guestlist
}

export const getTotalGuestCount = getTotalGuestCountFunc;